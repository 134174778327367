import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Label } from "reactstrap";
import * as yup from "yup";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import Select from "react-select";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { MECHANIC_LIST_URL } from "../../../../../redux/config/links";
import {
  MECHANIC_INPUT,
  MECHANIC_EDIT,
} from "../../../../../redux/config/storing.links";
import useForm from "../../../../../hooks/useForm";

const MechanicAdd = ({
  initialValues = null,
  isEdit = false,
  onClose,
  onReload,
}) => {
  const [isGettingMechanic, setIsGettingMechanic] = useState(true);
  const [onMechanicList, { data, loading }] = useLazyFetch({
    url: MECHANIC_LIST_URL,
    method: "post",
  });

  const [onMechanicInput] = useLazyFetch({
    url: MECHANIC_INPUT,
    method: "post",
  });
  const [onMechanicEdit] = useLazyFetch({
    url: MECHANIC_EDIT,
    method: "post",
  });
  const validation = () => {
    let selectYup = yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable();
    return yup.object().shape({
      mechanic: selectYup.required("Mechanic is required!"),
    });
  };
  const onSubmit = (values, { setSubmitting }) => {
    if (!isEdit) {
      onMechanicInput(
        {
          body: {
            id_storing_fleet_trip: initialValues.id_storing_fleet_trip,
            id_mechanic: values.mechanic.value,
          },
        },
        () => {
          setSubmitting(false);
          onClose();
          onReload();
        }
      );
    } else {
      onMechanicEdit(
        {
          body: {
            id_storing_fleet_trip: initialValues.id_storing_fleet_trip,
            id_mechanic: values.mechanic.value,
            id: initialValues.id,
          },
        },
        () => {
          setSubmitting(false);
          onClose();
          onReload();
        }
      );
    }
  };

  const {
    handleSubmit,
    errors,
    values,
    handleReset,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues: {
      mechanic:
        !!initialValues && !!initialValues.id_mechanic
          ? {
              value: initialValues.id_mechanic,
              label: initialValues.mechanic_first_name,
            }
          : null,
    },
    validation: validation(),
  });

  useEffect(() => {
    if (isGettingMechanic) {
      setIsGettingMechanic(false);
      onMechanicList({});
    }
  }, [isGettingMechanic, onMechanicList]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Mechanic</Label>
          </Col>
          <Col xs="12" md="9">
            <LoadingContent
              loading={loading}
              onReload={() => setIsGettingMechanic(true)}
            >
              <Select
                value={values.mechanic}
                onChange={(value) => {
                  setFieldValue("mechanic", value);
                }}
                options={(data ? data.result : []).map((item) => ({
                  label: item.first_name,
                  value: item.id,
                }))}
                disabled={isSubmitting}
                placeholder={"Choose mechanic"}
              />
            </LoadingContent>
            <FormFeedback>{errors.mechanic}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            color="primary"
            className="mr-1"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Wait..." : !isEdit ? "Save Changes" : "Edit"}
          </Button>
          <Button
            type="button"
            color="warning"
            className="mr-1"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >
            Close
          </Button>
          <Button
            type="reset"
            color="danger"
            className="mr-1"
            disabled={isSubmitting}
            onClick={handleReset}
          >
            Reset
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

export default MechanicAdd;

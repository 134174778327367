// ToggleSwitchCommandCenter.jsx
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MdToggleOn, MdOutlineToggleOff } from "react-icons/md";
import styles from "./ToggleSwitchCommandCenter.module.scss";
import { Button } from "reactstrap";

const ToggleSwitchCommandCenter = () => {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Set status toggle berdasarkan lokasi saat ini
    if (location.pathname === "/transporter/command-center") {
      setIsToggleOn(true);
    } else {
      setIsToggleOn(false);
    }
  }, [location]);

  const handleToggle = () => {
    setIsToggleOn((prev) => !prev);
    if (!isToggleOn) {
      history.push("/transporter/command-center");
    } else {
      history.push("/transporter/live-monitoring");
    }
  };

  return (
    <div className={styles["toggle-switch-wrapper"]}>
      {isToggleOn ? (
        <>
          <Button onClick={handleToggle} className={styles["toggle-button"]}>
            <MdToggleOn size={30} color="#0085FF" /> Switch to Live Monitoring
            Mode &nbsp;
          </Button>{" "}
        </>
      ) : (
        <Button onClick={handleToggle} className={styles["toggle-button"]}>
          <MdOutlineToggleOff size={30} color="#585757" />
          &nbsp; Switch to Command Center Mode
        </Button>
      )}
    </div>
  );
};

export default ToggleSwitchCommandCenter;

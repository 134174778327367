import React, {Component} from 'react'
import MaintenanceBindingDatatable from './MaintenanceBindingDatatable'
import { connect } from 'react-redux'
import { fetchFleetMaintenanceBindList } from '../../../../redux/actions/transporter/aTransporter-FleetListTable';
class MaintenanceBinding extends Component {
    constructor(props){
        super(props)
        this.state={
            fleetsMaintenanceBinding: []
        }
        this.props.fetchFleetMaintenanceBindList(this.props.selectedFleetId)
    }

    componentDidUpdate(prevpProps){
        if(prevpProps.fleetsMaintenanceBinding !== this.props.fleetsMaintenanceBinding){
            this.setState({
                fleetsMaintenanceBinding: this.props.fleetsMaintenanceBinding
            })
        }
    }

    refreshTableData = () => {
        this.props.fetchFleetMaintenanceBindList(this.props.selectedFleetId)
    }

    render(){
        const {fleetsMaintenanceBinding} = this.state
        return(
            <div className="animated fadeIn">
                <>
                    <MaintenanceBindingDatatable selectedFleetId={this.props.selectedFleetId}
                            refreshTable={this.refreshTableData}
                            fleetsMaintenanceBinding={fleetsMaintenanceBinding}/>
                </>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        fleetsMaintenanceBinding: state.fleetMaintenanceBindingListStore.fleetsMaintenanceBinding,
        inProgressMaintenanceBinding: state.fleetMaintenanceBindingListStore.inProgress,
    }
}
export default connect(mapStateToProps, {fetchFleetMaintenanceBindList})(MaintenanceBinding);
import React from "react";
import { Link } from "react-router-dom";
import Mechanic from "./Mechanic/Mechanic";
import Proof from "./Proof/Proof";
import ApprovalTypeSetting from "./ApprovalTypeSetting/ApprovalTypeSetting";

export const maintenanceRoutes = () => {
  const path = "/storing/maintenance";

  return [
    {
      name: "Maintenance",
      path: `${path}`,
      exact: true,
      render: () => (
        <>
          <ul>
            <li>
              <Link to={`${path}/mechanic`}>Mechanic</Link>
            </li>
            <li>
              <Link to={`${path}/proof`}>Proof</Link>
            </li>
            <li>
              <Link to={`${path}/approval/type/setting`}>Approval Type Setting</Link>
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Mechanic",
      path: `${path}/mechanic`,
      exact: true,
      render: (props) => (
        <Mechanic {...props} />
      ),
    },
    {
      name: "Proof",
      path: `${path}/proof`,
      exact: true,
      render: (props) => (
        <Proof {...props} />
      ),
    },
    {
      name: "Approval Type Setting",
      path: `${path}/approval/type/setting`,
      exact: true,
      render: (props) => (
        <ApprovalTypeSetting {...props} />
      ),
    },
  ];
};

import React, { useEffect, useState } from "react";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { MAINTENANCE_MECHANIC_TASK_DETAIL_LIST } from "../../../../../redux/config/storing.links";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  bootstrapTableOption,
  fileNameFormat,
} from "../../../../../helpers/helper";
import {
  Button,
  ButtonGroup,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import TaskDetailAdd from "./TaskDetailAdd";
import TaskSparePartList from "./TaskSparePartList";
import TaskDetailDelete from "./TaskDetailDelete";
import SummaryWidgets from "../../../../Widgets/SummaryWidgets";

const TaskDetailList = ({ dataTask }) => {
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  const [modalOpenDelete, setModalOpenDelete] = useState(null);
  const [openAddTaskDetail, setOpenAddTaskDetail] = useState(null);
  const [isGettingTaskDetail, setIsGettingTaskDetail] = useState(true);
  const [openSparePartRequestItemDetail, setOpenSparePartRequestItemDetail] =
    useState(false);

  const [onTaskListDetail, { loading, data }] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_TASK_DETAIL_LIST,
    method: "post",
  });

  useEffect(() => {
    if (isGettingTaskDetail) {
      setIsGettingTaskDetail(false);
      onTaskListDetail({});
    }
  }, [isGettingTaskDetail, onTaskListDetail]);

  const dataFiltered =
    !!dataTask && !!dataTask.id && data
      ? data.result.filter(
          (item) => item.id_storing_maintenance_mechanic_task === dataTask.id
        )
      : [];

  return (
    <>
      <Row>
        <Col md={3} xs={12}>
          <SummaryWidgets
            header={dataFiltered.length}
            mainText={"Total Task"}
            icon={"fa fa-id-card"}
            color={"success"}
          />
        </Col>
      </Row>
      <LoadingContent
        loading={loading}
        onReload={() => setIsGettingTaskDetail(true)}
      >
        <BootstrapTable
          data={
            !!dataTask && !!data
              ? data.result.filter(
                  (item) =>
                    item.id_storing_maintenance_mechanic_task === dataTask.id
                )
              : []
          }
          version="4"
          bordered={false}
          hover
          pagination
          search
          edit
          options={bootstrapTableOption({
            btnGroup: (props) => (
              <div>
                {props.exportCSVBtn}{" "}
                <Button
                  color={"primary"}
                  onClick={() => setIsGettingTaskDetail(true)}
                >
                  <i className="fa fa-refresh"></i> Reload
                </Button>{" "}
                <Button
                  color={"primary"}
                  onClick={() =>
                    setOpenAddTaskDetail({
                      id_storing_maintenance_mechanic_task: dataTask.id,
                    })
                  }
                >
                  <i className="fa fa-plus"></i> Add Task
                </Button>{" "}
              </div>
            ),
          })}
          exportCSV={true}
          csvFileName={fileNameFormat({ name: "" })}
          multiColumnSearch
        >
          <TableHeaderColumn
            dataField="id"
            isKey
            dataSort
            csvHeader="ID"
            hidden
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="task_name" dataSort csvHeader="Task">
            Task
          </TableHeaderColumn>
          <TableHeaderColumn dataField="desc" dataSort csvHeader="Description">
            Description
          </TableHeaderColumn>
          <TableHeaderColumn dataField="cost" dataSort csvHeader="Description">
            Cost
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="created_on"
            dataSort
            csvHeader="Created On"
          >
            Created On
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="id"
            dataFormat={(cell, row) => (
              <>
                <ButtonGroup>
                  <Button
                    size="sm"
                    color="warning"
                    onClick={() =>
                      setModalOpenEdit({
                        ...row,
                        id_storing_maintenance_mechanic_task: dataTask.id,
                      })
                    }
                  >
                    <i className="fa fa-edit"></i> Edit
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => setModalOpenDelete({ ...row })}
                  >
                    <i className="fa fa-trash"></i> Delete
                  </Button>
                </ButtonGroup>
                <br />
                <Button
                  size="sm"
                  color={"primary"}
                  onClick={() => setOpenSparePartRequestItemDetail(row)}
                >
                  Sparepart Request Item Detail
                </Button>
              </>
            )}
          >
            Action
          </TableHeaderColumn>
        </BootstrapTable>
      </LoadingContent>

      <Modal
        size="md"
        isOpen={!!openAddTaskDetail}
        toggle={() => setOpenAddTaskDetail(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenAddTaskDetail(null)}>
          Add Task Detail
        </ModalHeader>
        <ModalBody>
          <TaskDetailAdd
            initialValues={openAddTaskDetail}
            onClose={() => setOpenAddTaskDetail(null)}
            onReload={() => setIsGettingTaskDetail(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenEdit}
        toggle={() => setModalOpenEdit(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(null)}>
          Edit Task Detail
        </ModalHeader>
        <ModalBody>
          <TaskDetailAdd
            initialValues={modalOpenEdit}
            isEdit
            onClose={() => setModalOpenEdit(null)}
            onReload={() => setIsGettingTaskDetail(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        isOpen={!!openSparePartRequestItemDetail}
        toggle={() => setOpenSparePartRequestItemDetail(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenSparePartRequestItemDetail(null)}>
          Sparepart Request Item Detail
        </ModalHeader>
        <ModalBody>
          <TaskSparePartList
            dataTaskDetail={openSparePartRequestItemDetail}
            onClose={() => setOpenSparePartRequestItemDetail(null)}
          />
        </ModalBody>
      </Modal>
      <Modal
        size="md"
        isOpen={!!modalOpenDelete}
        toggle={() => setModalOpenDelete(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenDelete(null)}>
          Delete Task Detail
        </ModalHeader>
        <ModalBody>
          <TaskDetailDelete
            initialValues={modalOpenDelete}
            onClose={() => setModalOpenDelete(null)}
            onReload={() => setIsGettingTaskDetail(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default TaskDetailList;

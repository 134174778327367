import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as yup from "yup";
import useForm from "../../../../../hooks/useForm";
import Select from "react-select";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import {
  MAINTENANCE_MECHANIC_TASK_LIST,
  MAINTENANCE_PROOF_INPUT,
  MAINTENANCE_PROOF_EDIT,
} from "../../../../../redux/config/storing.links";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import InputImage from "../../../../../components/InputImage/InputImage";
import { UPLOAD_IMAGE_FOR_EDIT } from "../../../../../redux/config/links";

const ProofAddEdit = ({
  onClose,
  onReload,
  initialValues = null,
  isEdit = false,
}) => {
  const [isGettingMechanicTaskList, setIsGettingMechanicTaskList] =
    useState(true);

  const [onGetMechanicTaskList, { loading, data: dataTaskList }] = useLazyFetch(
    {
      url: MAINTENANCE_MECHANIC_TASK_LIST,
      method: "POST",
    }
  );

  const [onInput] = useLazyFetch({
    url: MAINTENANCE_PROOF_INPUT,
    method: "POST",
  });

  const [onEdit] = useLazyFetch({
    url: MAINTENANCE_PROOF_EDIT,
    method: "POST",
  });

  const [onUpload] = useLazyFetch({
    url: UPLOAD_IMAGE_FOR_EDIT,
    method: "POST",
    type: "formdata",
  });

  const validation = () => {
    let selectYup = yup.object().shape({
      label: yup.string().required("Required"),
      value: yup.number().required("Required"),
    });
    return yup.object().shape({
      storing_maintenance_mechanic_task: selectYup.required(
        "Mechanic Task is Required"
      ),
      name: yup.string().required("Name Task is Required"),
      ...(isEdit ? {} : { photo: yup.mixed().required("Photo is required") }),
    });
  };

  const {
    handleSubmit,
    errors: error,
    values,
    handleReset,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues: {
      storing_maintenance_mechanic_task:
        !!initialValues && initialValues.id_storing_maintenance_mechanic_task
          ? {
              value: initialValues.id_storing_maintenance_mechanic_task,
              label: initialValues.storing_maintenance_mechanic_task_name,
            }
          : null,
      name: !!initialValues && initialValues.name ? initialValues.name : "",
      photo:
        !!initialValues && initialValues.photo_link
          ? initialValues.photo_link
          : null,
    },
    validation: validation(),
  });

  const handleChange = (e) => {
    setFieldValue(e.currentTarget.name, e.currentTarget.value);
  };

  const onSubmitAddEdit = (value, { setSubmitting }, response = null) => {
    if (!isEdit) {
      onInput(
        {
          body: {
            photo_link: !!response ? response.data.result.link : "",
            name: value.name,
            id_storing_maintenance_mechanic_task:
              value.storing_maintenance_mechanic_task.value,
          },
        },
        () => {
          setSubmitting(false);
          onReload();
          onClose();
        }
      );
    } else {
      onEdit(
        {
          body: {
            photo_link: !!response ? response.data.result.link : value.photo,
            name: value.name,
            id_storing_maintenance_mechanic_task:
              value.storing_maintenance_mechanic_task.value,
            id: initialValues.id,
          },
        },
        () => {
          setSubmitting(false);
          onReload();
          onClose();
        }
      );
    }
  };

  const onSubmit = (value, { setSubmitting }) => {
    if (typeof value.photo !== "string") {
      onUpload(
        {
          body: {
            file: value.photo,
            image_desc: "",
          },
        },
        (response) => {
          if (!!response) {
            onSubmitAddEdit(value, { setSubmitting }, response);
          }
        }
      );
    } else {
      onSubmitAddEdit(value, { setSubmitting }, null);
    }
  };

  useEffect(() => {
    if (isGettingMechanicTaskList) {
      setIsGettingMechanicTaskList(false);
      onGetMechanicTaskList({});
    }
  }, [isGettingMechanicTaskList, onGetMechanicTaskList]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Col md="3">Storing Maintenance Mechanic Task</Col>
        <Col xs="12" md="9">
          <LoadingContent
            loading={loading}
            onReload={() => setIsGettingMechanicTaskList(true)}
          >
            <div
              className={`${
                !!error.storing_maintenance_mechanic_task ? "is-invalid" : ""
              }`}
            >
              <Select
                value={values.storing_maintenance_mechanic_task}
                onChange={(selected) => {
                  setFieldValue("storing_maintenance_mechanic_task", selected);
                }}
                options={(!!dataTaskList ? dataTaskList.result : []).map(
                  (item) => ({ label: item.task_name, value: item.id })
                )}
                disabled={isSubmitting}
              />
            </div>
          </LoadingContent>
          <FormFeedback>{error.storing_maintenance_mechanic_task}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="3">
          <Label htmlFor="name">Name</Label>
        </Col>
        <Col xs="12" md="9">
          <Input
            type="text"
            autoComplete="off"
            placeholder="Name"
            name="name"
            id="name"
            onChange={handleChange}
            value={values.name}
            valid={!error.name}
            invalid={!!error.name}
          />
          <FormFeedback>{error.name}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="3">
          <Label htmlFor="photo">Photo</Label>
        </Col>
        <Col xs="12" md="9">
          <InputImage
            value={values.photo}
            onChange={(result) => setFieldValue("photo", result)}
            invalid={!!error.photo}
          />
          <FormFeedback>{error.photo}</FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup>
        <Button
          type="submit"
          color="primary"
          className="mr-1"
          disabled={isSubmitting || !isValid}
        >
          {isSubmitting ? "Wait..." : !isEdit ? "Save Changes" : "Edit"}
        </Button>
        <Button
          type="button"
          color="warning"
          className="mr-1"
          disabled={isSubmitting}
          onClick={() => onClose()}
        >
          Close
        </Button>
        <Button
          type="reset"
          color="danger"
          className="mr-1"
          disabled={isSubmitting}
          onClick={handleReset}
        >
          Reset
        </Button>
      </FormGroup>
    </form>
  );
};

export default ProofAddEdit;

import React from "react";
import {
  GoogleMap,
  KmlLayer,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";

import { compose } from "recompose";

function generateRandom() {
  return Math.random() * 10000000000000000;
}

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{
        lat: !props.lat ? -7.6221790314 : props.lat,
        lng: !props.lng ? 110.8043441772 : props.lng,
      }}
    >
      {/* {console.log(props.markers)} */}
      <KmlLayer
        url={props.kmlLink + "&ver=" + generateRandom()}
        options={{ preserveViewport: true }}
      />
      {/* {props.marker === []? */}

      <Marker
        key={props.value}
        //   onClick={onClick}
        //   options={{icon: customMarker}}
        position={{ lat: props.lat, lng: props.lng }}
      >
        {/* {props.selectedMarker === marker &&
              <InfoWindow>
                <div>
                  {marker.label}
                </div>
              </InfoWindow>
            } */}
      </Marker>
      {/* } */}
    </GoogleMap>
  );
});

export default MapWithAMarker;

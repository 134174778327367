import React, { Component } from "react";
import { Card, CardHeader, CardBody, Col, Row, Button } from "reactstrap";
import PropTypes from "prop-types";
// import moment from 'moment';

const propTypes = {
  company: PropTypes.string,
  do_number: PropTypes.string,
  do_desc: PropTypes.string,
  fleet_plate: PropTypes.string,
  max_tonnage: PropTypes.number,
  driver_name: PropTypes.string,
  driver_phone: PropTypes.string,
  security_name: PropTypes.string,
  driver_assistant_name: PropTypes.string,
  assigned_do_date: PropTypes.string,
  end_assigned_do_date: PropTypes.string,
  avoid_toll: PropTypes.number,
  status_delivery_detail: PropTypes.string,
  notes: PropTypes.string,
  created_on: PropTypes.string,
  other_fee: PropTypes.number,
  toll_fee: PropTypes.number,
  weight_fee: PropTypes.number,
  total_est_carbon: PropTypes.number,
  total_est_delivery_cost: PropTypes.number,
  link_lapor: PropTypes.string,
  each_item_weight: PropTypes.number,
};

const defaultProps = {
  company: "",
  do_number: "",
  do_desc: "",
  fleet_plate: "",
  max_tonnage: 0,
  driver_name: "",
  driver_phone: "",
  security_name: "",
  driver_assistant_name: "",
  assigned_do_date: "",
  end_assigned_do_date: "",
  avoid_toll: 0,
  status_delivery_detail: "",
  notes: "",
  created_on: "",
  other_fee: 0,
  toll_fee: 0,
  weight_fee: 0,
  total_est_carbon: 0,
  total_est_delivery_cost: 0,
  link_lapor: "",
  each_item_weight: 0,
};

class DeliveryOrdersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  curStringFormat = (_digit) => {
    return (
      "Rp. " +
      parseFloat(_digit)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };

  render() {
    const {
      company,
      do_number,
      do_desc,
      fleet_plate,
      max_tonnage,
      driver_name,
      driver_phone,
      security_name,
      driver_assistant_name,
      assigned_do_date,
      end_assigned_do_date,
      avoid_toll,
      status_delivery_detail,
      notes,
      created_on,
      other_fee,
      weight_fee,
      toll_fee,
      total_est_delivery_cost,
      total_est_carbon,
      link_lapor,
      each_item_weight,
    } = this.props;
    return (
      <div>
        <Card style={{ width: "-webkit-fill-available" }}>
          <CardHeader>
            DO Details for {do_number} from <strong>{company}</strong>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <h5>DO Basic Information</h5> <br />
                <dt>DO Number</dt>
                <dd>{do_number ? do_number : "-"}</dd>
                <dt>DO Description</dt>
                <dd style={{ textAlign: "justify" }}>
                  {do_desc ? do_desc : "-"}
                </dd>
                <dt>Fleet Plate</dt>
                <dd>{fleet_plate ? fleet_plate : "-"}</dd>
                <dt>Max Tonnage</dt>
                <dd>{max_tonnage ? max_tonnage + " Kg" : "-"}</dd>
                <dt>Avoid Toll</dt>
                <dd>{avoid_toll ? (avoid_toll > 0 ? "Yes" : "No") : "-"}</dd>
                <dt>Each Item Weight</dt>
                <dd>{each_item_weight ? each_item_weight : "-"}</dd>
                <dt>Created On</dt>
                <dd>{created_on ? created_on : "-"}</dd>
                <br />
              </Col>
              <Col md="6">
                <br />
                <h5>Person in Charge Details</h5> <br />
                <dt>Driver Name</dt>
                <dd>{driver_name ? driver_name : "-"}</dd>
                <dt>Driver Phone</dt>
                <dd>{driver_phone ? driver_phone : "-"}</dd>
                <dt>Driver Assistant Name</dt>
                <dd>{driver_assistant_name ? driver_assistant_name : "-"}</dd>
                <br />
                <br />
                <h5>Cost Estimation</h5>
                <dt>Toll Fee</dt>
                <dd>{toll_fee ? this.curStringFormat(toll_fee) : "-"}</dd>
                <dt>Weight Fee</dt>
                <dd>{weight_fee ? this.curStringFormat(weight_fee) : "-"}</dd>
                <dt>Other Fee Fee</dt>
                <dd>{other_fee ? this.curStringFormat(other_fee) : "-"}</dd>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <h5>Assignments</h5>
                <dt>Assigned DO Date</dt>
                <dd>{assigned_do_date ? assigned_do_date : "-"}</dd>
                <dt>End Assigned DO Date</dt>
                <dd>
                  {end_assigned_do_date ? end_assigned_do_date : "-"}
                </dd>{" "}
                <br />
                <br />
                <h5>Cost & Carbon Related</h5>
                <dt>Total Est. Delivery Cost</dt>
                <dd>
                  {total_est_delivery_cost
                    ? this.curStringFormat(total_est_delivery_cost)
                    : "-"}
                </dd>
                <dt>Total Est. Carbon</dt>
                <dd>
                  {total_est_carbon
                    ? total_est_carbon + " metric tons of CO2e"
                    : "-"}
                </dd>{" "}
                <br />
              </Col>
              <Col md="6">
                <h5>Delivery Status</h5>
                <dt>Status</dt>
                <dd>{status_delivery_detail ? status_delivery_detail : "-"}</dd>
                <dt>Security Name</dt>
                <dd>{security_name ? security_name : "-"}</dd>
                <dt>Proof Of Delivery</dt>
                <dd>
                  {link_lapor ? (
                    <Button
                      color="primary"
                      size="md"
                      style={{ color: "white" }}
                      onClick={() => {
                        this.openPOD(link_lapor);
                      }}
                    >
                      <i className="fa fa-envelope-open"></i> View/Update POD
                    </Button>
                  ) : (
                    "-"
                  )}
                </dd>
                <dt>Notes</dt>
                <dd style={{ textAlign: "justify" }}>{notes ? notes : "-"}</dd>
              </Col>
            </Row>
            <br />
            {/* {this.state.physicalSpecifications ? <div><dt>Dimension (Length x Width x Height)</dt><dd>{length} mm x {width} mm x {height} mm</dd>
                            <dt>Weight</dt><dd>{weight} gram</dd></div> : <div></div>} */}
          </CardBody>
        </Card>
      </div>
    );
  }

  openPOD = (link_lapor) => {
    const newWindow = window.open(link_lapor, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
}

DeliveryOrdersDetail.propTypes = propTypes;
DeliveryOrdersDetail.defaultProps = defaultProps;

export default DeliveryOrdersDetail;

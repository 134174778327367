import React, { Component } from 'react';
import {    
    Row,
    Col,
    Button,
    ButtonGroup
} from 'reactstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import AddMasterItemDetaillAdjustment from './Forms/AddMaintenanceBinding'
class MaintenanceBindingDatatable extends Component {

    constructor(props){
        super(props)
        this.state = {
            initialFormValues: {
                id_preventive_maintenance_setting : null,
                id_package_maintenance: null,
                last_value_maintenance: null,
            },
            editedRowId: null,
            deletedRowId: null,
            isEdited:false,
            isAdd:true,
            isDelete:false
        }
    }

    toggleOpenEdit = (cell, row) => {
        if(row.id === this.state.editedRowId){
            this.setState({
                editedRowId: null,
                deletedRowId: null,
                isEdited:false,
                isAdd:true,
                isDelete:false,
                initialFormValues: {
                    id_preventive_maintenance_setting : null,
                    id_package_maintenance: null,
                    last_value_maintenance: null,
                },
                id_package_maintenance_settings: null
            })
        }
        if(row.id !== this.state.editedRowId){
            this.setState({
                editedRowId: row.id,
                deletedRowId: null,
                isEdited:true,
                isAdd:false,
                isDelete:false,
                initialFormValues: {
                    id_preventive_maintenance_setting : row.id_preventive_maintenance_setting,
                    id_package_maintenance: row.id_package_maintenance,
                    last_value_maintenance: Number(row.last_value_maintenance),
                },
                id_package_maintenance_settings: row.id_package_maintenance_settings
            })
        }
    }

    toggleOpenDelete = (cell, row) => {
        if(row.id === this.state.deletedRowId){
            this.setState({
                editedRowId: null,
                deletedRowId: null,
                isEdited:false,
                isAdd:true,
                isDelete:false,
                initialFormValues: {
                    id_preventive_maintenance_setting : null,
                    id_package_maintenance: null,
                    last_value_maintenance: null
                },
                id_package_maintenance_settings: null
            })
        }
        if(row.id !== this.state.deletedRowId){
            this.setState({
                deletedRowId: row.id,
                editedRowId: null,
                isEdited:false,
                isAdd:false,
                isDelete:true,
                initialFormValues: {
                    id_preventive_maintenance_setting : null,
                    id_package_maintenance: null,
                    last_value_maintenance: null
                },
                id_package_maintenance_settings: row.id_package_maintenance_settings
            })
        }
    }

    actionFormatter = (cell, row) => {
        return (
            <React.Fragment>
                <ButtonGroup>
                    <Button className="btn btn-warning" color="warning" 
                            size="sm" onClick={() => this.toggleOpenEdit(cell, row)}>
                                <i className="fa fa-edit"></i>
                    </Button>
                    <Button className="btn btn-danger" color="danger" 
                            size="sm" onClick={() => this.toggleOpenDelete(cell, row)}>
                                <i className="fa fa-trash"></i>
                    </Button>
                </ButtonGroup> 
            </React.Fragment>   
        )
    }

    rowClassNameFormat = (row) => {
        if(!row)
            return
        if(row.id === this.state.editedRowId)
            return  {"backgroundColor":"#fff49b"}
        if(row.id === this.state.deletedRowId)
            return {"backgroundColor":"#F8AAAC"}
    }

    showDatatable = () => {
        let options = {
            noDataText: 'No brands registered. Try one-lah!',
            sizePerPageList: [{
                text: '10', value: 10
            },{
                text: '25', value: 25
            },{
                text: '50', value: 50
            }],
            sizePerPage: 20,
            btnGroup: this.customBtnGroup,
        };
        return (
            <BootstrapTable data={this.props.fleetsMaintenanceBinding} version="4" bordered={false} hover pagination 
                            search edit options={options} exportCSV={true} csvFileName={this.fileNameFormat} multiColumnSearch
                            trStyle={this.rowClassNameFormat}>
                <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" searchable={false} hidden>ID</TableHeaderColumn>
                <TableHeaderColumn dataField="package_code" searchable dataSort csvHeader="Package Code" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Package Maint.</TableHeaderColumn>
                <TableHeaderColumn dataField="package_preventive_maintenance_automate_name" searchable dataSort csvHeader="Iteration Desc." thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Iteration Desc.</TableHeaderColumn>
                <TableHeaderColumn dataField="every" searchable dataSort csvHeader="Every" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="30%">Maint. Every (km)</TableHeaderColumn>
                <TableHeaderColumn dataField="last_value_maintenance" searchable dataSort csvHeader="Last Maintenance km" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="25%">Last Maint. (km)</TableHeaderColumn>
                <TableHeaderColumn dataField="action" export={false} dataFormat={ this.actionFormatter.bind(this) } thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="30%">Action</TableHeaderColumn>
            </BootstrapTable>
        )
    }

    refreshTableData = () => {
        this.setState({
            editedRowId: null,
            deletedRowId: null,
            isEdited:false,
            isAdd:true,
            isDelete:false,
            initialFormValues: {
                id_preventive_maintenance_setting : null,
                id_package_maintenance: null,
                last_value_maintenance: null
            },
            id_package_maintenance_settings: null
        })
        this.props.refreshTable()
    }

    render() {
        const {initialFormValues, isAdd, isEdited, isDelete, editedRowId, deletedRowId, id_package_maintenance_settings} = this.state
        return (
            <React.Fragment>
                {
                    <Row>
                        <Col xs="12" md="12">
                            <AddMasterItemDetaillAdjustment initialValues={initialFormValues} 
                                isAdd={isAdd} isEdited={isEdited} isDelete={isDelete}
                                editedRowId={editedRowId} deletedRowId={deletedRowId} id_package_maintenance_settings={id_package_maintenance_settings}
                                selectedFleetId={this.props.selectedFleetId} refreshTable={this.refreshTableData}/>
                        </Col>
                        <Col xs="12" md="12">
                            <hr />
                            {this.showDatatable()}
                        </Col>
                    </Row>
                }
                {/* <ToastContainer position="bottom-right" autoClose={7500} style={{zIndex: 7000}}/> */}
            </React.Fragment>
        )
    }
}

export default MaintenanceBindingDatatable
import React from "react";
import { Button, Col, FormGroup, Label } from "reactstrap";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import { MAINTENANCE_MECHANIC_TASK_DELETE } from "../../../../../redux/config/storing.links";

const TaskDelete = ({
  initialValues,
  onClose = () => null,
  onReload = () => null,
}) => {
  const [deleteMechanic, { loading }] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_TASK_DELETE,
    method: "POST",
    type: "json",
  });
  return (
    <div className="container">
      <FormGroup row>
        <Col xs="12" md="12">
          <Label htmlFor="text-input">
            Do you want to delete this Task?{" "}
          </Label>
        </Col>
      </FormGroup>
      <LoadingContent loading={loading}>
        <div>
          <Button
            onClick={() => {
                deleteMechanic({ body: {id: initialValues.id} }, () => {
                onReload();
                onClose();
              });
            }}
            size="sm"
            color="danger"
            disabled={loading}
          >
            <i className="fa fa-trash"></i> Submit
          </Button>
          <Button
            onClick={() => onClose()}
            size="sm"
            color="success"
            disabled={loading}
          >
            <i className="fa fa-window-close"></i> Close
          </Button>
        </div>
      </LoadingContent>
    </div>
  );
};

export default TaskDelete;

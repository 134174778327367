import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import LoadingContent from "../../../../components/LoadingContent/LoadingContent";
import Select from "react-select";
import useLazyFetch from "../../../../hooks/useLazyFetch";
import {
  USER_ACCOUNT_BANK_CREATE,
  USER_ACCOUNT_BANK_UPDATE,
  USER_BANK_LIST,
} from "../../../../redux/config/links";
import * as yup from "yup";
import { toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import CustomToast from "../../../../components/CustomToast/CustomToast";

const WithdrawalSettingsAddEdit = ({
  initialValues = null,
  onClose = () => null,
  onReload = () => null,
}) => {
  const [isGettingBankList, setIsGettingBankList] = useState(true);

  const [getBankList, { loading: loadingGetBankList, data: dataBank }] =
    useLazyFetch({
      url: USER_BANK_LIST,
      method: "POST",
    });
  const [inputBankAccount] = useLazyFetch({
    url: USER_ACCOUNT_BANK_CREATE,
    method: "POST",
    type: "json",
  });
  const [editBankAccount] = useLazyFetch({
    url: USER_ACCOUNT_BANK_UPDATE,
    method: "POST",
    type: "json",
  });

  const validation = () => {
    return yup.object().shape({
      bank: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.number(),
        })
        .required("Bank is required"),
      branch_name: yup.string().required("Branch Name is required"),
      account_number: yup.number().required("Account Number is required"),
      account_holding_name: yup.string().required("Account Name is required"),
    });
  };

  const onSubmit = (values, { setSubmitting }) => {
    let dataValues = { ...values, id_bank: values.bank.value };

    delete dataValues.bank;

    if (!!!initialValues) {
      inputBankAccount({ body: dataValues }, (success) => {
        setSubmitting(false);
        if (success) {
          toast.success(
            <CustomToast text="Bank account added!" icon={<FaCheckCircle />} />,
            {
              autoClose: 3000,
            }
          );
          onReload();
          onClose();
        } else {
          toast.error(
            <CustomToast
              text="Something went wrong!"
              icon={<BiSolidError />}
            />,
            {
              autoClose: 3000,
            }
          );
        }
      });
    } else {
      editBankAccount(
        { body: { ...dataValues, id: initialValues.id } },
        (success) => {
          setSubmitting(false);
          if (success) {
            toast.success(
              <CustomToast
                text="Bank account added!"
                icon={<FaCheckCircle />}
              />,
              {
                autoClose: 3000,
              }
            );
            onReload();
            onClose();
          } else {
            toast.error(
              <CustomToast
                text="Something went wrong!"
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          }
        }
      );
    }
  };

  useEffect(() => {
    if (isGettingBankList) {
      setIsGettingBankList(false);
      getBankList();
    }
  }, [isGettingBankList, getBankList]);

  return (
    <>
      <Formik
        {...(initialValues !== null
          ? {
              initialValues: {
                bank: {
                  label: initialValues.bank_name,
                  value: initialValues.id_bank,
                },
                branch_name: initialValues.branch_name,
                account_number: initialValues.account_number,
                account_holding_name: initialValues.account_holding_name,
              },
            }
          : {})}
        onSubmit={onSubmit}
        validationSchema={validation()}
        render={({
          values,
          errors,
          handleSubmit,
          handleReset,
          isValid,
          isSubmitting,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="name">Bank</Label>
              </Col>
              <Col xs="12" md="9">
                <LoadingContent
                  loading={loadingGetBankList}
                  onReload={() => setIsGettingBankList(true)}
                >
                  <Select
                    value={values.bank}
                    onChange={(e) => setFieldValue("bank", e)}
                    options={
                      dataBank
                        ? dataBank.result.map((item) => ({
                            value: item.id,
                            label: item.bank_name,
                          }))
                        : []
                    }
                    disabled={isSubmitting}
                    placeholder={"Bank"}
                  />
                </LoadingContent>
                <FormFeedback>{errors.bank}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="branch_name">Branch Name</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="branch_name"
                  id="branch_name"
                  placeholder="Branch Name"
                  autoComplete="off"
                  valid={!errors.branch_name}
                  invalid={touched.branch_name && !!errors.branch_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.branch_name || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.branch_name}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="account_number">Account Number</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="account_number"
                  id="account_number"
                  placeholder="Account Number"
                  autoComplete="off"
                  valid={!errors.account_number}
                  invalid={touched.account_number && !!errors.account_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.account_number || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.account_number}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="name">Account Name</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="account_holding_name"
                  id="account_holding_name"
                  placeholder="Account Name"
                  autoComplete="off"
                  valid={!errors.account_holding_name}
                  invalid={
                    touched.account_holding_name && !!errors.account_name
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.account_holding_name || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.account_holding_name}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup>
              <Button
                type="submit"
                color="primary"
                className="mr-1"
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting
                  ? "Wait..."
                  : !!!initialValues
                  ? "Save Changes"
                  : "Edit"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={isSubmitting}
                onClick={() => onClose()}
              >
                Close
              </Button>
              <Button
                type="reset"
                color="danger"
                className="mr-1"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Reset
              </Button>
            </FormGroup>
          </form>
        )}
      />
    </>
  );
};

export default WithdrawalSettingsAddEdit;

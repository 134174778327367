import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ButtonGroup,
    Badge,
    Row, Col, Input, FormGroup, Label,
    Form
} from 'reactstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import AddEditPublicFacilityForm from './Forms/AddEditPublicFacilityForm'
import DeletePublicFacilityForm from './Forms/DeletePublicFacilityForm'
class PublicFacilityDatatable extends Component {

    constructor(props){
        super(props)
        this.state = {
            initialFormValues: {},
            initialDeleteValues : {},
            filterCheckbOxTracker: {
                spbu : true,
                bandara: true,
                pelabuhan : true,
                bank_atm: true,
                building_material_store: true
            }
        }
    }
    
    toggleOpenAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd,
            initialFormValues: {
                id : '',
                name : '',
                address : '',
                owner : {label:"Public Sector", value:1},
                id_type: {label:"SPBU", value:1},
                lat : '',
                lng : '',
                status : '',
                desc: ''
            }
        })
    }

    toggleCloseAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd,
            initialFormValues: {
                id : '',
                name : '',
                address : '',
                owner : '',
                id_type: '',
                lat : '',
                lng : '',
                status : '',
                desc: ''
            }
        })
    }

    toggleOpenDelete = (_row) => {
        this.setState({
            modalDelete: !this.state.modalDelete,
            initialDeleteValues: {
                id: _row.id,
                name : _row.name
            },
        })
    }

    toggleCloseDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete,
            initialFormValues: {
                id : '',
                name : '',
                id_type: '',
                address : '',
                owner : '',
                lat : '',
                lng : '',
                status : '',
                desc: ''
            },
        })
    }

    showDatatable = () => {
        let options = {
            noDataText: 'No public facility registered. Try one-lah!',
            sizePerPageList: [{
                text: '10', value: 10
            },{
                text: '25', value: 25
            },{
                text: '50', value: 50
            }],
            sizePerPage: 20,
            btnGroup: this.customBtnGroup,
        };
        return (
            <BootstrapTable data={this.props.data} version="4" bordered={false} hover pagination search edit options={options} exportCSV={true} csvFileName={this.fileNameFormat} multiColumnSearch>
                <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" searchable={false} hidden>ID</TableHeaderColumn>
                <TableHeaderColumn dataField="name" filter={ { type: 'TextFilter', delay: 1000 } } searchable dataSort csvHeader="Code" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%" dataFormat={this.placeNameFormatter}>Place Name</TableHeaderColumn>
                <TableHeaderColumn dataField="public_place_type" filter={ { type: 'TextFilter', delay: 1000 } } searchable dataSort csvHeader="Type" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Type</TableHeaderColumn>
                <TableHeaderColumn dataField="public_place_address" filter={ { type: 'TextFilter', delay: 1000 } } searchable dataSort csvHeader="Address" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Address</TableHeaderColumn>
                <TableHeaderColumn dataField="customer_name" filter={ { type: 'TextFilter', delay: 1000 } } searchable dataSort csvHeader="Owner" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Owner</TableHeaderColumn>
                <TableHeaderColumn dataField="lat" filter={ { type: 'NumberFilter', delay: 1000 } } searchable dataSort csvHeader="Latitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Latitude</TableHeaderColumn>
                <TableHeaderColumn dataField="lng" filter={ { type: 'NumberFilter', delay: 1000 } } searchable dataSort csvHeader="Longitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Longitude</TableHeaderColumn>
                <TableHeaderColumn dataField="action" export={false}  dataFormat={this.statusFormatter} thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="30%">Verification</TableHeaderColumn>
            </BootstrapTable>
        )
    }

    customBtnGroup = props => {
        return(
            <Row>
                <Col xs="2" md="2">
                    { props.exportCSVBtn }
                </Col>
                <Col xs="6" md="6" style={{"margin-top":"7px"}}>
                    <this.FiltersCheckbox />
                </Col>
            </Row>
        );
    }

    handleFiltersChange = (e) => {
        const {filterCheckbOxTracker} = this.state
        let copyOfCheckboXTracker = filterCheckbOxTracker
        copyOfCheckboXTracker[e.target.id] =  !filterCheckbOxTracker[e.target.id]
        let copyOfData = [...this.props.fullData]
        let result = []
        this.setState({
            filterCheckbOxTracker : copyOfCheckboXTracker
        }, () => {
            let counter = 0
            let types = ["SPBU", "Bandara", "Pelabuhan", "Bank dan ATM", "Building Material Store"]
            for (const property in filterCheckbOxTracker) {
                let isChecked = filterCheckbOxTracker[property]
                if(isChecked)
                    result.push(
                        // eslint-disable-next-line no-loop-func
                        ...copyOfData.filter(d => d.public_place_type === types[counter])
                    )
                if(!isChecked)
                    result.push(
                        // eslint-disable-next-line no-loop-func
                        ...result.filter(d => d.public_place_type === types[counter])
                    )
                counter+=1
            }
            this.props.onResultFilterChange(result)
        })
    }

    FiltersCheckbox = (props) => {
        let filters = [
            {
                id: "spbu",
                name: "spbu",
                label: "Gas Station",
            },
            {
                id: "bandara",
                name: "bandara",
                label: "Airport",
            },
            {
                id: "pelabuhan",
                name: "pelabuhan",
                label: "Harbour",
            },
            {
                id: "bank_atm",
                name: "bank_atm",
                label: "Bank dan ATM",
            },
            {
                id: "building_material_store",
                name: "building_material_store",
                label: "Building Material Store",
            },
        ]

        return  <Form>
                 {filters.map(filter => (
                    <FormGroup
                        check
                        inline
                    >
                        <Input type="checkbox" 
                                id={filter.id}
                                name={filter.name}
                                defaultChecked={true}
                                onChange={e => this.handleFiltersChange(e)}/>
                        <Label check>
                            {filter.label}
                        </Label>
                    </FormGroup>
                 ))}
            </Form>
    }


    onViewTableClick = (row) => {
        this.props.onViewTableClick(row)
    }

    actionFormatter = (cell, row) => {
        return  <React.Fragment>
                    <ButtonGroup>
                        <Button className="btn btn-primary" color="primary" 
                            size="sm" onClick={() => this.onViewTableClick(row)}>
                            <i className="fa fa-eye"></i>
                        </Button>
                    </ButtonGroup> 
              </React.Fragment>   
    }

    placeNameFormatter = (cell, row) => {
        return <div style={{cursor: "pointer"}} onClick={() => this.props.onCustomerNameClicked(row)}>{row.name}</div>
    }

    statusFormatter = (cell, row) => {
        if(row.public_place_type === "Building Material Store" && row.review_points !== null && row.reviews > -1 && row.review_points > -1){
                return <a href={row.review_url} target='_blank' rel="noopener noreferrer">{row.reviews} reviews ({row.review_points} stars)</a>
        }
        
        if(row.public_place_type !== "Building Material Store") {
            const AUTOMA_VERIFIED = 1
            switch(row.status){
                case AUTOMA_VERIFIED:
                    return <>
                        <Badge color="info">Automa Verified</Badge>
                    </>
                default:
                    return <>
                        <Badge color="warning">Not Verified</Badge>
                    </>
            }
        }

        return <></>

    }

    refreshTableData = () => {
        this.props.fetchPublicFacility()
        setTimeout(() => {
            this.props.onAfterInput(this.props.publicFacilityData)
        }, 150)
    }

    render() {
        const {initialFormValues, initialDeleteValues} = this.state
        return (
            <React.Fragment>
                {this.showDatatable()}
                <Modal isOpen={this.state.modalAdd} toggle={this.toggleOpenAdd} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={this.toggleCloseAdd}>Add Public Facility</ModalHeader>
                    <ModalBody>
                            <AddEditPublicFacilityForm isAdd={true} initialValues={initialFormValues} addFormClose={this.toggleCloseAdd} refreshTable={this.refreshTableData}/>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modalDelete} toggle={this.toggleOpenDelete} className={this.props.className}>
                <ModalHeader toggle={this.toggleCloseDelete}>Delete Public Facility({initialDeleteValues.name})</ModalHeader>
                <ModalBody>
                         <DeletePublicFacilityForm initialValues={initialDeleteValues} deleteFormClose={this.toggleCloseDelete} refreshTable={this.refreshTableData}/>
                </ModalBody>
                </Modal>
                {/* <ToastContainer position="bottom-right" autoClose={7500} style={{zIndex: 7000}}/> */}
            </React.Fragment>
        )
    }
}

export default PublicFacilityDatatable
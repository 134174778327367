import React from "react";
import { Input, FormFeedback } from "reactstrap";
import NumberFormat from "react-number-format";

const CustomNumberFormat = ({
  name,
  id,
  value,
  onBlur,
  onValueChange,
  disabled,
  thousandSeparator = true,
  prefix,
  valid,
  invalid,
  errorMessage,
  placeholder,
  ...rest
}) => {
  return (
    <>
      <NumberFormat
        customInput={Input}
        name={name}
        id={id}
        value={value}
        thousandSeparator={thousandSeparator}
        prefix={prefix}
        disabled={disabled}
        onBlur={onBlur}
        onValueChange={onValueChange}
        {...rest}
        valid={valid}
        invalid={invalid}
        placeholder={placeholder}
      />
      {invalid && <FormFeedback>{errorMessage}</FormFeedback>}
    </>
  );
};

export default CustomNumberFormat;

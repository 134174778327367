import useLazyFetch from "../../../../../hooks/useLazyFetch";
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { MAINTENANCE_MECHANIC_LIST } from "../../../../../redux/config/storing.links";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  bootstrapTableOption,
  fileNameFormat,
} from "../../../../../helpers/helper";
import MechanicAdd from "./MechanicAdd";
import TaskList from "./TaskList";
import MechanicDelete from "./MechanicDelete";
import SummaryWidgets from "../../../../Widgets/SummaryWidgets";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";

const MechanicHome = () => {
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  const [modalOpenDelete, setModalOpenDelete] = useState(null);
  const [openTask, setOpenTask] = useState(false);

  const [openAddMechanic, setOpenAddMechanic] = useState(false);
  const [gettingTripList, setGettingTripList] = useState(true);

  const [onGetTripList, { loading: loadingTripList, data }] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_LIST,
    method: "POST",
  });

  useEffect(() => {
    if (gettingTripList) {
      setGettingTripList(false);
      onGetTripList({});
    }
  }, [gettingTripList, onGetTripList]);
  return (
    <>
      <Row>
        <Col md={3} xs={12}>
          <SummaryWidgets
            header={(data ? data.result : []).length}
            mainText={"Total Maintenance Mechanicg"}
            icon={"fa fa-id-card"}
            color={"success"}
          />
        </Col>
      </Row>
      <Card>
        <CardHeader>Maintenance Mechanic</CardHeader>
        <CardBody>
          <LoadingContent
            loading={loadingTripList}
            onReload={() => setGettingTripList(true)}
          >
            <BootstrapTable
              data={data ? data.result : []}
              version="4"
              bordered={false}
              hover
              pagination
              search
              edit
              options={bootstrapTableOption({
                btnGroup: (props) => (
                  <div>
                    {props.exportCSVBtn}{" "}
                    <Button
                      color={"primary"}
                      onClick={() => setGettingTripList(true)}
                    >
                      <i className="fa fa-refresh"></i> Reload
                    </Button>{" "}
                    <Button
                      color={"primary"}
                      onClick={() => setOpenAddMechanic(true)}
                    >
                      <i className="fa fa-plus"></i> Add Mechanic
                    </Button>{" "}
                  </div>
                ),
              })}
              exportCSV={true}
              csvFileName={fileNameFormat({ name: "" })}
              multiColumnSearch
            >
              <TableHeaderColumn
                dataField="id"
                isKey
                dataSort
                csvHeader="ID"
                hidden
              >
                ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="mechanic_first_name"
                dataSort
                csvHeader="First Name"
                export={false}
              >
                First Name
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="mechanic_last_name"
                dataSort
                csvHeader="Last Name"
                export={false}
              >
                Last Name
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="created_on"
                dataSort
                csvHeader="Created At"
                export={false}
              >
                Created At
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="id"
                dataFormat={(_cell, row) => (
                  <>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        color="warning"
                        onClick={() => setModalOpenEdit({ ...row })}
                      >
                        <i className="fa fa-edit"></i> Edit
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => setModalOpenDelete({ ...row })}
                      >
                        <i className="fa fa-trash"></i> Delete
                      </Button>
                    </ButtonGroup>
                    <br />
                    <Button
                      size="sm"
                      color={"primary"}
                      onClick={() => setOpenTask(row)}
                    >
                      Task
                    </Button>
                  </>
                )}
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>
          </LoadingContent>
        </CardBody>
      </Card>

      <Modal
        size="xl"
        isOpen={!!openTask}
        toggle={() => setOpenTask(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenTask(null)}>Task List</ModalHeader>
        <ModalBody>
          <TaskList dataMechanic={openTask} onClose={() => setOpenTask(null)} />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!openAddMechanic}
        toggle={() => setOpenAddMechanic(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenAddMechanic(null)}>
          Add Mechanic
        </ModalHeader>
        <ModalBody>
          <MechanicAdd
            onClose={() => setOpenAddMechanic(null)}
            onReload={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>

      
      <Modal
        size="md"
        isOpen={!!modalOpenEdit}
        toggle={() => setModalOpenEdit(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(null)}>
          Edit Mechanic
        </ModalHeader>
        <ModalBody>
          <MechanicAdd
            initialValues={modalOpenEdit}
            isEdit
            onClose={() => setModalOpenEdit(null)}
            onReload={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenDelete}
        toggle={() => setModalOpenDelete(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenDelete(null)}>
          Delete Mechanic
        </ModalHeader>
        <ModalBody>
          <MechanicDelete
            initialValues={modalOpenDelete}
            onClose={() => setModalOpenDelete(null)}
            onReload={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default MechanicHome;

import React, { Component } from 'react'
import { Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { connect } from "react-redux";
import { BarLoader } from 'react-spinners';
import { fetchDriversAssistantTable } from "../../../redux/actions/transporter/aTransporter-DriversAssistantTable";
import DriversAssistantDatatable from './DriversAssistant-Datatable'
import DatePicker from "react-datepicker";
import moment from "moment";
import * as API_LINKS from '../../../redux/config/links';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import {toast}  from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SummaryWidgets from '../SavedPlaces/Widgets/SummaryWidgets'
import Dropzone from '../../../components/Dropzone/'
import { employmentStatusSelections } from '../../../constants/constants';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

class DriversAssistant extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isAdding: false,
            modalAdd: false,
            driverBirthDate: '',
            date: '',
            driverAssistantPhoto: [],
         };
    }

    toggleOpenAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd
        })
    }

    toggleCloseAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd,
            driverBirthDate: '',
            date: '',
        })
    }

    handleBirthDateChange = (_date) => {
        var convertedDate = moment(_date).format("YYYY-MM-DD")
        // console.log(convertedDate)
        this.setState({
            date: _date,
            driverBirthDate: convertedDate
        });
    }

    handleOnDrop = (result) => { //Dropzone

        if(result.length === 0) {

            this.setState({driverAssistantPhoto: []});

            return;

        }

        if(!result.passed){

            toast.error("Photo, " + result.reasonRejected)
        
        }
        
        this.setState({driverAssistantPhoto: result})

    }

    handleEmploymentStatus = (employmentStatus) => {
        this.setState({ employmentStatus });
    }

    viewAddDriverAssistant = () => {
        return (
            <div className="container animated fadeIn">
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Assistant Name</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="addDriverName" placeholder="John Doe" disabled={this.state.isAdding}/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Indentification Card Number</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="addIdentificationNumber" placeholder="21711xxxxxxxxxxx" disabled={this.state.isAdding}/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Employment Status</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Select
                            value={this.state.employmentStatus}
                            onChange={this.handleEmploymentStatus}
                            options={employmentStatusSelections}
                            disabled={this.state.isAdding}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">Birth Date</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={this.state.date}
                            onChange={this.handleBirthDateChange} 
                            placeholderText="Pick a birth date"
                            disabled={this.state.isAdding}/>
                    </Col>
                </FormGroup>  
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Assistant Phone</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="addDriverPhone" placeholder="628xxxxxxxx" disabled={this.state.isAdding}/>
                    </Col>
                </FormGroup>
                {
                    this.state.employmentStatus ? 
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Daily Rate</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <NumberFormat value={this.state.daily_rate} thousandSeparator={true} disabled={this.state.isAdding} prefix={'Rp. '} onValueChange={(values) => {
                                // eslint-disable-next-line
                                const {formattedValue, value} = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                this.setState({daily_rate: value})
                            }}/>
                        </Col>
                    </FormGroup>
                    :
                    true
                }
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Photo</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Dropzone maxFile={1} 
                                  maxSize={1000000}
                                  accept="image/png, image/jpeg" 
                                  onDrop={this.handleOnDrop}
                                  preview={true}>
                                  </Dropzone>
                    </Col>
                </FormGroup>  
                {this.state.isAdding ? <center><BarLoader color={'#123abc'} 
                  loading={this.state.isAdding} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => this.handleAdd()} size="sm" color="primary" disabled={this.state.isAdding}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                <Button onClick={() => this.toggleCloseAdd()} size="sm" color="success" disabled={this.state.isAdding}><i className="fa fa-window-close"></i> Close</Button></div>}
            </div>
        )
    }

    handleAdd = () => {
        this.setState({ isAdding: true })
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;

        if(this.props.totalDriverAst >= this.props.maxTotalDriverAst) {
            return toast.error('Upgrade your subscription to insert another driver assistant!');
        }

        //Variables for text input
        var _assistantName = document.querySelector('#addDriverName').value;
        var _identificationNumber = document.querySelector('#addIdentificationNumber').value;
        var _assistantBirthDate = self.state.driverBirthDate;
        var _assistantPhone = document.querySelector('#addDriverPhone').value;

        const {driverAssistantPhoto} = this.state;

       if(_assistantName === "" || _assistantName === null) {
            self.setState({isAdding: false})
            toast.error("Name is invalid!")       
         } else if(driverAssistantPhoto.length === 0){
            self.setState({isAdding: false})
            toast.error("Driver Photo cannot empty!")
        } else if(!driverAssistantPhoto.passed){
            self.setState({isAdding: false})
        } else {

            let formData = new FormData();

            formData.append('name',_assistantName);
            formData.append('ktp', _identificationNumber)
            formData.append('telp',_assistantPhone);
            formData.append('birth_date',_assistantBirthDate);
            formData.append('file',driverAssistantPhoto.uploadedFiles[0]);

            axios({
                method:'post',
                url: API_LINKS.DRIVER_ASSISTANT_ADD_URL, //Please change when it's ready
                headers: {
                    Authorization: auth,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            })
            .then((response) => {
                if(response.status === 200) {
                    self.setState({
                        isAdding: false,
                        driverBirthDate: '',
                        date: '',
                    })
                    if (response.data.status === "success") {
                        toast.success('Data saved!')
                        self.toggleCloseAdd()
                        self.props.fetchDriversAssistantTable()
                    }
                }
            })
            .catch(function(error){
                if(error.response.status === 401) {
                    self.setState({
                        isAdding: false
                    })
                    return toast.error("Authorization failed! Please logout and login again to gain authentication")
                } else if (error.response.status === 404 || error.response.status === 500) {
                    self.setState({
                        isAdding: false
                    })
                    return toast.error("Fatal error! Please contact your local administrator!")
                } else {
                    self.setState({
                        isAdding: false
                    })
                    return toast.error("Unknown error! Please contact your local administrator!")
                }
            })
        }
    }

    render() {
        const {totalDriverAst} = this.props;
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col md={3} xs={12}>
                        <SummaryWidgets header={totalDriverAst} mainText={'Total Assistant(s)'} icon={'fa fa-users'} color={'primary'}/>
                    </Col>
                    {/* <Col md={3} xs={12}>
                        <SummaryWidgets header={approvedPlace} mainText={'Approved Location(s)'} icon={'fa fa-check'} color={'success'}/>
                    </Col>
                    <Col md={3} xs={12}>
                        <SummaryWidgets header={notApprovedPlace} mainText={'Not Approved Location(s)'} icon={'fa fa-ban'} color={'warning'}/>
                    </Col>
                    <Col md={3} xs={12}>
                        <SummaryWidgets header={deactivatedPlace} mainText={'Deactivated Location(s)'} icon={'fa fa-trash'} color={'danger'}/>
                    </Col> */}
                </Row>
            <Card>
            
            <CardHeader>Drivers Assistant List</CardHeader>
              <CardBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "1rem",
                  }}
                >
                  {this.props.successDriverAssistantFetch &&
                  !(
                    this.props.totalDriverAst >= this.props.maxTotalDriverAst
                  ) ? (
                    <div>
                      <Button
                        onClick={this.toggleOpenAdd}
                        size="md"
                        color="success"
                        disabled={this.props.inProgress}
                      >
                        <i className="fa fa-plus"></i> Add Driver Assistant
                      </Button>
                      &nbsp;&nbsp;
                    </div>
                  ) : (
                    true
                  )}
                </div>
                <DriversAssistantDatatable />
              </CardBody>

                {/* Modal for Adding Cards */}
                <Modal isOpen={this.state.modalAdd} toggle={this.toggleOpenAdd} className={this.props.className}>
                    <ModalHeader toggle={this.toggleOpenAdd}>Add Driver Assistant</ModalHeader>
                    <ModalBody>
                            {this.viewAddDriverAssistant()}
                    </ModalBody>
                    {/* <ToastContainer position="bottom-right" autoClose={7500} style={toastStyle}/> */}
                </Modal>
            </Card>
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        driversAssistantTableData: state.driversAssistantTableStore.driversAssistantTableData,
        totalDriverAst: state.driversAssistantTableStore.totalDriverAst,
        inProgress: state.driversAssistantTableStore.inProgress,
        successDriverAssistantFetch: state.driversAssistantTableStore.success,

        maxTotalDriverAst: state.authStore.subs.total_driver_ass
      }
}

export default connect(mapStateToProps, {fetchDriversAssistantTable})(DriversAssistant);
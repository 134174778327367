import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Row
} from 'reactstrap'
import ManagementDatatable from './ManagementDatatable'
import ManagementAddUser from './ManagementAddUser.jsx'
import { ToastContainer } from 'react-toastify';
import { AbilityContext} from '../../../casl/Can';

class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    checkRoles = () => {

        return (
            <React.Fragment>
            {this.context.can("view", "management_add_user@management") &&
            <div>
                <ManagementAddUser />
            </div>
            }
            </React.Fragment>
        )

    }

    render() {
        const isLevelValid = 
                this.context.can("view", "card@management") ? true : false;
        // eslint-disable-next-line
        if (isLevelValid) {
            return (
                <div className="animated fadeIn">
                    <ToastContainer position={"bottom-right"}/>
                    <Card>
                        <CardHeader>
                            User Management List
                        </CardHeader>
                        <CardBody>
                            <Row className="pull-right">
                                {this.checkRoles()}
                                {/* <Button onClick={this.handleRefreshData} size="sm" color="info" disabled={this.props.inProgressUserList}><i className="fa fa-refresh"></i> Refresh Data</Button>&nbsp; &nbsp; */}
                            </Row>
                            <br /><br /><br /><br />
                            <ManagementDatatable />
                        </CardBody>
                    </Card>
                </div>
            );
        } else {
            return (
                <div className="animated fadeIn">
                    {/* <ToastContainer position={"bottom-right"}/> */}
                    <Card>
                        <CardHeader>
                            User Management
                        </CardHeader>
                        <CardBody>
                            You have no authorization to access the data!
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }
}

UserManagement.contextType = AbilityContext;
export default UserManagement;
import React, { Component } from 'react';
import {Card, Table, Button, Badge} from 'reactstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {Link} from 'react-router-dom';
import 'react-day-picker/lib/style.css';
import { connect } from "react-redux";
import CheckInLog from './Report-CheckInLog';
import { BarLoader } from 'react-spinners';
import moment from 'moment';
import { fetchDeliveryCheckInStatus } from '../../../redux/actions/transporter/aTransporter-DeliveryCheckInStatus.jsx'

class DeliveryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryDetails: this.props.deliveryDetails
        };
    }

    dateFormatter = (_data) => {
        var date = moment(_data).format("DD-MMM-YYYY HH:mm:ss");
        return date
    }

    render() {
        return(
            <div className="fleet-mondtl-container">
                <div className="report-dodetails-table">
                    <Table responsive borderless>
                        <tbody>
                            <tr>
                                <td><h5>Delivery Order Number</h5></td>
                                {/* <td>&nbsp;</td> */}
                                <td><h5>{this.state.deliveryDetails.do_number}</h5></td>
                            </tr>
                            <tr>
                                <td><h5>Delivery Order Desc</h5></td>
                                {/* <td>&nbsp;</td> */}
                                <td><h5>{this.state.deliveryDetails.do_desc}</h5></td>
                            </tr>
                            <tr>
                                <td><h5>DO Assigned Date</h5></td>
                                {/* <td>&nbsp;</td> */}
                                <td><h5>{this.dateFormatter(this.state.deliveryDetails.do_assigned_date)}</h5></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div className="report-dodetails-table">
                    <Table responsive borderless>
                        <tbody>
                            <tr>
                                <td><h5>Driver</h5></td>
                                <td>&nbsp;</td>
                                <td><h5>{this.state.deliveryDetails.driver_name}</h5></td>
                            </tr>
                            <tr>
                                <td><h5>Driver Assistant</h5></td>
                                <td>&nbsp;</td>
                                <td><h5>{this.state.deliveryDetails.driver_assistant_name}</h5></td>
                            </tr>
                            <tr>
                                <td><h5>Fleet Plate</h5></td>
                                <td>&nbsp;</td>
                                <td><h5>{this.state.deliveryDetails.fleet_plate}</h5></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
}


class ReportDeliveryOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveryDetails: this.props.location.state
        };
    }

    UNSAFE_componentWillMount = () => {
        // console.log(this.props.location.state)
        this.props.fetchDeliveryCheckInStatus(this.props.match.params.id)
    }

    renderCheckInStatus = () => {
        if(this.props.deliveryCheckInStatusData !== "" || this.props.deliveryCheckInStatusData !== [] || this.props.deliveryCheckInStatusData !== null || this.props.deliveryCheckInStatusData !== undefined){
            return(
                <div className="fleet-mondtl-container">
                    <div className="report-dodetails-table">
                        <Table responsive borderless>
                            <tbody>
                                <tr>
                                    <td><Badge color="success"><h5>Check In Result: {this.props.deliveryCheckInStatusData}</h5></Badge></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            )
        }
    }

    render() {
        // const { selectedDay } = this.state;
        return (
            <div className="animated fadeIn">
                <div>
                <Link to="/transporter/delivery-order"><Button className="bg-info"><i className="fa fa-angle-left"></i> Back</Button></Link><br/><br/>
                </div>

                <Card className="fleet-mondtl-card">
                    <h2>Delivery Order Report</h2>
                    <hr/>
                    <DeliveryDetails deliveryDetails={this.state.deliveryDetails}/>
                    <hr/>
                    {this.props.inProgress ? <center><BarLoader color={'#123abc'} 
                        loading={this.props.inProgress} 
                        /> <br /> Loading.... Please wait...</center> : this.renderCheckInStatus()}
                    <CheckInLog id={this.props.match.params.id}/>
                </Card>
            </div>    
        );
    }
}

const mapStateToProps = (state) => {
    return {
        deliveryCheckInStatusData: state.deliveryCheckInStatusStore.deliveryCheckInStatusData,
        inProgress: state.deliveryCheckInStatusStore.inProgress,
      }
}

export default connect(mapStateToProps, {fetchDeliveryCheckInStatus}) (ReportDeliveryOrders)
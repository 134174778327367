import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Alert, Button, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import { BarLoader } from 'react-spinners';
import WorkOrderAddHomeWO from './WorkOrderAddHomeWO';
import WorkOrderAddWaypoints from './WorkOrderAddWaypoints';
import WorkOrderAddDeparture from './WorkOrderAddDeparture';
import WorkOrderAdd3PLWO from './WorkOrderAdd3PLWO';
import WorkOrderMaps from './WorkOrderMaps';
import SummaryWidgets from '../SavedPlaces/Widgets/SummaryWidgets';
import WorkOrderSaveButton from './WorkOrderSaveButton';
import WorkOrderWidget from './WorkOrderWidget'
import WorkOrdersPreviewOptimize from './WorkOrdersPreviewOptimize'
import { Link } from 'react-router-dom';
import { AbilityContext} from '../../../casl/Can';

// Redux Actions
import { fetchSettingList } from '../../../redux/actions/aUser-SettingList'
import { fetchWorkOrdersTable } from "../../../redux/actions/transporter/aTransporter-WorkOrdersTable.jsx";
import { fetchSavedPlacesTable } from "../../../redux/actions/transporter/aTransporter-SavedPlacesTable.jsx";
import { fetchWorkOrdersStockerInboundItemByWoTable } from '../../../redux/actions/transporter/aTransporter-WoStocker'
import { fetchWorkOrdersStockerOutboundItemByWoTable } from '../../../redux/actions/transporter/aTransporter-WoStocker'
import DeliveryCostEstimation from './DeliveryCostEstimation/DeliveryCostEstimation';
import WorkOrderAddStockerOutbound from './WorkOrderAddStockerOutbound';

class WorkOrdersNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            woPoints: [],
            pointDistance : [],
            woTrips: [],
            deliveryPrice : 0,

            canLoadWoTableData : true,
            canLoadWoPoints : true,
            canCalculateDeliveryPrice : true,
            settingListData: [],
            doSettings: {
                delivery_order_family_type: null,
                avoid_toll: null
            }
        };
    }

    UNSAFE_componentWillMount = () => {
        if(!this.props.location.state){
            window.location.href = "/"
        }
        this.props.fetchSavedPlacesTable()
        this.props.fetchWorkOrdersTable(this.props.match.params.id)
        this.props.fetchSettingList()
    }

    componentDidMount() {
        console.log(this.props)
        const {delivery_order_family_type, avoid_toll} = this.props.location.state;
        this.setState({
            doSettings: {
                delivery_order_family_type,
                avoid_toll
            }
        })
    }

    async componentDidUpdate(prevProps){
        const {workOrdersTableData, settingListData} = this.props
        if(prevProps.workOrdersTableData !== workOrdersTableData){
            await this.props.fetchWorkOrdersStockerInboundItemByWoTable(workOrdersTableData)
            await this.props.fetchWorkOrdersStockerOutboundItemByWoTable(workOrdersTableData)
        }
        if(prevProps.settingListData !== settingListData){
            this.setState({
                settingListData: settingListData
            })
        }
    }

    checkLevel = () => {
        var isLevelValid = 
                this.context.can("view", "work_orders_new_settings@work_orders_new") ? true : false;
        
        return isLevelValid;
    }

    isStockerWoBindingStatusSettingOn = () => {
        const {settingListData} = this.state
        const STOCKER_WO_BINDING_SETTING_ID = "Stocker on WO Binding"
        const STOCKER_WO_BINDING_SETTING_ON = 1
        if(!settingListData.result)
            return false
        let getData = settingListData.result.filter(sld => 
            sld.setting === STOCKER_WO_BINDING_SETTING_ID
        )
        let getStatus = getData[0]? getData[0].status : -1
        if(getStatus === STOCKER_WO_BINDING_SETTING_ON)
            return true
        return false
    }

    DOSettingsBadge = () => {
        const {delivery_order_family_type, avoid_toll} = this.props.location.state;
        const bagdes = []
        const badgeStyle = {"marginRight": "5px"}
        if(delivery_order_family_type === 1)
            bagdes.push(<Badge color="success" style={badgeStyle}>Family Checkin</Badge>)
        if(avoid_toll === 1)
            bagdes.push(<Badge color="danger" style={badgeStyle}>Avoid Tolls</Badge>)
        return bagdes
    }

    render() {
        const { boolDeparture, boolHome, boolDraft, totalWO, workOrdersTableData, woInboundData, woOutboundData} = this.props;
        const { do_number, driver_name, driver_assistant_name, driver_photo_link, driver_assistant_photo_link, 
            other_fee, weight_fee, toll_fee, total_est_carbon, total_est_delivery_cost, fleet_plate, do_assigned_date} = this.props.location.state;
        const {settingListData, doSettings} = this.state
        return (
            <div className="animated fadeIn">
                <Link to={'/transporter/delivery-order'}><Button color="primary" size="md"><i className="fa fa-arrow-left"></i> Back</Button></Link> &nbsp;&nbsp;
                <Button color="success" size="md" onClick={() => this.props.fetchWorkOrdersTable(this.props.match.params.id)} disabled={this.props.inProgress}><i className="fa fa-refresh"></i> Refresh</Button>
                <hr/>
                <Row>
                    <Col md={3} xs={12}>
                        <SummaryWidgets header={do_number} mainText={'Delivery Order Number'} icon={'fa fa-envelope-open-o'} color={'primary'}/>
                    </Col>
                    <Col md={3} xs={12}>
                        <SummaryWidgets header={(boolDraft ? 'Draft' : 'Saved')} mainText={'Work Order Status'} icon={(boolDraft ? 'fa fa-close' :'fa fa-check')} color={(boolDraft ? 'warning' : 'success')}/>
                    </Col>
                    <Col md={2} xs={6}>
                        <SummaryWidgets header={driver_name} mainText={'Driver'} imageLink={driver_photo_link} color={'primary'}/>
                    </Col>
                    <Col md={2} xs={6}>
                        <SummaryWidgets header={driver_assistant_name} mainText={'Driver Assistant'} imageLink={driver_assistant_photo_link} color={'primary'}/>
                    </Col>
                    <Col md={2} xs={6}>
                        <SummaryWidgets header={totalWO} mainText={'Total Work Order(s)'} icon={'fa fa-clone'} color={'primary'}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Card>
                            <CardBody className="container">
                                <div className="h5 mb-0" >Settings </div>
                                <div className="text-muted text-uppercase font-xs mb-3">Work Order Settings </div>
                                <div className="h5 mb-3"><this.DOSettingsBadge /></div>
                                {
                                    boolDraft ? 
                                    <Row className="container mt-3">
                                        <Alert color="primary">
                                            You still can edit the work order. If you wish to lock and start your delivery, please press save.
                                        </Alert>
                                    </Row>
                                    :
                                    <Row className="container mt-3">
                                        <Alert color="danger">
                                            Your work order has been locked. You cannot change or delete any configurations.
                                        </Alert>
                                    </Row>
                                }
                                {
                                    this.props.inProgress || settingListData.length === 0 ?
                                    <center><BarLoader color={'#123abc'} loading={this.props.inProgress || settingListData.length === 0}/> <br /> Loading.... Please wait...</center>
                                    : 
                                    (
                                        this.props.successWorkOrdersFetch ? 
                                        (
                                            boolDraft && (this.checkLevel()) ? 
                                            <Row className="container mt-3">
                                                {
                                                    boolDeparture ? 
                                                    true
                                                    :
                                                    <WorkOrderAddDeparture id_do={this.props.match.params.id} updateWO={this.props.fetchWorkOrdersTable} doSetting={doSettings}/>
                                                }
                                                
                                                {this.isStockerWoBindingStatusSettingOn()?
                                                    null
                                                    : 
                                                    <WorkOrderAddWaypoints id_do={this.props.match.params.id} updateWO={this.props.fetchWorkOrdersTable} lastSeq={this.props.lastSeq} doSetting={doSettings}/>
                                                }                       
                                                <WorkOrderAdd3PLWO id_do={this.props.match.params.id} updateWO={this.props.fetchWorkOrdersTable} lastSeq={this.props.lastSeq}/>
                                                <WorkOrderAddStockerOutbound id_do={this.props.match.params.id} updateWO={this.props.fetchWorkOrdersTable} lastSeq={this.props.lastSeq} doSetting={doSettings}/>
                                                {
                                                    boolHome ? 
                                                    true
                                                    :
                                                    <WorkOrderAddHomeWO id_do={this.props.match.params.id} updateWO={this.props.fetchWorkOrdersTable} doSetting={doSettings}/>
                                                }
                                            </Row>
                                            :
                                            true
                                        )
                                        : 
                                        <center>Error fetching your data... <br/><br/> 
                                            <Button className="btn btn-primary" color="primary" size="md" disabled={this.props.inProgress} onClick={() => this.props.fetchWorkOrdersTable(this.props.match.params.id)}>
                                                <i className="fa fa-refresh"></i> 
                                                Try again
                                            </Button>
                                        </center>
                                    )
                                }
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div className="h5 mb-0">Work Orders</div>
                                <div className="text-muted text-uppercase font-xs">Work Order List</div>
                                <div className="mt-3">
                                    {
                                        this.props.inProgress && woInboundData && woOutboundData? 
                                        <center><BarLoader color={'#123abc'} loading={this.props.inProgress && woInboundData && woOutboundData}/> <br /> Loading.... Please wait...</center>
                                        : 
                                        (
                                            this.props.successWorkOrdersFetch ? 
                                            // <WorkOrderList workOrderData={this.props.workOrdersTableData} />
                                            <div> 
                                            {workOrdersTableData.map((data, i)=>{
                                              return (
                                                    <WorkOrderWidget 
                                                        draftStatus={boolDraft}
                                                        key={data.id}
                                                        do_number={do_number}
                                                        driver_name={driver_name}
                                                        fleet_plate={fleet_plate}
                                                        do_assigned_date={do_assigned_date}
                                                        company={data.company}
                                                        company_phone={data.company_phone}
                                                        id_do={this.props.match.params.id}
                                                        id={data.id}
                                                        wo_number={data.wo_number}
                                                        wo_seq={data.wo_seq}
                                                        wo_desc={data.wo_desc}
                                                        wo_destination_name={data.wo_destination_name}
                                                        wo_address={data.wo_address}
                                                        pic_name={data.pic_name}
                                                        pic_phone={data.pic_phone}
                                                        loc_name={data.loc_name}
                                                        lat={data.lat}
                                                        lng={data.lng}
                                                        dept_lat={data.dept_lat}
                                                        dept_lng={data.dept_lng}
                                                        est_departure_time={data.est_departure_time}
                                                        est_arrival_time={data.est_arrival_time}
                                                        dept_time={data.dept_time}
                                                        arrival_time={data.arrival_time}
                                                        cargo_weight={data.cargo_weight}
                                                        // loading_time={data.loadingTime}
                                                        // check_in={data.timeCheckIn}
                                                        // check_out={data.timeCheckOut}
                                                        //distance={data.distance}
                                                        // share={data.share}
                                                        customer_approval={data.status_customer_approval !== null ? true : false}
                                                        status_customer_approval_detail={data.status_customer_approval_detail}
                                                        customer_approval_name={data.customer_approval_name}
                                                        customer_approval_remarks={data.customer_approval_remarks}
                                                        customer_approval_time={data.customer_approval_time}
                                                        main_customer={data.main_customer}
                                                        main_customer_phone={data.main_customer_phone}
                                                        id_security={data.id_security}
                                                        security_name={data.security_name}
                                                        status_delivery={data.status_delivery}
                                                        status_delivery_detail={data.status_delivery_detail}
                                                        notes={data.notes}
                                                        approval_time={data.approval_time}
                                                        updateWO={this.props.fetchWorkOrdersTable}
                                                        est_stop_time={data.est_stop_time}
                                                        break_time={data.break_time}
                                                        other_fee={data.other_fee}
                                                        weight_fee={data.weight_fee}
                                                        toll_fee={data.toll_fee}
                                                        boolSecurity={this.props.boolSecurity > 0 ? true : false}
                                                        price_delivery_tkm={data.price_delivery_tkm}
                                                        driver_notes={data.driver_notes}
                                                        est_carbon={data.est_carbon}
                                                        est_cost={data.est_cost}
                                                        invoice_id={data.invoice_id}
                                                        id_bid={data.id_bid}
                                                        id_stocker_outbound_saved={data.id_stocker_outbound_saved}
                                                        id_stocker_inbound_saved={data.id_inbound_stocker}
                                                        id_request_wo_3pl={data.id_request_wo_3pl}
                                                        company_requestor={data.company_requestor}
                                                        non_company_requestor_name={data.non_company_requestor_name}
                                                        outboundItem={woOutboundData[data.id_stocker_outbound_saved]? woOutboundData[data.id_stocker_outbound_saved] :[]}
                                                        inboundItem={woInboundData[data.id_inbound_stocker]? woInboundData[data.id_inbound_stocker]: []}
                                                        doSettings={doSettings}
                                                    />     
                                                ) 
                                            }) } 
                                            </div>
                                            : 
                                            <center>Error fetching your data... <br/><br/> 
                                                <Button className="btn btn-primary" color="primary" size="md" disabled={this.props.inProgress} onClick={() => this.props.fetchWorkOrdersTable(this.props.match.params.id)}>
                                                    <i className="fa fa-refresh"></i> 
                                                    Try again
                                                </Button>
                                            </center>
                                        )
                                    }

                                {
                                    this.props.inProgress ? 
                                    <center><BarLoader color={'#123abc'} loading={this.props.inProgress}/> <br /> Loading.... Please wait...</center>
                                    : 
                                    (
                                        this.props.successWorkOrdersFetch ? 
                                        (
                                            boolDraft && (this.checkLevel())? 
                                            <Row className="pull-right">
                                                <WorkOrdersPreviewOptimize id_do={this.props.match.params.id} updateWO={this.props.fetchWorkOrdersTable} loading_state={this.props.inProgress} totalWO={this.props.totalWO} name={'Check Optimization'} optimize={true}/> &nbsp;&nbsp;
                                                <WorkOrdersPreviewOptimize id_do={this.props.match.params.id} updateWO={this.props.fetchWorkOrdersTable} loading_state={this.props.inProgress} totalWO={this.props.totalWO} name={'Check Routes'} optimize={false}/> &nbsp;&nbsp;
                                                <WorkOrderSaveButton id_do={this.props.match.params.id} updateWO={this.props.fetchWorkOrdersTable} loading_state={this.props.inProgress} totalWO={this.props.totalWO} name={'Save'} optimize={false}/> &nbsp;&nbsp;
                                                <WorkOrderSaveButton id_do={this.props.match.params.id} updateWO={this.props.fetchWorkOrdersTable} loading_state={this.props.inProgress} totalWO={this.props.totalWO} name={'Save & Optimize'} optimize={true}/>&nbsp;&nbsp;&nbsp;&nbsp;
                                            </Row>
                                            :
                                            true
                                        )
                                        : 
                                        true
                                    )
                                }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <DeliveryCostEstimation 
                            other_fee={other_fee}
                            toll_fee={toll_fee}
                            weight_fee={weight_fee}
                            total_est_carbon={total_est_carbon}
                            total_est_delivery_cost={total_est_delivery_cost}
                        />
                        <Card>
                            <CardBody>
                                <div className="h5 mb-0">Map Plotting</div>
                                <div className="text-muted text-uppercase font-xs">Your work orders will be shown on the maps</div>
                                <div className="mt-3 mb-3">
                                {
                                    this.props.inProgress ? 
                                    <center><BarLoader color={'#123abc'} loading={this.props.inProgress}/> <br /> Loading.... Please wait...</center>
                                    : 
                                    (
                                        this.props.successWorkOrdersFetch ? 
                                        <WorkOrderMaps woData={this.props.woMarker}/>
                                        :
                                        <center>Error fetching your data... <br/><br/> 
                                            <Button className="btn btn-primary" color="primary" size="md" disabled={this.props.inProgress} onClick={() => this.props.fetchWorkOrdersTable(this.props.match.params.id)}>
                                                <i className="fa fa-refresh"></i> 
                                                Try again
                                            </Button>
                                        </center>
                                    )
                                }  
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        workOrdersTableData: state.workOrdersTableStore.workOrdersTableData,
        inProgress: state.workOrdersTableStore.inProgress,
        boolHome: state.workOrdersTableStore.boolHome,
        boolDraft: state.workOrdersTableStore.boolDraft,
        boolDeparture: state.workOrdersTableStore.boolDeparture,
        totalWO: state.workOrdersTableStore.totalWO,
        lastSeq: state.workOrdersTableStore.lastSeq,
        woMarker: state.workOrdersTableStore.woMarker,
        successWorkOrdersFetch: state.workOrdersTableStore.success,

        kml: state.companyStore.companyKML,
        statKML: state.settingStore.kmlSet,

        boolSecurity: state.authStore.subs.security_approval,

        woInboundData: state.woInboundItemDetailStore.woInboundData,
        woOutboundData: state.woOtuboundItemDetailStore.woOutboundData ,

        settingListData: state.settingStore.settingListData,
      }
}

WorkOrdersNew.contextType = AbilityContext;
export default connect(mapStateToProps, {fetchWorkOrdersTable, fetchSavedPlacesTable,
    fetchWorkOrdersStockerInboundItemByWoTable,
    fetchWorkOrdersStockerOutboundItemByWoTable, fetchSettingList})(WorkOrdersNew);
import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  Label,
  FormGroup,
  Col,
  ModalHeader,
  Badge,
  Form,
  Input,
  Row,
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import { toast } from "react-toastify";
import * as API_LINKS from "../../../redux/config/links";
import axios from "axios";
import { fetchSavedPlacesNear } from "../../../redux/actions/transporter/aTransporter-SavedPlacesTable";
import { fetchMainCustomerList } from "../../../redux/actions/transporter/aTransporter-MainCustomerList";
import { fetchDeleteItem } from "../../../redux/actions/transporter/aTransporter-DeleteItem";
import PropTypes from "prop-types";
import { SelectField } from "../../LandLord/Widgets/SelectField";
import { AbilityContext } from "../../../casl/Can";
import moment from "moment";
import ExportSavedPlaceWithMeshButton from "./ExportSavedPlaceWithMeshButton";
import SavedPlacesView from "./SavedPlacesView";
import SavedPlacesEditForm from "./SavedPlacesEditForm";
import SavedPlacesApproval from "./SavedPlacesApproval";

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const propTypes = {
  data: PropTypes.number,
};

const defaultProps = {
  data: 0,
};

class SavedPlacesDatatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalApproval: false,
      modalDelete: false,
      selectedId: 0,
      selectedName: "",
      selectedLat: 0,
      selectedLng: 0,
      isLoading: false,
      exportDataType: "default",
      initialValues: {
        customer: {},
      },
      filterCheckbOxTracker: {
        approved_filter: true,
        not_approved_filter: true,
        deactivated_filter: true,
      },
      selectedSavedPlace: [],
      meshExportPeriod: {
        label: "1 Month",
        value: {
          start_date: moment().subtract(1, "months").format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD"),
        },
      },
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.fullData !== this.props.fullData) {
      const { filterCheckbOxTracker } = this.state;
      this.filteringSPDataByStatus(filterCheckbOxTracker);
    }
  };

  toggleOpenApproval = (_id, _name, _lat, _lng, _cName, _cEmail, _cPhone) => {
    this.props.fetchSavedPlacesNear(
      {
        lat: _lat,
        lon: _lng,
      },
      _id
    );
    this.setState({
      selectedId: _id,
      selectedName: _name,
      modalApproval: !this.state.modalApproval,
    });
  };

  toggleOpenView = (_id) => {
    this.setState({
      selectedId: _id,
      modalView: !this.state.modalView,
    });
  };

  toggleOpenDelete = (_id, _name) => {
    this.setState({
      selectedId: _id,
      selectedName: _name,
      modalDelete: !this.state.modalDelete,
    });
  };

  toggleOpenEdit = (_id, _name) => {
    this.setState({
      selectedId: _id,
      selectedName: _name,
      modalEdit: !this.state.modalEdit,
      initialValues: {
        customer: {},
      },
    });
  };

  toggleCloseEdit = () => {
    this.setState({
      selectedId: 0,
      selectedName: "",
      modalEdit: !this.state.modalEdit,
      initialValues: {
        customer: {},
      },
    });
  };

  toggleCloseApproval = () => {
    this.setState({
      selectedId: 0,
      selectedName: "",
      selectedLat: 0,
      selectedLng: 0,
      selectedCustomerName: "",
      selectedCustomerPhone: "",
      selectedCustomerEmail: "",
      modalApproval: !this.state.modalApproval,
    });
  };

  toggleCloseView = () => {
    this.setState({
      selectedId: 0,
      selectedName: "",
      selectedLat: 0,
      selectedLng: 0,
      selectedCustomerName: "",
      selectedCustomerPhone: "",
      selectedCustomerEmail: "",
      modalView: !this.state.modalView,
    });
  };

  toggleCloseDelete = () => {
    this.setState({
      selectedId: 0,
      selectedName: "",
      selectedLat: 0,
      selectedLng: 0,
      modalDelete: !this.state.modalDelete,
    });
  };

  ActionButtonFormatter = (cell, row) => {
    //eslint-disable-next-line
    // let boolManager = this.props.boolManager > 0 ? true : false;
    let boolManager = true;
    return (
      <div>
        {row.status === 3 ? (
          <div>
            {this.context.can(
              "view",
              "approval_or_view_or_edit_or_delete@saved_places_datatable"
            ) && (
              <React.Fragment>
                {this.context.can("view", "approval_button@saved_place_subs") &&
                boolManager ? (
                  <>
                    <Button
                      className="btn btn-success"
                      color="success"
                      size="sm"
                      onClick={() =>
                        this.toggleOpenApproval(
                          row.id,
                          row.name,
                          row.lat,
                          row.lng,
                          row.customer_name,
                          row.customer_email,
                          row.customer_handphone,
                          row.email_user_approve,
                          row.date_approve
                        )
                      }
                    >
                      <i className="fa fa-check-square-o"></i> Place Approval
                    </Button>
                    <br />
                  </>
                ) : (
                  true
                )}
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="sm"
                  onClick={() => this.toggleOpenView(row.id)}
                >
                  <i className="fa fa-eye"></i> View
                </Button>
                <Button
                  className="btn btn-warning"
                  color="warning"
                  size="sm"
                  onClick={() => this.toggleOpenEdit(row.id, row.name)}
                >
                  <i className="fa fa-edit"></i> Edit
                </Button>
                <Button
                  className="btn btn-danger"
                  color="danger"
                  size="sm"
                  onClick={() => this.toggleOpenDelete(row.id, row.name)}
                >
                  <i className="fa fa-trash"></i> Delete
                </Button>
              </React.Fragment>
            )}

            {this.context.can("view", "approval_or_view@saved_places") && (
              <React.Fragment>
                <Button
                  className="btn btn-primary"
                  color="success"
                  size="sm"
                  onClick={() => this.toggleOpenView(row.id)}
                >
                  <i className="fa fa-eye"></i> View
                </Button>
                {this.context.can("view", "approval_button@saved_place_subs") &&
                boolManager ? (
                  <Button
                    className="btn btn-success"
                    color="success"
                    size="sm"
                    onClick={() =>
                      this.toggleOpenApproval(
                        row.id,
                        row.name,
                        row.lat,
                        row.lng,
                        row.customer_name,
                        row.customer_email,
                        row.customer_handphone
                      )
                    }
                  >
                    <i className="fa fa-check-square-o"></i> Place Approval
                  </Button>
                ) : (
                  true
                )}
              </React.Fragment>
            )}

            {this.context.can("view", "view_only@saved_places") && (
              <React.Fragment>
                (Ask manager to approve it...) &nbsp;&nbsp; <br></br>
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="sm"
                  onClick={() => this.toggleOpenView(row.id)}
                >
                  <i className="fa fa-eye"></i> View
                </Button>
              </React.Fragment>
            )}
          </div>
        ) : //eslint-disable-next-line
        row.status === 2 ? (
          <div>
            {this.context.can(
              "view",
              "approval_or_view_or_edit_or_delete@saved_places_datatable"
            ) && (
              <React.Fragment>
                {this.context.can(
                  "view",
                  "approval_button@saved_place_subs"
                ) && (
                  <React.Fragment>
                    {" "}
                    (Deactivated) &nbsp;&nbsp; <br></br>
                  </React.Fragment>
                )}
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="sm"
                  onClick={() => this.toggleOpenView(row.id)}
                >
                  <i className="fa fa-eye"></i> View
                </Button>
                <Button
                  className="btn btn-warning"
                  color="warning"
                  size="sm"
                  onClick={() => this.toggleOpenEdit(row.id, row.name)}
                >
                  <i className="fa fa-edit"></i> Edit
                </Button>
                <Button
                  className="btn btn-danger"
                  color="danger"
                  size="sm"
                  onClick={() => this.toggleOpenDelete(row.id, row.name)}
                >
                  <i className="fa fa-trash"></i> Delete
                </Button>
              </React.Fragment>
            )}

            {this.context.can("view", "approval_or_view@saved_places") && (
              <React.Fragment>
                {this.context.can(
                  "view",
                  "approval_button@saved_place_subs"
                ) && (
                  <React.Fragment> (Deactivated) &nbsp;&nbsp;</React.Fragment>
                )}
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="sm"
                  onClick={() => this.toggleOpenView(row.id)}
                >
                  <i className="fa fa-eye"></i> View
                </Button>
              </React.Fragment>
            )}

            {this.context.can("view", "view_only@saved_places") && (
              <React.Fragment>
                {this.context.can(
                  "view",
                  "approval_button@saved_place_subs"
                ) && (
                  <React.Fragment> (Deactivated) &nbsp;&nbsp;</React.Fragment>
                )}
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="sm"
                  onClick={() => this.toggleOpenView(row.id)}
                >
                  <i className="fa fa-eye"></i> View
                </Button>
              </React.Fragment>
            )}
          </div>
        ) : // eslint-disable-next-line
        row.status === 1 ? (
          <div>
            {this.context.can(
              "view",
              "approval_or_view_or_edit_or_delete@saved_places_datatable"
            ) && (
              <React.Fragment>
                {this.context.can(
                  "view",
                  "approval_button@saved_place_subs"
                ) && (
                  <React.Fragment>
                    {!row.email_user_approve && (
                      <React.Fragment>
                        (Approved)&nbsp;&nbsp;
                        <br />
                      </React.Fragment>
                    )}
                    {row.email_user_approve && row.date_approve && (
                      <div style={{ whiteSpace: "pre-line" }}>
                        Approved by {row.email_user_approve}
                        <br />
                        On{" "}
                        {moment
                          .utc(row.date_approve)
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                    )}
                  </React.Fragment>
                )}
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="sm"
                  onClick={() => this.toggleOpenView(row.id)}
                >
                  <i className="fa fa-eye"></i> View
                </Button>
                <Button
                  className="btn btn-warning"
                  color="warning"
                  size="sm"
                  onClick={() => this.toggleOpenEdit(row.id, row.name)}
                >
                  <i className="fa fa-edit"></i> Edit
                </Button>
                <Button
                  className="btn btn-danger"
                  color="danger"
                  size="sm"
                  onClick={() => this.toggleOpenDelete(row.id, row.name)}
                >
                  <i className="fa fa-trash"></i> Delete
                </Button>
              </React.Fragment>
            )}

            {this.context.can("view", "approval_or_view@saved_places") && (
              <React.Fragment>
                {this.context.can(
                  "view",
                  "approval_button@saved_place_subs"
                ) && (
                  <React.Fragment>
                    {!row.email_user_approve && (
                      <React.Fragment>
                        (Approved)&nbsp;&nbsp;
                        <br />
                      </React.Fragment>
                    )}
                    {row.email_user_approve && row.date_approve && (
                      <div style={{ whiteSpace: "pre-line" }}>
                        Approved by {row.email_user_approve}
                        <br />
                        On{" "}
                        {moment
                          .utc(row.date_approve)
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                    )}
                  </React.Fragment>
                )}
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="sm"
                  onClick={() => this.toggleOpenView(row.id)}
                >
                  <i className="fa fa-eye"></i> View
                </Button>
              </React.Fragment>
            )}

            {this.context.can("view", "view_only@saved_places") && (
              <React.Fragment>
                {this.context.can(
                  "view",
                  "approval_button@saved_place_subs"
                ) && (
                  <React.Fragment>
                    {!row.email_user_approve && (
                      <React.Fragment>
                        (Approved)&nbsp;&nbsp;
                        <br />
                      </React.Fragment>
                    )}
                    {row.email_user_approve && row.date_approve && (
                      <div style={{ whiteSpace: "pre-line" }}>
                        Approved by {row.email_user_approve}
                        <br />
                        On{" "}
                        {moment
                          .utc(row.date_approve)
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                    )}
                  </React.Fragment>
                )}
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="sm"
                  onClick={() => this.toggleOpenView(row.id)}
                >
                  <i className="fa fa-eye"></i> View
                </Button>
              </React.Fragment>
            )}
          </div>
        ) : (
          <div>
            {this.context.can(
              "view",
              "approval_or_view_or_edit_or_delete@saved_places_datatable"
            ) && (
              <React.Fragment>
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="sm"
                  onClick={() => this.toggleOpenView(row.id)}
                >
                  <i className="fa fa-eye"></i> View
                </Button>
                <Button
                  className="btn btn-warning"
                  color="warning"
                  size="sm"
                  onClick={() => this.toggleOpenEdit(row.id, row.name)}
                >
                  <i className="fa fa-edit"></i> Edit
                </Button>
                <Button
                  className="btn btn-danger"
                  color="danger"
                  size="sm"
                  onClick={() => this.toggleOpenDelete(row.id, row.name)}
                >
                  <i className="fa fa-trash"></i> Delete
                </Button>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  };

  positionFormatter = (cell, row) => {
    return `${cell}, ${row.lng}`;
  };

  showNearPlaceDataTable = () => {
    var options = {
      defaultSortName: "name", // default sort column name
      defaultSortOrder: "asc", // default sort order
      noDataText: "No place registered on your dashboard. Try one-lah!",
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
      sizePerPage: 20,
      exportCSVSeparator: ";",
      btnGroup: this.customGroupNear,
    };
    return (
      <BootstrapTable
        data={this.props.savedPlaceNearData}
        version="4"
        bordered={false}
        hover
        pagination
        search
        edit
        options={options}
        exportCSV={true}
        csvFileName={this.fileNameFormat}
        multiColumnSearch
      >
        <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" hidden>
          ID
        </TableHeaderColumn>
        {this.context.can("view", "company_field@saved_places_datatable") ? (
          <TableHeaderColumn
            dataField="company_name"
            searchable
            dataSort
            csvHeader="Company"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="15%"
          >
            Company
          </TableHeaderColumn>
        ) : null}
        <TableHeaderColumn
          dataField="name"
          searchable
          dataSort
          csvHeader="Location Name"
          thStyle={{ whiteSpace: "normal" }}
          width="25%"
        >
          Location Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="customer_name"
          searchable
          dataSort
          csvHeader="Customer Name"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="15%"
          dataFormat={this.showVerifiedBadge.bind(this)}
        >
          Customer Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="lat"
          dataFormat={this.positionFormatter}
          dataSort
          csvHeader="Latitude"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="15%"
        >
          Position
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  viewDeletePlace = () => {
    var self = this;
    const { selectedName, selectedId } = this.state;
    return (
      <div className="container">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Places Name: </Label>
          </Col>
          <Col xs="12" md="9">
            <Label htmlFor="text-input">{selectedName}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="12">
            <Label htmlFor="text-input">
              Do you want to delete this place?{" "}
            </Label>
          </Col>
        </FormGroup>

        {this.props.inProgressDelete ? (
          <center>
            <BarLoader
              color={"#123abc"}
              loading={this.props.inProgressDelete}
            />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => self.handleDelete(selectedId)}
              size="sm"
              color="danger"
              disabled={self.props.inProgressDelete}
            >
              <i className="fa fa-trash"></i> Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseDelete()}
              size="sm"
              color="success"
              disabled={self.props.inProgressDelete}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  handlePlaceApproval = () => {
    this.setState({
      isLoading: true,
    });
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    //Variables for text input
    const { selectedId } = this.state;

    if (selectedId === "" || selectedId === null || selectedId === 0) {
      self.setState({ isLoading: false });
      toast.error("Selected place is invalid!");
    } else {
      axios({
        method: "post",
        url: API_LINKS.SAVED_PLACES_APPROVAL_URL, //Please change when it's ready
        headers: {
          Authorization: auth,
        },
        data: {
          id: selectedId,
        },
      })
        .then((response) => {
          // console.log(response)
          if (response.status === 200) {
            if (response.data.status === "success") {
              self.setState({
                isLoading: false,
              });
              toast.success("Data saved!");
              self.toggleCloseApproval();
              self.props.refreshTable();
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              self.setState({
                isLoading: false,
              });
              return toast.error(error.response.data.message);
            } else if (error.response.status === 403) {
              self.setState({
                isLoading: false,
              });
              return toast.error(error.response.data.message);
            } else if (error.response.status === 400) {
              self.setState({
                isLoading: false,
              });
              return toast.error(error.response.data.message);
            } else if (
              error.response.status === 404 ||
              error.response.status === 500
            ) {
              self.setState({
                isLoading: false,
              });
              return toast.error(
                "Server cannot be contacted! Please ask your system administrator!"
              );
            } else {
              self.setState({
                isLoading: false,
              });
              return toast.error(
                "Something went wrong... Please try again later..."
              );
            }
          } else if (error.request) {
            self.setState({
              isLoading: false,
            });
            return toast.error("Request error! Please try again later...");
          } else {
            self.setState({
              isLoading: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        });
    }
  };

  handleDelete = (_id) => {
    this.props.fetchDeleteItem("P", _id);
    this.toggleCloseDelete();
    sleep(500).then(() => {
      // Do something after the sleep!
      this.props.refreshTable();
    });
  };

  showVerifiedBadge = (cell, row) => {
    if (row.verify_status === 1) {
      return (
        <div>
          {row.customer_name}&nbsp;<Badge color="success">Verified</Badge>
        </div>
      );
    } else {
      return (
        <div>
          {row.customer_name}&nbsp;<Badge color="warning">Not Verified</Badge>
        </div>
      );
    }
  };

  costumerNameFormatter = (cell, row) => {
    let syncBadge = null;
    if (row.sync === 1) {
      syncBadge = <Badge color="primary">sync</Badge>;
    }
    return (
      <span
        style={{ cursor: "pointer" }}
        onClick={() => this.props.onCustomerNameClicked(row)}
      >
        {cell} {syncBadge}
      </span>
    );
  };

  onRowSelect = (row, isSelected, e) => {
    const { selectedSavedPlace } = this.state;
    if (!isSelected)
      this.setState({
        selectedSavedPlace: [...selectedSavedPlace].filter(
          (s) => s.id !== row.id
        ),
      });
    // if(selectedSavedPlace.length >= 25){
    //     toast.error("max number of select (25) reach!")
    //     return false
    // }
    if (isSelected)
      this.setState({
        selectedSavedPlace: [...selectedSavedPlace, row],
      });
  };

  onSelectAll = (isSelected, rows) => {
    if (!isSelected) {
      this.setState({
        selectedSavedPlace: [],
      });
    }
    // if(isSelected && (rows.length >= 25)){
    //     toast.error("max number of select (25) reach!")
    //     return false
    // }
    if (isSelected) {
      this.setState({
        selectedSavedPlace: this.props.data,
      });
      return this.props.data.map((row) => row.id);
    }
  };

  getSelectRowProp = () => {
    return {
      mode: "checkbox",
      clickToSelect: true,
      columnWidth: "3%",
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
    };
  };

  showDataTable = () => {
    const { exportDataType } = this.state;
    var options = {
      defaultSortName: "name", // default sort column name
      defaultSortOrder: "asc", // default sort order
      noDataText: "No place registered on your dashboard. Try one-lah!",
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
      sizePerPage: 20,
      exportCSVSeparator: ";",
      btnGroup: this.customGroup,
    };
    return (
      <BootstrapTable
        selectRow={exportDataType === "mesh" ? this.getSelectRowProp() : {}}
        data={this.props.data}
        version="4"
        bordered={false}
        hover
        pagination
        search
        edit
        options={options}
        exportCSV={true}
        csvFileName={this.fileNameFormat}
        multiColumnSearch
      >
        <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID">
          ID
        </TableHeaderColumn>
          <TableHeaderColumn
            dataField="company_name"
            searchable
            dataSort
            csvHeader="Company"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="15%"
            filter={ { type: 'TextFilter', delay: 1000 } }
            hidden= {this.context.can("view", "company_field@saved_places_datatable")?false:true}
          >
            Company
          </TableHeaderColumn>
        <TableHeaderColumn
          dataField="customer_name"
          searchable
          dataSort
          csvHeader="Customer Name"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="20%"
          dataFormat={this.showVerifiedBadge.bind(this)}
          filter={ { type: 'TextFilter', delay: 1000 } }
        >
          Customer Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="name"
          searchable
          dataSort
          csvHeader="Location Name"
          dataFormat={this.costumerNameFormatter.bind(this)}
          thStyle={{ whiteSpace: "normal" }}
          width="20%"
          filter={ { type: 'TextFilter', delay: 1000 } }
        >
          Location Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="public_place_address"
          searchable
          dataSort
          csvHeader="Main Address"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="15%"
          filter={ { type: 'TextFilter', delay: 1000 } }
        >
          Location Address
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="customer_handphone"
          searchable
          dataSort
          csvHeader="Customer Phone Number"
          thStyle={{ whiteSpace: "normal" }}
          width="15%"
          filter={ { type: 'TextFilter', delay: 1000 } }
        >
          Customer Whatsapp
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="customer_email"
          searchable
          dataSort
          csvHeader="Customer Email"
          thStyle={{ whiteSpace: "normal" }}
          width="15%"
          filter={ { type: 'TextFilter', delay: 1000 } }
        >
          Customer Email
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="lat"
          dataSort
          csvHeader="Latitude"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="15%"
          filter={ { type: 'NumberFilter', delay: 1000 } }
        >
          Latitude
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="lng"
          dataSort
          csvHeader="Longitude"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="15%"
          filter={ { type: 'NumberFilter', delay: 1000 } }
        >
          Longitude
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="created_on"
          dataSort
          csvHeader="Created On"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="15%"
          hidden
        >
          Created On
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="action"
          export={false}
          dataFormat={this.ActionButtonFormatter.bind(this)}
          width="12%"
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  setExportedSPDataType = (e) => {
    this.setState({
      exportDataType: e.target.value,
    });
  };

  customGroupNear = (props) => {
    return (
      <Row>
        <Col xs="8" md="8">
          {props.exportCSVBtn}
        </Col>
      </Row>
    );
  };

  customGroup = (props) => {
    const { exportDataType, selectedSavedPlace, meshExportPeriod } = this.state;
    return (
      <Row>
        {this.context.can(
          "view",
          "export_csv_button@saved_places_datatable"
        ) && (
          <Col
            xs="2"
            md="2"
            style={{ "margin-top": "7px" }}
            onChange={(event) => this.setExportedSPDataType(event)}
          >
            <input
              type="radio"
              id="default"
              defaultChecked
              name="exportDataRadio"
              value="default"
            />
            <label htmlFor="default">&nbsp;&nbsp;Default</label>
            <input
              type="radio"
              id="mesh"
              name="exportDataRadio"
              value="mesh"
              style={{ marginLeft: "15px" }}
            />
            <label htmlFor="mesh">&nbsp;&nbsp;With Mesh</label>
          </Col>
        )}
        {exportDataType === "mesh" && (
          <Col style={{ "margin-top": "-2px" }}>
            <SelectField
              id="meshperiode"
              name="meshperiode"
              label="meshperiode"
              placeholder="Select Period"
              searchable
              options={[
                {
                  label: "1 Month",
                  value: {
                    start_date: moment()
                      .subtract(1, "months")
                      .format("YYYY-MM-DD"),
                    end_date: moment().format("YYYY-MM-DD"),
                  },
                },
                {
                  label: "3 Month",
                  value: {
                    start_date: moment()
                      .subtract(3, "months")
                      .format("YYYY-MM-DD"),
                    end_date: moment().format("YYYY-MM-DD"),
                  },
                },
                { label: "All Time", value: null },
              ]}
              value={meshExportPeriod}
              isMulti={false}
              onChange={(field, meshExportPeriod) =>
                this.setState({ meshExportPeriod })
              }
              onBlur={() => {}}
              isClearable={true}
              backspaceRemovesValue={true}
            />
          </Col>
        )}
        {exportDataType === "default" &&
          this.context.can(
            "view",
            "export_csv_button@saved_places_datatable"
          ) && (
            <Col xs="2" md="2">
              {props.exportCSVBtn}
            </Col>
          )}
        {exportDataType === "mesh" &&
          this.context.can(
            "view",
            "export_csv_button@saved_places_datatable"
          ) && (
            <Col xs="2" md="2">
              <ExportSavedPlaceWithMeshButton
                data={selectedSavedPlace}
                period={meshExportPeriod}
              />
            </Col>
          )}
        <Col xs="4" md="4" style={{ "margin-top": "7px" }}>
          <this.FiltersCheckbox />
        </Col>
      </Row>
    );
  };

  filteringSPDataByStatus = (filterCheckbOxTracker) => {
    let status = 1;
    let copyOfData = [...this.props.fullData];
    let result = [];
    for (const property in filterCheckbOxTracker) {
      let isChecked = filterCheckbOxTracker[property];
      if (isChecked)
        // eslint-disable-next-line no-loop-func
        result.push(...copyOfData.filter((d) => d.status === status));
      // eslint-disable-next-line no-loop-func
      if (!isChecked) result.push(...result.filter((d) => d.status === status));
      status += 1;
    }
    this.props.onResultFilterChange(result);
  };

  handleFiltersChange = (e) => {
    const { filterCheckbOxTracker } = this.state;
    let copyOfCheckboXTracker = filterCheckbOxTracker;
    copyOfCheckboXTracker[e.target.id] = !filterCheckbOxTracker[e.target.id];
    this.setState(
      {
        filterCheckbOxTracker: copyOfCheckboXTracker,
      },
      () => {
        this.filteringSPDataByStatus(filterCheckbOxTracker);
      }
    );
  };

  FiltersCheckbox = (props) => {
    let filters = [
      {
        id: "approved_filter",
        name: "approved_filter",
        label: "Approved",
      },
      {
        id: "not_approved_filter",
        name: "not_approved_filter",
        label: "Deactivated",
      },
      {
        id: "deactivated_filter",
        name: "deactivated_filter",
        label: "Not Approved",
      },
    ];

    return (
      <Form>
        {filters.map((filter) => (
          <FormGroup check inline>
            <Input
              type="checkbox"
              id={filter.id}
              name={filter.name}
              defaultChecked={true}
              onChange={(e) => this.handleFiltersChange(e)}
            />
            <Label check>{filter.label}</Label>
          </FormGroup>
        ))}
      </Form>
    );
  };

  render() {
    return (
      <div className="animated fadeIn">
        {this.showDataTable()}
        {/* Modal for View Place */}
        <Modal
          isOpen={this.state.modalView}
          toggle={this.toggleOpenView}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseView}>
            Place overview for : {this.state.selectedName}
          </ModalHeader>
          <ModalBody>
            {/* {this.viewPlaceView()} */}
            <SavedPlacesView
              id={this.state.selectedId}
              toggleCloseView={this.toggleCloseView.bind(this)}
            />
          </ModalBody>
        </Modal>

        {/* Modal for Approve Place */}
        <Modal
          size="lg"
          isOpen={this.state.modalApproval}
          toggle={this.toggleOpenApproval}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseApproval}>
            Customer Approval for : {this.state.selectedName}
          </ModalHeader>
          <ModalBody>
            <SavedPlacesApproval
              id={this.state.selectedId}
              toggleCloseApproval={this.toggleCloseApproval}
              showNearPlaceDataTable={this.showNearPlaceDataTable}
              refreshTable={this.props.refreshTable}
            />
          </ModalBody>
        </Modal>

        {/* Modal for Edit Place */}
        <Modal
          isOpen={this.state.modalEdit}
          toggle={this.toggleOpenEdit}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseEdit}>
            Edit Customer for {this.state.selectedName}?
          </ModalHeader>
          <ModalBody>
            {/* {this.viewEditPlace()} */}
            <SavedPlacesEditForm
              id={this.state.selectedId}
              toggleCloseEdit={this.toggleCloseEdit.bind(this)}
              refetch={this.props.refreshTable}
            />
          </ModalBody>
        </Modal>

        {/* Modal for Delete Place */}
        <Modal
          isOpen={this.state.modalDelete}
          toggle={this.toggleOpenDelete}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseDelete}>
            Delete {this.state.selectedName}?
          </ModalHeader>
          <ModalBody>{this.viewDeletePlace()}</ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    savedPlacesTableData: state.savedPlacesTableStore.savedPlacesTableData,
    savedPlaceApproved: state.savedPlacesTableStore.savedPlaceApproved,
    savedPlaceNotApproved: state.savedPlacesTableStore.savedPlaceNotApproved,
    savedPlaceDeleted: state.savedPlacesTableStore.savedPlaceDeleted,
    inProgress: state.savedPlacesTableStore.inProgress,
    successSavedPlaceFetch: state.savedPlacesTableStore.success,
    deleteItemData: state.deleteStore.deleteItemData,
    inProgressDelete: state.deleteStore.inProgress,

    //Customers
    mainCustomerSelect: state.mainCustomerStore.mainCustomerSelect,
    inProgressMainCustomer: state.mainCustomerStore.inProgress,
    successMainCustomer: state.mainCustomerStore.success,

    // KML
    kml: state.companyStore.companyKML,
    statKML: state.settingStore.kmlSet,

    boolManager: state.authStore.subs.manager_approval,

    savedPlaceNearData: state.savedPlaceNearStore.savedPlaceNearData,
    savedPlaceNearLoading: state.savedPlaceNearStore.loading,
  };
};

SavedPlacesDatatable.defaultProps = defaultProps;
SavedPlacesDatatable.propTypes = propTypes;
SavedPlacesDatatable.contextType = AbilityContext;

export default connect(mapStateToProps, {
  fetchDeleteItem,
  fetchMainCustomerList,
  fetchSavedPlacesNear,
})(SavedPlacesDatatable);

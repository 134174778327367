export default {
    items: [
      {
        name: 'TrackX',
        url: '/transporter',
        icon: 'fa fa-car',
        children: [
          {
            name: 'Fleet Maintenance',
            url: '/transporter/fleet-maintenance',
            // icon: 'fa fa-wrench',
          },
        ]
      }
    ]
}    
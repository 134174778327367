import React, { Component } from 'react'
import {
    ButtonGroup,
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label
} from 'reactstrap';
import {toast} from 'react-toastify'
import axios from 'axios'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as API_LINKS from '../../../redux/config/links'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { connect } from "react-redux";
import { BarLoader } from 'react-spinners';
import { fetchSecurityTable } from "../../../redux/actions/transporter/aTransporter-SecurityTable";
import { fetchDeleteItem } from "../../../redux/actions/transporter/aTransporter-DeleteItem";
import { AbilityContext} from '../../../casl/Can';

function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }  

class SecurityDatatable extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            modalApprove: false,
            modalDelete: false,
            isLoading: false,
            selectedId: 0,
            selectedName: '',
            selectedSecurityCode: '',
        };
    }

    toggleOpenApprove = (_id, _name, _securityCode) => {
        this.setState({
            selectedId: _id,
            selectedName: _name,
            selectedSecurityCode: _securityCode,
            modalApprove: !this.state.modalApprove
        })
    }
    
    toggleCloseApprove = () => {
        this.setState({
            selectedId: 0,
            selectedName: '',
            selectedSecurityCode: '',
            modalApprove: !this.state.modalApprove
        })
    }

    toggleOpenDelete = (_id, _name) => {
        this.setState({
            selectedId: _id,
            selectedName: _name,
            modalDelete: !this.state.modalDelete
        })
    }

    toggleCloseDelete = () => {
        this.setState({
            selectedId: 0,
            selectedName:'',
            modalDelete: !this.state.modalDelete
        })
    }

    UNSAFE_componentWillMount = () => {
      this.props.fetchSecurityTable()
    }

    ActionButtonFormatter = (cell, row) => {
            return (

                <ButtonGroup>

                    {/* {this.context.can("view", "security_approval_button@security_datatable_subs")&&
                            this.context.can("view", "security_approval_button@security_datatable")&&
                            <React.Fragment>
                                <Button className="btn btn-success" color="success" size="sm" onClick={() => this.toggleOpenApprove(row.id, row.name, row.security_code)}><i className="fa fa-check-square-o"></i> Security Approval</Button>
                            </React.Fragment>

                    } */}

                    {this.context.can("view", "security_delete_button@security_datatable")&&
                        <React.Fragment>
                            <Button className="btn btn-danger" color="danger" size="sm" onClick={() => this.toggleOpenDelete(row.id, row.name)}><i className="fa fa-trash"></i> Delete</Button>
                        </React.Fragment>
                    }

                </ButtonGroup>
            )

    }

    viewApproveSecurity = () => {
        var self = this;
        const { selectedName, selectedSecurityCode } = this.state;
        return(
            <div className="container">
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Security Name: </Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Label htmlFor="text-input">{selectedName}</Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Security Code: </Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Label htmlFor="text-input">{selectedSecurityCode}</Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" md="12">
                        <Label htmlFor="text-input">Do you want to approve this security registration? </Label>
                    </Col>
                </FormGroup>
                  
                {this.props.isLoading ? <center><BarLoader color={'#123abc'} 
                  loading={this.props.isLoading} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => this.handleApproveSecurity()} size="sm" color="primary" disabled={self.state.isLoading}><i className="fa fa-check-circle-o"></i> Yes</Button>
                <Button onClick={() => this.toggleCloseApproval()} size="sm" color="success" disabled={self.state.isLoading}><i className="fa fa-window-close"></i> No</Button></div>}         
            </div>
        )
    }

    viewDeleteSecurity = () => {
        var self = this;
        const { selectedName, selectedId} = this.state;
        return(
            <div className="container">
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Security Name: </Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Label htmlFor="text-input">{selectedName}</Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" md="12">
                        <Label htmlFor="text-input">Do you want to delete this security personel? </Label>
                    </Col>
                </FormGroup>
                  
                {this.props.inProgressDelete ? <center><BarLoader color={'#123abc'} 
                  loading={this.props.inProgressDelete} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => self.handleDelete(selectedId)} size="sm" color="danger" disabled={self.props.inProgressDelete}><i className="fa fa-trash"></i> Submit</Button>
                <Button onClick={() => this.toggleCloseDelete()} size="sm" color="success" disabled={self.props.inProgressDelete}><i className="fa fa-window-close"></i> Close</Button></div>}         
            </div>
        )
    }

    handleDelete = (_id) => {
        this.props.fetchDeleteItem('S', _id);
        this.toggleCloseDelete()
        sleep(500).then(() => {
            // Do something after the sleep!
            this.props.fetchSecurityTable()
        });
        
    }

    handleApproveSecurity = () => {
        this.setState({
            isLoading: true
        })
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;

        //Variables for text input
        const {selectedId} = this.state

        if (selectedId === "" || selectedId === null || selectedId === 0) {
            self.setState({isLoading: false})
            toast.error("Selected DO is Invalid!")
        } else {
            axios({
                method:'post',
                url: API_LINKS.DELIVERY_APPROVE_URL, //Please change when it's ready
                headers: {
                    Authorization: auth
                },
                data: {
                    id_security: selectedId,
                }
            })
            .then((response) => {
                // console.log(response)
                if(response.status === 200) {
                    if (response.data.status === "success") {
                        self.setState({
                            isLoading: false,
                        })
                        toast.success('Data saved!')
                        self.toggleCloseApprove()
                        self.props.fetchSecurityTable()
                    }
                }
            })
            .catch(function(error){
                if (error.response) {
                    if(error.response.status === 401) {
                        self.setState({
                            isLoading: false,
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                        self.setState({
                            isLoading: false,
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 400) {
                        self.setState({
                            isLoading: false,
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        self.setState({
                            isLoading: false,
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else {
                        self.setState({
                            isLoading: false,
                        })
                        return toast.error('Something went wrong... Please try again later...')
                    }
                } else if (error.request) {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }

    showDataTable = () => {
        return (
            <BootstrapTable data={this.props.securityTableData.result} version="4" bordered={false} hover pagination search edit options={this.options} exportCSV={true} csvFileName={this.fileNameFormat} multiColumnSearch>
              <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" hidden>ID</TableHeaderColumn>
              {this.context.can("view","company_field@security_datatable") ? 
                <TableHeaderColumn dataField="company" searchable dataSort csvHeader="Company" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%" >Company</TableHeaderColumn> : null
             }
              <TableHeaderColumn dataField="name" searchable dataSort csvHeader="Name" thStyle={ { whiteSpace: 'normal' } } width="20%">Name</TableHeaderColumn>
              <TableHeaderColumn dataField="security_code" dataSort csvHeader="Security Code" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Security Code</TableHeaderColumn>
              <TableHeaderColumn dataField="email" searchable dataSort csvHeader="Name" thStyle={ { whiteSpace: 'normal' } } width="20%">Email</TableHeaderColumn>
              <TableHeaderColumn dataField="phone" dataSort csvHeader="Security Code" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Phone</TableHeaderColumn>
            {/* <TableHeaderColumn dataField="lng" dataSort csvHeader="Longitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Longitude</TableHeaderColumn> */}
              {/* <TableHeaderColumn dataField="created_on" dataSort csvHeader="Created On" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%" hidden>Created On</TableHeaderColumn> */}
              <TableHeaderColumn dataField="action" export={false} dataFormat={ this.ActionButtonFormatter.bind(this) } width="12%">Action</TableHeaderColumn>
            </BootstrapTable>
        );
    }

    render() {
        return (
            <div className="animated fadeIn">
                {
                    this.props.inProgress ? 
                    <center><BarLoader color={'#123abc'} loading={this.props.inProgress}/> <br /> Loading.... Please wait...</center>
                    : 
                    (
                        this.props.successSecurityFetch ? 
                        this.showDataTable() 
                        : 
                        <center>Error fetching your data... <br/><br/> 
                            <Button className="btn btn-primary" color="primary" size="md" disabled={this.props.inProgress} onClick={() => this.props.fetchSecurityTable()}>
                                <i className="fa fa-refresh"></i> 
                                Try again
                            </Button>
                        </center>
                    )
                }

                {/* Modal for Adding Cards */}
                <Modal isOpen={this.state.modalApprove} toggle={this.toggleOpenApprove} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseApprove}>Security Approval for : {this.state.selectedName}</ModalHeader>
                    <ModalBody>
                            {this.viewApproveSecurity()}
                    </ModalBody>
                </Modal>

                {/* Modal for Delete Security */}
                <Modal isOpen={this.state.modalDelete} toggle={this.toggleOpenDelete} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseDelete}>Delete {this.state.selectedName}?</ModalHeader>
                    <ModalBody>
                            {this.viewDeleteSecurity()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        securityTableData: state.securityTableStore.securityTableData,
        inProgress: state.securityTableStore.inProgress,
        successSecurityFetch: state.securityTableStore.success,
        deleteItemData: state.deleteStore.deleteItemData,
        inProgressDelete: state.deleteStore.inProgress
      }
}

SecurityDatatable.contextType = AbilityContext;
export default connect(mapStateToProps, {fetchSecurityTable, fetchDeleteItem})(SecurityDatatable);
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardFooter } from "reactstrap";
import classNames from "classnames";
import { mapToCssModules } from "reactstrap/lib/utils";

const propTypes = {
  header: PropTypes.any,
  mainText: PropTypes.node,
  icon: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  footer: PropTypes.bool,
  link: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
};

const defaultProps = {
  header: "0",
  mainText: "Active Users",
  icon: "fa fa-users",
  color: "success",
  variant: "0",
  link: "#",
};

class SummaryWidgets extends Component {
  render() {
    const {
      className,
      cssModule,
      header,
      headerLink,
      mainText,
      icon,
      imageLink,
      color,
      footer,
      link,
      children,
      variant,
      ...attributes
    } = this.props;

    // demo purposes only
    const padding =
      variant === "0"
        ? { card: "p-3", icon: "p-3", lead: "mt-2" }
        : variant === "1"
        ? {
            card: "p-0",
            icon: "p-4",
            lead: "pt-3",
          }
        : { card: "p-0", icon: "p-4 px-5", lead: "pt-3" };

    const card = { style: "clearfix", color: color, icon: icon, classes: "" };
    card.classes = mapToCssModules(
      classNames(className, card.style, padding.card),
      cssModule
    );

    const lead = { style: "h5 mb-0", color: color, classes: "" };
    lead.classes = classNames(lead.style, "text-" + card.color, padding.lead);

    const leadLink = { style: "h5 mb-0", color: color, classes: "" };
    leadLink.classes = classNames(
      leadLink.style,
      "text-" + card.color,
      padding.lead
    );

    const blockIcon = function (icon) {
      const classes = classNames(
        icon,
        "bg-" + card.color,
        padding.icon,
        "font-2xl mr-3 float-left"
      );
      return <i className={classes}></i>;
    };

    const blockImage = function (imageLink) {
      const classImage = classNames("font-2xl mr-3 float-left");

      if (!imageLink)
        imageLink = process.env.PUBLIC_URL + "/assets/img/photo_not_found.png";

      return (
        <img
          width={45}
          height={50}
          className={classImage}
          src={imageLink}
          alt="Face should appear here!"
        />
      );
    };

    const headerContent = () => {
      if (!headerLink) return <div className={lead.classes}>{header}</div>;
      if (headerLink)
        return (
          <span
            className={leadLink.classes}
            style={{ cursor: "pointer" }}
            onClick={() => this.props.onLinkHeaderClick(headerLink)}
          >
            {header}
          </span>
        );
    };

    const cardFooter = function () {
      if (footer) {
        return (
          <CardFooter className="px-3 py-2">
            <a
              className="font-weight-bold font-xs btn-block text-muted"
              href={link}
            >
              View More
              <i className="fa fa-angle-right float-right font-lg"></i>
            </a>
          </CardFooter>
        );
      }
    };

    // const header = function () {

    // }

    return (
      <Card>
        <CardBody className={card.classes} {...attributes}>
          {imageLink || imageLink === null
            ? blockImage(imageLink)
            : blockIcon(card.icon)}
          {headerContent()}
          <div className="text-muted text-uppercase font-weight-bold font-xs">
            {mainText}
          </div>
        </CardBody>
        {cardFooter()}
      </Card>
    );
  }
}

SummaryWidgets.propTypes = propTypes;
SummaryWidgets.defaultProps = defaultProps;

export default SummaryWidgets;

import React, { Component } from 'react'
import {
    Button,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    FormFeedback,
} from 'reactstrap'
import { connect } from 'react-redux'
import { fetchLiveMonitoringShare } from '../../../redux/actions/transporter/aTransporter-LiveMonitoringShare';
import { Formik } from 'formik'
import {toast} from 'react-toastify'
import { SelectField } from '../../LandLord/Widgets/SelectField'
import { CopyToClipboard } from 'react-copy-to-clipboard';
class AddMasterItemDetaillAdjustment extends Component {

    constructor(props){
        super(props)
        this.state = {
            sharedLink : null
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.tokenResult !== this.props.tokenResult){
            this.setState({
                sharedLink: this.props.tokenResult
            })
            toast.success("Share Link Generated")
        }
    }

    getLinkLifetime = () => {
        return [
            {label: "1 Hour", value : 1},
            {label: "3 Hours", value : 3},
            {label: "6 Hours", value : 6},
            {label: "12 Hours", value : 12},
            {label: "24 Hours (A day)", value : 24},
            {label: "72 Hours (3 days)", value : 72},
            {label: "120 Hours (5 days)", value : 120},
            {label: "168 Hours (7 days)", value : 168},
        ]
    }

    form = () => {
        const {initialValues} = this.props
        return(
            <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting}) => {
                // let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                // let auth = loginCreds.token;
                setSubmitting(true)
                this.props.fetchLiveMonitoringShare(
                    values.id_fleet,
                    values.link_lifetime.value
                )
                setSubmitting(false)
            }} 
            render={({
            values,errors,
            touched,setFieldValue,
            handleSubmit,handleBlur,
            isSubmitting,
            }) => (
                <form>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Link Lifetime</Label>
                            </Col>
                            <Col xs="6" md="6">
                                {
                                    <SelectField
                                        id="link_lifetime"
                                        name="link_lifetime"
                                        label="link_lifetime"
                                        options={this.getLinkLifetime()}
                                        value={values.link_lifetime}
                                        isMulti={false}
                                        onChange={setFieldValue}
                                        onBlur={handleBlur}
                                        touched={touched.link_lifetime}
                                        error={errors.link_lifetime}
                                        isClearable={true}
                                        backspaceRemovesValue={true}
                                        disabled={isSubmitting}
                                    />   
                                }
                            </Col>
                            <FormFeedback>{errors.link_lifetime}</FormFeedback>
                            <Col xs="3" md="3">
                                <Button type="button" 
                                        onClick={handleSubmit} 
                                        color="primary" 
                                        className="mr-1" 
                                        disabled={(isSubmitting)}>{isSubmitting ? 'Wait...' : 'Submit'}
                                </Button>
                            </Col>
                        </FormGroup>
                    </form>
                )}
            />
        )
    }

    render(){
        const {sharedLink} = this.state
        return(
            <React.Fragment>
                {this.form()}
                <hr />
                <Row>
                    <Col md="3">
                        <Label htmlFor="text-input">Link </Label>
                    </Col>
                    <Col xs="6" md="6">
                        <Input type="text"
                                    name="link"
                                    id="link"
                                    placeholder="shared link"
                                    autoComplete="link"
                                    value={sharedLink}
                                    disabled={true}
                        />
                    </Col>
                    <Col xs="3" md="3">
                        <CopyToClipboard text={`https://track.automa.id/#/transporter/live-share/${encodeURI(localStorage.AutomaCmp)}/${sharedLink}`}
                            disabled={!sharedLink}
                            onCopy={() => this.setState({copied: true})}>
                            <Button size="md" color="primary"><i className="fa fa-copy"></i> Copy</Button>
                        </CopyToClipboard>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        {this.state.copied ? <span style={{color: 'red'}}>Link Copied.</span> : null}
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
} 

const mapStateToProps = (state) => {
    return {
        tokenResult: state.liveMonitoringShareTokenStore.tokenResult,
    }
}
export default connect(mapStateToProps, {fetchLiveMonitoringShare})(AddMasterItemDetaillAdjustment);
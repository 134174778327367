import React, { Component } from 'react';
import ChangePassword from './ChangePassword/ChangePassword'
import { Row, Col } from 'reactstrap';
import TransporterReminder from './Transporter/TransporterReminder/TransporterReminder'
import CompanySettings from './CompanySettings/CompanySettings'
import { AbilityContext} from '../../casl/Can';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    checkRoles = () => {
        return (

            <React.Fragment>
                {this.context.can("view","transporter_reminder_and_company_component@settings")&&
                    <Row>
                        <Col md="6" sm="12">
                            <TransporterReminder />
                        </Col>
                        <Col md="6" sm="12">
                            <CompanySettings />
                        </Col>
                    </Row>
                }
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className="animated fadeIn">
                {this.checkRoles()}
                <Row>
                    
                    <Col md="6" sm="12">
                        <ChangePassword />
                    </Col>
                </Row>
                
            </div>
        );
    }
}

Settings.contextType = AbilityContext;
export default Settings;
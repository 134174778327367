import React, { Component } from 'react'
import { Button, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { connect } from "react-redux";
import { BarLoader } from 'react-spinners';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

// React DateRangePicker
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
// import './react_dates_overrides.css';

import moment from 'moment';
import 'moment/locale/id';

import { fetchFleetReport } from "../../../redux/actions/transporter/aTransporter-FleetReport";
import {fetchCompanyList} from '../../../redux/actions/aUser-CompanyList'
import {fetchSettingList} from '../../../redux/actions/aUser-SettingList'

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow, KmlLayer, Polyline
  } from "react-google-maps"
import { compose } from "recompose"
import customMarker from '../../../assets/img/dots.png'
import customMarkerYellow from '../../../assets/img/yellowdot.png'
import customMarkerRed from '../../../assets/img/red.png'

function generateRandom() {
    return Math.random() * 10000000000000000
  }

const MapWithAMarker = compose(withScriptjs, withGoogleMap)(props => {
    return (
      <GoogleMap defaultZoom={7} defaultCenter={{ lat: -7.6221790314, lng: 110.8043441772 }}>
          <KmlLayer url={props.kmlLink + "&ver=" + generateRandom()} options={{ preserveViewport: true }}/>
          <Polyline
              path={props.markers}
              geodesic={true}
              options={{
                  strokeColor: "#3574dc",
                  strokeOpacity: 0.90,
                  strokeWeight: 5,
                  icons: [
                      {
                          // icon: lineSymbol,
                          offset: "0",
                          repeat: "20px"
                      }
                  ]
              }}
          />

        {props.markers.map(marker => {
          
          const onClick = props.onClick.bind(this, marker)
            return (
                <Marker
                  key={marker.id}
                  onClick={onClick}
                    options={marker.wrn > 0 ? (marker.spd >= 5 ? {icon: customMarker} : {icon: customMarkerYellow}) : {icon: customMarkerRed}}
                  position={{ lat: marker.lat, lng: marker.lng }}
                >
                    {props.selectedMarker === marker &&
                  <InfoWindow>
                    <div>
                      {marker.obd_id}<br/>{marker.time}
                    </div>
                  </InfoWindow>
                }
                </Marker>
            )
        })}
      </GoogleMap>
    )
  })

class FleetsReport extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            startDate: '',
            endDate: '',
            markers: [],
            selectedMarker: false,
        };
    }

    UNSAFE_componentWillMount = () =>  {
        this.props.fetchFleetReport(this.props.match.params.id)
    }

    handleChangeDate = ({startDate, endDate}) => {
        // console.log(startDate, endDate)
        var startValue;
        var endValue;

        if(startDate !== null || endDate !== null){
            var correctorStart = moment(startDate)
                                .subtract(6,'hours');
            var correctorEnd = moment(endDate)
                                    .add(11,'hours')
                                    .add(59, 'minutes')
                                    .add(59, 'seconds');

            startValue = correctorStart
            endValue = correctorEnd
            
            // console.log(startDate, endDate)
            
            this.setState({
                startDate: startValue,
                endDate: endValue
            })
            // console.log(startValue, endValue)
            this.props.fetchFleetReport(this.props.match.params.id, startValue, endValue, true);
        } else {
            this.setState({
                startDate: null,
                endDate: null
            })
            this.props.fetchFleetReport(this.props.match.params.id, startValue, endValue, true);
        }

    }

    fileNameFormatter = () => {
        var baseName = "Fleet Report - ";
        var baseDate = new Date();
        var date = moment(baseDate).format("DD-MMM-YYYY HH:mm");
        var strDate = date.toString();
        return baseName+strDate+".csv";
      }

    dateFormatter = (cell, row) => {
        var date = moment(row.time).format("DD-MMM-YYYY HH:mm:ss");
        return date
    }

    fleetStatusFormat = (cell, row) => {
        if(cell === 0) {
            return (
                <span>Off</span>
            )
        } else {
            return (
                <span>On</span>
            )
        }
    }

    showDatePicker = () => {
        moment.locale('id');
        let startDate= this.state.startDate;
        let endDate= this.state.endDate;
        
        return(
            <DateRangePicker
                startDate={startDate ? moment(startDate,'M/D/YYYY') : null}
                startDateId="startDate"
                endDate={endDate ? moment(endDate,'M/D/YYYY') : null}
                endDateId="endDate"
                isOutsideRange={(day) => day.isAfter(moment().hour(12).add(1, 'days')) || day.isBefore(moment().subtract(2, 'years'))}
                onDatesChange={this.handleChangeDate}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({focusedInput})}
                orientation={this.state.orientation}
                openDirection={this.state.openDirection}
                showClearDates={true}
            />
        )
    }

    showDataTable = () => {
        var options = {
            noDataText: 'No report found!'
        };
        return (
            <BootstrapTable data={this.props.fleetReportTableData.result} version="4" bordered={false} hover pagination search edit options={options} exportCSV={true} csvFileName={this.fileNameFormatter} multiColumnSearch>
              <TableHeaderColumn dataField="no" isKey dataSort csvHeader="no" hidden>No</TableHeaderColumn>
              <TableHeaderColumn dataField="id" searchable dataSort csvHeader="ID" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } }  width="10%">ID</TableHeaderColumn>
              <TableHeaderColumn dataField="dev" dataSort csvHeader="Dev" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%" hidden export={false}>Device</TableHeaderColumn>
              <TableHeaderColumn dataField="sp" dataSort csvHeader="Sp" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Sp</TableHeaderColumn>
              <TableHeaderColumn dataField="lat" dataSort csvHeader="Latitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%" >Latitude</TableHeaderColumn>
              <TableHeaderColumn dataField="lon" dataSort csvHeader="Longitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Longitude</TableHeaderColumn>
              <TableHeaderColumn dataField="acc" dataSort csvHeader="Acc" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Acc</TableHeaderColumn>
              <TableHeaderColumn dataField="spd" dataSort csvHeader="Spd" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Spd</TableHeaderColumn>
              <TableHeaderColumn dataField="wrn" dataSort csvHeader="Engine Status" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%" dataFormat={this.fleetStatusFormat.bind(this)} csvFormat={this.fleetStatusFormat.bind(this)} >Engine Status</TableHeaderColumn>
              <TableHeaderColumn dataField="vsys" dataSort csvHeader="Vsys" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Vsys</TableHeaderColumn>
              <TableHeaderColumn dataField="vraw" dataSort csvHeader="Vraw" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Vraw</TableHeaderColumn>
              <TableHeaderColumn dataField="temp" dataSort csvHeader="Temperature" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Temperature</TableHeaderColumn>
              <TableHeaderColumn dataField="time" dataSort csvHeader="Time" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%" dataFormat={this.dateFormatter.bind(this)} csvFormat={this.dateFormatter.bind(this)}>Time</TableHeaderColumn>
              {/* <TableHeaderColumn dataField="created_on" dataSort csvHeader="Created On" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Created On</TableHeaderColumn> */}
            </BootstrapTable>
        );
    }

    handleClick = (marker, event) => {
        // console.log({ marker })
        this.setState({ selectedMarker: marker })
    }

    render() {
        const { inProgressFleetReport, success } = this.props
        return (
            <div className="animated fadeIn">
                <Link to={'/transporter/fleets'}><Button color="primary" size="sm"><i className="fa fa-arrow-left"></i> Back</Button></Link>
                <hr/>

                <Row>
                    <Col md="12" xs="12">
                        <h6>Select Date Range</h6>
                    </Col>
                    <Col md="12" xs="12">
                        {this.showDatePicker()}
                    </Col>
                </Row><br/>
                <Card>
                    <CardHeader>
                        Fleet Plotting
                    </CardHeader>
                    <CardBody>
                    {
                        this.props.statKML ? 
                        <MapWithAMarker
                            selectedMarker={this.state.selectedMarker}
                            markers={this.props.fleetReportMaps}
                            onClick={this.handleClick}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `400px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            kmlLink={this.props.kml}
                        />
                        :
                        <MapWithAMarker
                            selectedMarker={this.state.selectedMarker}
                            markers={this.props.fleetReportMaps}
                            onClick={this.handleClick}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `400px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            kmlLink={''}
                        />
                    }
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>
                        Fleet Report
                    </CardHeader>
                    <CardBody>
                    {
                        inProgressFleetReport ? 
                        <center><BarLoader color={'#123abc'} loading={inProgressFleetReport}/> <br /> Loading.... Please wait...</center>
                        : 
                        (
                            success ? 
                            this.showDataTable() 
                            : 
                            <center>Error fetching your data... <br/><br/> 
                                <Button className="btn btn-primary" color="primary" size="md" disabled={inProgressFleetReport} onClick={() => this.props.fetchFleetReport(this.props.match.params.id, this.state.startValue, this.state.endValue, true)}>
                                    <i className="fa fa-refresh"></i> 
                                    Try again
                                </Button>
                            </center>
                        )
                    }
                    </CardBody>

                </Card>
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        fleetReportTableData: state.fleetReportTableStore.fleetReportTableData,
        fleetReportMaps: state.fleetReportTableStore.fleetReportMaps,
        inProgressFleetReport: state.fleetReportTableStore.inProgress,
        success: state.fleetReportTableStore.success,
        kml: state.companyStore.companyKML,
        statKML: state.settingStore.kmlSet
      }
}

export default connect(mapStateToProps, {fetchFleetReport, fetchCompanyList, fetchSettingList})(FleetsReport);
import React, { Component } from "react";
import axios from "axios";
import Dropzone from "../../../components/Dropzone/";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  FormGroup,
  Button,
} from "reactstrap";
import PropTypes from "prop-types";
import * as API_LINKS from "../../../redux/config/links";
import { toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import CustomToast from "../../../components/CustomToast/CustomToast";

const propTypes = {
  inProgress: PropTypes.bool,
  success: PropTypes.bool,
  companyListData: PropTypes.object,
  link_logo: PropTypes.string,
  fetchCompanyList: PropTypes.func,
};

const defaultProps = {
  inProgress: false,
  success: true,
  companyListData: {},
  link_logo: "",
};

class CompanySettingsLogoUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  handleSubmit = () => {
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    const { id } = this.state;

    this.setState({
      isLoading: true,
    });

    axios({
      method: "post",
      url: API_LINKS.COMPANY_EDIT_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        logo: id,
      },
    })
      .then(() => {
        self.setState({ isLoading: false });
        self.props.fetchCompanyList();
        return toast.success(
          <CustomToast
            text="Company data successfully changed!"
            icon={<FaCheckCircle />}
          />,
          {
            autoClose: 3000,
          }
        );
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({ isLoading: false });
            return toast.error(
              <CustomToast
                text={error.response.data.message}
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({ isLoading: false });
            return toast.error(
              <CustomToast
                text={error.response.data.message}
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          } else if (error.response.status === 400) {
            self.setState({ isLoading: false });
            return toast.error(
              <CustomToast
                text={error.response.data.message}
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          } else {
            self.setState({ isLoading: false });
            return toast.error(
              <CustomToast
                text="Something went wrong... Please try again later..."
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          }
        } else if (error.request) {
          self.setState({ isLoading: false });
          return toast.error(
            <CustomToast
              text="Request error! Please try again later..."
              icon={<BiSolidError />}
            />,
            {
              autoClose: 3000,
            }
          );
        } else {
          self.setState({ isLoading: false });
          return toast.error(
            <CustomToast
              text="Something went wrong... Please try again later..."
              icon={<BiSolidError />}
            />,
            {
              autoClose: 3000,
            }
          );
        }
      });
  };

  handleOnDrop = (result) => {
    //Dropzone
    if (result.length === 0) {
      this.setState({ companyLogo: [] });
      return;
    }
    if (!result.passed) {
      toast.error(
        <CustomToast
          text={"Photo, " + result.reasonRejected}
          icon={<BiSolidError />}
        />,
        {
          autoClose: 3000,
        }
      );
      return;
    }

    this.setState({ companyLogo: result });

    var self = this;
    const { companyLogo } = this.state;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    this.setState({
      isLoading: true,
    });

    let formData = new FormData();
    formData.append("file", companyLogo.uploadedFiles[0]);
    axios({
      method: "post",
      url: API_LINKS.UPLOAD_IMAGE_FOR_EDIT, //Please change when it's ready
      headers: {
        Authorization: auth,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            self.setState({
              companyLogo: null,
              isLoading: false,
              id: response.data.result.id,
            });
            toast.success(
              <CustomToast
                text="Photo uploaded! Click save to confirm!"
                icon={<FaCheckCircle />}
              />,
              {
                autoClose: 3000,
              }
            );
            // this.handleSubmit(response.data.result.id)
          }
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          self.setState({
            isLoading: false,
          });
          return toast.error(
            <CustomToast
              text="Authorization failed! Please logout and login again to gain authentication"
              icon={<BiSolidError />}
            />,
            {
              autoClose: 3000,
            }
          );
        } else if (
          error.response.status === 404 ||
          error.response.status === 500
        ) {
          self.setState({
            isLoading: false,
          });
          return toast.error(
            <CustomToast
              text="Fatal error! Please contact your local administrator!"
              icon={<BiSolidError />}
            />,
            {
              autoClose: 3000,
            }
          );
        } else {
          self.setState({
            isLoading: false,
          });
          return toast.error(
            <CustomToast
              text="Unknown error! Please contact your local administrator!"
              icon={<BiSolidError />}
            />,
            {
              autoClose: 3000,
            }
          );
        }
      });
  };

  showContent = () => {
    const { link_logo } = this.props;
    const { isLoading } = this.state;
    return (
      <div>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Previous Photo</Label>
          </Col>
          <Col xs="12" md="9">
            {link_logo ? (
              <img
                src={link_logo}
                alt="previous logo"
                height="100%"
                width="256px"
              />
            ) : (
              "No photo saved before!"
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Photo</Label>
          </Col>
          <Col xs="12" md="9">
            <Dropzone
              maxFile={1}
              maxSize={1000000}
              accept="image/png, image/jpeg"
              onDrop={this.handleOnDrop}
              preview={true}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="pull-right">
          <Button
            onClick={() => this.handleSubmit()}
            color="primary"
            className="mr-2"
            disabled={isLoading}
          >
            {isLoading ? "Wait..." : "Save"}
          </Button>
          &nbsp;&nbsp;
        </FormGroup>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Card>
          <CardHeader>Company Logo Change</CardHeader>
          <CardBody>{this.showContent()}</CardBody>
        </Card>
      </div>
    );
  }
}

CompanySettingsLogoUpload.propTypes = propTypes;
CompanySettingsLogoUpload.defaultProps = defaultProps;

export default CompanySettingsLogoUpload;

import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import { onFetchArrayAsync } from "src/helpers/helper";
import { CHECK_SHEET_WEEKLY_DETAIL_APPROVE } from "src/redux/config/links";

interface IProps {
    data: {
        check_sheet_detail_activity_name: string;
        checked: boolean;
        image: string | null;
        notes: string | null;
        id: number | null;
        approve: number | null;
    }[];
    onReload?: () => void;
    onClose?: () => void;
}
const CheckSheetWeeklySubmitted: React.FC<IProps> = ({ data, onReload, onClose }) => {

    const [isRequesting, setIsRequesting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        if(isRequesting){
            setIsRequesting(false);
            onFetchArrayAsync(data.map((item)=>({
                url:CHECK_SHEET_WEEKLY_DETAIL_APPROVE,
                method: "POST",
                data:{
                    id:item.id
                }
            })),{
                beforeSend: () => {setIsLoading(true); return null},
                success: () => {
                    setIsLoading(false)
                    onReload?.();
                    onClose?.();
                    return null
                },
                error: () => {
                    setIsLoading(false)
                    return null
                }
            })
        }
    },[isRequesting, data,])
    return <>
        <div style={{ display: "flex", flexDirection: "row", gap: ".5rem", flexWrap: "wrap" }}>
            {data.map((item, i) => <React.Fragment key={i}>
                <div style={{ display: "flex", flexDirection: "row", gap: ".5rem", flex: "1 1 200px" }}>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <Input
                            style={{marginLeft: 0, position: "static"}}
                            name="activity"
                            type="checkbox"
                            defaultChecked={item.checked}
                            disabled
                        />
                    </div>
                    <div>
                        {item.check_sheet_detail_activity_name}<br />
                        <span>
                            {item.notes}
                        </span><br />
                        <img loading="lazy" src={item.image ?? ""} width={100} height={100} style={{ objectFit: "cover" }} />
                    </div>
                </div>
            </React.Fragment>)}
        </div>
        <Button color="primary" type="button" disabled={isLoading || data.length === data.filter((item)=>item.approve===1).length} onClick={()=>setIsRequesting(true)}>Approve</Button>
        <Button color="warning" type="button" onClick={()=> onClose?.()}>Close</Button>
    </>
}

export default CheckSheetWeeklySubmitted;
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { bootstrapTableOption, fileNameFormat } from "../../../helpers/helper";
import useLazyFetch from "../../../hooks/useLazyFetch";
import { USER_WITHDRAWAL_LIST } from "../../../redux/config/links";
import LoadingContent from "../../../components/LoadingContent/LoadingContent";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import RequestWithdraw from "./contents/RequestWithdraw";
import WithdrawalDelete from "./contents/WithdrawalDelete";

const status = ["", "Accepted", "Declined", "Pending", "Approved"];

const Withdrawal = () => {
  const [modalOpenCreate, setModalOpenCreate] = useState(false);
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  const [modalOpenDelete, setModalOpenDelete] = useState(null);
  const [isGettingWithdrawalList, setIsGettingWithdrawalList] = useState(true);

  const [getWithdrawalList, { loading: loadingGetWithdrawalList, data }] =
    useLazyFetch({
      url: USER_WITHDRAWAL_LIST,
      method: "POST",
    });

  useEffect(() => {
    if (isGettingWithdrawalList) {
      setIsGettingWithdrawalList(false);
      getWithdrawalList();
    }
  }, [isGettingWithdrawalList]);

  return (
    <>
      <LoadingContent
        loading={loadingGetWithdrawalList}
        onReload={() => setIsGettingWithdrawalList(true)}
      >
        <BootstrapTable
          data={data ? data.result : []}
          version="4"
          bordered={false}
          hover
          pagination
          search
          edit
          options={bootstrapTableOption({
            defaultSortName: "request_on",
            defaultSortOrder: "desc",
            btnGroup: (props) => (
              <div>
                {props.exportCSVBtn}{" "}
                <Button
                  color={"primary"}
                  onClick={() => setIsGettingWithdrawalList(true)}
                >
                  <i className="fa fa-refresh"></i> Reload
                </Button>{" "}
                <Button
                  color={"primary"}
                  onClick={() => setModalOpenCreate(true)}
                >
                  <i className="fa fa-plus"></i> Request Withdraw
                </Button>
              </div>
            ),
          })}
          exportCSV={true}
          csvFileName={fileNameFormat("Withdrawal User")}
          multiColumnSearch
        >
          <TableHeaderColumn
            dataField="id"
            isKey
            dataSort
            csvHeader="ID"
            hidden
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id_trx"
            dataSort
            csvHeader="ID Transaksi"
          >
            ID Transaksi
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="account_number"
            dataSort
            csvHeader="Bank Account"
          >
            Bank Account
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="request_on"
            dataSort
            csvHeader="Date Time Request"
          >
            Date Time Request
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status_pengajuan"
            dataSort
            csvHeader="Status"
            dataFormat={(cell) => (
              <>
                <strong
                  style={{
                    ...(cell === 1
                      ? { color: "green" }
                      : cell === 2
                      ? { color: "red" }
                      : {}),
                  }}
                >
                  {status[cell]}
                </strong>
              </>
            )}
          >
            Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="amount"
            dataSort
            csvHeader="Nilai"
            dataFormat={(cell) => (
              <>Rp {Number(cell).toLocaleString("id-ID")}</>
            )}
          >
            Amount
          </TableHeaderColumn>
          {/* <TableHeaderColumn
            dataField="saldo_akhir"
            dataSort
            csvHeader="Saldo Akhir"
            export={false}
            dataFormat={(cell) => <>Rp {cell.toLocaleString("id-ID")}</>}
          >
            Saldo Akhir
          </TableHeaderColumn> */}
          {/* <TableHeaderColumn
            dataField="action"
            export={false}
            dataFormat={(cell, row) => (
              <>
                <ButtonGroup>
                  <Button
                    className="btn btn-info"
                    color="info"
                    onClick={() => setModalOpenEdit({...row})}
                  >
                    <i className="fa fa-pencil"></i> Edit
                  </Button>
                  <Button
                    className="btn btn-danger"
                    color="danger"
                    onClick={() => setModalOpenDelete({...row})}
                  >
                    <i className="fa fa-trash"></i> Delete
                  </Button>
                </ButtonGroup>
              </>
            )}
            width="25%"
          >
            Action
          </TableHeaderColumn> */}
        </BootstrapTable>
      </LoadingContent>

      <Modal
        size="md"
        isOpen={!!modalOpenCreate}
        toggle={() => setModalOpenCreate(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenCreate(null)}>
          Request Widthdraw
        </ModalHeader>
        <ModalBody>
          <RequestWithdraw
            onClose={() => setModalOpenCreate(null)}
            onReload={() => setIsGettingWithdrawalList(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenEdit}
        toggle={() => setModalOpenEdit(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(null)}>
          Edit Widthdraw
        </ModalHeader>
        <ModalBody>
          <RequestWithdraw
            initialValues={modalOpenEdit}
            onClose={() => setModalOpenEdit(null)}
            onReload={() => setIsGettingWithdrawalList(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenDelete}
        toggle={() => setModalOpenDelete(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenDelete(null)}>
          Delete Widthdraw
        </ModalHeader>
        <ModalBody>
          <WithdrawalDelete
            initialValues={modalOpenDelete}
            onClose={() => setModalOpenDelete(null)}
            onReload={() => setIsGettingWithdrawalList(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Withdrawal;

import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import LoadingContent from "src/components/LoadingContent/LoadingContent";
import Table from "src/components/Table/Table";
import useLazyFetch from "src/hooks/useLazyFetch";
import { CHECK_SHEET_WEEKLY_DETAIL_LIST } from "src/redux/config/links";
import CheckSheetWeeklySubmitted from "../CheckSheetWeeklySubmitted/CheckSheetWeeklySubmitted";

interface IProps {
    id: number;
    onClose?: () => void;
}

const CheckSheetWeeklyApprovalList: React.FC<IProps> = ({ id }) => {
    const [isGetting, setIsGetting] = useState(true);
    const [dataCheck, setDataCheck] = useState<{ id: number; modified_on: string; created_on:string }[]>([]);
    const [dataDetailList, setDataDetailList] = useState([]);
    const [dataFiltered, setDataFiltered] = useState<{
        check_sheet_detail_activity_name: string;
        checked: boolean;
        image: string | null;
        notes: string | null;
        id: number | null;
        approve: number | null;
    }[] | null>(null);
    const [getCheckSheetWeeklyDetailList, { loading }] =
        useLazyFetch({
            url: CHECK_SHEET_WEEKLY_DETAIL_LIST,
            method: "POST",
        });

    useEffect(() => {
        if (isGetting) {
            setIsGetting(false);
            getCheckSheetWeeklyDetailList({
                body: {
                    id_check_sheet_weekly: id
                }
            }, (response) => {
                if (!!response) {
                    setDataDetailList(response.data.result);
                    let listDt: { id: number; modified_on: string; id_driver: number; driver_name: string; created_on:string }[] = [];
                    response.data.result.forEach((item: { weeklyRepeats: { modified_on: string; id_driver: number; driver_name: string; created_on:string }[]; }) => {
                        item.weeklyRepeats.forEach((sub, i: number) => {
                            console.log(sub)
                            if (!!!listDt.find((i) => i.created_on === sub.created_on)) {
                                listDt = [...listDt, { id: i, modified_on: sub.modified_on, id_driver: sub.id_driver, driver_name: sub.driver_name, created_on: sub.created_on }];
                            }
                        })
                    })
                    setDataCheck(listDt);
                }
            })
        }
    }, [isGetting, getCheckSheetWeeklyDetailList, id])
    return <>
        <LoadingContent
            loading={loading}
            onReload={() => { setIsGetting(true); return null; }}>


            <Table
                name={"Check Sheet weekly"}
                data={dataCheck}
                columns={[{
                    field: "id",
                    name: "ID",
                    isKey: true,
                    hidden: true,
                    dataSort: true,
                }, {
                    field: "driver_name",
                    name: "Driver",
                    dataSort: true,
                    width: "25%",
                }, {
                    name: "Approval",
                    width: "25%",
                    dataFormat: (cell, row) => {
                        const dt = dataDetailList.map((item: { check_sheet_detail_activity_name: string; weeklyRepeats: { created_on: string }[] }) => {

                            const wr = item.weeklyRepeats.find((item: { created_on: string; }) => item.created_on === row.created_on) as unknown as { link: string; notes: string; modified_on: string; id: number; approve: number | null;  created_on:string }

                            return {
                                check_sheet_detail_activity_name: item.check_sheet_detail_activity_name,
                                checked: wr !== null,
                                image: wr?.link ?? null,
                                notes: wr?.notes ?? null,
                                id: wr?.id,
                                approve: wr?.approve
                            }
                        })

                        
                        return <>{dt.length === dt.filter((item)=>item.approve===1).length ? "Approved" : ""}</>
                    }
                }, {
                    field: "created_on",
                    name: "Created On",
                    dataSort: true,
                    width: "25%",
                }, {
                    name: "Action",
                    width: "25%",
                    dataFormat: (cell, row) => {
                        return <>
                            <Button color="primary" size="sm" onClick={() => {
                                const dt = dataDetailList.map((item: { check_sheet_detail_activity_name: string; weeklyRepeats: { created_on: string }[] }) => {

                                    const wr = item.weeklyRepeats.find((item: { created_on: string; }) => item.created_on === row.created_on) as unknown as { link: string; notes: string; modified_on: string; id: number; approve: number | null;  created_on:string }

                                    return {
                                        check_sheet_detail_activity_name: item.check_sheet_detail_activity_name,
                                        checked: wr !== null,
                                        image: wr?.link ?? null,
                                        notes: wr?.notes ?? null,
                                        id: wr?.id,
                                        approve: wr?.approve
                                    }
                                })
                                setDataFiltered([...dt]);
                            }}>
                                Check Sheet
                            </Button>
                        </>
                    }
                }]}
            />
        </LoadingContent>

        <Modal
            isOpen={!!dataFiltered}
            toggle={() => setDataFiltered(null)}
            size="lg"
        >
            <ModalHeader toggle={() => setDataFiltered(null)}>
                Approval
            </ModalHeader>
            <ModalBody>
                {!!dataFiltered ? <CheckSheetWeeklySubmitted data={dataFiltered} onClose={() => setDataFiltered(null)} onReload={()=>setIsGetting(true)} /> : null}
            </ModalBody>
        </Modal>
    </>
}

export default CheckSheetWeeklyApprovalList;
export default {
    items: [
      {
        name: 'TrackX',
        url: '/transporter',
        icon: 'fa fa-car',
        children: [
          {
              name: 'Main Dashboard',
              url: '/transporter/dashboard',
              // icon: 'icon-speedometer',
            },
            {
              name: 'Fleet Maintenance',
              url: '/transporter/fleet-maintenance',
              // icon: 'fa fa-wrench',
            },
            {
              name: 'Mechanics',
              url: '/transporter/mechanics',
              // icon: 'fa fa-user',
            },
        ]
      }
    ]
  };
  
export default {
  items: [
    {
      name: "Home",
      url: "/welcome",
      icon: "home icon",
    },
    {
      name: "TrackX",
      url: "/transporter",
      icon: "truck icon",
      children: [
        {
          name: "Check Sheet",
          url: "/transporter/check-sheet",
          // icon: "fa fa-check-square-o",
        },
        {
          name: "Delivery Orders",
          url: "/transporter/delivery-order",
          // icon: "fa fa-file-text",
        },
        {
          name: "Drivers",
          url: "/transporter/drivers",
          // icon: "fa fa-id-badge",
        },
        {
          name: "Drivers Assistant",
          url: "/transporter/drivers-assistant",
          // icon: "fa fa-male",
        },
        {
          name: "DO Category",
          url: "/transporter/delivery/category",
          // icon: "fa fa-tree",
        },
        
      ],
    },
  ],
};

import React, { Component } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import { fetchFleetListTable } from "../../../redux/actions/transporter/aTransporter-FleetListTable";
import { fetchCarBrandsTable } from "../../../redux/actions/transporter/aTransporter-CarBrandsTable";
import { fetchCarGeneralTypeTable } from "../../../redux/actions/transporter/aTransporter-CarGeneralTypeTable";
import { fetchCarTypeTable } from "../../../redux/actions/transporter/aTransporter-CarTypeTable";
import { fetchCarFuelTable } from "../../../redux/actions/transporter/aTransporter-CarFuelTypeTable";
import SummaryWidgets from "../SavedPlaces/Widgets/SummaryWidgets";
import FleetDatatable from "./Fleets-Datatable";
import moment from "moment";
import Select from "react-select";
import "react-select/dist/react-select.css";
import * as API_LINKS from "../../../redux/config/links";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { AbilityContext } from "../../../casl/Can";
import NumberFormat from "react-number-format";
import { fetchCompanyList } from "../../../redux/actions/aUser-CompanyList";
import { fetchFleetMaintenanceReminderDashboard } from "../../../redux/actions/transporter/preventive-maintenance.act";
import InputImage from "../../../components/InputImage/InputImage";
import { onFetch, onFetchAll } from "../../../helpers/helper";

class Fleets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      modalAdd: false,

      //For add state
      selectedBrands: {},
      brandsList: [],

      selectedType: {},
      typeList: [],

      selectedGeneralType: {},
      generalTypeList: [],

      selectedFuel: {},
      fuelList: [],

      selectedRegYear: 0,
      selectedManYear: 0,

      odometer: 0,
      fuelConsumption: 0.0,
      odometerError: "",
      fuelConsumptionError: "",

      tkmCost: 0,
      emptyWeight: 0,

      companyListData: [],
      fleetListTableData: [],

      fleetsMaintenanceReminder: [],
      loadingFleetMaintenanceReminder: "loading",

      activeFleetMaintenanceTab: "almost-maintenance",

      inputImageValue: [
        {
          image: null,
          desc: "",
        },
      ],
      inputImageQrSolar: null
    };
  }

  componentDidUpdate(prevProps) {
    const { companyListData, fleetListTableData } = this.props;
    if (prevProps.companyListData !== companyListData) {
      this.setState({
        companyListData: companyListData,
      });
    }
    if (prevProps.fleetListTableData !== fleetListTableData) {
      this.setState({
        fleetListTableData: fleetListTableData.result,
      });
      const queryString = window.location.hash.split("?");
      const urlParams = new URLSearchParams(queryString[1]);
      if (
        Array.isArray(fleetListTableData.result) &&
        fleetListTableData.result.length !== 0
      ) {
        this.linkHeaderClickHandler(urlParams.get("type"));
      }
    }
    const { fleetsMaintenanceReminder, loadingFleetMaintenanceReminder } =
      this.props;
    if (prevProps.fleetsMaintenanceReminder !== fleetsMaintenanceReminder) {
      this.setState({
        fleetsMaintenanceReminder,
      });
    }
    if (
      prevProps.loadingFleetMaintenanceReminder !==
      loadingFleetMaintenanceReminder
    ) {
      this.setState({
        loadingFleetMaintenanceReminder,
      });
    }
  }

  UNSAFE_componentWillMount = () => {
    this.props.fetchCarBrandsTable();
    this.props.fetchCarGeneralTypeTable();
    this.props.fetchCarTypeTable();
    this.props.fetchCarFuelTable();
    this.props.fetchCompanyList();
    this.props.fetchFleetMaintenanceReminderDashboard();
  };

  getTypeFromCompanyData = () => {
    const { companyListData } = this.state;
    if (!companyListData.result) return null;
    if (companyListData.result.length === 0) return null;
    return companyListData.result[0].id_type;
  };

  toggleOpenAdd = () => {
    this.loadPropertiesDatatoSelect();
    this.setState({
      modalAdd: !this.state.modalAdd,
    });
  };

  toggleCloseAdd = () => {
    this.setState({
      modalAdd: false,
      driverBirthDate: "",
      date: "",
    });
  };

  loadPropertiesDatatoSelect = () => {
    var list = [];
    //For General Type
    if (this.props.carGeneralTypeTableData.length !== 0) {
      var carGeneralTypeData = this.props.carGeneralTypeTableData.result;

      carGeneralTypeData.forEach((p, index) => {
        var carGeneralData = {
          label: carGeneralTypeData[index].name,
          value: carGeneralTypeData[index].id,
        };
        list.push(carGeneralData);
      });
    }
    this.setState({ generalTypeList: list });
    // console.log(list)

    //For Car Brands
    list = [];
    if (this.props.carBrandsTableData.length !== 0) {
      var carBrandsData = this.props.carBrandsTableData.result;

      carBrandsData.forEach((p, index) => {
        var carBrandData = {
          label: carBrandsData[index].name,
          value: carBrandsData[index].id,
        };
        list.push(carBrandData);
      });
    }
    this.setState({ brandsList: list });

    //For Car Type
    list = [];
    if (this.props.carTypeTableData.length !== 0) {
      var carTypeData = this.props.carTypeTableData.result;

      carTypeData.forEach((p, index) => {
        var carType = {
          label: carTypeData[index].name,
          value: carTypeData[index].id,
        };
        list.push(carType);
      });
    }
    this.setState({ typeList: list });

    //Fuel
    list = [];
    if (this.props.carFuelTableData.length !== 0) {
      var carFuelData = this.props.carFuelTableData.result;

      carFuelData.forEach((p, index) => {
        var carFuel = {
          label: carFuelData[index].name,
          value: carFuelData[index].id,
        };
        list.push(carFuel);
      });
    }
    this.setState({ fuelList: list });
  };

  handleChangeGeneralType = (selectedGeneralType) => {
    this.setState({ selectedGeneralType });
    // console.log(`Option selected:`, selectedGeneralType);
  };

  handleChangeBrands = (selectedBrands) => {
    this.setState({ selectedBrands });
    // console.log(`Option selected:`, selectedBrands);
  };

  handleChangeType = (selectedType) => {
    this.setState({ selectedType });
    // console.log(`Option selected:`, selectedType);
  };

  handleChangeFuel = (selectedFuel) => {
    this.setState({ selectedFuel });
    // console.log(`Option selected:`, selectedFuel);
  };

  handleChangeFuelConsumption = (e) => {
    const target = e.target;
    if (target.validationMessage === "Please match the requested format.") {
      this.setState({
        fuelConsumption: target.value,
        fuelConsumptionError: "Invalid format!",
      });
    } else {
      this.setState({
        fuelConsumption: target.value,
        fuelConsumptionError: target.validationMessage,
      });
    }
  };

  handleChangeOdometer = (e) => {
    const target = e.target;
    if (target.validationMessage === "Please match the requested format.") {
      this.setState({
        odometer: target.value,
        odometerError: "Invalid format!",
      });
    } else {
      this.setState({
        odometer: target.value,
        odometerError: target.validationMessage,
      });
    }
  };

  onChangeRegYear = (e) => {
    this.setState({
      selectedYear: e.target.value,
    });
    // console.log("selected value:", e.target.value);
  };

  onChangeManYear = (e) => {
    this.setState({
      selectedYear: e.target.value,
    });
    // console.log("selected value:", e.target.value);
  };

  getDropList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(25), (v, i) => (
      <option key={i} value={year - i}>
        {year - i}
      </option>
    ));
  };

  handleChangeTaxExpiry = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      taxDate: _date,
      taxExpiryDate: convertedDate,
    });
  };

  handleChangePVIExpiry = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      pviDate: _date,
      pviExpiryDate: convertedDate,
    });
  };

  viewAddFleet = () => {
    const {
      selectedBrands,
      selectedGeneralType,
      selectedType,
      selectedFuel,
      tkmCost,
      isAdding,
    } = this.state;
    return (
      <div className="container animated fadeIn">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Car General Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={selectedGeneralType}
              onChange={this.handleChangeGeneralType}
              options={this.state.generalTypeList}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Car Brands</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={selectedBrands}
              onChange={this.handleChangeBrands}
              options={this.state.brandsList}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Car Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={selectedType}
              onChange={this.handleChangeType}
              options={this.state.typeList}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fuel Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={selectedFuel}
              onChange={this.handleChangeFuel}
              options={this.state.fuelList}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>

        {/* disini input image */}
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Pictures</Label>
          </Col>
          <Col xs="12" md="9">
            {this.state.inputImageValue.map((item, index) => (
              <React.Fragment key={index}>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <InputImage
                          value={item.image}
                          onChange={(result) =>
                            this.setState({
                              inputImageValue: [
                                ...this.state.inputImageValue.map(
                                  (subitem, subindex) =>
                                    subindex === index
                                      ? {
                                          ...subitem,
                                          image: result,
                                        }
                                      : subitem
                                ),
                              ],
                            })
                          }
                          onRemove={(dt, idx) => {
                            this.setState({
                              inputImageValue: [
                                ...this.state.inputImageValue.map((dti) => ({
                                  ...dti,
                                  image: dti.image.filter(
                                    (subitem, subindex) => subindex !== idx
                                  ),
                                })),
                              ],
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Input
                          type="text"
                          value={item.desc}
                          placeholder="Description"
                          onChange={(e) =>
                            this.setState({
                              inputImageValue: [
                                ...this.state.inputImageValue.map(
                                  (subitem, subindex) =>
                                    subindex === index
                                      ? {
                                          ...subitem,
                                          desc: e.currentTarget.value,
                                        }
                                      : subitem
                                ),
                              ],
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {this.state.inputImageValue.length > 1 ? (
                  <Row>
                    <Col>
                      <Button
                        size="small"
                        onClick={() =>
                          this.setState({
                            inputImageValue: [
                              ...this.state.inputImageValue.filter(
                                (subitem, subindex) => index !== subindex
                              ),
                            ],
                          })
                        }
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ) : null}
                <hr />
              </React.Fragment>
            ))}
            {this.state.inputImageValue.length < 3 ? (
              <Row>
                <Col>
                  <Button
                    size="small"
                    onClick={() =>
                      this.setState({
                        inputImageValue: [
                          ...this.state.inputImageValue,
                          {
                            image: null,
                            desc: "",
                          },
                        ],
                      })
                    }
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Registration Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addRegNumber"
              placeholder="B2121UKL"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Door Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="doorNumber"
              placeholder="e.g. T123"
              disabled={this.state.isAdding}
              maxLength="9"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Registration Year</Label>
          </Col>
          <Col xs="12" md="9">
            <select
              onChange={this.onChangeRegYear}
              disabled={this.state.isAdding}
            >
              {this.getDropList()}
            </select>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Manufacture Year</Label>
          </Col>
          <Col xs="12" md="9">
            <select
              onChange={this.onChangeManYear}
              disabled={this.state.isAdding}
            >
              {this.getDropList()}
            </select>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Cylinder Capacity</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addCylCap"
              placeholder="1200"
              disabled={this.state.isAdding}
              maxLength="5"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">VIN</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addVIN"
              placeholder="JKHA820AVD713"
              disabled={this.state.isAdding}
              maxLength="25"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Engine Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addEngineNumber"
              placeholder="1K14B32JHSS08"
              disabled={this.state.isAdding}
              maxLength="25"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fuel Consumption (km/l)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="fuelConsumption"
              placeholder="10"
              disabled={this.state.isAdding}
              value={this.state.fuelConsumption}
              onChange={this.handleChangeFuelConsumption}
              pattern="[+-]?([0-9]*[.])?[0-9]+"
              autoComplete="off"
            />
            <div className="invalid-feedback d-block">
              {this.state.fuelConsumptionError}
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Odometer (last in m)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="odometer"
              placeholder="10129.2"
              disabled={this.state.isAdding}
              value={this.state.odometer}
              onChange={this.handleChangeOdometer}
              pattern="[+-]?([0-9]*[.])?[0-9]+"
              autoComplete="off"
            />
            <div className="invalid-feedback d-block">
              {this.state.odometerError}
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Tax Expiry</Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={this.state.taxDate}
              onChange={this.handleChangeTaxExpiry}
              placeholderText="Pick a tax expiry date"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">PVI Expiry</Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={this.state.pviDate}
              onChange={this.handleChangePVIExpiry}
              placeholderText="Pick a PVI expiry date"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <h6>Distance Matrix</h6>
        <br />
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Max Load (in Kg)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addMaxLoad"
              placeholder="8000"
              disabled={this.state.isAdding}
              maxLength="5"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Manual Speed Reduction</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addMtSpeed"
              placeholder="0.5"
              disabled={this.state.isAdding}
              maxLength="5"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Rest Interval (in Minutes)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addRestInterval"
              placeholder="30"
              disabled={this.state.isAdding}
              maxLength="5"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">
              Interval Between Rest (in Minutes)
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addIntervalBetween"
              placeholder="1000"
              disabled={this.state.isAdding}
              maxLength="5"
            />
          </Col>
        </FormGroup>
        {/* Fuel Section */}
        <dd>
          <i>
            This section will allow you to fine tune the fuel report. To
            maximise the result, please contact automa's certified technician.
          </i>
        </dd>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fuel Tank Voltage (in v)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addFuelTankVoltage"
              placeholder="0.123"
              disabled={this.state.isAdding}
              maxLength="5"
              value={this.state.full_tank_voltage}
              onChange={(e) =>
                this.setState({ full_tank_voltage: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Empty Tank Voltage (in v)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addEmptyTankVoltage"
              placeholder="0.008"
              disabled={this.state.isAdding}
              maxLength="5"
              value={this.state.empty_tank_voltage}
              onChange={(e) =>
                this.setState({ empty_tank_voltage: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fuel Tank Capacity (in L)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addFuelTankCapacity"
              placeholder="40"
              min="0"
              max="500"
              disabled={this.state.isAdding}
              maxLength="5"
              value={this.state.fuel_tank_capacity}
              onChange={(e) =>
                this.setState({ fuel_tank_capacity: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fuel Alert Sensitivity</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addFuelAlertSensitivity"
              placeholder="100"
              min="0"
              max="100"
              disabled={this.state.isAdding}
              maxLength="5"
              value={this.state.fuel_alert_sensitivity}
              onChange={(e) =>
                this.setState({ fuel_alert_sensitivity: e.target.value })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={3}>
              <Label htmlFor="">QR Solar</Label>
          </Col>
          <Col xs={12} md={9}>
            <InputImage
              value={this.state.inputImageQrSolar}
              onChange={(result) =>
                this.setState({
                  inputImageQrSolar: result,
                })
              }
              onRemove={() => {
                this.setState({
                  inputImageQrSolar: null,
                });
              }}
            />
          </Col>
        </FormGroup>
        <hr />
        <h5>Tkm and Carbon Emission Section</h5>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Empty Weight (in Kg)</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="number"
              name="text-input"
              id="addEmptyWeight"
              placeholder="40"
              min="0"
              max="20000"
              disabled={this.state.isAdding}
              maxLength="5"
              value={this.state.emptyWeight}
              onChange={(e) => this.setState({ emptyWeight: e.target.value })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Tkm Cost</Label>
          </Col>
          <Col xs="12" md="9">
            <NumberFormat
              value={tkmCost}
              thousandSeparator={true}
              disabled={isAdding}
              prefix={"Rp. "}
              onValueChange={(values) => {
                // eslint-disable-next-line
                const { formattedValue, value } = values;
                // formattedValue = $2,223
                // value ie, 2223
                this.setState({ tkmCost: value });
              }}
            />
          </Col>
        </FormGroup>
        {this.state.isAdding ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.state.isAdding} /> <br />{" "}
            Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => this.handleAdd()}
              size="sm"
              color="primary"
              disabled={this.state.isAdding}
            >
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseAdd()}
              size="sm"
              color="success"
              disabled={this.state.isAdding}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  handleAddNext = (dtValue, imgResp = null) => {

    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    let self = this;

    axios({
      method: "post",
      url: API_LINKS.FLEET_INPUT_URL, //Please change when it's ready
      headers: {
        Authorization: auth,
      },
      data: {...dtValue,
        qr_link_solar: imgResp
      },
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            if (this.state.inputImageValue.length > 0) {
              onFetchAll(
                {
                  url: API_LINKS.FLEET_INPUT_IMAGE_URL,
                  data: this.state.inputImageValue.map((item) => ({
                    file: item.image,
                    image_desc: item.desc,
                    id_fleet: response.data.result.id,
                    type_image: 1,
                  })),
                  type: "form",
                },
                {
                  beforeSend: () => {},
                  success: () => {
                    self.setState({
                      selectedRegYear: 0,
                      selectedManYear: 0,
                      selectedBrands: {},
                      selectedFuel: {},
                      selectedGeneralType: {},
                      selectedType: {},
                      odometer: 0,
                      fuelConsumption: 0.0,
                      tkmCost: 0,
                      emptyWeight: 0,
                      isAdding: false,
                      inputImageValue: [
                        {
                          image: null,
                          desc: "",
                        },
                      ],
                    });
                    toast.success("Data saved!");
                    self.props.fetchFleetListTable();
                    self.toggleCloseAdd();
                  },
                  error: () => {
                    self.setState({
                      selectedRegYear: 0,
                      selectedManYear: 0,
                      selectedBrands: {},
                      selectedFuel: {},
                      selectedGeneralType: {},
                      selectedType: {},
                      odometer: 0,
                      fuelConsumption: 0.0,
                      tkmCost: 0,
                      emptyWeight: 0,
                      isAdding: false,
                      inputImageValue: [
                        {
                          image: null,
                          desc: "",
                        },
                      ],
                    });
                    toast.success("Data saved!");
                    self.props.fetchFleetListTable();
                    self.toggleCloseAdd();
                  },
                }
              );
            } else {
              self.setState({
                selectedRegYear: 0,
                selectedManYear: 0,
                selectedBrands: {},
                selectedFuel: {},
                selectedGeneralType: {},
                selectedType: {},
                odometer: 0,
                fuelConsumption: 0.0,
                tkmCost: 0,
                emptyWeight: 0,
                isAdding: false,
                inputImageValue: [
                  {
                    image: null,
                    desc: "",
                  },
                ],
              });
              toast.success("Data saved!");
              self.props.fetchFleetListTable();
              self.toggleCloseAdd();
            }
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({
              isAdding: false,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({
              isAdding: false,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            self.setState({
              isAdding: false,
            });
            return toast.error(error.response.data.message);
          } else {
            self.setState({
              isAdding: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          self.setState({
            isAdding: false,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          self.setState({
            isAdding: false,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  }

  handleAdd = () => {
    // const totalFleetsLimitSubs = this.totalFleetsBasedOnSubs();
    if (this.props.totalFleet >= this.props.maxTotalFleet) {
      toast.error("Upgrade your subscription to insert another fleet!");
    } else {
      this.setState({ isAdding: true });
      var self = this;

      //Variables for text input
      var _carGeneralType = self.state.selectedGeneralType.value;
      var _carBrands = self.state.selectedBrands.value;
      var _carType = self.state.selectedType.value;
      var _fuelType = self.state.selectedFuel.value;
      var _regNumber = document.querySelector("#addRegNumber").value;
      var _doorNumber = document.querySelector("#doorNumber").value;
      var _regYear = self.state.selectedRegYear;
      var _manYear = self.state.selectedManYear;
      var _cylCap = document.querySelector("#addCylCap").value;
      var _VIN = document.querySelector("#addVIN").value;
      var _engineNumber = document.querySelector("#addEngineNumber").value;
      var _fuelConsumption;
      var _odometer;
      var _expPajak = this.state.taxExpiryDate;
      var _expKir = this.state.pviExpiryDate;
      var _maxLoad = document.querySelector("#addMaxLoad").value;
      var _speedRed = document.querySelector("#addMtSpeed").value;
      var _restInt = document.querySelector("#addRestInterval").value;
      var _intBetween = document.querySelector("#addIntervalBetween").value;
      // Fuel
      var _fullTankVoltage;
      var _emptyTankVoltage;
      var _fuelTankCapacity;
      var _fuelAlertSensitivity;
      var _emptyWeight;
      var _tkmCost;

      if (
        this.state.fuelConsumption !== null ||
        this.state.fuelConsumption !== undefined ||
        this.state.fuelConsumption > 0
      ) {
        _fuelConsumption = this.state.fuelConsumption;
      } else {
        _fuelConsumption = 0;
      }

      if (
        this.state.odometer !== null ||
        this.state.odometer !== undefined ||
        this.state.odometer > 0
      ) {
        _odometer = this.state.odometer;
      } else {
        _odometer = 0;
      }

      // Fuel
      if (
        this.state.full_tank_voltage !== null ||
        this.state.full_tank_voltage !== undefined ||
        this.state.full_tank_voltage > 0
      ) {
        _fullTankVoltage = this.state.full_tank_voltage;
      } else {
        _fullTankVoltage = 0;
      }

      if (
        this.state.empty_tank_voltage !== null ||
        this.state.empty_tank_voltage !== undefined ||
        this.state.empty_tank_voltage > 0
      ) {
        _emptyTankVoltage = this.state.empty_tank_voltage;
      } else {
        _emptyTankVoltage = 0;
      }

      if (
        this.state.fuel_tank_capacity !== null ||
        this.state.fuel_tank_capacity !== undefined ||
        this.state.fuel_tank_capacity > 0
      ) {
        _fuelTankCapacity = this.state.fuel_tank_capacity;
      } else {
        _fuelTankCapacity = 0;
      }

      if (
        this.state.fuel_alert_sensitivity !== null ||
        this.state.fuel_alert_sensitivity !== undefined ||
        this.state.fuel_alert_sensitivity > 0
      ) {
        _fuelAlertSensitivity = this.state.fuel_alert_sensitivity;
      } else {
        _fuelAlertSensitivity = 0;
      }

      if (
        this.state.tkmCost !== null ||
        this.state.tkmCost !== undefined ||
        this.state.tkmCost > 0
      ) {
        _tkmCost = this.state.tkmCost;
      } else {
        _tkmCost = 0;
      }

      if (
        this.state.emptyWeight !== null ||
        this.state.emptyWeight !== undefined ||
        this.state.emptyWeight > 0
      ) {
        _emptyWeight = this.state.emptyWeight;
      } else {
        _emptyWeight = 0;
      }

      if (_carGeneralType === "" || _carGeneralType === null) {
        self.setState({ isAdding: false });
        toast.error("Car general type is invalid!!");
      } else if (_carBrands === "" || _carBrands === null) {
        self.setState({ isAdding: false });
        toast.error("Car brands is invalid!");
      } else if (_carType === "" || _carType === null) {
        self.setState({ isAdding: false });
        toast.error("Car type is invalid!");
      } else if (_fuelType === "" || _fuelType === null) {
        self.setState({ isAdding: false });
        toast.error("Fuel type is invalid!");
      } else if (_regNumber === "" || _regNumber === null) {
        self.setState({ isAdding: false });
        toast.error("Registration Number is invalid!");
      } else if (_regYear === "" || _regYear === null) {
        self.setState({ isAdding: false });
        toast.error("Registration Year is invalid!");
      } else {

        const dtValue = {
          id_car_general_type: _carGeneralType,
          id_car_brands: _carBrands,
          id_car_type: _carType,
          reg_number: _regNumber,
          door_number: _doorNumber,
          reg_year: _regYear,
          manufacture_year: _manYear,
          cyl_cap: _cylCap,
          vehicle_id_number: _VIN,
          engine_number: _engineNumber,
          id_fuel_type: _fuelType,
          fuel_consumption: _fuelConsumption,
          odometer: _odometer,
          exp_pajak: _expPajak,
          exp_kir: _expKir,
          max_tonnage: _maxLoad,
          mt_speed_reduction: _speedRed,
          rest_interval: _restInt,
          interval_between_rest: _intBetween,
          full_tank_voltage: _fullTankVoltage,
          empty_tank_voltage: _emptyTankVoltage,
          fuel_tank_capacity: _fuelTankCapacity,
          fuel_alert_sensitivity: _fuelAlertSensitivity,
          empty_weight: _emptyWeight,
          tkm_cost: _tkmCost,
        }

        if(this.state.inputImageQrSolar!==null){

          onFetch({
            url: API_LINKS.UPLOAD_IMAGE_FOR_EDIT,
            method: "post",
            type: "formdata",
            data: {
              file: this.state.inputImageQrSolar,
              image_desc: "",
            }
          }, {
            beforeSend: ()=> null,
            success:(data)=>{
                this.handleAddNext(dtValue, !!data ? `${data.data.result.link}` : null)
            },
            error:()=>{
              this.handleAddNext(dtValue, null)
            }});
        }
        else{
          this.handleAddNext(dtValue, null)
        } 
      }
    }
  };

  checkRoles = () => {
    return (
      <div>
        {this.context.can("view", "add_fleet_button@fleets") &&
        this.getTypeFromCompanyData() !== 2 ? (
          <React.Fragment>
            {this.props.totalFleet >= this.props.maxTotalFleet ? (
              true
            ) : (
              <div>
                <Button
                  onClick={this.toggleOpenAdd}
                  size="md"
                  color="success"
                  disabled={this.props.inProgress}
                >
                  <i className="fa fa-plus"></i> Add Fleet
                </Button>
                &nbsp;&nbsp;
              </div>
            )}
          </React.Fragment>
        ) : null}
      </div>
    );
  };

  linkHeaderClickHandler = (type) => {
    const { fleetListTableData } = this.props;
    if (type === "all-fleets")
      this.setState({ fleetListTableData: fleetListTableData.result });
    if (type === "assigned-fleet")
      this.setState({
        fleetListTableData: fleetListTableData.result.filter(
          (r) => r.obd_code !== null
        ),
      });
    if (type === "unsigned-fleet")
      this.setState({
        fleetListTableData: fleetListTableData.result.filter(
          (r) => r.obd_code === null
        ),
      });
  };

  linkMaintenanceHeaderClickHandler = (data) => {
    this.setState({ fleetListTableData: data });
  };

  FleetAlmostMaintenanceSummary = (props) => {
    const { fleetsMaintenanceReminder } = this.state;
    return (
      <>
        {fleetsMaintenanceReminder.almost.map((d, index) => (
          <Col key={index} md={3} xs={12}>
            <SummaryWidgets
              header={d.fleet_count}
              headerLink={d.data}
              onLinkHeaderClick={this.linkMaintenanceHeaderClickHandler}
              mainText={d.maintenance_package_code}
              icon={"fa fa-wrench"}
              color={"info"}
            />
          </Col>
        ))}
      </>
    );
  };

  FleetExpiredMaintenanceSummary = (props) => {
    const { fleetsMaintenanceReminder } = this.state;
    return (
      <>
        {fleetsMaintenanceReminder.expired.map((d, index) => (
          <Col key={index} md={3} xs={12}>
            <SummaryWidgets
              header={d.fleet_count}
              headerLink={d.data}
              onLinkHeaderClick={this.linkMaintenanceHeaderClickHandler}
              mainText={d.maintenance_package_code}
              icon={"fa fa-wrench"}
              color={"warning"}
            />
          </Col>
        ))}
      </>
    );
  };

  getMaintenanceTabContents = () => {
    return [
      {
        dataTab: "almost-maintenance",
        tabName: "In or close to maintenance km",
        tabIcon: "",
        component: <this.FleetAlmostMaintenanceSummary />,
      },
      {
        dataTab: "expired-maintenance",
        tabName: "Passed by maintenance km",
        tabIcon: "",
        component: <this.FleetExpiredMaintenanceSummary />,
      },
    ];
  };

  MaintenanceTabs = (props) => {
    const { activeFleetMaintenanceTab } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col xs="12" md="12" className="mb-4">
            <Nav tabs>
              {this.getMaintenanceTabContents().map((content, index) => (
                <NavItem key={index + 1}>
                  <NavLink
                    className={classnames({
                      active: activeFleetMaintenanceTab === content.dataTab,
                    })}
                    onClick={() => {
                      this.onActiveMaintenanceTabChange(content.dataTab);
                    }}
                  >
                    <i className={content.tabIcon}></i>&nbsp;
                    {content.tabName}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeFleetMaintenanceTab}>
              {this.getMaintenanceTabContents().map((content, index) => (
                <TabPane tabId={content.dataTab} key={index + 1}>
                  <div
                    style={{ display: "flex", width: "auto", overflow: "auto" }}
                  >
                    {content.component}
                  </div>
                </TabPane>
              ))}
            </TabContent>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  onActiveMaintenanceTabChange = (dataTab) => {
    this.setState({
      activeFleetMaintenanceTab: dataTab,
    });
  };

  refreshMaintenanceDashboard = () => {
    this.props.fetchFleetMaintenanceReminderDashboard();
  };

  render() {
    const { totalFleet, totalAssignedFleet, totalUnsignedFleet } = this.props;
    const {
      fleetListTableData,
      loadingFleetMaintenanceReminder,
      fleetsMaintenanceReminder,
    } = this.state;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col md={3} xs={12}>
            <SummaryWidgets
              header={totalFleet}
              headerLink={"all-fleets"}
              onLinkHeaderClick={this.linkHeaderClickHandler}
              mainText={"Total Fleet(s)"}
              icon={"fa fa-car"}
              color={"primary"}
            />
          </Col>
          <Col md={3} xs={12}>
            <SummaryWidgets
              header={totalAssignedFleet}
              headerLink={"assigned-fleet"}
              onLinkHeaderClick={this.linkHeaderClickHandler}
              mainText={"OBD Assigned Fleet(s)"}
              icon={"fa fa-car"}
              color={"success"}
            />
          </Col>
          <Col md={3} xs={12}>
            <SummaryWidgets
              header={totalUnsignedFleet}
              headerLink={"unsigned-fleet"}
              onLinkHeaderClick={this.linkHeaderClickHandler}
              mainText={"OBD Unsigned Fleet(s)"}
              icon={"fa fa-car"}
              color={"warning"}
            />
          </Col>
          {/* <Col md={3} xs={12}>
                    <SummaryWidgets header={approvedPlace} mainText={'Approved Location(s)'} icon={'fa fa-check'} color={'success'}/>
                </Col>
                <Col md={3} xs={12}>
                    <SummaryWidgets header={notApprovedPlace} mainText={'Not Approved Location(s)'} icon={'fa fa-ban'} color={'warning'}/>
                </Col>
                <Col md={3} xs={12}>
                    <SummaryWidgets header={deactivatedPlace} mainText={'Deactivated Location(s)'} icon={'fa fa-trash'} color={'danger'}/>
                </Col> */}
        </Row>
        {loadingFleetMaintenanceReminder === "loading" && (
          <Row>
            <Col md={12} xs={12}>
              <center>
                <BarLoader color={"#123abc"} loading={true} />
                <br /> Loading.... Please wait...
              </center>
            </Col>
          </Row>
        )}
        {loadingFleetMaintenanceReminder === "loaded" &&
          (fleetsMaintenanceReminder.almost.length > 0 ||
            fleetsMaintenanceReminder.expired.length > 0) && (
            <Row>
              <Col md={12} xs={12}>
                <this.MaintenanceTabs />
              </Col>
            </Row>
          )}
        <Card>
          <CardHeader>Fleets List</CardHeader>
          <CardBody>
            {this.props.successFleetFetch ? (
              <div>
                <Row className="pull-right">{this.checkRoles()}</Row> <br />
                <br />
                <br />{" "}
              </div>
            ) : (
              true
            )}
            <FleetDatatable
              fleetListTableData={fleetListTableData}
              refreshMaintenanceDashboard={this.refreshMaintenanceDashboard}
            />
          </CardBody>

          {/* Modal for Adding Cards */}
          <Modal
            isOpen={this.state.modalAdd}
            toggle={this.toggleOpenAdd}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleOpenAdd}>Add Fleet</ModalHeader>
            <ModalBody>{this.viewAddFleet()}</ModalBody>
          </Modal>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fleetListTableData: state.fleetListTableStore.fleetListTableData,
    successFleetFetch: state.fleetListTableStore.success,
    totalFleet: state.fleetListTableStore.totalFleet,
    totalAssignedFleet: state.fleetListTableStore.totalAssignedFleet,
    totalUnsignedFleet: state.fleetListTableStore.totalUnsignedFleet,
    inProgressFleetList: state.fleetListTableStore.inProgress,
    carGeneralTypeTableData:
      state.carGeneralTypeTableStore.carGeneralTypeTableData,
    inProgressCarGeneralType: state.carGeneralTypeTableStore.inProgress,
    carBrandsTableData: state.carBrandsTableStore.carBrandsTableData,
    inProgressCarBrands: state.carBrandsTableStore.inProgress,
    carTypeTableData: state.carTypeTableStore.carTypeTableData,
    inProgressCarType: state.carTypeTableStore.inProgress,
    carFuelTableData: state.carFuelTableStore.carFuelTableData,
    inProgressCarFuel: state.carFuelTableStore.inProgress,

    maxTotalFleet: state.authStore.subs.total_fleet,
    companyListData: state.companyStore.companyListData,
    fleetsMaintenanceReminder:
      state.fleetMaintenanceReminderDashboardListStore
        .fleetsMaintenanceReminder,
    loadingFleetMaintenanceReminder:
      state.fleetMaintenanceReminderDashboardListStore.loading,
  };
};

Fleets.contextType = AbilityContext;
export default connect(mapStateToProps, {
  fetchFleetListTable,
  fetchCarBrandsTable,
  fetchCarGeneralTypeTable,
  fetchCompanyList,
  fetchCarTypeTable,
  fetchCarFuelTable,
  fetchFleetMaintenanceReminderDashboard,
})(Fleets);

import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import axios from "axios";
import { TRIPL_BID_STATUS_TO_READY_TO_REVIEW_URL } from "../../../redux/config/links";
import handleErrorResponseCode from "../../../helpers/error-response-code-handler";
import { BarLoader } from "react-spinners";
import { toast } from "react-toastify";
class WorkOrderSubmitForReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalConfirmationOpen: false,
      onProcessSubmitReadytoReview: false,
    };
  }

  modalConfirmationOpen = () => {
    const { modalConfirmationOpen } = this.state;
    this.setState({
      modalConfirmationOpen: !modalConfirmationOpen,
    });
  };

  modalConfirmationClose = () => {
    const { modalConfirmationOpen } = this.state;
    this.setState({
      modalConfirmationOpen: !modalConfirmationOpen,
    });
  };

  ModalConfirmation = (props) => {
    const { woNumber } = props;
    const { onProcessSubmitReadytoReview } = this.state;
    return (
      <React.Fragment>
        {onProcessSubmitReadytoReview ? (
          <center>
            <BarLoader
              color={"#123abc"}
              loading={onProcessSubmitReadytoReview}
            />
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <>
            <p>
              Are you sure to change {woNumber} status to{" "}
              <b>Ready to Review </b>?
            </p>
            <br />
            <Button
              color="success"
              onClick={this.onSubmitReadytoReviewClicked.bind(this)}
            >
              Yes
            </Button>
            &nbsp;
            <Button
              color="danger"
              onClick={() => {
                this.modalConfirmationClose();
              }}
            >
              No
            </Button>
          </>
        )}
      </React.Fragment>
    );
  };

  readytoReviewSubmitting = (isSubmitting) => {
    if (isSubmitting)
      this.setState({
        onProcessSubmitReadytoReview: isSubmitting,
      });

    if (!isSubmitting)
      this.setState({
        onProcessSubmitReadytoReview: isSubmitting,
      });
  };

  onSubmitReadytoReviewClicked = () => {
    const { bidId } = this.props;
    // console.log(`ID BID ${bidId}`);
    this.readytoReviewSubmitting(true);
    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    let auth = loginCreds.token;
    axios({
      method: "post",
      url: TRIPL_BID_STATUS_TO_READY_TO_REVIEW_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        id_bid: bidId,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(
            "Bid status has been change to Ready to Review succesfully!"
          );
          this.readytoReviewSubmitting(false);
          this.modalConfirmationClose();
        }
      })
      .catch(function (error) {
        toast.error(handleErrorResponseCode(error));
      });
    this.readytoReviewSubmitting(false);
  };

  render() {
    const { woNumber, size } = this.props;
    const { modalConfirmationOpen } = this.state;
    return (
      <React.Fragment>
        &nbsp;
        <Button
          color="primary"
          size={size}
          onClick={() => {
            this.modalConfirmationOpen();
          }}
        >
          <i className="fa fa-paper-plane"></i> Submit for Review
        </Button>
        <Modal
          isOpen={modalConfirmationOpen}
          toggle={this.modalConfirmationOpen}
          className={this.props.className}
          backdrop="static"
        >
          <ModalHeader toggle={this.modalConfirmationClose}>
            Confirmation Modal
          </ModalHeader>
          <ModalBody>
            <this.ModalConfirmation woNumber={woNumber} />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default WorkOrderSubmitForReview;

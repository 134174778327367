import React, { Component } from "react";
import { Button, FormGroup, Label, Input, Col, FormFeedback } from "reactstrap";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import handleErrorResponseCode from "../../../../../../../helpers/error-response-code-handler";
import {
  WORKSHOP_PIC_ADD_URL,
  WORKSHOP_PIC_EDIT_URL,
} from "../../../../../../../redux/config/links";
import { connect } from "react-redux";
import { fetchWorkshopPicPosition } from "../../../../../../../redux/actions/transporter/workshop.act";
import { FaCheckCircle } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import CustomToast from "../../../../../../../components/CustomToast/CustomToast";

class AddEditPicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      picPositionsFetchInProgress: true,
      picPositionsSelect: [],
    };
  }

  componentDidMount() {
    const { picPositionsFetchInProgress, picPositionsSelect } = this.props;
    this.setState({
      picPositionsFetchInProgress: picPositionsFetchInProgress,
      picPositionsSelect: picPositionsSelect,
    });
  }

  validation = () => {
    let yup = Yup.object().shape({
      name: Yup.string().required("Pic Postion name is required!"),
      phone_number: Yup.number().required("Phone number is required!"),
      // id_workshop_pic_position: Yup.number().required('Pic Position Required'),
      email: Yup.string().email(),
    });
    return yup;
  };

  form = () => {
    const { initialValues } = this.props;
    const { isAdd, isEdit } = this.props;
    const { fetchRefresh, closeForm } = this.props;
    return (
      <Formik
        validateOnMount
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
          let auth = loginCreds.token;
          setSubmitting(true);
          values.id_workshop_pic_position = 9;
          if (isAdd) {
            axios({
              method: "post",
              url: WORKSHOP_PIC_ADD_URL,
              headers: {
                Authorization: auth,
              },
              data: values,
            })
              .then((response) => {
                if (response.data.statusCode === 201) {
                  setSubmitting(false);
                  toast.success(
                    <CustomToast
                      text="Pic has been saved succesfully!"
                      icon={<FaCheckCircle />}
                    />,
                    {
                      autoClose: 3000,
                    }
                  );
                  closeForm();
                  fetchRefresh();
                }
              })
              .catch(function (error) {
                setSubmitting(false);
                toast.error(
                  <CustomToast
                    text={handleErrorResponseCode(error)}
                    icon={<BiSolidError />}
                  />,
                  {
                    autoClose: 3000,
                  }
                );
              });
          }

          if (isEdit) {
            axios({
              method: "post",
              url: WORKSHOP_PIC_EDIT_URL,
              headers: {
                Authorization: auth,
              },
              data: values,
            })
              .then((response) => {
                if (response.data.statusCode === 200) {
                  setSubmitting(false);
                  toast.success(
                    <CustomToast
                      text="Pic has been edited succesfully!"
                      icon={<FaCheckCircle />}
                    />,
                    {
                      autoClose: 3000,
                    }
                  );
                  closeForm();
                  fetchRefresh();
                }
              })
              .catch(function (error) {
                setSubmitting(false);
                toast.error(
                  <CustomToast
                    text={handleErrorResponseCode(error)}
                    icon={<BiSolidError />}
                  />,
                  {
                    autoClose: 3000,
                  }
                );
              });
          }
          setSubmitting(false);
        }}
        validationSchema={this.validation()}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          handleBlur,
          handleChange,
          isValid,
          isSubmitting,
        }) => (
          <form>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Name</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  autoComplete="name"
                  valid={!errors.name}
                  invalid={touched.name && !!errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.name}</FormFeedback>
              </Col>
            </FormGroup>
            {/* <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Pic Position</Label>
                    </Col>
                    <Col xs="12" md="9">
                        {
                            picPositionsFetchInProgress? 
                                <center><BarLoader color={'#123abc'} loading={picPositionsFetchInProgress}/> <br /> Loading.... Please wait...</center>
                            :
                                <SelectField
                                    id="id_workshop_pic_position"
                                    name="id_workshop_pic_position"
                                    label="id_workshop_pic_position"
                                    placeholder="Select Pic Position"
                                    options={picPositionsSelect}
                                    value={values.id_workshop_pic_position}
                                    isMulti={false}
                                    onChange={async (field, selection) => {
                                        setFieldValue(field, selection.value, true);
                                    }}
                                    onBlur={handleBlur}
                                    touched={touched.id_workshop_pic_position}
                                    error={errors.id_workshop_pic_position}
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                    disabled={isSubmitting}
                                />
                        }
                    </Col>
                    <FormFeedback>{errors.id_workshop_pic_position}</FormFeedback>
                </FormGroup> */}
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Phone Number</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="number"
                  name="phone_number"
                  id="phone_number"
                  placeholder="Phone Number"
                  autoComplete="phone_number"
                  valid={!errors.phone_number}
                  invalid={touched.phone_number && !!errors.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.phone_number}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Whatsapp Number</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="number"
                  name="whatsapp_number"
                  id="whatsapp_number"
                  placeholder="Whatsapp Number"
                  autoComplete="whatsapp_number"
                  valid={!errors.whatsapp_number}
                  invalid={touched.whatsapp_number && !!errors.whatsapp_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.whatsapp_number || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.whatsapp_number}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Email</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  autoComplete="email"
                  valid={!errors.email}
                  invalid={touched.email && !!errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.email}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup>
              <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="mr-1"
                disabled={!isValid || isSubmitting}
              >
                {isSubmitting ? "Wait..." : "Submit"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={isSubmitting}
                onClick={closeForm}
              >
                Close
              </Button>
            </FormGroup>
          </form>
        )}
      />
    );
  };

  render() {
    return <React.Fragment>{this.form()}</React.Fragment>;
  }
}
const mapStateToProps = (state) => ({
  picPositionsSelect: state.workshopPicPositionsStore.picPositionsSelect,
  picPositionsFetchInProgress: state.workshopPicPositionsStore.inProgress,
});
export default connect(mapStateToProps, { fetchWorkshopPicPosition })(
  AddEditPicForm
);

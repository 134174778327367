import React, { Component } from 'react'
import {
    Button,
    FormGroup,
    Label,
    Col,
    FormFeedback
} from 'reactstrap'
import axios from 'axios';
import { connect } from 'react-redux'
import {DRIVER_FLEETS_INPUT_URL, DRIVER_FLEETS_DELETE_URL}  from '../../../../../redux/config/links'
import { fetchFleetListTable } from '../../../../../redux/actions/transporter/aTransporter-FleetListTable';
import { BarLoader } from 'react-spinners';
import * as Yup from 'yup'
import { Formik } from 'formik'
import {toast} from 'react-toastify'
import handleErrorResponseCode from '../../../../../helpers/error-response-code-handler'
import { SelectField } from '../../../../LandLord/Widgets/SelectField'
class AddDeleteDriverFleets extends Component {

    componentDidMount = () => {
        this.props.fetchFleetListTable()
    }

    validation = () => {
        const {isAdd} = this.props
        let yup = null
        if(isAdd){
            Yup.object().shape({
                id_fleet : Yup.number().required('Fleet cannot empty!'),
            })
        }
        return yup
    }

    getSubmitText = () => {
        const {isAdd, isDelete} = this.props
        if(isAdd)
            return "Add"
        if(isDelete)
            return "Delete"
    }

    getSubmitColor= () => {
        const {isAdd, isDelete} = this.props
        if(isAdd)
            return "primary"
        if(isDelete)
            return "danger"
    }

    form = () => {
        const {initialValues, isAdd, isDelete, refreshTable} = this.props
        const {completeFleetSelectData, fleetDatainProgress} = this.props
        return(
            <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, resetForm}) => {
                let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                let auth = loginCreds.token;
                setSubmitting(true)
                if(isAdd){
                    axios({
                            method: 'post',
                            url: DRIVER_FLEETS_INPUT_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : {
                                id_driver: initialValues.id_driver,
                                id_fleet: values.id_fleet
                            }
                        })
                        .then((response) => {
                            if(response.status === 200){
                                setSubmitting(false)
                                toast.success('Driver fleet has been saved succesfully!')
                                resetForm()
                                refreshTable()
                            }
                        })
                    .catch(function(error){
                        setSubmitting(false)
                        toast.error(handleErrorResponseCode(error))
                    })
                }
                if(isDelete){
                    axios({
                            method: 'post',
                            url: DRIVER_FLEETS_DELETE_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : {id_pairing: initialValues.id}
                        }).then((response) => {
                            if(response.status === 200){
                                setSubmitting(false)
                                toast.success('Driver fleet has been deleted succesfully!')
                                resetForm()
                                refreshTable()
                            }
                            return null
                        })
                    .catch(function(error){
                        setSubmitting(false)
                        toast.error(handleErrorResponseCode(error))
                    })
                }
                setSubmitting(false)
            }} 
            validationSchema={this.validation()}
            render={({
            values,errors,
            touched,setFieldValue,
            handleSubmit,handleBlur,
            handleReset, 
            isValid,isSubmitting,
            }) => (
                <form>
                        {isAdd &&
                                <>
                                    <FormGroup row>
                                        <Col md="4">
                                            <Label htmlFor="text-input">Bind With Fleet</Label>
                                        </Col>
                                        <Col xs="8" md="8">
                                            {fleetDatainProgress && 
                                                <center>
                                                    <BarLoader color={'#123abc'} loading={true}/>
                                                         <br /> Loading... Please Wait...
                                                    </center>
                                            }
                                            {!fleetDatainProgress && 
                                                <SelectField
                                                    id="id_fleet"
                                                    name="id_fleet"
                                                    label="id_fleet"
                                                    placeholder="Select Fleet"
                                                    options={completeFleetSelectData}
                                                    value={values.id_fleet}
                                                    isMulti={false}
                                                    onChange={async (field, selection) => {
                                                        if(selection)
                                                            setFieldValue(field, selection.value, true);
                                                    }}
                                                    onBlur={handleBlur}
                                                    touched={touched.id_fleet}
                                                    error={errors.id_fleet}
                                                    isClearable={false}
                                                    backspaceRemovesValue={false}
                                                    disabled={isSubmitting}
                                                />
                                            }
                                        <FormFeedback>{errors.id_fleet}</FormFeedback>
                                        </Col>
                                    </FormGroup>
                                </>
                            }
                            {isDelete &&
                                <FormGroup row>
                                    <Col md="4">
                                            <Label htmlFor="text-input">Are you sure to delete selected row?</Label>
                                        </Col>
                                </FormGroup>
                            }
                            <FormGroup>
                                    <Button type="button" 
                                            onClick={handleSubmit} 
                                            color={this.getSubmitColor()}
                                            className="mr-1" 
                                            disabled={!isDelete? (isSubmitting || !isValid) : false}>{isSubmitting ? 'Wait...' : this.getSubmitText()}
                                    </Button>
                                    {!isDelete &&
                                        <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button>
                                    }
                            </FormGroup>
                    </form>
                )}
            />
        )
    }

    render(){
        return(
            <React.Fragment>
                {this.form()}
            </React.Fragment>
        )
    }

} 

const mapStateToProps = (state) => {
    return {
        completeFleetSelectData: state.fleetListTableStore.completeFleetSelectData,
        fleetDatainProgress: state.fleetListTableStore.inProgress,
    }
}
export default connect(mapStateToProps, {fetchFleetListTable})(AddDeleteDriverFleets);
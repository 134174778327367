import React, { Component } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import GreenEconomy from "../../assets/img/green-economy.png";
import { connect } from "react-redux";
import { fetchFleetNotificationDashboard } from "../../redux/actions/transporter/aTransporter-FleetNotificationDashboard";
import { fetchFleetMaintenanceNotificationDashboard } from "../../redux/actions/transporter/aTransporter-FleetMaintenanceDashboardNotification";
import { fetchDriversNotificationDashboard } from "../../redux/actions/transporter/aTransporter-DriversNotificationDashboard";
import { fetchTriPLGroupAssignFindNotification } from "../../redux/actions/transporter/aTransporter-TriPLGrouping";
import { fetchCompanyList } from "../../redux/actions/aUser-CompanyList";
import { fetchSettingList } from "../../redux/actions/aUser-SettingList";
import { fetchFleetMaintenanceReminderDashboard } from "../../redux/actions/transporter/preventive-maintenance.act";
import { NotificationContext } from "./NotificationWrapper";
import { fetchSaldo } from '../../redux/actions/wallet/aWallet-Saldo'

class WelcomeMessage extends Component {

  static contextType = NotificationContext;

  UNSAFE_componentWillMount() {
    setTimeout(() => {
      this.props.fetchCompanyList();
      this.props.fetchSettingList();
      this.reloadNotification();
      this.props.fetchSaldo();
    }, 1200);
  }

  componentDidUpdate(){
    if(this.context.reloadMessages){
      this.context.setReloadMessages(false);
      this.reloadNotification();
    }
  }

  reloadNotification = () => {
    this.props.fetchFleetMaintenanceNotificationDashboard();
    this.props.fetchFleetNotificationDashboard();
    this.props.fetchDriversNotificationDashboard();
    this.props.fetchTriPLGroupAssignFindNotification();
    this.props.fetchFleetMaintenanceReminderDashboard();
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardHeader>
            <i className="fa fa-envelope-open"></i> A Welcome Message
          </CardHeader>
          <CardBody style={{ "fontSize": "11pt" }}>
            <h3>Hi Automers,</h3>

            <p>
              Thank you for trusting us to help you achieve a sustainable supply
              chain.
            </p>

            <p>
              Automa is here to democratize the green economy. We help
              manufacturers, operators and retailers achieve economic and
              environmental sustainability through <br />
              (1) <b>digitization</b>, (2){" "}
              <b>hassle-free carbon emission tracking</b>, and (3){" "}
              <b>data monetization</b>, using supply chain activity analytics
              and the Internet of Things (IoT). <br />
              By using this platform, you can make your business conducted
              transparently and with integrity. Two key values that enable
              businesses to run their business models in a sharing economy.{" "}
              <br />
              At the same time you will increase your effectiveness, make your
              efficiency better, and measure your environmental impact.
            </p>

            <p>
              If you have a full subscription, you will activate all the
              features:
              <br />
              1. <b>TrackX</b>: to manage and analyze all transportation,
              expense, first to end mile delivery as well as third party carrier
              orders.
              <br />
              2. <b>ConnectX</b>: to manage and analyze your stock, batch of
              goods, order process from receiving goods to billing customers
              <br />
              3. <b>DockX</b>: to manage and analyze your connections with
              vendors, customers, banks, and other external parties
              <br />
              4. <b>CarbonX</b>: to analyze your scope 1, 2 and 3 carbon
              emissions.
              <br />
              Let's move together to create a sustainable economy so that we can
              live on our beloved earth!
            </p>

            <p>
              Regards,
              <br />
              Automa Control Center
            </p>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <i className="fa fa-leaf"></i> Our Current Solution to Green Economy
          </CardHeader>
          <CardBody>
            <img src={GreenEconomy} height="auto" width="100%" alt="" />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalFleetNotification:
      state.fleetNotificationDashboardStore.totalNotification,
  };
};
export default connect(mapStateToProps, {
  fetchFleetNotificationDashboard,
  fetchDriversNotificationDashboard,
  fetchCompanyList,
  fetchSettingList,
  fetchFleetMaintenanceNotificationDashboard,
  fetchTriPLGroupAssignFindNotification,
  fetchFleetMaintenanceReminderDashboard,
  fetchSaldo
})(WelcomeMessage);

import React, {Component} from 'react'
import DriverFleetsDatatable from './DriverFleetsDatatable'
import { connect } from 'react-redux'
import { fetchDriverFleets } from '../../../../redux/actions/transporter/aTransporter-DriversTable';
import { HashLoader } from 'react-spinners';

class DriverFleets extends Component {
    constructor(props){
        super(props)
        this.state={
            driverFleetsData: []
        }
        this.props.fetchDriverFleets(this.props.idDriver)
    }

    refreshTableData = () => {
        this.props.fetchDriverFleets(this.props.idDriver)
    }

    render(){
        const {driverFleetsData, driverFleetsLoading} = this.props
        // console.log(driverFleetsData)
        return(
            <div className="animated fadeIn">
                <>
                    {driverFleetsLoading === "loading" &&
                        <center>
                            <HashLoader color={'#123abc'} loading={true} /> 
                            <br /> Loading.... Please wait...
                        </center> 
                    }
                    {driverFleetsLoading === "loaded" &&
                            <DriverFleetsDatatable 
                                idDriver={this.props.idDriver}
                                refreshTable={this.refreshTableData}
                                driverFleets={driverFleetsData}/>
                    }
                </>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        driverFleetsData: state.driverFleetsStore.driverFleetsData,
        driverFleetsLoading: state.driverFleetsStore.loading,
    }
}
export default connect(mapStateToProps, {fetchDriverFleets})(DriverFleets);
import React, { Component } from 'react'
import {Button} from 'reactstrap'
import ReactDropzone from 'react-dropzone'
import helper from './dropzone-helper.js'
import "./styles.css";

class Dropzone extends Component {      

    constructor(props){

        super(props);

        this.state = {
            files: [],
            preview: false,
            toVerify : {
                accept : '',
                maxFile : 0,
                maxSize : 0 
            },
        };

    }

    componentDidMount(){

        const {accept, maxFile, maxSize, preview} = this.props

        this.setState({toVerify : {

             accept : accept,
            maxFile : maxFile,
            maxSize : maxSize 

        }, preview : preview}); 

    }

    handleOnDrop = (newFile) => {

        let numberOfFileNow = this.state.files.length
        let toVerify = this.state.toVerify

        let result = helper.verifyFile(newFile[0], toVerify, numberOfFileNow)

        if(!result.passed)
        
            return this.props.onDrop(result)
        
        this.setState({files: [...this.state.files, newFile[0]]});

        result.uploadedFiles = this.state.files

        this.props.onDrop(result)

    }

    handleOnRemove = async (index) => {

        let removeAtIndexFiles = [...this.state.files]
        removeAtIndexFiles.splice(index,1)
        await this.setState({files: removeAtIndexFiles});

        let result = await helper.verifyFilesStateLength(this.state.files);  
        
        if(!result)
            
            this.props.onDrop([])

    }

    render(){

        const {accept, maxFile, maxSize, files, preview} = this.state
        
        return(

            <ReactDropzone onDrop={this.handleOnDrop} 
                           accept={accept} 
                           maxFile={maxFile} 
                           maxSize={maxSize}>

                    {({getRootProps, getInputProps}) => (

                            <div {...getRootProps({className:'dropzone'})}>
                                    <input {...getInputProps()} />

                                    <p>Drag and drop or click to select files</p>
                            <aside>
                                {files.map((file, index) => (

                                    preview ?
                                    
                                            <React.Fragment key={index}>

                                                <img src={URL.createObjectURL(file)}
                                                     alt={file.name}
                                                     className="img-thumbnail" />  
                                                    
                                                <Button
                                                    size="sm"
                                                    color="danger"
                                                    onClick={this.handleOnRemove.bind(this, index)} 
                                                    block
                                                >Delete</Button>

                                                <br/>

                                            </React.Fragment>

                                        : null
                                       
                                ))}
                            </aside>
                            </div>   
                    )}

            </ReactDropzone>
              
        )

    }

}

export default Dropzone
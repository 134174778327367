import React, { Component } from "react";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from "recharts";

class TemperatureChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempArr: [
        
      ],
      timestamp: this.props.timestamp,
    };
    this.updateData = this.updateData.bind(this);
  }

  updateData() {
    if (this.props.timestamp < 10) {
        let newTempArr = this.state.tempArr.slice();
        // newTempArr[this.props.timestamp - 1].tempMachine1 = this.props.tempMachine1;
        // newTempArr[this.props.timestamp - 1].tempMachine2 = this.props.tempMachine2;
        this.setState({ tempArr: newTempArr });
        // console.log(this.state.tempArr)
      } else {
        if (this.state.tempArr.length >= 10) {
          this.setState({
            tempArr: this.state.tempArr.slice(1, this.state.tempArr.length + 1),
          });
          // console.log(this.state.tempArr)
        }
        // console.log(+this.state.tempArr)
        this.setState(prevState => ({
          tempArr: [
            ...prevState.tempArr,
            { date: this.props.timestamp, 
              tempMachine1: this.props.tempMachine1,
              tempMachine2: this.props.tempMachine2,
              tempMachine3: this.props.tempMachine3,
              tempMachine4: this.props.tempMachine4  
            },
          ],
        }));
      }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.timestamp !== this.props.timestamp;
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateData();
    if (prevProps.timestamp === this.props.timestamp) {
      // console.log(prevProps.timestamp, "prevProps.seconds");
      // console.log(this.props.timestamp, "this.props.seconds");
    }
  }

  componentDidMount() {
    this.updateData();
  }

  render() {
    return (
      <div className="area-graph">
        <ResponsiveContainer minHeight={200}>
          <LineChart
            width={700}
            height={450}
            data={this.state.tempArr}
            margin={{ top: 10, right: 30, left: 0, bottom: 10 }}
          >
            <XAxis dataKey="date" stroke="#4e4e4e" />
            <YAxis
              ticks={[0, 20, 40, 60, 80, 100, 120, 140]}
              domain={[0, 150]}
              stroke="#4e4e4e"
            />
            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="tempMachine1" stroke="#3498db" strokeWidth={2} activeDot={{ r: 6 }}/>
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default TemperatureChart;
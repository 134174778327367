import React, { useEffect, useState } from "react";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { MAINTENANCE_MECHANIC_TASK_LIST } from "../../../../../redux/config/storing.links";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  bootstrapTableOption,
  fileNameFormat,
} from "../../../../../helpers/helper";
import {
  Button,
  ButtonGroup,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import TaskAdd from "./TaskAdd";
import TaskDetailList from "./TaskDetailList";
import TaskDelete from "./TaskDelete";
import SummaryWidgets from "../../../../Widgets/SummaryWidgets";

const TaskList = ({ dataMechanic = null }) => {
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  const [modalOpenDelete, setModalOpenDelete] = useState(null);
  const [openAddMechanic, setOpenAddMechanic] = useState(null);
  const [openTaskDetail, setOpenTaskDetail] = useState(null);

  const [isGettingMechanic, setIsGettingMechanic] = useState(true);

  const [onTaskList, { loading, data }] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_TASK_LIST,
    method: "post",
  });

  useEffect(() => {
    if (isGettingMechanic) {
      setIsGettingMechanic(false);
      onTaskList({});
    }
  }, [isGettingMechanic, onTaskList]);

  const dataFiltered = (!!dataMechanic && !!dataMechanic.id && data ? data.result.filter(
    (item) =>
      item.id_storing_maintenance_mechanic === dataMechanic.id
  ) : []);
  
  return (
    <>
      <Row>
        <Col md={3} xs={12}>
          <SummaryWidgets
            header={dataFiltered.length}
            mainText={"Total Task"}
            icon={"fa fa-id-card"}
            color={"success"}
          />
        </Col>
      </Row>
      <LoadingContent
        loading={loading}
        onReload={() => setIsGettingMechanic(true)}
      >
        <BootstrapTable
          data={dataFiltered}
          version="4"
          bordered={false}
          hover
          pagination
          search
          edit
          options={bootstrapTableOption({
            btnGroup: (props) => (
              <div>
                {props.exportCSVBtn}{" "}
                <Button
                  color={"primary"}
                  onClick={() => setIsGettingMechanic(true)}
                >
                  <i className="fa fa-refresh"></i> Reload
                </Button>{" "}
                <Button
                  color={"primary"}
                  onClick={() =>
                    setOpenAddMechanic({
                      id_storing_maintenance_mechanic: dataMechanic.id,
                    })
                  }
                >
                  <i className="fa fa-plus"></i> Add Task
                </Button>{" "}
              </div>
            ),
          })}
          exportCSV={true}
          csvFileName={fileNameFormat({ name: "" })}
          multiColumnSearch
        >
          <TableHeaderColumn
            dataField="id"
            isKey
            dataSort
            csvHeader="ID"
            hidden
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="task_name" dataSort csvHeader="Task">
            Task
          </TableHeaderColumn>
          <TableHeaderColumn dataField="desc" dataSort csvHeader="Description">
            Description
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="created_on"
            dataSort
            csvHeader="Created On"
          >
            Created On
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="id"
            dataFormat={(cell, row) => (
              <>
                <ButtonGroup>
                  <Button
                    size="sm"
                    color="warning"
                    onClick={() =>
                      setModalOpenEdit({
                        ...row,
                        id_storing_maintenance_mechanic: dataMechanic.id,
                      })
                    }
                  >
                    <i className="fa fa-edit"></i> Edit
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => setModalOpenDelete({ ...row })}
                  >
                    <i className="fa fa-trash"></i> Delete
                  </Button>
                </ButtonGroup>
                <br />
                <Button
                  size="sm"
                  color={"primary"}
                  onClick={() => setOpenTaskDetail(row)}
                >
                  Detail
                </Button>
              </>
            )}
          >
            Action
          </TableHeaderColumn>
        </BootstrapTable>
      </LoadingContent>

      <Modal
        size="md"
        isOpen={!!openAddMechanic}
        toggle={() => setOpenAddMechanic(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenAddMechanic(null)}>
          Add Task
        </ModalHeader>
        <ModalBody>
          <TaskAdd
            initialValues={openAddMechanic}
            onClose={() => setOpenAddMechanic(null)}
            onReload={() => setIsGettingMechanic(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenEdit}
        toggle={() => setModalOpenEdit(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(null)}>
          Edit Task
        </ModalHeader>
        <ModalBody>
          <TaskAdd
            initialValues={modalOpenEdit}
            isEdit
            onClose={() => setModalOpenEdit(null)}
            onReload={() => setIsGettingMechanic(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        isOpen={!!openTaskDetail}
        toggle={() => setOpenTaskDetail(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenTaskDetail(null)}>
          Task Detail
        </ModalHeader>
        <ModalBody>
          <TaskDetailList dataTask={openTaskDetail} />
        </ModalBody>
      </Modal>
      <Modal
        size="md"
        isOpen={!!modalOpenDelete}
        toggle={() => setModalOpenDelete(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenDelete(null)}>
          Delete Task
        </ModalHeader>
        <ModalBody>
          <TaskDelete
            initialValues={modalOpenDelete}
            onClose={() => setModalOpenDelete(null)}
            onReload={() => setIsGettingMechanic(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default TaskList;

import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  // Image,
} from "@react-pdf/renderer";
import Header from './Components/Header'
import Body from './Components/Body'

Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: './assets/fonts/Roboto-Regular.ttf',
      },
      {
        fontStyle: 'bold',
        src: './assets/fonts/Roboto-Bold.ttf',
      },
      {
        fontStyle: 'bolditalic',
        src: './assets/fonts/Roboto-BoldItalic.ttf',
      },
      {
        fontStyle: 'black',
        src: './assets/fonts/Roboto-Black.ttf',
      },
  ],
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff'
    },
})

const PrintableSingleWorkOrder = (props) => (
    <Page size="A4" style={styles.page} orientation="portrait">
      {/* Header for PDF */}
        <Header woNumber={props.wo_number} 
          id_request_wo_3pl={props.id_request_wo_3pl} 
          id_inbound_stocker={props.id_inbound_stocker}
          id_stocker_outbound={props.id_stocker_outbound}
          est_arrival_time={props.est_arrival_time}
        />
        {/* Body part */}
        <Body 
          company={props.company}
          company_phone={props.company_phone}
          do_number={props.do_number}
          driver_name={props.driver_name}
          fleet_plate={props.fleet_plate}
          id={props.id}
          wo_number={props.wo_number}
          wo_seq={props.wo_seq}
          wo_desc={props.wo_desc}
          wo_destination_name={props.wo_destination_name}
          wo_address={props.wo_address}
          pic_name={props.pic_name}
          pic_phone={props.pic_phone}
          loc_name={props.loc_name}
          lat={props.lat}
          lng={props.lng}
          dept_lat={props.dept_lat}
          dept_lng={props.dept_lng}
          est_departure_time={props.est_departure_time}
          est_arrival_time={props.est_arrival_time}
          cargo_weight={props.cargo_weight}
          loading_time={props.loading_time}
          distance={props.distance}
          main_customer={props.main_customer}
          main_customer_phone={props.main_customer_phone}
          id_request_wo_3pl={props.id_request_wo_3pl}
          id_stocker_outbound={props.id_stocker_outbound}
          token={props.token}
          tokenDataURI={props.tokenDataURI}
          stocker_items={props.stocker_items}
          company_requestor={props.company_requestor}
          />
    </Page>
)

export default (
  props
) => (
    <Document
      author="Automa IoT Platform"
      subject="Work Order - Automa"
      title="Work Order - Automa"
    >
      <PrintableSingleWorkOrder 
        do_number={props.do_number}
        company={props.company}
        company_phone={props.company_phone}
        driver_name={props.driver_name}
        fleet_plate={props.fleet_plate}
        id={props.id}
        wo_number={props.wo_number}
        wo_seq={props.wo_seq}
        wo_desc={props.wo_desc}
        wo_destination_name={props.wo_destination_name}
        wo_address={props.wo_address}
        pic_name={props.pic_name}
        pic_phone={props.pic_phone}
        loc_name={props.loc_name}
        lat={props.lat}
        lng={props.lng}
        dept_lat={props.dept_lat}
        dept_lng={props.dept_lng}
        est_departure_time={props.est_departure_time}
        est_arrival_time={props.est_arrival_time}
        cargo_weight={props.cargo_weight}
        loading_time={props.loading_time}
        distance={props.distance}
        main_customer={props.main_customer}
        main_customer_phone={props.main_customer_phone}
        id_request_wo_3pl={props.id_request_wo_3pl}
        id_inbound_stocker={props.id_inbound_stocker}
        id_stocker_outbound={props.id_stocker_outbound}
        id_stocker_outbound_saved={props.id_stocker_outbound_saved}
        token={props.token}
        tokenDataURI={props.tokenDataURI}
        stocker_items={props.stocker_items}
        company_requestor={props.company_requestor}
        />
    </Document>
  );
import React from 'react';
import moment from 'moment';

import { Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: './assets/fonts/Roboto-Regular.ttf',
    },
    {
      fontStyle: 'bold',
      src: './assets/fonts/Roboto-Bold.ttf',
    },
    {
      fontStyle: 'bolditalic',
      src: './assets/fonts/Roboto-BoldItalic.ttf',
    },
    {
      fontStyle: 'black',
      src: './assets/fonts/Roboto-Black.ttf',
    },
],
});

const styles = StyleSheet.create({
  header: {
    fontSize: 7,
    marginBottom: 10,
    textAlign: 'center',
    color: 'grey',
  },
  container: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
    margin: 5
  },
  logoColumn: {
    flexDirection: 'column',
    flexGrow: 7,
    height: 'auto',
    width: '8%',
    margin: '10'
  },
  detailColumn: {
    marginTop: '10',
    flexDirection: 'column',
    flexGrow: 7,
    textTransform: 'uppercase',
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    height: 'auto',
    width: '8%'
  },
  invoiceHeader: {
    fontSize: 20,
    fontFamily: 'Roboto',
    fontStyle: 'bold'
  },
  invoiceNumber: {
    fontSize: 10,
    justifySelf: 'flex-end',
    fontFamily: 'Roboto',
  },
  badgeNormal: {
    width: 120,
    marginTop: 3,
    padding: 2,
    fontSize: 9,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#fff',
    lineHeight: 1,
    textAlign: 'center',
    borderRadius: 5,
    backgroundColor: '#0D6EFD',
    borderColor: '#0D6EFD'
  },
  badgeTriPL: {
    width: 120,
    marginTop: 3,
    padding: 2,
    fontSize: 9,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#fff',
    lineHeight: 1,
    textAlign: 'center',
    borderRadius: 5,
    backgroundColor: '#673D87',
    borderColor: '#673D87'
  },
  badgeStockerTriPL: {
    width: 145,
    marginTop: 3,
    padding: 2,
    fontSize: 9,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#fff',
    lineHeight: 1,
    textAlign: 'center',
    borderRadius: 5,
    backgroundColor: '#125C3A',
    borderColor: '#125C3A'
  },
  link: {
    fontFamily: 'Roboto',
    fontSize: 10,
    color: 'black',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
});

export default (
  props
) => (
  <View style={styles.container}>
    {/* <View style={styles.header}>
      <Text>~ Generated automatically by system. Powered by automa.id. Printed on {moment().toLocaleString()} by {localStorage.AutomaEml} ~</Text>
    </View> */}
    <View style={styles.logoColumn}>
        <Image
          src={{
            uri: "/static/media/new-automa-logo.28615f70.png?noCache=" + Math.random().toString(),
            headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
          }}
          style={styles.image}
        />
    </View>
    <View style={styles.detailColumn}>
      <Text style={styles.invoiceHeader}>Automa Work Order</Text>
      <Text style={styles.invoiceNumber}>{props.woNumber ? props.woNumber : 'Invalid Work Order'}</Text>
      <Text style={styles.invoiceNumber}>{props.est_arrival_time ? moment(props.est_arrival_time).format('dddd, Do MMMM YYYY') : 'Invalid ETA'}</Text>
      {
        (!props.id_request_wo_3pl && !props.id_stocker_outbound_saved && !props.id_inbound_stocker) ? 
        <Text style={styles.badgeNormal}>
          Normal Work Order
        </Text>
        :
        (
          <>
            {
              (props.id_request_wo_3pl && (!props.id_inbound_stocker && !props.id_stocker_outbound)) ? 
              <Text style={styles.badgeTriPL}>
                3PL Work Order
              </Text>
              :
              <Text style={styles.badgeStockerTriPL}>
                Stocker Work Order
              </Text>
            }
          </>
        )
      }
    </View>
    {/* <View style={styles.linkColumn}>
      <Image
          src={{
            uri: "/static/media/new-automa-logo.28615f70.png" + '?noCache=' + Math.random().toString(),
            headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
          }}
          style={styles.image}
        />
    </View> */}
  </View>
);
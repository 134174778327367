import React from "react";
import { Switch,Route } from "react-router-dom";
import { mechanicRoutes } from "./Mechanic.constants";

const Mechanic = () => {
  return (
    <>
    
    <Switch>
        {mechanicRoutes().map(({ render, ...route }, idx) => (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={render}
          />
        ))}
      </Switch>
    </>
  );
};

export default Mechanic;

import React, { Component, useState } from "react";
import { Button, Card, CardHeader, CardBody } from "reactstrap";
import axios from "axios";
// import {Gmaps, Marker, Circle, InfoWindow} from 'react-gmaps';
import { BarLoader } from "react-spinners";
import * as API_LINKS from "../../../../redux/config/links.jsx";
import { toast } from "react-toastify";
import LiveMonitoringDatatable from "./LiveMonitoring-Datatable";
import { compose } from "recompose";
import customMarkerIdle from "../../../../assets/img/truckidle.png";
import customMarkerMoving from "../../../../assets/img/truckmoving.png";
import customMarkerWarning from "../../../../assets/img/truckalert.png";
import customMarkerOff from "../../../../assets/img/truckoff.png";
import { fetchCompanyList } from "../../../../redux/actions/aUser-CompanyList";
import { fetchSettingList } from "../../../../redux/actions/aUser-SettingList";
import { setLiveMonitoringAddress } from "../../../../redux/actions/transporter/aTransporter-Geocoding";
import { connect } from "react-redux";
import moment from "moment";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  KmlLayer,
  TrafficLayer,
} from "react-google-maps";
import ArrowImage from "../../../../assets/img/arrow.png";

function generateRandom() {
  return Math.random() * 10000000000000000;
}

const showTruckMarkerBasedOnEngineAndSpeedStatus = (marker) => {
  if (marker.wrn === 0 && marker.spd > 0) return customMarkerWarning;
  if (marker.wrn === 0) return customMarkerOff;
  if (marker.spd > 0) return customMarkerIdle;
  if (marker.spd === 0) return customMarkerMoving;
};

const showTruckValueBasedOnEngineAndSpeedStatus = (marker) => {
  if (marker.spd > 0 && marker.wrn === 0) return "Off (Warning!)";
  if (marker.wrn === 0) return "Off";
  if (marker.spd > 0) return "On & Moving";
  if (marker.spd === 0) return "On & Idle";
};

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)((props) => {
  // console.log(props.kmlLink)
  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleMarkerClick = (marker) => {
    // Toggle selectedMarker
    setSelectedMarker((prevMarker) =>
      prevMarker && prevMarker.id === marker.id ? null : marker
    );
    // Optionally call props.onClick if needed
    props.onClick(marker);
  };
  return (
    <GoogleMap
      defaultZoom={7}
      defaultCenter={{ lat: -7.6221790314, lng: 110.8043441772 }}
    >
      <KmlLayer
        url={props.kmlLink + "&ver=" + generateRandom()}
        options={{ preserveViewport: true }}
      />
      <TrafficLayer autoUpdate />
      {props.markers.map((marker) => {
        return (
          marker.time !== null && (
            <Marker
              key={marker.id}
              onClick={() => handleMarkerClick(marker)}
              options={{
                icon: showTruckMarkerBasedOnEngineAndSpeedStatus(marker),
              }}
              // options={{icon: customMarkerIdle}}
              position={{ lat: marker.lat, lng: marker.lng }}
            >
              {selectedMarker && selectedMarker.id === marker.id ? (
                <InfoWindow>
                  <div>
                    <h5>Reg. No: {marker.reg_number_with_door}</h5>
                    <h5>
                      Status:{" "}
                      {showTruckValueBasedOnEngineAndSpeedStatus(marker)}
                    </h5>
                    <h5>
                      Car : <b>{`${marker.car_brands} | ${marker.car_type}`}</b>
                    </h5>
                    <h5>Speed: {marker.spd} KM/h</h5>
                    <h5>
                      {" "}
                      Last Update:{" "}
                      {moment(marker.time).format("YYYY-MM-DD HH:mm:ss")}
                    </h5>
                    <h5>
                      Direction:{" "}
                      <img
                        style={{
                          paddingLeft: "2px",
                          paddingRight: "2px",
                          width: "24px",
                          height: "24px",
                          transform: `rotate(${marker.radian}rad`,
                        }}
                        src={ArrowImage}
                        alt=""
                      />
                    </h5>
                    {marker.address ? (
                      <h5>
                        <br />
                        <br />
                        <u>Location Address</u>{" "}
                        <div style={{ whiteSpace: "pre-line" }}>
                          {marker.address}
                        </div>
                      </h5>
                    ) : null}
                  </div>
                </InfoWindow>
              ) : null}
            </Marker>
          )
        );
      })}
    </GoogleMap>
  );
});

var refreshInterval;

class LiveMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      liveMonitoringData: [],
      liveMonitoringDataCopy: [],
      mapsMarkerData: [],
      markers: [],
      selectedMarker: false,
      success: false,
      summaries: {
        allFleets: 0,
        normal: 0,
        delayed: 0,
        longDelayed: 0,
      },
    };
  }

  UNSAFE_componentWillMount = () => {
    this.props.fetchCompanyList();
    this.props.fetchSettingList();
    this.getLiveMonitoringData();
    refreshInterval = setInterval(() => {
      this.getLiveMonitoringData();
      this.props.setLiveMonitoringAddress([], true);
    }, 300000);
  };

  componentWillUnmount() {
    clearInterval(refreshInterval);
  }

  getLiveMonitoringData = () => {
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    this.setState({
      isLoading: true,
      success: false,
    });
    axios({
      method: "post",
      url: API_LINKS.RENTEE_REQUEST_LIST,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        // console.log(response);
        response.data.result.map((r) => {
          r.start_date = moment(r.start_date).format("YYYY-MM-DD");
          r.end_date = moment(r.end_date).format("YYYY-MM-DD");
          return r;
        });
        response.data.result = response.data.result.filter(
          (r) =>
            r.approve_by_fleet_owner !== null &&
            r.approve_by_rente !== null &&
            moment() >= moment(r.start_date) &&
            moment() <= moment(r.end_date)
        );
        let fetchFleetByAllRequest = [];
        for (let data of response.data.result) {
          fetchFleetByAllRequest.push(
            axios({
              method: "post",
              url: API_LINKS.RENTEE_FLEET_LIVE_MONITORING,
              headers: {
                Authorization: auth,
              },
              data: {
                id_contract: data.id_contract,
              },
            })
          );
        }
        Promise.all([...fetchFleetByAllRequest])
          .then((responses) => {
            let payload = [];
            for (let response of responses) {
              let responseResult = response.data.result;
              if (!responseResult) continue;
              if (!responseResult.length > 0) continue;
              payload = [...payload, ...responseResult];
            }
            return payload;
          })
          .then((payload) => {
            self.setState({
              liveMonitoringData: payload,
              liveMonitoringDataCopy: payload,
              isLoading: false,
              success: true,
            });
            self.mapsMarkertoArray();
          });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({
              isLoading: false,
              success: false,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({
              isLoading: false,
              success: false,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            self.setState({
              isLoading: false,
              success: false,
            });
            return toast.error(error.response.data.message);
          } else {
            self.setState({
              isLoading: false,
              success: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          self.setState({
            isLoading: false,
            success: false,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          self.setState({
            isLoading: false,
            success: false,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };

  mapsMarkertoArray = () => {
    let summaries = {
      allFleets: 0,
      normal: 0,
      delayed: 0,
      longDelayed: 0,
    };
    var list = [];
    if (this.state.liveMonitoringData.length !== 0) {
      var liveMonitoringData = this.state.liveMonitoringData;

      liveMonitoringData.forEach((p, index) => {
        summaries.allFleets += 1;
        if (
          liveMonitoringData[index].lat !== null ||
          liveMonitoringData[index].lng !== null
        ) {
          let fleetIdStr =
            liveMonitoringData[index].reg_number +
            " " +
            (liveMonitoringData[index].door_number
              ? `(${liveMonitoringData[index].door_number})`
              : "");
          var data = {
            id: liveMonitoringData[index].id,
            fleet_id: fleetIdStr,
            lat: liveMonitoringData[index].lat,
            lng: liveMonitoringData[index].lng,
            spd: liveMonitoringData[index].spd,
            wrn: liveMonitoringData[index].wrn,
            time: liveMonitoringData[index].time,
          };
          list.push(data);
          let currentDuration = moment.duration(
            moment().diff(liveMonitoringData[index].time)
          );
          if (
            currentDuration.asMinutes() >= 1440 &&
            liveMonitoringData[index].wrn === 0
          ) {
            summaries.longDelayed += 1;
            return;
          }
          if (
            currentDuration.asMinutes() >= 60 &&
            liveMonitoringData[index].wrn === 1
          ) {
            summaries.longDelayed += 1;
            return;
          }
          if (
            currentDuration.asMinutes() > 15 &&
            currentDuration.asMinutes() < 60 &&
            liveMonitoringData[index].wrn === 1
          ) {
            summaries.delayed += 1;
            return;
          }
          if (currentDuration.asMinutes() <= 15) {
            summaries.normal += 1;
            return;
          }
          if (
            currentDuration.asMinutes() > 15 &&
            liveMonitoringData[index].wrn === 0
          ) {
            summaries.normal += 1;
            return;
          }
        }
      });
    }
    this.setState({ markers: list });
    this.setState({
      isLoading: false,
      summaries: summaries,
    });
  };

  // drawMarker = () => {
  //     const {mapsMarkerData} = this.state

  //     return (
  //         <div>
  //             {this.state.markers.map((marker, index) => (
  //             // <Marker key={index} lat={marker[0]} lng={marker[1]} />
  //             <InfoWindow
  //                 key={index}
  //                 lat={marker[0].lat}
  //                 lng={marker[1].lng}
  //                 content={marker[3].fleet_id}
  //                 />
  //             ))}
  //         </div>
  //     )
  // }

  handleClick = (marker, event) => {
    // console.log({ marker })
    this.setState({ selectedMarker: marker });
  };

  onIdFleetClicked = (row) => {
    this.setState({
      selectedMarker: Object.assign(
        {},
        {
          fleet_id: row.reg_number_with_door,
          id: row.id,
          lat: row.lat,
          lng: row.lng,
          spd: row.spd,
          time: row.time,
          wrn: row.wrn,
        }
      ),
    });
  };

  onDataSearch = (searchResult) => {
    let copyOfSearchResult = [...searchResult];
    let isSameLength = copyOfSearchResult.length === this.state.markers.length;
    let isSameContent = copyOfSearchResult.every((element, index) => {
      return element === this.state.markers[index];
    });
    if (!isSameContent || !isSameLength) {
      this.setState({ markers: searchResult });
    }
  };

  linkHeaderClickHandler = (type) => {
    const { liveMonitoringData, liveMonitoringDataCopy } = this.state;
    if (type === "all-fleets")
      this.setState({ liveMonitoringDataCopy: liveMonitoringData });
    if (type === "on-time")
      this.setState({
        liveMonitoringDataCopy: liveMonitoringData.filter(
          (r) =>
            moment.duration(moment().diff(r.time)).asMinutes() <= 15 ||
            (moment.duration(moment().diff(r.time)).asMinutes() > 15 &&
              r.wrn === 0)
        ),
      });
    if (type === "delay")
      this.setState({
        liveMonitoringDataCopy: liveMonitoringData.filter(
          (r) =>
            moment.duration(moment().diff(r.time)).asMinutes() > 15 &&
            moment.duration(moment().diff(r.time)).asMinutes() < 60 &&
            r.wrn === 1
        ),
      });
    if (type === "long-delay")
      this.setState({
        liveMonitoringDataCopy: liveMonitoringData.filter(
          (r) =>
            (moment.duration(moment().diff(r.time)).asMinutes() >= 60 &&
              r.wrn === 1) ||
            (moment.duration(moment().diff(r.time)).asMinutes() >= 1440 &&
              r.wrn === 0)
        ),
      });
    this.setState({ markers: liveMonitoringDataCopy });
  };

  render() {
    const { summaries } = this.state;
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody className="m-0 p-0">
            <div>
              {/* {
                                this.state.isLoading ? 
                                <center><BarLoader color={'#123abc'} loading={this.state.isLoading}/> <br /> Loading.... Please wait...</center>
                                : 
                                (
                                    this.state.success ? 
                                    <div> */}
              {this.props.statKML ? (
                <MapWithAMarker
                  selectedMarker={this.state.selectedMarker}
                  markers={this.state.markers}
                  onClick={this.handleClick}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  kmlLink={this.props.kml}
                />
              ) : (
                <MapWithAMarker
                  selectedMarker={this.state.selectedMarker}
                  markers={this.state.markers}
                  onClick={this.handleClick}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  kmlLink={""}
                />
              )}

              {/* </div>
                                    : 
                                    <center>Error fetching your data... <br/><br/> 
                                        <Button className="btn btn-primary" color="primary" size="md" disabled={this.state.isLoading} onClick={() => this.getLiveMonitoringData()}>
                                            <i className="fa fa-refresh"></i> 
                                            Try again
                                        </Button>
                                    </center>
                                )
                            } */}
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <b>Live Monitoring Details</b>
          </CardHeader>
          <CardBody>
            {this.state.isLoading ? (
              <center>
                <BarLoader color={"#123abc"} loading={this.state.isLoading} />{" "}
                <br /> Loading.... Please wait...
              </center>
            ) : this.state.success ? (
              <LiveMonitoringDatatable
                summaries={summaries}
                onLinkHeaderClicked={this.linkHeaderClickHandler}
                state={this.state}
                onIdFleetClicked={this.onIdFleetClicked}
                onDataSearch={this.onDataSearch}
              />
            ) : (
              <center>
                Error fetching your data... <br />
                <br />
                <Button
                  className="btn btn-primary"
                  color="primary"
                  size="md"
                  disabled={this.state.isLoading}
                  onClick={() => this.getLiveMonitoringData()}
                >
                  <i className="fa fa-refresh"></i>
                  Try again
                </Button>
              </center>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  kml: state.companyStore.companyKML,
  statKML: state.settingStore.kmlSet,
});

export default connect(mapStateToProps, {
  fetchCompanyList,
  fetchSettingList,
  setLiveMonitoringAddress,
})(LiveMonitoring);

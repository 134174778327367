import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, ButtonGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import { bootstrapTableOption, fileNameFormat } from "../../../helpers/helper";
import { USER_ACCOUNT_BANK_LIST } from "../../../redux/config/links";
import useLazyFetch from "../../../hooks/useLazyFetch";
import LoadingContent from "../../../components/LoadingContent/LoadingContent";
import WithdrawalSettingsAddEdit from "./contents/WithdrawalSettingsAddEdit";
import WithdrawalSettingsDelete from "./contents/WithdrawalSettingsDelete";

const WithdrawalSettings = () => {
  const [modalOpenCreate, setModalOpenCreate] = useState(false);
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  const [modalOpenDelete, setModalOpenDelete] = useState(null);
  const [isGettingUserAccount, setIsGettingUserAccount] = useState(true);

  const [
    getUserAccountBankList,
    { loading: loadingGetUserAccountBankList, data },
  ] = useLazyFetch({
    url: USER_ACCOUNT_BANK_LIST,
    method: "POST",
  });

  useEffect(() => {
    if (isGettingUserAccount) {
      setIsGettingUserAccount(false);
      getUserAccountBankList();
    }
  }, [isGettingUserAccount, getUserAccountBankList]);
  return (
    <>
      <LoadingContent
        loading={loadingGetUserAccountBankList}
        onReload={() => setIsGettingUserAccount(true)}
      >
        <BootstrapTable
          data={data ? data.result : []}
          version="4"
          bordered={false}
          hover
          pagination
          search
          edit
          options={bootstrapTableOption({
            btnGroup: (props) => (
              <div>
                {props.exportCSVBtn}{" "}
                <Button
                  color={"primary"}
                  onClick={() => setIsGettingUserAccount(true)}
                >
                  <i className="fa fa-refresh"></i> Reload
                </Button>{" "}
                <Button
                  color={"primary"}
                  onClick={() => setModalOpenCreate(true)}
                >
                  <i className="fa fa-plus"></i> Create
                </Button>
              </div>
            ),
          })}
          exportCSV={true}
          csvFileName={fileNameFormat({ name: "" })}
          multiColumnSearch
        >
          <TableHeaderColumn
            dataField="id"
            isKey
            dataSort
            csvHeader="ID"
            hidden
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="bank_name" dataSort csvHeader="Bank">
            Bank
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="account_number"
            dataSort
            csvHeader="Account Number"
          >
            Account Number
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="account_holding_name"
            dataSort
            csvHeader="Account Name"
          >
            Account Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="action"
            export={false}
            dataFormat={(cell, row) => (
              <>
                <ButtonGroup>
                  <Button
                    className="btn btn-info"
                    color="info"
                    onClick={() => setModalOpenEdit({ ...row })}
                  >
                    <i className="fa fa-pencil"></i> Edit
                  </Button>
                  <Button
                    className="btn btn-danger"
                    color="danger"
                    onClick={() => setModalOpenDelete({ ...row })}
                  >
                    <i className="fa fa-trash"></i> Delete
                  </Button>
                </ButtonGroup>
              </>
            )}
            width="25%"
          >
            Action
          </TableHeaderColumn>
        </BootstrapTable>
      </LoadingContent>

      <Modal
        size="md"
        isOpen={modalOpenCreate}
        toggle={() => setModalOpenCreate(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenCreate(false)}>
          Create Widthdrawal Setting
        </ModalHeader>
        <ModalBody>
          <WithdrawalSettingsAddEdit
            onClose={() => setModalOpenCreate(false)}
            onReload={() => setIsGettingUserAccount(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenEdit}
        toggle={() => setModalOpenEdit(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(null)}>
          Edit Widthdrawal Setting
        </ModalHeader>
        <ModalBody>
          <WithdrawalSettingsAddEdit
            initialValues={modalOpenEdit}
            onClose={() => setModalOpenEdit(null)}
            onReload={() => setIsGettingUserAccount(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenDelete}
        toggle={() => setModalOpenDelete(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenDelete(null)}>
          Delete Widthdrawal Setting
        </ModalHeader>
        <ModalBody>
          <WithdrawalSettingsDelete
            initialValues={modalOpenDelete}
            onClose={() => setModalOpenDelete(null)}
            onReload={() => setIsGettingUserAccount(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default WithdrawalSettings;

import React, { Component } from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormGroup,
    Col,
    Input,
    ButtonGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { connect } from "react-redux";
import moment from 'moment'
import axios from 'axios'
import { BarLoader } from 'react-spinners';
import {toast}  from 'react-toastify';
import { fetchDriversAssistantTable } from "../../../redux/actions/transporter/aTransporter-DriversAssistantTable";
import { fetchDeleteItem } from "../../../redux/actions/transporter/aTransporter-DeleteItem";
import * as API_LINKS from '../../../redux/config/links';
import { AbilityContext} from '../../../casl/Can';
import Dropzone from '../../../components/Dropzone/'
import { employmentStatusSelections } from '../../../constants/constants';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }  

class DriversAssistantDatatable extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            selectedName: '',
            selectedId: 0,
            modalDelete: false,
            selectedRow: {},

            isEditing: false,
            telp: '',
            birth_date: '',
            driverAssistantPhoto: []

         };
    }

    UNSAFE_componentWillMount = () => {
      this.props.fetchDriversAssistantTable()
    }

    toggleOpenEdit = (_row) => {
        this.setState({
            selectedRow: _row,
            modalEdit: !this.state.modalEdit,
            identificationNumber: _row.ktp
        })
    }

    toggleCloseEdit = () => {
        this.setState({
            selectedRow: {},
            modalEdit: !this.state.modalEdit
        })
    }

    toggleOpenDelete = (_id, _name) => {
        this.setState({
            selectedId: _id,
            selectedName: _name,
            modalDelete: !this.state.modalDelete
        })
    }

    toggleCloseDelete = () => {
        this.setState({
            selectedId: 0,
            selectedName:'',
            modalDelete: !this.state.modalDelete
        })
    }
    
    ActionButtonFormatter = (cell, row) => {
        return (
          <div>
            <ButtonGroup>
              {this.context.can("view", "add_covid@covid_related") &&
                this.context.can("view", "add_covid@covid_related_subs") && (
                  <Link
                    to={{
                      pathname: "/transporter/covid-related/" + row.id,
                      state: {
                        id: row.id,
                        isDriver: true,
                        name: row.name,
                      },
                    }}
                  >
                    <Button
                      className="btn btn-success"
                      color="success"
                      size="sm"
                    >
                      <i className="fa fa-exclamation-triangle"></i> Covid
                      Related
                    </Button>
                  </Link>
                )}
              &nbsp;&nbsp;
              {this.context.can(
                "view",
                "edit_and_delete_button@drivers_assistant_datatable"
              ) && (
                <React.Fragment>
                  <Button
                    className="btn btn-warning"
                    color="warning"
                    size="sm"
                    onClick={() => this.toggleOpenEdit(row)}
                  >
                    <i className="fa fa-edit"></i> Edit
                  </Button>{" "}
                  &nbsp;&nbsp;
                  <Button
                    className="btn btn-danger"
                    color="danger"
                    size="sm"
                    onClick={() => this.toggleOpenDelete(row.id, row.name)}
                  >
                    <i className="fa fa-trash"></i> Delete
                  </Button>
                </React.Fragment>
              )}
            </ButtonGroup>
          </div>
        );// eslint-disable-next-line
    }

    handleDelete = (_id) => {
        this.props.fetchDeleteItem('DA', _id);
        this.toggleCloseDelete()
        sleep(500).then(() => {
            // Do something after the sleep!
            this.props.fetchDriversAssistantTable()
        });
        
    }
    
    handleOnDrop = (result) => { //Dropzone

        if(result.length === 0) {

            this.setState({driverAssistantPhoto: []});

            return;

        }

        if(!result.passed){

            toast.error("Driver photo, " + result.reasonRejected)

        }

        this.setState({driverAssistantPhoto: result})
    }

    viewDeleteDriverAssistant = () => {
        var self = this;
        const { selectedName, selectedId} = this.state;
        return(
            <div className="container">
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Assistant Name: </Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Label htmlFor="text-input">{selectedName}</Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" md="12">
                        <Label htmlFor="text-input">Do you want to delete this driver assistant? </Label>
                    </Col>
                </FormGroup>
                  
                {this.props.inProgressDelete ? <center><BarLoader color={'#123abc'} 
                  loading={this.props.inProgressDelete} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => self.handleDelete(selectedId)} size="sm" color="danger" disabled={self.props.inProgressDelete}><i className="fa fa-trash"></i> Submit</Button>
                <Button onClick={() => this.toggleCloseDelete()} size="sm" color="success" disabled={self.props.inProgressDelete}><i className="fa fa-window-close"></i> Close</Button></div>}         
            </div>
        )
    }

    handleBirthDateChange = (_date) => {
        var convertedDate = moment(_date).format("YYYY-MM-DD")
        // console.log(convertedDate)
        this.setState({
            date: _date,
            // selectedRow: {
                birth_date: convertedDate
            // }
            // driverBirthDate: convertedDate
        });
    }

    handleEmploymentStatus = (employmentStatus) => {
        this.setState({ employmentStatus });
    }

    viewEditDriverAssistant = () => {
        const { isEditing } = this.state;
        return (
            <div className="container animated fadeIn">
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Assistant Name</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="editDriverName" placeholder={this.state.selectedRow.name} value={this.state.selectedRow.name} disabled/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Identification Card Number</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="editIdentificationNumber" placeholder="21711xxxxxxxxxxx" disabled={this.state.isEditing } onChange={e => this.setState({ identificationNumber: e.target.value })} value={this.state.identificationNumber}/>
                    </Col>
                </FormGroup> 
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Employment Status</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Select
                            value={this.state.employmentStatus}
                            onChange={this.handleEmploymentStatus}
                            options={employmentStatusSelections}
                            disabled={isEditing}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">Birth Date</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={this.state.birth_date}
                            onChange={this.handleBirthDateChange} 
                            placeholderText={this.state.selectedRow.birth_date}
                            disabled={isEditing}/>
                    </Col>
                </FormGroup>  
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Assistant Phone</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="addDriverPhone" placeholder={this.state.selectedRow.telp} onChange={e => this.setState({telp: e.target.value })} value={this.state.telp} disabled={this.state.isEditing}/>
                    </Col>
                </FormGroup>
                {
                    this.state.employmentStatus ? 
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Daily Rate</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <NumberFormat value={this.state.daily_rate} thousandSeparator={true} disabled={this.state.isAdding} prefix={'Rp. '} onValueChange={(values) => {
                                // eslint-disable-next-line
                                const {formattedValue, value} = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                this.setState({daily_rate: value})
                            }}/>
                        </Col>
                    </FormGroup>
                    :
                    true
                }
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Current Driver Assistant Photo</Label>
                    </Col>
                    <Col xs="12" md="9">
                        {(this.state.selectedRow.link_image === null) ?
                            <img width="75" height="70" src={ process.env.PUBLIC_URL + '/assets/img/photo_not_found.png'} alt="old preview"/>
                            :
                            <img width="130" height="100" src={this.state.selectedRow.link_image} alt="old preview"/>
                        }  
                    </Col>
                </FormGroup>  
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">New Driver Assistant Photo</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Dropzone maxFile={1} 
                                  maxSize={1000000} //1MB
                                  accept="image/png, image/jpeg" 
                                  onDrop={this.handleOnDrop}
                                  preview={true}>
                                  </Dropzone>
                    </Col>
                </FormGroup> 
                {isEditing ? <center><BarLoader color={'#123abc'} 
                  loading={isEditing} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => this.handleEdit()} size="sm" color="primary" disabled={isEditing}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                <Button onClick={() => this.toggleCloseEdit()} size="sm" color="success" disabled={isEditing}><i className="fa fa-window-close"></i> Close</Button></div>}
            </div>
        )
    }

    handleEdit = async() => {
        this.setState({ isAdding: true })
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;

        //Variables for text input
        var _id = self.state.selectedRow.id;
        var _assistantBirthDate = self.state.birth_date;
        var _assistantPhone = self.state.telp;
        var _identificationNumber = this.state.identificationNumber

        const {driverAssistantPhoto} = this.state;

        let editedImageUploaded = false;
        let editedImageId = -1;

        if(driverAssistantPhoto.length > 0){
            
            if(driverAssistantPhoto.passed){
            
                let formDataUploadImage = new FormData();

                formDataUploadImage.append('file',driverAssistantPhoto.uploadedFiles[0]);
                formDataUploadImage.append('image_desc',"Foto driver bernama " + driverAssistantPhoto.uploadedFiles.name);

                await axios({
                    method:'post',
                    url: API_LINKS.UPLOAD_IMAGE_FOR_EDIT, 
                    headers: {
                        Authorization: auth,
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formDataUploadImage
                })
                .then((response) => {
                    if(response.status === 200) {
                        editedImageUploaded = true;
                        if (response.data.status === "success") {
                            editedImageId = response.data.result.id;
                        }
                    }
                })
                .catch(function(error){
                    if (error.response) {
                        return toast.error("Error " + error.response.status + " while edit photo");
                    }
                })

            }

        } 

        let dataEdit =  {

            id: _id,
            telp: _assistantPhone,
            birth_date: _assistantBirthDate,
            ktp: _identificationNumber
        }
        
        if(editedImageUploaded){

            dataEdit['id_image_log'] = editedImageId;

        }

        axios({
            method:'post',
            url: API_LINKS.DRIVER_ASSISTANT_EDIT_URL, //Please change when it's ready
            headers: {
                Authorization: auth
            },
            data: dataEdit 
        })
        .then((response) => {
            if(response.status === 200) {
                self.setState({
                    isEditing: false,
                    selectedRow: {}
                })
                if (response.data.status === "success") {
                    toast.success('Data saved!')
                    self.toggleCloseEdit()
                    self.props.fetchDriversAssistantTable()
                }
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    self.setState({
                        isEditing: false
                    })
                    return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 403) {
                    self.setState({
                        isEditing: false
                    })
                    return toast.error("Unauthorized Access! Please try to logout..");
                } else if (error.response.status === 400) {
                    self.setState({
                        isEditing: false
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    self.setState({
                        isEditing: false
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    self.setState({
                        isEditing: false
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                self.setState({
                    isEditing: false
                })
                return toast.error('Request error! Please try again later...')
            } else {
                self.setState({
                    isEditing: false
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }

    imageFormatter = (cell) => {

        let imageLink = cell

        if(!imageLink)
            imageLink = process.env.PUBLIC_URL + '/assets/img/photo_not_found.png'

        return '<img width="72" height="70" src="'+imageLink+'" />';
    }

    showDataTable = () => {

        var options = {
            defaultSortName: 'name',  // default sort column name
            defaultSortOrder: 'asc', // default sort order
            noDataText: 'No driver assistant registered on your dashboard. Try one-lah!',
            sizePerPageList: [{
                text: '10', value: 10
            },{
                text: '20', value: 20
            },{
                text: '30', value: 30
            }],
            sizePerPage: 20,
        }
        return (
            <BootstrapTable data={this.props.driversAssistantTableData.result} version="4" bordered={false} hover pagination search edit options={options} exportCSV={true} csvFileName={this.fileNameFormat} multiColumnSearch>
              <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" hidden>ID</TableHeaderColumn>
              {this.context.can("view", "company_field@drivers_assistant_datatable") ?
                  <TableHeaderColumn dataField="company" searchable dataSort csvHeader="Company" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%" >Company</TableHeaderColumn> : null
              }
              <TableHeaderColumn dataField="name" searchable dataSort csvHeader="Driver Name" dataFormat={this.asstWithKtp.bind(this) } thStyle={ { whiteSpace: 'normal' } } width="20%">Driver Name</TableHeaderColumn>

              <TableHeaderColumn dataField="link_image" csvHeader="Photo" dataFormat={this.imageFormatter} thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="8%">Photo</TableHeaderColumn>

              <TableHeaderColumn dataField="telp" dataSort csvHeader="Phone Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Phone Number</TableHeaderColumn>
              <TableHeaderColumn dataField="birth_date" dataSort csvHeader="Birth Date" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Birth Date</TableHeaderColumn>
              <TableHeaderColumn dataField="created_on" dataSort csvHeader="Created On" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Created On</TableHeaderColumn>
              <TableHeaderColumn dataField="action" export={false} dataFormat={ this.ActionButtonFormatter.bind(this) } width="30%">Action</TableHeaderColumn>
            </BootstrapTable>
        );
    }

    asstWithKtp = (cell, row) => {
        return <div>
                    {`${cell} ${row.ktp? `(${row.ktp})` : ''}`}
                </div>
    }

    render() {
        return (
            <div className="animated fadeIn">
                {this.props.inProgress  ? <center><BarLoader color={'#123abc'} 
                loading={this.props.inProgress} 
                /> <br /> Loading.... Please wait...</center> : (this.props.successDriverAssistantFetch ? this.showDataTable() : <center>Error fetching your data... <br/><br/> <Button className="btn btn-primary" color="primary" size="md" disabled={this.props.inProgress} onClick={() => this.props.fetchDriversAssistantTable()}><i className="fa fa-refresh"></i> Try again</Button></center>)}

                {/* Modal for Delete Place */}
                <Modal isOpen={this.state.modalDelete} toggle={this.toggleOpenDelete} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseDelete}>Delete {this.state.selectedName}?</ModalHeader>
                    <ModalBody>
                            {this.viewDeleteDriverAssistant()}
                    </ModalBody>
                </Modal>

                {/* Modal for Edit Driver Assistance */}
                <Modal isOpen={this.state.modalEdit} toggle={this.toggleOpenEdit} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseEdit}>Edit Driver Assistant data</ModalHeader>
                    <ModalBody>
                            {this.viewEditDriverAssistant()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        driversAssistantTableData: state.driversAssistantTableStore.driversAssistantTableData,
        inProgress: state.driversAssistantTableStore.inProgress,
        deleteItemData: state.deleteStore.deleteItemData,
        inProgressDelete: state.deleteStore.inProgress,
        successDriverAssistantFetch: state.driversAssistantTableStore.success,
      }
}

DriversAssistantDatatable.contextType = AbilityContext;
export default connect(mapStateToProps, {fetchDriversAssistantTable, fetchDeleteItem})(DriversAssistantDatatable);
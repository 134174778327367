import useLazyFetch from "../../../../hooks/useLazyFetch";
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { TRIP_LIST } from "../../../../redux/config/storing.links";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import LoadingContent from "../../../../components/LoadingContent/LoadingContent";
import {
  bootstrapTableOption,
  fileNameFormat,
} from "../../../../helpers/helper";
import TripAddEdit from "./contents/TripAddEdit";
import MechanicDatatable from "./contents/MechanicDatatable";
import SparepartDatatable from "./contents/SparepartDatatable";
import TripDelete from "./contents/TripDelete";
import SummaryWidgets from "../../../Widgets/SummaryWidgets";

const Trip = () => {
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  const [modalOpenDelete, setModalOpenDelete] = useState(null);
  const [openMechanic, setOpenMechanic] = useState(null);
  const [openSparepart, setOpenSparepart] = useState(null);

  const [gettingTripList, setGettingTripList] = useState(true);
  const [modalOpenCreate, setModalOpenCreate] = useState(false);

  const [onGetTripList, { loading: loadingTripList, data }] = useLazyFetch({
    url: TRIP_LIST,
    method: "POST",
  });

  useEffect(() => {
    if (gettingTripList) {
      setGettingTripList(false);
      onGetTripList({});
    }
  }, [gettingTripList, onGetTripList]);
  return (
    <>
      <Row>
        <Col md={3} xs={12}>
          <SummaryWidgets
            header={(data ? data.result : []).length}
            mainText={"Total Maintenance Trip"}
            icon={"fa fa-id-card"}
            color={"success"}
          />
        </Col>
      </Row>
      <Card>
        <CardHeader>Maintenance Trip</CardHeader>
        <CardBody>
          <LoadingContent
            loading={loadingTripList}
            onReload={() => setGettingTripList(true)}
          >
            <BootstrapTable
              data={data ? data.result : []}
              version="4"
              bordered={false}
              hover
              pagination
              search
              edit
              options={bootstrapTableOption({
                btnGroup: (props) => (
                  <div>
                    {props.exportCSVBtn}{" "}
                    <Button
                      color={"primary"}
                      onClick={() => setGettingTripList(true)}
                    >
                      <i className="fa fa-refresh"></i> Reload
                    </Button>{" "}
                    <Button
                      color={"primary"}
                      onClick={() => setModalOpenCreate(true)}
                    >
                      <i className="fa fa-plus"></i> Create
                    </Button>
                  </div>
                ),
              })}
              exportCSV={true}
              csvFileName={fileNameFormat({ name: "" })}
              multiColumnSearch
            >
              <TableHeaderColumn
                dataField="id"
                isKey
                dataSort
                csvHeader="ID"
                hidden
              >
                ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="driver_name"
                dataSort
                csvHeader="Driver"
              >
                Driver
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="driver_telp"
                dataSort
                csvHeader="Driver"
              >
                Driver Telp
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="driver_assistant_name"
                dataSort
                csvHeader="Driver Assistant"
              >
                Driver Assistant
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="est_arrival_time"
                dataSort
                csvHeader="Est Arrival Time"
              >
                Est Arrival Time
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="realization_arrival_time"
                dataSort
                csvHeader="Realization Arrival Time"
              >
                Realization Arrival Time
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataFormat={(_cell, row) => (
                  <>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        color="warning"
                        onClick={() => setModalOpenEdit({ ...row })}
                      >
                        <i className="fa fa-edit"></i> Edit
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => setModalOpenDelete({ ...row })}
                      >
                        <i className="fa fa-trash"></i> Delete
                      </Button>
                    </ButtonGroup>
                    <br />
                    <ButtonGroup>
                      <Button
                        size="sm"
                        color={"primary"}
                        onClick={() => setOpenMechanic(row)}
                      >
                        Mechanic
                      </Button>
                      <Button
                        size="sm"
                        color={"primary"}
                        onClick={() => setOpenSparepart(row)}
                      >
                        Sparepart
                      </Button>
                    </ButtonGroup>
                  </>
                )}
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>
          </LoadingContent>
        </CardBody>
      </Card>

      <Modal
        size="md"
        isOpen={modalOpenCreate}
        toggle={() => setModalOpenCreate(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenCreate(false)}>
          Create Trip
        </ModalHeader>
        <ModalBody>
          <TripAddEdit
            onClose={() => setModalOpenCreate(false)}
            onRefresh={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>
      <Modal
        size="md"
        isOpen={modalOpenEdit}
        toggle={() => setModalOpenEdit(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(null)}>
          Edit Trip
        </ModalHeader>
        <ModalBody>
          <TripAddEdit
            initialValues={modalOpenEdit}
            isEdit
            onClose={() => setModalOpenEdit(null)}
            onRefresh={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>
      <Modal
        size="lg"
        isOpen={!!openMechanic}
        toggle={() => setOpenMechanic(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenMechanic(null)}>Mechanic</ModalHeader>
        <ModalBody>
          <MechanicDatatable
            onClose={() => setOpenMechanic(null)}
            id_storing_fleet_trip={!!openMechanic ? openMechanic.id : null}
          />
        </ModalBody>
      </Modal>
      <Modal
        size="lg"
        isOpen={!!openSparepart}
        toggle={() => setOpenSparepart(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenSparepart(null)}>
          Sparepart
        </ModalHeader>
        <ModalBody>
          <SparepartDatatable
            onClose={() => setOpenSparepart(null)}
            id_storing_fleet_trip={!!openSparepart ? openSparepart.id : null}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenDelete}
        toggle={() => setModalOpenDelete(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenDelete(null)}>
          Delete Mechanic
        </ModalHeader>
        <ModalBody>
          <TripDelete
            initialValues={modalOpenDelete}
            onClose={() => setModalOpenDelete(null)}
            onReload={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Trip;

import React, { Component } from 'react'
import {
    Button,
    FormGroup,
    Label,
    Col,
} from 'reactstrap'
import axios from 'axios';
import { Formik } from 'formik'
import {toast} from 'react-toastify'
import {WORK_ORDER_DELETE_IMAGE_URL}  from '../../../redux/config/links'
import handleErrorResponseCode from '../../../helpers/error-response-code-handler'

class DeleteVendorsForm extends Component {

    form = () => {
        const {initialValues} = this.props
        const {deleteFormClose, refreshData} = this.props
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                    let auth = loginCreds.token;
                    setSubmitting(true)
                    axios({
                            method: 'post',
                            url: WORK_ORDER_DELETE_IMAGE_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : values
                        }).then((response) => {
                            // console.log(response)
                            if(response.status === 200){
                                setSubmitting(false)
                                toast.success('Image has been deleted succesfully!')
                                refreshData()
                                deleteFormClose()
                            }
                        })
                    .catch(function(error){
                        setSubmitting(false)
                        toast.error(handleErrorResponseCode(error))
                    })
                    // setSubmitting(false)
                    //refreshData()
                    // deleteFormClose()
                }}
                render={({
                handleSubmit,
                isSubmitting
                }) => (
                <form>
                    <FormGroup row>
                        <Col md="12">
                            <Label htmlFor="text-input">Are you sure to delete {initialValues.desc? <b>{initialValues.desc}</b> : "this"} image?</Label>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Button type="button" onClick={handleSubmit} color="danger" className="mr-1" disabled={isSubmitting}>{isSubmitting ? 'Deleting...' : 'Delete'}</Button>
                        <Button type="button" color="warning" className="mr-1" disabled={isSubmitting} onClick={deleteFormClose}>Close</Button>
                    </FormGroup>
                </form>
                )}
            />
        )
    }

    render(){
        return(
            <React.Fragment>
                {this.form()}
            </React.Fragment>
        )
    }

} export default DeleteVendorsForm
import { combineReducers } from "redux";
import { fetchAuth } from "./user/redUser-Auth.jsx";
import { fetchCarDetailsTable } from "./transporter/redTransporter_CarDetailsTable.jsx";
import { userVerification } from "./user/redUser-Verify";
// import { reducer as reduxFormReducer } from 'redux-form'
import {
  fetchDriversTable,
  fetchDriversSummary,
  fetchDriverFleets,
} from "./transporter/redTransporter-DriversTable.jsx";
import { fetchDriversAssistantTable } from "./transporter/redTransporter-DriversAssistantTable.jsx";
import { fetchDeliveryOrdersTable } from "./transporter/redTransporter-DeliveryOrdersTable.jsx";
import {
  fetchWorkOrdersTable,
  fetchWoUploadedImage,
  fetchWoUploadedImageCompare,
} from "./transporter/redTransporter-WorkOrdersTable.jsx";
import { fetchCarBrandsTable } from "./transporter/redTransporter-CarBrandsTable.jsx";
import { fetchCarTypeTable } from "./transporter/redTransporter-CarTypeTable.jsx";
import { fetchCarGeneralTypeTable } from "./transporter/redTransporter-CarGeneralTypeTable.jsx";
import {
  fetchFleetListTable,
  FleetQrList,
  FleetMaintenanceBindingList,
} from "./transporter/redTransporter-FleetListTable.jsx";
import { fetchCarFuelTable } from "./transporter/redTransporter-CarFuelTypeTable.jsx";
import { fetchOBDTypeTable } from "./transporter/redTransporter-OBDTypeTable.jsx";
import { fetchDeliveryCheckInLog } from "./transporter/redTransporter-DeliveryCheckInLog.jsx";
import { fetchDeliveryCheckInStatus } from "./transporter/redTransporter-DeliveryCheckInStatus.jsx";
import {
  fetchSavedPlacesTable,
  fetchSavedPlaceMesh,
  fetchSavedPlaceNear,
} from "./transporter/redTransporter-SavedPlacesTable.jsx";
import {
  publicFacility,
  publicFacilityGlobal,
} from "./transporter/publicfacility.red";
import { publicFacilityType } from "./transporter/publicfacilitytype.red";
import { fetchSecurityTable } from "./transporter/redTransporter-SecurityTable.jsx";
import {
  fetchFleetReport,
  changeFleetReportTrip,
} from "./transporter/redTransporter-FleetReport.jsx";
import { fetchDeleteItem } from "./transporter/redTransporter-DeleteItem.jsx";
import { fetchUserTable } from "./user/redUser-UserListTable";
import { fetchWorkOrderShare } from "./transporter/redTransporter-WorkOrderShare.jsx";
import { fetchSettingList } from "./user/redUser-SettingList.jsx";
import { landlord_fetchLandlordSensorReadings } from "./landlord/redLandLord-SensorReadings.jsx";
import { fetchFleetNotificationDashboard } from "./transporter/redTransporter-FleetNotificationDashboard.jsx";
import { fetchDriversNotificationDashboard } from "./transporter/redTransporter-DriversNotificationDashboard.jsx";
import { fetchFleetMaintenanceTable } from "./transporter/redTransporter-FleetMaintenanceTable.jsx";
import { fetchFleetMaintenanceNotificationDashboard } from "./transporter/redTransporter-FleetMaintenanceDashboardNotification.jsx";
import {
  fetchCompanyList,
  fetchCompanyAllList,
} from "./user/redUser-CompanyList.jsx";
import { fetchMainCustomerList } from "./transporter/redTransporter-MainCustomerList.jsx";
import { fetchDeliveryAudit } from "./transporter/redTransporter_DeliveryAudit.jsx";
import { fetchDeliveryStatusList } from "./transporter/redTransporter-DeliveryStatusList.jsx";
import { fetchWorkOrderAdderMulti } from "./transporter/redTransporter-WOAdderMulti.jsx";
import { fetchWorkOrdersReview } from "./transporter/redTransporter-WOReview.jsx";
import { fetchFuelReport } from "./transporter/redTransporter-FuelReport.jsx";
import { fetchFuelManagementList } from "./transporter/redTransporter-FuelManagement.jsx";
import { servicePlan } from "./user/subs.red.js";
import { fetchLiveMonitoringFleetForEstCost } from "./transporter/redTransporter-LiveMonitoringLite";
import { LiveMonitoringShareToken } from "./transporter/redTransporter-LiveMonitoringShare";
import { fetchWorkOrdersStocker } from "./transporter/redTransporter-WoStocker";
import { woInboundItemDetail } from "./transporter/redTransporter-WoStocker";
import { woOtuboundItemDetail } from "./transporter/redTransporter-WoStocker";
import {
  PreventiveMaintenance,
  PreventiveMaintenanceTask,
  FleetMaintenanceReminderDashboardList,
} from "./transporter/preventive-maintenance.red";
import { UnplannedStop } from "./transporter/unplannedStop.red.js";
import { Cchv } from "./transporter/cold-chain-chart-history.red.js";

// TriPL
import { fetchTriPLWorkOrderRequestResult } from "./transporter/redTransporter-TriPLWORequestListResult.jsx";
import { fetchTriPLWorkOrderList } from "./transporter/redTransporter-TriPLWorkOrderList.jsx";
import { fetchTriPLIncomingBidList } from "./transporter/redTransporter-TriPLIncomingBidList.jsx";
import { fetchTriPLWoBidHistoryList } from "./transporter/redTransporter-TriPLWOBidHistoryList.jsx";
import { fetchTriPLApprovedRequestList } from "./transporter/redTransporter-TriPLApprovedRequestList.jsx";
import {
  fetchTriPLGroupingList,
  fetchTriPLGroupingAssignCreateFind,
  fetchTriPLGroupingAssigneFind,
  fetchTriPLGroupingAssigneFindNotification,
  fetchTriPLGroupFleetCheck,
  fetchTriPLEstCost,
  fetchTriPLMemberView,
} from "./transporter/redTransporter-TriPLGroupingList";
import {
  fetchTriPLApprovedCheckPayment,
  fetchTriPLApprovedGenerateInvoice,
} from "./transporter/redTransporter-TriPLBidApprovedPayment";
import {
  fetchTriPLWinnerTracker,
  fetchTriPLHistoryPath,
} from "../reducers/transporter/redTransporter-TriPLWinnerTracker";
import { fetchTriPLWLiveMonitoring } from "../reducers/transporter/redTransporter-TriPLLiveMonitoring";

import { DriverAppUsersList } from "./transporter/driverappuser.red.js";
import { fetchDeleteDriverAppUser } from "./transporter/driverappuser-delete.red.js";

import { MechanicList } from "./transporter/mechanic.red.js";
import { MechanicCertificationList } from "./transporter/mechanicCertification.red.js";
import { MechanicSpecializationList } from "./transporter/mechanicSpecialization.red.js";
import { MechanicOperationalHourList } from "./transporter/mechanicOperationalHour.red.js";

import {
  WorkshopList,
  BindedWorkshopList,
  PicPositions,
  Pics,
  PaymentTermTypes,
} from "./transporter/workshop.red.js";
import { WorkshopOperationalHourList } from "./transporter/workshopOperationalHour.red.js";
import {
  CheckSheets,
  CheckSheetsDetail,
  CheckSheetBindingDo,
  CheckSheetApprovals,
  CheckSheetApprovalType,
  CheckSheetApprovalSetting,
  CheckSheetDoDetail,
} from "./transporter/check-sheet.red";
import { Divisions } from "./transporter/divisions.red";
import {
  PackageMaintenance,
  PackageMaintenanceTask,
} from "./transporter/package-maintenance.red";
import { ScheduledMaintenanceList } from "./transporter/scheduledmaintenance.red.js";
import {
  SMMechanicTaskList,
  ScheduledMaintenanceTaskDetailist,
  ScheduledMaintenanceTaskProofist,
} from "./transporter/smmechanictask.red.js";
import { SmMechanicList } from "./transporter/smmechanic.red.js";
import {
  SmApprovalTypeList,
  SmApprovalSetting,
  SmApprovals,
} from "./transporter/smapprovaltype.red.js";

import {
  MaintenanceCategories,
  MaintenanceSubCategories,
} from "./transporter/maintenance-categories-subcategories.red";
import {
  CurrativeOrders,
  CurrativeOrderProof,
} from "./transporter/currative-orders-red";
import { MaintenanceInvoice } from "./transporter/maintenance-invoice.red";
import {
  LiveMonitoringAddress,
  RenteeLiveMonitoringAddress,
} from "./transporter/redTransporter-Geocoding";
//Landlord
import { fetchLLLocationTypeList } from "./landlord/redLandLord-LocationTypeList";
import { fetchLLLocationList } from "./landlord/redLandLord-LocationList.jsx";
import { fetchLLSensorFabricatorList } from "./landlord/redLandLord-SensorFabricatorList.jsx";
import { fetchLLSensorClassList } from "./landlord/redLandLord-SensorClassList.jsx";
import { fetchLLSensorTypeList } from "./landlord/redLandLord-SensorTypeList.jsx";
import { fetchLLSensorList } from "./landlord/redLandLord-SensorList.jsx";
import { fetchLLSensorInstallationList } from "./landlord/redLandLord-SensorInstallationList.jsx";
import { fetchLLUtilityTypeList } from "./landlord/redLandLord-UtilityTypeList.jsx";
import { fetchLLResourceUsageTypeList } from "./landlord/redLandLord-ResourceUsageTypeList.jsx";
import { fetchLLUsageScheduleList } from "./landlord/redLandLord-UsageScheduleList.jsx";
import { fetchLLUtilityList } from "./landlord/redLandLord-UtilityList.jsx";
import { CovidVaccineList } from "./transporter/covidvaccine.red.js";
import { CovidTestList } from "./transporter/covidtest.red.js";

//Enermo
import {
  fetchLiveDataReducer,
  fetchLiveDataByIdDeviceTimeUnixReducer,
  fetchLiveDataByIdDeviceIdSensorTimeUnixReducer,
} from "./enermo/live-monitoring.red";
import {
  fetchKwhAverageReducer,
  fetchSensorHistoryGroupingReducer,
  fetchAlertHistoryGroupingReducer,
  fetchDeviceActiveTimeReducer,
} from "./enermo/processing-live-monitoring.red";
import { fetchSensorsReducer } from "./enermo/sensor-list.red";
import { utilityReducer } from "./enermo/utilities.red";
import {
  visualModelReducer,
  visualModelDeviceReducer,
  visualModelSensorReducer,
  visualModelUtilitiesReducer,
} from "./enermo/visual-modeling.red";
import {
  generalSettingReducer,
  budgetSettingReducer,
} from "./enermo/setting.red";
import { devicesReducer } from "./enermo/devices.red";
import { monthlyKwhSetsReducer } from "./enermo/Dashboard/monthly-kwh-sets.red";

// Pointhub
import { PthCustomer, PthCustomerSync } from "./pointhub/pth.customer.red.js";
import { PthBanks } from "./pointhub/pth.banks.red.js";
import { PthCustomersPic } from "./pointhub/pth.customerpic.red.js";
import { PthPicPositions } from "./pointhub/pth.picpositions.red.js";
import { PthCustomerBankAcc } from "./pointhub/pth.customerbankacc.red.js";
import { PthCustomerApproval } from "./pointhub/pth.customerapproval.red.js";

// Stocker Related
import { StckOperatingAreas } from "./stocker/stck.operatingareas.red.js";
import { StckUnits } from "./stocker/stck.units.red.js";
import { StckAdmOffices } from "./stocker/stck.admoffices.red.js";
import { StckOperatingAreaGeofences } from "./stocker/stck.operatingareageofence.red.js";
import { StckWarehouse } from "./stocker/stck.warehouse.red.js";
import { StckCountries } from "./stocker/stck.countries.red.js";
import { StckBatchs } from "./stocker/stck.batchs.red";
import { StckBrands } from "./stocker/stck.brands.red.js";
import { StckProvinces } from "./stocker/stck.provinces.red.js";
import { StckItemCategories } from "./stocker/stck.itemcategories.red.js";
import { StckItemQualityCategories } from "./stocker/stck.itemqualitycategories.red.js";
import { StckItemDepreciationMethods } from "./stocker/stck.itemdepreciationmethods.red.js";
import { StckItemHandlingCategories } from "./stocker/stck.itemhandlingcategories.red.js";
import { StckItemTaxCategories } from "./stocker/stck.itemtaxcategories.red.js";
import { StckWarehouseRules } from "./stocker/stck.warehouserules.red.js";
import { StckWarehouseOperationRules } from "./stocker/stck.warehouseoperationrules.red.js";
import { StckWarehouseGeofences } from "./stocker/stck.warehousegeofence.red.js";
import { StckWarehouseArea } from "./stocker/stck.warehousearea.red.js";
import { StckTrackerTypes } from "./stocker/stck.trackertypes.red.js";
import { StckDivision } from "./stocker/stck.division.red.js";
import { StckVendor } from "./stocker/stck.vendor.red.js";
import { StckVendorBank } from "./stocker/stck.vendorbank.red.js";
import { StckVendorPic } from "./stocker/stck.vendorpic.red.js";
import { StckMasterInbound } from "./stocker/stck.masterinbound.red";
import { StckInboundDetail } from "./stocker/stck.inbounddetail.red.js";
import { StckInboundVehicle } from "./stocker/stck.inboundvehicle.red.js";
import { StckInboundItemDetail } from "./stocker/stck.inbounditemdetail.red.js";
import { StckInboundOfficerAsst } from "./stocker/stck.inboundofficerasst.red.js";
import { StckIdentityTypes } from "./stocker/stck.identitytypes.red.js";
import { StckOfficerTypes } from "./stocker/stck.officertype.red.js";
import { StckPurchaseOrder } from "./stocker/stck.purchaseorder.red.js";
import { StckPoType } from "./stocker/stck.potype.red.js";
import { StckPoRule } from "./stocker/stck.porule.red.js";
import { StckPackageType } from "./stocker/stck.pacakgetype.red.js";
import { StckPackageMaterialType } from "./stocker/stck.packagematrialtype.red.js";
import { StckPackageMaterialComposition } from "./stocker/stck.packagematerialcomposition.red.js";
import { StckRepackaging } from "./stocker/stck.repackaging.red.js";
import { StckRepackagingItem } from "./stocker/stck.repackagingitem.red.js";
import { StckPaymentTerm } from "./stocker/stck.paymentterm.red.js";
import { StckInboundDriverOfficer } from "./stocker/stck.inbounddriverofficer.red.js";
import {
  StckPoApproval,
  StckPoApprovalCheck,
} from "./stocker/stck.poapproval.red.js";
import { StckPoPrintJob } from "./stocker/stck.poprintjob.red.js";
import { StckPoPayment } from "./stocker/stck.popayment.red.js";
import { StckMeasureUnit } from "./stocker/stck.masureunit.red.js";
import {
  StckMasterItem,
  StckMasterItemIntercompany,
  StckMasterItemCountCheck,
} from "./stocker/stck.masteritem.red.js";
import { StckMasterItemDetail } from "./stocker/stck.masteritemdetail.red.js";
import { StckMasterItemDetailAdjustment } from "./stocker/stck.masteritemdetailadjustment.red";
import { StckMutations } from "./stocker/stck.mutations.red";
import { StckMasterDefectItem } from "./stocker/stck.defectitem.red.js";
import {
  StckMasterOutbound,
  StckMasterOutboundDoWo,
} from "./stocker/stck.masteroutbound.red.js";
import { StckOutboundType } from "./stocker/stck.outboundtype.red.js";
import { StckOutboundDetail } from "./stocker/stck.outbounddetail.red";
import { StckOutboundItemDetail } from "./stocker/stck.outbounditemdetail.red";
import { StckSalesOrder } from "./stocker/stck.salesorder.red.js";
import { StckSoType } from "./stocker/stck.sotype.red.js";
import { StckSoRule } from "./stocker/stck.sorule.red.js";
import {
  StckSoApproval,
  StckSoApprovalCheck,
} from "./stocker/stck.soapproval.red.js";
import { StckSoPrintJob } from "./stocker/stck.soprintjob.red.js";
import { StckSoPayment } from "./stocker/stck.sopayment.red.js";
import { StckDisposalItem } from "./stocker/stck.disposalitem.red.js";
import { StckReturnOrder } from "./stocker/stck.returnorder.red.js";
import { StckTransferMethod } from "./stocker/stck.transfermethod.red.js";
import { StckDisposalType } from "./stocker/stck.disposaltype.red.js";
import { StckDisposalItemDetail } from "./stocker/stck.disposalitemdetail.red";
import { StckCompanyBankAcc } from "./stocker/stck.companybankacc.red.js";
import { StckPoItemDetail } from "./stocker/stck.poitemdetail.red";
import { StckSoItemDetail } from "./stocker/stck.soitemdetail.red";
import { StckMasterWarehouseTransfer } from "./stocker/stck.masterwarehousetransfer.red.js";
import {
  StckWarehouseTransferApproval,
  StckWarehouseTransferApprovalCheck,
} from "./stocker/stck.warehousetransferapproval.red.js";
import { StckWarehouseTransferApprovalSetting } from "./stocker/stck.warehousetransferapprovalsetting.red.js";
import { StckWarehouseTransferDetail } from "./stocker/stck.warehousetransferdetail.red.js";
import { StckWarehouseTransferItem } from "./stocker/stck.warehousetransferitem.red.js";
import { StckWarehouseTransferApprovalType } from "./stocker/stck.warehousetransferapprovaltype.red.js";
import { StckIntercompany } from "./stocker/stck.intercompany.red.js";
import {
  StckOpnames,
  StckOpnameTaskDetails,
  StckMyOpnames,
} from "./stocker/stck.opname.red.js";
import { StckRacks } from "./stocker/stck.rack.red.js";
import { StckRackVisualBuilder } from "./stocker/stck.rackvisualbuilder.red.js";
//Carbonx
import { CarbonXLoggerScope1 } from "./carbonx/carbonx.logger-scope1.red.js";
import { CarbonXLoggerScope3Downstream } from "./carbonx/carbonx.logger-scope3downstream.red";
import { OutboundItemsCarb } from "./carbonx/carbonx.logger-scope3downstream.red";
import { CarbonXLoggerScope3Upstream } from "./carbonx/carbonx.logger-scope3Upstream.red";
import { carbonInvoice } from "./carbonx/carbonx.invoice.red";

//Rentee
import {
  RenteeRequest,
  RenteeRequestFleet,
  RenteeContract,
  RenteeRequestsRenteeContract,
  RenteeRequestContract,
  RenteeRequestFleetDetail,
  RenteeContractAll,
  RenteeContractOwnerAll,
} from "./transporter/rentee-request.red";
import {
  RenteeApprovedFleets,
  RenteeListFleets,
  RenteeLiveFleets,
} from "./transporter/rentee-request-impl.red";

import intercompanyOrder from "./pointhub/pth.intercompanyorder.red";
import walletSaldo from "./wallet/redWallet-Saldo";
import cctvActive from "./cctv/redCctv-active.js";
const rootReducer = combineReducers({
  cctvStore: cctvActive,
  authStore: fetchAuth,
  intercompanyOrder: intercompanyOrder,
  verifyStore: userVerification,
  // Transporter Reducers
  carDetailsTableStore: fetchCarDetailsTable,
  driversTableStore: fetchDriversTable,
  driversSummaryStore: fetchDriversSummary,
  driverFleetsStore: fetchDriverFleets,
  driversAssistantTableStore: fetchDriversAssistantTable,
  deliveryOrdersTableStore: fetchDeliveryOrdersTable,
  workOrdersTableStore: fetchWorkOrdersTable,
  woUploadedImageStore: fetchWoUploadedImage,
  woUploadedImageCompareStore: fetchWoUploadedImageCompare,
  CchvStore: Cchv,

  // form: reduxFormReducer,
  deleteStore: fetchDeleteItem,
  carBrandsTableStore: fetchCarBrandsTable,
  carTypeTableStore: fetchCarTypeTable,
  carGeneralTypeTableStore: fetchCarGeneralTypeTable,
  fleetListTableStore: fetchFleetListTable,
  fleetQrListStore: FleetQrList,
  fleetMaintenanceBindingListStore: FleetMaintenanceBindingList,
  carFuelTableStore: fetchCarFuelTable,
  obdTypeTableStore: fetchOBDTypeTable,
  deliveryCheckInStore: fetchDeliveryCheckInLog,
  savedPlacesTableStore: fetchSavedPlacesTable,
  savedPlacesMeshStore: fetchSavedPlaceMesh,
  savedPlaceNearStore: fetchSavedPlaceNear,
  publicFacilityStore: publicFacility,
  publicFacilityTypeStore: publicFacilityType,
  publicFacilityGlobalStore: publicFacilityGlobal,
  securityTableStore: fetchSecurityTable,
  fleetReportTableStore: fetchFleetReport,
  changeFleetReportTripStore: changeFleetReportTrip,
  fuelReportTableStore: fetchFuelReport,
  deliveryCheckInStatusStore: fetchDeliveryCheckInStatus,
  userTableStore: fetchUserTable,
  workOrderShareStore: fetchWorkOrderShare,
  workOrdersReviewStore: fetchWorkOrdersReview,
  workOrderAdderMultiStore: fetchWorkOrderAdderMulti,
  fleetNotificationDashboardStore: fetchFleetNotificationDashboard,
  fleetMaintenanceNotificationDashboardStore:
    fetchFleetMaintenanceNotificationDashboard,
  driversNotificationDashboardStore: fetchDriversNotificationDashboard,
  fleetMaintenanceTableStore: fetchFleetMaintenanceTable,
  mainCustomerStore: fetchMainCustomerList,
  deliveryAuditStore: fetchDeliveryAudit,
  deliveryStatusListStore: fetchDeliveryStatusList,
  liveMonitoringFleetForEstCostListStore: fetchLiveMonitoringFleetForEstCost,
  liveMonitoringShareTokenStore: LiveMonitoringShareToken,
  workOrderStocker: fetchWorkOrdersStocker,
  woInboundItemDetailStore: woInboundItemDetail,
  woOtuboundItemDetailStore: woOtuboundItemDetail,

  // Fuel Management
  fuelManagementListStore: fetchFuelManagementList,

  // 3PL Related
  triPLWorkOrderRequestResultStore: fetchTriPLWorkOrderRequestResult,
  triPLWorkOrderListStore: fetchTriPLWorkOrderList,
  triPLIncomingBidListStore: fetchTriPLIncomingBidList,
  triPLApprovedRequestListStore: fetchTriPLApprovedRequestList,
  triPLWoBidHistoryListStore: fetchTriPLWoBidHistoryList,
  triPLGroupingListStore: fetchTriPLGroupingList,
  triPLGroupingAssignCreateFindStore: fetchTriPLGroupingAssignCreateFind,
  triPLGroupingAssignedFindStore: fetchTriPLGroupingAssigneFind,
  fetchTriPLGroupingAssigneFindNotificationStore:
    fetchTriPLGroupingAssigneFindNotification,
  TriPLGroupFleetCheckStore: fetchTriPLGroupFleetCheck,
  triPLEstCostStore: fetchTriPLEstCost,
  triPLMemberViewStore: fetchTriPLMemberView,
  triPLApprovedCheckPaymentStore: fetchTriPLApprovedCheckPayment,
  triPLApprovedGenerateInvoiceStore: fetchTriPLApprovedGenerateInvoice,
  triPLWinnerTrackerStore: fetchTriPLWinnerTracker,
  fetchTriPLHistoryPathStore: fetchTriPLHistoryPath,
  triPLiveMonitoringStore: fetchTriPLWLiveMonitoring,

  driverAppUserStore: DriverAppUsersList,
  deleteDriverAppUserStore: fetchDeleteDriverAppUser,

  mechanicStore: MechanicList,
  mechanicCertificationStore: MechanicCertificationList,
  mechanicSpecializationStore: MechanicSpecializationList,
  mechanicOperationalHourStore: MechanicOperationalHourList,

  workshopStore: WorkshopList,
  bindedWorkshopListStore: BindedWorkshopList,
  workshopOperationalHourStore: WorkshopOperationalHourList,
  workshopPicPositionsStore: PicPositions,
  workShopPicsStore: Pics,
  workshopPaymentTermTypesStore: PaymentTermTypes,

  checkSheetsStore: CheckSheets,
  checkSheetsDetailStore: CheckSheetsDetail,
  checkSheetsApprovalTypeStore: CheckSheetApprovalType,
  checkSheetBindingDoStore: CheckSheetBindingDo,

  scheduledMaintenanceStore: ScheduledMaintenanceList,
  smApprovalTypeStore: SmApprovalTypeList,
  smApprovalSettingStore: SmApprovalSetting,
  smApprovalsStore: SmApprovals,
  smMechanicTaskStore: SMMechanicTaskList,
  scheduledMaintenanceTaskDetailStore: ScheduledMaintenanceTaskDetailist,
  scheduledMaintenanceTaskProofistStore: ScheduledMaintenanceTaskProofist,
  maintenanceInvoiceStore: MaintenanceInvoice,
  smMechanicStore: SmMechanicList,
  liveMonitoringAddressStore: LiveMonitoringAddress,
  renteeLiveMonitoringAddressStore: RenteeLiveMonitoringAddress,

  maintenanceCategoriesStore: MaintenanceCategories,
  maintenanceSubCategoriesStore: MaintenanceSubCategories,
  packageMaintenanceStore: PackageMaintenance,
  packageMaintenanceTaskStore: PackageMaintenanceTask,
  preventiveMaintenanceStore: PreventiveMaintenance,
  preventiveMaintenanceTaskStore: PreventiveMaintenanceTask,
  fleetMaintenanceReminderDashboardListStore:
    FleetMaintenanceReminderDashboardList,
  UnplannedStopLockedStore: UnplannedStop,

  currativeOrdersStore: CurrativeOrders,
  currativeOrderProofStore: CurrativeOrderProof,

  transporterDivisionsStore: Divisions,
  checkSheetApprovalSettingStore: CheckSheetApprovalSetting,
  checkSheetDoDetailStore: CheckSheetDoDetail,
  checkSheetApprovalsStore: CheckSheetApprovals,

  //Settings
  settingStore: fetchSettingList,
  companyStore: fetchCompanyList,
  companyAllStore: fetchCompanyAllList,
  servicePlanStore: servicePlan,
  covidVaccineStore: CovidVaccineList,
  covidTestStore: CovidTestList,

  // Landlord Reducers
  landlordSensorReadingsStore: landlord_fetchLandlordSensorReadings,
  landlordLocationTypeStore: fetchLLLocationTypeList,
  landlordLocationStore: fetchLLLocationList,
  landlordSensorFabricatorStore: fetchLLSensorFabricatorList,
  landlordSensorClassStore: fetchLLSensorClassList,
  landlordSensorTypeStore: fetchLLSensorTypeList,
  landlordSensorStore: fetchLLSensorList,
  landlordSensorInstallationStore: fetchLLSensorInstallationList,
  landlordUtilityTypeStore: fetchLLUtilityTypeList,
  landlordResourceUsageTypeStore: fetchLLResourceUsageTypeList,
  landlordUsageScheduleStore: fetchLLUsageScheduleList,
  landlordUtilityStore: fetchLLUtilityList,

  //Enermo
  liveDataStore: fetchLiveDataReducer,
  liveDataByIdDeviceTimeUnixStore: fetchLiveDataByIdDeviceTimeUnixReducer,
  liveDataByIdDeviceIdSensorTimeUnixStore:
    fetchLiveDataByIdDeviceIdSensorTimeUnixReducer,

  processingLiveMonitoringKwhAverageStore: fetchKwhAverageReducer,
  processingLiveMonitoringSensorHistoryGroupingStore:
    fetchSensorHistoryGroupingReducer,
  processingLiveMonitoringSensorAlertGroupingStore:
    fetchAlertHistoryGroupingReducer,
  processingLiveMonitoringDeviceActiveTimeStore: fetchDeviceActiveTimeReducer,

  visualModelReducerStore: visualModelReducer,
  visualModelSensorReducerStore: visualModelSensorReducer,
  visualModelUtilitiesReducerStore: visualModelUtilitiesReducer,
  visualModelDeviceReducerStore: visualModelDeviceReducer,

  sensorsStore: fetchSensorsReducer,
  utilitiesStore: utilityReducer,

  monthlyKwhSetsStore: monthlyKwhSetsReducer,

  generalSettingStore: generalSettingReducer,
  budgetSettingStore: budgetSettingReducer,

  devicesStore: devicesReducer,

  // Stocker
  stckOperatingAreaStore: StckOperatingAreas,
  stckUnitsStore: StckUnits,
  stckAdmOfficeStore: StckAdmOffices,
  stckOperatingAreaGeofenceStore: StckOperatingAreaGeofences,
  stckWarehouseStore: StckWarehouse,
  stckBatchsStore: StckBatchs,
  stckBrandsStore: StckBrands,
  stckCountriesStore: StckCountries,
  stckProvincesStore: StckProvinces,
  stckItemCategoriesStore: StckItemCategories,
  stckItemQualityCategoriesStore: StckItemQualityCategories,
  stckItemDepreciationMethodsStore: StckItemDepreciationMethods,
  stckItemHandlingCategoriesStore: StckItemHandlingCategories,
  stckItemTaxCategoriesStore: StckItemTaxCategories,
  stckWarehouseRulesStore: StckWarehouseRules,
  stckWarehouseOperationRulesStore: StckWarehouseOperationRules,
  stckWarehouseGeofencesPointsStore: StckWarehouseGeofences,
  stckWarehouseAreaStore: StckWarehouseArea,
  stckTrackerTypesStore: StckTrackerTypes,
  stckDivisionStore: StckDivision,
  stckVendorStore: StckVendor,
  stckVendorBankStore: StckVendorBank,
  stckVendorPicStore: StckVendorPic,
  stckMainInboundStore: StckMasterInbound,
  stckInboundDetailStore: StckInboundDetail,
  stckInboundVehicleStore: StckInboundVehicle,
  stckInboundItemDetailStore: StckInboundItemDetail,
  stckInboundOfficerAsstStore: StckInboundOfficerAsst,
  stckIdentityTypeStore: StckIdentityTypes,
  stckOfficerTypeStore: StckOfficerTypes,
  stckPurchaseOrderStore: StckPurchaseOrder,
  stckPoTypeStore: StckPoType,
  stckPoRuleStore: StckPoRule,
  stckPaymentTermStore: StckPaymentTerm,
  stckPackageTypeStore: StckPackageType,
  stckPackageMaterialTypeStore: StckPackageMaterialType,
  stckPackageMaterialCompositionStore: StckPackageMaterialComposition,
  stckRepackagingStore: StckRepackaging,
  stckRepackagingItemStore: StckRepackagingItem,
  stckInboundDriverOfficerStore: StckInboundDriverOfficer,
  stckPoApprovalStore: StckPoApproval,
  stckPoApprovalCheckStore: StckPoApprovalCheck,
  stckPoPrintJobStore: StckPoPrintJob,
  stckPoPaymentStore: StckPoPayment,
  stckMasterItemStore: StckMasterItem,
  stckMasterItemIntercompanyStore: StckMasterItemIntercompany,
  stckMasterItemCountCheckStore: StckMasterItemCountCheck,
  stckMasterItemDetailStore: StckMasterItemDetail,
  stckMasterItemDetailAdjustmentStore: StckMasterItemDetailAdjustment,
  stckMutationStore: StckMutations,
  stckMasterDefectItemStore: StckMasterDefectItem,
  stckMasterOutboundStore: StckMasterOutbound,
  StckMasterOutboundDoWoStore: StckMasterOutboundDoWo,
  stckOutboundTypeStore: StckOutboundType,
  stckOutboundDetailStore: StckOutboundDetail,
  stckSalesOrderStore: StckSalesOrder,
  stckSoTypeStore: StckSoType,
  stckSoRuleStore: StckSoRule,
  stckSoApprovalStore: StckSoApproval,
  stckSoApprovalCheckStore: StckSoApprovalCheck,
  stckSoPrintJobStore: StckSoPrintJob,
  stckSoPaymentStore: StckSoPayment,
  stckDisposalItemStore: StckDisposalItem,
  stckReturnOrderStore: StckReturnOrder,
  stckTransferMethodStore: StckTransferMethod,
  stckDisposalTypeStore: StckDisposalType,
  stckDisposalItemDetailStore: StckDisposalItemDetail,
  stckCompanyBankAccStore: StckCompanyBankAcc,
  stckPoItemDetailStore: StckPoItemDetail,
  stckSoItemDetailStore: StckSoItemDetail,
  stckOutboundItemDetailStore: StckOutboundItemDetail,
  stckMeasureUnitStore: StckMeasureUnit,
  stckMasterWarehouseTransferStore: StckMasterWarehouseTransfer,
  stckWarehouseTransferApprovalStore: StckWarehouseTransferApproval,
  stckWarehouseTransferApprovalCheckStore: StckWarehouseTransferApprovalCheck,
  stckWarehouseTransferApprovalSettingStore:
    StckWarehouseTransferApprovalSetting,
  stckWarehouseTransferDetailStore: StckWarehouseTransferDetail,
  stckWarehouseTransferItemStore: StckWarehouseTransferItem,
  stckWarehouseTransferApprovalTypeStore: StckWarehouseTransferApprovalType,
  stckIntercompanyStore: StckIntercompany,
  stckOpnamesStore: StckOpnames,
  stckOpnameTaskDetailsStore: StckOpnameTaskDetails,
  stckMyOpnamesStore: StckMyOpnames,
  stckRacksStore: StckRacks,
  stckRackVisualBuilderStore: StckRackVisualBuilder,
  // Pointhub
  pthCustomersStore: PthCustomer,
  pthCustomersSyncStore: PthCustomerSync,
  pthCustomersPicStore: PthCustomersPic,
  pthBanksStore: PthBanks,
  pthPicPositionsStore: PthPicPositions,
  pthCustomerBankAccStore: PthCustomerBankAcc,
  pthCustomerApprovalStore: PthCustomerApproval,

  //CarbonX
  carbonxLoggerScope1Store: CarbonXLoggerScope1,
  carbonXLoggerScope3Downstream: CarbonXLoggerScope3Downstream,
  carbonXLoggerScope3UpstreamStore: CarbonXLoggerScope3Upstream,
  outboundItemsCarbStore: OutboundItemsCarb,
  carbonInvoiceStore: carbonInvoice,

  renteeRequestStore: RenteeRequest,
  renteeRequestContractStore: RenteeRequestContract,
  renteeRequestFleetStore: RenteeRequestFleet,
  renteeRequestFleetDetailStore: RenteeRequestFleetDetail,
  renteeContractStore: RenteeContract,
  renteeRequestsRenteeContractStore: RenteeRequestsRenteeContract,
  renteeApprovedFleetStore: RenteeApprovedFleets,
  renteeLiveFleetsStore: RenteeLiveFleets,
  renteeContractAllStore: RenteeContractAll,
  renteeContractOwnerAllStore: RenteeContractOwnerAll,
  renteeListFleetsStore: RenteeListFleets,

  walletSaldo: walletSaldo,
});

export default rootReducer;

import axios from "axios";
import * as API_LINKS from '../config/links.jsx'
import {toast} from 'react-toastify'

export function runSetupForCompanyWorkshop(){
    if(!localStorage.Automalt)
        return (dispatch, getState) => {}
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    return (dispatch, getState) => {
        axios({
            method: 'post',
            url: API_LINKS.COMPANY_SETTING_LIST_URL,
            headers: {
                Authorization: auth
            },
        }).then((response) => {
            dispatch( {
                type: "RECEIVE_COMPANY_LIST",
                payload: response.data
            })
            let companySettingData = response.data.result[0]
            const WORKSHOP_COMPANY_TYPE = 2
            let isCompanyTypeWorkshop = companySettingData.id_type === WORKSHOP_COMPANY_TYPE
            if(!isCompanyTypeWorkshop)
                return false
            axios({
                method: 'post',
                url: API_LINKS.WORKSHOP_LIST_URL,
                headers: {
                    Authorization: auth
                },
            }).then(response => {
                let workshopData = response.data.result
                let isCompanyWorkshopDataRegistered = 
                    workshopData.some(w => w.id_company === companySettingData.id)
                if(isCompanyWorkshopDataRegistered)
                    return false
                axios({
                    method: 'post',
                    url: API_LINKS.WORKSHOP_ADD_URL,
                    headers: {
                        Authorization: auth
                    },
                    data: {
                        name:companySettingData.company,
                        address:companySettingData.company_address,
                        latitude:0,
                        longitude:0,
                        main_phone_number:companySettingData.telp
                    }
                })
            })
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    return toast.error(error.response.data.message);
                } else {
                    return toast.error('Something went wrong... Please try again later...');
                }
            } else if (error.request) {
                return toast.error('Request error! Please try again later...')
            } else {
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }
}
import { useEffect, useState } from "react";
import BindCheckSheet from "../BindCheckSheet/BindCheckSheet";
import { CHECK_SHEET_WEEKLY_DETAIL_LIST, CHECK_SHEET_WEEKLY_LIST } from "src/redux/config/links";
import useLazyFetch from "src/hooks/useLazyFetch";
import LoadingContent from "src/components/LoadingContent/LoadingContent";
import Table from "src/components/Table/Table";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import CheckSheetWeeklySubmission from "../CheckSheetWeeklySubmission/CheckSheetWeeklySubmission";
import CheckSheetWeeklyApprovalList from "../CheckSheetWeeklyApprovalList/CheckSheetWeeklyApprovalList";
import { activeFeature } from "src/constants/constants";

interface IProps {
    fleet: {
        id: number;
    };
    onClose?: () => void;
    initialValues?: any;
    isEdit?: boolean;
}

const CheckSheetWeekly: React.FC<IProps> = ({ fleet, isEdit }) => {
    const [isGetting, setIsGetting] = useState(true);
    const [data, setData] = useState<{ id: number; check_sheet_detail: number[] } | null>(null);
    const [isReady, setReady] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const [dataDetail, setDataDetail] = useState<{ id: number; check_sheet_name: string; } | null>(null);
    const [loadingOnDataDetail, setLoadingOnDataDetail] = useState<number | null>(null);
    const [modalSubmission, setModalSubmission] = useState<{ id: number; } | null>(null);

    const [getCheckSheetWeeklyList, { loading, data: listData }] =
        useLazyFetch({
            url: CHECK_SHEET_WEEKLY_LIST,
            method: "POST",
        });

    const [getCheckSheetWeeklyDetailList] =
        useLazyFetch({
            url: CHECK_SHEET_WEEKLY_DETAIL_LIST,
            method: "POST",
        });

    useEffect(() => {
        if (isGetting) {
            setIsGetting(false);
            getCheckSheetWeeklyList({
                body: {
                    id_fleet: fleet.id
                }
            }, (response, err) => {
                if (!err) {
                    const dt = JSON.parse(JSON.stringify(response?.data.result));
                    dt.sort((a: { created_on: string; id: number }, b: { created_on: string; id: number }) => {
                        const dt = new Date(a.created_on);
                        const new_dt = new Date(b.created_on);

                        return new_dt.getTime() - dt.getTime() || b.id - a.id;

                    });
                    const [newest] = dt;
                    setData(newest);
                    setReady(true);
                }
            })
        }
    }, [isGetting, getCheckSheetWeeklyList, fleet]);

    const onDataDetail = () => {
        setLoadingOnDataDetail(data?.id ?? null)
        getCheckSheetWeeklyDetailList({
            body: {
                id_check_sheet_weekly: data?.id
            }
        }, (response) => {
            setLoadingOnDataDetail(null)
            if(data){
                setData({...data, check_sheet_detail: response?.data.result.map((item: { id_check_sheet_detail: any; })=>item.id_check_sheet_detail)} ?? [])
            }
            setModalAdd(true)
        })
    }


    return <>

        <LoadingContent
            loading={loading}
            onReload={() => { setIsGetting(true); return null; }}
        >
            <Table
                name={"Check Sheet weekly"}
                data={listData?.result ?? []}
                option={{
                    btnGroup: (props) => (
                        <div>
                            <Button
                                color={"secondary"}
                                onClick={() => getCheckSheetWeeklyList(true)}
                            >
                                <i className="fa fa-refresh"></i> Reload
                            </Button>{" "}
                            <Button
                                color={"primary"}
                                onClick={() => onDataDetail()}
                                disabled={loadingOnDataDetail!==null}
                            >
                                <i className="fa fa-plus"></i> Bind Check sheet
                            </Button>
                            {props.exportCSVBtn}{" "}
                        </div>
                    ),
                }}
                columns={[{
                    field: "id",
                    name: "ID",
                    isKey: true,
                    hidden: true,
                    dataSort: true,
                }, {
                    field: "check_sheet_name",
                    name: "Name",
                    dataSort: true,
                    width: "20%"
                }, {
                    field: "created_on",
                    name: "Active",
                    dataSort: true,
                    width: "20%",
                    dataFormat: (cell, row) => {
                        return <>{row.id === data?.id ? "Active" : ""}</>
                    }
                }, {
                    field: "created_on",
                    name: "Created On",
                    dataSort: true,
                    width: "20%",
                }, {
                    field: "name",
                    name: "Action",
                    width: "40%",
                    dataFormat: (cell, row) => {
                        return <>
                        <Button color="primary" size="sm" onClick={() =>
                            setDataDetail({
                                id: row.id,
                                check_sheet_name: row.check_sheet_name,
                            })}>
                            <><i className="fa fa-file-text-o"></i> List Approval</>
                        </Button>
                        {data?.id===row.id && activeFeature.CHECKSHEET_WEEKLY_SUBMISSION ?<Button color="warning" size="sm" onClick={() =>
                            setModalSubmission({
                                id: row.id,
                            })}>
                            <><i className="fa fa-file-text-o"></i> Submit Checksheet</>
                        </Button>:null}
                        </>
                    }
                }]}
            />
        </LoadingContent>

        <Modal
            isOpen={modalAdd}
            toggle={() => setModalAdd(false)}
            size="lg"
        >
            <ModalHeader toggle={() => setModalAdd(false)}>
                Check Sheet Weekly
            </ModalHeader>
            <ModalBody>
                <LoadingContent loading={loading} onReload={() => { setIsGetting(true); return null; }}>

                    {isReady ? <BindCheckSheet fleet={fleet} initialValues={data} isEdit={isEdit ?? false} onClose={() => {
                        setModalAdd(false);
                        setIsGetting(true);
                    }} /> : null}
                </LoadingContent>
            </ModalBody>
        </Modal>
        <Modal
            isOpen={!!modalSubmission}
            toggle={() => setModalSubmission(null)}
            size="lg"
        >
            <ModalHeader toggle={() => setModalSubmission(null)}>
                Submit Check Sheet Weekly
            </ModalHeader>
            <ModalBody>
                {modalSubmission?<CheckSheetWeeklySubmission id={modalSubmission?.id} onClose={()=>setModalSubmission(null)} />:null}
            </ModalBody>
        </Modal>
        <Modal
            isOpen={!!dataDetail}
            toggle={() => setDataDetail(null)}
            size="lg"
        >
            <ModalHeader toggle={() => setDataDetail(null)}>
                Approval List
            </ModalHeader>
            <ModalBody>
                {dataDetail?<CheckSheetWeeklyApprovalList id={dataDetail?.id} onClose={()=>setModalSubmission(null)} />:null}
            </ModalBody>
        </Modal>
    </>
}

export default CheckSheetWeekly;
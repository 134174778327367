import React from 'react';

import { Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: './assets/fonts/Roboto-Regular.ttf',
    },
    {
      fontStyle: 'bold',
      src: './assets/fonts/Roboto-Bold.ttf',
    },
    {
      fontStyle: 'bolditalic',
      src: './assets/fonts/Roboto-BoldItalic.ttf',
    },
    {
      fontStyle: 'black',
      src: './assets/fonts/Roboto-Black.ttf',
    },
],
});

const styles = StyleSheet.create({
  header: {
    fontSize: 7,
    marginBottom: 10,
    textAlign: 'center',
    color: 'grey',
  },
  container: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
    margin: 5
  },
  logoColumn: {
    flexDirection: 'column',
    flexGrow: 7,
    height: 'auto',
    width: '8%',
    margin: '10'
  },
  detailColumn: {
    marginTop: '30',
    flexDirection: 'column',
    flexGrow: 7,
    textTransform: 'uppercase',
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    height: 'auto',
    width: '8%'
  },
  qrHeader: {
    fontSize: 20,
    fontFamily: 'Roboto',
    fontStyle: 'bold'
  },
  invoiceNumber: {
    fontSize: 10,
    justifySelf: 'flex-end',
    fontFamily: 'Roboto',
  },
  link: {
    fontFamily: 'Roboto',
    fontSize: 10,
    color: 'black',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
});

export default (
  props
) => (
  <View style={styles.container}>
    {/* <View style={styles.header}>
      <Text>~ Generated automatically by system. Powered by automa.id. Printed on {moment().toLocaleString()} by {localStorage.AutomaEml} ~</Text>
    </View> */}
    <View style={styles.logoColumn}>
        <Image
          src={{
            uri: "/static/media/new-automa-logo.28615f70.png?noCache=" + Math.random().toString(),
            headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
          }}
          style={styles.image}
        />
    </View>
    <View style={styles.detailColumn}>
      {/* <Text style={styles.qrHeader}>Fleet(s) QR</Text> */}
      <Text style={styles.qrHeader}>Fleet(s) Qr</Text>
      {/* <Text style={styles.invoiceNumber}>PT. DMPL</Text> */}
    </View>
    {/* <View style={styles.linkColumn}>
      <Image
          src={{
            uri: "/static/media/new-automa-logo.28615f70.png" + '?noCache=' + Math.random().toString(),
            headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
          }}
          style={styles.image}
        />
    </View> */}
  </View>
);
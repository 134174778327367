import React, { Component } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Button,
    Col,
    Label,
    Input,
    FormFeedback
} from 'reactstrap'
import * as Yup from 'yup';
import { Formik } from 'formik';
import { SelectField } from '../../LandLord/Widgets/SelectField';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Datetime from 'react-datetime';
// import moment from 'moment';
import { BarLoader } from 'react-spinners';
import {toast} from 'react-toastify'
import axios from 'axios';
import * as API_LINKS from '../../../redux/config/links';
import { fetchSavedPlacesTable } from '../../../redux/actions/transporter/aTransporter-SavedPlacesTable';
import { fetchWorkOrdersTable } from '../../../redux/actions/transporter/aTransporter-WorkOrdersTable';

// Import for Maps
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    // InfoWindow, 
    KmlLayer
  } from "react-google-maps"
import { compose } from "recompose"

const propTypes = {
    id_do: PropTypes.any
}

const defaultProps = {
    id_do: 0,
}

function generateRandom() {
    return Math.random() * 10000000000000000
}

const MapWithAMarker = compose(withScriptjs, withGoogleMap)(props => {
    return (
        <GoogleMap defaultZoom={18} defaultCenter={{ lat: props.companyLat, lng: props.companyLng }} center={{ lat: props.lat, lng: props.lng }}>
            <KmlLayer url={props.kmlLink + "&ver=" + generateRandom()} options={{ preserveViewport: true }}/>
            {
                Array.isArray(props.marker) && props.marker.length === 0?
                true:

                <Marker
                    key={props.value}
                //   onClick={onClick}
                    //   options={{icon: customMarker}}
                    position={{ lat: props.lat, lng: props.lng }}
                >
                </Marker>
            }
        </GoogleMap>
    )
})

const validationSchema = Yup.object().shape({
    wo_number: Yup.string()
    .required("Work Order number should be stated!"),
    saved_place: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.number(),
      lat: Yup.number(),
      lng: Yup.number()
    })
    .nullable()
    .required("Location is required")
})


class WorkOrderAddHomeWO extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            modalAdd: false,
            initialValues: {
                wo_number: '',
                saved_place: {}
            }
        };
    }

    toggleOpen = () => {
        this.setState({
            modalAdd: !this.state.modalAdd
        })
    }

    toggleClose = () => {
        this.setState({
            modalAdd: !this.state.modalAdd
        })
    }


    getSavedPlaceByDOSettings = () => {
        const { selectorAllPfWithCustGroup } = this.props;
        const { doSetting } = this.props;
        if(doSetting.delivery_order_family_type === 1){
            return selectorAllPfWithCustGroup
        }
        return selectorAllPfWithCustGroup
    }


    viewAddHome = () => {
        return (
            <Formik
                initialValues={this.state.initialValues}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    var self = this;
                    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                    var auth = loginCreds.token;
                    setSubmitting(true)

                    axios({
                        method: 'post',
                        url: API_LINKS.WORK_ORDERS_DRAFT_ADD_URL,
                        headers: {
                            Authorization: auth
                        },
                        data: {
                            id_delivery_order: self.props.id_do,
                            wo_number: values.wo_number,
                            wo_seq: 9989,
                            id_saved_place: values.saved_place.value,
                        }
                    })
                    .then((response) => {
                        if(response.status === 200){
                            if (response.data.status === "success"){
                                setSubmitting(false)
                                toast.success('WO Home has been successfully added to the draft!')
                                self.toggleClose();
                                resetForm()
                                self.props.fetchWorkOrdersTable(self.props.id_do);
                            }
                        }
                    })
                    .catch(function(error){
                        if (error.response) {
                            if(error.response.status === 401) {
                                setSubmitting(false)
                                 toast.error("Invalid credentials! Please try again!");
                            } else if (error.response.status === 403) {
                                setSubmitting(false)
                                 toast.error("Unauthorized Access! Please try to logout..");
                            } else if (error.response.status === 400) {
                                setSubmitting(false)
                                 toast.error(error.response.data.message);
                            } else if (error.response.status === 404 || error.response.status === 500) {
                                setSubmitting(false)
                                 toast.error("Server cannot be contacted! Please ask your system administrator!");
                            } else {
                                setSubmitting(false)
                                 toast.error('Something went wrong... Please try again later...')
                            }
                        } else if (error.request) {
                            setSubmitting(false)
                             toast.error('Request error! Please try again later...')
                        } else {
                            setSubmitting(false)
                             toast.error('Something went wrong... Please try again later...')
                        }
                    })
                }}
                validationSchema={validationSchema}
                render={({
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                handleSubmit,
                handleBlur,
                handleChange,
                // handleReset,
                // setTouched,
                isValid,
                isSubmitting
                }) => (
                <form>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">WO Number</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text"
                                name="wo_number"
                                id="wo_number"
                                placeholder="WO Number"
                                valid={!errors.wo_number}
                                invalid={touched.wo_number && !!errors.wo_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.wo_number}
                                disabled={isSubmitting}
                            />
                          <FormFeedback>{errors.wo_number}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Home Location</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <SelectField
                                id="saved_place"
                                name="saved_place"
                                label="saved_place"
                                placeholder="Select location"
                                options={this.getSavedPlaceByDOSettings()}
                                value={values.saved_place}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.saved_place}
                                error={errors.saved_place}
                                isClearable={true}
                                backspaceRemovesValue={true}
                                disabled={isSubmitting}
                            />
                        </Col>
                        <FormFeedback>{errors.saved_place}</FormFeedback>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Location Plot</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <MapWithAMarker
                                // selectedMarker={this.state.selectedMarker}
                                // markers={this.state.markers}
                                lat={ values.saved_place ? values.saved_place.lat : this.props.companyLat }
                                lng={ values.saved_place ? values.saved_place.lng : this.props.companyLng }
                                // onClick={this.handleClick}
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `200px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                kmlLink={ this.props.statKML? this.props.kml : ''}
                                companyLat={ this.props.companyLat ? this.props.companyLat : 0 }
                                companyLng={ this.props.companyLng ? this.props.companyLng : 0 }
                            />
                        </Col>
                    </FormGroup>

                    {
                        isSubmitting ? 
                        <center><BarLoader color={'#123abc'} loading={isSubmitting} /> <br /> Loading.... Please wait...</center> 
                        : 
                        <div>
                            <Button onClick={handleSubmit} size="sm" color="success" disabled={isSubmitting || !isValid}><i className="fa fa-check"></i>{isSubmitting ? 'Wait...' : 'Submit'}</Button>
                            <Button onClick={this.toggleClose} size="sm" color="warning" disabled={isSubmitting || !isValid}><i className="fa fa-window-close"></i> Close</Button>
                        </div>
                    }         
                </form>
                )}
            />
        )
    }

    render() {
        return (
            <div>
                {/* <FormGroup> */}
                    {/* <Col md="12"> */}
                        <Button className="btn mr-2" onClick={() => this.toggleOpen()} color="success">
                            <div className="mr-1 ml-1 mb-1 mt-1">
                                <i className="fa fa-home"></i>&nbsp;Add WO Home
                            </div>
                        </Button>
                    {/* </Col> */}
                {/* </FormGroup> */}
                
                <Modal isOpen={this.state.modalAdd} toggle={this.toggleOpen} className={this.props.className}>
                    <ModalHeader toggle={this.toggleClose}>Add WO Home</ModalHeader>
                    <ModalBody>
                            {this.viewAddHome()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

WorkOrderAddHomeWO.propTypes = propTypes;
WorkOrderAddHomeWO.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => ({
    // KML and Company State
    kml: state.companyStore.companyKML,
    companyLat: state.companyStore.lat,
    companyLng: state.companyStore.lng,
    statKML: state.settingStore.kmlSet,

    // Saved Place State
    selectorAllPfWithCustGroup: state.savedPlacesTableStore.selectorAllPfWithCustGroup,
    savedPlaceWithMainCustomerLabel: state.savedPlacesTableStore.savedPlaceWithMainCustomerLabel,
    inProgress: state.savedPlacesTableStore.inProgress,
    successSavedPlaceFetch: state.savedPlacesTableStore.success,

    // Work Order Related
    // inProgressWorkOrder: state.workOrderStore.inProgress,
    // successWorkOrder: state.workOrderStore.success,
    // workOrderData: state.workOrderStore.workOrderData,

})

export default connect(mapStateToProps, {fetchSavedPlacesTable, fetchWorkOrdersTable})(WorkOrderAddHomeWO);
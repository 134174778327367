import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Col,
    Label,
    FormGroup
} from 'reactstrap'
import PropTypes from 'prop-types'
import {BarLoader} from 'react-spinners'
import axios from 'axios'
import * as API_LINKS from '../../../redux/config/links';
import {toast} from 'react-toastify'


const propTypes = {
    id_do: PropTypes.any,
    loading_state: PropTypes.bool,
    totalWO: PropTypes.number,
    optimize: PropTypes.bool
}

const defaultProps = {
    id_do: 0,
    loading_state: false,
    totalWO: 0,
    optimize: false
}

class WorkOrderSaveButton extends Component {
    constructor(props) {
        super(props);
        this.state = { 

        };
    }

    toggleOpenAdd = () => {
        this.setState({
            modalAdd: true
        })
    }

    toggleCloseAdd = () => {
        this.setState({
            modalAdd: false
        })
    }

    handleFetchSave = (_id) => {
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;

        axios({
            method:'post',
            url: API_LINKS.WORK_ORDERS_DRAFT_CONFIRM_URL, //Please change when it's ready
            headers: {
                Authorization: auth
            },
            data: {
                id_delivery_order: _id,
                optimize: self.props.optimize
            }
        })
        .then((response) => {
            // console.log(response)
            if(response.status === 200) {
                if (response.data.status === "success") {
                    self.setState({
                        isAdding: false,
                    })
                    self.toggleCloseAdd()
                    toast.success('WO has been finalized! Thank you for using Automa.id')
                    self.props.updateWO(self.props.id_do);
                }
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    self.setState({
                        isAdding: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    self.setState({
                        isAdding: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    self.setState({
                        isAdding: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    self.setState({
                        isAdding: false,
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    self.setState({
                        isAdding: false,
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                self.setState({
                    isAdding: false,
                })
                return toast.error('Request error! Please try again later...')
            } else {
                self.setState({
                    isAdding: false,
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })    
    }

    viewSaveWO = () => {
        var self = this;
        const { id_do, name } = this.props;
        return(
            <div className="container">
                <FormGroup row>
                    <Col xs="12" md="12">
                        {this.viewContent(name)}
                    </Col>
                </FormGroup>
                  
                {this.state.isLoading ? <center><BarLoader color={'#123abc'} 
                  loading={this.state.isLoading} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => self.handleFetchSave(id_do)} size="sm" color="success" disabled={this.state.isLoading || this.props.loading_state}><i className="fa fa-save"></i> Submit</Button>
                <Button onClick={() => this.toggleCloseAdd()} size="sm" color="warning" disabled={this.state.isLoading || this.props.loading_state}><i className="fa fa-window-close"></i> Cancel</Button></div>}         
            </div>
        )
    }

    viewContent = (_name) => {
        // eslint-disable-next-line
        if(_name == 'Check Optimization') {
            return (
                <Label htmlFor="text-input">Do you want to check the optimization of the route? <strong>This action will not save your current work order!</strong></Label>
            )
        } 
        // eslint-disable-next-line
        else if(_name == 'Save') {
            return (
                <Label htmlFor="text-input">Do you want to save and finalize this work order? <strong>Your action cannot be undone!</strong></Label>
            )
        }
        // eslint-disable-next-line
        else if(_name == 'Save & Optimize') {
            return (
                <Label htmlFor="text-input">Do you want to save, finalize and optimize this work order? <strong>Your action cannot be undone!</strong><br/>Your route will be determined by our direction service. You cannot customized once you finalized!</Label>
            )
        }
    }

    viewButton = (_name) => {
        // eslint-disable-next-line
        if(_name == 'Check Optimization') {
            return (
                <Button className="btn btn-warning btn-lg" onClick={() => this.toggleOpenAdd(_name)}><i className="fa fa-refresh"></i>&nbsp;{_name}</Button>
            )
        } 
        // eslint-disable-next-line
        else if(_name == 'Save') {
            return (
                <Button className="btn btn-success btn-lg" onClick={() => this.toggleOpenAdd(_name)}><i className="fa fa-save"></i>&nbsp;{_name}</Button>
            )
        }
        // eslint-disable-next-line
        else if(_name == 'Save & Optimize') {
            return (
                <Button className="btn btn-lg" color="primary" onClick={() => this.toggleOpenAdd(_name)}><i className="fa fa-superpowers"></i>&nbsp;{_name}</Button>
            )
        }
    }

    render() {
        const { totalWO, name } = this.props;
        return (
            <div>
                {
                    totalWO > 0 ? 
                    // 
                    this.viewButton(name)
                    :
                    true
                }

                <Modal isOpen={this.state.modalAdd} toggle={this.toggleOpenAdd} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseAdd}>Finalize WO?</ModalHeader>
                    <ModalBody>
                            {this.viewSaveWO()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

WorkOrderSaveButton.propTypes = propTypes;
WorkOrderSaveButton.defaultProps = defaultProps;

export default WorkOrderSaveButton;
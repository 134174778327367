import React, { Component } from 'react'
import {
    Button,
    FormGroup,
    Label,
    Col,
    FormFeedback,
    Input
} from 'reactstrap'
import axios from 'axios';
import { connect } from 'react-redux'
import {FLEET_MAINTENANCE_SETTING_INPUT_URL, FLEET_MAINTENANCE_SETTING_EDIT_URL, FLEET_MAINTENANCE_SETTING_BINDING_URL}  from '../../../../../redux/config/links'
import { fetchPackageMaintenance } from '../../../../../redux/actions/transporter/package-mainteance.act';
import { fetchPreventiveMaintenance } from '../../../../../redux/actions/transporter/preventive-maintenance.act';
import { BarLoader } from 'react-spinners';
import * as Yup from 'yup'
import { Formik } from 'formik'
import {toast} from 'react-toastify'
import handleErrorResponseCode from '../../../../../helpers/error-response-code-handler'
import { SelectField } from '../../../../LandLord/Widgets/SelectField'
class AddMaintenanceBinding extends Component {

    constructor(props){
        super(props)
        this.state = {
            preventiveMaintenancesSelectByDistanceOnly: [],
            preventiveMaintenanceInProgress: true,
            packageMaintenancesSelect: [],
            packageMaintenancesInProgress: true,
        }
    }

    componentDidMount = () => {
        this.props.fetchPackageMaintenance()
        this.props.fetchPreventiveMaintenance()
    }

    componentDidUpdate = (prevProps) => {
        const {preventiveMaintenancesSelectByDistanceOnly, preventiveMaintenanceInProgress} = this.props
        const {packageMaintenancesSelect, packageMaintenancesInProgress} = this.props
        if(prevProps.preventiveMaintenancesSelectByDistanceOnly !== preventiveMaintenancesSelectByDistanceOnly){
            this.setState({
                preventiveMaintenancesSelectByDistanceOnly
            })
        }
        if(prevProps.preventiveMaintenanceInProgress !== preventiveMaintenanceInProgress){
            this.setState({
                preventiveMaintenanceInProgress
            })
        }
        if(prevProps.packageMaintenancesSelect !== packageMaintenancesSelect){
            this.setState({
                packageMaintenancesSelect
            })
        }
        if(prevProps.packageMaintenancesInProgress !== packageMaintenancesInProgress){
            this.setState({
                packageMaintenancesInProgress
            })
        }
    }

    validation = () => {
        const {isAdd, isEdited} = this.props
        let yup = null
        if(isAdd){
            Yup.object().shape({
                id_preventive_maintenance_setting : Yup.number().required('Iterative setting cannot empty!'),
                id_package_maintenance : Yup.number().required('Package maintenance cannot empty!'),
            })
        }
        if(isEdited){
            Yup.object().shape({
                last_value_maintenance : Yup.number().required('Last maintenance km cannot empty!'),
            })
        }
        return yup
    }

    getSubmitText = () => {
        const {isAdd, isEdited, isDelete} = this.props
        if(isAdd)
            return "Add"
        if(isEdited)
            return "Edit"
        if(isDelete)
            return "Delete"
    }

    getSubmitColor= () => {
        const {isAdd, isEdited, isDelete} = this.props
        if(isAdd)
            return "primary"
        if(isEdited)
            return "warning"
        if(isDelete)
            return "danger"
    }

    form = () => {
        const {initialValues, isAdd, isEdited, isDelete, deletedRowId, editedRowId} = this.props
        const {refreshTable} = this.props
        const {preventiveMaintenancesSelectByDistanceOnly, packageMaintenancesSelect} = this.state
        const {preventiveMaintenanceInProgress, packageMaintenancesInProgress} = this.state
        return(
            <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, resetForm}) => {
                let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                let auth = loginCreds.token;
                setSubmitting(true)
                if(isAdd){
                    axios({
                            method: 'post',
                            url: FLEET_MAINTENANCE_SETTING_INPUT_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : values
                        }).then((response) => {
                            if(response.status === 200){
                                return response
                            }
                            return null
                        })
                        .then((response) => {
                            if(response.status === 200){
                                axios({
                                    method: 'post',
                                    url: FLEET_MAINTENANCE_SETTING_BINDING_URL,
                                    headers: {
                                        Authorization: auth,
                                    },
                                    data : {
                                        id_package_maintenance_settings: response.data.result.id,
                                        id_fleet: this.props.selectedFleetId
                                    }
                                })
                                .then((response) => {
                                    if(response.status === 200){
                                        setSubmitting(false)
                                        toast.success('Maintenance setting has been saved succesfully!')
                                        resetForm()
                                        refreshTable()
                                    }
                                    return null
                                })
                            }
                        })
                    .catch(function(error){
                        setSubmitting(false)
                        toast.error(handleErrorResponseCode(error))
                    })
                }
                if(isEdited){
                    axios({
                            method: 'post',
                            url: FLEET_MAINTENANCE_SETTING_EDIT_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : {
                                id: editedRowId,
                                last_value_maintenance: values.last_value_maintenance
                            }
                        }).then((response) => {
                            if(response.status === 200){
                                setSubmitting(false)
                                toast.success('Maintenance setting has been edited succesfully!')
                                resetForm()
                                refreshTable()
                            }
                            return null
                        })
                    .catch(function(error){
                        setSubmitting(false)
                        toast.error(handleErrorResponseCode(error))
                    })
                }
                if(isDelete){
                    axios({
                            method: 'post',
                            url: FLEET_MAINTENANCE_SETTING_EDIT_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : {
                                id: deletedRowId,
                                id_status: 2
                            }
                        }).then((response) => {
                            if(response.status === 200){
                                setSubmitting(false)
                                toast.success('Maintenance setting has been deleted succesfully!')
                                resetForm()
                                refreshTable()
                            }
                            return null
                        })
                    .catch(function(error){
                        setSubmitting(false)
                        toast.error(handleErrorResponseCode(error))
                    })
                }
                setSubmitting(false)
            }} 
            validationSchema={this.validation()}
            render={({
            values,errors,
            touched,setFieldValue,
            handleSubmit,handleBlur,
            handleReset, handleChange,
            isValid,isSubmitting,
            }) => (
                <form>
                        {isAdd &&
                            <>
                            <FormGroup row>
                                <Col md="4">
                                    <Label htmlFor="text-input">Package Maint.</Label>
                                </Col>
                                <Col xs="8" md="8">
                                    {packageMaintenancesInProgress && <center><BarLoader color={'#123abc'} loading={packageMaintenancesInProgress}/> <br /> Loading...</center>}
                                    {!packageMaintenancesInProgress && 
                                        <SelectField
                                            id="id_package_maintenance"
                                            name="id_package_maintenance"
                                            label="id_package_maintenance"
                                            placeholder="Select Package Maint."
                                            options={packageMaintenancesSelect}
                                            value={values.id_package_maintenance}
                                            isMulti={false}
                                            onChange={async (field, selection) => {
                                                setFieldValue(field, selection.value, true);
                                            }}
                                            onBlur={handleBlur}
                                            touched={touched.id_package_maintenance}
                                            error={errors.id_package_maintenance}
                                            isClearable={false}
                                            backspaceRemovesValue={false}
                                            disabled={isSubmitting}
                                            />
                                    }
                                <FormFeedback>{errors.id_package_maintenance}</FormFeedback>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md="4">
                                        <Label htmlFor="text-input">Iteration of Maint.</Label>
                                    </Col>
                                    <Col xs="8" md="8">
                                            {preventiveMaintenanceInProgress && <center><BarLoader color={'#123abc'} loading={preventiveMaintenanceInProgress}/> <br /> Loading...</center>}
                                            {!preventiveMaintenanceInProgress && <SelectField
                                                id="id_preventive_maintenance_setting"
                                                name="id_preventive_maintenance_setting"
                                                label="id_preventive_maintenance_setting"
                                                placeholder="Select Iteration of Maint."
                                                options={preventiveMaintenancesSelectByDistanceOnly}
                                                value={values.id_preventive_maintenance_setting}
                                                isMulti={false}
                                                onChange={async (field, selection) => {
                                                    setFieldValue(field, selection.value, true);
                                                }}
                                                onBlur={handleBlur}
                                                touched={touched.id_preventive_maintenance_setting}
                                                error={errors.id_preventive_maintenance_setting}
                                                isClearable={false}
                                                backspaceRemovesValue={false}
                                                disabled={isSubmitting}
                                                />
                                            }          
                                    <FormFeedback>{errors.id_preventive_maintenance_setting}</FormFeedback>
                                    </Col>
                            </FormGroup>
                            </>
                        }
                        {isEdited &&
                            <FormGroup row>
                                <Col md="4">
                                        <Label htmlFor="text-input">Last Maintenance km</Label>
                                    </Col>
                                    <Col xs="8" md="8">
                                    <Input type="number"
                                        id="last_value_maintenance"
                                        name="last_value_maintenance"
                                        placeholder="0"
                                        autoComplete="last-value-maintenance"
                                        valid={!errors.last_value_maintenance}
                                        invalid={touched.last_value_maintenance && !!errors.last_value_maintenance}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.last_value_maintenance}
                                        disabled={isSubmitting}
                                        />
                                        <FormFeedback>{errors.last_value_maintenance}</FormFeedback>
                                    </Col>
                            </FormGroup>
                        }
                        {isDelete &&
                            <FormGroup row>
                                 <Col md="4">
                                         <Label htmlFor="text-input">Are you sure to delete selected row?</Label>
                                     </Col>
                             </FormGroup>
                        }
                        <FormGroup>
                                <Button type="button" 
                                        onClick={handleSubmit} 
                                        color={this.getSubmitColor()}
                                        className="mr-1" 
                                        disabled={!isDelete? (isSubmitting || !isValid) : false}>{isSubmitting ? 'Wait...' : this.getSubmitText()}
                                </Button>
                                {!isDelete &&
                                    <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button>
                                }
                        </FormGroup>
                    </form>
                )}
            />
        )
    }

    render(){
        return(
            <React.Fragment>
                {this.form()}
            </React.Fragment>
        )
    }

} 

const mapStateToProps = (state) => {
    return {
        preventiveMaintenancesSelectByDistanceOnly: state.preventiveMaintenanceStore.preventiveMaintenancesSelectByDistanceOnly,
        preventiveMaintenanceInProgress: state.preventiveMaintenanceStore.inProgress,

        packageMaintenancesSelect: state.packageMaintenanceStore.packageMaintenancesSelect,
        packageMaintenancesInProgress: state.packageMaintenanceStore.inProgress,
    }
}
export default connect(mapStateToProps, {fetchPackageMaintenance, fetchPreventiveMaintenance})(AddMaintenanceBinding);
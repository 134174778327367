import React, { Component } from 'react';
import { connect } from 'react-redux'
import { fetchSavedPlacesWihMesh } from '../../../../redux/actions/transporter/aTransporter-SavedPlacesTable';
import { BarLoader } from 'react-spinners';
import Papa from 'papaparse';
import moment from 'moment';
import {
    Button
} from 'reactstrap'
class ExportSavedPlaceWithMeshButton extends Component{

    componentDidUpdate = (prevProps) => {
        const {savedPlaceMeshData} = this.props
        if(prevProps.savedPlaceMeshData !== savedPlaceMeshData){
            this.unparseMeshDatatoCsv(savedPlaceMeshData)
        }
    }

    unparseMeshDatatoCsv = (meshData) => {
       const {period} = this.props
       if(meshData.length === 0)
        return
       const csv = Papa.unparse({
            "fields": ["Customer Name", "Location Name", "Location Address",
                        "Customer Phone Number","Customer Email",
                        "Latitude", "Longitude",
                        // "Mesh Detected", "Mesh Name", 
                        "WO Called", "Visit", "UT", "Load Time Ave"],
            "data" : meshData, 
        }, { delimiter: ';' });

        //Create CSV Document
        let currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss")
        let fileNameStr = `Saved Place With Mesh ${currentDateTime} - ${period.label}.csv`
        const blob = new Blob([csv])
        const a = document.createElement('a')
        a.href = URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = fileNameStr
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    render(){
        const {loading, data, period} = this.props
        return(
            <>
                {loading === "loading" &&
                    <center>
                        <BarLoader color={'#123abc'} loading={true} /> 
                        <br /> Loading.... Please wait...
                    </center> 
                }
                 {(loading === "loaded") &&
                    <Button color="success" size="md" disabled={data.length === 0} onClick={() => this.props.fetchSavedPlacesWihMesh(data, period.value)}>
                        <i className="fa fa-download"></i> Export to CSV
                    </Button>
                }
                {(!loading || loading === "error") &&
                    <Button color="success" size="md" disabled={data.length === 0} onClick={() => this.props.fetchSavedPlacesWihMesh(data, period.value)}>
                        <i className="fa fa-download"></i> Export to CSV
                    </Button>
                }
                {/* <ToastContainer position="bottom-right" autoClose={7500} style={{zIndex: 7000}}/> */}
            </>
        )
    }

}
const mapStateToProps = (state) => ({
    savedPlaceMeshData: state.savedPlacesMeshStore.savedPlaceMeshData,
    loading: state.savedPlacesMeshStore.loading
})
export default connect(mapStateToProps, {fetchSavedPlacesWihMesh})(ExportSavedPlaceWithMeshButton);
import React from 'react';
import WelcomeMessage from '../containers/DefaultLayout/WelcomeMessage'
import Login from '../views/Users/Login/Login';
import Logout from '../views/Users/Logout/Logout';

const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));
const FleetMaintenance = React.lazy(() => import('../views/Fleet/FleetMaintenance/FleetMaintenanceMain.jsx'));
const SMMechanicTask = React.lazy(() => import('../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMMechanicTask/SMMechanicTask.jsx'));
const SMApprovalSetting = React.lazy(() => import('../views/Settings/FleetMaintenanceSettings/ApprovalType/ApprovalTypeSetting'));
const SMApproval = React.lazy(() => import('../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMApproval'));
const SMMechanicTaskDetail =  React.lazy(() => import('../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMMechanicTask/SMMechanicTaskDetail'));
const Mechanics = React.lazy(() => import('../views/Fleet/Mechanics/MechanicsMain.jsx'));
const MechanicsCertification = React.lazy(() => import('../views/Fleet/Mechanics/MechanicsCertification/MechanicsCertification.jsx'));
const MechanicsSpecialization = React.lazy(() => import('../views/Fleet/Mechanics/MechanicsSpecialization/MechanicsSpecialization.jsx'));
const MechanicsOperationalHour = React.lazy(() => import('../views/Fleet/Mechanics/MechanicsOperationalHour/MechanicsOperationalHour.jsx'));
const WSMMechanicTask = React.lazy(() => import('../views/Fleet/FleetMaintenance/ScheduledMaintenanceWorkshop/SMMechanicTask/SMMechanicTask'));
const WSMMechanicTaskDetail =  React.lazy(() => import('../views/Fleet/FleetMaintenance/ScheduledMaintenanceWorkshop/SMMechanicTask/SMMechanicTaskDetail'));

const Workshop = React.lazy(() => import('../views/Fleet/Workshop/WorkshopMain.jsx'));
const WorkshopOperationalHour = React.lazy(() => import('../views/Fleet/Workshop/WorkshopOperationalHour/WorkshopOperationalHour.jsx'));

const CurrativeOrder = React.lazy(() => import('../views/Fleet/FleetMaintenance/CurrativeOrders'));
const CheckSheet = React.lazy(() => import('../views/Fleet/CheckSheet'));
const CheckSheetDetail = React.lazy(() => import('../views/Fleet/CheckSheet/MainCheckSheet/MainCheckSheetDetail'));
const CheckSheetApprovalSetting = React.lazy(() => import('../views/Fleet/CheckSheet/ApprovalType/ApprovalTypeSetting'));
const DoCheckSheetApproval = React.lazy(() => import('../views/Fleet/DeliveryOrders/DOChecksheetApproval'));
const PackageMaintenanceTask = React.lazy(() => import('../views/Settings/FleetMaintenanceSettings/PackageMaintenance/PackageMaintenanceTask'));
const PreventiveMaintenanceTask = React.lazy(() => import('../views/Settings/FleetMaintenanceSettings/PreventiveMaintenance/PreventiveMaintenanceTask'));

const routes = [
    { path: '/welcome', name: 'Welcome Message', component: WelcomeMessage , exact: true },
    { path: '/transporter/dashboard', name: 'Dashboard', component: Dashboard, exact:true },
    { path: '/transporter/mechanics', name: 'Mechanics', component: Mechanics, exact: true },
    { path: '/transporter/mechanics/certification/:id', name: 'Mechanics Certification', component: MechanicsCertification, exact: true },
    { path: '/transporter/mechanics/specialization/:id', name: 'Mechanics Specialization', component: MechanicsSpecialization, exact: true },
    { path: '/transporter/mechanics/operational-hour/:id', name: 'Mechanics Operational Hour', component: MechanicsOperationalHour, exact: true },
    { path: '/transporter/workshop', name: 'Workshop', component: Workshop, exact: true },
    { path: '/transporter/workshop/operational-hour/:id', name: 'Workshop Operational Hour', component: WorkshopOperationalHour, exact: true },
    { path: '/transporter/fleet-maintenance', name: 'Fleet Maintenance', component: FleetMaintenance, exact: true },
    { path: '/transporter/fleet-maintenance/mechanic-task/:id', name: 'Scheduled Maintenance Mechanic Task', component: SMMechanicTask, exact: true },
    { path: '/transporter/fleet-maintenance/mechanic-task-detail/:id', name: 'Scheduled Maintenance Mechanic Task Detail', component: SMMechanicTaskDetail, exact: true },  
    { path: '/transporter/fleet-maintenance/workshop-mechanic-task/:id', name: 'Scheduled Maintenance Mechanic Task', component: WSMMechanicTask, exact: true },
    { path: '/transporter/fleet-maintenance/workshop-mechanic-task-detail/:id', name: 'Scheduled Maintenance Mechanic Task Detail', component: WSMMechanicTaskDetail, exact: true },
    { path: '/transporter/currative-order', name: 'Currative Order', component: CurrativeOrder, exact: true },
    { path: '/transporter/check-sheet', name: 'Check Sheet', component: CheckSheet, exact: true },
    { path: '/transporter/check-sheet/details/:id', name: 'Check Sheet Detail', component: CheckSheetDetail, exact: true },
    { path: '/transporter/check-sheet/approval-setting/:id', name: 'Check Sheet Approval Setting', component: CheckSheetApprovalSetting, exact: true },
    { path: '/transporter/do-check-sheet/approval/:id', name: 'Do Check Sheet Approval', component: DoCheckSheetApproval, exact: true },
    { path: '/transporter/package-maintenance-task/:id', name: 'Package Maintenance', component: PackageMaintenanceTask, exact: true },
    { path: '/transporter/preventive-maintenance-task/:id', name: 'Preventive Maintenance', component: PreventiveMaintenanceTask, exact: true },
    { path: '/transporter/scheduled-maintenance/approval-setting/:id', name: 'Scheduled Maintenance Approval Setting', component: SMApprovalSetting, exact: true },
    { path: '/transporter/scheduled-maintenance/approval/:id', name: 'Scheduled Maintenance Approval', component: SMApproval, exact: true },


  //User Management
  { path: '/login', name: 'Login', component: Login },
  { path: '/logout', name: 'Logout', component: Logout },

]

export default routes;
import React, {Component} from 'react'
import classnames from 'classnames';
import { Col, Nav, NavItem, 
            NavLink, Row, TabContent, 
            TabPane } from 'reactstrap';
import {toast} from 'react-toastify'
import OperationalHour from './OperationalHour'
import Pic from './Pic';
// import PaymentTermType from './PaymentTermType';

class SettingTab extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            activeTab : "operational-hour"
        }
    }

    getTabContent = () => {
        const {id_workshop} = this.props
        return [
            {
                dataTab : "operational-hour",
                tabName : "Operational Hour",
                tabIcon: "fa fa-clock-o",
                component : <OperationalHour id_workshop={id_workshop}/>
            },
            // {
            //     dataTab : "pic-position",
            //     tabName : "PIC Position",
            //     tabIcon: "fa fa-user",
            //     component : <PicPosition />
            // },
            {
                dataTab : "PIC",
                tabName : "PIC",
                tabIcon: "fa fa-id-card-o",
                component : <Pic  id_workshop={id_workshop}/>
            },
            // {
            //     dataTab : "PaymentTermType",
            //     tabName : "Payment Term Type",
            //     tabIcon: "fa fa-money",
            //     component : <PaymentTermType />
            // },
        ]
    }

    render(){
        return(
            <>
                {this.props.id_workshop?
                    <this.Tabs tabContent={this.getTabContent()} />
                    :
                    toast.error("id workshop not available!")
                }
            </>
        )
    }

    Tabs = (props) => {
        const {tabContent} = props
        const {activeTab} = this.state
        return(
            <React.Fragment>
                <Row>
                    <Col xs="12" md="12" className="mb-4">
                        <Nav tabs>
                            {tabContent.map((content, index) => (
                            <NavItem key={index+1}>
                                <NavLink    
                                    className={classnames({active: activeTab === content.dataTab})}
                                    onClick={() => { this.onActiveTabChange(content.dataTab); }}
                                >
                                    <i className={content.tabIcon}></i>&nbsp; 
                                    {content.tabName}
                                </NavLink>
                            </NavItem>
                            ))}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            {tabContent.map((content, index) => (
                                <TabPane 
                                    tabId={content.dataTab} 
                                    key={index+1}>
                                    {content.component}
                                </TabPane>
                            ))}
                        </TabContent>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    onActiveTabChange = (dataTab) => {
        this.setState({
            activeTab : dataTab
        })
    }   

} export default SettingTab
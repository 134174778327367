import React, { Component } from 'react';
import axios from 'axios'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Col,
    Input,
    Label,
    FormFeedback,
} from 'reactstrap'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {toast} from 'react-toastify'
import { REGISTER_URL, DRIVER_EDIT_URL} from '../../../redux/config/links';


const validationSchema = Yup.object().shape({
    email: Yup.string()
    .required('Email is required')
    .matches(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i,
    "Must be a valid email address"
    ),
    password: Yup.string()
    .required('Password is required!')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$&()*|;'?\\-`.+,_/\"])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
})

class DriverAppRegisterButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }


    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    showContent = () => {
        const { id,name, telp} = this.props
        return (
            <Formik
                initialValues={this.state.initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    var self = this;
                    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                    var auth = loginCreds.token;
                    var link = REGISTER_URL
                    setSubmitting(true)

                    var _data = {
                        email: values.email,
                        password: values.password,
                        first_name: name.substring(0, name.indexOf(' ')),
                        last_name: name.substring(name.indexOf(' ') + 1),
                        phone:telp,
                        id_level: 8
                    }

                    axios({
                        method: 'post',
                        url: link,
                        headers: {
                            Authorization: auth
                        },
                        data: _data
                    })
                    .then((response) => {
                        // console.log(response)
                        if(response.status === 200){
                            axios({
                                method: 'post',
                                url: DRIVER_EDIT_URL,
                                headers: {
                                    Authorization: auth
                                },
                                data: {
                                    id:id,
                                    user_id: response.data.result.id
                                }
                            })
                            .then((response) => {
                                if(response.status === 200){
                                    setSubmitting(false)
                                    toast.success('You have record the username successfully!')
                                    self.toggle();
                                    self.props.fetchData();
                                }
                            })
                        }
                    })
                    .catch(function(error){
                        if (error.response) {
                            if(error.response.status === 401) {
                                setSubmitting(false)
                                return toast.error("Invalid credentials! Please try again!");
                            } else if (error.response.status === 403) {
                                setSubmitting(false)
                                return toast.error("Unauthorized Access! Please try to logout..");
                            } else if (error.response.status === 400) {
                                setSubmitting(false)
                                return toast.error(error.response.data.message);
                            } else if (error.response.status === 404 || error.response.status === 500) {
                                setSubmitting(false)
                                return toast.error("Server cannot be contacted! Please ask your system administrator!");
                            } else {
                                setSubmitting(false)
                                return toast.error('Something went wrong... Please try again later...')
                            }
                        } else if (error.request) {
                            setSubmitting(false)
                            return toast.error('Request error! Please try again later...')
                        } else {
                            setSubmitting(false)
                            return toast.error('Something went wrong... Please try again later...')
                        }
                    })
                }}
                validationSchema={validationSchema}
                render={({
                values,
                errors,
                touched,
                handleSubmit,
                handleBlur,
                handleChange,
                isValid,
                isSubmitting
                }) => (
                <form>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Email</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Email i.e: john@doe.com"
                                    valid={!errors.email}
                                    invalid={touched.email && !!errors.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    disabled={isSubmitting}
                                />
                            <FormFeedback>{errors.email}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Password</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="password"
                                    name="password"
                                    id="password"
                                    placeholder="*********"
                                    valid={!errors.password}
                                    invalid={touched.password && !!errors.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    disabled={isSubmitting}
                                />
                            <FormFeedback>{errors.password}</FormFeedback>
                        </Col>
                    </FormGroup>
                    
                    <FormGroup>
                        <Button type="button" onClick={handleSubmit} color="primary" className="mr-1" disabled={isSubmitting || !isValid}>{isSubmitting ? 'Wait...' : 'Submit'}</Button>
                        <Button type="button" color="warning" className="mr-1" disabled={isSubmitting} onClick={this.toggleCloseAdd}>Close</Button>
                        {/* <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button> */}
                    </FormGroup>
                </form>
                )}
            />
        )
    }

    toggleCloseAdd = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    render() {
        const { isLoading, name } = this.props
            return (
                <div>
                    <Button className="btn btn-primary" onClick={() => this.toggle()} disabled={isLoading} color="primary" size="sm"><i className="fa fa-plus"></i> Register App</Button>
    
                    {/* Modal for Add Covid Test */}
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                        <ModalHeader toggle={this.toggle}>Register App for {name}</ModalHeader>
                        <ModalBody>
                                {this.showContent()}
                        </ModalBody>
                    </Modal>
                </div>
            );
    }
}

export default DriverAppRegisterButton;
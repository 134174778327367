import React, { Component } from 'react';
import {Card, Table, Button, Modal, ModalHeader, ModalBody, Col, Input, FormGroup, Label} from 'reactstrap';
import { connect } from "react-redux";
import { BarLoader } from 'react-spinners';
import {Gmaps, Marker, InfoWindow, Circle} from 'react-gmaps';
import { fetchDeliveryCheckInLog } from "../../../redux/actions/transporter/aTransporter-DeliveryCheckInLog.jsx";
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const params = {
    v: '3.exp', 
    key: 'AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg'
  };

class CheckInLog extends Component {
    constructor(props){
        super(props);
        this.state = {
            modalShare: false
        }
    }

    UNSAFE_componentWillMount = () => {
        this.props.fetchDeliveryCheckInLog(this.props.id)
    }

    toggleOpenShare = (_shareToken, _woNumber, _desc, _dest ) => {
        this.setState({
            selectedShareToken: _shareToken,
            selectedWOShare: _woNumber,
            selectedDescShare: _desc,
            selectedDestShare: _dest,
            modalShare: !this.state.modalShare
        })
    }

    toggleCloseShare = () => {
        this.setState({
            selectedShareToken: "",
            selectedWOShare: "",
            selectedDescShare: "",
            selectedDestShare: "",
            copied: false,
            modalShare: !this.state.modalShare
        })
    }

    onMapCreated(map) {
        map.setOptions({
          disableDefaultUI: false
        });
    }

    dateFormatter = (_data) => {
        var date = moment(_data).format("DD-MMM-YYYY HH:mm:ss");
        if(date !== "Invalid date"){
            return date
        }
        else {
            return "Time limit exceeded"
        }
    }


    renderCheckInLog = () => {
        if(this.props.deliveryCheckInLogData !== "" || this.props.deliveryCheckInLogData !== [] || this.props.deliveryCheckInLogData !== null){
            return this.props.deliveryCheckInLogData.map(data => {
                return(
                    <div>
                        <hr/>
                        <Button className="btn btn-primary pull-right" color="primary" size="sm" onClick={() => this.toggleOpenShare(data.share, data.work_order_from.wo_number, data.work_order_from.wo_desc, data.work_order_from.wo_destination_name)}><i className="fa fa-copy"></i> Share WO</Button>
                        <br/><br/>
                        <Card>
                            <div className="fleet-mondtl-container">
                                <div className="fleet-mondtl-table">
                                    <Table responsive bordered={false}>
                                        <tbody>
                                            <tr>
                                                <td><h6>WO Number</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.wo_number}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>WO Seq</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.wo_seq}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>WO Desc</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.wo_desc}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>WO Destination Name</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.wo_destination_name}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>WO Address</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.wo_address}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="fleet-mondtl-table">
                                    <Table responsive bordered={false}>
                                        <tbody>
                                            <tr>
                                                <td><h6>PIC Name</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.pic_name}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>PIC Phone</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.pic_phone}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>Location Name</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.loc_name}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>Latitude</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.lat}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>Longitude</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.lng}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="fleet-mondtl-table">
                                    <Table responsive bordered={false}>
                                        <tbody>
                                            <tr>
                                                <td><h6>Distance</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.distance} KM</td>
                                            </tr>
                                            <tr>
                                                <td><h6>Est. Departure Time</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{this.dateFormatter(data.work_order_from.est_departure_time)}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>Est. Arrival Time</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{this.dateFormatter(data.work_order_from.est_arrival_time)}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>Check In Time</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{this.dateFormatter(data.timeCheckIn)}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>Check Out Time</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{this.dateFormatter(data.timeCheckOut)}</td>
                                            </tr>
                                            {/* <tr>
                                                <td><h6>Departure Time</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.dept_time}</td>
                                            </tr>
                                            <tr>
                                                <td><h6>Arrival Time</h6></td>
                                                <td>&nbsp;</td>
                                                <td>{data.work_order_from.arrival_time}</td>
                                            </tr> */}
                                        </tbody>
                                    </Table>
                                </div>
                                {/* Check In Details */}
                                <div className="report-dodetails-table">
                                    <Table responsive borderless>
                                        <tbody>
                                            {/* <tr>
                                                <td><h6>Check In Time</h6></td>
                                                <td><h6>{this.dateFormatter(data.timeCheckIn)}</h6></td>
                                            </tr> */}
                                            <tr>
                                                <td><h6>Distance from Check In Location</h6></td>
                                                <td><h6>{data.meterToCheckIn} M</h6></td>
                                            </tr>
                                            <tr>
                                                <td><h6>Loading Time</h6></td>
                                                <td><h6>{data.loadingTime}</h6></td>
                                            </tr>
                                            {/* <tr>
                                                <td><h6>Check Out Time</h6></td>
                                                <td><h6>{this.dateFormatter(data.timeCheckOut)}</h6></td>
                                            </tr> */}
                                        </tbody>
                                    </Table>
                                </div>
            
                            <div className="report-dodetails-table">
                                <Gmaps
                                    width="100%"
                                    height="20vw"
                                    lat={data.latitude}
                                    lng={data.longitude}
                                    zoom={16}
                                    loadingMessage={"Please wait while we're preparing this for you..."}
                                    params={params}
                                    onMapCreated={this.onMapCreated}>
                                    <Marker
                                        lat={data.latitude}
                                        lng={data.longitude}
                                        draggable={true}
                                        onDragEnd={this.onDragEnd}
                                    />
                                    <InfoWindow
                                        lat={data.latitude+0.0008}
                                        lng={data.longitude}
                                        content={"<strong>Lat: </strong>"+data.latitude+"<strong>,<br/> Long: </strong>"+data.longitude}
                                        onCloseClick={this.onCloseClick}
                                    />
                                    <Circle
                                        lat={data.latitude}
                                        lng={data.longitude}
                                        radius={100}
                                        // onClick={this.onClick}
                                    />
                                </Gmaps>
                                {/* <Table responsive borderless>
                                    <tbody>
                                        <tr>
                                            <td><h6>Check Out Time</h6></td>
                                            <td><h6>{data.timeCheckOut}</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6>Latitude</h6></td>
                                            <td><h6>{data.latitude}</h6></td>
                                        </tr>
                                        <tr>
                                            <td><h6>Longitude</h6></td>
                                            <td><h6>{data.longitude}</h6></td>
                                        </tr>
                                    </tbody>
                                </Table> */}
                            </div>
                        </div>
                    </Card>
                </div>
                )
            })
        } else {
            return (
                <Card>
                    <center><h6>There is no data to display!</h6></center>
                </Card>
            )
        }
        
    }

    viewShareWO = () => {
        return(
            <div>
                {this.showShareWO()}
                <hr/>
                <Button onClick={() => this.toggleCloseShare()} size="sm" color="success"><i className="fa fa-window-close"></i> Close</Button>
            </div>
        )
    }

    showShareWO = () => {
        const { selectedShareToken, selectedWOShare, selectedDescShare, selectedDestShare } = this.state;            return(
                <div className="container">
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">WO Number</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text" name="text-input" id="shareWONumber" placeholder={selectedWOShare} disabled/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Description</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text" name="text-input" id="shareWODesc" placeholder={selectedDescShare} disabled/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Destination</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text" name="text-input" id="shareDestination" placeholder={selectedDestShare} disabled/>
                        </Col>
                    </FormGroup>
                    <hr/>
                    <FormGroup row>
                        <Col xs="12" md="12">
                            <Input type="text" name="text-input" id="copyText" value={'track.automa.id/#/transporter/'+selectedShareToken} disabled={true}/>
                        </Col>
                        <Col md="6">
                            <CopyToClipboard text={'track.automa.id/#/transporter/'+selectedShareToken}
                                onCopy={() => this.setState({copied: true})}>
                                <Button size="sm" color="primary"><i className="fa fa-copy"></i> Copy</Button>
                            </CopyToClipboard>
                            {this.state.copied ? <span style={{color: 'red'}}>Link Copied.</span> : null}
                        </Col>     
                    </FormGroup>                           
                </div>
            )
    }

    render(){
        return(
            
            <div className="fleet-mondtl-container">
            {this.props.inProgress ? <center><BarLoader color={'#123abc'} 
                loading={this.props.inProgress} 
                /> <br /> Loading.... Please wait...</center> : this.renderCheckInLog()}
            {/* {this.renderCheckInLog()} */}
                {/* <div className="fleet-mondtl-table">
                    <Table responsive bordered={false}>
                        <tbody>
                            <tr>
                                <td><h6>WO Number</h6></td>
                                <td>&nbsp;</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><h6>WO Seq</h6></td>
                                <td>&nbsp;</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><h6>WO Desc</h6></td>
                                <td>&nbsp;</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><h6>WO Destination Name</h6></td>
                                <td>&nbsp;</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><h6>WO Address</h6></td>
                                <td>&nbsp;</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="fleet-mondtl-table">
                    <Table responsive bordered={false}>
                        <tbody>
                            <tr>
                                <td><h6>PIC Name</h6></td>
                                <td>&nbsp;</td>
                                <td>92 KM</td>
                            </tr>
                            <tr>
                                <td><h6>PIC Phone</h6></td>
                                <td>&nbsp;</td>
                                <td>10 L</td>
                            </tr>
                            <tr>
                                <td><h6>Location Name</h6></td>
                                <td>&nbsp;</td>
                                <td>30 KPH</td>
                            </tr>
                            <tr>
                                <td><h6>Latitude</h6></td>
                                <td>&nbsp;</td>
                                <td>30 KPH</td>
                            </tr>
                            <tr>
                                <td><h6>Longitude</h6></td>
                                <td>&nbsp;</td>
                                <td>30 KPH</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="fleet-mondtl-table">
                    <Table responsive bordered={false}>
                        <tbody>
                            <tr>
                                <td><h6>Distance</h6></td>
                                <td>&nbsp;</td>
                                <td>30 KPH</td>
                            </tr>
                            <tr>
                                <td><h6>Est. Departure Time</h6></td>
                                <td>&nbsp;</td>
                                <td>04:34:21</td>
                            </tr>
                            <tr>
                                <td><h6>Est. Arrival Time</h6></td>
                                <td>&nbsp;</td>
                                <td>05:23:44</td>
                            </tr>
                            <tr>
                                <td><h6>Departure Time</h6></td>
                                <td>&nbsp;</td>
                                <td>09:58:05</td>
                            </tr>
                            <tr>
                                <td><h6>Arrival Time</h6></td>
                                <td>&nbsp;</td>
                                <td>09:58:05</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                {/* Check In Details */}
                {/* <div className="report-dodetails-table">
                    <Table responsive borderless>
                        <tbody>
                            <tr>
                                <td><h6>Check In Time</h6></td>
                                <td><h6></h6></td>
                            </tr>
                            <tr>
                                <td><h6>Distance from Check In Location</h6></td>
                                <td><h6> M</h6></td>
                            </tr>
                            <tr>
                                <td><h6>Loading Time</h6></td>
                                <td><h6></h6></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div className="report-dodetails-table">
                    <Table responsive borderless>
                        <tbody>
                            <tr>
                                <td><h6>Check Out Time</h6></td>
                                <td><h6></h6></td>
                            </tr>
                            <tr>
                                <td><h6>Latitude</h6></td>
                                <td><h6></h6></td>
                            </tr>
                            <tr>
                                <td><h6>Longitude</h6></td>
                                <td><h6></h6></td>
                            </tr>
                        </tbody>
                    </Table>
                </div> */}

                {/* Modal for Share WO */}
                <Modal isOpen={this.state.modalShare} toggle={this.toggleOpenShare} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={this.toggleCloseShare}>Share WO</ModalHeader>
                    <ModalBody>
                            {this.viewShareWO()}
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        deliveryCheckInLogData: state.deliveryCheckInStore.deliveryCheckInLogData,
        inProgress: state.deliveryCheckInStore.inProgress,
      }
}

export default connect(mapStateToProps, {fetchDeliveryCheckInLog}) (CheckInLog)
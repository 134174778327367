import React from "react";
import History from "./History/History";
import Withdrawal from "./Withdrawal/Withdrawal";
import WithdrawalSettings from "./WithdrawalSettings/WithdrawalSettings";

export const walletRoute = (id_role) => {
  const path = "/wallet";
  return [
    {
      path: `${path}`,
      name: "Wallet",
      exact: true,
      render: () => <></>,
    },
    {
      path: `${path}/history`,
      name: "History",
      exact: true,
      render: (props) => <History {...props} />,
    },
    ...(id_role === 4
      ? [
          {
            path: `${path}/widthdrawal`,
            name: "Widthdrawal",
            exact: true,
            render: (props) => <Withdrawal {...props} />,
          },
          {
            path: `${path}/widthdrawal-settings`,
            name: "Widthdrawal Settings",
            exact: true,
            render: (props) => <WithdrawalSettings {...props} />,
          },
        ]
      : []),
  ];
};

import React from "react";
import NumberFormat from "react-number-format";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as yup from "yup";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import {
  MAINTENANCE_MECHANIC_TASK_DETAIL_INPUT,
  MAINTENANCE_MECHANIC_TASK_DETAIL_EDIT,
} from "../../../../../redux/config/storing.links";
import useForm from "../../../../../hooks/useForm";

const TaskDetailAdd = ({
  initialValues = null,
  isEdit = false,
  onReload,
  onClose,
}) => {
  const [onInputTask] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_TASK_DETAIL_INPUT,
    method: "POST",
  });

  const [onEditTask] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_TASK_DETAIL_EDIT,
    method: "POST",
  });

  const handleChange = (e) => {
    setFieldValue(e.currentTarget.name, e.currentTarget.value);
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (!isEdit) {
      onInputTask(
        {
          body: {
            id_storing_maintenance_mechanic_task:
              initialValues.id_storing_maintenance_mechanic_task,
            task_name: values.task_name,
            desc: values.desc,
            cost: values.cost,
          },
        },
        () => {
          setSubmitting(false);
          onReload();
          onClose();
        }
      );
    } else {
      onEditTask(
        {
          body: {
            id_storing_maintenance_mechanic_task:
              initialValues.id_storing_maintenance_mechanic_task,
            task_name: values.task_name,
            desc: values.desc,
            cost: values.cost,
            id: initialValues.id,
          },
        },
        () => {
          setSubmitting(false);
          onReload();
          onClose();
        }
      );
    }
  };
  const validation = () => {
    return yup.object().shape({
      task_name: yup.string().required("Task Name is required!"),
      desc: yup.string().required("Description is required!"),
      cost: yup.string().required("Cost is required!"),
    });
  };

  const {
    handleSubmit,
    errors,
    values,
    handleReset,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues: {
      task_name: !!initialValues && !!initialValues.task_name ? initialValues.task_name : "",
      desc: !!initialValues && !!initialValues.desc ? initialValues.desc : "",
      cost: !!initialValues && !!initialValues.cost ? initialValues.cost : "",
    },
    validation: validation(),
  });
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="task_name">Task Name</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="task_name"
              id="task_name"
              placeholder="Task Name"
              autoComplete="off"
              valid={!errors.task_name}
              invalid={!!errors.task_name}
              onChange={handleChange}
              value={values.task_name || ""}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.task_name}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="desc">Description</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="desc"
              id="desc"
              placeholder="Description"
              autoComplete="off"
              valid={!errors.desc}
              invalid={!!errors.desc}
              onChange={handleChange}
              value={values.desc || ""}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.desc}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="cost">Cost</Label>
          </Col>
          <Col xs="12" md="9">
            <NumberFormat
              name="cost"
              id="cost"
              value={values.cost}
              thousandSeparator={true}
              disabled={isSubmitting}
              prefix={"Rp. "}
              onValueChange={(values) => {
                const { value } = values;
                return setFieldValue("cost", value);
              }}
            />

            <FormFeedback>{errors.cost}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            color="primary"
            className="mr-1"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Wait..." : !isEdit ? "Save Changes" : "Edit"}
          </Button>
          <Button
            type="button"
            color="warning"
            className="mr-1"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >
            Close
          </Button>
          <Button
            type="reset"
            color="danger"
            className="mr-1"
            disabled={isSubmitting}
            onClick={handleReset}
          >
            Reset
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

export default TaskDetailAdd;

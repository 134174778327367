import React, { Component } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Button,
    Col,
    Label,
    Input,
    FormFeedback
} from 'reactstrap'
import * as Yup from 'yup';
import PropTypes from 'prop-types'
import { Formik } from 'formik';
import { SelectField } from '../../LandLord/Widgets/SelectField';
import { connect } from 'react-redux';
// import Datetime from 'react-datetime';
// import moment from 'moment';
import { BarLoader } from 'react-spinners';
import {toast} from 'react-toastify'
import axios from 'axios';
import * as API_LINKS from '../../../redux/config/links';
// import { fetchSavedPlacesTable } from '../../../redux/actions/transporter/aTransporter-SavedPlacesTable';
import { fetchTriPLApprovedRequestList } from '../../../redux/actions/transporter/aTransporter-TriPLApprovedRequestList.jsx'

const validationSchema = Yup.object().shape({
    id_wo_request: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.number()
    })
    .nullable()
    .required("Location is required"),
    est_stop_time: Yup.number()
    .min(0, 'Value cannot be lower than 0')
    .required("Est. Stop Time should be stated!"),
})

const propTypes = {
    id_do: PropTypes.any,
    lastSeq: 0,
}

const defaultProps = {
    id_do: 0,
    lastSeq: 0,
}

class WorkOrderAdd3PLWO extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            modalAdd: false,
            initialState: {
                id_wo_request: {},
                est_stop_time: 0,
            }
         };
    }

    UNSAFE_componentWillMount = () => {
        this.props.fetchTriPLApprovedRequestList()
    }

    toggleOpen = () => {
        this.setState({
            modalAdd: !this.state.modalAdd
        })
    }

    toggleClose = () => {
        this.setState({
            modalAdd: !this.state.modalAdd
        })
    }

    viewAdd3PLWO = () => {
        const { selectApprovedList } = this.props;
        // var yesterday = moment().subtract( 1, 'day' );
        // var disabledDays = function( current ){
        //     return current.isAfter( yesterday );
        // };
        return (
            <Formik
                initialValues={this.state.initialState}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    var self = this;
                    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                    var auth = loginCreds.token;
                    setSubmitting(true)

                    axios({
                        method: 'post',
                        url: API_LINKS.WORK_ORDERS_DRAFT_TRIPL_ADD_URL,
                        headers: {
                            Authorization: auth
                        },
                        data: {
                            id_delivery_order: self.props.id_do,
                            wo_seq: self.props.lastSeq,
                            id_wo_request: values.id_wo_request.value,
                            est_stop_time: values.est_stop_time
                        }
                    })
                    .then((response) => {
                        if(response.status === 200){
                            if (response.data.status === "success"){
                                setSubmitting(false)
                                toast.success('3PL Work Order has been saved to draft!')
                                self.toggleClose();
                                resetForm()
                                self.props.updateWO(self.props.id_do);
                            }
                        }
                    })
                    .catch(function(error){
                        if (error.response) {
                            if(error.response.status === 401) {
                                setSubmitting(false)
                                return toast.error("Invalid credentials! Please try again!");
                            } else if (error.response.status === 403) {
                                setSubmitting(false)
                                return toast.error("Unauthorized Access! Please try to logout..");
                            } else if (error.response.status === 400) {
                                setSubmitting(false)
                                return toast.error(error.response.data.message);
                            } else if (error.response.status === 404 || error.response.status === 500) {
                                setSubmitting(false)
                                return toast.error("Server cannot be contacted! Please ask your system administrator!");
                            } else {
                                setSubmitting(false)
                                return toast.error('Something went wrong... Please try again later...')
                            }
                        } else if (error.request) {
                            setSubmitting(false)
                            return toast.error('Request error! Please try again later...')
                        } else {
                            setSubmitting(false)
                            return toast.error('Something went wrong... Please try again later...')
                        }
                    })
                }}
                validationSchema={validationSchema}
                render={({
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                handleSubmit,
                handleBlur,
                handleChange,
                // handleReset,
                // setTouched,
                isValid,
                isSubmitting
                }) => (
                <form>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">3PL Work Order</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <SelectField
                                id="id_wo_request"
                                name="id_wo_request"
                                label="id_wo_request"
                                placeholder="Select 3PL Work Order"
                                searchable
                                options={selectApprovedList}
                                value={values.id_wo_request}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.id_wo_request}
                                error={errors.id_wo_request}
                                isClearable={true}
                                backspaceRemovesValue={true}
                                disabled={isSubmitting}
                            />
                        </Col>
                        {/* <FormFeedback>{errors.location}</FormFeedback> */}
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Est Stop Time (in Min)</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="est_stop_time"
                                    id="est_stop_time"
                                    placeholder="Cargo Weight"
                                    valid={!errors.est_stop_time}
                                    invalid={touched.est_stop_time && !!errors.est_stop_time}
                                    autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.est_stop_time} />
                            <FormFeedback>{errors.est_stop_time}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col xs="12" md="12">
                            <Label htmlFor="text-input"><strong>Please make sure that you input the right wo. 3PL Work order will bind to this delivery order. Your action cannot be undone.</strong></Label>
                        </Col>
                    </FormGroup>
                    {
                        isSubmitting ? 
                        <center><BarLoader color={'#123abc'} loading={isSubmitting} /> <br /> Loading.... Please wait...</center> 
                        : 
                        <div>
                            <Button onClick={handleSubmit} size="sm" color="success" disabled={isSubmitting || !isValid}><i className="fa fa-check"></i>{isSubmitting ? 'Wait...' : 'Submit'}</Button>
                            <Button onClick={this.toggleClose} size="sm" color="warning" disabled={isSubmitting || !isValid}><i className="fa fa-window-close"></i> Close</Button>
                        </div>
                    }
                </form>
                )}
            />
        )
    }

    render() {
        return (
            <div>
                {/* <FormGroup> */}
                    {/* <Col md="12"> */}
                        <Button className="btn mr-2" onClick={() => this.toggleOpen()} color="warning">
                            <div className="mr-1 ml-1 mb-1 mt-1">
                                <i className="fa fa-send"></i>&nbsp;Add 3PL WO
                            </div>
                        </Button>
                    {/* </Col> */}
                {/* </FormGroup> */}
                
                <Modal isOpen={this.state.modalAdd} toggle={this.toggleOpen} className={this.props.className}>
                    <ModalHeader toggle={this.toggleClose}>Add WO 3PL</ModalHeader>
                    <ModalBody>
                            {this.viewAdd3PLWO()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

WorkOrderAdd3PLWO.propTypes = propTypes;
WorkOrderAdd3PLWO.defaultProps = defaultProps;

const mapStateToProps = (state) => {
    return {
        selectApprovedList: state.triPLApprovedRequestListStore.selectApprovedList,
        inProgressTriPLApproved: state.triPLApprovedRequestListStore.inProgress,
        successTriPLApproved: state.triPLApprovedRequestListStore.success,
      }
}

export default connect(mapStateToProps, {fetchTriPLApprovedRequestList})(WorkOrderAdd3PLWO);
import React, { Component } from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ButtonGroup,
    Button
} from 'reactstrap'
import { HashLoader } from 'react-spinners'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import AddEditOperationalHourForm from './Forms/AddEditOperationalHourForm';
import DeleteOperationalHourForm from './Forms/DeleteOperationalHourForm';

class OperationalHourDatatable extends Component{

    constructor(props) {
        super(props);
        this.state = { 
            initialLoad: {},
            operationalHour: [],
            initialValues: this.defaultInitialValues(),

            modalAdd: false,
            modalEdit: false,
            modalDelete: false
        }
    }

    componentDidUpdate(prevProps){
        const {operationalHour, initialLoad} = this.props
        if(prevProps.operationalHour !== operationalHour){
            this.setState({
                operationalHour: operationalHour
            })
        }
        if(prevProps.initialLoad !== initialLoad){
            this.setState({
                initialLoad: initialLoad
            })
        }
    }

    defaultInitialValues(){
        const {id_workshop} = this.props
        return {
            id_workshop: id_workshop,
            day:'',
            start_time: '',
            end_time:'',
            break_time: '',
            desc: '',
        }
    }

    toggleOpenAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd,
            initialValues: this.defaultInitialValues()
        })
    }

    toggleCloseAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd,
            initialValues: this.defaultInitialValues()
        })
    }

    toggleOpenEdit = (_row) => {
        this.setState({
            modalEdit: !this.state.modalEdit,
            initialValues: {
                id: _row.id,
                day:_row.day,
                start_time: _row.start_time,
                end_time:_row.end_time,
                break_time:_row.break_time,
                desc: _row.desc,
            }
        })
    }

    toggleCloseEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit,
            initialValues: this.defaultInitialValues()
        })
    }


    toggleOpenDelete = (_row) => {
        this.setState({
            modalDelete: !this.state.modalDelete,
            initialValues: {
                id: _row.id,
                day: _row.day,
            },
        })
    }

    toggleCloseDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete,
            initialValues: this.defaultInitialValues()
        })
    }

    fetchRefresh = () => {
        this.props.refreshDataTable()
    }

    customBtnGroup = props => {
        return(
            <div>
                <Button className="mr-2" onClick={() => this.toggleOpenAdd()} color="primary">
                    <i className="fa fa-plus"></i>&nbsp;Add New Operational Hour
                </Button> 
                { props.exportCSVBtn }
            </div>
        );
    }
    
    actionFormatter = (cell, row) => {
        return (
            <React.Fragment>
                <ButtonGroup>
                    <Button className="btn btn-warning" color="warning" size="sm" onClick={() => this.toggleOpenEdit(row)}><i className="fa fa-edit"></i> Edit</Button>
                    <Button className="btn btn-danger" color="danger" size="sm" onClick={() => this.toggleOpenDelete(row)}><i className="fa fa-trash"></i> Delete</Button>
                </ButtonGroup> 
            </React.Fragment>   
        )
    }

    showDatatable = () => {
        const {operationalHour} = this.state
        var options = {
            noDataText: 'No data registered. Try one-lah!',
            sizePerPageList: [{
                text: '10', value: 10
            },{
                text: '25', value: 25
            },{
                text: '50', value: 50
            }],
            sizePerPage: 20,
            btnGroup: this.customBtnGroup,
        };
        return (
            <BootstrapTable data={operationalHour} version="4" bordered={false} hover pagination search edit options={options} exportCSV={true} csvFileName={this.fileNameFormat} multiColumnSearch>
                <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" searchable={false} hidden>ID</TableHeaderColumn>
                <TableHeaderColumn dataField="day" searchable dataSort csvHeader="Day" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="40%">Day</TableHeaderColumn>
                <TableHeaderColumn dataField="start_time" searchable dataSort csvHeader="Start Time" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Start Time</TableHeaderColumn>
                <TableHeaderColumn dataField="break_time" searchable dataSort csvHeader="Break Time" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Break Time</TableHeaderColumn>
                <TableHeaderColumn dataField="end_time" searchable dataSort csvHeader="End Time" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">End Time</TableHeaderColumn>
                <TableHeaderColumn dataField="desc" searchable dataSort csvHeader="Desc" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Description</TableHeaderColumn>
                <TableHeaderColumn dataField="action" export={false} dataFormat={ this.actionFormatter.bind(this) } thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="30%">Action</TableHeaderColumn>
            </BootstrapTable>
        )
    }

    fetchRefresh = () => {
        this.props.fetchRefresh()
    }

    render(){
        const {initialValues, initialLoad} = this.state
        const {modalAdd, modalEdit, modalDelete} = this.state
        return(
            <>
                {initialLoad.isComplete? 
                    this.showDatatable():
                    <center>
                     <HashLoader color={'#123abc'} loading={!initialLoad.isComplete}/>
                         <br /> Loading.... Please wait...
                    </center>
                }
                <Modal isOpen={modalAdd} toggle={this.toggleOpenAdd} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={this.toggleCloseAdd}>Add New Operational Hour</ModalHeader>
                    <ModalBody>
                        <AddEditOperationalHourForm
                                closeForm={this.toggleCloseAdd} initialValues={initialValues} 
                                isAdd={true} fetchRefresh={this.fetchRefresh}/>
                    </ModalBody>
                </Modal>
                <Modal isOpen={modalEdit} toggle={this.toggleOpenEdit} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={this.toggleCloseEdit}>Edit Operational Hour ({initialValues.day})</ModalHeader>
                    <ModalBody>
                        <AddEditOperationalHourForm 
                                     closeForm={this.toggleCloseEdit} initialValues={initialValues} 
                                     isEdit={true} fetchRefresh={this.fetchRefresh}/>
                    </ModalBody>
                </Modal>
                <Modal isOpen={modalDelete} toggle={this.toggleOpenDelete} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseDelete}>Delete Operational Hour ({initialValues.day})</ModalHeader>
                    <ModalBody>
                        <DeleteOperationalHourForm  
                            initialValues={initialValues} closeForm={this.toggleCloseDelete}
                            fetchRefresh={this.fetchRefresh}/>
                    </ModalBody>
                </Modal>
            </>
        )
    }

} export default OperationalHourDatatable

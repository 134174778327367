import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import LoadingContent from "../../../../components/LoadingContent/LoadingContent";
import Select from "react-select";
import * as yup from "yup";
import useLazyFetch from "../../../../hooks/useLazyFetch";
import {
  USER_ACCOUNT_BANK_LIST,
  USER_PAYMENT_INTERNAL_WALLET_FEE,
  USER_WITHDRAWAL_EDIT,
  USER_WITHDRAWAL_INPUT,
} from "../../../../redux/config/links";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { fetchSaldo } from "../../../../redux/actions/wallet/aWallet-Saldo";
import { BiSolidError } from "react-icons/bi";
import CustomToast from "../../../../components/CustomToast/CustomToast";
import { FaCheckCircle } from "react-icons/fa";

const RequestWithdraw = ({ onClose, onReload, initialValues = null }) => {
  const dispatch = useDispatch();
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const { walletSaldo } = useSelector((state) => ({
    walletSaldo: state.walletSaldo,
  }));
  const [isGettingUserAccount, setIsGettingUserAccount] = useState(true);
  const [isGettingWithdrawalFee, setIsGettingWithdrawalFee] = useState(true);

  const [
    getUserAccountBankList,
    { loading: loadingGetUserAccountBankList, data: dataBankAccount },
  ] = useLazyFetch({
    url: USER_ACCOUNT_BANK_LIST,
    method: "POST",
  });

  const [requestUserWithdrawal] = useLazyFetch({
    url: USER_WITHDRAWAL_INPUT,
    method: "POST",
    type: "json",
  });

  const [editUserWithdrawal] = useLazyFetch({
    url: USER_WITHDRAWAL_EDIT,
    method: "POST",
    type: "json",
  });

  const [onWithdrawalFee, { data: dataFee, loading: loadingFee }] =
    useLazyFetch({
      url: USER_PAYMENT_INTERNAL_WALLET_FEE,
      method: "POST",
      type: "json",
    });

  useEffect(() => {
    if (isGettingUserAccount) {
      setIsGettingUserAccount(false);
      getUserAccountBankList();
    }
  }, [isGettingUserAccount, getUserAccountBankList]);

  useEffect(() => {
    if (isGettingWithdrawalFee) {
      setIsGettingWithdrawalFee(false);
      onWithdrawalFee();
    }
  }, [isGettingWithdrawalFee, onWithdrawalFee]);

  const validation = () => {
    return yup.object().shape({
      bank_account: yup
        .object()
        .shape({
          label: yup.string(),
          value: yup.number(),
        })
        .required("Bank account is required"),
      amount: yup.number().required("Amount is required"),
    });
  };
  const onSubmit = (values, { setSubmitting }) => {
    let dataValues = { ...values };
    dataValues.id_bank_account = values.bank_account.value;
    delete dataValues.bank_account;

    if (initialValues === null) {
      const remain = walletSaldo.currentSaldo - dataValues.amount;
      // setIsOpenWarning(true);

      if (
        remain < Number(dataFee.result.filter((item) => item.id === 3)[0].value)
      ) {
        setIsOpenWarning(true);
        setSubmitting(false);
      } else {
        requestUserWithdrawal({ body: dataValues }, (success) => {
          setSubmitting(false);
          if (success) {
            toast.success(
              <CustomToast
                text="Successfully request withdraw!"
                icon={<FaCheckCircle />}
              />,
              {
                autoClose: 3000,
              }
            );
            onReload();
            onClose();
            dispatch(fetchSaldo());
          } else {
            toast.error(
              <CustomToast
                text="Request withdrawal failed!"
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          }
        });
      }
    } else {
      editUserWithdrawal(
        { body: { ...dataValues, id: initialValues.id } },
        (success) => {
          setSubmitting(false);
          if (success) {
            toast.success(
              <CustomToast
                text="Successfully edit withdraw!"
                icon={<FaCheckCircle />}
              />,
              {
                autoClose: 3000,
              }
            );
            onReload();
            onClose();
          } else {
            toast.error(
              <CustomToast
                text="Edit withdrawal failed!"
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          }
        }
      );
    }
  };

  return (
    <>
      <Modal
        size="md"
        isOpen={!!isOpenWarning}
        toggle={() => setIsOpenWarning(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setIsOpenWarning(false)}>
          Warning!
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <LoadingContent
              loading={loadingFee}
              onReload={() => setIsGettingWithdrawalFee(true)}
            >
              <>
                {!!dataFee ? (
                  <FormGroup row>
                    <Col xs="12" md="12">
                      <Label htmlFor="text-input">
                        The remain amount of your wallet for your widthdrawal
                        request is below Rp{" "}
                        {Number(
                          dataFee.result.filter((item) => item.id === 3)[0]
                            .value
                        ).toLocaleString("id-ID")}
                      </Label>
                    </Col>
                  </FormGroup>
                ) : null}
              </>
            </LoadingContent>

            <FormGroup>
              <div>
                <Button
                  onClick={() => setIsOpenWarning(false)}
                  size="sm"
                  color="primary"
                >
                  OK
                </Button>
              </div>
            </FormGroup>
          </div>
        </ModalBody>
      </Modal>
      <Formik
        initialValues={{
          bank_account:
            initialValues !== null
              ? {
                  value: initialValues.id_bank_account,
                  label: `${initialValues.bank_name} (${initialValues.account_holding_name} - ${initialValues.account_number})`,
                }
              : null,
          amount:
            initialValues !== null
              ? Number(initialValues.amount).toString()
              : "",
        }}
        onSubmit={onSubmit}
        validationSchema={validation()}
        render={({
          values,
          errors,
          handleSubmit,
          handleReset,
          isValid,
          isSubmitting,
          handleBlur,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="bank_account">Bank Account</Label>
              </Col>
              <Col xs="12" md="9">
                <LoadingContent
                  loading={loadingGetUserAccountBankList}
                  onReload={() => setIsGettingUserAccount(true)}
                >
                  <Select
                    value={values.bank_account}
                    onChange={(e) => setFieldValue("bank_account", e)}
                    options={
                      dataBankAccount
                        ? dataBankAccount.result.map((item) => ({
                            value: item.id,
                            label: `${item.bank_name} (${item.account_holding_name} - ${item.account_number})`,
                          }))
                        : []
                    }
                    disabled={isSubmitting}
                    placeholder={"Bank Account"}
                  />
                </LoadingContent>
                <FormFeedback>{errors.bank_account}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="amount">Amount</Label>
              </Col>
              <Col xs="12" md="9">
                <NumberFormat
                  onBlur={handleBlur}
                  name="amount"
                  id="amount"
                  value={values.amount}
                  thousandSeparator={true}
                  disabled={isSubmitting}
                  prefix={"Rp "}
                  onValueChange={(values) => {
                    const { value } = values;
                    return setFieldValue("amount", value);
                  }}
                />
                <FormFeedback>{errors.amount}</FormFeedback>
              </Col>
            </FormGroup>

            <LoadingContent
              loading={loadingFee}
              onReload={() => setIsGettingWithdrawalFee(true)}
            >
              <>
                {!!dataFee ? (
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="amount">Withdraw Fee</Label>
                    </Col>
                    <Col xs="12" md="9">
                      Rp{" "}
                      {Number(
                        dataFee.result.filter((item) => item.id === 2)[0].value
                      ).toLocaleString("id-ID")}
                    </Col>
                  </FormGroup>
                ) : null}
              </>
            </LoadingContent>
            <FormGroup>
              <Button
                type="submit"
                color="primary"
                className="mr-1"
                disabled={isSubmitting || !isValid || loadingFee}
              >
                {isSubmitting
                  ? "Wait..."
                  : initialValues === null
                  ? "Submit"
                  : "Edit"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={isSubmitting}
                onClick={() => onClose()}
              >
                Close
              </Button>
              <Button
                type="reset"
                color="danger"
                className="mr-1"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Reset
              </Button>
            </FormGroup>
          </form>
        )}
      />
    </>
  );
};

export default RequestWithdraw;

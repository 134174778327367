import React from "react";
import { Link } from "react-router-dom";
import Trip from "./Trip/Trip";

export const fleetRoutes = () => {
  const path = "/storing/fleet";

  return [
    {
      name: "Fleet",
      path: `${path}`,
      exact: true,
      render: () => (
        <>
          <ul>
            <li>
              <Link to={`${path}/trip`}>Trip</Link>
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Trip",
      path: `${path}/trip`,
      exact: true,
      render: (props) => (
        <Trip {...props} />
      ),
    },
  ];
};

import React, { Component } from "react";
import { Button, FormGroup, Label, Col } from "reactstrap";
import axios from "axios";
import { Formik } from "formik";
import { toast } from "react-toastify";
import handleErrorResponseCode from "../../../../../../../helpers/error-response-code-handler";
import { WORKSHOP_OPERATIONAL_HOUR_DELETE_URL } from "../../../../../../../redux/config/links";
import { FaCheckCircle } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import CustomToast from "../../../../../../../components/CustomToast/CustomToast";

class DeleteMaintenanceCategoriesForm extends Component {
  form = () => {
    const { initialValues } = this.props;
    const { fetchRefresh, closeForm } = this.props;
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
          let auth = loginCreds.token;
          axios({
            method: "post",
            url: WORKSHOP_OPERATIONAL_HOUR_DELETE_URL,
            headers: {
              Authorization: auth,
            },
            data: values,
          })
            .then((response) => {
              if (response.data.statusCode === 200) {
                setSubmitting(false);
                toast.success(
                  <CustomToast
                    text="Operational hour has been deleted succesfully!"
                    icon={<FaCheckCircle />}
                  />,
                  {
                    autoClose: 3000,
                  }
                );
                closeForm();
                fetchRefresh();
              }
            })
            .catch(function (error) {
              setSubmitting(false);
              toast.error(
                <CustomToast
                  text={handleErrorResponseCode(error)}
                  icon={<BiSolidError />}
                />,
                {
                  autoClose: 3000,
                }
              );
            });
        }}
        render={({
          handleSubmit,
          isSubmitting,
        }) => (
          <form>
            <FormGroup row>
              <Col md="12">
                <Label htmlFor="text-input">
                  Are you sure to delete this oeprational hour?
                </Label>
              </Col>
            </FormGroup>
            <FormGroup>
              <Button
                type="button"
                onClick={handleSubmit}
                color="danger"
                className="mr-1"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Deleting..." : "Delete"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={isSubmitting}
                onClick={closeForm}
              >
                Close
              </Button>
            </FormGroup>
          </form>
        )}
      />
    );
  };

  render() {
    return <React.Fragment>{this.form()}</React.Fragment>;
  }
}
export default DeleteMaintenanceCategoriesForm;

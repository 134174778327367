import React, { Component } from 'react';
import {connect} from 'react-redux';
import {fetchCompanyList} from '../../../redux/actions/aUser-CompanyList'
import {fetchSettingList} from '../../../redux/actions/aUser-SettingList'

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow, 
    KmlLayer,
    Polyline
} from "react-google-maps"
import { compose } from "recompose"
// import customMarker from '../../../assets/img/greendots.png'
// import customMarkerYellow from '../../../assets/img/yellowdot.png'
// import customMarkerRed from '../../../assets/img/red.png'
import customMarkerPin from '../../../assets/img/pin.png'
import decodePolyline from 'decode-google-map-polyline'

function generateRandom() {
    return Math.random() * 10000000000000000
}

const MapWithAMarker = compose(
    withScriptjs, 
    withGoogleMap,
    )(props => {
        const dcPoly = props.mapOptimized ? decodePolyline(props.mapOptimized) : []
    return (
        <GoogleMap defaultZoom={14} defaultCenter={{ lat:  ( !props.companyLat ? (props.woMarker.length > 0 ? props.woMarker[0].lat : -7.6221790314):  props.companyLat), lng: ( !props.companyLng ? (props.woMarker.length > 0 ? props.woMarker[0].lng : 110.8043441772):  props.companyLng) }}>
            <KmlLayer url={props.kmlLink + "&ver=" + generateRandom()} options={{ preserveViewport: true }}/>
            <Polyline
                path={dcPoly}
                geodesic={true}
                options={{
                    strokeColor: "#3bba3c",
                    strokeOpacity: 0.90,
                    strokeWeight: 5,
                    icons: [
                        {
                            // icon: lineSymbol,
                            offset: "0",
                            repeat: "20px"
                        }
                    ]
                }}
            />
            {/* <MapDirectionsRenderer
                waypoints={props.waypoints}
                avoidToll={props.doDetails[0].avoid_toll > 0 ? true : false}
                travelMode={window.google.maps.TravelMode.DRIVING}
            /> */}
        
        {/* Fleet Markers */}
        {/* {props.markers.map((marker, i) => {
          const onClick = props.onClick.bind(this, marker)
            return (
                <Marker
                  key={i}
                  onClick={onClick}
                    options={marker.wrn > 0 ? (marker.spd > 5 ? {icon: customMarker} : {icon: customMarkerYellow}) : {icon: customMarkerRed}}
                  position={{ lat: marker.lat, lng: marker.lng }}
                >
                    {props.selectedMarker === marker &&
                  <InfoWindow>
                    <div>
                    {props.doDetails[0].fleet_plate}<br/>{marker.obd_id}<br/>{marker.time}<br/><span>Latitude: {marker.lat}</span>
                    <br/><span>Longitude: {marker.lng}</span>
                    <br/><span>Speed: {marker.spd} KM/h</span>
                    <br/><span>Engine Status: {marker.wrn > 0 ? 'Engine On' : 'Engine Off'}</span>
                    </div>
                  </InfoWindow>
                }
                </Marker>
            )
        })} */}
        {/* WO Markers */}
        {
            props.woMarker.length > 0 || props.woMarker !== undefined ? 
            (
                props.woMarker.map(marker => { 
                    const onClick = props.onClick.bind(this, marker)
                        return (
                            <Marker
                            key={marker.id}
                            onClick={onClick}
                            options={{icon: customMarkerPin}}
                            position={{ lat: marker.lat, lng: marker.lng }}
                            >
                                {/* {props.selectedMarker === marker && */}
                            <InfoWindow>
                                <div>
                                    {marker.wo_seq === 0 ? 'Departure' : (marker.wo_seq === 9989 ? 'Home' : 'Waypoint')} - {marker.wo_destination_name ? marker.wo_destination_name : ''}
                                </div>
                            </InfoWindow>
                            {/* } */}
                            </Marker>
                        )
                    }
                )
            )
            :
            true
        }
      </GoogleMap>
    )
})

class WorkOrderMaps extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }

    handleClick = (marker) => {
        // console.log({ marker })
        this.setState({ selectedMarker: marker })
    }

    render() {
        return (
            <div>
                <MapWithAMarker
                    // selectedMarker={this.state.selectedMarker}
                    // markers={this.props.fleetPath}
                    onClick={this.handleClick}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `700px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    kmlLink={this.props.statKML ? this.props.kml : null}
                    woMarker={this.props.woMarker}
                    mapOptimized={this.props.overviewPoly}
                    // doDetails={this.props.doDetailsData}
                    companyLat={this.props.companyLat}
                    companyLng={this.props.companyLng}
                    // waypoints={waypoints}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    // KML and Company State
    kml: state.companyStore.companyKML,
    companyLat: state.companyStore.lat,
    companyLng: state.companyStore.lng,
    statKML: state.settingStore.kmlSet,

    woMarker: state.workOrdersTableStore.woMarker,
    overviewPoly: state.workOrdersTableStore.overviewPoly,

    // Work Order Related
    // inProgressWorkOrder: state.workOrderStore.inProgress,
    // successWorkOrder: state.workOrderStore.success,
    // workOrderData: state.workOrderStore.workOrderData,

})

export default connect(mapStateToProps, {fetchCompanyList, fetchSettingList})(WorkOrderMaps);
import React, { useRef } from "react";
import styles from "./InputImage.module.scss";

const InputImage = ({
  onChange,
  onRemove,
  value,
  multiple = false,
  invalid = false,
  acceptPdf = false,
}) => {
  const inputRef = useRef();

  const handleInput = (e) => {
    if (e.currentTarget.files.length > 0) {
      if (value) {
        onChange(
          multiple
            ? [...value, ...e.currentTarget.files]
            : e.currentTarget.files[0]
        );
      } else {
        onChange(
          multiple ? [...e.currentTarget.files] : e.currentTarget.files[0]
        );
      }
    }
  };

  const renderPreview = (item) => {
    if (!item) return null; // Handle empty or undefined item gracefully

    // Handle string URLs
    if (typeof item === "string") {
      if (item.endsWith(".pdf")) {
        return (
          <embed
            className={styles["img"]}
            src={item}
            type="application/pdf"
            width="100%"
            height="400px"
          />
        );
      }
      return (
        <img
          className={styles["img"]}
          src={item}
          alt="thumbnail"
          loading="lazy"
        />
      );
    }

    // Handle Blob or File objects
    let fileUrl;
    if (item instanceof Blob || item instanceof File) {
      fileUrl = URL.createObjectURL(item);
      if (item.type === "application/pdf") {
        return (
          <embed
            className={styles["img"]}
            src={fileUrl}
            type="application/pdf"
            width="100%"
            height="400px"
          />
        );
      }
      return (
        <img
          className={styles["img"]}
          src={fileUrl}
          alt="thumbnail"
          loading="lazy"
        />
      );
    }

    // Handle Buffer objects or other cases
    if (
      item instanceof Object &&
      item.type === "Buffer" &&
      Array.isArray(item.data)
    ) {
      const urlString = String.fromCharCode.apply(null, item.data);
      if (urlString.endsWith(".pdf")) {
        return (
          <embed
            className={styles["img"]}
            src={urlString}
            type="application/pdf"
            width="100%"
            height="400px"
          />
        );
      }
      // Render as an img tag
      return (
        <img
          className={styles["img"]}
          src={urlString}
          alt="thumbnail"
          loading="lazy"
        />
      );
    }
  };

  return (
    <div className={`${invalid ? "is-invalid" : ""} ${styles["container"]}`}>
      <div className={styles["images"]}>
        {multiple ? (
          (!!value ? value : []).map((item, index) => (
            <div className={styles["img-wrapper"]} key={index}>
              {renderPreview(item)}
              <div
                className={styles["img-remover"]}
                onClick={() => onRemove(item, index)}
              >
                x
              </div>
            </div>
          ))
        ) : (
          <>
            {!!value ? (
              <div className={styles["img-wrapper"]}>
                {renderPreview(value)}
                <div
                  className={styles["img-remover"]}
                  onClick={() => onRemove(value)}
                >
                  x
                </div>
              </div>
            ) : null}
          </>
        )}
        <input
          ref={inputRef}
          type="file"
          onChange={handleInput}
          accept={
            acceptPdf
              ? "image/png, image/gif, image/jpeg, application/pdf"
              : "image/png, image/gif, image/jpeg"
          }
          style={{ display: "none" }}
          multiple={multiple}
        />
        {multiple || (!multiple && !value) ? (
          <div
            className={styles["add-button"]}
            onClick={() => inputRef.current.click()}
          >
            +
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InputImage;

import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { connect } from "react-redux";
import { BarLoader } from 'react-spinners';
import { fetchCarDetailsTable } from '../../../redux/actions/transporter/aTransporter-CarDetailsTable.jsx';

class CarDetailsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  UNSAFE_componentWillMount() {
    this.props.fetchCarDetailsTable();

  }

  showDataTable() {
    return (
      <BootstrapTable data={this.props.carDetailsTableData.result} version="4" bordered={false} hover pagination search edit options={this.options} exportCSV={true} csvFileName={this.fileNameFormat}>
        <TableHeaderColumn dataField="id" isKey dataSort csvHeader="id" hidden>id</TableHeaderColumn>
        <TableHeaderColumn dataField="dev" dataSort csvHeader="id" hidden>Device</TableHeaderColumn>
        <TableHeaderColumn dataField="acc" dataSort csvHeader="Activity" thStyle={ { whiteSpace: 'normal' } } width="10%">Acc</TableHeaderColumn>
        <TableHeaderColumn dataField="spd" dataSort csvHeader="Speed" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Speed</TableHeaderColumn>
        <TableHeaderColumn dataField="temp" dataSort csvHeader="Temperature" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Temperature</TableHeaderColumn>
        <TableHeaderColumn dataField="vraw" dataSort csvHeader="VRaw" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">VRaw</TableHeaderColumn>
        <TableHeaderColumn dataField="vsys" dataSort csvHeader="VSys" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Vsys</TableHeaderColumn>
        <TableHeaderColumn dataField="lat" dataSort csvHeader="Latitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Lat</TableHeaderColumn>
        <TableHeaderColumn dataField="lon" dataSort csvHeader="Longitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Long</TableHeaderColumn>
        <TableHeaderColumn dataField="time" dataSort csvHeader="Time" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Time</TableHeaderColumn>
      </BootstrapTable>);
  }


  render() {
      return(
        <div>
            {this.props.inProgress ? <center><BarLoader color={'#123abc'} 
            loading={this.props.inProgress} 
            /> <br /> Loading.... Please wait...</center> : this.showDataTable()}
        </div>
      )
      
    
        
  }
}

function mapStateToProps(state) {
  return {
    carDetailsTableData: state.carDetailsTableStore.carDetailsTableData,
    inProgress: state.carDetailsTableStore.inProgress
  }
}

export default connect(mapStateToProps, { fetchCarDetailsTable })(CarDetailsTable);

import React, { Component } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import LiveMonitoring from "./LiveMonitoring";
import TriPLLiveMonitoring from "./TriPLLiveMap/TriPLLiveMonitoring";
import RenteeLiveMap from "./RenteeLiveMap/LiveMonitoring";
import ToggleSwitchCommandCenter from "../../../components/ToggleSwitchCommandCenter/ToggleSwitchCommandCenter";
import { activeFeature } from "../../../constants/constants";
import styles from "./LiveMonitoringTabs.module.scss";

const TRIPL_OWN_FLEET_TAB = 1;
const RENTEE_LIVE_MAP_TAB = 2;
const TRIPL_3PL_FLEET_TAB = 3;
class LiveMonitoringTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: TRIPL_OWN_FLEET_TAB,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div>
        <div className={styles["floating-button"]}>
          {activeFeature.LIVE_MONITORING_V2 && <ToggleSwitchCommandCenter />}
        </div>
        <div className="fw-bold fs-3 mb-3">Live Monitoring</div>
        <Row>
          <Col xs="12" md="12" className="mb-4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TRIPL_OWN_FLEET_TAB,
                  })}
                  onClick={() => {
                    this.toggle(TRIPL_OWN_FLEET_TAB);
                  }}
                >
                  <i className="fa fa-truck"></i>
                  <span
                    className={
                      this.state.activeTab === TRIPL_OWN_FLEET_TAB
                        ? ""
                        : "d-none"
                    }
                  >
                    {" "}
                    Own Fleet
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === RENTEE_LIVE_MAP_TAB,
                  })}
                  onClick={() => {
                    this.toggle(RENTEE_LIVE_MAP_TAB);
                  }}
                >
                  <i className="fa fa-truck"></i>
                  <span
                    className={
                      this.state.activeTab === RENTEE_LIVE_MAP_TAB
                        ? ""
                        : "d-none"
                    }
                  >
                    {" "}
                    Rental Fleets
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === TRIPL_3PL_FLEET_TAB,
                  })}
                  onClick={() => {
                    this.toggle(TRIPL_3PL_FLEET_TAB);
                  }}
                >
                  <i className="fa fa-arrows"></i>
                  <span
                    className={
                      this.state.activeTab === TRIPL_3PL_FLEET_TAB
                        ? ""
                        : "d-none"
                    }
                  >
                    {" "}
                    3PL Fleet
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={TRIPL_OWN_FLEET_TAB}>
                <LiveMonitoring history={this.props.history} />
              </TabPane>
              <TabPane tabId={RENTEE_LIVE_MAP_TAB}>
                <RenteeLiveMap />
              </TabPane>
              <TabPane tabId={TRIPL_3PL_FLEET_TAB}>
                <TriPLLiveMonitoring />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LiveMonitoringTabs;

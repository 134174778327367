import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ButtonGroup,
  Button,
} from "reactstrap";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import classnames from "classnames";
import styles from "./FleetDetails.module.scss";
import { activeFeature } from "../../../constants/constants";
import { onFetch } from "../../../helpers/helper";
import { FLEET_LIST_IMAGE_URL } from "../../../redux/config/links";

const propTypes = {
  reg_number: PropTypes.string,
  car_brands: PropTypes.string,
  car_type: PropTypes.string,
  fuel_type: PropTypes.string,
  reg_year: PropTypes.number,
  manufacture_year: PropTypes.number,
  cyl_cap: PropTypes.number,
  vehicle_id_number: PropTypes.string,
  engine_number: PropTypes.string,
  max_tonnage: PropTypes.number,
  mt_speed_reduction: PropTypes.number,
  rest_interval: PropTypes.number,
  interval_between_rest: PropTypes.number,
  fuel_consumption: PropTypes.number,
  odometer: PropTypes.number,
  obd_code: PropTypes.string,
  exp_pajak: PropTypes.string,
  exp_kir: PropTypes.string,
  tkm_cost: PropTypes.number,
  empty_weight: PropTypes.number,
};

const defaultProps = {
  reg_number: "",
  car_brands: "",
  car_type: "",
  fuel_type: "",
  reg_year: 0,
  manufacture_year: 0,
  cyl_cap: 0,
  vehicle_id_number: "",
  engine_number: "",
  max_tonnage: 0,
  mt_speed_reduction: 0,
  rest_interval: 0,
  interval_between_rest: 0,
  fuel_consumption: 0,
  odometer: 0,
  obd_code: "",
  exp_pajak: "",
  exp_kir: "",
  tkm_cost: 0,
  empty_weight: 0,
};

const options = {
  noDataText: "No sparepart registered on your dashboard. Try one-lah!",
  defaultSortName: "id", // default sort column name
  defaultSortOrder: "asc", // default sort order
  sizePerPageList: [
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "30",
      value: 30,
    },
  ],
  sizePerPage: 30,
  exportCSVSeparator: ";",
  btnGroup: null,
};

const fileNameFormat = () => {
  var baseName = "Location - ";
  var baseDate = new Date();
  var date = moment(baseDate).format("DD-MMM-YYYY HH:mm");
  var strDate = date.toString();
  return baseName + strDate + ".csv";
};

class FleetDetails extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "fleet-detail",
      loadingRemoteImages: false,
      images: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.getImages(this.props.fleet_id);
  }

  getImages = (id) => {
    onFetch(
      {
        url: FLEET_LIST_IMAGE_URL,
        data: {
          id_fleet: id,
        },
      },
      {
        beforeSend: () => {
          this.setState({
            loadingRemoteImages: true,
          });
        },
        success: (response) => {
          this.setState({
            loadingRemoteImages: false,
            images: response.data.result.map((item) => ({
              image: item.link,
              desc: item.desc,
            })),
          });
        },
        error: () => {
          this.setState({
            loadingRemoteImages: false,
          });
        },
      }
    );
  };

  curStringFormat = (_digit) => {
    return (
      "Rp. " +
      parseFloat(_digit)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };

  render() {
    const {
      reg_number,
      car_brands,
      car_type,
      fuel_type,
      reg_year,
      manufacture_year,
      cyl_cap,
      vehicle_id_number,
      engine_number,
      max_tonnage,
      mt_speed_reduction,
      rest_interval,
      interval_between_rest,
      fuel_consumption,
      odometer,
      obd_code,
      exp_pajak,
      exp_kir,
      tkm_cost,
      empty_weight,
      door_number,
      qr_link_solar
    } = this.props;
    return (
      <React.Fragment>
        <Row>
          <Col xs="12" md="12" className="mb-4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "fleet-detail",
                  })}
                  onClick={() => {
                    this.setState({
                      activeTab: "fleet-detail",
                    });
                  }}
                >
                  <i className={``}></i>&nbsp; Fleet Details for {reg_number}
                </NavLink>
              </NavItem>
              {activeFeature.TIRE_MANAGEMENT && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "showroom",
                    })}
                    onClick={() => {
                      this.setState({
                        activeTab: "showroom",
                      });
                    }}
                  >
                    <i className={``}></i>&nbsp; Showroom
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={"fleet-detail"}>
                <>
                  <Row>
                    <Col md="4">
                      <Row>
                        <Col>
                          <h5>Fleet Basic Information</h5> <br />
                          <dt>Registration Number</dt>
                          <dd>{reg_number ? reg_number : "-"}</dd>
                          <dt>Door Number</dt>
                          <dd>{door_number ? door_number : "-"}</dd>
                          <dt>Car Brands</dt>
                          <dd>{car_brands ? car_brands : "-"}</dd>
                          <dt>Car Type</dt>
                          <dd>{car_type ? car_type : "-"}</dd>
                          <dt>Fuel Type</dt>
                          <dd>{fuel_type ? fuel_type : "-"}</dd>
                          <dt>Odometer</dt>
                          <dd>{odometer ? odometer + " km" : "0 km"}</dd>
                          {/* <dt>Address</dt><dd style={{ textAlign: 'justify' }}>{main_address ? main_address : '-'}</dd>
                                <dt>Latitude</dt><dd>{lat ? lat : '-'}</dd>
                                <dt>Longitude</dt><dd>{lng ? lng : '-'}</dd>
                                <dt>Email</dt><dd>{email ? email : '-'}</dd>
                                <dt>Phone Number</dt><dd>{phone ? phone : '-'}</dd>
                                <dt>Fax Number</dt><dd>{fax ? fax : '-'}</dd>
                                <dt>Whatsapp/Mobile Number</dt><dd>{handphone ? handphone : '-'}</dd> */}
                          {/*<dt>Description</dt><dd style={{ textAlign: 'justify' }}>{deviceDesc}</dd>*/}
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <br />
                      <Row>
                        <Col>
                          <h5>Fleet Adjuster Information</h5> <br />
                          <dt>Max Tonnage</dt>
                          <dd>{max_tonnage ? max_tonnage + " kg" : "-"}</dd>
                          <dt>Manual Speed Reduction Multiplier</dt>
                          <dd>
                            {mt_speed_reduction
                              ? mt_speed_reduction + "x"
                              : "-"}
                          </dd>
                          <dt>Rest Interval</dt>
                          <dd>
                            {rest_interval ? rest_interval + " min" : "-"}
                          </dd>
                          <dt>Interval Between Rest</dt>
                          <dd>
                            {interval_between_rest
                              ? interval_between_rest + " min"
                              : "-"}
                          </dd>
                          <dt>Fuel Consumption</dt>
                          <dd>
                            {fuel_consumption
                              ? fuel_consumption + " km/l"
                              : "0 km/l"}
                          </dd>
                          {/* <dt>Address</dt><dd style={{ textAlign: 'justify' }}>{main_address ? main_address : '-'}</dd>
                                <dt>Latitude</dt><dd>{lat ? lat : '-'}</dd>
                                <dt>Longitude</dt><dd>{lng ? lng : '-'}</dd>
                                <dt>Email</dt><dd>{email ? email : '-'}</dd>
                                <dt>Phone Number</dt><dd>{phone ? phone : '-'}</dd>
                                <dt>Fax Number</dt><dd>{fax ? fax : '-'}</dd>
                                <dt>Whatsapp/Mobile Number</dt><dd>{handphone ? handphone : '-'}</dd> */}
                          {/*<dt>Description</dt><dd style={{ textAlign: 'justify' }}>{deviceDesc}</dd>*/}
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4">
                      <Row>
                        <Col>
                          <h5>Vehicle Information</h5> <br />
                          <dt>Registration Year</dt>
                          <dd>{reg_year ? reg_year : "-"}</dd>
                          <dt>Manufacturing Year</dt>
                          <dd>{manufacture_year ? manufacture_year : "-"}</dd>
                          <dt>Vehicle ID Number</dt>
                          <dd>{vehicle_id_number ? vehicle_id_number : "-"}</dd>
                          <dt>Engine Number</dt>
                          <dd>{engine_number ? engine_number : "-"}</dd>
                          <dt>Cylinder Capacity</dt>
                          <dd>{cyl_cap ? cyl_cap + " cc" : "-"}</dd>
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <Row>
                        <Col>
                          <h5>Calculation Information</h5> <br />
                          <dt>Tkm Cost</dt>
                          <dd>
                            {tkm_cost ? this.curStringFormat(tkm_cost) : "-"}
                          </dd>
                          <dt>Empty Weight</dt>
                          <dd>{empty_weight ? empty_weight + " kg" : "-"}</dd>
                          <br />
                          <h5>Other Information</h5> <br />
                          <dt>OBD Code</dt>
                          <dd>{obd_code ? obd_code : "-"}</dd>
                          <dt>Tax Expiry Date</dt>
                          <dd>{exp_pajak ? exp_pajak : "-"}</dd>
                          <dt>Periodical Vehicle Inspection Expiry</dt>
                          <dd>{exp_kir ? exp_kir : "-"}</dd>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="4">
                      {this.state.images.map((item, idx) => (
                        <React.Fragment key={idx}>
                          <Row>
                            <Col>
                              <img
                                src={item.image}
                                alt={item.desc}
                                style={{
                                  height: 140,
                                  width: 240,
                                  objectFit: "cover",
                                }}
                              />
                              <p>{item.desc}</p>
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <br />
                        </React.Fragment>
                      ))}
                      {qr_link_solar?<>
                        <Row>
                          <Col><strong>QR Solar</strong></Col>
                        </Row>
                        <Row>
                          <Col>
                            <img
                              src={qr_link_solar}
                              alt={""}
                              style={{
                                height: 140,
                                width: 240,
                                objectFit: "cover",
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                        <br />
                        <br />
                    </>:null}
                    </Col>
                  </Row>
                  {/* {this.state.physicalSpecifications ? <div><dt>Dimension (Length x Width x Height)</dt><dd>{length} mm x {width} mm x {height} mm</dd>
						<dt>Weight</dt><dd>{weight} gram</dd></div> : <div></div>} */}
                </>
              </TabPane>
              {activeFeature.TIRE_MANAGEMENT && (
                <TabPane tabId={"showroom"}>
                  <Row>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <dt>Truck's Photo</dt>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <img
                            className={styles["truck-left"]}
                            src={"/assets/img/trucks/left-side.jpg"}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <img
                            className={styles["truck-right"]}
                            src={"/assets/img/trucks/right-side.jpg"}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row className={styles["gap-image-y"]}>
                        <Col md={6}>
                          <img
                            className={styles["truck-front"]}
                            src={"/assets/img/trucks/front-side.jpg"}
                            alt=""
                          />
                        </Col>
                        <Col md={6}>
                          <img
                            className={styles["truck-back"]}
                            src={"/assets/img/trucks/back-side.jpg"}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <dt>Admin and Tax</dt>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <img
                            className={styles["truck-back"]}
                            src={"/assets/img/trucks/admin+tax.jpg"}
                            alt=""
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={9}>
                      <Row>
                        <Col md={12}>
                          <dt>Sparepart & accessories</dt>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <img
                            className={styles["truck-bottom"]}
                            src={"/assets/img/trucks/bottom-side.png"}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <BootstrapTable
                            data={[
                              {
                                id: 1,
                                sparepart: "a",
                              },
                              {
                                id: 2,
                                sparepart: "b",
                              },
                              {
                                id: 3,
                                sparepart: "c",
                              },
                            ]}
                            version="4"
                            bordered={false}
                            hover
                            pagination
                            search
                            edit
                            options={options}
                            exportCSV={true}
                            csvFileName={fileNameFormat}
                          >
                            <TableHeaderColumn
                              dataField="id"
                              isKey
                              dataSort
                              csvHeader="ID"
                              hidden
                            >
                              ID
                            </TableHeaderColumn>

                            <TableHeaderColumn
                              dataField="sparepart"
                              csvHeader="Company"
                              thStyle={{ whiteSpace: "normal" }}
                              tdStyle={{ whiteSpace: "normal" }}
                            >
                              Sparepart's name
                            </TableHeaderColumn>

                            <TableHeaderColumn
                              dataField="action"
                              export={false}
                              width="15%"
                              dataFormat={() => (
                                <>
                                  <ButtonGroup>
                                    <Button
                                      className="btn btn-warning"
                                      size="sm"
                                      onClick={() => null}
                                    >
                                      <i className="fa fa-edit"></i> Edit
                                    </Button>
                                    <Button
                                      className="btn btn-danger"
                                      color="danger"
                                      size="sm"
                                      onClick={() => null}
                                    >
                                      <i className="fa fa-trash"></i> Delete
                                    </Button>
                                  </ButtonGroup>
                                </>
                              )}
                              thStyle={{ whiteSpace: "normal" }}
                              tdStyle={{ whiteSpace: "normal" }}
                            >
                              Action
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
              )}
            </TabContent>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

FleetDetails.propTypes = propTypes;
FleetDetails.defaultProps = defaultProps;

export default FleetDetails;

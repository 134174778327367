import React, { Component } from 'react';
import { connect } from 'react-redux'
import DriverSummaryFilter from './DriverSummaryFilter';
import { fetchDriversSummary } from '../../../../redux/actions/transporter/aTransporter-DriversTable';
import { HashLoader } from 'react-spinners';
import Papa from 'papaparse';
import moment from 'moment';
class CheckSheet extends Component{

    constructor(props) {
        super(props);
        this.state={
            filterState: null
        }
    }

    componentDidUpdate = (prevProps) => {
        const {driversSummaryData} = this.props
        if(prevProps.driversSummaryData !== driversSummaryData){
            this.unparseSummaryDatatoCsv(driversSummaryData)
        }
    }

    unparseSummaryDatatoCsv = (summaryData) => {
       if(summaryData.length === 0)
        return
       const {filterState} = this.state
       const csv = Papa.unparse({
            "fields": ["Driver","WO Amount","DO Amount",
                        "Estimated DO KM", "Realization DO KM",
                        "WO Check In", "WO with POD", 
                        "WO Check In Time", "WO Check In Late"],
            "data" : summaryData, 
        }, { delimiter: ';' });

        //Create CSV Document
        let filterStartDate = moment(filterState.start_date).format("YYYY MMMM")
        let filterEndDate = moment(filterState.end_date).format("YYYY MMMM")
        let fileNameStr = `Drivers Summary ${filterStartDate} - ${filterEndDate}.csv`
        if(filterStartDate === filterEndDate)
            fileNameStr = `Drivers Summary ${filterStartDate}.csv`
        const blob = new Blob([csv])
        const a = document.createElement('a')
        a.href = URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = fileNameStr
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    onFilterSubmited = (filterState) => {
        const {drivers} = this.props
        this.setState({filterState})
        this.props.fetchDriversSummary(drivers, filterState)
    }

    onClose = () => {
        this.props.onClose()
    }

    render(){
        const {loading} = this.props
        return(
            <>
                {loading === "loading" &&
                    <center>
                        <HashLoader color={'#123abc'} loading={true} /> 
                        <br /> Loading.... Please wait...
                    </center> 
                }
                {loading === "loaded" &&
                    <DriverSummaryFilter onFilterSubmited={this.onFilterSubmited} onClose={this.onClose}/>
                }
                {(!loading || loading === "error") &&
                    <DriverSummaryFilter onFilterSubmited={this.onFilterSubmited} onClose={this.onClose}/>
                }
                {/* <ToastContainer position="bottom-right" autoClose={7500} style={{zIndex: 7000}}/> */}
            </>
        )
    }

}
const mapStateToProps = (state) => ({
    driversSummaryData: state.driversSummaryStore.driversSummaryData,
    loading: state.driversSummaryStore.loading
})
export default connect(mapStateToProps, {fetchDriversSummary})(CheckSheet);
import useLazyFetch from "../../../../hooks/useLazyFetch";
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { MAINTENANCE_APPROVAL_TYPE_SETTING_LIST } from "../../../../redux/config/storing.links";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ApprovalTypeSettingDelete from "./contents/ApprovalTypeSettingDelete";
import ApprovalTypeSettingAddEdit from "./contents/ApprovalTypeSettingAddEdit";
import LoadingContent from "../../../../components/LoadingContent/LoadingContent";
import {
  bootstrapTableOption,
  fileNameFormat,
} from "../../../../helpers/helper";
import SummaryWidgets from "../../../Widgets/SummaryWidgets";

const ApprovalTypeSetting = () => {
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  const [modalOpenDelete, setModalOpenDelete] = useState(null);
  const [gettingTripList, setGettingTripList] = useState(true);
  const [openAddMechanic, setOpenAddMechanic] = useState(false);

  const [onGetTripList, { loading: loadingTripList, data }] = useLazyFetch({
    url: MAINTENANCE_APPROVAL_TYPE_SETTING_LIST,
    method: "POST",
  });

  useEffect(() => {
    if (gettingTripList) {
      setGettingTripList(false);
      onGetTripList({});
    }
  }, [gettingTripList, onGetTripList]);
  return (
    <>
      <Row>
        <Col md={3} xs={12}>
          <SummaryWidgets
            header={(data ? data.result : []).length}
            mainText={"Total Approval Type Setting"}
            icon={"fa fa-id-card"}
            color={"success"}
          />
        </Col>
      </Row>
      <Card>
        <CardHeader> Approval Type Setting</CardHeader>
        <CardBody>
          <LoadingContent
            loading={loadingTripList}
            onReload={() => setGettingTripList(true)}
          >
            <BootstrapTable
              data={data ? data.result : []}
              version="4"
              bordered={false}
              hover
              pagination
              search
              edit
              options={bootstrapTableOption({
                btnGroup: (props) => (
                  <div>
                    {props.exportCSVBtn}{" "}
                    <Button
                      color={"primary"}
                      onClick={() => setGettingTripList(true)}
                    >
                      <i className="fa fa-refresh"></i> Reload
                    </Button>{" "}
                    <Button
                      color={"primary"}
                      onClick={() => setOpenAddMechanic(true)}
                    >
                      <i className="fa fa-plus"></i> Add Approval Type Setting
                    </Button>{" "}
                  </div>
                ),
              })}
              exportCSV={true}
              csvFileName={fileNameFormat({ name: "" })}
              multiColumnSearch
            >
              <TableHeaderColumn
                dataField="id"
                isKey
                dataSort
                csvHeader="ID"
                hidden
              >
                ID
              </TableHeaderColumn>

              <TableHeaderColumn dataField="step" dataSort csvHeader="Step">
                Step
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="trackx_division_name"
                dataSort
                csvHeader="Trackx Division Name"
              >
                Trackx Division Name
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="created_on"
                dataSort
                csvHeader="Created On"
              >
                Created On
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataFormat={(cell, row) => (
                  <>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        color="warning"
                        onClick={() => setModalOpenEdit({ ...row })}
                      >
                        <i className="fa fa-edit"></i> Edit
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => setModalOpenDelete({ ...row })}
                      >
                        <i className="fa fa-trash"></i> Delete
                      </Button>
                    </ButtonGroup>
                  </>
                )}
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>
          </LoadingContent>
        </CardBody>
      </Card>
      <Modal
        size="md"
        isOpen={!!openAddMechanic}
        toggle={() => setOpenAddMechanic(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenAddMechanic(false)}>
          Add Approval Type Setting
        </ModalHeader>
        <ModalBody>
          <ApprovalTypeSettingAddEdit
            onClose={() => setOpenAddMechanic(false)}
            onReload={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>
      <Modal
        size="md"
        isOpen={!!modalOpenEdit}
        toggle={() => setModalOpenEdit(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(null)}>
          Add Approval Type Setting
        </ModalHeader>
        <ModalBody>
          <ApprovalTypeSettingAddEdit
            initialValues={modalOpenEdit}
            isEdit
            onClose={() => setModalOpenEdit(null)}
            onReload={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>
      <Modal
        size="md"
        isOpen={!!modalOpenDelete}
        toggle={() => setModalOpenDelete(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenDelete(null)}>
          Delete Approval Type Setting
        </ModalHeader>
        <ModalBody>
          <ApprovalTypeSettingDelete
            initialValues={modalOpenDelete}
            onClose={() => setModalOpenDelete(null)}
            onReload={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default ApprovalTypeSetting;

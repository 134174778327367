import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import { fetchDriversTable } from "../../../redux/actions/transporter/aTransporter-DriversTable";
import DriversDatatable from "./Drivers-Datatable";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as API_LINKS from "../../../redux/config/links";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SummaryWidgets from "../SavedPlaces/Widgets/SummaryWidgets";
import Dropzone from "../../../components/Dropzone/";
import { AbilityContext } from "../../../casl/Can";
import {
  employmentStatusSelections,
  activeFeature,
} from "../../../constants/constants";
import CustomNumberFormat from "../../CarbonX/Widgets/NumberFormat";
import Select from "react-select";
import "react-select/dist/react-select.css";

class Drivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      modalAdd: false,
      driverBirthDate: "",
      date: "",
      licDate: "",
      licExpiryDate: "",
      licType: "",
      driverPhoto: [],
      email: "",
      password: "",

      // New parameter
      trainingStatus: "",
      trainDate: "",
      trainingDate: "",
      certifDate: "",
      certificationExpiryDate: "",
      nextTrainingDate: "",
      nextTrainingScheduleDate: "",
    };
  }

  toggleOpenAdd = () => {
    this.setState({
      modalAdd: !this.state.modalAdd,
    });
  };

  toggleCloseAdd = () => {
    this.setState({
      modalAdd: !this.state.modalAdd,
      driverBirthDate: "",
      date: "",
      licDate: "",
      licExpiryDate: "",
      licType: "",

      // New parameter
      trainingStatus: "",
      trainDate: "",
      trainingDate: "",
      certifDate: "",
      certificationExpiryDate: "",
      nextTrainingDate: "",
      nextTrainingScheduleDate: "",
    });
  };

  handleBirthDateChange = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      date: _date,
      driverBirthDate: convertedDate,
    });
  };

  handleLicenseExpiryDateChange = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      licDate: _date,
      licExpiryDate: convertedDate,
    });
  };

  handleCertificationExpiryDateChange = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      certifDate: _date,
      certificationExpiryDate: convertedDate,
    });
  };

  handleTrainingDateChange = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      trainDate: _date,
      trainingDate: convertedDate,
    });
  };

  handleNextTrainingScheduleDate = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      nextTrainingDate: _date,
      nextTrainingScheduleDate: convertedDate,
    });
  };

  handleLicenseTypeChange(e) {
    this.setState({ licType: e.target.value });
  }

  handleOnDrop = (result) => {
    //Dropzone
    if (result.length === 0) {
      this.setState({ driverPhoto: [] });
      return;
    }
    if (!result.passed) {
      toast.error("Photo, " + result.reasonRejected);
    }
    this.setState({ driverPhoto: result });
  };

  handleEmploymentStatus = (employmentStatus) => {
    this.setState({ employmentStatus });
  };

  viewAddDriver = () => {
    return (
      <div className="container animated fadeIn">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">First Name</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addDriverFirstName"
              placeholder="John"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Last Name</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addDriverLastName"
              placeholder="Doe"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        {/* <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver Name</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addDriverName"
              placeholder="John Doe"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup> */}
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Indentification Card Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addIdentificationNumber"
              placeholder="21711xxxxxxxxxxx"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Employment Status</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={this.state.employmentStatus}
              onChange={this.handleEmploymentStatus}
              options={employmentStatusSelections}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Birth Date</Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={this.state.date}
              onChange={this.handleBirthDateChange}
              placeholderText="Select a birth date"
              disabled={this.state.isAdding}
              customInput={
                <Input type="text" placeholder="Select a birth date" />
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver Phone</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addDriverPhone"
              placeholder="628xxxxxxxx"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">License Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="select"
              name="select"
              id="addSelectLicenseType"
              disabled={this.state.isAdding}
            >
              <option value="">Select License Type</option>
              <option value="SIM A">SIM A</option>
              <option value="SIM A (Umum)">SIM A (Umum)</option>
              <option value="SIM B1">SIM B1</option>
              <option value="SIM B1 (Umum)">SIM B1 (Umum)</option>
              <option value="SIM B2">SIM B2</option>
              <option value="SIM B2 (Umum)">SIM B2 (Umum)</option>
              <option value="SIM C">SIM C</option>
              <option value="SIM D">SIM D</option>
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">License Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addLicenseNumber"
              placeholder="9018xxxxxxxx"
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">License Expiry Date</Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={this.state.licDate}
              onChange={this.handleLicenseExpiryDateChange}
              placeholderText="Select License Expiry Date"
              disabled={this.state.isAdding}
              customInput={
                <Input type="text" placeholder="Select License Expiry Date" />
              }
            />
          </Col>
        </FormGroup>
        {this.state.employmentStatus ? (
          <FormGroup row>
            <Col md="3">
              <Label htmlFor="text-input">Daily Rate</Label>
            </Col>
            <Col xs="12" md="9">
              <CustomNumberFormat
                value={this.state.daily_rate}
                thousandSeparator={true}
                disabled={this.state.isAdding}
                prefix={"Rp. "}
                onValueChange={(values) => {
                  // eslint-disable-next-line
                  const { formattedValue, value } = values;
                  // formattedValue = $2,223
                  // value ie, 2223
                  this.setState({ daily_rate: value });
                }}
              />
            </Col>
          </FormGroup>
        ) : (
          true
        )}
        <FormGroup row>
          <Col xs="3">
            <Label htmlFor="text-input">Driver Photo</Label>
          </Col>
          <Col xs="12" md="9">
            <Dropzone
              maxFile={1}
              maxSize={1000000}
              accept="image/png, image/jpeg"
              onDrop={this.handleOnDrop}
              preview={true}
            ></Dropzone>
          </Col>
        </FormGroup>
        {/* Parameter baru */}
        {activeFeature.HR_DASHBOARD && (
          <>
            <br />
            Training & Certifications
            <hr />
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Training Status</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="select"
                  name="training_status"
                  id="training_status"
                  disabled={this.state.isAdding}
                >
                  <option value="">Select Training Status</option>
                  <option value="Completed">Completed</option>
                  <option value="Ongoing">Ongoing</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Training Date</Label>
              </Col>
              <Col xs="12" md="9">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={
                    this.state.trainingDate
                      ? new Date(this.state.trainingDate)
                      : null
                  }
                  onChange={this.handleTrainingDateChange}
                  placeholderText="Select Training Date"
                  disabled={this.state.isAdding}
                  customInput={
                    <Input type="text" placeholder="Select Training Date" />
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Certification Expiry Date</Label>
              </Col>
              <Col xs="12" md="9">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={
                    this.state.certificationExpiryDate
                      ? new Date(this.state.certificationExpiryDate)
                      : null
                  }
                  onChange={this.handleCertificationExpiryDateChange}
                  placeholderText="Select Certification Expiry Date"
                  disabled={this.state.isAdding}
                  customInput={
                    <Input
                      type="text"
                      placeholder="Select Certification Expiry Date"
                    />
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Certificate Number</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="certificate_number"
                  id="certificate_number"
                  placeholder="Enter Certificate Number"
                  disabled={this.state.isAdding}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Organizer</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="organizer"
                  id="organizer"
                  placeholder="Enter Organizer"
                  disabled={this.state.isAdding}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Next Training Schedule</Label>
              </Col>
              <Col xs="12" md="9">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={
                    this.state.nextTrainingScheduleDate
                      ? new Date(this.state.nextTrainingScheduleDate)
                      : null
                  }
                  onChange={this.handleNextTrainingScheduleDate}
                  placeholderText="Select Next Training Schedule"
                  disabled={this.state.isAdding}
                  customInput={
                    <Input
                      type="text"
                      placeholder="Select Next Training Schedule"
                    />
                  }
                />
              </Col>
            </FormGroup>
          </>
        )}
        {/* End of Parameter baru */}
        <br />
        Driver Account
        <hr />
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Email</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              autoComplete="email"
              onChange={(e) => this.setState({ email: e.target.value })}
              onBlur={() => {}}
              value={this.state.email || ""}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Password</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="*********"
              onChange={(e) => this.setState({ password: e.target.value })}
              onBlur={() => {}}
              value={this.state.password || ""}
              disabled={this.state.isAdding}
            />
          </Col>
        </FormGroup>
        {this.state.isAdding ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.state.isAdding} /> <br />{" "}
            Loading.... Please wait...
          </center>
        ) : (
          <div className="d-flex justify-content-end w-100">
            <Button
              onClick={() => this.handleAdd()}
              type="submit"
              color="primary"
              className="mr-1 w-50"
              disabled={this.state.isAdding}
            >
              Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseAdd()}
              size="sm"
              color="warning"
              className="mr-1 w-50"
              disabled={this.state.isAdding}
            >
              Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  totalDriversBasedOnSubs = () => {
    if (this.context.can("create", "max_10_free@total_drivers_subs")) return 10;

    if (this.context.can("create", "max_10_basic@total_drivers_subs"))
      return 10;

    if (this.context.can("create", "max_20_medium@total_drivers_subs"))
      return 20;

    if (this.context.can("create", "max_advanced@total_drivers_subs"))
      return Number.MAX_SAFE_INTEGER;
  };

  validEmail(emailInput) {
    // eslint-disable-next-line no-useless-escape
    // var filter =
    //   /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    var filter = /^\s*[\w+\-.]+@[\w\-.]+\.[\w\-.]+\s*$/;
    return String(emailInput).search(filter) !== -1;
  }

  validPassword(passwordInput) {
    // eslint-disable-next-line no-useless-escape
    // var filter =
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$&()*|;'?\\-`.+,_/\"])(?=.{8,})/;
    var filter =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$&()*|;'?\\-`.+,_/]).{8,}$/;
    return String(passwordInput).search(filter) !== -1;
  }

  handleAdd = async () => {
    // const totalDriverLimitSubs = this.totalDriversBasedOnSubs();

    if (this.props.totalDriver >= this.props.maxTotalDriver) {
      toast.error("Upgrade your subscription to insert another driver!");
    } else {
      this.setState({ isAdding: true });
      var self = this;
      var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      var auth = loginCreds.token;
      //Variables for text input
      var e = document.getElementById("addSelectLicenseType");
      var _licType = e.options[e.selectedIndex].value;
      // console.log(_licType)
      // var _licType = document.querySelector('#addSelectLicenseType').value;
      var _firstName = document.querySelector("#addDriverFirstName").value;
      var _lastName = document.querySelector("#addDriverLastName").value;
      var _driverName = _firstName + " " + _lastName;
      var _driverBirthDate = self.state.driverBirthDate;
      var _driverPhone = document.querySelector("#addDriverPhone").value;
      var _licenseNumber = document.querySelector("#addLicenseNumber").value;
      var _identificationNumber = document.querySelector(
        "#addIdentificationNumber"
      ).value;
      var _licExpiryDate = self.state.licExpiryDate;
      const { driverPhoto, email, password } = this.state;

      // New parameter
      var _trainingStatus = document.querySelector("#training_status").value;
      var _trainingDate = self.state.trainingDate;
      var _certificationExpiryDate = self.state.certificationExpiryDate;
      var _certificateNumber = document.querySelector(
        "#certificate_number"
      ).value;
      var _organizer = document.querySelector("#organizer").value;
      var _nextTrainingScheduleDate = self.state.nextTrainingScheduleDate;
      // End of new parameter

      if (_driverName === "" || _driverName === null) {
        self.setState({ isAdding: false });
        toast.error("Name is invalid!");
      } else if (_licType === "" || _licType === null) {
        self.setState({ isAdding: false });
        toast.error("License type is invalid!");
      } else if (_licenseNumber === "" || _licenseNumber === null) {
        self.setState({ isAdding: false });
        toast.error("License number is invalid!");
      } else if (
        _identificationNumber === "" ||
        _identificationNumber === null
      ) {
        self.setState({ isAdding: false });
        toast.error("Identification number is invalid!");
      } else if (_licExpiryDate === "" || _licExpiryDate === null) {
        self.setState({ isAdding: false });
        toast.error("License expiry date is invalid!");
      } else if (driverPhoto.length === 0) {
        self.setState({ isAdding: false });
        toast.error("Driver Photo cannot empty!");
      } else if (!driverPhoto.passed) {
        self.setState({ isAdding: false });
      }
      // Parameter baru
      if (_trainingStatus === "" || _trainingStatus === null) {
        self.setState({ isAdding: false });
        toast.error("Training Status is invalid!");
      } else if (_trainingDate === "" || _trainingDate === null) {
        self.setState({ isAdding: false });
        toast.error("Training Date is invalid!");
      } else if (
        _certificationExpiryDate === "" ||
        _certificationExpiryDate === null
      ) {
        self.setState({ isAdding: false });
        toast.error("Certification Expiry Date is invalid!");
      } else if (_certificateNumber === "" || _certificateNumber === null) {
        self.setState({ isAdding: false });
        toast.error("Certificate Number is invalid!");
      } else if (_organizer === "" || _organizer === null) {
        self.setState({ isAdding: false });
        toast.error("Organizer is invalid!");
      } else if (
        _nextTrainingScheduleDate === "" ||
        _nextTrainingScheduleDate === null
      ) {
        self.setState({ isAdding: false });
        toast.error("Training Schedule Date is invalid!");
      }
      // End of Parameter baru
      else if (email === null || email === "") {
        self.setState({ isAdding: false });
        toast.error("Email cannot empty!");
      } else if (!this.validEmail(email)) {
        self.setState({ isAdding: false });
        toast.error("Invalid email!");
      } else if (password === null || password === "") {
        self.setState({ isAdding: false });
        toast.error("Password cannot empty!");
      } else if (!this.validPassword(password)) {
        self.setState({ isAdding: false });
        toast.error(
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character!"
        );
      } else {
        let formData = new FormData();

        formData.append("first_name", _firstName);
        formData.append("last_name", _lastName);
        formData.append("name", _driverName);
        formData.append("telp", _driverPhone);
        formData.append("birth_date", _driverBirthDate);
        formData.append("lic_type", _licType);
        formData.append("lic_number", _licenseNumber);
        formData.append("ktp", _identificationNumber);
        formData.append("exp_date", _licExpiryDate);
        formData.append("file", driverPhoto.uploadedFiles[0]);

        // Start of PARAMETER BARU
        formData.append("training_status", _trainingStatus);
        formData.append("training_date", _trainingDate);
        formData.append("certification_expiry_date", _certificationExpiryDate);
        formData.append("certificate_number", _certificateNumber);
        formData.append("organizer", _organizer);
        formData.append("next_training_schedule", _nextTrainingScheduleDate);
        // End of PARAMETER BARU

        formData.append(
          "image_desc",
          "Foto driver bernama " + driverPhoto.uploadedFiles.name
        );
        formData.append("email", email);
        formData.append("password", password);

        for (let pair of formData.entries()) {
          console.log(`${pair[0]}: ${pair[1]}`);
        }

        axios({
          method: "post",
          url: API_LINKS.DRIVER_ADD_URL, //Please change when it's ready
          headers: {
            Authorization: auth,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
          .then((response) => {
            if (response.status === 200) {
              self.setState({
                isAdding: false,
                driverBirthDate: "",
                date: "",
                licDate: "",
                licExpiryDate: "",
                licType: "",
                driverPhoto: [],
                email: "",
                password: "",

                // Start new parameter
                trainingStatus: "",
                trainDate: "",
                trainingDate: "",
                certifDate: "",
                certificationExpiryDate: "",
                nextTrainingDate: "",
                nextTrainingScheduleDate: "",
                // End of new parameter
              });
              if (response.data.status === "success") {
                toast.success("Data saved!");
                self.toggleCloseAdd();
                self.props.fetchDriversTable();
              }
            }
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              self.setState({
                isAdding: false,
              });
              return toast.error(
                "Authorization failed! Please logout and login again to gain authentication"
              );
            } else if (
              error.response.status === 404 ||
              error.response.status === 500
            ) {
              self.setState({
                isAdding: false,
              });
              return toast.error(
                "Fatal error! Please contact your local administrator!"
              );
            } else {
              self.setState({
                isAdding: false,
              });
              return toast.error(error.response.data.message);
            }
          });
      }
    }
  };

  render() {
    const {
      totalDriver,
      totalWorkOrders,
      verifiedWorkOrders,
      underTimeOrders,
      overTimeOrders,
      normalTimeOrders,
      departureOrders,
      returnHomeOrders,
      noArrivalTimeOrders,
      noCheckoutTimeOrders,
      incompleteOrders,
    } = this.props;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col md={3} xs={12}>
            <SummaryWidgets
              header={totalDriver}
              mainText={"Total Driver(s)"}
              icon={"fa fa-user"}
              color={"primary"}
            />
          </Col>
          {activeFeature.SUMMARY_WIDGET_DRIVER_NEW && (
            <>
              <Col md={3} xs={12}>
                <SummaryWidgets
                  header={totalWorkOrders}
                  mainText={"Total Work Orders"}
                  icon={"fa fa-tasks"}
                  color={"info"}
                />
              </Col>
              <Col md={3} xs={12}>
                <SummaryWidgets
                  header={verifiedWorkOrders}
                  mainText={"Verified Work Orders"}
                  icon={"fa fa-check"}
                  color={"success"}
                />
              </Col>
              <Col md={3} xs={12}>
                <SummaryWidgets
                  header={normalTimeOrders}
                  mainText={"On Time Orders"}
                  icon={"fa fa-clock-o"}
                  color={"success"}
                />
              </Col>
              <Col md={3} xs={12}>
                <SummaryWidgets
                  header={underTimeOrders}
                  mainText={"Undertime Orders"}
                  icon={"fa fa-exclamation-circle"}
                  color={"warning"}
                />
              </Col>
              <Col md={3} xs={12}>
                <SummaryWidgets
                  header={overTimeOrders}
                  mainText={"Overtime Orders"}
                  icon={"fa fa-exclamation-triangle"}
                  color={"danger"}
                />
              </Col>
              <Col md={3} xs={12}>
                <SummaryWidgets
                  header={departureOrders}
                  mainText={"Departure Orders"}
                  icon={"fa fa-sign-out"}
                  color={"primary"}
                />
              </Col>
              <Col md={3} xs={12}>
                <SummaryWidgets
                  header={returnHomeOrders}
                  mainText={"Return Home Orders"}
                  icon={"fa fa-home"}
                  color={"secondary"}
                />
              </Col>
              <Col md={3} xs={12}>
                <SummaryWidgets
                  header={incompleteOrders}
                  mainText={"Incomplete Orders"}
                  icon={"fa fa-times-circle"}
                  color={"warning"}
                />
              </Col>
              <Col md={3} xs={12}>
                <SummaryWidgets
                  header={noArrivalTimeOrders}
                  mainText={"No Arrival Time"}
                  icon={"fa fa-question-circle"}
                  color={"warning"}
                />
              </Col>
              <Col md={3} xs={12}>
                <SummaryWidgets
                  header={noCheckoutTimeOrders}
                  mainText={"No Checkout Time"}
                  icon={"fa fa-times-circle-o"}
                  color={"warning"}
                />
              </Col>
            </>
          )}

          {/* <Col md={3} xs={12}>
                        <SummaryWidgets header={approvedPlace} mainText={'Approved Location(s)'} icon={'fa fa-check'} color={'success'}/>
                    </Col>
                    <Col md={3} xs={12}>
                        <SummaryWidgets header={notApprovedPlace} mainText={'Not Approved Location(s)'} icon={'fa fa-ban'} color={'warning'}/>
                    </Col>
                    <Col md={3} xs={12}>
                        <SummaryWidgets header={deactivatedPlace} mainText={'Deactivated Location(s)'} icon={'fa fa-trash'} color={'danger'}/>
                    </Col> */}
        </Row>
        <Card>
          <CardHeader>Drivers List</CardHeader>
          <CardBody>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "1rem",
              }}
            >
              {this.props.successDriversFetch &&
              !(this.props.totalDriver >= this.props.maxTotalDriver) ? (
                <div>
                  <Button
                    onClick={this.toggleOpenAdd}
                    size="md"
                    color="success"
                    disabled={this.props.inProgress}
                  >
                    <i className="fa fa-plus"></i> Add Driver
                  </Button>
                  &nbsp;&nbsp;
                </div>
              ) : (
                true
              )}
            </div>

            <DriversDatatable />
          </CardBody>

          {/* Modal for Adding Cards */}
          <Modal
            isOpen={this.state.modalAdd}
            toggle={this.toggleOpenAdd}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleOpenAdd}>Add Driver</ModalHeader>
            <ModalBody>{this.viewAddDriver()}</ModalBody>
            {/* <ToastContainer position="bottom-right" autoClose={7500} style={toastStyle}/> */}
          </Modal>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driversTableData: state.driversTableStore.driversTableData,
    totalDriver: state.driversTableStore.totalDriver,
    inProgress: state.driversTableStore.inProgress,
    successDriversFetch: state.driversTableStore.success,

    totalWorkOrders: state.driversTableStore.totalWorkOrders,
    verifiedWorkOrders: state.driversTableStore.verifiedWorkOrders,
    underTimeOrders: state.driversTableStore.underTimeOrders,
    overTimeOrders: state.driversTableStore.overTimeOrders,
    normalTimeOrders: state.driversTableStore.normalTimeOrders,
    departureOrders: state.driversTableStore.departureOrders,
    returnHomeOrders: state.driversTableStore.returnHomeOrders,
    noArrivalTimeOrders: state.driversTableStore.noArrivalTimeOrders,
    noCheckoutTimeOrders: state.driversTableStore.noCheckoutTimeOrders,
    incompleteOrders: state.driversTableStore.incompleteOrders,

    maxTotalDriver: state.authStore.subs.total_driver,
  };
};

Drivers.contextType = AbilityContext;
export default connect(mapStateToProps, { fetchDriversTable })(Drivers);

import React, { Component } from 'react'
import { Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { connect } from "react-redux";
import { BarLoader } from 'react-spinners';
import { fetchSecurityTable } from "../../../redux/actions/transporter/aTransporter-SecurityTable";
import SecurityDatatable from './Security-Datatable'
import * as API_LINKS from '../../../redux/config/links';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import {toast}  from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { AbilityContext} from '../../../casl/Can';
import SummaryWidgets from '../SavedPlaces/Widgets/SummaryWidgets'

class Security extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isAdding: false,
            modalAdd: false,
         };
    }

    toggleOpenAdd = () => {
        this.setState({
            date: null,
            modalAdd: !this.state.modalAdd
        })
    }

    toggleCloseAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd,
        })
    }

    handleBirthDateChange = (_date) => {
        var convertedDate = moment(_date).format("YYYY-MM-DD")
        // console.log(convertedDate)
        this.setState({
            date: _date,
            secBirthDate: convertedDate
        });
    }

    viewAddSecurity = () => {
        return (
            <div className="container animated fadeIn">
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">Email Address<span className="incomplete-cell">*</span></Label>
                    </Col>
                    <Col xs="12" md="9">
                    <Input type="email" name="text-input" id="addEmail" placeholder="john@doe.com" disabled={this.state.isAdding} required autoComplete="off"/>
                    </Col>
                </FormGroup> 
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">Password<span className="incomplete-cell">*</span></Label>
                    </Col>
                    <Col xs="12" md="9">
                    <Input type="password" name="text-input" id="addPassword" placeholder="*********" disabled={this.state.isAdding} required autoComplete="off"/>
                    </Col>
                </FormGroup> 
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">First Name</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="addFirstName" placeholder="ex: Budi" disabled={this.state.isAdding}/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Last Name</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="addLastName" placeholder="ex: Doe" disabled={this.state.isAdding}/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">Birth Date</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={this.state.date}
                            onChange={this.handleBirthDateChange} 
                            placeholderText="Pick a birth date"
                            disabled={this.state.isAdding}/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Security Code</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="addSecurityCode" placeholder="ex: 1200827847" disabled={this.state.isAdding}/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Phone<span className="incomplete-cell">*</span></Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="addPhone" placeholder="628123456789" disabled={this.state.isAdding} required autoComplete="off"/>
                    </Col>
                </FormGroup> 
                {/* <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Longitude</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="text" name="text-input" id="addLongitude" placeholder="ex: -4.127346" disabled={this.state.isAdding}/>
                    </Col>
                </FormGroup>   */}
                {this.state.isAdding ? <center><BarLoader color={'#123abc'} 
                  loading={this.state.isAdding} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => this.handleAdd()} size="sm" color="primary" disabled={this.state.isAdding}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                <Button onClick={() => this.toggleCloseAdd()} size="sm" color="success" disabled={this.state.isAdding}><i className="fa fa-window-close"></i> Close</Button></div>}
            </div>
        )
    }

    isValidEmailAddress(emailAddress) {
        var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
        return pattern.test(emailAddress);
    }

    handleAdd = () => {
        this.setState({ isAdding: true })

        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;

        //Variables for text input
        var _firstName = document.querySelector('#addFirstName').value;
        var _lastName = document.querySelector('#addLastName').value;
        var _email = document.querySelector('#addEmail').value;
        var _password = document.querySelector('#addPassword').value;
        var _phone = document.querySelector('#addPhone').value;
        var _securityCode = document.querySelector('#addSecurityCode').value;
        var _birthDate = this.state.secBirthDate;

        const nameRegex = /^[a-zA-Z ]{2,30}$/;

        if (_firstName === "" || _firstName === null || !nameRegex.test(_firstName)) {
            self.setState({isAdding: false})
            toast.error("First Name is Invalid! Please fill in the visitor name! (Alphabets only)")
        } else if (_lastName === "" || _lastName === null || !nameRegex.test(_lastName)) {
            self.setState({isAdding: false})
            toast.error("Last Name is Invalid! Please fill in the visitor name! (Alphabets only)")
        } else if (_email === null || _email === "" || !this.isValidEmailAddress(_email)) {
            this.setState({
                isAdding: false
            })
            toast.error('Email address is not valid!!')
        } else if(_securityCode === "" || _securityCode === null) {
            self.setState({isAdding: false})
            toast.error("Security Code is invalid!!")
        } else if (_password === null || _password === "") {
            self.setState({
                isAdding: false
            })
            toast.error('Password is not valid!!')
        } else if (_password.length < 8) {
            self.setState({
                isAdding: false
            })
            toast.error('Password minimum length 8 characters!!')
        } else {
            axios({
                method:'post',
                url: API_LINKS.SECURITY_INPUT_URL, //Please change when it's ready
                headers: {
                    Authorization: auth
                },
                data: {
                    email: _email,
                    password: _password,
                    first_name: _firstName,
                    last_name: _lastName,
                    phone: _phone,
                    security_code: _securityCode,
                    birth_date: _birthDate
                }
            })
            .then((response) => {
                // console.log(response)
                if(response.status === 200) {
                    if (response.data.status === "success") {
                        self.setState({
                            isAdding: false,
                        })
                        self.toggleCloseAdd()
                        self.props.fetchSecurityTable()
                        return toast.success('Security has been registered!');
                    }
                }
            })
            .catch(function(error){
                if (error.response) {
                    if(error.response.status === 401) {
                        self.setState({
                            isAdding: false,
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                        self.setState({
                            isAdding: false,
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 400) {
                        self.setState({
                            isAdding: false,
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        self.setState({
                            isAdding: false,
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else {
                        self.setState({
                            isAdding: false,
                        })
                        return toast.error('Something went wrong... Please try again later...')
                    }
                } else if (error.request) {
                    self.setState({
                        isAdding: false,
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    self.setState({
                        isAdding: false,
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }

    checkRoles = () => {

        return (
            <React.Fragment>
                {this.context.can("view","add_button@security")&&
                    <div>
                        <Button onClick={this.toggleOpenAdd} size="sm" color="success" disabled={this.props.inProgress}><i className="fa fa-plus"></i> Add Security</Button> &nbsp; &nbsp;
                    </div>
                }
            </React.Fragment>
        )

    }

    render() {
        const {securityTableData} = this.props
        return (
            <div className="animated fadeIn">
            <Row>
                <Col md={3} xs={12}>
                    <SummaryWidgets header={securityTableData.result?securityTableData.result.length:0} 
                                    mainText={'Total Security'} icon={'fa fa-shield'} 
                                    color={'primary'}/>
                </Col>
            </Row>
            <Card>
                <CardHeader>
                    Security
                </CardHeader>
                <CardBody>
                    {
                        (
                            this.props.successSecurityFetch 
                            ? 
                            <div><Row className="pull-right">
                                {this.checkRoles()}
                            </Row> <br/><br/><br/><br/> </div>
                            :
                            true
                        )
                    }
                    <SecurityDatatable />
                </CardBody>

                {/* Modal for Adding Cards */}
                <Modal isOpen={this.state.modalAdd} toggle={this.toggleOpenAdd} className={this.props.className}>
                    <ModalHeader toggle={this.toggleOpenAdd}>Add Security</ModalHeader>
                    <ModalBody>
                            {this.viewAddSecurity()}
                    </ModalBody>
                    {/* <ToastContainer position="bottom-right" autoClose={7500} style={toastStyle}/> */}
                </Modal>
            </Card>
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        securityTableData: state.securityTableStore.securityTableData,
        inProgress: state.securityTableStore.inProgress,
        successSecurityFetch: state.securityTableStore.success,
      }
}

Security.contextType = AbilityContext;
export default connect(mapStateToProps, {fetchSecurityTable})(Security);
import React, { useState, useEffect, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import * as API_LINKS from "../../../redux/config/links";
import { Formik } from "formik";
import axios from "axios";
import { SelectField } from "../../LandLord/Widgets/SelectField";
import { BarLoader } from "react-spinners";
import { connect } from "react-redux";
import styles from "./SavedPlaces.module.scss";

const mapStateToProps = (state) => {
  return {
    mainCustomerSelect: state.mainCustomerStore.mainCustomerSelect,
  };
};

const FormEdit = connect(
  mapStateToProps,
  {}
)(
  ({
    formik,
    id,
    toggleCloseEdit,
    refetch,
    images,
    setImages,
    setIdDelete,
    isFetching,
    setIsFetching,
    ...props
  }) => {
    const inputFileRef = useRef(null);
    // const [isLoading, setIsLoading] = useState(false);
    const [imagesFromApi, setImagesFromApi] = useState([]);

    const onGetData = useCallback(() => {
      if (id) {
        const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        const auth = loginCreds.token;
        // setIsLoading(true);
        axios({
          method: "post",
          url: API_LINKS.SAVED_PLACES_LIST_URL, //Please change when it's ready
          headers: {
            Authorization: auth,
          },
          data: {
            id,
          },
        })
          .then((res) => {
            if (res.data.result && res.data.result.length > 0) {
              const [response] = res.data.result;
              formik.setFieldValue("customer", {
                value: response.id_customer,
                label: response.customer_name,
              });
            }
            setImagesFromApi([...res.data.image]);
            // setIsLoading(false);
          })
          .catch(() => {
            // setIsLoading(false);
          });
      }
    }, [API_LINKS, id]);

    useEffect(() => {
      if (isFetching) {
        setIsFetching(false);
        onGetData();
      }
    }, [onGetData, isFetching]);

    return (
      <form onSubmit={formik.handleSubmit}>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Customer Binding</Label>
          </Col>
          <Col xs="12" md="9">
            <SelectField
              id="customer"
              name="customer"
              label="customer"
              placeholder="Select customer"
              searchable
              options={props.mainCustomerSelect}
              value={formik.values.customer}
              isMulti={false}
              onChange={formik.handleChange}
              onBlur={formik.setFieldTouched}
              touched={formik.touched.customer}
              error={formik.errors.customer}
              isClearable={true}
              backspaceRemovesValue={true}
              disabled={formik.isSubmitting}
            />
          </Col>
          {/* <FormFeedback>{errors.location}</FormFeedback> */}
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Image</Label>
          </Col>
          <Col xs="12" md="9">
            <input
              type="file"
              hidden
              ref={inputFileRef}
              accept="image/*"
              onChange={(e) => {
                console.log(e.currentTarget.files);
                const [file] = e.currentTarget.files;
                if (file) {
                  setImages([
                    ...images,
                    { file, url: URL.createObjectURL(file) },
                  ]);
                }
              }}
            />
            <div className={styles["image-container"]}>
              {imagesFromApi.map((item, key) => (
                <div className={styles["image-item-container"]} key={key}>
                  <img
                    className={styles["image-item"]}
                    src={item.link}
                    alt={item.desc}
                  />
                  <div
                    className={styles["image-close"]}
                    onClick={() => setIdDelete(item.id)}
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
              ))}
              {images.map((item, key) => (
                <div className={styles["image-item-container"]} key={key}>
                  <img className={styles["image-item"]} src={item.url} alt="" />
                  <div
                    className={styles["image-close"]}
                    onClick={() =>
                      setImages([...images.filter((_, index) => index !== key)])
                    }
                  >
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>
              ))}
              <div
                className={styles["add-image"]}
                onClick={() => inputFileRef.current.click()}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </div>
            </div>
          </Col>
          {/* <FormFeedback>{errors.location}</FormFeedback> */}
        </FormGroup>
        {formik.isSubmitting ? (
          <center>
            <BarLoader color={"#123abc"} loading={formik.isSubmitting} /> <br />{" "}
            Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              type="submit"
              size="sm"
              color="success"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <i className="fa fa-edit"></i> Submit
            </Button>
            <Button
              onClick={() => toggleCloseEdit()}
              size="sm"
              color="warning"
              disabled={formik.isSubmitting}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </form>
    );
  }
);

const SavedPlacesEditForm = ({ id, toggleCloseEdit, refetch }) => {
  const [images, setImages] = useState([]);
  const [idDelete, setIdDelete] = useState(null);
  const [inProgressDelete, setInProgressDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const uploadMultipleImage = (allimages, curIndex, id, auth, cb) => {
    const fd = new FormData();
    fd.append("file", allimages[curIndex].file);
    fd.append("image_desc", "");
    fd.append("id", id);
    const newIndex = curIndex + 1;

    axios({
      method: "post",
      url: API_LINKS.SAVED_PLACES_UPLOAD_IMAGE, //Please change when it's ready
      headers: {
        Authorization: auth,
      },
      data: fd,
    })
      .then(() => {
        if (allimages[newIndex]) {
          return uploadMultipleImage(allimages, newIndex, id, auth, cb);
        } else {
          return cb();
        }
      })
      .catch(() => {
        if (allimages[newIndex]) {
          return uploadMultipleImage(allimages, newIndex, id, auth, cb);
        } else {
          return cb();
        }
      });
  };

  useEffect(() => {
    if (isDeleting) {
      setIsDeleting(false);
      setInProgressDelete(true);
      const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      const auth = loginCreds.token;
      axios({
        method: "post",
        url: API_LINKS.SAVED_PLACES_DELETE_IMAGE, //Please change when it's ready
        headers: {
          Authorization: auth,
        },
        data: {
          id: idDelete,
        },
      })
        .then(() => {
          setInProgressDelete(false);
          setIdDelete(null);
          setIsFetching(true);
        })
        .catch(() => {
          setInProgressDelete(false);
        });
    }
  }, [isDeleting, API_LINKS, idDelete]);

  return (
    <>
      <Formik
        initialValues={{
          customer: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
          var auth = loginCreds.token;
          setSubmitting(true);

          axios({
            method: "post",
            url: API_LINKS.SAVED_PLACES_EDIT_URL,
            headers: {
              Authorization: auth,
            },
            data: {
              id,
              id_customer: values.customer.value,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                if (response.data.status === "success") {
                  if (images.length !== 0) {
                    uploadMultipleImage(images, 0, id, auth, () => {
                      setSubmitting(false);
                      toast.success(
                        "Customer binding has been saved succesfully!"
                      );
                      toggleCloseEdit();
                      resetForm();
                      refetch();
                    });
                  } else {
                    setSubmitting(false);
                    toast.success(
                      "Customer binding has been saved succesfully!"
                    );
                    toggleCloseEdit();
                    resetForm();
                    refetch();
                  }
                }
              }
            })
            .catch(function (error) {
              if (error) {
                if (error.response.status === 401) {
                  setSubmitting(false);
                  return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 403) {
                  setSubmitting(false);
                  return toast.error(
                    "Unauthorized Access! Please try to logout.."
                  );
                } else if (error.response.status === 400) {
                  setSubmitting(false);
                  return toast.error(error.response.data.message);
                } else if (
                  error.response.status === 404 ||
                  error.response.status === 500
                ) {
                  setSubmitting(false);
                  return toast.error(
                    "Server cannot be contacted! Please ask your system administrator!"
                  );
                } else {
                  setSubmitting(false);
                  return toast.error(
                    "Something went wrong... Please try again later..."
                  );
                }
              } else if (error.request) {
                setSubmitting(false);
                return toast.error("Request error! Please try again later...");
              } else {
                setSubmitting(false);
                return toast.error(
                  "Something went wrong... Please try again later..."
                );
              }
            });
        }}
        render={({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          handleBlur,
          handleChange,
          handleReset,
          setTouched,
          isValid,
          isSubmitting,
        }) => (
          <>
            <FormEdit
              formik={{
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                handleSubmit,
                handleBlur,
                handleChange,
                handleReset,
                setTouched,
                isValid,
                isSubmitting,
              }}
              id={id}
              toggleCloseEdit={toggleCloseEdit}
              refetch={refetch}
              images={images}
              setImages={setImages}
              setIdDelete={setIdDelete}
              idDelete={idDelete}
              setIsFetching={setIsFetching}
              isFetching={isFetching}
            />
          </>
        )}
      />

      <Modal isOpen={!!idDelete} toggle={() => setIdDelete(null)}>
        <ModalHeader toggle={() => setIdDelete(null)}>Delete?</ModalHeader>
        <ModalBody>
          <div className="container">
            <FormGroup row>
              <Col xs="12" md="12">
                <Label htmlFor="text-input">
                  Do you want to delete this image?{" "}
                </Label>
              </Col>
            </FormGroup>

            {inProgressDelete ? (
              <center>
                <BarLoader color={"#123abc"} loading={inProgressDelete} />{" "}
                <br /> Loading.... Please wait...
              </center>
            ) : (
              <div>
                <Button
                  onClick={() => setIsDeleting(true)}
                  size="sm"
                  color="danger"
                  disabled={inProgressDelete}
                >
                  <i className="fa fa-trash"></i> Submit
                </Button>
                <Button
                  onClick={() => setIdDelete(null)}
                  size="sm"
                  color="success"
                  disabled={inProgressDelete}
                >
                  <i className="fa fa-window-close"></i> Close
                </Button>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SavedPlacesEditForm;

import useLazyFetch from "../../../hooks/useLazyFetch";
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { STORING_TICKET_LIST } from "../../../redux/config/incident.links";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import LoadingContent from "../../../components/LoadingContent/LoadingContent";
import { bootstrapTableOption, fileNameFormat } from "../../../helpers/helper";
import StoringTicketAddEdit from "./contents/StoringTicketAddEdit";
import StoringTicketDelete from "./contents/StoringTicketDelete";
import SummaryWidgets from "../../Widgets/SummaryWidgets";

const StoringTicket = () => {
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  const [modalOpenDelete, setModalOpenDelete] = useState(null);
  const [modalOpenCreate, setModalOpenCreate] = useState(false);
  const [gettingTripList, setGettingTripList] = useState(true);

  const [onGetTripList, { loading: loadingTripList, data }] = useLazyFetch({
    url: STORING_TICKET_LIST,
    method: "POST",
  });

  useEffect(() => {
    if (gettingTripList) {
      setGettingTripList(false);
      onGetTripList({});
    }
  }, [gettingTripList, onGetTripList]);
  return (
    <>
      <Row>
        <Col md={3} xs={12}>
          <SummaryWidgets
            header={(data ? data.result : []).length}
            mainText={"Total Storing Ticket"}
            icon={"fa fa-id-card"}
            color={"success"}
          />
        </Col>
      </Row>
      <Card>
        <CardHeader>Storing Ticket</CardHeader>
        <CardBody>
          <LoadingContent
            loading={loadingTripList}
            onReload={() => setGettingTripList(true)}
          >
            <BootstrapTable
              data={data ? data.result : []}
              version="4"
              bordered={false}
              hover
              pagination
              search
              edit
              options={bootstrapTableOption({
                btnGroup: (props) => (
                  <div>
                    {props.exportCSVBtn}{" "}
                    <Button
                      color={"primary"}
                      onClick={() => setGettingTripList(true)}
                    >
                      <i className="fa fa-refresh"></i> Reload
                    </Button>{" "}
                    <Button
                      color={"primary"}
                      onClick={() => setModalOpenCreate(true)}
                    >
                      <i className="fa fa-plus"></i> Create
                    </Button>
                  </div>
                ),
              })}
              exportCSV={true}
              csvFileName={fileNameFormat({ name: "" })}
              multiColumnSearch
            >
              <TableHeaderColumn
                dataField="id"
                isKey
                dataSort
                csvHeader="ID"
                hidden
              >
                ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="title"
                dataSort
                csvHeader="Title"
              >
                Title
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="desc"
                dataSort
                csvHeader="Description"
              >
                Description
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="driver_name"
                dataSort
                csvHeader="Driver"
              >
                Driver
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="driver_telp"
                dataSort
                csvHeader="Driver"
              >
                Driver's Phone
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="ticket_number"
                dataSort
                csvHeader="Ticket Number"
              >
                Ticket Number
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="created_on"
                dataSort
                csvHeader="Created On"
              >
                Created On
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                dataFormat={(cell, row) => (
                  <>
                    <ButtonGroup>
                      <Button
                        size="sm"
                        color="warning"
                        onClick={() => setModalOpenEdit({ ...row })}
                      >
                        <i className="fa fa-edit"></i> Edit
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => setModalOpenDelete({ ...row })}
                      >
                        <i className="fa fa-trash"></i> Delete
                      </Button>
                    </ButtonGroup>
                  </>
                )}
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>
          </LoadingContent>
        </CardBody>
      </Card>

      <Modal
        size="md"
        isOpen={modalOpenCreate}
        toggle={() => setModalOpenCreate(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenCreate(false)}>
          Create Storing Ticket
        </ModalHeader>
        <ModalBody>
          <StoringTicketAddEdit
            onClose={() => setModalOpenCreate(false)}
            onRefresh={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>

      
      <Modal
        size="md"
        isOpen={modalOpenEdit}
        toggle={() => setModalOpenEdit(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(false)}>
          Edit Storing Ticket
        </ModalHeader>
        <ModalBody>
          <StoringTicketAddEdit
            initialValues={modalOpenEdit}
            onClose={() => setModalOpenEdit(false)}
            onRefresh={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>
      
      <Modal
        size="md"
        isOpen={!!modalOpenDelete}
        toggle={() => setModalOpenDelete(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenDelete(null)}>
          Delete Storing Ticket
        </ModalHeader>
        <ModalBody>
          <StoringTicketDelete
            initialValues={modalOpenDelete}
            onClose={() => setModalOpenDelete(null)}
            onReload={() => setGettingTripList(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default StoringTicket;

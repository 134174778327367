import React, { useEffect, useState } from "react";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { MECHANIC_FLEET_SCAN_LIST } from "../../../../../redux/config/links";
import * as yup from "yup";
import { Button, Col, FormFeedback, FormGroup, Label } from "reactstrap";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import Select from "react-select";
import {
  MAINTENANCE_MECHANIC_INPUT,
  MAINTENANCE_MECHANIC_EDIT,
} from "../../../../../redux/config/storing.links";
import useForm from "../../../../../hooks/useForm";

const MechanicAdd = ({
  onClose,
  onReload,
  initialValues = null,
  isEdit = false,
}) => {
  const [isGettingMechanicScan, setIsGettingMechanicScan] = useState(true);
  const [
    onMechanicScanList,
    { data: mechanicScanList, loading: loadingMechanicScanList },
  ] = useLazyFetch({
    url: MECHANIC_FLEET_SCAN_LIST,
    method: "post",
  });
  const [onAddMechanic] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_INPUT,
    method: "post",
  });
  const [onEditMechanic] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_EDIT,
    method: "post",
  });

  const validation = () => {
    let selectYup = yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable();
    return yup.object().shape({
      // mechanic: selectYup.required("Mechanic is required!"),
      mechanic_fleet_scan: selectYup.required("Mechanic is required!"),
    });
  };

  const {
    handleSubmit,
    errors,
    values,
    handleReset,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues: {
      mechanic:
        !!initialValues && !!initialValues.id_mechanic
          ? {
              value: initialValues.id_mechanic,
              label: initialValues.mechanic_first_name,
            }
          : null,
      mechanic_fleet_scan:
        !!initialValues && !!initialValues.id_mechanic_fleet_scan
          ? {
              value: initialValues.id_mechanic_fleet_scan,
              label: initialValues.scanned_qr_id,
            }
          : null,
    },
    validation: validation(),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (!isEdit) {
      onAddMechanic(
        {
          body: {
            id_mechanic: values.mechanic.value,
            id_mechanic_fleet_scan: values.mechanic_fleet_scan.value,
          },
        },
        () => {
          setSubmitting(false);
          onClose();
          onReload();
        }
      );
    } else {
      onEditMechanic(
        {
          body: {
            id_mechanic: values.mechanic.value,
            id_mechanic_fleet_scan: values.mechanic_fleet_scan.value,
            id: initialValues.id,
          },
        },
        () => {
          setSubmitting(false);
          onClose();
          onReload();
        }
      );
    }
  };

  useEffect(() => {
    if (isGettingMechanicScan) {
      setIsGettingMechanicScan(false);
      onMechanicScanList({});
    }
  }, [isGettingMechanicScan, onMechanicScanList]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Mechanic Fleet Scan</Label>
          </Col>
          <Col xs="12" md="9">
            <LoadingContent
              loading={loadingMechanicScanList}
              onReload={() => setIsGettingMechanicScan(true)}
            >
              <div
                className={`${
                  !!errors.mechanic_fleet_scan ? "is-invalid" : ""
                }`}
              >
                <Select
                  value={values.mechanic_fleet_scan}
                  onChange={(value) => {
                    if (value) {
                      setFieldValue("mechanic_fleet_scan", value);
                      setFieldValue("mechanic", {
                        label: value.mechanic_first_name,
                        value: value.id_mechanic,
                      });
                    } else {
                      setFieldValue("mechanic_fleet_scan", null);
                      setFieldValue("mechanic", null);
                    }
                  }}
                  options={(mechanicScanList
                    ? mechanicScanList.result
                    : []
                  ).map((item) => ({
                    label: item.scanned_qr_id,
                    value: item.id,
                    mechanic_first_name: item.mechanic_first_name,
                    id_mechanic: item.id_mechanic,
                  }))}
                  disabled={isSubmitting}
                  placeholder={"Choose Mechanic Fleet Scan"}
                />
              </div>
            </LoadingContent>
            <FormFeedback>{errors.mechanic_fleet_scan}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Mechanic</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={values.mechanic}
              onChange={(value) => {
                setFieldValue("mechanic", value);
              }}
              options={[]}
              disabled={true}
              placeholder={"Choose mechanic"}
            />
            <FormFeedback>{errors.mechanic}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            color="primary"
            className="mr-1"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Wait..." : !isEdit ? "Save Changes" : "Edit"}
          </Button>
          <Button
            type="button"
            color="warning"
            className="mr-1"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >
            Close
          </Button>
          <Button
            type="reset"
            color="danger"
            className="mr-1"
            disabled={isSubmitting}
            onClick={handleReset}
          >
            Reset
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

export default MechanicAdd;

import React, { Component } from 'react'
import {
    Button,
    FormGroup,
    Label,
    Col,
    FormFeedback
} from 'reactstrap'
import axios from 'axios';
import * as Yup from 'yup'
import { Formik } from 'formik'
import {toast} from 'react-toastify'
import handleErrorResponseCode from '../../../helpers/error-response-code-handler'
import {CHECK_SHEET_BINDING_DO_ADD_URL, CHECK_SHEET_BINDING_DO_DELETE_URL, CHECK_SHEET_DO_DETAIL_INPUT}  from '../../../redux/config/links'
import { SelectField } from '../../LandLord/Widgets/SelectField'
import { BarLoader } from 'react-spinners'
import { connect } from 'react-redux'
import { fetchApprovalType, fetchCheckSheet, fetchCheckSheetBindingDO} from '../../../redux/actions/transporter/check-sheet.act'
import { fetchCheckSheetDetail } from '../../../redux/actions/transporter/check-sheet.act';

class DOChecksheetBinding extends Component {

    constructor(props){
        super(props)
        this.state={
            initialValues: this.props.initialValues,
            checkSheetsFetchInProgress: true,
            checkSheetsSelect: [],
            approvalTypeFetchInProgress: true,
            checkSheetsApprovalTypeSelect: [],
            totalCheckSheetBindingDos: -1,
            checkSheetBindingFetchInProgress: [],
            checkSheetsBindingDo: [],
            isAdd: false, isEdit: false,
            checkSheetsDetailFetchInProgress: true,
            checkSheetsDetail: [],
        }
        this.props.fetchApprovalType()
        this.props.fetchCheckSheet()
        let getIdDo = this.props.initialValues.id_do
        this.props.fetchCheckSheetBindingDO(getIdDo)
    }

    isBindingBeforeForSubmit(){
        const {totalCheckSheetBindingDos} = this.state
        if(totalCheckSheetBindingDos===0){
            this.setState({
                isAdd: true,
                isEdit: false
            })
        }
        if(totalCheckSheetBindingDos>0){
            this.setState({
                isAdd:false,
                isEdit: true
            })
        }
    }

    isBindingBeforeForInitialValues(){
        const {checkSheetsBindingDo} = this.state
        if(checkSheetsBindingDo.length>0){
            const {initialValues} = this.state
            let changedInitialValues = initialValues
            let checkSheetsBindingDoFirstData = checkSheetsBindingDo[0]
            changedInitialValues.id = checkSheetsBindingDoFirstData.id
            changedInitialValues.id_check_sheet = checkSheetsBindingDoFirstData.id_check_sheet
            changedInitialValues.id_check_sheet_approval_type = checkSheetsBindingDoFirstData.id_check_sheet_approval_type
            changedInitialValues.binding = 1
            this.setState({
                initialValues: changedInitialValues
            })
        }
    }

    componentDidUpdate(prevProps){
        const {checkSheetsFetchInProgress, approvalTypeFetchInProgress} = this.props
        const {checkSheetBindingFetchInProgress, checkSheetsBindingDo} = this.props
        const {totalCheckSheetBindingDos} = this.state
        const {checkSheetsSelect, checkSheetsApprovalTypeSelect} = this.props
        const {checkSheetsDetail, checkSheetsDetailFetchInProgress} = this.props
        if(prevProps.checkSheetsFetchInProgress !== checkSheetsFetchInProgress){
            this.setState({
                checkSheetsFetchInProgress: checkSheetsFetchInProgress
            })
        }
        if(prevProps.checkSheetsSelect !== checkSheetsSelect){
            this.setState({
                checkSheetsSelect: checkSheetsSelect
            })
        }
        if(prevProps.approvalTypeFetchInProgress !== approvalTypeFetchInProgress){
            this.setState({
                approvalTypeFetchInProgress: approvalTypeFetchInProgress
            })
        }
        if(prevProps.checkSheetsApprovalTypeSelect !== checkSheetsApprovalTypeSelect){
            this.setState({
                checkSheetsApprovalTypeSelect: checkSheetsApprovalTypeSelect
            })
        }
        if(prevProps.checkSheetBindingFetchInProgress !== checkSheetBindingFetchInProgress){
            this.setState({
                checkSheetBindingFetchInProgress: checkSheetBindingFetchInProgress
            })
        }
        if(prevProps.totalCheckSheetBindingDos !== totalCheckSheetBindingDos){
            this.setState({
                totalCheckSheetBindingDos: prevProps.totalCheckSheetBindingDos
            }, () => {
                this.isBindingBeforeForSubmit()
            })
        }
        if(prevProps.checkSheetsBindingDo !== checkSheetsBindingDo){
            this.setState({
                checkSheetsBindingDo: checkSheetsBindingDo
            }, () => {
                this.isBindingBeforeForInitialValues()
            })
        }
        if(prevProps.checkSheetsDetailFetchInProgress !== checkSheetsDetailFetchInProgress){
            this.setState({
                checkSheetsDetailFetchInProgress: checkSheetsDetailFetchInProgress
            })
        }
        if(prevProps.checkSheetsDetail !== checkSheetsDetail){
            this.setState({
                checkSheetsDetail: checkSheetsDetail
            })
        }
    }

    validation = () => {
        let yup = Yup.object().shape({
                        id_check_sheet: Yup.number().required('Check sheet required'),
                        id_check_sheet_approval_type: Yup.number().required('Approval type required'),
                    })
        return yup
    }

    form = () => {
        const {initialValues} = this.state
        const {isAdd, isEdit} = this.state
        const {closeForm} = this.props
        const {checkSheetsSelect, checkSheetsFetchInProgress, checkSheetsDetail,
                checkSheetsApprovalTypeSelect, approvalTypeFetchInProgress} = this.state
        return(
            <Formik
            validateOnMount
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                let auth = loginCreds.token;
                setSubmitting(true)
                if(isAdd){
                    axios({
                            method: 'post',
                            url: CHECK_SHEET_BINDING_DO_ADD_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : values
                        }).then((response) => {
                            if(response.data.statusCode === 201){
                                let fetchFleetByAllRequest = []
                                for(let data of checkSheetsDetail){
                                    fetchFleetByAllRequest.push(
                                        axios({
                                            method: 'post',
                                            url: CHECK_SHEET_DO_DETAIL_INPUT,
                                            headers: {
                                                Authorization: auth
                                            },
                                            data:{
                                                id_check_sheet_detail: data.id,
                                                id_check_sheet_do: response.data.result.id
                                            }
                                        })
                                    )
                                }
                                Promise.all([...fetchFleetByAllRequest])
                                .then(() => {
                                    setSubmitting(false)
                                    toast.success('Check sheet binding has been saved succesfully!')
                                    this.props.refresh()
                                    closeForm()
                                })   
                                .catch(function(error){
                                    setSubmitting(false)
                                    toast.error(handleErrorResponseCode(error))
                                })
                            }
                        })
                    .catch(function(error){
                        setSubmitting(false)
                        toast.error(handleErrorResponseCode(error))
                    })
                }

                if(isEdit){
                    axios({
                            method: 'post',
                            url: CHECK_SHEET_BINDING_DO_DELETE_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : values
                        }).then((response) => {
                            if(response.data.statusCode === 200){
                                return true
                            }
                            throw new Error(500)
                        }).then(() => {
                            axios({
                                method: 'post',
                                url: CHECK_SHEET_BINDING_DO_ADD_URL,
                                headers: {
                                    Authorization: auth,
                                },
                                data : values
                            }).then((response) => {
                                if(response.data.statusCode === 201){
                                    setSubmitting(false)
                                    toast.success('Check sheet binding has been edited succesfully!')
                                    closeForm()
                                }
                            })
                        })
                    .catch(function(error){
                        setSubmitting(false)
                        toast.error(handleErrorResponseCode(error))
                    })
                }
                setSubmitting(false)
            }} 
            validationSchema={this.validation()}
            render={({
            values,errors,
            touched,setFieldValue,
            handleSubmit,handleBlur,
            isValid,isSubmitting,
            })=> (
                <form>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Check Sheet</Label>
                        </Col>
                        <Col xs="12" md="9">
                            {
                                checkSheetsFetchInProgress? 
                                    <center><BarLoader color={'#123abc'} loading={checkSheetsFetchInProgress}/> <br /> Loading.... Please wait...</center>
                                :
                                    <SelectField
                                        id="id_check_sheet"
                                        name="id_check_sheet"
                                        label="id_check_sheet"
                                        placeholder="Select Check Sheet to Bind"
                                        options={checkSheetsSelect}
                                        value={values.id_check_sheet}
                                        isMulti={false}
                                        onChange={async (field, selection) => {
                                            this.props.fetchCheckSheetDetail(selection.value)
                                            setFieldValue(field, selection.value, true);
                                        }}
                                        onBlur={handleBlur}
                                        touched={touched.id_check_sheet}
                                        error={errors.id_check_sheet}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                        disabled={isSubmitting}
                                    />
                            }
                        </Col>
                        <FormFeedback>{errors.id_check_sheet}</FormFeedback>
                        </FormGroup>
                            <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Approval Type</Label>
                            </Col>
                            <Col xs="12" md="9">
                                {
                                    approvalTypeFetchInProgress? 
                                        <center><BarLoader color={'#123abc'} loading={approvalTypeFetchInProgress}/> <br /> Loading.... Please wait...</center>
                                    :
                                        <SelectField
                                            id="id_check_sheet_approval_type"
                                            name="id_check_sheet_approval_type"
                                            label="id_check_sheet_approval_type"
                                            placeholder="Select Check Sheet Approval Type"
                                            options={checkSheetsApprovalTypeSelect}
                                            value={values.id_check_sheet_approval_type}
                                            isMulti={false}
                                            onChange={async (field, selection) => {
                                                setFieldValue(field, selection.value, true);
                                            }}
                                            onBlur={handleBlur}
                                            touched={touched.id_check_sheet_approval_type}
                                            error={errors.id_check_sheet_approval_type}
                                            isClearable={false}
                                            backspaceRemovesValue={false}
                                            disabled={isSubmitting}
                                        />
                                }
                            </Col>
                            <FormFeedback>{errors.id_check_sheet_approval_type}</FormFeedback>
                            </FormGroup>
                        <FormGroup>
                        <Button type="button" onClick={handleSubmit} color="primary" className="mr-1" disabled={!isValid || isSubmitting}>{isSubmitting ? 'Wait...' : 'Submit'}</Button>
                        <Button type="button" color="warning" className="mr-1" disabled={isSubmitting} onClick={closeForm}>Close</Button>
                    </FormGroup>
                </form>
                )}
            />
        )
    }

    render(){
        const {checkSheetBindingFetchInProgress} = this.state
        return(
            <React.Fragment>
                {checkSheetBindingFetchInProgress? 
                    <center>
                        <BarLoader color={'#123abc'} loading={checkSheetBindingFetchInProgress}/> 
                            <br /> Loading.... Please wait...
                    </center>
                        :
                     this.form()
                }
            </React.Fragment>
        )
    }

}
const mapStateToProps = (state) => ({
    checkSheetsSelect: state.checkSheetsStore.checkSheetsSelect,
    checkSheetsFetchInProgress: state.checkSheetsStore.inProgress,
    checkSheetsApprovalTypeSelect: state.checkSheetsApprovalTypeStore.checkSheetsApprovalTypeSelect,
    approvalTypeFetchInProgress: state.checkSheetsApprovalTypeStore.inProgress,
    totalCheckSheetBindingDos: state.checkSheetBindingDoStore.totalCheckSheetBindingDos,
    checkSheetBindingFetchInProgress: state.checkSheetBindingDoStore.inProgress,
    checkSheetsBindingDo: state.checkSheetBindingDoStore.checkSheetsBindingDo,
    checkSheetsDetail: state.checkSheetsDetailStore.checkSheetsDetail,
    checkSheetsDetailFetchInProgress: state.checkSheetsDetailStore.inProgress
})
export default connect(mapStateToProps, {fetchApprovalType, fetchCheckSheet, fetchCheckSheetBindingDO, fetchCheckSheetDetail})(DOChecksheetBinding);
import React from "react";
import {
  GoogleMap,
  KmlLayer,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";

import { compose } from "recompose";

function generateRandom() {
  return Math.random() * 10000000000000000;
}

const MapWithAMarkerB = compose(
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      defaultZoom={1}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
    >
      <KmlLayer
        url={props.kmlLink + "&ver=" + generateRandom()}
        options={{ preserveViewport: true }}
      />
      {/* {props.markers.map(marker => { */}
      {/* const onClick = props.onClick.bind(this, marker)
            return ( */}
      <Marker
        key={props.id}
        //   onClick={onClick}
        // options={{icon: customMarker}}
        position={{ lat: props.lat, lng: props.lng }}
      ></Marker>
      {/* ) */}
      {/* // })} */}
    </GoogleMap>
  );
});

export default MapWithAMarkerB;

import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col
} from 'reactstrap'
import { connect } from 'react-redux'
import SummaryWidgets from '../../../../../Widgets/SummaryWidgets';
import OperationalHourDatatable from './OperationalHourDatatable';
import { fetchWorkshopOperationalHour } from '../../../../../../redux/actions/transporter/workshopOperationalHour.act';

class OperationalHour extends Component{

    constructor(props) {
        super(props);
        this.state = { 
            operationalHourFetchInProgress: true,
            totalOperationalHours: 0,
            operationalHour: [],
        }
        const {id_workshop} = this.props
        this.props.fetchWorkshopOperationalHour(id_workshop)
    }

    componentDidUpdate(prevProps){
        const {operationalHour, totalOperationalHours, operationalHourFetchInProgress} = this.props
        if(prevProps.operationalHourFetchInProgress !== operationalHourFetchInProgress){
            this.setState({
                operationalHourFetchInProgress: operationalHourFetchInProgress
            })
        }
        if(prevProps.operationalHour !== operationalHour){
            this.setState({
                operationalHour: operationalHour
            })
        }
        if(prevProps.totalOperationalHours !== totalOperationalHours){
            this.setState({
                totalOperationalHours: totalOperationalHours
            })
        }
    }

    fetchRefresh = () => {
        const {id_workshop} = this.props
        this.props.fetchWorkshopOperationalHour(id_workshop)
    }

    initialLoad = () => {
        const {operationalHourFetchInProgress, totalOperationalHours} = this.state
        if(operationalHourFetchInProgress){
            return {isComplete: false}
        }
        if((totalOperationalHours === 0) && !operationalHourFetchInProgress){
            return {isComplete: true}
        }
        return {isComplete: true}
    }

    render(){
        const {totalOperationalHours, operationalHour} = this.state
        const {id_workshop} = this.props
        return(
            <>
                <div className="animated fadeIn">
                    <Row>
                        <Col md={3} xs={12}>
                            <SummaryWidgets header={totalOperationalHours} 
                                            mainText={'Total Operational Hour Data'} 
                                            icon={'fa fa-user'} 
                                            color={'success'}/>
                        </Col>
                    </Row>
                    <Card>
                        <CardHeader>
                            Available Operational Hour
                        </CardHeader>
                        <CardBody>
                            <OperationalHourDatatable
                                initialLoad={this.initialLoad()} operationalHour={operationalHour}
                                id_workshop={id_workshop} 
                                fetchRefresh={this.fetchRefresh} />
                        </CardBody>
                    </Card>
                </div>
                {/* <ToastContainer position="bottom-right" autoClose={7500} style={{zIndex: 7000}}/> */}
            </>
        )
    }

}
const mapStateToProps = (state) => ({
    totalOperationalHours: state.workshopOperationalHourStore.workshopOperationalHourTotal,
    operationalHour: state.workshopOperationalHourStore.workshopOperationalHourListData,
    operationalHourFetchInProgress: state.workshopOperationalHourStore.inProgress
})
export default connect(mapStateToProps, {fetchWorkshopOperationalHour})(OperationalHour);
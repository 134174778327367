import React, { Component } from 'react';
import { Input } from 'reactstrap';

class DriversList extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            value: this.props.isEdit? this.props.state.value : "",
            name: ""
        };
    }
    
    handleChange(e){
        this.setState({
            value: e.target.value
        })
        if(this.props.isDriverBindingOn)
            this.props.onDriverSelected(e.target.value)
        // console.log(this.state.value)
    }

    render() {
        let drivers = this.props.state.drivers;
        // console.log(drivers);
        let optionItems = drivers.map ((driver) =>
            <option value={driver.id}>{driver.name}</option>
        );

        return (
            <div className="animated fadeIn">
                <Input type="select" name="select" id="addDriverSelect" value={this.state.value} onChange={this.handleChange.bind(this)}>
                    <option value="">Please select driver</option>
                    {optionItems}
                </Input>
            </div>
        );
    }
}

export default DriversList;
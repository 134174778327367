import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import {connect} from 'react-redux'
import { BarLoader } from 'react-spinners';
import { fetchSettingList } from '../../../../redux/actions/aUser-SettingList'
import EmailSwitches from './EmailSwitches';
import { AbilityContext} from '../../../../casl/Can';

class TransporterReminder extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    UNSAFE_componentWillMount = () => {
        this.props.fetchSettingList()
    }

    showContent = () => {
        const { settingListData } = this.props;
        return (
            <div>
                {
                    settingListData.result.map(setting => {
                         
                        if ((setting.value === "1") && !this.context.can("view","disconnected_report_switch@settings_subs") ) return ()=>{};

                        if ((setting.value === "2") && !this.context.can("view","daily_report_switch@settings_subs")) return ()=>{};
                        
                        return (
                            <div>
                                <EmailSwitches key={setting.id} name={setting.setting} value={setting.status} id={setting.id} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        return (
            <div>
                <Card>
                    <CardHeader>
                        Transporter Settings
                    </CardHeader>
                    <CardBody>
                    {
                        this.props.inProgress ? 
                        <center><BarLoader color={'#123abc'} loading={this.props.inProgress}/> <br /> Loading.... Please wait...</center>
                        : 
                        (
                            this.props.successSettingList ? 
                            this.showContent() 
                            : 
                            <center>Error fetching your data... <br/><br/> 
                                <Button className="btn btn-primary" color="primary" size="md" disabled={this.props.inProgress} onClick={() => this.props.fetchSettingList()}>
                                    <i className="fa fa-refresh"></i> 
                                    Try again
                                </Button>
                            </center>
                        )
                    }
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settingListData: state.settingStore.settingListData,
        inProgress: state.settingStore.inProgress,
        successSettingList: state.settingStore.success

        
      }
}

TransporterReminder.contextType = AbilityContext;
export default connect(mapStateToProps, {fetchSettingList})(TransporterReminder);
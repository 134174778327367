import React, { Component } from "react";
import { Button, FormGroup, Label, Input, Col, FormFeedback } from "reactstrap";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import handleErrorResponseCode from "../../../../../../../helpers/error-response-code-handler";
import {
  WORKSHOP_OPERATIONAL_HOUR_ADD_URL,
  WORKSHOP_OPERATIONAL_HOUR_EDIT_URL,
} from "../../../../../../../redux/config/links";
import { SelectField } from "../../../../../../LandLord/Widgets/SelectField";
import { FaCheckCircle } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import CustomToast from "../../../../../../../components/CustomToast/CustomToast";

class AddEditMaintenanceCategoriesForm extends Component {
  validation = () => {
    let yup = Yup.object().shape({
      day: Yup.string().required("Day is required!"),
      start_time: Yup.string().required("Start time is required!"),
      break_time: Yup.string().required("break time is required!"),
      end_time: Yup.string().required("End time is required!"),
    });
    return yup;
  };

  form = () => {
    const { initialValues } = this.props;
    const { isAdd, isEdit } = this.props;
    const { fetchRefresh, closeForm } = this.props;
    return (
      <Formik
        validateOnMount
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
          let auth = loginCreds.token;
          setSubmitting(true);
          if (isAdd) {
            axios({
              method: "post",
              url: WORKSHOP_OPERATIONAL_HOUR_ADD_URL,
              headers: {
                Authorization: auth,
              },
              data: values,
            })
              .then((response) => {
                if (response.data.statusCode === 201) {
                  setSubmitting(false);
                  toast.success(
                    <CustomToast
                      text="Operational hour has been saved succesfully!"
                      icon={<FaCheckCircle />}
                    />,
                    {
                      autoClose: 3000,
                    }
                  );
                  closeForm();
                  fetchRefresh();
                }
              })
              .catch(function (error) {
                setSubmitting(false);
                toast.error(
                  <CustomToast
                    text={handleErrorResponseCode(error)}
                    icon={<BiSolidError />}
                  />,
                  {
                    autoClose: 3000,
                  }
                );
              });
          }

          if (isEdit) {
            axios({
              method: "post",
              url: WORKSHOP_OPERATIONAL_HOUR_EDIT_URL,
              headers: {
                Authorization: auth,
              },
              data: values,
            })
              .then((response) => {
                if (response.data.statusCode === 200) {
                  setSubmitting(false);
                  toast.success(
                    <CustomToast
                      text="Operational hour has been edited succesfully!"
                      icon={<FaCheckCircle />}
                    />,
                    {
                      autoClose: 3000,
                    }
                  );
                  closeForm();
                  fetchRefresh();
                }
              })
              .catch(function (error) {
                setSubmitting(false);
                toast.error(
                  <CustomToast
                    text={handleErrorResponseCode(error)}
                    icon={<BiSolidError />}
                  />,
                  {
                    autoClose: 3000,
                  }
                );
              });
          }
          setSubmitting(false);
        }}
        validationSchema={this.validation()}
        render={({
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          handleBlur,
          handleChange,
          isValid,
          isSubmitting,
        }) => (
          <form>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Days</Label>
              </Col>
              <Col xs="12" md="9">
                <SelectField
                  id="day"
                  name="day"
                  label="day"
                  placeholder="Select Day"
                  options={[
                    { label: "Monday", value: "Monday" },
                    { label: "Tuesday", value: "Tuesday" },
                    { label: "Wednesday", value: "Wednesday" },
                    { label: "Thursday", value: "Thursday" },
                    { label: "Friday", value: "Friday" },
                    { label: "Saturday", value: "Saturday" },
                    { label: "Sunday", value: "Sunday" },
                  ]}
                  value={values.day}
                  isMulti={false}
                  onChange={(field, selection) => {
                    setFieldValue(field, selection.value, true);
                  }}
                  onBlur={handleBlur}
                  touched={touched.day}
                  error={errors.day}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  disabled={isSubmitting}
                />
              </Col>
              <FormFeedback>{errors.day}</FormFeedback>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Start Time</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="start_time"
                  id="start_time"
                  placeholder="Start Time"
                  autoComplete="start_time"
                  valid={!errors.start_time}
                  invalid={touched.start_time && !!errors.start_time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.start_time || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.start_time}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Break Time</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="break_time"
                  id="break_time"
                  placeholder="Break Time"
                  autoComplete="break_time"
                  valid={!errors.break_time}
                  invalid={touched.break_time && !!errors.break_time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.break_time || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.break_time}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">End Time</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="end_time"
                  id="end_time"
                  placeholder="End Time"
                  autoComplete="end_time"
                  valid={!errors.end_time}
                  invalid={touched.end_time && !!errors.end_time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.end_time || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.end_time}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Description</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="textarea"
                  name="desc"
                  id="desc"
                  placeholder="Description"
                  valid={!errors.desc}
                  invalid={touched.desc && !!errors.desc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.desc || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.desc}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup>
              <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="mr-1"
                disabled={!isValid || isSubmitting}
              >
                {isSubmitting ? "Wait..." : "Submit"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={isSubmitting}
                onClick={closeForm}
              >
                Close
              </Button>
            </FormGroup>
          </form>
        )}
      />
    );
  };

  render() {
    return <React.Fragment>{this.form()}</React.Fragment>;
  }
}
export default AddEditMaintenanceCategoriesForm;

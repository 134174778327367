import React, { Component, useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import axios from "axios";
// import {Gmaps, Marker, Circle, InfoWindow} from 'react-gmaps';
import * as API_LINKS from "../../../../redux/config/links.jsx";
import { toast } from "react-toastify";
import TriPLLiveMonitoringDatatable from "./TriPLLiveMonitoring-Datatable";
import { compose } from "recompose";
import customMarkerIdle from "../../../../assets/img/truckidle.png";
import customMarkerMoving from "../../../../assets/img/truckmoving.png";
import { fetchCompanyList } from "../../../../redux/actions/aUser-CompanyList";
import { fetchSettingList } from "../../../../redux/actions/aUser-SettingList";
import { connect } from "react-redux";
import home from "../../../../assets/img/home.png";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  KmlLayer,
  TrafficLayer,
} from "react-google-maps";

function generateRandom() {
  return Math.random() * 10000000000000000;
}

const logoUrlToIcon = function (logoUrl) {
  let icon = {
    url: logoUrl,
    scaledSize: new window.google.maps.Size(65, 30),
  };
  return icon;
};

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)((props) => {
  // console.log(props.kmlLink)
  // const onClickCompany = props.onClick.bind(this, props.companyMarker)
  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleMarkerClick = (marker) => {
    // Toggle selectedMarker
    setSelectedMarker((prevMarker) =>
      prevMarker && prevMarker.id === marker.id ? null : marker
    );
    // Optionally call props.onClick if needed
    props.onClick(marker);
  };
  return (
    <GoogleMap
      defaultZoom={7}
      defaultCenter={{ lat: -7.6221790314, lng: 110.8043441772 }}
    >
      <KmlLayer
        url={props.kmlLink + "&ver=" + generateRandom()}
        options={{ preserveViewport: true }}
      />
      <TrafficLayer autoUpdate />
      <Marker
        key={"C0001"}
        onClick={() => handleMarkerClick(props.companyMarker)}
        options={{ icon: home }}
        position={{
          lat: props.companyMarker.lat,
          lng: props.companyMarker.lng,
        }}
      >
        {selectedMarker && selectedMarker.id === props.companyMarker.id ? (
          <InfoWindow>
            <div style={{ maxWidth: "250px" }}>
              <b>{props.companyMarker.data.company}</b>
              <hr />
              {props.companyMarker.data.company_address}
            </div>
          </InfoWindow>
        ) : null}
      </Marker>
      {props.markers.map((marker) => {
        const onClick = props.onClick.bind(this, marker);
        return (
          <Marker
            key={marker.id}
            onClick={onClick}
            options={
              marker.wrn > 0
                ? marker.spd > 3.5
                  ? {
                      icon: marker.logo
                        ? logoUrlToIcon(marker.logo)
                        : customMarkerMoving,
                    }
                  : {
                      icon: marker.logo
                        ? logoUrlToIcon(marker.logo)
                        : customMarkerIdle,
                    }
                : {
                    icon: marker.logo
                      ? logoUrlToIcon(marker.logo)
                      : customMarkerIdle,
                  }
            }
            // options={{icon: customMarkerIdle}}
            position={{ lat: marker.lat, lng: marker.lng }}
          >
            {props.selectedMarker.fleet_id &&
              props.selectedMarker.fleet_id === marker.fleet_id && (
                <InfoWindow>
                  <div>
                    <b>
                      {marker.delivery_id} - {marker.wo}
                    </b>
                    <hr />
                    Company: {marker.company}
                    <br />
                    Destination : {marker.destination}
                    <br />
                    Reg. No: {marker.fleet_id}
                    <br />
                    Speed: {marker.spd} KM/h
                    <br />
                    Last Update: {marker.time}
                  </div>
                </InfoWindow>
              )}
          </Marker>
        );
      })}
    </GoogleMap>
  );
});

var refreshInterval;

class TriPLLiveMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      liveMonitoringData: {},
      liveMonitoringWoData: {},
      mapsMarkerData: [],
      markers: [],
      selectedMarker: false,
      success: false,
    };
  }

  UNSAFE_componentWillMount = () => {
    this.props.fetchCompanyList();
    this.props.fetchSettingList();
    this.getLiveMonitoringData();
    refreshInterval = setInterval(() => {
      this.getLiveMonitoringData();
    }, 300000);
  };

  componentWillUnmount() {
    clearInterval(refreshInterval);
  }

  getLiveMonitoringData = () => {
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    this.setState({
      isLoading: true,
      success: false,
    });

    axios({
      method: "post",
      url: API_LINKS.TRIPL_LIVE_MONITORING_URL, //Should Change Link later
      headers: {
        Authorization: auth,
      },
      data: {
        type: "request",
      },
    })
      .then((response) => {
        // console.log(response.data)
        self.setState({
          liveMonitoringData: response.data.result,
          liveMonitoringWoData: response.data.work_order,
          isLoading: false,
          success: true,
        });
        self.mapsMarkertoArray();
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({
              isLoading: false,
              success: false,
            });
            return toast.error(
              "Invalid username or password! Please try again!"
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({
              isLoading: false,
              success: false,
            });
            return toast.error(
              "(3PL Live Monitoring)  Server cannot be contacted! Please ask your system administrator!"
            );
          } else if (error.response.status === 400) {
            self.setState({
              isLoading: false,
              success: false,
            });
            // return toast.error(`(3PL Live Monitoring) ${error.response.data.message}`);
          } else {
            self.setState({
              isLoading: false,
              success: false,
            });
            return toast.error(
              "(3PL Live Monitoring) Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          self.setState({
            isLoading: false,
            success: false,
          });
          return toast.error(
            "(3PL Live Monitoring)  Request error! Please try again later..."
          );
        } else {
          self.setState({
            isLoading: false,
            success: false,
          });
          return toast.error(
            "(3PL Live Monitoring) Something went wrong... Please try again later..."
          );
        }
      });
  };

  isWoCanbeLoaded = (delIveryIdObj, delIveryId, currentWoSeq) => {
    if (!delIveryIdObj[delIveryId]) return true;

    let lastWoSeq = delIveryIdObj[delIveryId].wo_seq;
    if (lastWoSeq > currentWoSeq) return true;

    return false;
  };

  onIdFleetClicked = (row) => {
    row.fleet_id = row.fleet_plate;
    this.setState({ selectedMarker: row });
  };

  mapsMarkertoArray = () => {
    var list = [];
    const { liveMonitoringWoData } = this.state;
    if (this.state.liveMonitoringData.length !== 0) {
      var liveMonitoringData = this.state.liveMonitoringData;
      let delIveryIdObj = {};
      liveMonitoringData.forEach((p, index) => {
        let deliveryId = liveMonitoringWoData[index].delivery_id;
        let woSeq = liveMonitoringWoData[index].wo_seq;
        if (
          (liveMonitoringData[index].lat !== null ||
            liveMonitoringData[index].lng !== null) &&
          this.isWoCanbeLoaded(delIveryIdObj, deliveryId, woSeq)
        ) {
          var data = {
            id: liveMonitoringData[index].id,
            company: liveMonitoringWoData[index].company,
            logo: liveMonitoringWoData[index].link_logo,
            wo: liveMonitoringWoData[index].wo_number,
            destination: liveMonitoringWoData[index].wo_destination_name,
            fleet_id: liveMonitoringWoData[index].fleet_plate,
            lat: liveMonitoringData[index].lat,
            lng: liveMonitoringData[index].lng,
            spd: liveMonitoringData[index].spd,
            wrn: liveMonitoringData[index].wrn,
            time: liveMonitoringData[index].time,
            delivery_id: liveMonitoringWoData[index].delivery_id,
          };
          delIveryIdObj[deliveryId] = { wo_seq: woSeq };
          list.push(data);
        }
      });
    }
    this.setState({ markers: list });
    this.setState({
      isLoading: false,
    });
  };

  // drawMarker = () => {
  //     const {mapsMarkerData} = this.state

  //     return (
  //         <div>
  //             {this.state.markers.map((marker, index) => (
  //             // <Marker key={index} lat={marker[0]} lng={marker[1]} />
  //             <InfoWindow
  //                 key={index}
  //                 lat={marker[0].lat}
  //                 lng={marker[1].lng}
  //                 content={marker[3].fleet_id}
  //                 />
  //             ))}
  //         </div>
  //     )
  // }

  handleClick = (marker, event) => {
    this.setState({ selectedMarker: marker });
  };

  getCompanyData = () => {
    const { companyListData } = this.props;
    if (companyListData.result) {
      return companyListData.result[0];
    }
    return null;
  };

  render() {
    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody className="m-0 p-0">
            <div>
              {
                // this.state.isLoading ?
                // <center><BarLoader color={'#123abc'} loading={this.state.isLoading}/> <br /> Loading.... Please wait...</center>
                // :
                // (
                // this.state.success ?
                <div>
                  {this.props.statKML ? (
                    <MapWithAMarker
                      selectedMarker={this.state.selectedMarker}
                      companyMarker={{
                        data: this.getCompanyData(),
                        lat: this.props.companyLat,
                        lng: this.props.companyLng,
                      }}
                      markers={this.state.markers}
                      onClick={this.handleClick}
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `400px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      kmlLink={this.props.kml}
                    />
                  ) : (
                    <MapWithAMarker
                      selectedMarker={this.state.selectedMarker}
                      companyMarker={{
                        data: this.getCompanyData(),
                        lat: this.props.companyLat,
                        lng: this.props.companyLng,
                      }}
                      markers={this.state.markers}
                      onClick={this.handleClick}
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `400px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      kmlLink={""}
                    />
                  )}
                </div>
                //      :
                //     <center>Error fetching your data... <br/><br/>
                //         <Button className="btn btn-primary" color="primary" size="md" disabled={this.state.isLoading} onClick={() => this.getLiveMonitoringData()}>
                //             <i className="fa fa-refresh"></i>
                //             Try again
                //         </Button>
                //     </center>
                // )
              }
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <b>3PL Live Monitoring Details</b>
          </CardHeader>
          <CardBody>
            <TriPLLiveMonitoringDatatable
              state={this.state}
              onIdFleetClicked={this.onIdFleetClicked}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  kml: state.companyStore.companyKML,
  companyListData: state.companyStore.companyListData,
  companyLat: state.companyStore.lat,
  companyLng: state.companyStore.lng,
  statKML: state.settingStore.kmlSet,
});

export default connect(mapStateToProps, { fetchCompanyList, fetchSettingList })(
  TriPLLiveMonitoring
);

import { CCTV_CHANGE_ACTIVE } from "src/redux/types/cctv/tCctv-Active";
  
  const defaultSaldoState = {
    active: null,
  };
  
  const cctvActive = (state = defaultSaldoState, action) => {
    switch (action.type) {
      case CCTV_CHANGE_ACTIVE:
        return {
          ...state,
          active: action.payload,
        };
      default:
        return { ...state };
    }
  };
  
  export default cctvActive;
  
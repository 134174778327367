import React, { Component } from 'react';
import { Input } from 'reactstrap';

class DriverAssistantsList extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            value: this.props.isEdit? this.props.state.value : "",
        };
    }
    
    handleChange(e){
        this.setState({
            value: e.target.value
        })
        // console.log(this.state.value)
    }

    render() {
        let driverAssistants = this.props.state.driverAssistants;
        // console.log(driverAssistants);
        let optionItems = driverAssistants.map ((driverAssistant) =>
            <option value={driverAssistant.id}>{driverAssistant.name}</option>
        );

        return (
            <div className="animated fadeIn">
                <Input type="select" name="select" id="addDriverAssistantSelect" value={this.state.value} onChange={this.handleChange.bind(this)}>
                    <option value="">Please select driver assistant</option>
                    {optionItems}
                </Input>
            </div>
        );
    }
}

export default DriverAssistantsList;
import React from 'react';

import { Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import moment from 'moment';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: './assets/fonts/Roboto-Regular.ttf',
    },
    {
      fontStyle: 'bold',
      src: './assets/fonts/Roboto-Bold.ttf',
    },
    {
      fontStyle: 'bolditalic',
      src: './assets/fonts/Roboto-BoldItalic.ttf',
    },
    {
      fontStyle: 'black',
      src: './assets/fonts/Roboto-Black.ttf',
    },
],
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
    margin: 5
  },
  invoiceToColumn: {
    flexDirection: 'column',
    flexGrow: 7,
    marginLeft: 20,
    marginTop: 5
  },
  woToColumn: {
    flexDirection: 'column',
    flexGrow: 7,
    marginLeft: 10,
    marginTop: 5
  },
  headerDetail: {
    fontSize: '11',
    fontFamily: 'Roboto',
    fontStyle: 'bold'
  },
  informationDetail: {
    fontSize: '10',
    fontFamily: 'Roboto',
  },
  fillInBlank: {
    fontSize: '10',
    fontFamily: 'Roboto',
    marginBottom: 10,
    marginTop: 5
  },
  informationDetailSmall: {
    fontSize: '7',
    fontFamily: 'Roboto',
  },
  receiptHeaderWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0
  },    
  receiptHeader: {
    fontSize: '14',
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    textAlign: 'center'
  },
  approvalHeaderWrapper: {
    flexDirection: 'row',
    alignItems: 'left',
    margin: 0
  },    
  approvalHeader: {
    fontSize: '10',
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    textAlign: 'left',
    marginLeft: 20
  },
  tableWrapper: {
    flexGrow: 1,
    flexDirection: 'row',
    margin: 30
  },
  tableWrapperApproval: {
    flexGrow: 1,
    flexDirection: 'row',
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
    marginBottom: 20
  },
  tablehandle: {
    width: '100%'
  },
  table: {
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColNumber: { 
    width: "15%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 
  tableColPackageDetails: { 
    width: "30%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableColReceiverDetails: { 
    width: "30%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableColAmount: { 
    width: "25%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: { 
    // margin: "auto", 
    margin: 5,
    textAlign: 'center',
    fontSize: 10,
    fontStyle: 'bold',
    fontFamily: 'Roboto'
  },
  tableCell: { 
    // margin: "auto", 
    margin: 5,
    fontFamily: 'Roboto',
    fontSize: 10 
  },
  tableCellBold: { 
    margin: "auto", 
    marginLeft: 5,
    marginBottom: 0,
    marginTop: 3,
    fontSize: 10,
    fontFamily: 'Roboto',
    fontStyle: 'bold'
  },
  tableColPaymentChannel: { 
    width: "30%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableColSecurity: { 
    width: "50%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableColCustomer: { 
    width: "50%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableColPaymentTime: { 
    width: "40%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableColPaymentStatus: { 
    width: "30%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  paymentSuccess: {
    // margin: "auto", 
    margin: 5,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    fontSize: 10,
    color: '#22bb33'
  },
  paymentFailed: {
    // margin: "auto", 
    margin: 5,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    fontSize: 10,
    color: '#bb2124'
  },
  footer: {
    fontSize: 7,
    marginTop: 20,
    marginBottom: 10,
    textAlign: 'center',
    color: 'grey',
  },
  qrCodeImage: {
    width: '120px',
    height: 'auto'
  },
  qrColumn: {
    flexDirection: 'column',
    flexGrow: 7,
    marginLeft: 20,
    marginTop: 10
  },
});

export default (props) => (
    <View>
        <View style={styles.container}>
            <View style={styles.invoiceToColumn}>
                <Text style={styles.headerDetail}>Recepient:</Text>
                <Text style={styles.informationDetail}>{props.main_customer ? props.main_customer : 'Invalid Main Customer'}</Text>
                <Text style={styles.informationDetail}>{props.main_customer_phone ? props.main_customer_phone : '-'}</Text> 
                <Text style={styles.informationDetail}>&nbsp;</Text>
                <Text style={styles.headerDetail}>Destination:</Text>
                <Text style={styles.informationDetail}>{props.wo_destination_name ? props.wo_destination_name : 'Invalid Destination'}</Text>
                <Text style={styles.informationDetail}>{props.wo_address ? props.wo_address : '-'}</Text>
                <Text style={styles.informationDetailSmall}>Pinpoint: {props.lat ? props.lat : '-'},{props.lng ? props.lng : '-'}</Text>
            </View>
            <View style={styles.woToColumn}>
                <Text style={styles.headerDetail}>Transporter Detail:</Text>
                <Text style={styles.informationDetail}>{props.company ? props.company : 'Invalid Transporter Company'}</Text>
                <Text style={styles.informationDetail}>{props.company_phone ? props.company_phone : 'Invalid Phone'}</Text> 
                <Text style={styles.informationDetail}>&nbsp;</Text>
                <Text style={styles.headerDetail}>Driver and Fleet</Text>
                <Text style={styles.informationDetail}>{props.driver_name ? props.driver_name : 'Invalid Driver'}</Text>
                <Text style={styles.informationDetail}>{props.fleet_plate ? props.fleet_plate : 'Invalid Fleet'}</Text>

            </View>
        </View>
        <View style={styles.receiptHeaderWrapper}>
            {/* spacer */}
            <Text style={styles.receiptHeader}> </Text>
        </View>
        <View style={styles.receiptHeaderWrapper}>
            <Text style={styles.receiptHeader}>Shipping details</Text>
        </View>
        <View style={styles.tableWrapper}>
            <View style={styles.table}>
                {/* Table Header */}
                <View style={styles.tableRow}>
                    <View style={styles.tableColNumber}>
                        <Text style={styles.tableCellHeader}>No.</Text>
                    </View>
                    <View style={styles.tableColPackageDetails}>
                        <Text style={styles.tableCellHeader}>Package Details</Text>
                    </View>
                    <View style={styles.tableColReceiverDetails}>
                        <Text style={styles.tableCellHeader}>Delivery Details</Text>
                    </View>
                    <View style={styles.tableColAmount}>
                        <Text style={styles.tableCellHeader}>Delivery Notes</Text>
                    </View>
                </View>
                {/* Content of the Table */}
                <View style={styles.tableRow}>
                    <View style={styles.tableColNumber}>
                        <Text style={styles.tableCell}>1</Text>
                    </View>
                    <View style={styles.tableColPackageDetails}>
                        {/* <Text style={styles.tableCell}>{props.wo_desc ? props.wo_desc : 'N/A'}</Text> */}
                        {/* {console.log(props.stocker_items)} */}
                        {
                          props.stocker_items.length > 0 && props.stocker_items.map((item, index) => {
                            return (
                              <Text style={styles.tableCell} key={index}>{item.item_name} [Total unit: {item.realization_qty}] | Received: _______</Text>
                            )
                          })
                        }
                    </View>
                    <View style={styles.tableColReceiverDetails}>
                        {/* WO Destination Name */}
                        <Text style={styles.tableCellBold}>Shipper</Text>
                        <Text style={styles.tableCell}>{props.company_requestor || props.non_company_requestor_name ? props.company_requestor || props.non_company_requestor_name : props.company}</Text>
                        
                        {/* Estimated Arrival Time */}
                        <Text style={styles.tableCellBold}>Estimated Arrival Time</Text>
                        <Text style={styles.tableCell}>{props.est_arrival_time ? moment(props.est_arrival_time).format('dddd, Do MMMM YYYY, hh:mm:ss') : 'Invalid ETA'}</Text>
                        {/* WO Destination Name */}
                        <Text style={styles.tableCellBold}>Receiver</Text>
                        <Text style={styles.tableCell}>{props.wo_destination_name ? props.wo_destination_name : 'Invalid Receiver'}</Text>
                        {/* Address */}
                        <Text style={styles.tableCellBold}>Receiver Address</Text>
                        <Text style={styles.tableCell}>{props.wo_address ? props.wo_address : '-'}</Text>
                        {/* Address */}
                        <Text style={styles.tableCellBold}>PIC Name</Text>
                        <Text style={styles.tableCell}>{props.pic_name ? props.pic_name : 'Invalid PIC Name'}</Text>
                    </View>
                    <View style={styles.tableColAmount}>
                        {/* <Text style={styles.tableCell}>{props.invoice_value_base ? 'Rp. ' + currStringFormatter(props.invoice_value_base) : 'Invalid Amount'}</Text> */}
                    </View>
                </View>
                {/* <View style={styles.tableRow}>
                    <View style={styles.tableColPaymentChannel}>
                        <Text style={styles.tableCellBold}>Payment Channel/Method</Text>
                        <Text style={styles.tableCell}>{props.invoice_payment_channel ? props.invoice_payment_channel : 'Invalid Channel'} ({props.invoice_payment_method ? props.invoice_payment_method : '-'})</Text>
                    </View>
                    <View style={styles.tableColPaymentTime}>
                        <Text style={styles.tableCellBold}>Payment Time</Text>
                        <Text style={styles.tableCell}>{props.invoice_payment_finish ? props.invoice_payment_finish : '-'}</Text>
                    </View>
                    <View style={styles.tableColPaymentStatus}>
                        <Text style={styles.tableCellBold}>Payment Status</Text>
                        <Text style={styles.paymentSuccess}>{props.invoice_payment_finish ? 'PAID' : 'NOT PAID'}</Text>
                    </View>
                </View> */}
            </View>
        </View>

        {/* Delivery Notes */}
        {/* <View style={styles.invoiceToColumn}>
            <Text style={styles.headerDetail}>Delivery notes:</Text>
            <Text style={styles.fillInBlank}>_________________________________________________</Text>
        </View> */}

        {/* Security and Customer Approval Details */}
        <View style={styles.approvalHeaderWrapper}>
            <Text style={styles.approvalHeader}>Approval details</Text>
        </View>
        <View style={styles.tableWrapperApproval}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                {/* Table Header */}
                <View style={styles.tableColCustomer}>
                    <Text style={styles.tableCellHeader}>Customer</Text>
                </View>
                <View style={styles.tableColSecurity}>
                    <Text style={styles.tableCellHeader}>Security</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                {/* Table Content */}
                <View style={styles.tableColCustomer}>
                    <Text style={styles.tableCell}>&nbsp;</Text>
                    <Text style={styles.tableCell}>&nbsp;</Text>
                    <Text style={styles.tableCell}>_________________________________________________________</Text>
                </View>
                <View style={styles.tableColSecurity}>
                    <Text style={styles.tableCell}>&nbsp;</Text>
                    <Text style={styles.tableCell}>&nbsp;</Text>
                    <Text style={styles.tableCell}>_________________________________________________________</Text>
                </View>
              </View>
            </View>
        </View>

        {/* Details payment */}
        <View style={styles.invoiceToColumn}>
            <Text style={styles.headerDetail}>Notes:</Text>
            <Text style={styles.informationDetail}> This receipt is computer generated and no need to be signed. Please mind the environment before printing.</Text>
        </View>

        {/* Token sudah bisa masuk */}
        <View style={styles.qrColumn}>
            <Text style={styles.headerDetail}>Verification QR:</Text>
            <Image src={props.tokenDataURI} style={styles.qrCodeImage}/>
        </View>

        <View style={styles.footer}>
            <Text>~ Generated automatically by system. Powered by automa.id. Printed on {moment().toLocaleString()} by {localStorage.AutomaEml} ~</Text>
          </View>
    </View>
);
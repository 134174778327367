import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import moment  from "moment";
import { Button, Label, FormGroup, Col } from "reactstrap";
import MapWithAMarker from "./MapWithAMarker";
import * as API_LINKS from "../../../redux/config/links";
import styles from "./SavedPlaces.module.scss";

const SavedPlacesView = ({ id, toggleCloseView, ...props }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [images, setImages] = useState(null);
  const onGetData = useCallback(() => {
    if (id) {
      const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      const auth = loginCreds.token;
      setIsLoading(true);
      axios({
        method: "post",
        url: API_LINKS.SAVED_PLACES_LIST_URL, //Please change when it's ready
        headers: {
          Authorization: auth,
        },
        data: {
          id,
        },
      })
        .then((res) => {
          if (res.data.result && res.data.result.length > 0) {
            const [response] = res.data.result;
            const dt = {
              address: response.address,
              company_name: response.company_name,
              company_phone: response.company_phone,
              created_on: response.created_on,
              customer_email: response.customer_email,
              customer_handphone: response.customer_handphone,
              customer_name: response.customer_name,
              date_approve: response.date_approve,
              email_user_approve: response.email_user_approve,
              id: response.id,
              id_company: response.id_company,
              id_customer: response.id_customer,
              id_type: response.id_type,
              id_user_approve: response.id_user_approve,
              lat: response.lat,
              lng: response.lng,
              modified_on: response.modified_on,
              name: response.name,
              public_place: response.public_place,
              review_points: response.review_points,
              review_url: response.review_url,
              reviews: response.reviews,
              status: response.status,
              type_desc: response.type_desc,
              type_name: response.type_name,
              verify_status: response.verify_status,
            };
            setData({
              ...dt,
            });
          }
          setImages([...res.data.image]);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [API_LINKS, id]);

  useEffect(() => {
    if (isFetching) {
      setIsFetching(false);
      onGetData();
    }
  }, [onGetData, isFetching]);

  return (
    <>
      <div className="container">
        {isLoading ? (
          "Loading ..."
        ) : (
          <>
            {data !== null && (
              <>
                <FormGroup row>
                  <Col xs="12" md="12">
                    <MapWithAMarker
                      // selectedMarker={this.state.selectedMarker}
                      // markers={this.state.markers}
                      lat={data.lat}
                      lng={data.lng}
                      // onClick={this.handleClick}
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `200px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      kmlLink={props.statKML ? props.kml : ""}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Places Name: </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label htmlFor="text-input">{data.name}</Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Customer Name: </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label htmlFor="text-input">{data.customer_name}</Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Customer Email: </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label htmlFor="text-input">{data.customer_email}</Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">
                      Customer Whatsapp/Phone Number:{" "}
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label htmlFor="text-input">
                      {data.customer_handphone}
                    </Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Latitude: </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label htmlFor="text-input">{data.lat}</Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Longitude: </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label htmlFor="text-input">{data.lng}</Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Approved By: </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label htmlFor="text-input">
                      {data.email_user_approve ? data.email_user_approve : "-"}
                    </Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Approved On: </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Label htmlFor="text-input">
                      {data.date_approve
                        ? moment
                            .utc(data.date_approve)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")
                        : "-"}
                    </Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Images: </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <div className={styles["image-container"]}>
                      {images &&
                        images.map((item, key) => (
                          <div
                            className={styles["image-item-container"]}
                            key={key}
                          >
                            <img
                              className={styles["image-item"]}
                              src={item.link}
                              alt={item.desc}
                            />
                          </div>
                        ))}
                    </div>
                  </Col>
                </FormGroup>
                {/* <FormGroup row>
          <Col xs="12" md="12">
              <Label htmlFor="text-input">Do you want to approve this location? </Label>
          </Col>
      </FormGroup> */}

                {/* {this.props.isLoading ? <center><BarLoader color={'#123abc'} 
        loading={this.props.isLoading} 
      /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => this.handlePlaceApproval()} size="sm" color="primary" disabled={self.state.isLoading}><i className="fa fa-check-circle-o"></i> Yes</Button> */}

                <Button
                  onClick={() => toggleCloseView()}
                  size="sm"
                  color="success"
                  disabled={isLoading}
                >
                  <i className="fa fa-window-close"></i> Close
                </Button>
                {/* </div>
      }          */}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // KML
    kml: state.companyStore.companyKML,
    statKML: state.settingStore.kmlSet,
  };
};

export default connect(mapStateToProps, {})(SavedPlacesView);

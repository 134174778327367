import React from "react";
// import DashboardDetails from '../views/Dashboard/DashboardDetails/DashboardDetails.jsx';
import WelcomeMessage from "../containers/DefaultLayout/WelcomeMessage";


//User Management Files Import
import Login from "../views/Users/Login/Login";
import Logout from "../views/Users/Logout/Logout";
import Register from "../views/Users/Register/Register";
import UserManagement from "../views/Users/Management/Management";

// Delivery Management Files Import
import DeliveryOrders from "../views/Fleet/DeliveryOrders/DeliveryOrders";
import WorkOrders from "../views/Fleet/WorkOrders/WorkOrdersNew";
import ReportDeliveryOrders from "../views/Fleet/Report-DeliveryOrders/Report-DeliveryOrders";
import { activeFeature } from "../constants/constants";



//Fleet files import
// const VehicleMonitoring = React.lazy(() => import('../views/Fleet/VehicleMonitoring/VehicleMonitoring.jsx'));
const MonitorDetails = React.lazy(() =>
  import("../views/Fleet/MonitorDetails/MonitorDetails.jsx")
);
const Drivers = React.lazy(() => import("../views/Fleet/Drivers/Drivers.jsx"));
const DriversAssistant = React.lazy(() =>
  import("../views/Fleet/DriverAssistants/DriverAssistants.jsx")
);
// const Fleets = React.lazy(() => import('../views/Fleet/Fleets/Fleets.jsx'));
const Fleets = React.lazy(() => import("../views/Fleet/Fleets/FleetsTab"));
const FleetsObdHistory = React.lazy(() =>
  import("../views/Fleet/Fleets/FleetObdHistory.jsx")
);
const FleetOperations = React.lazy(() =>
  import("../views/Fleet/FleetOperations/FleetOperations.jsx")
);
const SavedPlaces = React.lazy(() =>
  import("../views/Fleet/SavedPlaces/SavedPlaces.jsx")
);
const PublicFacility = React.lazy(() =>
  import("../views/Fleet/PublicFacility/Tabs")
);
const MainCustomer = React.lazy(() =>
  import("../views/Fleet/MainCustomer/MainCustomer.jsx")
);
const LiveMonitoring = React.lazy(() =>
  import("../views/Fleet/LiveMonitoring/LiveMonitoringTabs")
);
const LiveMonitoringCommandCenter = React.lazy(() =>
  import(
    "../views/Fleet/LiveMonitoringCommandCenter/LiveMonitoringCCWrapper"
  )
);

const Security = React.lazy(() =>
  import("../views/Fleet/Security/Security.jsx")
);
const FleetsReport = React.lazy(() =>
  import("../views/Fleet/Fleets/Fleets-Report")
);
const FuelReport = React.lazy(() =>
  import("../views/Fleet/Fleets/FuelReport/FleetFuelReport")
);
const DeliveryAudit = React.lazy(() =>
  import("../views/Fleet/DeliveryOrders/DeliveryOrderAudit/DeliveryOrderAudit")
);
const TriPLWorkOrderRequest = React.lazy(() =>
  import("../views/Fleet/3PL/Request/TriPLRequest")
);
const TriPLAdministrative = React.lazy(() =>
  import("../views/Fleet/3PL/Administrative/TriPLAdministrative")
);
const TriPLGrouping = React.lazy(() =>
  import("../views/Fleet/3PL/Grouping/TriPLGroupingTabs")
);
const GroupMembersAssign = React.lazy(() =>
  import("../views/Fleet/3PL/Grouping/GroupMembersAssign/GroupMembersAssign")
);
const WOReview = React.lazy(() =>
  import("../views/Fleet/Report/WOReview/WOReview")
);
const WOPODConfirmReview = React.lazy(() =>
  import("../views/Fleet/Report/WOPODVerif/WOPODVerif")
);
const FleetMaintenance = React.lazy(() =>
  import("../views/Fleet/FleetMaintenance/FleetMaintenanceMain.jsx")
);
const SMMechanicTask = React.lazy(() =>
  import(
    "../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMMechanicTask/SMMechanicTask.jsx"
  )
);
const SMApprovalSetting = React.lazy(() =>
  import(
    "../views/Settings/FleetMaintenanceSettings/ApprovalType/ApprovalTypeSetting"
  )
);
const SMApproval = React.lazy(() =>
  import("../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMApproval")
);
const SMMechanicTaskDetail = React.lazy(() =>
  import(
    "../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMMechanicTask/SMMechanicTaskDetail"
  )
);
const DOCategory = React.lazy(() => import("../views/Fleet/Category/Category"));
const Mechanics = React.lazy(() =>
  import("../views/Fleet/Mechanics/MechanicsMain.jsx")
);
const MechanicsCertification = React.lazy(() =>
  import(
    "../views/Fleet/Mechanics/MechanicsCertification/MechanicsCertification.jsx"
  )
);
const MechanicsSpecialization = React.lazy(() =>
  import(
    "../views/Fleet/Mechanics/MechanicsSpecialization/MechanicsSpecialization.jsx"
  )
);
const MechanicsOperationalHour = React.lazy(() =>
  import(
    "../views/Fleet/Mechanics/MechanicsOperationalHour/MechanicsOperationalHour.jsx"
  )
);
const WSMMechanicTask = React.lazy(() =>
  import(
    "../views/Fleet/FleetMaintenance/ScheduledMaintenanceWorkshop/SMMechanicTask/SMMechanicTask"
  )
);
const WSMMechanicTaskDetail = React.lazy(() =>
  import(
    "../views/Fleet/FleetMaintenance/ScheduledMaintenanceWorkshop/SMMechanicTask/SMMechanicTaskDetail"
  )
);

const Workshop = React.lazy(() =>
  import("../views/Fleet/Workshop/WorkshopMain.jsx")
);
const WorkshopOperationalHour = React.lazy(() =>
  import(
    "../views/Fleet/Workshop/WorkshopOperationalHour/WorkshopOperationalHour.jsx"
  )
);

const CurrativeOrder = React.lazy(() =>
  import("../views/Fleet/FleetMaintenance/CurrativeOrders")
);
const CheckSheet = React.lazy(() => import("../views/Fleet/CheckSheet"));
const CheckSheetDetail = React.lazy(() =>
  import("../views/Fleet/CheckSheet/MainCheckSheet/MainCheckSheetDetail")
);
const CheckSheetApprovalSetting = React.lazy(() =>
  import("../views/Fleet/CheckSheet/ApprovalType/ApprovalTypeSetting")
);
const DoCheckSheetApproval = React.lazy(() =>
  import("../views/Fleet/DeliveryOrders/DOChecksheetApproval")
);
const PackageMaintenanceTask = React.lazy(() =>
  import(
    "../views/Settings/FleetMaintenanceSettings/PackageMaintenance/PackageMaintenanceTask"
  )
);
const PreventiveMaintenanceTask = React.lazy(() =>
  import(
    "../views/Settings/FleetMaintenanceSettings/PreventiveMaintenance/PreventiveMaintenanceTask"
  )
);

const TransporterDivisions = React.lazy(() =>
  import("../views/Fleet/Divisions")
);

const Dashboard = React.lazy(() => import("../views/Dashboard"));
const CovidMain = React.lazy(() => import("../views/Fleet/Covid/CovidMain"));
const DriverAppUsers = React.lazy(() =>
  import("../views/Fleet/DriverAppUsers/DriverAppUsers.jsx")
);
const MaintenanceSettings = React.lazy(() =>
  import("../views/Settings/FleetMaintenanceSettings")
);

// const DashboardDetails = React.lazy(() => import('../views/DashboardDetails/DashboardDetails.jsx'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/welcome",
    name: "Welcome Message",
    component: WelcomeMessage,
    exact: true,
  },

  //Fleet
  {
    path: "/transporter/dashboard",
    name: "Dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/transporter/live-monitoring",
    name: "Live Monitoring",
    component: LiveMonitoring,
    exact: true,
  },
  activeFeature.LIVE_MONITORING_V2 && {
    path: "/transporter/command-center",
    name: "Live Monitoring Command Center",
    component: LiveMonitoringCommandCenter,
    exact: true,
  },
  // { path: '/transporter/monitoring', name: 'Fleet Monitoring', component: VehicleMonitoring, exact: true },
  {
    path: "/transporter/monitoring/details",
    name: "Monitor Details",
    component: MonitorDetails,
  },
  {
    path: "/transporter/fleets",
    name: "Fleets",
    component: Fleets,
    exact: true,
  },
  {
    path: "/transporter/fleet-operations",
    name: "Fleet Operations",
    component: FleetOperations,
    exact: true,
  },
  {
    path: "/transporter/fleets/report/:id",
    name: "Fleet Report",
    component: FleetsReport,
  },
  {
    path: "/transporter/fleets/fuel/report/:id",
    name: "Fuel Report",
    component: FuelReport,
  },
  {
    path: "/transporter/fleets/obd-history/:id",
    name: "Fleet OBD History",
    component: FleetsObdHistory,
  },
  { path: "/transporter/drivers", name: "Drivers", component: Drivers },
  {
    path: "/transporter/drivers-assistant",
    name: "Drivers Assistant",
    component: DriversAssistant,
  },
  {
    path: "/transporter/delivery/audit/:id",
    name: "Delivery Order Audit",
    component: DeliveryAudit,
    exact: true,
  },
  {
    path: "/transporter/delivery-order",
    name: "Delivery Order",
    component: DeliveryOrders,
  },
  {
    path: "/transporter/main-customers",
    name: "Main Customers",
    component: MainCustomer,
  },
  {
    path: "/transporter/saved-places",
    name: "Saved Places",
    component: SavedPlaces,
  },
  {
    path: "/transporter/public-facility",
    name: "Public Facilty",
    component: PublicFacility,
  },
  { path: "/transporter/security", name: "Security", component: Security },
  {
    path: "/transporter/work-order/:id",
    name: "Work Order",
    component: WorkOrders,
    exact: true,
  },
  {
    path: "/transporter/wo-review/:month/:id",
    name: "Work Order Review",
    component: WOReview,
    exact: true,
  },
  {
    path: "/transporter/wo-pod-verif-review/:id",
    name: "Work Order POD Verification Review",
    component: WOPODConfirmReview,
    exact: true,
  },
  {
    path: "/transporter/report/delivery/:id",
    name: "Delivery Order Report",
    component: ReportDeliveryOrders,
    exact: true,
  },
  {
    path: "/transporter/3pl/request",
    name: "3PL Work Order Request",
    component: TriPLWorkOrderRequest,
    exact: true,
  },
  {
    path: "/transporter/3pl/grouping",
    name: "3PL Work Order Grouping",
    component: TriPLGrouping,
    exact: true,
  },
  {
    path: "/transporter/3pl/group-members-assign/:id",
    name: "3PL Work Order Grouping Member Assign",
    component: GroupMembersAssign,
    exact: true,
  },
  {
    path: "/transporter/3pl/administrative",
    name: "3PL Administrative",
    component: TriPLAdministrative,
    exact: true,
  },
  {
    path: "/transporter/covid-related/:id",
    name: "Covid Related",
    component: CovidMain,
    exact: true,
  },
  {
    path: "/transporter/driver-app-user/:id",
    name: "Driver App Users",
    component: DriverAppUsers,
    exact: true,
  },
  {
    path: "/transporter/mechanics",
    name: "Mechanics",
    component: Mechanics,
    exact: true,
  },
  {
    path: "/transporter/mechanics/certification/:id",
    name: "Mechanics Certification",
    component: MechanicsCertification,
    exact: true,
  },
  {
    path: "/transporter/mechanics/specialization/:id",
    name: "Mechanics Specialization",
    component: MechanicsSpecialization,
    exact: true,
  },
  {
    path: "/transporter/mechanics/operational-hour/:id",
    name: "Mechanics Operational Hour",
    component: MechanicsOperationalHour,
    exact: true,
  },
  {
    path: "/transporter/workshop",
    name: "Workshop",
    component: Workshop,
    exact: true,
  },
  {
    path: "/transporter/workshop/operational-hour/:id",
    name: "Workshop Operational Hour",
    component: WorkshopOperationalHour,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance",
    name: "Fleet Maintenance",
    component: FleetMaintenance,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance/mechanic-task/:id",
    name: "Scheduled Maintenance Mechanic Task",
    component: SMMechanicTask,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance/mechanic-task-detail/:id",
    name: "Scheduled Maintenance Mechanic Task Detail",
    component: SMMechanicTaskDetail,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance/workshop-mechanic-task/:id",
    name: "Scheduled Maintenance Mechanic Task",
    component: WSMMechanicTask,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance/workshop-mechanic-task-detail/:id",
    name: "Scheduled Maintenance Mechanic Task Detail",
    component: WSMMechanicTaskDetail,
    exact: true,
  },
  {
    path: "/transporter/currative-order",
    name: "Currative Order",
    component: CurrativeOrder,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance-settings",
    name: "Fleet Maintenance Settings",
    component: MaintenanceSettings,
    exact: true,
  },
  {
    path: "/transporter/check-sheet",
    name: "Check Sheet",
    component: CheckSheet,
    exact: true,
  },
  {
    path: "/transporter/check-sheet/details/:id",
    name: "Check Sheet Detail",
    component: CheckSheetDetail,
    exact: true,
  },
  {
    path: "/transporter/check-sheet/approval-setting/:id",
    name: "Check Sheet Approval Setting",
    component: CheckSheetApprovalSetting,
    exact: true,
  },
  {
    path: "/transporter/do-check-sheet/approval/:id",
    name: "Do Check Sheet Approval",
    component: DoCheckSheetApproval,
    exact: true,
  },
  {
    path: "/transporter/divisions",
    name: "Divisions",
    component: TransporterDivisions,
    exact: true,
  },
  {
    path: "/transporter/package-maintenance-task/:id",
    name: "Package Maintenance",
    component: PackageMaintenanceTask,
    exact: true,
  },
  {
    path: "/transporter/preventive-maintenance-task/:id",
    name: "Preventive Maintenance",
    component: PreventiveMaintenanceTask,
    exact: true,
  },

  {
    path: "/transporter/scheduled-maintenance/approval-setting/:id",
    name: "Scheduled Maintenance Approval Setting",
    component: SMApprovalSetting,
    exact: true,
  },
  {
    path: "/transporter/scheduled-maintenance/approval/:id",
    name: "Scheduled Maintenance Approval",
    component: SMApproval,
    exact: true,
  },
  {
    path: "/transporter/delivery/category",
    name: "Category",
    component: DOCategory,
    exact: true,
  },
  { path: "/login", name: "Login", component: Login },
  { path: "/logout", name: "Logout", component: Logout },
  { path: "/register", name: "Register", component: Register },
  {
    path: "/user/management",
    name: "User Management",
    component: UserManagement,
  },
];

export default routes;

import React, { Component } from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ButtonGroup,
    Button
} from 'reactstrap'
import { HashLoader } from 'react-spinners'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import AddEditPicForm from './Forms/AddEditPicForm';
import DeletePicForm from './Forms/DeletePicForm';

class PicDatatable extends Component{

    constructor(props) {
        super(props);
        this.state = { 
            initialLoad: {},
            pic: [],
            initialValues: this.defaultInitialValues(),

            modalAdd: false,
            modalEdit: false,
            modalDelete: false
        }
    }

    componentDidUpdate(prevProps){
        const {pic, initialLoad} = this.props
        if(prevProps.pic !== pic){
            this.setState({
                pic: pic
            })
        }
        if(prevProps.initialLoad !== initialLoad){
            this.setState({
                initialLoad: initialLoad
            })
        }
    }

    defaultInitialValues(){
        const {id_workshop} = this.props
        return {
            id_workshop:id_workshop,
            name:'',
            id_workshop_pic_position: null,
            whatsapp_number: '',
            phone_number: '',
            email: ''
        }
    }

    toggleOpenAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd,
            initialValues: this.defaultInitialValues()
        })
    }

    toggleCloseAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd,
            initialValues: this.defaultInitialValues()
        })
    }

    toggleOpenEdit = (_row) => {
        this.setState({
            modalEdit: !this.state.modalEdit,
            initialValues: {
                id: _row.id,
                id_workshop_pic_position: _row.id_workshop_pic_position,
                name: _row.name,
                whatsapp_number: _row.whatsapp_number,
                phone_number: _row.phone_number,
                email: _row.email
            }
        })
    }

    toggleCloseEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit,
            initialValues: this.defaultInitialValues()
        })
    }


    toggleOpenDelete = (_row) => {
        this.setState({
            modalDelete: !this.state.modalDelete,
            initialValues: {
                id: _row.id,
                name: _row.name,
            },
        })
    }

    toggleCloseDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete,
            initialValues: this.defaultInitialValues()
        })
    }

    fetchRefresh = () => {
        this.props.refreshDataTable()
    }

    customBtnGroup = props => {
        return(
            <div>
                <Button className="mr-2" onClick={() => this.toggleOpenAdd()} color="primary">
                    <i className="fa fa-plus"></i>&nbsp;Add New Pic 
                </Button> 
                { props.exportCSVBtn }
            </div>
        );
    }
    
    actionFormatter = (cell, row) => {
        return (
            <React.Fragment>
                <ButtonGroup>
                    <Button className="btn btn-warning" color="warning" size="sm" onClick={() => this.toggleOpenEdit(row)}><i className="fa fa-edit"></i> Edit</Button>
                    <Button className="btn btn-danger" color="danger" size="sm" onClick={() => this.toggleOpenDelete(row)}><i className="fa fa-trash"></i> Delete</Button>
                </ButtonGroup> 
            </React.Fragment>   
        )
    }

    showDatatable = () => {
        const {pic} = this.state
        var options = {
            noDataText: 'No data registered. Try one-lah!',
            sizePerPageList: [{
                text: '10', value: 10
            },{
                text: '25', value: 25
            },{
                text: '50', value: 50
            }],
            sizePerPage: 20,
            btnGroup: this.customBtnGroup,
        };
        return (
            <BootstrapTable data={pic} version="4" bordered={false} hover pagination search edit options={options} exportCSV={true} csvFileName={this.fileNameFormat} multiColumnSearch>
                <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" searchable={false} hidden>ID</TableHeaderColumn>
                <TableHeaderColumn dataField="name" searchable dataSort csvHeader="Name" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="40%">Name</TableHeaderColumn>
                {/* <TableHeaderColumn dataField="workshop_pic_position_name" searchable dataSort csvHeader="Position" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="40%">Position</TableHeaderColumn> */}
                <TableHeaderColumn dataField="whatsapp_number" searchable dataSort csvHeader="Whatsapp Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Whatsapp Number</TableHeaderColumn>
                <TableHeaderColumn dataField="phone_number" searchable dataSort csvHeader="Phone Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Phone Number</TableHeaderColumn>
                <TableHeaderColumn dataField="email" searchable dataSort csvHeader="Email" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">Email</TableHeaderColumn>
                <TableHeaderColumn dataField="action" export={false} dataFormat={ this.actionFormatter.bind(this) } thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="30%">Action</TableHeaderColumn>
            </BootstrapTable>
        )
    }

    fetchRefresh = () => {
        this.props.fetchRefresh()
    }

    render(){
        const {initialValues, initialLoad} = this.state
        const {modalAdd, modalEdit, modalDelete} = this.state
        return(
            <>
                {initialLoad.isComplete? 
                    this.showDatatable():
                    <center>
                     <HashLoader color={'#123abc'} loading={!initialLoad.isComplete}/>
                         <br /> Loading.... Please wait...
                    </center>
                }
                <Modal isOpen={modalAdd} toggle={this.toggleOpenAdd} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={this.toggleCloseAdd}>Add New Pic </ModalHeader>
                    <ModalBody>
                        <AddEditPicForm
                                closeForm={this.toggleCloseAdd} initialValues={initialValues} 
                                isAdd={true} fetchRefresh={this.fetchRefresh}/>
                    </ModalBody>
                </Modal>
                <Modal isOpen={modalEdit} toggle={this.toggleOpenEdit} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={this.toggleCloseEdit}>Edit Pic ({initialValues.name})</ModalHeader>
                    <ModalBody>
                        <AddEditPicForm 
                                     closeForm={this.toggleCloseEdit} initialValues={initialValues} 
                                     isEdit={true} fetchRefresh={this.fetchRefresh}/>
                    </ModalBody>
                </Modal>
                <Modal isOpen={modalDelete} toggle={this.toggleOpenDelete} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseDelete}>Delete Pic ({initialValues.name})</ModalHeader>
                    <ModalBody>
                        <DeletePicForm  
                            initialValues={initialValues} closeForm={this.toggleCloseDelete}
                            fetchRefresh={this.fetchRefresh}/>
                    </ModalBody>
                </Modal>
            </>
        )
    }

} export default PicDatatable

import React, { Component } from 'react';
import {    
    Row,
    Col,
    Button,
    ButtonGroup
} from 'reactstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import AddDeleteDriverFleets from './Forms/AddDeleteDriverFleets'
class DriverFleetsDatatable extends Component {

    constructor(props){
        super(props)
        this.state = {
            initialFormValues: {
                id_driver : this.props.idDriver,
                id_fleet: null,
            },
            deletedRowId: null,
            isAdd:true,
            isDelete:false
        }
    }

    toggleOpenDelete = (cell, row) => {
        if(row.id === this.state.deletedRowId){
            this.setState({
                deletedRowId: null,
                isAdd:true,
                isDelete:false,
                initialFormValues: {
                    id_driver : this.props.idDriver,
                    id_fleet: null,
                },
            })
        }
        if(row.id !== this.state.deletedRowId){
            this.setState({
                deletedRowId: row.id,
                isAdd:false,
                isDelete:true,
                initialFormValues: {
                    id: row.id_pairing,
                    id_driver : this.props.idDriver,
                    id_fleet: row.id,
                },
            })
        }
    }

    actionFormatter = (cell, row) => {
        return (
            <React.Fragment>
                <ButtonGroup>
                    <Button className="btn btn-danger" color="danger" 
                            size="sm" onClick={() => this.toggleOpenDelete(cell, row)}>
                                <i className="fa fa-chain-broken"></i> Unbind
                    </Button>
                </ButtonGroup> 
            </React.Fragment>   
        )
    }

    rowClassNameFormat = (row) => {
        if(!row)
            return
        if(row.id === this.state.deletedRowId)
            return {"backgroundColor":"#F8AAAC"}
    }

    showDatatable = () => {
        let options = {
            noDataText: 'No brands registered. Try one-lah!',
            sizePerPageList: [{
                text: '10', value: 10
            },{
                text: '25', value: 25
            },{
                text: '50', value: 50
            }],
            sizePerPage: 20,
            btnGroup: this.customBtnGroup,
        };
        return (
            <BootstrapTable data={this.props.driverFleets} version="4" bordered={false} hover pagination 
                            search edit options={options} exportCSV={true} csvFileName={this.fileNameFormat} multiColumnSearch
                            trStyle={this.rowClassNameFormat}>
                <TableHeaderColumn dataField="id_pairing" isKey dataSort csvHeader="ID" searchable={false} hidden>ID</TableHeaderColumn>
                <TableHeaderColumn dataField="id" dataSort csvHeader="id_fleet" searchable={false} hidden>ID Fleet</TableHeaderColumn>
                <TableHeaderColumn dataField="reg_number_with_door" searchable dataSort csvHeader="Package Code" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="50%">ID Fleet</TableHeaderColumn>
                <TableHeaderColumn dataField="car_general_type" searchable dataSort csvHeader="Iteration Desc." thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="30%">General Type</TableHeaderColumn>
                <TableHeaderColumn dataField="car_type" searchable dataSort csvHeader="Every" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="30%">Type</TableHeaderColumn>
                <TableHeaderColumn dataField="car_brands" searchable dataSort csvHeader="Last Maintenance km" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="30%">Brand</TableHeaderColumn>
                <TableHeaderColumn dataField="action" export={false} dataFormat={ this.actionFormatter.bind(this) } thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="30%">Action</TableHeaderColumn>
            </BootstrapTable>
        )
    }

    refreshTableData = () => {
        this.props.refreshTable()
    }

    render() {
        const {initialFormValues, isAdd, isDelete,} = this.state
        return (
            <React.Fragment>
                {
                    <Row>
                        <Col xs="12" md="12">
                            <AddDeleteDriverFleets initialValues={initialFormValues} 
                                isAdd={isAdd}isDelete={isDelete}
                                refreshTable={this.refreshTableData}/>
                        </Col>
                        <Col xs="12" md="12">
                            <hr />
                            {this.showDatatable()}
                        </Col>
                    </Row>
                }
                {/* <ToastContainer position="bottom-right" autoClose={7500} style={{zIndex: 7000}}/> */}
            </React.Fragment>
        )
    }
}

export default DriverFleetsDatatable
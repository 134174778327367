import React, { useEffect, useState } from "react";
import * as yup from "yup";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import Select from "react-select";
import { SPAREPARTS_REQUEST_ITEM_DETAIL_LIST } from "../../../../../redux/config/links";
import {
  MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST_INPUT,
  MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST_EDIT,
} from "../../../../../redux/config/storing.links";
import useForm from "../../../../../hooks/useForm";

const TaskSparePartAddEdit = ({
  initialValues = null,
  onClose,
  onReload,
  isEdit = false,
}) => {
  const [isGettingSparepartDetail, setIsGettingSparepartDetail] =
    useState(true);
  const [
    onSparepartDetail,
    { loading: loadingSparepartDetail, data: sparepartDetailList },
  ] = useLazyFetch({
    url: SPAREPARTS_REQUEST_ITEM_DETAIL_LIST,
    method: "POST",
  });

  const [onInputTask] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST_INPUT,
    method: "POST",
  });

  const [onEditTask] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST_EDIT,
    method: "POST",
  });
  const onSubmit = (values, { setSubmitting }) => {
    if (!isEdit) {
      onInputTask(
        {
          body: {
            id_storing_maintenance_mechanic_task_detail:
              initialValues.id_storing_maintenance_mechanic_task_detail,
            id_spare_part_request_item_detail:
              values.spare_part_request_item_detail.value,
            desc: values.desc,
          },
        },
        () => {
          setSubmitting(false);
          onReload();
          onClose();
        }
      );
    } else {
      onEditTask(
        {
          body: {
            id_storing_maintenance_mechanic_task_detail:
              initialValues.id_storing_maintenance_mechanic_task_detail,
            id_spare_part_request_item_detail:
              values.spare_part_request_item_detail.value,
            desc: values.desc,
            id: initialValues.id,
          },
        },
        () => {
          setSubmitting(false);
          onReload();
          onClose();
        }
      );
    }
  };
  const validation = () => {
    let selectYup = yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable();
    return yup.object().shape({
      spare_part_request_item_detail: selectYup.required(
        "Sparepart Request Item Detail is required!"
      ),
      desc: yup.string().required("Description is required!"),
    });
  };

  const {
    handleSubmit,
    errors,
    values,
    handleReset,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues: {
      spare_part_request_item_detail:
        !!initialValues && !!initialValues.id_spare_part_request_item_detail
          ? {
              value: initialValues.id_spare_part_request_item_detail,
              label: initialValues.spare_part_detail_part_number,
            }
          : null,
      desc: !!initialValues && !!initialValues.desc ? initialValues.desc : "",
    },
    validation: validation(),
  });

  const handleChange = (e) => {
    setFieldValue(e.currentTarget.name, e.currentTarget.value);
  };

  useEffect(() => {
    if (isGettingSparepartDetail) {
      setIsGettingSparepartDetail(false);
      onSparepartDetail({});
    }
  }, [isGettingSparepartDetail, onSparepartDetail]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Sparepart Request Item Detail</Label>
          </Col>
          <Col xs="12" md="9">
            <LoadingContent
              loading={loadingSparepartDetail}
              onReload={() => setIsGettingSparepartDetail(true)}
            >
              <Select
                value={values.spare_part_request_item_detail}
                onChange={(value) => {
                  setFieldValue("spare_part_request_item_detail", value);
                }}
                options={(sparepartDetailList
                  ? sparepartDetailList.result
                  : []
                ).map((item) => ({
                  label: item.spare_part_detail_part_number,
                  value: item.id,
                }))}
                disabled={isSubmitting}
                placeholder={"Choose Mechanic Fleet Scan"}
              />
            </LoadingContent>
            <FormFeedback>{errors.spare_part_request_item_detail}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="desc">Description</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="desc"
              id="desc"
              placeholder="Description"
              autoComplete="off"
              valid={!errors.desc}
              invalid={!!errors.desc}
              onChange={handleChange}
              value={values.desc || ""}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.desc}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            color="primary"
            className="mr-1"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Wait..." : !isEdit ? "Save Changes" : "Edit"}
          </Button>
          <Button
            type="button"
            color="warning"
            className="mr-1"
            disabled={false}
            onClick={() => null}
          >
            Close
          </Button>
          <Button
            type="reset"
            color="danger"
            className="mr-1"
            disabled={isSubmitting}
            onClick={handleReset}
          >
            Reset
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

export default TaskSparePartAddEdit;

import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Row,
    Col
} from 'reactstrap'
import moment from 'moment'

class StockerItemDetail extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            collapse: false,
         };
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    dateFormatter = (_data) => {
        var date = moment(_data).format("DD-MMM-YYYY HH:mm:ss");
        if(date !== "Invalid date"){
            // console.log(date)
            return date
        }
        else {
            return "Invalid date"
        }
    }

    viewStockerItem = () => {
        const {
            // id,
            // id_master_outbound,
            // outbound_number,
            // document_number,
            // id_outbound_detail,
            // awb_number,
            // id_master_item,
            item_name,
            sku_item_name,
            expected_qty,
            realization_qty,
            // rejected_qty,
            batch_number,
            production_date,
            expired_date,
            // id_receiver
        } = this.props
        return (
            <Row className="container">
                <Col md="12">
                    <dt>Item Name</dt>
                    <dd>{item_name ? item_name : '-'}</dd>

                    <dt>SKU Item Name</dt>
                    <dd>{sku_item_name ? sku_item_name : '-'}</dd>

                    <dt>Expected Qty</dt>
                    <dd>{expected_qty ? expected_qty : '-'}</dd>

                    <dt>Realization Qty</dt>
                    <dd>{realization_qty ? realization_qty : '-'}</dd>

                    <dt>Batch Number</dt>
                    <dd>{batch_number ? batch_number : '-'}</dd>

                    <dt>Production Date</dt>
                    <dd>{production_date ? this.dateFormatter(production_date) : '-'}</dd>

                    <dt>Expired Date</dt>
                    <dd>{expired_date ? this.dateFormatter(expired_date) : '-'}</dd>

                    {/* <br/>
                    <h6>Cost Estimation</h6> */}
                    {/* <dt>Toll Fee</dt><dd>{toll_fee ? this.curStringFormat(toll_fee) : '-'}</dd>
                    <dt>Weight Fee</dt><dd>{weight_fee ? this.curStringFormat(weight_fee) : '-'}</dd>
                    <dt>Other Fee Fee</dt><dd>{other_fee ? this.curStringFormat(other_fee) : '-'}</dd> */}
                    <br/>
                </Col>
            </Row>
        )
    }

    render() {
        const {
            // id,
            index,
            // id_master_outbound,
            // outbound_number,
            // document_number,
            // id_outbound_detail,
            // awb_number,
            // id_master_item,
            item_name,
            sku_item_name,
            expected_qty,
            // realization_qty,
            // rejected_qty,
            // batch_number,
            // production_date,
            // expired_date,
            // id_receiver
        } = this.props
        return (
            <div>
                <Card key={index} >
                    <CardHeader>
                        <span>{item_name} - {sku_item_name} - Expected Qty: {expected_qty}</span>
                        <div className="card-header-actions">
                            {/* eslint-disable-next-line */}
                            <a className="card-header-action btn btn-minimize" data-target="#collapseExample" onClick={() => this.toggle()}>{this.state.collapse ? <i 
                            className="icon-arrow-up"></i> : <i className="icon-arrow-down"></i>}</a>

                        </div>
                    </CardHeader>
                    <Collapse isOpen={this.state.collapse} id="collapseExample">
                        <CardBody>
                            {this.viewStockerItem()}
                        </CardBody>
                    </Collapse>
                </Card>
            </div>
        );
    }
}

export default StockerItemDetail;
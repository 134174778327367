import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Label } from "reactstrap";
import * as yup from "yup";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import Select from "react-select";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { SPAREPARTS_REQUEST_ITEM_DETAIL_LIST } from "../../../../../redux/config/links";
import { SPARE_PART_INPUT, SPARE_PART_EDIT } from "../../../../../redux/config/storing.links";
import useForm from "../../../../../hooks/useForm";

const SparepartAdd = ({
  initialValues = null,
  isEdit = false,
  onClose,
  onReload,
}) => {
  const [isGettingMechanic, setIsGettingMechanic] = useState(true);
  const [onMechanicList, { data, loading }] = useLazyFetch({
    url: SPAREPARTS_REQUEST_ITEM_DETAIL_LIST,
    method: "post",
  });

  const [onSparepartInput] = useLazyFetch({
    url: SPARE_PART_INPUT,
    method: "post",
  });
  const [onSparepartEdit] = useLazyFetch({
    url: SPARE_PART_EDIT,
    method: "post",
  });

  const validation = () => {
    let selectYup = yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable();
    return yup.object().shape({
      spare_part_request_item_detail: selectYup.required(
        "Sparepart is required!"
      ),
    });
  };

  const {
    handleSubmit,
    errors,
    values,
    handleReset,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues: {
      spare_part_request_item_detail:
        !!initialValues && !!initialValues.id_spare_part_request_item_detail
          ? {
              value: initialValues.id_spare_part_request_item_detail,
              label: initialValues.spare_part_detail_part_number,
            }
          : null,
      checked: !!initialValues && !!initialValues.checked ? initialValues.checked === 1 ? true : false : false,
    },
    validation: validation(),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if(!isEdit){
      onSparepartInput(
        {
          body: {
            id_storing_fleet_trip: initialValues.id_storing_fleet_trip,
            id_spare_part_request_item_detail:
              values.spare_part_request_item_detail.value,
            checked: values.checked,
          },
        },
        () => {
          setSubmitting(false);
          onClose();
          onReload();
        }
      );
    }
    else{
      onSparepartEdit(
        {
          body: {
            id_storing_fleet_trip: initialValues.id_storing_fleet_trip,
            id_spare_part_request_item_detail:
              values.spare_part_request_item_detail.value,
            checked: values.checked,
            id: initialValues.id
          },
        },
        () => {
          setSubmitting(false);
          onClose();
          onReload();
        }
      );
    }
  };

  useEffect(() => {
    if (isGettingMechanic) {
      setIsGettingMechanic(false);
      onMechanicList({});
    }
  }, [isGettingMechanic, onMechanicList]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Spare part request item detail</Label>
          </Col>
          <Col xs="12" md="9">
            <LoadingContent
              loading={loading}
              onReload={() => setIsGettingMechanic(true)}
            >
              <Select
                value={values.spare_part_request_item_detail}
                onChange={(value) => {
                  setFieldValue("spare_part_request_item_detail", value);
                }}
                options={(data ? data.result : []).map((item) => ({
                  label: item.spare_part_detail_part_number,
                  value: item.id,
                }))}
                disabled={isSubmitting}
                placeholder={"Choose Spare part request item detail"}
              />
            </LoadingContent>
            <FormFeedback>{errors.spare_part_request_item_detail}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Checked</Label>
          </Col>
          <Col xs="12" md="9">
            <input
              type="checkbox"
              name="checked"
              onChange={() => {
                if (values.checked) {
                  setFieldValue("checked", false);
                } else {
                  setFieldValue("checked", true);
                }
              }}
              value={"checked"}
              checked={values.checked}
            />
            <FormFeedback>{errors.checked}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            color="primary"
            className="mr-1"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Wait..." : !isEdit ? "Save Changes" : "Edit"}
          </Button>
          <Button
            type="button"
            color="warning"
            className="mr-1"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >
            Close
          </Button>
          <Button
            type="reset"
            color="danger"
            className="mr-1"
            disabled={isSubmitting}
            onClick={handleReset}
          >
            Reset
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

export default SparepartAdd;

import React, { Component } from 'react'
import {
    Button,
    FormGroup,
    Label,
    Col
} from 'reactstrap'
import axios from 'axios';
import * as Yup from 'yup'
import { Formik } from 'formik'
import {toast} from 'react-toastify'
import handleErrorResponseCode from '../../../helpers/error-response-code-handler'
import {DELIVERY_ORDERS_COPY_URL}  from '../../../redux/config/links'
import DateTime from 'react-datetime'
import moment from 'moment'
class DOCopyForm extends Component {

    validation = () => {
        let yup = Yup.object().shape({
                        est_departure_time : Yup.string().required('Departure time is required!'),
                    })
        return yup
    }

    form = () => {
        const {initialValues} = this.props
        const {fetchRefresh, closeForm} = this.props
        return(
            <Formik
            validateOnMount
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                let auth = loginCreds.token;
                setSubmitting(true)
                const estDeparture = moment().format("YYYY-MM-DD") + ' ' + moment(values.est_departure_time).format("HH:mm:ss")
                    axios({
                            method: 'post',
                            url: DELIVERY_ORDERS_COPY_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : {
                                id_delivery_order: values.id_delivery_order,
                                assigned_do_date: estDeparture,
                                est_departure_time: estDeparture,
                                end_assigned_do_date: moment(estDeparture).add(values.difference, "hours").format("YYYY-MM-DD HH:mm:ss")
                            }
                        }).then((response) => {
                            if(response.status === 200){
                                setSubmitting(false)
                                toast.success('DO Copied Successfully!')
                                closeForm()
                                fetchRefresh()
                            }
                        })
                    .catch(function(error){
                        setSubmitting(false)
                        toast.error(handleErrorResponseCode(error))
                    })
                setSubmitting(false)
            }} 
            validationSchema={this.validation()}
            render={({
            values,
            setFieldValue,
            handleSubmit,
            isValid,isSubmitting,
            })=> (
                <form>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Estimation Departure</Label>
                        </Col>
                        <Col xs="8" md="8">
                            <div style={{display:"flex"}}>
                                <div style={{marginTop:"7px"}}>
                                 {moment().format("YYYY-MM-DD")}&nbsp;
                                </div>
                                <DateTime
                                        id="est_departure_time"
                                        name="est_departure_time"
                                        autoComplete="off"
                                        dateFormat={false}
                                        timeFormat="HH:mm:ss"
                                        value={values.est_departure_time}
                                        onChange={time => setFieldValue('est_departure_time', time? time : null)}
                                        placeholderText={values.est_departure_time? values.est_departure_time : "Enter time"}/>
                            </div>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Button type="button" onClick={handleSubmit} color="primary" className="mr-1" disabled={!isValid || isSubmitting}>{isSubmitting ? 'Wait...' : 'Submit'}</Button>
                        <Button type="button" color="warning" className="mr-1" disabled={isSubmitting} onClick={closeForm}>Close</Button>
                    </FormGroup>
                </form>
                )}
            />
        )
    }

    render(){
        return(
            <React.Fragment>
                {this.form()}
            </React.Fragment>
        )
    }

}export default DOCopyForm
import React from "react";
import { Formik } from "formik";
import * as API_LINKS from "../../../redux/config/links";
import * as Yup from "yup";
import { Button, Col, FormFeedback, FormGroup, Label } from "reactstrap";
import { BarLoader } from "react-spinners";
import Select from "react-select";
import { toast } from "react-toastify";

const validation = () => {
  let selectYup = Yup.object().shape({
    label: Yup.string().required("Required"),
    value: Yup.number().required("Required"),
  });
  let yup = Yup.object().shape({
    category: selectYup,
    subcategory: selectYup,
  });
  return yup;
};

const Form = ({ formik, onClose, setDataCategory, setDataSubcategory }) => {
  const [category, setCategory] = React.useState(null);
  const [isLoadingCategory, setIsLoadingCategory] = React.useState(false);
  const [isFetchCategory, setIsFetchCategory] = React.useState(true);

  const [subcategory, setSubcategory] = React.useState(null);
  const [isLoadingSubcategory, setIsLoadingSubcategory] = React.useState(false);
  const [isFetchSubcategory, setIsFetchSubcategory] = React.useState(true);

  const onGetCategory = React.useCallback(() => {
    setIsLoadingCategory(true);
    const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    const auth = loginCreds.token;

    fetch(API_LINKS.DELIVERY_ORDER_CATEGORY_LIST, {
      method: "post",
      headers: {
        Authorization: auth,
      },
    })
      .then((res) => {
        setIsLoadingCategory(false);
        if (res.status >= 200 && res.status < 300) {
          res.json().then((result) => {
            setCategory(
              result.result.map((item) => ({
                label: item.name,
                value: item.id,
              }))
            );

            setDataCategory(
              result.result.map((item) => ({
                label: item.name,
                value: item.id,
              }))
            );
          });
        }
      })
      .catch(() => {
        setIsLoadingCategory(false);
      });
  }, [API_LINKS]);

  const onGetSubcategory = React.useCallback(
    (id) => {
      setIsLoadingSubcategory(true);
      const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      const auth = loginCreds.token;

      //   const fd = new FormData();
      //   fd.append("id_delivery_order_category", id);

      fetch(API_LINKS.DELIVERY_ORDER_SUBCATEGORY_LIST, {
        method: "post",
        headers: {
          Authorization: auth,
        },
        // body: fd,
      })
        .then((res) => {
          setIsLoadingSubcategory(false);
          if (res.status >= 200 && res.status < 300) {
            res.json().then((result) => {
              setSubcategory(
                result.result.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))
              );

              setDataSubcategory(
                result.result.map((item) => ({
                  label: item.name,
                  value: item.id,
                  id_category: item.id_delivery_order_category,
                }))
              );
            });
          }
        })
        .catch(() => {
          setIsLoadingSubcategory(false);
        });
    },
    [API_LINKS]
  );

  React.useEffect(() => {
    if (isFetchCategory) {
      setIsFetchCategory(false);
      onGetCategory();
    }
  }, [isFetchCategory, onGetCategory]);

  React.useEffect(() => {
    if (isFetchSubcategory) {
      setIsFetchSubcategory(false);
      onGetSubcategory();
    }
  }, [isFetchCategory, onGetSubcategory]);
  return (
    <>
      <>
        <form onSubmit={formik.handleSubmit}>
          <FormGroup row>
            <Col md="3">
              <Label htmlFor="name">Category</Label>
            </Col>
            <Col xs="12" md="9">
              {isLoadingCategory ? (
                <center>
                  <BarLoader color={"#123abc"} loading={true} /> <br />{" "}
                  Loading.... Please wait...
                </center>
              ) : category ? (
                <Select
                  value={formik.values.category}
                  onChange={() => {
                    formik.setFieldValue("category", formik.values.category);

                    setIsFetchSubcategory(true);
                  }}
                  options={category ? category : []}
                  disabled={formik.isSubmitting}
                />
              ) : (
                <center>
                  Error fetching your data... <br />
                  <br />
                  <Button
                    className="btn btn-primary"
                    color="primary"
                    size="md"
                    disabled={isLoadingCategory}
                    onClick={() => setIsFetchCategory(true)}
                  >
                    <i className="fa fa-refresh"></i>
                    Try again
                  </Button>
                </center>
              )}
              <FormFeedback>{formik.errors.category}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md="3">
              <Label htmlFor="name">Subcategory</Label>
            </Col>
            <Col xs="12" md="9">
              {isLoadingSubcategory ? (
                <center>
                  <BarLoader color={"#123abc"} loading={true} /> <br />{" "}
                  Loading.... Please wait...
                </center>
              ) : formik.values.category !== null ? (
                subcategory ? (
                  <Select
                    value={formik.values.subcategory}
                    onChange={() => {
                      formik.setFieldValue(
                        "subcategory",
                        formik.values.subcategory
                      );
                    }}
                    options={subcategory ? subcategory : []}
                    disabled={formik.isSubmitting}
                  />
                ) : (
                  <center>
                    Error fetching your data... <br />
                    <br />
                    <Button
                      className="btn btn-primary"
                      color="primary"
                      size="md"
                      disabled={isLoadingSubcategory}
                      onClick={() => setIsFetchSubcategory(true)}
                    >
                      <i className="fa fa-refresh"></i>
                      Try again
                    </Button>
                  </center>
                )
              ) : null}
              <FormFeedback>{formik.errors.subcategory}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup>
            <Button
              type="submit"
              color="primary"
              className="mr-1"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? "Wait..." : true ? "Unbind" : "Edit"}
            </Button>
            <Button
              type="button"
              color="warning"
              className="mr-1"
              disabled={false}
              onClick={() => onClose()}
            >
              Close
            </Button>
            <Button
              type="reset"
              color="danger"
              className="mr-1"
              disabled={formik.isSubmitting}
              onClick={formik.handleReset}
            >
              Reset
            </Button>
          </FormGroup>
        </form>
      </>
    </>
  );
};

const UnbindSubcategory = ({ dataDo, onClose, refetch }) => {
  const [isShow, setIsShow] = React.useState(true);
  const [category, setCategory] = React.useState(null);
  const [initialValues, setInitialValues] = React.useState({
    category: null,
    subcategory: null,
  });
  const [isAlreadySet, setIsAlreadySet] = React.useState({
    category: false,
    subcategory: false,
  });

  const onSubmit = (values, { setSubmitting }) => {
    const fd = new FormData();
    fd.append("id_delivery_order", dataDo.id);

    const loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    const auth = loginCreds.token;

    fetch(API_LINKS.DELIVERY_ORDER_UNBIND_CATEGORY, {
      method: "post",
      body: fd,
      headers: {
        Authorization: auth,
      },
    })
      .then((res) => {
        setSubmitting(false);
        if (res.status >= 200 && res.status < 300) {
          refetch();
          onClose();
        }
        // else if (res.status >= 400 && res.status < 500) {
        //   res.json().then((result) => toast.error(result.message));
        // }
        else {
          toast.error("Something went wrong!");
        }
      })
      .catch(() => {
        toast.error("Something went wrong!");
        setSubmitting(false);
      });
  };

  React.useEffect(() => {
    if (isShow === false) {
      setIsShow(true);
    }
  }, [isShow]);

  React.useEffect(() => {
    if (
      initialValues.subcategory !== null &&
      initialValues.category === null &&
      category !== null
    ) {
      const [c] = category.filter(
        (item) => item.value === initialValues.subcategory.id_category
      );
      if (c) {
        setInitialValues({
          subcategory: {
            label: initialValues.subcategory.label,
            value: initialValues.subcategory.value,
          },
          category: c,
        });
        setIsShow(false);
      }
    }
  }, [initialValues, category]);
  return (
    <>
      {isShow ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validation()}
          render={({
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
            handleBlur,
            handleChange,
            handleReset,
            isValid,
            isSubmitting,
          }) => {
            return (
              <>
                <Form
                  onClose={onClose}
                  setDataCategory={(c) => {
                    if (isAlreadySet.category === false) {
                      setIsAlreadySet({ ...isAlreadySet, category: true });
                      setCategory(c);
                    }
                  }}
                  setDataSubcategory={(subcategory) => {
                    if (isAlreadySet.subcategory === false) {
                      setIsAlreadySet({ ...isAlreadySet, subcategory: true });
                      const [s] = subcategory.filter(
                        (item) => item.value === dataDo.id_delivery_sub_category
                      );
                      if (s) {
                        setInitialValues({ ...initialValues, subcategory: s });
                      }
                    }
                  }}
                  formik={{
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    handleReset,
                    isValid,
                    isSubmitting,
                  }}
                />
              </>
            );
          }}
        />
      ) : null}
    </>
  );
};

export default UnbindSubcategory;

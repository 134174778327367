//For Base URL and User Authentication
const BASE_URL = "https://api.automa.id/api/stocker";

const OPERATING_AREA_LIST_URL = BASE_URL + "/operating-area/list";
const OPERATING_AREA_ADD_URL = BASE_URL + "/operating-area/input";
const OPERATING_AREA_EDIT_URL = BASE_URL + "/operating-area/edit";
const OPERATING_AREA_DELETE_URL = BASE_URL + "/operating-area/delete";

const OPERATING_AREA_GEOFENCE_LIST_URL =
  BASE_URL + "/operating-area/geofence-point/list";
const OPERATING_AREA_GEOFENCE_ADD_URL =
  BASE_URL + "/operating-area/geofence-point/input";
const OPERATING_AREA_GEOFENCE_EDIT_URL =
  BASE_URL + "/operating-area/geofence-point/edit";
const OPERATING_AREA_GEOFENCE_DELETE_URL =
  BASE_URL + "/operating-area/geofence-point/delete";

const UNITS_LIST_URL = BASE_URL + "/unit/list";
const UNIT_ADD_URL = BASE_URL + "/unit/input";
const UNIT_EDIT_URL = BASE_URL + "/unit/edit";
const UNIT_DELETE_URL = BASE_URL + "/unit/delete";
const UNIT_LIST_V2_URL = BASE_URL + "/unit/v2/list";

const COUNTRIES_LIST_URL = BASE_URL + "/country/list";

const TRACKER_TYPES_LIST_URL = BASE_URL + "/type/tracker/list";

const IDENTITY_TYPES_LIST_URL = BASE_URL + "/type/identity/list";

const OFFICER_TYPES_LIST_URL = BASE_URL + "/type/officer/list";

const PROVINCES_LIST_URL = BASE_URL + "/province/list";

const COMPANY_BANK_ACC_LIST_URL = BASE_URL + "/bank/company/account/list";
const COMPANY_BANK_ACC_ADD_URL = BASE_URL + "/bank/company/account/input";
const COMPANY_BANK_ACC_EDIT_URL = BASE_URL + "/bank/company/account/edit";
const COMPANY_BANK_ACC_DELETE_URL = BASE_URL + "/bank/company/account/delete";

const DIVISION_LIST_URL = BASE_URL + "/division/list";
const DIVISION_ADD_URL = BASE_URL + "/division/input";
const DIVISION_EDIT_URL = BASE_URL + "/division/edit";
const DIVISION_DELETE_URL = BASE_URL + "/division/delete";

const BATCH_LIST_URL = BASE_URL + "/batch/list";
const BACTH_ADD_URL = BASE_URL + "/batch/input";
const BACTH_EDIT_URL = BASE_URL + "/batch/update";
const BACTH_DELETE_URL = BASE_URL + "/batch/delete";

const BRANDS_LIST_URL = BASE_URL + "/brand/list";
const BRANDS_ADD_URL = BASE_URL + "/brand/input";
const BRANDS_EDIT_URL = BASE_URL + "/brand/edit";
const BRANDS_DELETE_URL = BASE_URL + "/brand/delete";

const MASTER_ITEM_LIST_URL = BASE_URL + "/item/list";
const MASTER_ITEM_ADD_URL = BASE_URL + "/item/input";
const MASTER_ITEM_EDIT_URL = BASE_URL + "/item/edit";
const MASTER_ITEM_DELETE_URL = BASE_URL + "/item/delete";
const MASTER_ITEM_LIST_URL_V2 = BASE_URL + "/item/v2/list";
const MASTER_ITEM_ADD_URL_V2 = BASE_URL + "/item/v2/input";
const MASTER_ITEM_EDIT_URL_V2 = BASE_URL + "/item/v2/edit";
const MASTER_ITEM_LIST_URL_V3 = BASE_URL + "/item/v3/list";
const MASTER_ITEM_STOCK_COUNT_URL = BASE_URL + "/item/v2/monitor";

const MASTER_ITEM_CART_LIST = BASE_URL + "/item/carts/list";
const MASTER_ITEM_CART_INPUT = BASE_URL + "/item/carts/input";
const MASTER_ITEM_CART_EDIT = BASE_URL + "/item/carts/edit";
const MASTER_ITEM_CART_DELETE = BASE_URL + "/item/carts/delete";

const DISPOSAL_ITEM_LIST_URL = BASE_URL + "/disposal/item/list";
const DISPOSAL_ITEM_ADD_URL = BASE_URL + "/disposal/item/input";
const DISPOSAL_ITEM_EDIT_URL = BASE_URL + "/disposal/item/edit";
const DISPOSAL_ITEM_DELETE_URL = BASE_URL + "/disposal/item/delete";

const DISPOSAL_ITEM_DETAIL_LIST_URL = BASE_URL + "/disposal/item/detail/list";
const DISPOSAL_ITEM_DETAIL_ADD_URL = BASE_URL + "/disposal/item/detail/input";
const DISPOSAL_ITEM_DETAIL_EDIT_URL = BASE_URL + "/disposal/item/detail/edit";
const DISPOSAL_ITEM_DETAIL_DELETE_URL =
  BASE_URL + "/disposal/item/detail/delete";

const DISPOSAL_TYPE_LIST_URL = BASE_URL + "/type/disposal/list";
const DISPOSAL_TYPE_ADD_URL = BASE_URL + "/type/disposal/input";
const DISPOSAL_TYPE_EDIT_URL = BASE_URL + "/type/disposal/edit";
const DISPOSAL_TYPE_DELETE_URL = BASE_URL + "/type/disposal/delete";

const MASTER_ITEM_DETAIL_LIST_URL = BASE_URL + "/item/detail/list";
const MASTER_ITEM_DETAIL_ADD_URL = BASE_URL + "/item/detail/input";
const MASTER_ITEM_DETAIL_EDIT_URL = BASE_URL + "/item/detail/edit";
const MASTER_ITEM_DETAIL_DELETE_URL = BASE_URL + "/item/detail/delete";

const MASTER_ITEM_DETAIL_ADJUSTMENT_LIST_URL =
  BASE_URL + "/item/detail/adjustment/list";
const MASTER_ITEM_DETAIL_ADJUSTMENT_ADD_URL =
  BASE_URL + "/item/detail/adjustment/input";
const MUTATION_LIST_URL = BASE_URL + "/item/mutation/list";

const MASTER_ITEM_DEFECT_LIST_URL = BASE_URL + "/item/defect/list";
const MASTER_ITEM_DEFECT_ADD_URL = BASE_URL + "/item/defect/input";
const MASTER_ITEM_DEFECT_EDIT_URL = BASE_URL + "/item/defect/edit";
const MASTER_ITEM_DEFECT_DELETE_URL = BASE_URL + "/item/defect/delete";

const MASTER_INBOUND_LIST_URL = BASE_URL + "/inbound/list";
const MASTER_INBOUND_ADD_URL = BASE_URL + "/inbound/v2/input";
// const MASTER_INBOUND_ADD_V3_URL = BASE_URL + "/inbound/v3/input";

const MASTER_INBOUND_EDIT_URL = BASE_URL + "/inbound/v2/edit";
const MASTER_INBOUND_V1_EDIT_URL = BASE_URL + "/inbound/edit";
const MASTER_INBOUND_DELETE_URL = BASE_URL + "/inbound/delete";

const MASTER_OUTBOUND_LIST_URL = BASE_URL + "/outbound/list/v3";
const MASTER_OUTBOUND_ADD_URL = BASE_URL + "/outbound/v2/input";
const MASTER_OUTBOUND_ADD_V3_URL = BASE_URL + "/outbound/v3/input";
const MASTER_OUTBOUND_EDIT_URL = BASE_URL + "/outbound/edit";
const MASTER_OUTBOUND_DELETE_URL = BASE_URL + "/outbound/delete";

const OUTBOUND_ITEM_DETAIL_LIST_URL = BASE_URL + "/outbound/item/detail/list";
const OUTBOUND_ITEM_DETAIL_ADD_URL = BASE_URL + "/outbound/item/detail/input";
const OUTBOUND_BOX_ITEM_DETAIL_ADD_URL =
  BASE_URL + "/outbound/item/detail/box/input";
const OUTBOUND_BOX_ITEM_DETAIL_LIST_URL =
  BASE_URL + "/outbound/item/detail/box/list";
const OUTBOUND_ITEM_DETAIL_EDIT_URL = BASE_URL + "/outbound/item/detail/edit";
const OUTBOUND_ITEM_DETAIL_DELETE_URL =
  BASE_URL + "/outbound/item/detail/delete";

const OUTBOUND_DETAIL_LIST_URL = BASE_URL + "/outbound/detail/list";
const OUTBOUND_DETAIL_ADD_URL = BASE_URL + "/outbound/detail/input";
const OUTBOUND_DETAIL_EDIT_URL = BASE_URL + "/outbound/detail/edit";
const OUTBOUND_DETAIL_DELETE_URL = BASE_URL + "/outbound/detail/delete";

const CREATE_INVOICE_SALES_ORDER = BASE_URL + "/so/create/invoice";
const CREATE_INVOICE_CHECK = BASE_URL + "/so/create/invoice/check";
const CREATE_INVOICE_OUTBOUND = BASE_URL + "/outbound/create/invoice";
const CREATE_INVOICE_OUTBOUND_CHECK =
  BASE_URL + "/outbound/create/invoice/check";

const OUTBOUND_TYPE_LIST_URL = BASE_URL + "/outbound/type/list";
const OUTBOUND_TYPE_ADD_URL = BASE_URL + "/outbound/type/input";
const OUTBOUND_TYPE_EDIT_URL = BASE_URL + "/outbound/type/edit";
const OUTBOUND_TYPE_DELETE_URL = BASE_URL + "/outbound/type/delete";

const INBOUND_DETAIL_LIST_URL = BASE_URL + "/inbound/detail/list";
const INBOUND_DETAIL_ADD_URL = BASE_URL + "/inbound/detail/input";
const INBOUND_DETAIL_EDIT_URL = BASE_URL + "/inbound/detail/edit";
const INBOUND_DETAIL_DELETE_URL = BASE_URL + "/inbound/detail/delete";

const INBOUND_ITEM_DETAIL_LIST_URL = BASE_URL + "/inbound/item/detail/list";
const INBOUND_ITEM_DETAIL_ADD_URL = BASE_URL + "/inbound/item/detail/input";
const INBOUND_ITEM_DETAIL_EDIT_URL = BASE_URL + "/inbound/item/detail/edit";
const INBOUND_ITEM_DETAIL_DELETE_URL = BASE_URL + "/inbound/item/detail/delete";

const INBOUND_VEHICLE_LIST_URL = BASE_URL + "/inbound/vehicle/list";
const INBOUND_VEHICLE_ADD_URL = BASE_URL + "/inbound/vehicle/input";
const INBOUND_VEHICLE_EDIT_URL = BASE_URL + "/inbound/vehicle/edit";
const INBOUND_VEHICLE_DELETE_URL = BASE_URL + "/inbound/vehicle/delete";

const INBOUND_OFFICER_ASST_LIST_URL =
  BASE_URL + "/inbound/officer-assistant/list";
const INBOUND_OFFICER_ASST_ADD_URL =
  BASE_URL + "/inbound/officer-assistant/input";
const INBOUND_OFFICER_ASST_EDIT_URL =
  BASE_URL + "/inbound/officer-assistant/edit";
const INBOUND_OFFICER_ASST_DELETE_URL =
  BASE_URL + "/inbound/officer-assistant/delete";

const INBOUND_DRIVER_OFFICER_LIST_URL =
  BASE_URL + "/inbound/driver-officer/list";
const INBOUND_DRIVER_OFFICER_ADD_URL =
  BASE_URL + "/inbound/driver-officer/input";
const INBOUND_DRIVER_OFFICER_EDIT_URL =
  BASE_URL + "/inbound/driver-officer/edit";
const INBOUND_DRIVER_OFFICER_DELETE_URL =
  BASE_URL + "/inbound/driver-officer/delete";

const ITEM_CATEGORIES_LIST_URL = BASE_URL + "/item/category/list";
const ITEM_CATEGORIES_ADD_URL = BASE_URL + "/item/category/input";
const ITEM_CATEGORIES_EDIT_URL = BASE_URL + "/item/category/edit";
const ITEM_CATEGORIES_DELETE_URL = BASE_URL + "/item/category/delete";

const ITEM_TAX_CATEGORIES_LIST_URL = BASE_URL + "/item/tax/category/list";
const ITEM_TAX_CATEGORIES_ADD_URL = BASE_URL + "/item/tax/category/input";
const ITEM_TAX_CATEGORIES_EDIT_URL = BASE_URL + "/item/tax/category/edit";
const ITEM_TAX_CATEGORIES_DELETE_URL = BASE_URL + "/item/tax/category/delete";

const ITEM_DEPRECIATION_METHODS_LIST_URL =
  BASE_URL + "/item/depreciation/method/list";
const ITEM_DEPRECIATION_METHODS_ADD_URL =
  BASE_URL + "/item/depreciation/method/input";
const ITEM_DEPRECIATION_METHODS_EDIT_URL =
  BASE_URL + "/item/depreciation/method/edit";
const ITEM_DEPRECIATION_METHODS_DELETE_URL =
  BASE_URL + "/item/depreciation/method/delete";

const ITEM_HANDLING_CATEGORIES_LIST_URL =
  BASE_URL + "/item/handling/category/list";
const ITEM_HANDLING_CATEGORIES_ADD_URL =
  BASE_URL + "/item/handling/category/input";
const ITEM_HANDLING_CATEGORIES_EDIT_URL =
  BASE_URL + "/item/handling/category/edit";
const ITEM_HANDLING_CATEGORIES_DELETE_URL =
  BASE_URL + "/item/handling/category/delete";

const ITEM_QUALITY_CATEGORIES_LIST_URL =
  BASE_URL + "/item/category/quality/list";
const ITEM_QUALITY_CATEGORIES_ADD_URL =
  BASE_URL + "/item/category/quality/input";
const ITEM_QUALITY_CATEGORIES_EDIT_URL =
  BASE_URL + "/item/category/quality/edit";
const ITEM_QUALITY_CATEGORIES_DELETE_URL =
  BASE_URL + "/item/category/quality/delete";

const ADMINISTRATION_OFFICES_LIST_URL = BASE_URL + "/admin-office/list";
const ADMINISTRATION_OFFICES_ADD_URL = BASE_URL + "/admin-office/input";
const ADMINISTRATION_OFFICES_EDIT_URL = BASE_URL + "/admin-office/edit";
const ADMINISTRATION_OFFICES_DELETE_URL = BASE_URL + "/admin-office/delete";

const PURCHASE_ORDER_LIST_URL = BASE_URL + "/po/list";
const PURCHASE_ORDER_ADD_URL = BASE_URL + "/po/v2/input";
const PURCHASE_ORDER_EDIT_V1_URL = BASE_URL + "/po/edit";
const PURCHASE_ORDER_EDIT_URL = BASE_URL + "/po/v2/edit";
const PURCHASE_ORDER_DELETE_URL = BASE_URL + "/po/delete";
const PURCHASE_ORDER_SAVE_DEFAULT_URL = BASE_URL + "/po/request/input";
const PURCHASE_ORDER_USE_SAVE_DEFAULT_URL = BASE_URL + "/po/request/list";

const RETURN_ORDER_LIST_URL = BASE_URL + "/order/return/list";
const RETURN_ORDER_ADD_URL = BASE_URL + "/order/return/v2/input";
const RETURN_ORDER_EDIT_URL = BASE_URL + "/order/return/v2/edit";
const RETURN_ORDER_DELETE_URL = BASE_URL + "/order/return/delete";

const SALES_ORDER_LIST_URL = BASE_URL + "/so/list";
const SALES_ORDER_ADD_URL = BASE_URL + "/so/v2/input";
const SALES_ORDER_EDIT_V1_URL = BASE_URL + "/so/edit";
const SALES_ORDER_EDIT_URL = BASE_URL + "/so/v2/edit";
const SALES_ORDER_DELETE_URL = BASE_URL + "/so/delete";

const SALES_ORDER_TYPE_LIST_URL = BASE_URL + "/so/type/list";
const SALES_ORDER_TYPE_ADD_URL = BASE_URL + "/so/type/input";
const SALES_ORDER_TYPE_EDIT_URL = BASE_URL + "/so/type/edit";
const SALES_ORDER_TYPE_DELETE_URL = BASE_URL + "/so/type/delete";

const SALES_ORDER_RULE_LIST_URL = BASE_URL + "/so/rule/list";
const SALES_ORDER_RULE_ADD_URL = BASE_URL + "/so/rule/input";
const SALES_ORDER_RULE_EDIT_URL = BASE_URL + "/so/rule/edit";
const SALES_ORDER_RULE_DELETE_URL = BASE_URL + "/so/rule/delete";

const SALES_ORDER_APPROVAL_LIST_URL = BASE_URL + "/so/approval/list";
const SALES_ORDER_APPROVAL_ADD_URL = BASE_URL + "/so/approval/v2/input";
const SALES_ORDER_APPROVAL_EDIT_URL = BASE_URL + "/so/approval/edit";
const SALES_ORDER_APPROVAL_DELETE_URL = BASE_URL + "/so/approval/delete";
const SALES_ORDER_APPROVAL_CHECK_URL = BASE_URL + "/so/approval/check";

const SALES_ORDER_PRINT_JOB_LIST_URL = BASE_URL + "/so/print-job/list";
const SALES_ORDER_PRINT_JOB_ADD_URL = BASE_URL + "/so/print-job/input";
const SALES_ORDER_PRINT_JOB_EDIT_URL = BASE_URL + "/so/print-job/edit";
const SALES_ORDER_PRINT_JOB_DELETE_URL = BASE_URL + "/so/print-job/delete";

const SALES_ORDER_PAYMENT_LIST_URL = BASE_URL + "/so/payment/list";
const SALES_ORDER_PAYMENT_ADD_URL = BASE_URL + "/so/payment/input";
const SALES_ORDER_PAYMENT_EDIT_URL = BASE_URL + "/so/payment/edit";
const SALES_ORDER_PAYMENT_DELETE_URL = BASE_URL + "/so/payment/delete";

const SALES_ORDER_REALIZATION_CONFIRM_URL = BASE_URL + "/so/realization/confirm";

const TRANSFER_METHOD_LIST_URL = BASE_URL + "/bank/transfer/method/list";
const TRANSFER_METHOD_ADD_URL = BASE_URL + "/bank/transfer/method/input";
const TRANSFER_METHOD_EDIT_URL = BASE_URL + "/bank/transfer/method/edit";
const TRANSFER_METHOD_DELETE_URL = BASE_URL + "/bank/transfer/method/delete";

const MASTER_WAREHOUSE_TRANSFER_LIST_URL =
  BASE_URL + "/warehouse/transfer/list";
const MASTER_WAREHOUSE_TRANSFER_ADD_URL =
  BASE_URL + "/warehouse/transfer/input";
const MASTER_WAREHOUSE_TRANSFER_EDIT_URL =
  BASE_URL + "/warehouse/transfer/edit";
const MASTER_WAREHOUSE_TRANSFER_DELETE_URL =
  BASE_URL + "/warehouse/transfer/delete";

const MEASURE_UNIT_LIST_URL = BASE_URL + "/unit/measurement/list";
const MEASURE_UNIT_ADD_URL = BASE_URL + "/unit/measurement/input";
const MEASURE_UNIT_EDIT_URL = BASE_URL + "/unit/measurement/edit";
const MEASURE_UNIT_DELETE_URL = BASE_URL + "/unit/measurement/delete";

const PACKAGE_TYPE_LIST_URL = BASE_URL + "/package/type/list";
const PACKAGE_TYPE_ADD_URL = BASE_URL + "/package/type/input";
const PACKAGE_TYPE_EDIT_URL = BASE_URL + "/package/type/edit";
const PACKAGE_TYPE_DELETE_URL = BASE_URL + "/package/type/delete";

const PACKAGE_MATERIAL_TYPE_LIST_URL = BASE_URL + "/package/material-type/list";
const PACKAGE_MATERIAL_TYPE_ADD_URL = BASE_URL + "/package/material-type/input";
const PACKAGE_MATERIAL_TYPE_EDIT_URL = BASE_URL + "/package/material-type/edit";
const PACKAGE_MATERIAL_TYPE_DELETE_URL =
  BASE_URL + "/package/material-type/delete";

const PACKAGE_MATERIAL_COMPOSITION_LIST_URL =
  BASE_URL + "/package/material-composition/list";
const PACKAGE_MATERIAL_COMPOSITION_ADD_URL =
  BASE_URL + "/package/material-composition/input";
const PACKAGE_MATERIAL_COMPOSITION_EDIT_URL =
  BASE_URL + "/package/material-composition/edit";
const PACKAGE_MATERIAL_COMPOSITION_DELETE_URL =
  BASE_URL + "/package/material-composition/delete";

const REPACKAGING_LIST_URL = BASE_URL + "/package/repack/list";
const REPACKAGING_ADD_URL = BASE_URL + "/package/repack/input";
const REPACKAGING_EDIT_URL = BASE_URL + "/package/repack/edit";
const REPACKAGING_DELETE_URL = BASE_URL + "/package/repack/delete";

const REPACKAGING_ITEM_LIST_URL = BASE_URL + "/package/repack/item/list";
const REPACKAGING_ITEM_ADD_URL = BASE_URL + "/package/repack/item/input";
const REPACKAGING_ITEM_EDIT_URL = BASE_URL + "/package/repack/item/edit";
const REPACKAGING_ITEM_DELETE_URL = BASE_URL + "/package/repack/item/delete";

const PAYMENT_TERM_CONDITION_LIST_URL =
  BASE_URL + "/payment/term-condition/list";
const PAYMENT_TERM_CONDITION_ADD_URL =
  BASE_URL + "/payment/term-condition/input";
const PAYMENT_TERM_CONDITION_EDIT_URL =
  BASE_URL + "/payment/term-condition/edit";
const PAYMENT_TERM_CONDITION_DELETE_URL =
  BASE_URL + "/payment/term-condition/delete";

const PURCHASE_ORDER_TYPE_LIST_URL = BASE_URL + "/po/type/list";
const PURCHASE_ORDER_TYPE_ADD_URL = BASE_URL + "/po/type/input";
const PURCHASE_ORDER_TYPE_EDIT_URL = BASE_URL + "/po/type/edit";
const PURCHASE_ORDER_TYPE_DELETE_URL = BASE_URL + "/po/type/delete";

const PURCHASE_ORDER_APPROVAL_LIST_URL = BASE_URL + "/po/approval/list";
const PURCHASE_ORDER_APPROVAL_ADD_URL = BASE_URL + "/po/approval/v2/input";
const PURCHASE_ORDER_APPROVAL_EDIT_URL = BASE_URL + "/po/approval/edit";
const PURCHASE_ORDER_APPROVAL_DELETE_URL = BASE_URL + "/po/approval/delete";
const PURCHASE_ORDER_APPROVAL_CHECK_URL = BASE_URL + "/po/approval/check";

const PURCHASE_ORDER_RULE_LIST_URL = BASE_URL + "/po/rule/list";
const PURCHASE_ORDER_RULE_ADD_URL = BASE_URL + "/po/rule/input";
const PURCHASE_ORDER_RULE_EDIT_URL = BASE_URL + "/po/rule/edit";
const PURCHASE_ORDER_RULE_DELETE_URL = BASE_URL + "/po/rule/delete";

const PURCHASE_ORDER_PAYMENT_LIST_URL = BASE_URL + "/po/payment/list";
const PURCHASE_ORDER_PAYMENT_ADD_URL = BASE_URL + "/po/payment/input";
const PURCHASE_ORDER_PAYMENT_EDIT_URL = BASE_URL + "/po/payment/edit";
const PURCHASE_ORDER_PAYMENT_DELETE_URL = BASE_URL + "/po/payment/delete";

const PURCHASE_ORDER_PRINT_JOB_LIST_URL = BASE_URL + "/po/print-job/list";
const PURCHASE_ORDER_PRINT_JOB_ADD_URL = BASE_URL + "/po/print-job/input";
const PURCHASE_ORDER_PRINT_JOB_EDIT_URL = BASE_URL + "/po/print-job/edit";
const PURCHASE_ORDER_PRINT_JOB_DELETE_URL = BASE_URL + "/po/print-job/delete";

const WAREHOUSE_LIST_URL = BASE_URL + "/warehouse/list";
const WAREHOUSE_ADD_URL = BASE_URL + "/warehouse/input";
const WAREHOUSE_EDIT_URL = BASE_URL + "/warehouse/edit";
const WAREHOUSE_DELETE_URL = BASE_URL + "/warehouse/delete";

const WAREHOUSE_AREAS_LIST_URL = BASE_URL + "/warehouse/area/list";
const WAREHOUSE_AREAS_ADD_URL = BASE_URL + "/warehouse/area/input";
const WAREHOUSE_AREAS_EDIT_URL = BASE_URL + "/warehouse/area/edit";
const WAREHOUSE_AREAS_DELETE_URL = BASE_URL + "/warehouse/area/delete";

const WAREHOUSE_RULES_LIST_URL = BASE_URL + "/warehouse/rule/list";
const WAREHOUSE_RULES_ADD_URL = BASE_URL + "/warehouse/rule/input";
const WAREHOUSE_RULES_EDIT_URL = BASE_URL + "/warehouse/rule/edit";
const WAREHOUSE_RULES_DELETE_URL = BASE_URL + "/warehouse/rule/delete";

const RACK_MANAGEMENT = BASE_URL + "/warehouse/rack";
const RACK_MANAGEMENT_LIST = RACK_MANAGEMENT + "/list";
const RACK_MANAGEMENT_INPUT = RACK_MANAGEMENT + "/input";
const RACK_MANAGEMENT_EDIT = RACK_MANAGEMENT + "/edit";
const RACK_MANAGEMENT_DELETE = RACK_MANAGEMENT + "/delete";
const RACK_MANAGEMENT_HISTORY = RACK_MANAGEMENT + "/history";
const RACK_MANAGEMENT_HISTORY_LIST = RACK_MANAGEMENT_HISTORY + "/list";
const RACK_MANAGEMENT_HISTORY_INPUT = RACK_MANAGEMENT_HISTORY + "/input";
const RACK_MANAGEMENT_HISTORY_EDIT = RACK_MANAGEMENT_HISTORY + "/edit";
const RACK_MANAGEMENT_HISTORY_DELETE = RACK_MANAGEMENT_HISTORY + "/delete";

const RACK_MANAGEMENT_V2 = BASE_URL + "/warehouse/rack/v2";
const RACK_MANAGEMENT_LIST_V2 = RACK_MANAGEMENT_V2 + "/list";
const RACK_MANAGEMENT_INPUT_V2 = RACK_MANAGEMENT_V2 + "/input";
const RACK_MANAGEMENT_EDIT_V2 = RACK_MANAGEMENT_V2 + "/edit";
const RACK_MANAGEMENT_DELETE_V2 = RACK_MANAGEMENT_V2 + "/delete";
const RACK_MANAGEMENT_HISTORY_V2 = RACK_MANAGEMENT_V2 + "/history";
const RACK_MANAGEMENT_HISTORY_LIST_V2 = RACK_MANAGEMENT_HISTORY_V2 + "/list";
const RACK_MANAGEMENT_HISTORY_INPUT_V2 = RACK_MANAGEMENT_HISTORY_V2 + "/input";
const RACK_MANAGEMENT_HISTORY_EDIT_V2 = RACK_MANAGEMENT_HISTORY_V2 + "/edit";
const RACK_MANAGEMENT_HISTORY_DELETE_V2 =
  RACK_MANAGEMENT_HISTORY_V2 + "/delete";

const WAREHOUSE_OPERATIONS_RULES_LIST_URL =
  BASE_URL + "/warehouse/operation/rule/list";
const WAREHOUSE_OPERATIONS_RULES_ADD_URL =
  BASE_URL + "/warehouse/operation/rule/input";
const WAREHOUSE_OPERATIONS_RULES_EDIT_URL =
  BASE_URL + "/warehouse/operation/rule/edit";
const WAREHOUSE_OPERATIONS_RULES_DELETE_URL =
  BASE_URL + "/warehouse/operation/rule/delete";

const WAREHOUSE_TRANSFER_APPROVAL_LIST_URL =
  BASE_URL + "/warehouse/transfer/approval/list";
const WAREHOUSE_TRANSFER_APPROVAL_ADD_URL =
  BASE_URL + "/warehouse/transfer/approval/input";
const WAREHOUSE_TRANSFER_APPROVAL_EDIT_URL =
  BASE_URL + "/warehouse/transfer/approval/edit";
const WAREHOUSE_TRANSFER_APPROVAL_DELETE_URL =
  BASE_URL + "/warehouse/transfer/approval/delete";
const WAREHOUSE_TRANSFER_APPROVAL_CHECK_URL =
  BASE_URL + "/warehouse/transfer/approval/check";

const WAREHOUSE_TRANSFER_APPROVAL_SETTING_LIST_URL =
  BASE_URL + "/warehouse/transfer/approval/setting/list";
const WAREHOUSE_TRANSFER_APPROVAL_SETTING_ADD_URL =
  BASE_URL + "/warehouse/transfer/approval/setting/input";
const WAREHOUSE_TRANSFER_APPROVAL_SETTING_EDIT_URL =
  BASE_URL + "/warehouse/transfer/approval/setting/edit";
const WAREHOUSE_TRANSFER_APPROVAL_SETTING_DELETE_URL =
  BASE_URL + "/warehouse/transfer/approval/setting/delete";

const WAREHOUSE_TRANSFER_APPROVAL_TYPE_LIST_URL =
  BASE_URL + "/warehouse/transfer/approval/type/list";
const WAREHOUSE_TRANSFER_APPROVAL_TYPE_ADD_URL =
  BASE_URL + "/warehouse/transfer/approval/type/input";
const WAREHOUSE_TRANSFER_APPROVAL_TYPE_EDIT_URL =
  BASE_URL + "/warehouse/transfer/approval/type/edit";
const WAREHOUSE_TRANSFER_APPROVAL_TYPE_DELETE_URL =
  BASE_URL + "/warehouse/transfer/approval/type/delete";

const WAREHOUSE_TRANSFER_DETAIL_LIST_URL = BASE_URL + "/list";
const WAREHOUSE_TRANSFER_DETAIL_ADD_URL = BASE_URL + "/input";
const WAREHOUSE_TRANSFER_DETAIL_EDIT_URL = BASE_URL + "/edit";
const WAREHOUSE_TRANSFER_DETAIL_DELETE_URL = BASE_URL + "/delete";

const WAREHOUSE_TRANSFER_ITEM_LIST_URL =
  BASE_URL + "/warehouse/transfer/item/list";
const WAREHOUSE_TRANSFER_ITEM_ADD_URL =
  BASE_URL + "/warehouse/transfer/item/input";
const WAREHOUSE_TRANSFER_ITEM_EDIT_URL =
  BASE_URL + "/warehouse/transfer/item/edit";
const WAREHOUSE_TRANSFER_ITEM_DELETE_URL =
  BASE_URL + "/warehouse/transfer/item/delete";

const WAREHOUSE_GEOFENCES_LIST_URL =
  BASE_URL + "/warehouse/geofence/point/list";
const WAREHOUSE_GEOFENCES_ADD_URL =
  BASE_URL + "/warehouse/geofence/point/input";
const WAREHOUSE_GEOFENCES_EDIT_URL =
  BASE_URL + "/warehouse/geofence/point/edit";
const WAREHOUSE_GEOFENCES_DELETE_URL =
  BASE_URL + "/warehouse/geofence/point/delete";

const VENDOR_LIST_URL = BASE_URL + "/vendor/list";
const VENDOR_ADD_URL = BASE_URL + "/vendor/input";
const VENDOR_EDIT_URL = BASE_URL + "/vendor/edit";
const VENDOR_DELETE_URL = BASE_URL + "/vendor/delete";

const VENDOR_BANK_LIST_URL = BASE_URL + "/vendor/bank/list";
const VENDOR_BANK_ADD_URL = BASE_URL + "/vendor/bank/input";
const VENDOR_BANK_EDIT_URL = BASE_URL + "/vendor/bank/edit";
const VENDOR_BANK_DELETE_URL = BASE_URL + "/vendor/bank/delete";

const VENDOR_PIC_LIST_URL = BASE_URL + "/vendor/pic/list";
const VENDOR_PIC_ADD_URL = BASE_URL + "/vendor/pic/input";
const VENDOR_PIC_EDIT_URL = BASE_URL + "/vendor/pic/edit";
const VENDOR_PIC_DELETE_URL = BASE_URL + "/vendor/pic/delete";

const SO_ITEM_DETAIL_LIST_URL = BASE_URL + "/so/item/detail/list";
const SO_ITEM_DETAIL_ADD_URL = BASE_URL + "/so/item/detail/input";
const SO_ITEM_DETAIL_EDIT_URL = BASE_URL + "/so/item/detail/edit";
const SO_ITEM_DETAIL_DELETE_URL = BASE_URL + "/so/item/detail/delete";

const PO_ITEM_DETAIL_LIST_URL = BASE_URL + "/po/item/detail/list";
const PO_ITEM_DETAIL_ADD_URL = BASE_URL + "/po/item/detail/input";
const PO_ITEM_DETAIL_EDIT_URL = BASE_URL + "/po/item/detail/edit";
const PO_ITEM_DETAIL_DELETE_URL = BASE_URL + "/po/item/detail/delete";

const INTERCOMPANY_LIST_URL = BASE_URL + "/so/list/intercompany";
const INTERCOMPANY_APPROVAL_URL = BASE_URL + "/so/approval/intercompany";

const OPNAME_TASK_LIST_URL = BASE_URL + "/opname/list";
const OPNAME_TASK_INPUT_URL = BASE_URL + "/opname/input";
const OPNAME_TASK_EDIT_URL = BASE_URL + "/opname/edit";
const OPNAME_GENERATE_TASK_DETAIL =
  BASE_URL + "/opname/task-order-detail/generate";
const OPNAME_GENERATE_TASK_DETAIL_LIST =
  BASE_URL + "/opname/task-order-detail/list";
const OPNAME_GENERATE_TASK_DETAIL_EDIT =
  BASE_URL + "/opname/task-order-detail/edit";

const IOT_BINDING_LIST = BASE_URL + "/iot-binding/list";
const IOT_BINDING_ADD = BASE_URL + "/iot-binding/input-scanner-new";
const IOT_BINDING_EDIT = BASE_URL + "/iot-binding/edit";

const IOT_BINDING_ITEM_GROUP_LIST = BASE_URL + "/iot-binding/item-group/list";
const IOT_BINDING_ITEM_GROUP_INPUT = BASE_URL + "/iot-binding/item-group/input";
const IOT_BINDING_ITEM_GROUP_EDIT = BASE_URL + "/iot-binding/item-group/edit";
const IOT_BINDING_ITEM_GROUP_DELETE =
  BASE_URL + "/iot-binding/item-group/delete";

const IOT_BINDING_GROUP_LIST = BASE_URL + "/iot-binding/group/list";
const IOT_BINDING_GROUP_INPUT = BASE_URL + "/iot-binding/group/input";
const IOT_BINDING_GROUP_EDIT = BASE_URL + "/iot-binding/group/edit";
const IOT_BINDING_GROUP_DELETE = BASE_URL + "/iot-binding/group/delete";

const IOT_BINDING_ITEM_DETAIL_LIST =
  BASE_URL + "/iot-binding/item-group/detail/list";
const IOT_BINDING_ITEM_DETAIL_INPUT =
  BASE_URL + "/iot-binding/item-group/detail/input";
const IOT_BINDING_ITEM_DETAIL_EDIT =
  BASE_URL + "/iot-binding/item-group/detail/edit";
const IOT_BINDING_ITEM_DETAIL_DELETE =
  BASE_URL + "/iot-binding/item-group/detail/delete";

const IOT_BINDING_TAGGING_GROUP_LIST =
  BASE_URL + "/iot-binding/tagging-group/list";
const IOT_BINDING_TAGGING_GROUP_INPUT =
  BASE_URL + "/iot-binding/tagging-group/input";
const IOT_BINDING_TAGGING_GROUP_EDIT =
  BASE_URL + "/iot-binding/tagging-group/edit";

const WAREHOUSE_MAPPING_LIST = BASE_URL + "/warehouse/mapping/List";
const WAREHOUSE_MAPPING_INPUT = BASE_URL + "/warehouse/mapping/input";
const WAREHOUSE_MAPPING_EDIT = BASE_URL + "/warehouse/mapping/edit";
const WAREHOUSE_MAPPING_DELETE = BASE_URL + "/warehouse/mapping/delete";
export {
  OPERATING_AREA_LIST_URL,
  OPERATING_AREA_ADD_URL,
  OPERATING_AREA_EDIT_URL,
  OPERATING_AREA_DELETE_URL,
  OPERATING_AREA_GEOFENCE_LIST_URL,
  OPERATING_AREA_GEOFENCE_ADD_URL,
  OPERATING_AREA_GEOFENCE_EDIT_URL,
  OPERATING_AREA_GEOFENCE_DELETE_URL,
  UNITS_LIST_URL,
  UNIT_ADD_URL,
  UNIT_EDIT_URL,
  UNIT_DELETE_URL,
  UNIT_LIST_V2_URL,
  COUNTRIES_LIST_URL,
  TRACKER_TYPES_LIST_URL,
  IDENTITY_TYPES_LIST_URL,
  OFFICER_TYPES_LIST_URL,
  PROVINCES_LIST_URL,
  BATCH_LIST_URL,
  BACTH_ADD_URL,
  BACTH_EDIT_URL,
  BACTH_DELETE_URL,
  BRANDS_LIST_URL,
  BRANDS_ADD_URL,
  BRANDS_EDIT_URL,
  BRANDS_DELETE_URL,
  COMPANY_BANK_ACC_LIST_URL,
  COMPANY_BANK_ACC_ADD_URL,
  COMPANY_BANK_ACC_EDIT_URL,
  COMPANY_BANK_ACC_DELETE_URL,
  DISPOSAL_ITEM_ADD_URL,
  DISPOSAL_ITEM_DELETE_URL,
  DISPOSAL_ITEM_EDIT_URL,
  DISPOSAL_ITEM_LIST_URL,
  DISPOSAL_ITEM_DETAIL_LIST_URL,
  DISPOSAL_ITEM_DETAIL_ADD_URL,
  DISPOSAL_ITEM_DETAIL_EDIT_URL,
  DISPOSAL_ITEM_DETAIL_DELETE_URL,
  DISPOSAL_TYPE_ADD_URL,
  DISPOSAL_TYPE_DELETE_URL,
  DISPOSAL_TYPE_EDIT_URL,
  DISPOSAL_TYPE_LIST_URL,
  DIVISION_LIST_URL,
  DIVISION_ADD_URL,
  DIVISION_DELETE_URL,
  DIVISION_EDIT_URL,
  INBOUND_DETAIL_ADD_URL,
  INBOUND_DETAIL_DELETE_URL,
  INBOUND_DETAIL_EDIT_URL,
  INBOUND_DETAIL_LIST_URL,
  INBOUND_ITEM_DETAIL_ADD_URL,
  INBOUND_ITEM_DETAIL_DELETE_URL,
  INBOUND_ITEM_DETAIL_EDIT_URL,
  INBOUND_ITEM_DETAIL_LIST_URL,
  INBOUND_OFFICER_ASST_ADD_URL,
  INBOUND_OFFICER_ASST_DELETE_URL,
  INBOUND_OFFICER_ASST_EDIT_URL,
  INBOUND_OFFICER_ASST_LIST_URL,
  INBOUND_DRIVER_OFFICER_ADD_URL,
  INBOUND_DRIVER_OFFICER_DELETE_URL,
  INBOUND_DRIVER_OFFICER_EDIT_URL,
  INBOUND_DRIVER_OFFICER_LIST_URL,
  INBOUND_VEHICLE_ADD_URL,
  INBOUND_VEHICLE_DELETE_URL,
  INBOUND_VEHICLE_EDIT_URL,
  INBOUND_VEHICLE_LIST_URL,
  ITEM_TAX_CATEGORIES_LIST_URL,
  ITEM_TAX_CATEGORIES_ADD_URL,
  ITEM_TAX_CATEGORIES_EDIT_URL,
  ITEM_TAX_CATEGORIES_DELETE_URL,
  ITEM_HANDLING_CATEGORIES_LIST_URL,
  ITEM_HANDLING_CATEGORIES_ADD_URL,
  ITEM_HANDLING_CATEGORIES_EDIT_URL,
  ITEM_HANDLING_CATEGORIES_DELETE_URL,
  ITEM_DEPRECIATION_METHODS_LIST_URL,
  ITEM_DEPRECIATION_METHODS_ADD_URL,
  ITEM_DEPRECIATION_METHODS_EDIT_URL,
  ITEM_DEPRECIATION_METHODS_DELETE_URL,
  ITEM_CATEGORIES_LIST_URL,
  ITEM_CATEGORIES_ADD_URL,
  ITEM_CATEGORIES_EDIT_URL,
  ITEM_CATEGORIES_DELETE_URL,
  ITEM_QUALITY_CATEGORIES_LIST_URL,
  ITEM_QUALITY_CATEGORIES_ADD_URL,
  ITEM_QUALITY_CATEGORIES_EDIT_URL,
  ITEM_QUALITY_CATEGORIES_DELETE_URL,
  ADMINISTRATION_OFFICES_LIST_URL,
  ADMINISTRATION_OFFICES_ADD_URL,
  ADMINISTRATION_OFFICES_EDIT_URL,
  ADMINISTRATION_OFFICES_DELETE_URL,
  OUTBOUND_TYPE_ADD_URL,
  OUTBOUND_TYPE_DELETE_URL,
  OUTBOUND_TYPE_EDIT_URL,
  OUTBOUND_TYPE_LIST_URL,
  OUTBOUND_DETAIL_ADD_URL,
  OUTBOUND_DETAIL_DELETE_URL,
  OUTBOUND_DETAIL_EDIT_URL,
  OUTBOUND_DETAIL_LIST_URL,
  OUTBOUND_ITEM_DETAIL_LIST_URL,
  OUTBOUND_ITEM_DETAIL_ADD_URL,
  OUTBOUND_BOX_ITEM_DETAIL_LIST_URL,
  OUTBOUND_BOX_ITEM_DETAIL_ADD_URL,
  OUTBOUND_ITEM_DETAIL_EDIT_URL,
  OUTBOUND_ITEM_DETAIL_DELETE_URL,
  MASTER_INBOUND_ADD_URL,
  MASTER_INBOUND_DELETE_URL,
  MASTER_INBOUND_EDIT_URL,
  MASTER_INBOUND_V1_EDIT_URL,
  MASTER_INBOUND_LIST_URL,
  MASTER_OUTBOUND_ADD_URL,
  MASTER_OUTBOUND_ADD_V3_URL,
  MASTER_OUTBOUND_DELETE_URL,
  MASTER_OUTBOUND_EDIT_URL,
  MASTER_OUTBOUND_LIST_URL,
  MASTER_ITEM_ADD_URL,
  MASTER_ITEM_DELETE_URL,
  MASTER_ITEM_EDIT_URL,
  MASTER_ITEM_LIST_URL,
  MASTER_ITEM_LIST_URL_V2,
  MASTER_ITEM_ADD_URL_V2,
  MASTER_ITEM_LIST_URL_V3,
  MASTER_ITEM_EDIT_URL_V2,
  MASTER_ITEM_CART_LIST,
  MASTER_ITEM_CART_INPUT,
  MASTER_ITEM_CART_EDIT,
  MASTER_ITEM_CART_DELETE,
  MASTER_ITEM_STOCK_COUNT_URL,
  MASTER_ITEM_DETAIL_ADD_URL,
  MASTER_ITEM_DETAIL_DELETE_URL,
  MASTER_ITEM_DETAIL_EDIT_URL,
  MASTER_ITEM_DETAIL_LIST_URL,
  MASTER_ITEM_DETAIL_ADJUSTMENT_LIST_URL,
  MASTER_ITEM_DETAIL_ADJUSTMENT_ADD_URL,
  MUTATION_LIST_URL,
  MASTER_ITEM_DEFECT_ADD_URL,
  MASTER_ITEM_DEFECT_DELETE_URL,
  MASTER_ITEM_DEFECT_EDIT_URL,
  MASTER_ITEM_DEFECT_LIST_URL,
  SALES_ORDER_ADD_URL,
  SALES_ORDER_DELETE_URL,
  SALES_ORDER_EDIT_V1_URL,
  SALES_ORDER_EDIT_URL,
  SALES_ORDER_LIST_URL,
  SALES_ORDER_TYPE_ADD_URL,
  SALES_ORDER_TYPE_DELETE_URL,
  SALES_ORDER_TYPE_EDIT_URL,
  SALES_ORDER_TYPE_LIST_URL,
  SALES_ORDER_RULE_ADD_URL,
  SALES_ORDER_RULE_DELETE_URL,
  SALES_ORDER_RULE_EDIT_URL,
  SALES_ORDER_RULE_LIST_URL,
  SALES_ORDER_APPROVAL_ADD_URL,
  SALES_ORDER_APPROVAL_DELETE_URL,
  SALES_ORDER_APPROVAL_EDIT_URL,
  SALES_ORDER_APPROVAL_LIST_URL,
  SALES_ORDER_APPROVAL_CHECK_URL,
  SALES_ORDER_PRINT_JOB_ADD_URL,
  SALES_ORDER_PRINT_JOB_DELETE_URL,
  SALES_ORDER_PRINT_JOB_EDIT_URL,
  SALES_ORDER_PRINT_JOB_LIST_URL,
  SALES_ORDER_PAYMENT_ADD_URL,
  SALES_ORDER_PAYMENT_DELETE_URL,
  SALES_ORDER_PAYMENT_EDIT_URL,
  SALES_ORDER_PAYMENT_LIST_URL,
  SALES_ORDER_REALIZATION_CONFIRM_URL,
  TRANSFER_METHOD_ADD_URL,
  TRANSFER_METHOD_DELETE_URL,
  TRANSFER_METHOD_EDIT_URL,
  TRANSFER_METHOD_LIST_URL,
  MEASURE_UNIT_LIST_URL,
  MEASURE_UNIT_ADD_URL,
  MEASURE_UNIT_EDIT_URL,
  MEASURE_UNIT_DELETE_URL,
  PACKAGE_TYPE_LIST_URL,
  PACKAGE_TYPE_ADD_URL,
  PACKAGE_TYPE_EDIT_URL,
  PACKAGE_TYPE_DELETE_URL,
  PACKAGE_MATERIAL_TYPE_LIST_URL,
  PACKAGE_MATERIAL_TYPE_ADD_URL,
  PACKAGE_MATERIAL_TYPE_EDIT_URL,
  PACKAGE_MATERIAL_TYPE_DELETE_URL,
  PACKAGE_MATERIAL_COMPOSITION_LIST_URL,
  PACKAGE_MATERIAL_COMPOSITION_ADD_URL,
  PACKAGE_MATERIAL_COMPOSITION_EDIT_URL,
  PACKAGE_MATERIAL_COMPOSITION_DELETE_URL,
  REPACKAGING_LIST_URL,
  REPACKAGING_ADD_URL,
  REPACKAGING_EDIT_URL,
  REPACKAGING_DELETE_URL,
  REPACKAGING_ITEM_LIST_URL,
  REPACKAGING_ITEM_ADD_URL,
  REPACKAGING_ITEM_EDIT_URL,
  REPACKAGING_ITEM_DELETE_URL,
  RETURN_ORDER_ADD_URL,
  RETURN_ORDER_DELETE_URL,
  RETURN_ORDER_EDIT_URL,
  RETURN_ORDER_LIST_URL,
  PAYMENT_TERM_CONDITION_ADD_URL,
  PAYMENT_TERM_CONDITION_DELETE_URL,
  PAYMENT_TERM_CONDITION_LIST_URL,
  PAYMENT_TERM_CONDITION_EDIT_URL,
  PURCHASE_ORDER_ADD_URL,
  PURCHASE_ORDER_DELETE_URL,
  PURCHASE_ORDER_EDIT_V1_URL,
  PURCHASE_ORDER_EDIT_URL,
  PURCHASE_ORDER_LIST_URL,
  PURCHASE_ORDER_SAVE_DEFAULT_URL,
  PURCHASE_ORDER_USE_SAVE_DEFAULT_URL,
  PURCHASE_ORDER_TYPE_ADD_URL,
  PURCHASE_ORDER_TYPE_DELETE_URL,
  PURCHASE_ORDER_TYPE_EDIT_URL,
  PURCHASE_ORDER_TYPE_LIST_URL,
  PURCHASE_ORDER_RULE_ADD_URL,
  PURCHASE_ORDER_RULE_DELETE_URL,
  PURCHASE_ORDER_RULE_EDIT_URL,
  PURCHASE_ORDER_RULE_LIST_URL,
  PURCHASE_ORDER_APPROVAL_ADD_URL,
  PURCHASE_ORDER_APPROVAL_DELETE_URL,
  PURCHASE_ORDER_APPROVAL_EDIT_URL,
  PURCHASE_ORDER_APPROVAL_LIST_URL,
  PURCHASE_ORDER_APPROVAL_CHECK_URL,
  PURCHASE_ORDER_PRINT_JOB_ADD_URL,
  PURCHASE_ORDER_PRINT_JOB_DELETE_URL,
  PURCHASE_ORDER_PRINT_JOB_EDIT_URL,
  PURCHASE_ORDER_PRINT_JOB_LIST_URL,
  PURCHASE_ORDER_PAYMENT_ADD_URL,
  PURCHASE_ORDER_PAYMENT_DELETE_URL,
  PURCHASE_ORDER_PAYMENT_EDIT_URL,
  PURCHASE_ORDER_PAYMENT_LIST_URL,
  VENDOR_ADD_URL,
  VENDOR_DELETE_URL,
  VENDOR_EDIT_URL,
  VENDOR_LIST_URL,
  VENDOR_BANK_ADD_URL,
  VENDOR_BANK_DELETE_URL,
  VENDOR_BANK_EDIT_URL,
  VENDOR_BANK_LIST_URL,
  VENDOR_PIC_ADD_URL,
  VENDOR_PIC_DELETE_URL,
  VENDOR_PIC_EDIT_URL,
  VENDOR_PIC_LIST_URL,
  WAREHOUSE_LIST_URL,
  WAREHOUSE_ADD_URL,
  WAREHOUSE_EDIT_URL,
  WAREHOUSE_DELETE_URL,
  WAREHOUSE_AREAS_ADD_URL,
  WAREHOUSE_AREAS_DELETE_URL,
  WAREHOUSE_AREAS_EDIT_URL,
  WAREHOUSE_AREAS_LIST_URL,
  WAREHOUSE_RULES_LIST_URL,
  WAREHOUSE_RULES_ADD_URL,
  WAREHOUSE_RULES_DELETE_URL,
  WAREHOUSE_RULES_EDIT_URL,
  WAREHOUSE_OPERATIONS_RULES_ADD_URL,
  WAREHOUSE_OPERATIONS_RULES_DELETE_URL,
  WAREHOUSE_OPERATIONS_RULES_EDIT_URL,
  WAREHOUSE_OPERATIONS_RULES_LIST_URL,
  WAREHOUSE_GEOFENCES_ADD_URL,
  WAREHOUSE_GEOFENCES_DELETE_URL,
  WAREHOUSE_GEOFENCES_EDIT_URL,
  WAREHOUSE_GEOFENCES_LIST_URL,
  MASTER_WAREHOUSE_TRANSFER_LIST_URL,
  MASTER_WAREHOUSE_TRANSFER_ADD_URL,
  MASTER_WAREHOUSE_TRANSFER_EDIT_URL,
  MASTER_WAREHOUSE_TRANSFER_DELETE_URL,
  WAREHOUSE_TRANSFER_APPROVAL_LIST_URL,
  WAREHOUSE_TRANSFER_APPROVAL_ADD_URL,
  WAREHOUSE_TRANSFER_APPROVAL_EDIT_URL,
  WAREHOUSE_TRANSFER_APPROVAL_DELETE_URL,
  WAREHOUSE_TRANSFER_APPROVAL_SETTING_LIST_URL,
  WAREHOUSE_TRANSFER_APPROVAL_SETTING_ADD_URL,
  WAREHOUSE_TRANSFER_APPROVAL_SETTING_EDIT_URL,
  WAREHOUSE_TRANSFER_APPROVAL_SETTING_DELETE_URL,
  WAREHOUSE_TRANSFER_APPROVAL_TYPE_LIST_URL,
  WAREHOUSE_TRANSFER_APPROVAL_TYPE_ADD_URL,
  WAREHOUSE_TRANSFER_APPROVAL_TYPE_EDIT_URL,
  WAREHOUSE_TRANSFER_APPROVAL_TYPE_DELETE_URL,
  WAREHOUSE_TRANSFER_APPROVAL_CHECK_URL,
  WAREHOUSE_TRANSFER_DETAIL_LIST_URL,
  WAREHOUSE_TRANSFER_DETAIL_ADD_URL,
  WAREHOUSE_TRANSFER_DETAIL_EDIT_URL,
  WAREHOUSE_TRANSFER_DETAIL_DELETE_URL,
  WAREHOUSE_TRANSFER_ITEM_LIST_URL,
  WAREHOUSE_TRANSFER_ITEM_ADD_URL,
  WAREHOUSE_TRANSFER_ITEM_EDIT_URL,
  WAREHOUSE_TRANSFER_ITEM_DELETE_URL,
  SO_ITEM_DETAIL_LIST_URL,
  SO_ITEM_DETAIL_ADD_URL,
  SO_ITEM_DETAIL_EDIT_URL,
  SO_ITEM_DETAIL_DELETE_URL,
  PO_ITEM_DETAIL_LIST_URL,
  PO_ITEM_DETAIL_ADD_URL,
  PO_ITEM_DETAIL_EDIT_URL,
  PO_ITEM_DETAIL_DELETE_URL,
  INTERCOMPANY_LIST_URL,
  INTERCOMPANY_APPROVAL_URL,
  CREATE_INVOICE_SALES_ORDER,
  CREATE_INVOICE_CHECK,
  CREATE_INVOICE_OUTBOUND,
  CREATE_INVOICE_OUTBOUND_CHECK,
  RACK_MANAGEMENT_LIST,
  RACK_MANAGEMENT_INPUT,
  RACK_MANAGEMENT_EDIT,
  RACK_MANAGEMENT_DELETE,
  RACK_MANAGEMENT_HISTORY_LIST,
  RACK_MANAGEMENT_HISTORY_INPUT,
  RACK_MANAGEMENT_HISTORY_EDIT,
  RACK_MANAGEMENT_HISTORY_DELETE,
  RACK_MANAGEMENT_LIST_V2,
  RACK_MANAGEMENT_INPUT_V2,
  RACK_MANAGEMENT_EDIT_V2,
  RACK_MANAGEMENT_DELETE_V2,
  RACK_MANAGEMENT_HISTORY_LIST_V2,
  RACK_MANAGEMENT_HISTORY_INPUT_V2,
  RACK_MANAGEMENT_HISTORY_EDIT_V2,
  RACK_MANAGEMENT_HISTORY_DELETE_V2,
  OPNAME_TASK_LIST_URL,
  OPNAME_TASK_INPUT_URL,
  OPNAME_TASK_EDIT_URL,
  OPNAME_GENERATE_TASK_DETAIL,
  OPNAME_GENERATE_TASK_DETAIL_LIST,
  OPNAME_GENERATE_TASK_DETAIL_EDIT,
  IOT_BINDING_LIST,
  IOT_BINDING_ADD,
  IOT_BINDING_EDIT,
  IOT_BINDING_ITEM_GROUP_LIST,
  IOT_BINDING_ITEM_GROUP_INPUT,
  IOT_BINDING_ITEM_GROUP_EDIT,
  IOT_BINDING_ITEM_GROUP_DELETE,
  IOT_BINDING_GROUP_DELETE,
  IOT_BINDING_ITEM_DETAIL_LIST,
  IOT_BINDING_ITEM_DETAIL_INPUT,
  IOT_BINDING_ITEM_DETAIL_EDIT,
  IOT_BINDING_ITEM_DETAIL_DELETE,
  IOT_BINDING_TAGGING_GROUP_LIST,
  IOT_BINDING_TAGGING_GROUP_INPUT,
  IOT_BINDING_TAGGING_GROUP_EDIT,
  IOT_BINDING_GROUP_LIST,
  IOT_BINDING_GROUP_EDIT,
  IOT_BINDING_GROUP_INPUT,
  WAREHOUSE_MAPPING_LIST,
  WAREHOUSE_MAPPING_INPUT,
  WAREHOUSE_MAPPING_EDIT,
  WAREHOUSE_MAPPING_DELETE,
};

import React, { Component } from "react";
import { Button } from "reactstrap";

class CheckOutboundItemButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
    };
  }

  toggleOpenItemTable = () => {
    this.setState(
      {
        showTable: !this.state.showTable,
      },
      () => {
        this.props.showCheckItemTable(this.state.showTable);
      }
    );
  };

  render() {
    const { showTable } = this.state;
    const { size } = this.props;
    return (
      <React.Fragment>
        <Button
          color="info"
          size={size}
          style={{ color: "white" }}
          onClick={() => {
            this.toggleOpenItemTable();
          }}
        >
          <i className="fa fa-archive"></i> {showTable ? "Hide" : "Show"}{" "}
          Delivery Item(s)
        </Button>
      </React.Fragment>
    );
  }
}

export default CheckOutboundItemButton;

import React from "react";
import { Switch, Route } from "react-router-dom";
import { fleetRoutes } from "./Fleet.constants";

const Fleet = () => {
  return (
    <>
      <Switch>
        {fleetRoutes().map(({ render, ...route }, idx) => (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={render}
          />
        ))}
      </Switch>
    </>
  );
};

export default Fleet;

import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import LoadingContent from "../../../../components/LoadingContent/LoadingContent";
import Select from "react-select";
import useLazyFetch from "../../../../hooks/useLazyFetch";
import { DRIVER_FLEET_SCAN_LIST } from "../../../../redux/config/links";
import * as Yup from "yup";
import {
  STORING_TICKET_EDIT,
  STORING_TICKET_INPUT,
} from "../../../../redux/config/incident.links";

const StoringTicketAddEdit = ({
  initialValues = null,
  onClose = () => null,
  onRefresh = () => null,
}) => {
  const [gettingDriverList, setGettingDriverList] = useState(true);

  const [onGetDriverList, { loading: loadingDriverList, data: driverList }] =
    useLazyFetch({
      url: DRIVER_FLEET_SCAN_LIST,
      method: "POST",
    });

  const [onCreateStoringTicket] = useLazyFetch({
    url: STORING_TICKET_INPUT,
    method: "POST",
  });

  const [onEditStoringTicket] = useLazyFetch({
    url: STORING_TICKET_EDIT,
    method: "POST",
  });

  const onSubmit = (values, { setSubmitting }) => {
    let newValues = { ...values };
    if (values.driver_fleet_scan !== null) {
      newValues.id_driver_fleet_scan = values.driver_fleet_scan.value;
    }
    delete newValues.driver_fleet_scan;

    if (!!!initialValues) {
      onCreateStoringTicket({ body: newValues }, () => {
        setSubmitting(false);
        onClose();
        onRefresh();
      });
    } else {
      onEditStoringTicket(
        { body: { ...newValues, id: initialValues.id } },
        () => {
          setSubmitting(false);
          onClose();
          onRefresh();
        }
      );
    }
  };

  const validation = () => {
    let selectYup = Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable();

    let yup = Yup.object().shape({
      driver_fleet_scan: selectYup.required("Driver Fleet Scan is required!"),
      ticket_number: Yup.string().required("Ticket Number is required!"),
      title: Yup.string().required("Title is required!"),
      desc: Yup.string().required("Description is required!"),
      lat: Yup.string().required("Latitude is required!"),
      lng: Yup.string().required("Longitude is required!"),
      progress_status: Yup.string().required("Progress Status is required!"),
      odometer: Yup.string().required("Odometer is required!"),
      maintenance_realization_cost: Yup.string().required(
        "Maintenance Realization Cost is required!"
      ),
      system_calc_est_cost: Yup.string().required(
        "System Calc Est Cost is required!"
      ),
      progress_status_remark: Yup.string().required(
        "Progress Status Remark is required!"
      ),
    });
    return yup;
  };

  useEffect(() => {
    if (gettingDriverList) {
      setGettingDriverList(false);
      onGetDriverList({});
    }
  }, [gettingDriverList, onGetDriverList]);
  return (
    <>
      <Formik
        initialValues={{
          driver_fleet_scan:
            !!initialValues && !!initialValues.id_driver_fleet_scan
              ? {
                  label: initialValues.driver_name,
                  value: initialValues.id_driver_fleet_scan,
                }
              : null,
          ticket_number:
            !!initialValues && !!initialValues.ticket_number
              ? initialValues.ticket_number
              : null,
          title:
            !!initialValues && !!initialValues.title
              ? initialValues.title
              : null,
          desc:
            !!initialValues && !!initialValues.desc ? initialValues.desc : null,
          lat:
            !!initialValues && !!initialValues.lat ? initialValues.lat : null,
          lng:
            !!initialValues && !!initialValues.lng ? initialValues.lng : null,
          progress_status:
            !!initialValues && !!initialValues.progress_status
              ? initialValues.progress_status
              : null,
          odometer:
            !!initialValues && !!initialValues.odometer
              ? initialValues.odometer
              : null,
          system_calc_est_cost:
            !!initialValues && !!initialValues.system_calc_est_cost
              ? initialValues.system_calc_est_cost
              : null,
          progress_status_remark:
            !!initialValues && !!initialValues.progress_status_remark
              ? initialValues.progress_status_remark
              : null,
          maintenance_realization_cost:
            !!initialValues && !!initialValues.maintenance_realization_cost
              ? initialValues.maintenance_realization_cost
              : null,
        }}
        onSubmit={onSubmit}
        validationSchema={validation()}
        render={({
          values,
          errors,
          setFieldValue,
          handleSubmit,
          handleReset,
          isValid,
          isSubmitting,
          handleChange,
          touched,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Driver Fleet Scan</Label>
              </Col>
              <Col xs="12" md="9">
                <LoadingContent
                  loading={loadingDriverList}
                  onReload={() => setGettingDriverList(true)}
                >
                  <Select
                    value={values.driver_fleet_scan}
                    onChange={(value) => {
                      setFieldValue("driver_fleet_scan", value);
                    }}
                    options={(driverList ? driverList.result : []).map(
                      (item) => ({
                        label: item.driver_name,
                        value: item.id,
                      })
                    )}
                    disabled={false}
                    placeholder={"Choose Driver"}
                  />
                </LoadingContent>
                <FormFeedback>{errors.driver_fleet_scan}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="ticket_number">Ticket Number</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="ticket_number"
                  id="ticket_number"
                  placeholder="Ticket Number"
                  autoComplete="off"
                  valid={!errors.ticket_number}
                  invalid={touched.ticket_number && !!errors.ticket_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ticket_number || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.ticket_number}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="title">Title</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Title"
                  autoComplete="off"
                  valid={!errors.title}
                  invalid={touched.title && !!errors.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.title}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="desc">Description</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="desc"
                  id="desc"
                  placeholder="Description"
                  autoComplete="off"
                  valid={!errors.desc}
                  invalid={touched.desc && !!errors.desc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.desc || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.desc}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="lat">Latitude</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="lat"
                  id="lat"
                  placeholder="Latitude"
                  autoComplete="off"
                  valid={!errors.lat}
                  invalid={touched.lat && !!errors.lat}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lat || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.lat}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="lng">Longitude</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="lng"
                  id="lng"
                  placeholder="Longitude"
                  autoComplete="off"
                  valid={!errors.lng}
                  invalid={touched.lng && !!errors.lng}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lng || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.lng}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="progress_status">Progress Status</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="progress_status"
                  id="progress_status"
                  placeholder="Progress Status"
                  autoComplete="off"
                  valid={!errors.progress_status}
                  invalid={touched.progress_status && !!errors.progress_status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.progress_status || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.progress_status}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="odometer">Odometer</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="odometer"
                  id="odometer"
                  placeholder="Odometer"
                  autoComplete="off"
                  valid={!errors.odometer}
                  invalid={touched.odometer && !!errors.odometer}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.odometer || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.odometer}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="maintenance_realization_cost">
                  Maintenance Realization Cost
                </Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="maintenance_realization_cost"
                  id="maintenance_realization_cost"
                  placeholder="Maintenance Realization Cost"
                  autoComplete="off"
                  valid={!errors.maintenance_realization_cost}
                  invalid={
                    touched.maintenance_realization_cost &&
                    !!errors.maintenance_realization_cost
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.maintenance_realization_cost || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>
                  {errors.maintenance_realization_cost}
                </FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="system_calc_est_cost">
                  System Calc Est Cost
                </Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="system_calc_est_cost"
                  id="system_calc_est_cost"
                  placeholder="System Calc Est Cost"
                  autoComplete="off"
                  valid={!errors.system_calc_est_cost}
                  invalid={
                    touched.system_calc_est_cost &&
                    !!errors.system_calc_est_cost
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.system_calc_est_cost || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.system_calc_est_cost}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md="3">
                <Label htmlFor="progress_status_remark">
                  Progress Status Remark
                </Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="progress_status_remark"
                  id="progress_status_remark"
                  placeholder="Progress Status Remark"
                  autoComplete="off"
                  valid={!errors.progress_status_remark}
                  invalid={
                    touched.progress_status_remark &&
                    !!errors.progress_status_remark
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.progress_status_remark || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.progress_status_remark}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup>
              <Button
                type="submit"
                color="primary"
                className="mr-1"
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting
                  ? "Wait..."
                  : !!!initialValues
                  ? "Save Changes"
                  : "Edit"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={false}
                onClick={() => null}
              >
                Close
              </Button>
              <Button
                type="reset"
                color="danger"
                className="mr-1"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Reset
              </Button>
            </FormGroup>
          </form>
        )}
      />
    </>
  );
};

export default StoringTicketAddEdit;

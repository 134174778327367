import { activeFeature } from "../constants/constants";

export default {
  items: [
    {
      name: "Home",
      url: "/welcome",
      icon: "home icon",
    },
    // {
    //   name: 'Enviroma Devices',
    //   url: '/enviroma/devices',
    //   icon: 'fa fa-microchip',
    // },
    // {
    //   name: 'Temperature',
    //   url: '/enviroma/temperature',
    //   icon: 'fa fa-thermometer-three-quarters',
    // },
    // {
    //   name: 'Air Pressure',
    //   url: '/airpressure',
    //   icon: 'fa fa-cloud',
    // },
    // {
    //   name: 'Humidity',
    //   url: '/humidity',
    //   icon: 'fa fa-tint',
    // },
    // {
    //   name: 'Alerts',
    //   url: '/alerts',
    //   icon: 'fa fa-bell',
    // },
    {
      name: "ConnectX",
      url: "/stocker",
      icon: "box icon",
      badge: {
        variant: "warning",
        text: "beta",
      },
      children: [
        {
          name: "My Stock",
          url: "/stocker/mystock",
          // icon: "fa fa-cubes",
          badge: {
            variant: "info",
            text: "▼",
          },
          children: [
            {
              name: "Administrative Offices",
              url: "/stocker/administrative-offices",
              // icon: "fa fa-university",
            },
            {
              name: "Batchs",
              url: "/stocker/batchs",
              // icon: "fa fa-sitemap",
            },
            {
              name: "Brands",
              url: "/stocker/brands",
              // icon: "fa fa-bold",
            },
            {
              name: "Company Bank Accounts",
              url: "/stocker/company-bank-accounts",
              // icon: "fa fa-id-card",
            },
            {
              name: "Divisions",
              url: "/stocker/divisions",
              // icon: "fa fa-id-card",
            },
            {
              name: "Operating Areas",
              url: "/stocker/operating-areas",
              // icon: "fa fa-map",
            },
            {
              name: "Repackagings",
              url: "/stocker/repacakaging",
              // icon: "fa fa-archive",
            },
            {
              name: "Items",
              url: "/stocker/items",
              // icon: "boxes icon",
            },
            {
              name: "Item Settings",
              url: "/stocker/item-settings",
              // icon: "cog icon",
            },
            {
              name: "Vendors",
              url: "/stocker/vendors",
              // icon: "fa fa-handshake-o",
            },
            {
              name: "Opname",
              url: "/stocker/opname/",
              // icon: "fa fa-sort-numeric-asc",
            },
            {
              name: "PIC Position",
              url: "/stocker/pic-positions",
              // icon: "fa fa-arrow-down",
            },
            {
              name: "Warehouse",
              url: "/stocker/warehouse",
              // icon: "fa fa-industry",
            },
            // {
            //   name: 'Warehouse Transfer',
            //   url: '/stocker/warehouse-transfer',
            //   icon: 'fa fa-exchange',
            // },
            {
              name: "Rack Management",
              url: "/stocker/rack-management",
              // icon: "fa fa-table",
            },
          ],
        },
        {
          name: "My Bound",
          url: "/stocker/mybound/",
          // icon: "fa fa-arrows-alt",
          badge: {
            variant: "info",
            text: "▼",
          },
          children: [
            //Inbounds
            // {
            //   name: 'Incoming Goods',
            //   url: '/stocker/incoming-goods',
            //   icon: 'fa fa-anchor',
            // },
            {
              name: "Inbounds",
              url: "/stocker/inbounds",
              // icon: "fa fa-level-down",
            },

            {
              name: "Outbounds",
              url: "/stocker/outbounds",
              // icon: "fa fa-level-up",
            },
          ],
        },
        // {
        //   name: 'My Payment',
        //   url: '/',
        //   icon: 'fa fa-credit-card-alt',
        //   badge: {
        //     variant: 'info',
        //     text: '▼',
        //   },
        //   children: [
        //     {
        //       name: 'Payment Term',
        //       url: '/stocker/payment-term',
        //       icon: 'fa fa-money',
        //     },
        //   ]
        // },
        {
          name: "My Order",
          url: "/stocker/myorder/",
          // icon: "fa fa-shopping-basket",
          badge: {
            variant: "info",
            text: "▼",
          },
          children: [
            //Sales
            {
              name: "Purchase Order",
              url: "/stocker/purchase-order",
              // icon: "fa fa-mail-reply",
            },
            // {
            //   name: 'Return Order',
            //   url: '/stocker/return-order',
            //   icon: 'fa fa-undo',
            // },
            {
              name: "Sales Order",
              url: "/stocker/sales-order",
              // icon: "fa fa-mail-forward",
            },
            {
              name: "Intercompany Invoice(s)",
              url: "/stocker/invoice-page",
              // icon: "fa fa-file-text-o",
            },

            {
              name: "IOT Binding",
              url: "/stocker/iotbinding/",
              // icon: "fa fa-table",
              badge: {
                variant: "info",
                text: "▼",
              },
              children: [
                {
                  name: "User & Device",
                  url: "/stocker/user-and-device",
                  // icon: "fa fa-users",
                },
                {
                  name: "Item Box",
                  url: "/stocker/item-box",
                  // icon: "box icon",
                },
                // {
                //   name: "Tagging",
                //   url: "/stocker/tagging",
                //   icon: "fa fa-tags",
                // },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "TrackX",
      url: "/transporter",
      icon: "truck icon",
      children: [
        {
          name: "Main Dashboard",
          url: "/transporter/dashboard",
          // icon: "icon-speedometer",
        },
        {
          name: "3PL Request",
          url: "/transporter/3pl/request",
          // icon: "fa fa-bullhorn",
        },
        {
          name: "3PL Administrative",
          url: "/transporter/3pl/administrative",
          // icon: "fa fa-magic",
        },
        // {
        //   name: 'Divisions',
        //   url: '/transporter/divisions',
        //   icon: 'fa fa-id-card',
        // },
        {
          name: "Check Sheet",
          url: "/transporter/check-sheet",
          // icon: "fa fa-check-square-o",
        },
        {
          name: "Delivery Orders",
          url: "/transporter/delivery-order",
          // icon: "fa fa-file-text",
        },
        {
          name: "Drivers",
          url: "/transporter/drivers",
          // icon: "fa fa-id-badge",
        },
        {
          name: "Drivers Assistant",
          url: "/transporter/drivers-assistant",
          // icon: "fa fa-male",
        },
        {
          name: "Fleets",
          url: "/transporter/fleets",
          // icon: "fa fa-car",
        },
        {
          name: "Fleet Operations",
          url: "/transporter/fleet-operations",
          // icon: "map signs icon",
        },
        {
          name: "Mechanics",
          url: "/transporter/mechanics",
          // icon: "fa fa-user",
        },
        {
          name: "Fleet Maintenance",
          url: "/transporter/fleet-maintenance",
          // icon: "fa fa-wrench",
        },
        // {
        //   name: 'Maintenance Settings',
        //   url: '/transporter/fleet-maintenance-settings',
        //   icon: 'fa fa-cogs',
        // },
        {
          name: "Live Monitoring",
          url: "/transporter/live-monitoring",
          // icon: "eye icon",
        },
        {
          name: "Main Customers",
          url: "/transporter/main-customers",
          // icon: "fa fa-users",
        },
        {
          name: "Saved Places",
          url: "/transporter/saved-places",
          // icon: "bookmark icon",
        },
        // {
        //   name: 'Public Facility',
        //   url: '/transporter/public-facility',
        //   icon: 'fa fa-globe',
        // },
        {
          name: "Security",
          url: "/transporter/security",
          // icon: "shield alternate icon",
        },
        // {
        //   name: 'Workshop',
        //   url: '/transporter/workshop',
        //   icon: 'fa fa-file',
        // },
      ],
    },
    // {
    //   name: 'Land Lord',
    //   url: '/landlord',
    //   icon: 'fa fa-industry',
    //   children: [
    //     {
    //       name: 'LL Monitoring',
    //       url: '/landlord/dashboard',
    //       icon: 'fa fa-stethoscope',
    //     },
    //     {
    //       name: 'Location Type',
    //       url: '/landlord/locationtype',
    //       icon: 'fa fa-bars',
    //     },
    //     {
    //       name: 'Location',
    //       url: '/landlord/locations',
    //       icon: 'fa fa-map-pin',
    //     },
    //     {
    //       name: 'Sensors',
    //       url: '/landlord/sensors',
    //       icon: 'fa fa-microchip',
    //     },
    //     {
    //       name: 'Utilities',
    //       url: '/landlord/utilities',
    //       icon: 'fa fa-shower',
    //     },
    //   ]
    // },
    {
      name: "DockX",
      url: "/point-hub",
      icon: "share alternate icon",
      children: [
        {
          name: "Customer",
          url: "/point-hub/customers",
          // icon: "fa fa-users",
        },
        {
          name: "Customer PIC",
          url: "/point-hub/customer-pic",
          // icon: "fa fa-user-circle",
        },
        {
          name: "Customer Bank Accounts",
          url: "/point-hub/customer-bank-accounts",
          // icon: "fa fa-id-card",
        },
        {
          name: "3PL Group",
          url: "/transporter/3pl/grouping",
          // icon: "users icon",
        },
        {
          name: "Rentee",
          url: "/point-hub/rentee",
          // icon: "fa fa-truck",
          badge: {
            variant: "info",
            text: "NEW",
          },
        },
        {
          name: "Maintenance Workshop",
          url: "/point-hub/maintenance-workshop",
          // icon: "fa fa-wrench",
        },
        {
          name: "Goods Trade",
          url: "/point-hub",
          // icon: "fa fa-handshake-o",
          badge: {
            variant: "info",
            text: "▼",
          },
          children: [
            {
              name: "Intercompany Order",
              url: "/point-hub/intercompany-order",
              // icon: "fa fa-building-o",
            },
            {
              name: "Pair As Vendor",
              url: "/point-hub/intercompany-trading/vendor",
              // icon: "fa fa-building-o",
            },
            {
              name: "Pair As Customer",
              url: "/point-hub/intercompany-trading/customer",
              // icon: "fa fa-user",
            },
          ],
        },
        // {
        //   name: 'Customer Approvals',
        //   url: '/point-hub/customer-approvals',
        //   icon: 'fa fa-check',
        // },
        // {
        //   name: 'PIC Positions',
        //   url: '/point-hub/pic-positions',
        //   icon: 'fa fa-arrow-down',
        // },
      ],
    },
    {
      name: "CarbonX",
      url: "/point-hub",
      icon: "leaf icon",
      children: [
        ...(activeFeature.MENUBAR_CARBONX_V2
          ? [
              {
                name: "CarbonX Dashboard",
                url: "/carbonx/dashboard/",
                // icon: "tachometer alternate icon",
                children: [
                  {
                    name: "Supply Chain Emission",
                    url: "/carbonx/dashboard/supplychainemission",
                    // icon: "truck icon",
                  },
                  {
                    name: "Company Emission",
                    url: "/carbonx/dashboard/companyemission",
                    // icon: "industry icon",
                  },
                ],
              },
              {
                name: "Company Disclosure",
                url: "/carbonx/companydisclosure/",
                // icon: "fa fa-building",
                children: [
                  {
                    name: "Company Setting",
                    url: "/carbonx/companydisclosure/companysetting",
                    // icon: "cogs icon",
                  },
                  {
                    name: "List Disclosure",
                    url: "/carbonx/companydisclosure/listdisclosure",
                    // icon: "list alternate icon",
                    children: [
                      {
                        name: "Checksheets",
                        url: "/carbonx/listdisclosure/checksheets",
                        // icon: "check square icon",
                      },
                      {
                        name: "Asset",
                        url: "/carbonx/listdisclosure/asset",
                        // icon: "briefcase icon",
                      },
                      {
                        name: "HR",
                        url: "/carbonx/listdisclosure/hr",
                        // icon: "users icon",
                      },
                      {
                        name: "Bills",
                        url: "/carbonx/listdisclosure/bills",
                        // icon: "money bill alternate icon",
                      },
                      {
                        name: "Purchased Goods and Service",
                        url: "/carbonx/listdisclosure/purchasedgoodsandservices",
                        // icon: "dolly flatbed icon",
                      },
                      {
                        name: "Third Party Logistics",
                        url: "/carbonx/listdisclosure/thirdpartylogistics",
                        // icon: "warehouse icon",
                      },
                      {
                        name: "Produced Goods",
                        url: "/carbonx/listdisclosure/producedgoods",
                        // icon: "box icon",
                      },
                      {
                        name: "Waste",
                        url: "/carbonx/listdisclosure/waste",
                        // icon: "trash alternate icon",
                      },
                    ],
                  },
                  {
                    name: "Operation Disclosure",
                    url: "/carbonx/companydisclosure/operationdisclosure/",
                    // icon: "cog icon",
                    children: [
                      {
                        name: "Office & Commuting",
                        url: "/carbonx/operationdisclosure/officeandcommuting",
                        // icon: "building icon",
                      },
                      {
                        name: "Logistics and Manufacturing",
                        url: "/carbonx/operationdisclosure/logisticsanfmanufacturing",
                        // icon: "boxes icon",
                      },
                      {
                        name: "Business Travel",
                        url: "/carbonx/operationdisclosure/businesstravel",
                        // icon: "truck icon",
                      },
                      {
                        name: "Land Use",
                        url: "/carbonx/operationdisclosure/landuse",
                        // icon: "check square icon",
                      },
                    ],
                  },
                  {
                    name: "Accounting Disclosure",
                    url: "/carbonx/companydisclosure/accountingdisclosure/",
                    // icon: "fa fa-calculator",
                    children: [
                      {
                        name: "Balanced Sheets",
                        url: "/carbonx/accountingdisclosure/balancedsheets",
                        // icon: "balance scale icon",
                      },
                      {
                        name: "Cashflows",
                        url: "/carbonx/accountingdisclosure/cashflow",
                        // icon: "money bill alternate icon",
                      },
                    ],
                  },
                ],
              },
              {
                name: "Automatic Emission Tracking",
                url: "/carbonx/AET/",
                // icon: "sync icon",
                children: [
                  {
                    name: "AET Dashboard",
                    url: "/carbonx/AET/AETDashboard",
                    // icon: "fa fa-tachometer",
                  },
                  {
                    name: "Scope 1",
                    url: "/carbonx/AET/scope1",
                    // icon: "industry icon",
                  },
                  {
                    name: "Scope 2",
                    url: "/carbonx/AET/scope2",
                    // icon: "bolt icon",
                  },
                  {
                    name: "Scope 3",
                    url: "/carbonx/AET/scope3",
                    // icon: "truck icon",
                  },
                  {
                    name: "Carbon Invoice",
                    url: "/carbonx/invoice",
                    // icon: "fa fa-file-text-o",
                  },
                ],
              },
              {
                name: "Emission Factors",
                url: "/carbonx/emissionfactors",
                // icon: "crosshairs icon",
              },
              {
                name: "Emission Database",
                url: "/carbonx/emissiondatabase/",
                // icon: "fa fa-database",
                children: [
                  {
                    name: "My Data",
                    url: "/carbonx/emissiondatabase/mydata",
                    // icon: "fa fa-bar-chart",
                  },
                  {
                    name: "Data Pool",
                    url: "/carbonx/emissiondatabase/datapool",
                    // icon: "fa fa-table",
                  },
                ],
              },
              {
                name: "Decarbonization Plan",
                url: "/carbonx/decarbonizationplan",
                // icon: "fa fa-cloud-download",
              },
              {
                name: "Offset and Stewardship",
                url: "/carbonx/offsetstewardship",
                // icon: "umbrella icon",
              },
              {
                name: "Report Disclosure",
                url: "/carbonx/reportdisclosure",
                // icon: "fa fa-file-text",
              },
            ]
          : [
              {
                name: "Main Dashboard",
                url: "/carbonx/dashboard/",
                // icon: "icon-speedometer",
              },
              {
                name: "Logger",
                url: "/carbonx/logger/",
                // icon: "fa fa-terminal",
                badge: {
                  variant: "info",
                  text: "▼",
                },
                children: [
                  //Inbounds
                  {
                    name: "Scope 1",
                    url: "/carbonx/logger/scope1",
                    // icon: "fa fa-truck",
                  },
                  {
                    name: "Scope 2",
                    url: "/carbonx/logger/scope2",
                    // icon: "fa fa-bolt",
                  },
                  {
                    name: "Scope 3",
                    url: "/carbonx/logger/scope3",
                    // icon: "fa fa-share",
                  },
                ],
              },
              {
                name: "Carbon Invoice",
                url: "/carbonx/invoice",
                // icon: "fa fa-file-text-o",
              },
              {
                name: "Data Market",
                url: "/carbonx/data-market",
                // icon: "fa fa-database",
              },
              // {
              //   name: 'Settings',
              //   url: '/enermo/settings',
              //   icon: 'fa fa-cogs',
              // },
            ]),
      ],
    },
    {
      name: "Tools",
      url: "/tools",
      icon: "wrench  icon",
      children: [
        {
          name: "Geocode Converter",
          url: "/tools/geocode-converter",
          // icon: "map icon",
          badge: {
            variant: "info",
            text: "NEW",
          },
        },
        activeFeature.MY_PERTAMINA_COMPARE_REPORT_FUEL && {
          name: "MyPertamina Converter",
          url: "/tools/mypertamina-converter",
          // icon: "map icon",
          badge: {
            variant: "info",
            text: "NEW",
          },
        },
      ],
    },
    // {
    //   name: 'Fleets',
    //   url: '/transporter/fleets',
    //   icon: 'fa fa-car',
    // },
    // {
    //   name: 'Fleet Monitoring',
    //   url: '/transporter/monitoring',
    //   icon: 'fa fa-map',
    // },
    // {
    //   name: 'Drivers',
    //   url: '/transporter/drivers',
    //   icon: 'fa fa-id-badge',
    // },
    // {
    //   name: 'Drivers Assistant',
    //   url: '/transporter/drivers-assistant',
    //   icon: 'fa fa-male',
    // },
    // {
    //   name: 'Delivery Orders',
    //   url: '/transporter/delivery-order',
    //   icon: 'fa fa-file-text',
    // },
    {
      name: "Subscriptions",
      url: "/subscriptions",
      icon: "certificate icon",
    },
    {
      name: "Settings",
      url: "/transporter",
      icon: "cog icon",
      children: [
        {
          name: "Company Settings",
          url: "/settings",
          // icon: "cogs icon",
        },
        {
          name: "Maintenance Settings",
          url: "/transporter/fleet-maintenance-settings",
          // icon: "cogs icon",
        },
      ],
    },
  ],
};

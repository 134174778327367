import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Col,
  Input,
  ButtonGroup,
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import { fetchDriversTable } from "../../../redux/actions/transporter/aTransporter-DriversTable";
import { fetchDeleteItem } from "../../../redux/actions/transporter/aTransporter-DeleteItem";
import DatePicker from "react-datepicker";
import * as API_LINKS from "../../../redux/config/links";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { AbilityContext } from "../../../casl/Can";
import Dropzone from "../../../components/Dropzone/";
import { Link } from "react-router-dom";
import DriverAppRegisterButton from "./DriverAppRegisterButton";
import DriverSummary from "./DriverSummary";
import DriverFleets from "./DriverFleets";
import DriversDetails from "./DriversDetails";
import { activeFeature } from "../../../constants/constants";
function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

class DriversDatatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedName: "",
      selectedId: 0,
      modalDriverFleets: false,
      modalSummary: false,
      modalEdit: false,
      modalDelete: false,
      modalDetail: false,
      selectedDriverDetail: {},
      selectedRow: {},

      licDate: "",
      date: "",

      isEditing: false,
      driverBirthDate: "",
      telp: "",
      licNumber: "",
      licExpiryDate: "",
      licType: "",
      driverPhoto: [],

      selectedDriver: {},

      // New parameter
      trainingStatus: "",
      trainDate: "",
      trainingDate: "",
      certifDate: "",
      certificationExpiryDate: "",
      certificateNumber: "",
      organizer: "",
      nextTrainingDate: "",
      nextTrainingScheduleDate: "",
    };
  }

  UNSAFE_componentWillMount = () => {
    this.props.fetchDriversTable();
  };

  toggleOpenDelete = (_id, _name) => {
    this.setState({
      selectedId: _id,
      selectedName: _name,
      modalDelete: !this.state.modalDelete,
    });
  };

  toggleCloseDelete = () => {
    this.setState({
      selectedId: 0,
      selectedName: "",
      modalDelete: !this.state.modalDelete,
    });
  };

  toggleOpenEdit = (_row) => {
    const formatDate = (date) => {
      if (!date) {
        // Jika date kosong, kembalikan tanggal dengan tahun saat ini
        return moment().format("YYYY-MM-DD");
      }

      const parsedDate = new Date(date);
      return isNaN(parsedDate.getTime())
        ? moment().format("YYYY-MM-DD") // Menggunakan tahun saat ini jika tanggal tidak valid
        : moment(parsedDate).format("YYYY-MM-DD");
    };

    this.setState({
      selectedId: _row.id,
      selectedRow: _row,
      driverBirthDate: formatDate(_row.birth_date),
      telp: _row.telp,
      licNumber: _row.lic_number,
      licExpiryDate: formatDate(_row.exp_date),
      licType: _row.lic_type,
      identificationNumber: _row.ktp,

      // New parameter
      trainingStatus: _row.training_status,
      trainingDate: formatDate(_row.training_date),
      certificationExpiryDate: formatDate(_row.certification_expiry_date),
      certificateNumber: _row.certificate_number,
      organizer: _row.organizer,
      nextTrainingScheduleDate: formatDate(_row.next_training_schedule),

      modalEdit: !this.state.modalEdit,
    });
  };

  toggleCloseEdit = () => {
    this.setState({
      selectedRow: {},
      modalEdit: !this.state.modalEdit,
    });
  };

  toggleOpenSummary = () => {
    this.setState({
      modalSummary: !this.state.modalSummary,
    });
  };

  toggleCloseSummary = () => {
    this.setState({
      modalSummary: !this.state.modalSummary,
    });
  };

  toggleOpenDriverFleets = (_id, _name) => {
    this.setState({
      selectedId: _id,
      selectedName: _name,
      modalDriverFleets: !this.state.modalDriverFleets,
    });
  };

  toggleCloseDriverFleets = () => {
    this.setState({
      selectedId: 0,
      selectedName: "",
      modalDriverFleets: !this.state.modalDriverFleets,
    });
  };

  toggleOpenDetail = (driver) => {
    this.setState({
      selectedDriverDetail: driver,
      modalDetail: !this.state.modalDetail,
    });
  };

  toggleCloseDetail = () => {
    this.setState({
      selectedDriverDetail: {},
      modalDetail: !this.state.modalDetail,
    });
  };

  handleOnDrop = (result) => {
    //Dropzone
    if (result.length === 0) {
      this.setState({ driverPhoto: [] });
      return;
    }
    if (!result.passed) {
      toast.error("Driver photo, " + result.reasonRejected);
    }
    this.setState({ driverPhoto: result });
  };

  ActionButtonFormatter = (cell, row) => {
    return (
      <div>
        <ButtonGroup>
          {
            row.username ? (
              this.context.can("view", "manage_driver@driver_app_user") && (
                <Link
                  to={{
                    pathname: "/transporter/driver-app-user/" + row.id,
                    state: {
                      id: row.id,
                      isDriver: true,
                      name: row.name,
                      company: row.company,
                      link_image: row.link_image,
                      telp: row.telp,
                      birth_date: row.birth_date,
                      lic_type: row.lic_type,
                      lic_number: row.lic_number,
                      exp_date: row.exp_date,
                      email: row.email,
                      username: row.username,
                    },
                  }}
                >
                  <Button
                    className="btn btn-secondary"
                    color="secondary"
                    size="sm"
                  >
                    <i className="fa fa-user"></i> Driver Analytics
                  </Button>
                </Link>
              )
            ) : this.context.can(
                "view",
                "add_driver_app_user@driver_app_user"
              ) && row.user_id === null ? (
              <DriverAppRegisterButton
                isLoading={this.props.inProgress}
                id={row.id}
                name={row.name}
                telp={row.telp}
                fetchData={this.props.fetchDriversTable}
              />
            ) : null
            // <Button className="btn btn-primary" color="primary" size="sm"><i className="fa fa-plus"></i> Register App</Button>
          }
          &nbsp;&nbsp;
          <Button
            className="btn btn-secondary"
            size="sm"
            onClick={() => this.toggleOpenDetail(row)}
          >
            <i className="fa fa-info-circle"></i> Detail
          </Button>{" "}
          &nbsp;&nbsp;
          <Button
            className="btn btn-info"
            size="sm"
            onClick={() => this.toggleOpenDriverFleets(row.id, row.name)}
          >
            <i className="fa fa-truck"></i> Driver Fleet(s)
          </Button>{" "}
          &nbsp;&nbsp;
          {this.context.can("view", "add_covid@covid_related") &&
            this.context.can("view", "add_covid@covid_related_subs") && (
              <Link
                to={{
                  pathname: "/transporter/covid-related/" + row.id,
                  state: {
                    id: row.id,
                    isDriver: true,
                    name: row.name,
                  },
                }}
              >
                <Button className="btn btn-success" color="success" size="sm">
                  <i className="fa fa-exclamation-triangle"></i> Covid Related
                </Button>
              </Link>
            )}{" "}
          &nbsp;&nbsp;
          {this.context.can("view", "edit_button@driver_datatable") && (
            <React.Fragment>
              <Button
                className="btn btn-warning"
                color="warning"
                size="sm"
                onClick={() => this.toggleOpenEdit(row)}
              >
                <i className="fa fa-edit"></i> Edit
              </Button>{" "}
              &nbsp;&nbsp;
            </React.Fragment>
          )}
          <br />
          {this.context.can("view", "delete_button@driver_datatable") && (
            <Button
              className="btn btn-danger"
              color="danger"
              size="sm"
              onClick={() => this.toggleOpenDelete(row.id, row.name)}
            >
              <i className="fa fa-trash"></i> Delete
            </Button>
          )}
        </ButtonGroup>
      </div>
    ); //eslint-disable-next-line
  };

  handleDelete = (_id) => {
    this.props.fetchDeleteItem("D", _id);
    this.toggleCloseDelete();
    sleep(500).then(() => {
      // Do something after the sleep!
      this.props.fetchDriversTable();
    });
  };

  viewDeleteDriver = () => {
    var self = this;
    const { selectedName, selectedId } = this.state;
    return (
      <div className="container">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver Name: </Label>
          </Col>
          <Col xs="12" md="9">
            <Label htmlFor="text-input">{selectedName}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12" md="12">
            <Label htmlFor="text-input">
              Do you want to delete this driver ?{" "}
            </Label>
          </Col>
        </FormGroup>

        {this.props.inProgressDelete ? (
          <center>
            <BarLoader
              color={"#123abc"}
              loading={this.props.inProgressDelete}
            />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => self.handleDelete(selectedId)}
              size="sm"
              color="danger"
              disabled={self.props.inProgressDelete}
            >
              <i className="fa fa-trash"></i> Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseDelete()}
              size="sm"
              color="success"
              disabled={self.props.inProgressDelete}
            >
              <i className="fa fa-window-close"></i> Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  handleBirthDateChange = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      date: _date,
      driverBirthDate: convertedDate,
    });
  };

  handleLicenseExpiryDateChange = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      licDate: _date,
      licExpiryDate: convertedDate,
    });
  };

  handleCertificationExpiryDateChange = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      certifDate: _date,
      certificationExpiryDate: convertedDate,
    });
  };

  handleTrainingDateChange = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      trainDate: _date,
      trainingDate: convertedDate,
    });
  };

  handleNextTrainingScheduleDate = (_date) => {
    var convertedDate = moment(_date).format("YYYY-MM-DD");
    // console.log(convertedDate)
    this.setState({
      nextTrainingDate: _date,
      nextTrainingScheduleDate: convertedDate,
    });
  };

  handleLicenseTypeChange(e) {
    this.setState({ licType: e.target.value, selectedRow: { lic_type: null } });
  }

  handleTrainingStatusChange(e) {
    this.setState({
      trainingStatus: e.target.value,
      selectedRow: { training_status: null },
    });
  }

  viewEditDriver = () => {
    let simSelection = [
      "SIM A",
      "SIM A (Umum)",
      "SIM B1",
      "SIM B1 (Umum)",
      "SIM B2",
      "SIM B2 (Umum)",
      "SIM C",
      " SIM D",
    ];
    simSelection = simSelection.filter((sim) => sim !== this.state.licType);
    simSelection.unshift(this.state.licType);

    let trainingStatusSelection = ["Completed", "Ongoing"];
    trainingStatusSelection = trainingStatusSelection.filter(
      (training_status) => training_status !== this.state.trainingStatus
    );
    trainingStatusSelection.unshift(this.state.trainingStatus);

    return (
      <div className="container animated fadeIn">
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver Name</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="editDriverName"
              placeholder="John Doe"
              disabled
              value={this.state.selectedRow.name}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Identification Card Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="editIdentificationNumber"
              placeholder="21711xxxxxxxxxxx"
              disabled={this.state.isEditing}
              onChange={(e) =>
                this.setState({ identificationNumber: e.target.value })
              }
              value={this.state.identificationNumber}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Birth Date</Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={this.state.date}
              onChange={this.handleBirthDateChange}
              placeholderText={this.state.driverBirthDate}
              disabled={this.state.isEditing}
              customInput={
                <Input type="text" placeholder="Select a birth date" />
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver Phone</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="editDriverPhone"
              placeholder="628xxxxxxxx"
              onChange={(e) => this.setState({ telp: e.target.value })}
              disabled={this.state.isEditing}
              value={this.state.telp}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">License Type</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="select"
              name="select"
              id="editSelectLicenseType"
              disabled={this.state.isEditing}
            >
              {simSelection.map((sim, index) => (
                <option key={index} value={sim}>
                  {sim}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">License Number</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="text-input"
              id="addLicenseNumber"
              placeholder="9018xxxxxxxx"
              disabled={this.state.isEditing}
              onChange={(e) => this.setState({ licNumber: e.target.value })}
              value={this.state.licNumber}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">License Expiry Date</Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={this.state.licDate}
              onChange={this.handleLicenseExpiryDateChange}
              placeholderText={this.state.licExpiryDate}
              disabled={this.state.isEditing}
              customInput={
                <Input type="text" placeholder="Select License Expiry Date" />
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Current Driver Photo</Label>
          </Col>
          <Col xs="12" md="9">
            {this.state.selectedRow.link_image === null ? (
              <img
                width="75"
                height="70"
                src={process.env.PUBLIC_URL + "/assets/img/photo_not_found.png"}
                alt="old preview"
              />
            ) : (
              <img
                width="130"
                height="100"
                src={this.state.selectedRow.link_image}
                alt="old preview"
              />
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">New Driver Photo</Label>
          </Col>
          <Col xs="12" md="9">
            <Dropzone
              maxFile={1}
              maxSize={1000000} //1MB
              accept="image/png, image/jpeg"
              onDrop={this.handleOnDrop}
              preview={true}
              disabled={this.state.isEditing}
            ></Dropzone>
          </Col>
        </FormGroup>
        {activeFeature.HR_DASHBOARD && (
          <>
            <br />
            Training & Certifications
            <hr />
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Training Status</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="select"
                  name="training_status"
                  id="training_status"
                  disabled={this.state.isEditing}
                >
                  {trainingStatusSelection.map((training_status, index) => (
                    <option key={index} value={training_status}>
                      {training_status}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Training Date</Label>
              </Col>
              <Col xs="12" md="9">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={
                    this.state.trainingDate
                      ? new Date(this.state.trainingDate)
                      : null
                  }
                  onChange={this.handleTrainingDateChange}
                  placeholderText="Select Training Date"
                  disabled={this.state.isEditing}
                  customInput={
                    <Input type="text" placeholder="Select Training Date" />
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Certification Expiry Date</Label>
              </Col>
              <Col xs="12" md="9">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={
                    this.state.certificationExpiryDate
                      ? new Date(this.state.certificationExpiryDate)
                      : null
                  }
                  onChange={this.handleCertificationExpiryDateChange}
                  placeholderText="Select Certification Expiry Date"
                  disabled={this.state.isEditing}
                  customInput={
                    <Input
                      type="text"
                      placeholder="Select Certification Expiry Date"
                    />
                  }
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Certificate Number</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="certificate_number"
                  id="certificate_number"
                  placeholder="Enter Certificate Number"
                  disabled={this.state.isEditing}
                  onChange={(e) =>
                    this.setState({ certificateNumber: e.target.value })
                  }
                  value={this.state.certificateNumber}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Organizer</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="text"
                  name="organizer"
                  id="organizer"
                  placeholder="Enter Organizer"
                  disabled={this.state.isEditing}
                  onChange={(e) => this.setState({ organizer: e.target.value })}
                  value={this.state.organizer}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Next Training Schedule</Label>
              </Col>
              <Col xs="12" md="9">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selected={
                    this.state.nextTrainingScheduleDate
                      ? new Date(this.state.nextTrainingScheduleDate)
                      : null
                  }
                  onChange={this.handleNextTrainingScheduleDate}
                  placeholderText="Select Next Training Schedule"
                  disabled={this.state.isEditing}
                  customInput={
                    <Input
                      type="text"
                      placeholder="Select Next Training Schedule"
                    />
                  }
                />
              </Col>
            </FormGroup>
          </>
        )}
        {this.state.isEditing ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.state.isEditing} />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <div className="d-flex justify-content-end w-100">
            <Button
              onClick={() => this.handleEdit()}
              type="submit"
              color="primary"
              className="mr-1 w-50"
              disabled={this.state.isEditing}
            >
              Submit
            </Button>
            <Button
              onClick={() => this.toggleCloseEdit()}
              size="sm"
              color="warning"
              className="mr-1 w-50"
              disabled={this.state.isEditing}
            >
              Close
            </Button>
          </div>
        )}
      </div>
    );
  };

  handleEdit = async () => {
    this.setState({ isEditing: true });
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    //Variables for text input
    var e = document.getElementById("editSelectLicenseType");
    var _licType = e.options[e.selectedIndex].value;
    // console.log(_licType)
    var _id = this.state.selectedId;
    // var _licType = document.querySelector('#addSelectLicenseType').value;
    // var _driverName = document.querySelector('#addDriverName').value;
    var _driverBirthDate = self.state.driverBirthDate;
    var _driverPhone = this.state.telp;
    var _licenseNumber = this.state.licNumber;
    var _licExpiryDate = self.state.licExpiryDate;
    var _identificationNumber = this.state.identificationNumber;
    const { driverPhoto } = this.state;

    // New parameter
    var eTrainingStatus = document.getElementById("training_status");
    var _trainingStatus =
      eTrainingStatus.options[eTrainingStatus.selectedIndex].value;
    var _trainingDate = self.state.trainingDate;
    var _certificationExpiryDate = self.state.certificationExpiryDate;
    var _certificateNumber = this.state.certificateNumber;
    var _organizer = this.state.organizer;
    var _nextTrainingScheduleDate = self.state.nextTrainingScheduleDate;
    // End of new parameter

    //        if(_driverName === "" || _driverName === null) {
    //         self.setState({isEditing: false})
    //         toast.error("Name is invalid!")
    //     } else if (_licType === "" || _licType === null ) {
    //         self.setState({isEditing: false})
    //         toast.error("License type is invalid!")
    //     } else if(_licenseNumber === "" || _licenseNumber === null) {
    //         self.setState({isEditing: false})
    //         toast.error("License number is invalid!")
    //     } else if(_licExpiryDate === "" || _licExpiryDate === null) {
    //         self.setState({isEditing: false})
    //         toast.error("License expiry date is invalid!")
    //     } else {

    let editedImageUploaded = false;
    let editedImageId = -1;

    if (driverPhoto.length > 0) {
      if (driverPhoto.passed) {
        let formDataUploadImage = new FormData();

        formDataUploadImage.append("file", driverPhoto.uploadedFiles[0]);
        formDataUploadImage.append(
          "image_desc",
          "Foto driver bernama " + driverPhoto.uploadedFiles.name
        );

        await axios({
          method: "post",
          url: API_LINKS.UPLOAD_IMAGE_FOR_EDIT,
          headers: {
            Authorization: auth,
            "Content-Type": "multipart/form-data",
          },
          data: formDataUploadImage,
        })
          .then((response) => {
            if (response.status === 200) {
              editedImageUploaded = true;
              if (response.data.status === "success") {
                editedImageId = response.data.result.id;
              }
            }
          })
          .catch(function (error) {
            if (error.response) {
              return toast.error(
                "Error " + error.response.status + " while edit photo"
              );
            }
          });
      }
    }

    let dataEdit = {
      id: _id,
      telp: _driverPhone,
      birth_date: _driverBirthDate,
      lic_type: _licType,
      lic_number: _licenseNumber,
      ktp: _identificationNumber,
      exp_date: _licExpiryDate,

      // New Parameter
      training_status: _trainingStatus,
      training_date: _trainingDate,
      certification_expiry_date: _certificationExpiryDate,
      certificate_number: _certificateNumber,
      organizer: _organizer,
      next_training_schedule: _nextTrainingScheduleDate,
    };

    console.log("Data to be sent:", dataEdit);

    if (editedImageUploaded) {
      dataEdit["id_image_log"] = editedImageId;
    }

    await axios({
      method: "post",
      url: API_LINKS.DRIVER_EDIT_URL, //Please change when it's ready
      headers: {
        Authorization: auth,
      },
      data: dataEdit,
    })
      .then((response) => {
        if (response.status === 200) {
          self.setState({
            isEditing: false,
            driverBirthDate: "",
            date: "",
            licDate: "",
            licExpiryDate: "",
            licType: "",
            telp: "",
            licNumber: "",
            driverPhoto: [],

            // Start new parameter
            trainingStatus: "",
            trainDate: "",
            trainingDate: "",
            certifDate: "",
            certificationExpiryDate: "",
            certificateNumber: "",
            organizer: "",
            nextTrainingDate: "",
            nextTrainingScheduleDate: "",
            // End of new parameter
          });
          if (response.data.status === "success") {
            toast.success("Data successfully edited!");
            self.toggleCloseEdit();
            self.props.fetchDriversTable();
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({
              isEditing: false,
            });
            return toast.error("Invalid credentials! Please try again!");
          } else if (error.response.status === 403) {
            self.setState({
              isEditing: false,
            });
            return toast.error("Unauthorized Access! Please try to logout..");
          } else if (error.response.status === 400) {
            self.setState({
              isEditing: false,
            });
            return toast.error(error.response.data.message);
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({
              isEditing: false,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else {
            self.setState({
              isEditing: false,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          self.setState({
            isEditing: false,
          });
          return toast.error("Request error! Please try again later...");
        } else {
          self.setState({
            isEditing: false,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
    // }
  };

  imageFormatter = (cell) => {
    let imageLink = cell;

    if (!imageLink)
      imageLink = process.env.PUBLIC_URL + "/assets/img/photo_not_found.png";

    return '<img width="72" height="auto" src="' + imageLink + '" />';
  };

  onlyDateFormatter = (cell) => {
    return moment(cell).format("DD-MM-YYYY");
  };

  onRowSelect = (row, isSelected, e) => {
    let selectedDriver = { ...this.state.selectedDriver };
    if (isSelected) selectedDriver[row.id] = row;
    if (!isSelected) delete selectedDriver[row.id];
    this.setState({
      selectedDriver,
    });
  };

  onSelectAll = (isSelected, rows) => {
    let selectedDriver = {};
    if (isSelected) {
      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        selectedDriver[row.id] = row;
      }
    }
    this.setState({ selectedDriver });
  };

  getSelectRowProp = () => {
    return {
      mode: "checkbox",
      clickToSelect: true,
      columnWidth: "5%",
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
    };
  };

  customBtnGroup = (props) => {
    const { selectedDriver } = this.state;
    return (
      <div>
        <Button
          className="mr-2"
          color="primary"
          disabled={Object.keys(selectedDriver).length === 0}
          onClick={() => this.toggleOpenSummary()}
        >
          <i className="fa fa-table"></i>&nbsp; Driver(s) Summary
        </Button>
        {props.exportCSVBtn}
      </div>
    );
  };

  showDataTable = () => {
    var options = {
      defaultSortName: "name", // default sort column name
      defaultSortOrder: "desc", // default sort order
      noDataText: "No driver registered on your dashboard. Try one-lah!",
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
      sizePerPage: 20,
      btnGroup: this.customBtnGroup,
    };
    return (
      <BootstrapTable
        data={this.props.driversTableData.result}
        version="4"
        bordered={false}
        hover
        pagination
        search
        edit
        options={options}
        exportCSV={true}
        csvFileName={this.fileNameFormat}
        multiColumnSearch
        selectRow={this.getSelectRowProp()}
      >
        <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" hidden>
          ID
        </TableHeaderColumn>
        {this.context.can("view", "company_field@drivers_datatable") ? (
          <TableHeaderColumn
            dataField="company"
            searchable
            dataSort
            csvHeader="Company"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
            width="15%"
          >
            Company
          </TableHeaderColumn>
        ) : null}
        <TableHeaderColumn
          dataField="name"
          searchable
          dataSort
          csvHeader="Driver Name"
          dataFormat={this.driverWithKtp.bind(this)}
          csvFormat={this.driverWithKtpCsv.bind(this)}
          thStyle={{ whiteSpace: "normal" }}
          width="12%"
          headerStyle={{ backgroundColor: "#1C406C", color: "#fff" }}
        >
          Driver Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="ktp"
          searchable
          csvHeader="KTP"
          width="0%"
          hidden
        ></TableHeaderColumn>

        <TableHeaderColumn
          dataField="link_image"
          csvHeader="Photo"
          dataFormat={this.imageFormatter}
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
        >
          Photo
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="telp"
          dataSort
          csvHeader="Phone Number"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="12%"
        >
          Phone Number
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="birth_date"
          dataSort
          csvHeader="Birth Date"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
          dataFormat={this.onlyDateFormatter.bind(this)}
        >
          Birth Date
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="certification_expiry_date"
          dataSort
          csvHeader="Certification Expiry Date"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
          hidden
          dataFormat={this.onlyDateFormatter.bind(this)}
        >
          Certification Expiry Date
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="lic_type"
          dataSort
          csvHeader="License Type"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
        >
          License Type
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="lic_number"
          dataSort
          csvHeader="License Number"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
        >
          License Number
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="exp_date"
          dataSort
          csvHeader="Expiry Date"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="10%"
          dataFormat={this.onlyDateFormatter.bind(this)}
        >
          Expiry Date
        </TableHeaderColumn>
        {/* Driver Apps */}
        <TableHeaderColumn
          dataField="email"
          dataSort
          csvHeader="Email"
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="12%"
        >
          Email
        </TableHeaderColumn>
        {/* <TableHeaderColumn dataField="created_on" dataSort csvHeader="Created On" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Created On</TableHeaderColumn> */}
        <TableHeaderColumn
          dataField="action"
          export={false}
          dataFormat={this.ActionButtonFormatter.bind(this)}
          thStyle={{ whiteSpace: "normal" }}
          tdStyle={{ whiteSpace: "normal" }}
          width="30%"
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  driverWithKtp = (cell, row) => {
    return (
      <div style={{ whiteSpace: "pre-line" }}>
        {`${cell} ${row.ktp ? `(${row.ktp})` : ""}`}
      </div>
    );
  };

  driverWithKtpCsv = (cell, row) => {
    return `${cell} ${row.ktp ? `(${row.ktp})` : ""}`;
  };

  render() {
    return (
      <div className="animated fadeIn">
        {this.props.inProgress ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.props.inProgress} />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : this.props.successDriversFetch ? (
          this.showDataTable()
        ) : (
          <center>
            Error fetching your data...
            <br />
            <br />
            <Button
              className="btn btn-primary"
              color="primary"
              size="md"
              disabled={this.props.inProgress}
              onClick={() => this.props.fetchDriversTable()}
            >
              <i className="fa fa-refresh"></i> Try again
            </Button>
          </center>
        )}

        <Modal
          isOpen={this.state.modalDriverFleets}
          toggle={this.toggleOpenDriverFleets}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggleCloseDriverFleets}>
            {this.state.selectedName} Fleet(s)
          </ModalHeader>
          <ModalBody>
            <DriverFleets idDriver={this.state.selectedId} />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modalDetail}
          toggle={this.toggleCloseDetail}
          size="lg"
        >
          <ModalHeader toggle={this.toggleCloseDetail}>
            Driver Details
          </ModalHeader>
          <ModalBody>
            <DriversDetails
              id={this.state.selectedDriverDetail.id}
              name={this.state.selectedDriverDetail.name}
              ktp={this.state.selectedDriverDetail.ktp}
              link_image={this.state.selectedDriverDetail.link_image}
              telp={this.state.selectedDriverDetail.telp}
              birth_date={this.state.selectedDriverDetail.birth_date}
              lic_type={this.state.selectedDriverDetail.lic_type}
              lic_number={this.state.selectedDriverDetail.lic_number}
              exp_date={this.state.selectedDriverDetail.exp_date}
              email={this.state.selectedDriverDetail.email}
              training_status={this.state.selectedDriverDetail.training_status}
              training_date={this.state.selectedDriverDetail.training_date}
              certification_expiry_date={
                this.state.selectedDriverDetail.certification_expiry_date
              }
              certificate_number={
                this.state.selectedDriverDetail.certificate_number
              }
              organizer={this.state.selectedDriverDetail.organizer}
              next_training_schedule={
                this.state.selectedDriverDetail.next_training_schedule
              }
            />
          </ModalBody>
        </Modal>

        {/* Modal for Edit Driver */}
        <Modal
          isOpen={this.state.modalEdit}
          toggle={this.toggleOpenEdit}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseEdit}>
            Edit Driver Data
          </ModalHeader>
          <ModalBody>{this.viewEditDriver()}</ModalBody>
        </Modal>

        {/* Modal for Delete Place */}
        <Modal
          isOpen={this.state.modalDelete}
          toggle={this.toggleOpenDelete}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseDelete}>
            Delete {this.state.selectedName}?
          </ModalHeader>
          <ModalBody>{this.viewDeleteDriver()}</ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modalSummary}
          toggle={this.toggleOpenSummary}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseSummary}>
            Data Range for Selected Driver(s) Summary
          </ModalHeader>
          <ModalBody>
            <DriverSummary
              drivers={this.state.selectedDriver}
              onClose={this.toggleCloseSummary}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driversTableData: state.driversTableStore.driversTableData,
    inProgress: state.driversTableStore.inProgress,
    deleteItemData: state.deleteStore.deleteItemData,
    inProgressDelete: state.deleteStore.inProgress,
    successDriversFetch: state.driversTableStore.success,
  };
};

DriversDatatable.contextType = AbilityContext;
export default connect(mapStateToProps, { fetchDriversTable, fetchDeleteItem })(
  DriversDatatable
);

import React, { Suspense } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import { Provider } from "react-redux";
import store from "./redux/store.js";

import { AbilityContext } from "./casl/Can";
import ability from "./casl/Ability.jsx";

// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import "./App.scss";
import WelcomeMessage from "./containers/DefaultLayout/WelcomeMessage";
import Logout from "./views/Users/Logout/Logout.jsx";
import ToastPortal from "./components/ToastPortal/";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import DefaultLayout from "./containers/DefaultLayout";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
// const DefaultLayout = Loadable({
//   loader: () => import("./containers/DefaultLayout"),
//   loading,
// });

//Login Component
const Login = Loadable({
  loader: () => import("./views/Users/Login/Login.jsx"),
  loading,
});

//Register Component
// const Register = Loadable({
//   loader: () => import('./views/Users/Register/Register.jsx'),
//   loading
// });

const UserVerification = Loadable({
  loader: () => import("./views/Users/Verification/"),
  loading,
});

// nice

const coba = "kosong";

const persistor = persistStore(store);
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AbilityContext.Provider value={ability}>
          <HashRouter>
              <Switch>
                <Route exact path="/login" name="Login" component={Login} />
                {/* <Route exact path="/register" name="Register" component={Register} /> */}
                <Route
                  exact
                  path="/validation/user/:authtoken"
                  name="Verify"
                  component={UserVerification}
                />
                <Route path="/" name="Home" component={DefaultLayout} />
                <Route
                  path="/welcome"
                  name="Welcome"
                  component={WelcomeMessage}
                />
                <Route path="/logout" name="Logout" component={Logout} />

                <Redirect from="/" to="/welcome" />
              </Switch>
          </HashRouter>
        </AbilityContext.Provider>
        <ToastPortal />
      </PersistGate>
    </Provider>
  );
};

export default App;

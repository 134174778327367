import React, { Component } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import WorkOrderImageDeletionConfirmation from "./WorkOrderImageDeletionConfirmation";
import { fetchWorkOrdersUploadedImage } from "../../../redux/actions/transporter/aTransporter-WorkOrdersTable.jsx";
import { AbilityContext } from "../../../casl/Can";
import WorkOrdersImageUpload from "./WorkOrdersImageUpload";
import { SelectField } from "../../LandLord/Widgets/SelectField";
import moment from "moment";
class WorkOrdersViewImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUpload: false,
      modalDelete: false,
      isEdit: false,
      selectedImage: null,
      initialFormValues: null,
      selectedUploader: { label: "All", value: "All" },
    };
  }

  componentDidUpdate(prevProps) {
    const { uploadedWoImageData } = this.props;
    if (prevProps.uploadedWoImageData !== uploadedWoImageData) {
      this.setState({
        selectedImage: uploadedWoImageData[0],
      });
    }
  }

  toggleOpenUpload = () => {
    this.setState({
      modalUpload: !this.state.modalUpload,
    });
    this.props.fetchWorkOrdersUploadedImage(this.props.idDo, this.props.idWo);
  };

  refreshModal = () => {
    this.props.fetchWorkOrdersUploadedImage(this.props.idDo, this.props.idWo);
    this.setState({
      isEdit: false,
    });
  };

  toggleCloseUpload = () => {
    this.setState({
      modalUpload: !this.state.modalUpload,
      isEdit: false,
    });
  };

  showEditMode = () => {
    this.setState({
      isEdit: true,
    });
  };

  toggleOpenDelete = (_row) => {
    this.setState({
      modalDelete: !this.state.modalDelete,
      initialFormValues: {
        id: _row.id,
        desc: _row.desc,
      },
    });
  };

  toggleCloseDelete = (_row) => {
    this.setState({
      modalDelete: !this.state.modalDelete,
      initialFormValues: {
        id: null,
        desc: "",
      },
    });
  };

  WoImagesList = (porps) => {
    const { uploadedWoImageData, uploadedWoImageDatav2, uploaderEmailSelect } =
      this.props;
    const { selectedImage, selectedUploader } = this.state;
    return (
      <React.Fragment>
        {this.props.inProgress ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.props.inProgress} />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : this.props.success ? (
          <React.Fragment>
            <Alert color="info">
              {uploadedWoImageData.length === 0
                ? this.context.can("view", "pod_upload@pod_button") &&
                  this.props.isfromWOBuilder
                  ? "You could upload POD using form bellow"
                  : "No image found!"
                : "Click the image to be able see the true size"}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {this.context.can("view", "edit_pod_upload@pod_button") &&
              this.props.isfromWOBuilder &&
              uploadedWoImageData.length !== 0 ? (
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => this.showEditMode()}
                  disabled={this.state.isEdit}
                >
                  Add Another POD
                </Button>
              ) : null}
            </Alert>
            {(uploadedWoImageData.length === 0 &&
              this.context.can("view", "pod_upload@pod_button") &&
              this.props.isfromWOBuilder) ||
            this.state.isEdit ? (
              <WorkOrdersImageUpload
                idDo={this.props.idDo}
                isEdit={this.state.isEdit}
                idWo={this.props.idWo}
                totalWoImage={0}
                woNumber={this.props.woNumber}
                refreshModal={this.refreshModal}
                closeModal={this.toggleCloseUpload}
              />
            ) : null}
            {selectedImage && (
              <ListGroup
                style={{
                  "flex-direction": "row",
                  display: "block",
                  marginLeft: "auto",
                  "margin-right": "auto",
                }}
              >
                <ListGroupItem>
                  <this.WoImageItem image={selectedImage} />
                </ListGroupItem>
              </ListGroup>
            )}
            <br />
            {uploadedWoImageData.length > 0 && (
              <>
                <SelectField
                  id="selected_email"
                  name="selected_email"
                  label="selected_email"
                  placeholder="Select Uploader"
                  options={uploaderEmailSelect}
                  value={selectedUploader}
                  isMulti={false}
                  onChange={async (field, selection) => {
                    if (selection) {
                      this.setState({
                        selectedUploader: {
                          label: selection.label,
                          value: selection.value,
                        },
                      });
                    }
                  }}
                  onBlur={() => {}}
                  touched={() => {}}
                  isClearable={false}
                  backspaceRemovesValue={false}
                />
                <br />
              </>
            )}
            {uploadedWoImageData.length > 0 && (
              <div
                style={{
                  display: "flex",
                  width: "auto",
                  overflow: "auto",
                  outline: "1px solid grey",
                }}
              >
                {selectedUploader["value"] === "All" &&
                  uploadedWoImageData.map((image) => (
                    <Col
                      xs="3"
                      md="3"
                      style={{
                        "margin-right": "72px",
                        "margin-top": "7px",
                        marginBottom: "7px",
                        position: "relative",
                      }}
                    >
                      <span
                        onClick={() => this.setState({ selectedImage: image })}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          width="256px"
                          height="128px"
                          border="0"
                          src={image.link}
                          alt=""
                        />
                      </span>
                      {this.context.can("view", "pod_delete@pod_button") &&
                        !this.props.id_request_wo_3pl && (
                          <div
                            style={{
                              position: "absolute",
                              right: -79,
                              top: 0,
                              "z-index": 5,
                            }}
                          >
                            <Button
                              className="btn btn-danger"
                              color="danger"
                              size="md"
                              onClick={() => this.toggleOpenDelete(image)}
                            >
                              <p style={{ color: "white", fontWeight: "bold" }}>
                                X
                              </p>
                            </Button>
                          </div>
                        )}
                    </Col>
                  ))}
                {selectedUploader["value"] !== "All" &&
                  uploadedWoImageDatav2[selectedUploader["value"]].map(
                    (image) => (
                      <Col
                        xs="3"
                        md="3"
                        style={{
                          "margin-right": "72px",
                          "margin-top": "7px",
                          marginBottom: "7px",
                          position: "relative",
                        }}
                      >
                        <span
                          onClick={() =>
                            this.setState({ selectedImage: image })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            width="256px"
                            height="128px"
                            border="0"
                            src={image.link}
                            alt=""
                          />
                        </span>
                        {this.context.can("view", "pod_delete@pod_button") &&
                          !this.props.id_request_wo_3pl && (
                            <div
                              style={{
                                position: "absolute",
                                right: -79,
                                "z-index": 5,
                                top: 0,
                              }}
                            >
                              <Button
                                className="btn btn-danger"
                                color="danger"
                                size="md"
                                onClick={() => this.toggleOpenDelete(image)}
                              >
                                <p
                                  style={{ color: "white", fontWeight: "bold" }}
                                >
                                  X
                                </p>
                              </Button>
                            </div>
                          )}
                      </Col>
                    )
                  )}
              </div>
            )}
          </React.Fragment>
        ) : (
          <center>
            Error fetching your data... <br />
            <br />
            <Button
              className="btn btn-primary"
              color="primary"
              size="md"
              disabled={this.props.inProgress}
              onClick={() =>
                this.props.fetchWorkOrdersUploadedImage(
                  this.props.idDo,
                  this.props.idWo
                )
              }
            >
              <i className="fa fa-refresh"></i>
              Try again
            </Button>
          </center>
        )}
      </React.Fragment>
    );
  };

  WoImageItem = (props) => {
    const { image } = props;
    return (
      <React.Fragment>
        <Row>
          <Col xs="12" md="12">
            <a href={image.link} target="_blank" rel="noopener noreferrer">
              <img
                width="100%"
                height="auto"
                border="0"
                align="center"
                src={image.link}
                alt=""
              />
            </a>{" "}
          </Col>
        </Row>
        <Row>
          <Col xs="10" md="10">
            <p style={{ "font-size": "10pt" }}>
              <b>{image.desc}</b> - {image.email_user} on{" "}
              {moment
                .utc(image.created_on)
                .local()
                .format("YYYY-MM-DD HH:mm:ss")}
            </p>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  render() {
    const { woNumber, size } = this.props;
    const { initialFormValues } = this.state;
    return (
      <React.Fragment>
        <Button
          color="primary"
          size={size}
          onClick={() => {
            this.toggleOpenUpload();
          }}
        >
          <i className="fa fa-envelope-open-o"></i> Proof of Delivery
        </Button>
        <Modal
          isOpen={this.state.modalUpload}
          className={this.props.className}
          backdrop="static"
          size="lg"
        >
          <ModalHeader toggle={this.toggleCloseUpload}>
            Proof of Delivery of WO {woNumber}
          </ModalHeader>
          <ModalBody>
            <this.WoImagesList />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalDelete}
          toggle={this.toggleOpenDelete}
          className={this.props.className}
          backdrop="static"
        >
          <ModalHeader toggle={this.toggleCloseDelete}>
            Delete Selected Image
          </ModalHeader>
          <ModalBody>
            <WorkOrderImageDeletionConfirmation
              initialValues={initialFormValues}
              deleteFormClose={this.toggleCloseDelete}
              refreshData={this.refreshModal}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uploadedWoImageData: state.woUploadedImageStore.uploadedWoImageData,
    uploadedWoImageDatav2: state.woUploadedImageStore.uploadedWoImageDatav2,
    uploaderEmailSelect: state.woUploadedImageStore.uploaderEmailSelect,
    inProgress: state.woUploadedImageStore.inProgress,
    success: state.woUploadedImageStore.success,
    totalWoImage: state.woUploadedImageStore.totalWoImage,
  };
};

WorkOrdersViewImageUpload.contextType = AbilityContext;
export default connect(mapStateToProps, { fetchWorkOrdersUploadedImage })(
  WorkOrdersViewImageUpload
);

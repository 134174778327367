import React, { Component } from "react";
import {
  Row,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Col,
  Button,
  ButtonGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import { BarLoader } from "react-spinners";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import Dropzone from "../../../components/Dropzone";
import { toast } from "react-toastify";
import { fetchWorkOrdersUploadedImage } from "../../../redux/actions/transporter/aTransporter-WorkOrdersTable.jsx";
import handleErrorResponseCode from "../../../helpers/error-response-code-handler";
import {
  WORK_ORDER_UPLOAD_IMAGE_URL,
  WORK_ORDER_UPLOADED_IMAGE_DELETE_URL,
} from "../../../redux/config/links";

class WorkOrdersImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDeleteImage: false,
      isAdd: true,
      validationObjs: { caption1: Yup.string().required("Caption 1 required") },
      validations: Yup.object().shape({
        caption1: Yup.string().required("Caption 1 required"),
      }),
      formsAndValues: [
        {
          caption: null,
          imageObj: null,
        },
      ],
    };
  }

  // toggleOpenUpload = () => {

  // }

  // toggleCloseUpload = () => {
  //     this.setState({
  //         modalUpload: !this.state.modalUpload,
  //         validationObjs: null,
  //         validations: null,
  //         formsAndValues: []
  //     })
  // }

  handleOnDrop = (result, index) => {
    //Dropzone
    const { formsAndValues } = this.state;
    let tempFormsAndValues = formsAndValues;
    if (result.length === 0) {
      tempFormsAndValues[index]["imageObj"] = null;
      this.setState({
        uploadedEvidence: [],
        formsAndValues: tempFormsAndValues,
      });
      // console.log(formsAndValues);
      return;
    }
    if (!result.passed) {
      toast.error("Wo image, " + result.reasonRejected);
    }
    tempFormsAndValues[index]["imageObj"] = result;
    this.setState({ formsAndValues: tempFormsAndValues });
  };

  AddRemoveForm = (props) => {
    let maxFormsBuild = props.uploadLimit;
    const MIN_FORM_BUILD = 1;
    const { disableAdd, disableRemove, formsAndValues } = this.state;
    let currentFormTotal = formsAndValues.length;
    return (
      <ButtonGroup>
        <Button
          color="primary"
          onClick={() =>
            this.addForm(currentFormTotal, maxFormsBuild, MIN_FORM_BUILD)
          }
          disabled={disableAdd}
        >
          <i className="fa fa-plus"></i>
        </Button>
        <Button
          color="primary"
          onClick={() =>
            this.removeForm(currentFormTotal, maxFormsBuild, MIN_FORM_BUILD)
          }
          disabled={disableRemove}
        >
          <i className="fa fa-minus"></i>
        </Button>
      </ButtonGroup>
    );
  };

  addForm(currentFormTotal, maxForm, minForm) {
    let formsAndValuesTemp = this.state.formsAndValues;
    let validationObjsTemp = this.state.validationObjs;
    let nexValidationObjText = `Caption ${currentFormTotal + 1} required`;
    formsAndValuesTemp.push({
      caption: null,
      imageObj: null,
    });
    validationObjsTemp["caption" + Number(currentFormTotal + 1)] =
      Yup.string().required(nexValidationObjText);

    this.setState({
      formsAndValues: formsAndValuesTemp,
      validationObjs: validationObjsTemp,
      disableAdd: this.checkReachMax(currentFormTotal + 1, maxForm),
      disableRemove: this.checkReachMin(currentFormTotal + 1, minForm),
    });
    this.updateValidations(validationObjsTemp);
  }

  removeForm(currentFormTotal, maxForm, minForm) {
    let formsAndValuesTemp = this.state.formsAndValues;
    let validationObjsTemp = this.state.validationObjs;
    formsAndValuesTemp.pop();
    delete validationObjsTemp["caption" + currentFormTotal];
    this.setState({
      formsAndValues: formsAndValuesTemp,
      validationObjs: validationObjsTemp,
      disableAdd: this.checkReachMax(currentFormTotal - 1, maxForm),
      disableRemove: this.checkReachMin(currentFormTotal - 1, minForm),
    });
    this.updateValidations(validationObjsTemp);
  }

  updateValidations(newValidations) {
    let updatedValidations = Yup.object().shape(newValidations);
    this.setState({
      validations: updatedValidations,
    });
  }

  checkReachMax(currentFormTotal, maxForm) {
    if (currentFormTotal === maxForm) {
      return true;
    }
    return false;
  }

  checkReachMin(currentFormTotal, minForm) {
    if (currentFormTotal === minForm) {
      return true;
    }
    return false;
  }

  UploadForm = (props) => {
    const { validations, formsAndValues, isAdd } = this.state;
    return (
      <React.Fragment>
        {/* <Row>
                    <Col xs="12" md="12">
                        <Alert color="info">You can stack up to {uploadLimit} upload forms!</Alert>
                    </Col>
                    <Col xs="12" md="12">
                        <this.AddRemoveForm uploadLimit={uploadLimit}/>
                    </Col>
                </Row>
                <br /> */}
        <Formik
          onSubmit={(values, { setSubmitting }) => {
            const { formsAndValues } = this.state;
            let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
            let auth = loginCreds.token;
            setSubmitting(true);
            if (isAdd) {
              Promise.all(
                formsAndValues.map((obj, index) => {
                  let formDataUploadImage = new FormData();
                  let imageFile = obj["imageObj"]
                    ? obj["imageObj"].uploadedFiles[0]
                    : null;
                  if (imageFile === null) {
                    toast.error("Cannot submit an empty file!");
                    return 1;
                  }
                  formDataUploadImage.append("file", imageFile);
                  formDataUploadImage.append(
                    "image_desc",
                    values["caption" + Number(index + 1)]
                  );
                  formDataUploadImage.append("id_work_order", this.props.idWo);
                  return axios({
                    method: "post",
                    url: WORK_ORDER_UPLOAD_IMAGE_URL,
                    headers: {
                      Authorization: auth,
                    },
                    data: formDataUploadImage,
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        setSubmitting(false);
                        toast.success(
                          `${
                            values["caption" + Number(index + 1)]
                          } has been uploaded succesfully!`
                        );
                        this.props.refreshModal();
                      }
                    })
                    .catch(function (error) {
                      setSubmitting(false);
                      toast.error(handleErrorResponseCode(error));
                    });
                })
              )
                .then((result) => {
                  if (result[0] === 1) {
                    return;
                  }
                  // setTimeout(() => {
                  //     this.props.fetchWorkOrdersUploadedImage(
                  //         this.props.idDo,
                  //         this.props.idWo
                  //     )
                  // }, 2500);
                })
                .catch(function (error) {
                  setSubmitting(false);
                  toast.error(error);
                });
              // addFormClose()
            }
            setSubmitting(false);
          }}
          validationSchema={validations}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            handleBlur,
            handleChange,
            isValid,
            isSubmitting,
          }) => (
            <form>
              {formsAndValues.map((formAndValue, index) => (
                <React.Fragment>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="text-input">{"Image"}</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Dropzone
                        maxFile={1}
                        maxSize={10000000} //10MB
                        accept="image/png, image/jpeg"
                        onDrop={(result) => this.handleOnDrop(result, index)}
                        preview={true}
                        disabled={isSubmitting}
                      ></Dropzone>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="text-input">{`Image Caption`}</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        type="text"
                        name={"caption" + Number(index + 1)}
                        id={"caption" + Number(index + 1)}
                        placeholder={"Image Caption " + Number(index + 1)}
                        autoComplete={"caption" + Number(index + 1)}
                        valid={!errors["caption" + Number(index + 1)]}
                        invalid={
                          touched["caption" + Number(index + 1)] &&
                          !!errors["caption" + Number(index + 1)]
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values["caption" + Number(index + 1)] || ""}
                        disabled={isSubmitting}
                      />
                      <FormFeedback>
                        {errors["caption" + Number(index + 1)]}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                </React.Fragment>
              ))}
              <FormGroup>
                <Button
                  type="button"
                  onClick={handleSubmit}
                  color="primary"
                  className="mr-1"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? "Wait..." : isAdd ? "Submit" : "Edit"}
                </Button>
                <Button
                  type="button"
                  color="warning"
                  className="mr-1"
                  disabled={isSubmitting}
                  onClick={() => this.props.closeModal()}
                >
                  Close
                </Button>
              </FormGroup>
            </form>
          )}
        />
      </React.Fragment>
    );
  };

  toggleOpenDeleteImage = (_id, _desc) => {
    this.setState({
      idImageDelete: _id,
      desc: _desc,
      modalDeleteImage: !this.state.modalDeleteImage,
    });
  };

  toggleCloseDeleteImage = () => {
    this.setState({
      idImageDelete: null,
      desc: "",
      modalDeleteImage: !this.state.modalDeleteImage,
    });
  };

  DeleteImageForm = (props) => {
    return (
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
          let auth = loginCreds.token;
          setSubmitting(true);
          axios({
            method: "post",
            url: WORK_ORDER_UPLOADED_IMAGE_DELETE_URL,
            headers: {
              Authorization: auth,
            },
            data: { id: this.state.idImageDelete },
          })
            .then((response) => {
              if (response.status === 200) {
                setSubmitting(false);
                toast.success(
                  `Image ${this.state.desc} has been deleted succesfully!`
                );
                this.toggleCloseDeleteImage();
                this.props.fetchWorkOrdersUploadedImage(
                  this.props.idDo,
                  this.props.idWo
                );
              }
            })
            .catch(function (error) {
              setSubmitting(false);
              toast.error(handleErrorResponseCode(error));
            });
          // deleteFormClose()
          // setSubmitting(false);
        }}
        render={({ handleSubmit, isSubmitting }) => (
          <form>
            <FormGroup row>
              <Col md="12">
                <Label htmlFor="text-input">
                  Are you sure to delete this image?
                </Label>
              </Col>
            </FormGroup>
            <FormGroup>
              <Button
                type="button"
                onClick={handleSubmit}
                color="danger"
                className="mr-1"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Deleting..." : "Delete"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={isSubmitting}
                onClick={() => this.props.closeModal()}
              >
                Close
              </Button>
            </FormGroup>
          </form>
        )}
      />
    );
  };

  WoImagesList = (porps) => {
    const { uploadedWoImageData } = this.props;
    return (
      <React.Fragment>
        <dt>Last POD Image(s) Uploaded</dt>
        <ListGroup
          style={{
            "flex-direction": "row",
            display: "block",
            marginLeft: "auto",
            "margin-right": "auto",
          }}
        >
          {uploadedWoImageData.map((item) => (
            <ListGroupItem>
              <this.WoImageItem image={item} />
            </ListGroupItem>
          ))}
        </ListGroup>
      </React.Fragment>
    );
  };

  WoImageItem = (props) => {
    const { image } = props;
    return (
      <React.Fragment>
        <Row>
          <Col xs="12" md="12">
            <a href={image.link} target="_blank" rel="noopener noreferrer">
              <img
                width="100%"
                height="300"
                border="0"
                align="center"
                src={image.link}
                alt=""
              />
            </a>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.props.inProgress ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.props.inProgress} />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : this.props.success ? (
          <React.Fragment>
            {/* <this.WoImagesList /><br /> */}
            {/* <Button color="primary" onClick={() => {this.toggleOpenUpload()}}
                                        disabled={totalWoImage === MAX_IMAGE? true : false}>
                                            <i className="fa fa-upload"></i> Upload WO Image(s)
                                </Button> */}
            {/* <Modal isOpen={this.state.modalUpload} toggle={this.toggleOpenUpload} className={this.props.className} backdrop="static">
                                    <ModalHeader toggle={this.toggleCloseUpload}>Upload Image of WO {woNumber}</ModalHeader>
                                    <ModalBody>
                                            <this.UploadForm />
                                    </ModalBody>
                                </Modal> */}
            <this.UploadForm />
            <Modal
              isOpen={this.state.modalDeleteImage}
              toggle={this.toggleOpenDeleteImage}
              className={this.props.className}
              backdrop="static"
            >
              <ModalHeader toggle={this.toggleCloseDeleteImage}>
                Delete Image {this.state.desc} of {this.props.woNumber}
              </ModalHeader>
              <ModalBody>
                <this.DeleteImageForm />
              </ModalBody>
            </Modal>
          </React.Fragment>
        ) : (
          <center>
            Error fetching your data... <br />
            <br />
            <Button
              className="btn btn-primary"
              color="primary"
              size="md"
              disabled={this.props.inProgress}
              onClick={() =>
                this.props.fetchWorkOrdersUploadedImage(
                  this.props.idDo,
                  this.props.idWo
                )
              }
            >
              <i className="fa fa-refresh"></i>
              Try again
            </Button>
          </center>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uploadedWoImageData: state.woUploadedImageStore.uploadedWoImageData,
    inProgress: state.woUploadedImageStore.inProgress,
    success: state.woUploadedImageStore.success,
    totalWoImage: state.woUploadedImageStore.totalWoImage,
  };
};

export default connect(mapStateToProps, { fetchWorkOrdersUploadedImage })(
  WorkOrdersImageUpload
);

import React, { Component } from 'react';
import {
  Card,
  CardBody,
//   Input,
//   Button
} from 'reactstrap';
// import ReactSpeedometer from "react-d3-speedometer";

class EnviromaTemperature extends Component {
    constructor(props){
        super(props);
        this.state = {
            alert: 64.4
        }
    }

    overallRatingsColor(){
        var alertValue;
        if(this.state.alert > 100){
          alertValue = "bg-danger"
        } else {
          alertValue = "bg-success"
        }
        return alertValue
    }

    render(){
        return(
            <div className="animated fadeIn">
                <Card>
                    <CardBody>
                        <div className="flex-container">
                            <div className="flex-column-75">
                                <Card>
                                    <div className="title-text-temp">Temperature</div>
                                    <CardBody>
                                        <div className="flex-container">
                                            <div className="flex-column-25">Number of Devices</div>
                                            <div className="flex-column-75">: 12</div>
                                            <div className="flex-column-25">Alert Raised</div>
                                            <div className="flex-column-75">: 0</div>
                                            <div className="flex-column-25">Highest Level</div>
                                            <div className="flex-column-temp-details">: 87</div>
                                            <div className="flex-column-25">Lowest Level</div>
                                            <div className="flex-column-temp-details">: 55</div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className="flex-column-25">
                                <Card className={this.overallRatingsColor()}>
                                <CardBody>
                                    <div className="title-text-ratings">64.4</div>
                                </CardBody>
                                </Card>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <Card>
                    <div className="flex-container">
                        <div className="flex-column-100">
                            <div className="title-text-tempRooms">Bedroom</div>
                        </div>
                        <div className="flex-column-30">
                            <Card className="bg-primary">
                                <div className="title-text-speedometer">Average Temperature</div>
                                <div className="title-text-details">60</div>
                                <div className="title-text-progressbar">(Progress bar here)</div>
                            </Card>
                        </div>
                        <div className="flex-column-70">
                            Timeseries Chart
                        </div>

                        <div className="flex-column-100">
                            <div className="title-text-tempRooms">Warehouse</div>
                        </div>
                        <div className="flex-column-30">
                            <Card className="bg-primary">
                                <div className="title-text-speedometer">Average Air Pressure</div>
                                <div className="title-text-details">60</div>
                                <div className="title-text-progressbar">(Progress bar here)</div>
                            </Card>
                        </div>
                        <div className="flex-column-70">
                            Timeseries Chart
                        </div>

                        <div className="flex-column-100">
                            <div className="title-text-tempRooms">Bathroom</div>
                        </div>
                        <div className="flex-column-30">
                            <Card className="bg-primary">
                                <div className="title-text-speedometer">Average Humidity</div>
                                <div className="title-text-details">60</div>
                                <div className="title-text-progressbar">(Progress bar here)</div>
                            </Card>
                        </div>
                        <div className="flex-column-70">
                            Timeseries Chart
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

export default EnviromaTemperature;
import React, { 
  useEffect
 } from "react";
import { useHistory } from "react-router-dom";

const DefaultTemplate = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/transporter/command-center") {
        [...document.querySelectorAll(".main .container-fluid")].forEach((el) => {
          el.style.padding = 0;
        });
        [...document.querySelectorAll(".breadcrumb")].forEach((el) => {
          el.style.marginBottom = 0;
        });
    } else {
      [...document.querySelectorAll(".main .container-fluid")].forEach((el) => {
        el.style.padding = "0 30px";
      });
      [...document.querySelectorAll(".breadcrumb")].forEach((el) => {
        el.style.marginBottom = "1.5rem";
      });
    }
  }, [history.location]);

  return <>{children}</>;
};

export default DefaultTemplate;

import React, { Component } from 'react';
import {
    Button
} from 'reactstrap'
import { connect } from 'react-redux'
import {toast} from 'react-toastify'
import PrintableTriPLInvoice from './PrintableQr';
import { fetchFleetsQR } from '../../../../redux/actions/transporter/aTransporter-FleetListTable';
import {pdf} from '@react-pdf/renderer'
import moment from 'moment'
import QRCode from 'qrcode'

class PrintableButton extends Component{

    constructor(props) {
        super(props);
        this.state = { 
            fleets:[]
        }
    }

    componentDidUpdate(prevProps){
        const {fleets} = this.props
        if(prevProps.fleets !== fleets){
            this.setState({
                fleets
            })
        }
    }

    generateQrForEverySelectedFleet = async() => {
        const {fleetsQr} = this.props
        let fleetsTemp = fleetsQr
        fleetsTemp.map(f => {
            QRCode.toDataURL(f.qr,{type:'png'}, 
                function (err, url) {
                    f.qr=url
                })
            return f;
        })
        this.setState({fleets: fleetsTemp})
    }


    makeDocumentName = () => {
        return `Fleet(s) QR ${moment().format("YYYY-MM-DD HH:mm:ss")} .pdf`
    }

    printQr = async (isFetch, numberOfTry) => {
        const {fleets} = this.state
        const {fleetsQr, fleetsQrFetchInProgress} = this.props
        if(isFetch){
            this.props.fetchFleetsQR(fleets)
        }
        if(numberOfTry === 5){
            toast.error("Something wrong when load Qr!")
            return
        }
        if(!fleetsQrFetchInProgress && fleetsQr.length > 0){
            await this.generateQrForEverySelectedFleet()
            const doc = <PrintableTriPLInvoice 
                fleets={fleets}
            />
            const asPdf = pdf([]);
            asPdf.updateContainer(doc);
            const blob = await asPdf.toBlob();
            var csvURL = window.URL.createObjectURL(blob);
            var tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', this.makeDocumentName());
            tempLink.click();

            
        }else{
         setTimeout(() => {
            this.printQr(false, numberOfTry+1)
         }, 1000)
        }
    }

    isAnyFleetSelected = () => {
        const {fleets} = this.props
        if(!Array.isArray(fleets))
            return false
        if(fleets.length === 0)
            return false
        return true
    }

    render(){
        return(
            <>
                <Button className="mr-2"
                        color="primary"
                        disabled={!this.isAnyFleetSelected()}
                        onClick={async () => await this.printQr(true, 1)}>
                    <i className="fa fa-qrcode"></i> Generate QR
                </Button>
            </>
        )
    }

}

const mapStateToProps = (state) => ({
    fleetsQr: state.fleetQrListStore.fleetsQr,
    fleetsQrFetchInProgress: state.fleetQrListStore.inProgress
})
export default connect(mapStateToProps, {fetchFleetsQR})(PrintableButton);
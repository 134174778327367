import React from "react";
import { BarLoader } from "react-spinners";
import { Button } from "reactstrap";

const LoadingContent = ({
  loading = false,
  isSuccess = true,
  onReload = () => null,
  children,
}) => {
  return (
    <>
      {loading ? (
        <center>
          <BarLoader color={"#123abc"} loading /> <br /> Loading.... Please
          wait...
        </center>
      ) : (
        <>
          {isSuccess ? (
            <>{children}</>
          ) : (
            <center>
              Error fetching your data... <br />
              <br />
              <Button
                className="btn btn-primary"
                color="primary"
                size="md"
                disabled={false}
                onClick={() => onReload()}
              >
                <i className="fa fa-refresh"></i>
                Try again
              </Button>
            </center>
          )}
        </>
      )}
    </>
  );
};

export default LoadingContent;

import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container, Alert } from "reactstrap";
import styles from "./DefaultLayout.module.scss";
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
import navigation from "../../_nav";
import routes from "../../routes.js";
import managerRoutes from "../../routes/managerRoutes.jsx";
import hrRoutes from "../../routes/hrRoutes";
import maintenanceRoutes from "../../routes/maintenanceRoutes";
import managerNav from "../../routes/managerNav.jsx";
import mechanicRoutes from "../../routes/mechanicRoutes";
import mechanicNav from "../../routes/mechanicNav";
import headMechanicNav from "../../routes/headMechanicNav";
import hrNav from "../../routes/hrNav";
import maintenanceNav from "../../routes/maintenanceNav";
import securityRoutes from "../../routes/securityRoutes.jsx";
import securityNav from "../../routes/securityNav.jsx";
import goodsTraderIntercompanyNav from "../../routes/goodsTraderIntercompanyNav";
import goodsTraderIntercompanyRoutes from "../../routes/goodsTraderIntercompanyRoutes";
import { connect } from "react-redux";
import { runSetupForCompanyWorkshop } from "../../redux/actions/aUser-Company-Initialize";
import Notification from "./NotificationWrapper";
import AuthCheck from "./AuthCheck";
import WalletPayment from "./WalletPayment";
import { walletRoute } from "../../views/Wallet/Wallet.constants";
import { allStoringRoutes } from "../../views/Storing/Storing.constants";
import { allIncidentStoringRoutes } from "../../views/StoringIncident/Incident.constants";
import DefaultTemplate from "../../components/Template/DefaultTemplate/DefaultTemplate";

const DefaultAside = React.lazy(() => import("./DefaultAside"));
const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

class DefaultLayout extends Component {
  state = {
    searchTerm: "",
    filteredNav: null,
  };

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  componentDidMount() {
    this.props.runsetupforcompanyworkshop();
    const filteredNav = this.getCurrentNav();
    this.setState({ filteredNav });
  }

  handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredNav = this.filterNavigation(this.getCurrentNav(), searchTerm);
    this.setState({ searchTerm, filteredNav });
  };

  filterNavigation = (nav, searchTerm) => {
    return {
      ...nav,
      items: nav.items
        .map((item) => this.filterItem(item, searchTerm))
        .filter(Boolean),
    };
  };

  filterItem = (item, searchTerm) => {
    if (item.name.toLowerCase().includes(searchTerm)) {
      return item;
    }
    if (item.children) {
      const filteredChildren = item.children
        .map((child) => this.filterItem(child, searchTerm))
        .filter(Boolean);
      if (filteredChildren.length > 0) {
        return { ...item, children: filteredChildren };
      }
    }
    return null;
  };

  getCurrentNav = () => {
    const { authData } = this.props;

    if (!authData || !authData.result) {
      return navigation; // default navigation
    }

    if (authData.result.id_type_company === 4) {
      return goodsTraderIntercompanyNav;
    }

    switch (authData.result.id_roles) {
      case 1:
      case 4:
      case 5:
        return navigation;
      case 2:
        return managerNav;
      case 3:
        return securityNav;
      case 6:
        return mechanicNav;
      case 7:
        return headMechanicNav;
      case 13:
        return hrNav;
      case 14:
        return maintenanceNav;
      default:
        return navigation;
    }
  };

  layout = (nav, appRoutes) => {
    const routesBreadCrumb = appRoutes.filter((item) => item.exact === true);

    let newProps = { ...this.props };
    delete newProps.runsetupforcompanyworkshop;
    delete newProps.inProgress;
    delete newProps.isAuthenticated;
    delete newProps.authData;
    delete newProps.email_verified;

    return (
      <AuthCheck config={{ autoLogoutOnExpired: true }}>
        <WalletPayment>
          <Notification>
            <div className="app">
              <AppHeader fixed>
                <Suspense fallback={this.loading()}>
                  <DefaultHeader />
                </Suspense>
              </AppHeader>
              <div className="app-body">
                <AppSidebar fixed display="lg" minimized>
                  <AppSidebarHeader />
                  <AppSidebarForm>
                    <input
                      type="text"
                      className={`${styles["search-bar"]}`}
                      placeholder="Search..."
                      value={this.state.searchTerm}
                      onChange={this.handleSearchChange}
                    />
                  </AppSidebarForm>
                  <Suspense fallback={this.loading()}>
                    <AppSidebarNav
                      navConfig={this.state.filteredNav || this.getCurrentNav()}
                      {...newProps}
                    />
                  </Suspense>
                  <AppSidebarFooter />
                  <AppSidebarMinimizer />
                </AppSidebar>
                <main className="main">
                  <AppBreadcrumb appRoutes={routesBreadCrumb} />
                  <Container fluid>
                    {!this.props.email_verified ? (
                      <Alert color="warning">
                        <b>
                          <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                          Please verify your email! On 15 November 2022
                          unverified emails will be denied to access the
                          platform.
                        </b>
                      </Alert>
                    ) : null}
                    <Switch>
                      <Route
                        path={"/"}
                        render={(props) => (
                          <DefaultTemplate {...props}>
                            <Suspense fallback={this.loading()}>
                              <Switch>
                                {appRoutes.map((route, idx) => {
                                  return route.component ? (
                                    <Route
                                      key={idx}
                                      path={route.path}
                                      exact={route.exact}
                                      name={route.name}
                                      render={(props) => (
                                        <route.component {...props} />
                                      )}
                                    />
                                  ) : null;
                                })}
                                <Redirect from="/" to="/welcome" />
                              </Switch>
                            </Suspense>
                          </DefaultTemplate>
                        )}
                      />
                    </Switch>
                  </Container>
                </main>
                <AppAside fixed>
                  <Suspense fallback={this.loading()}>
                    <DefaultAside />
                  </Suspense>
                </AppAside>
              </div>
              <AppFooter>
                <Suspense fallback={this.loading()}>
                  <DefaultFooter />
                </Suspense>
              </AppFooter>
            </div>
          </Notification>
        </WalletPayment>
      </AuthCheck>
    );
  };

  render() {
    const guestLinks = <Redirect to="/login" />;
    const adminLinks = this.layout(this.state.filteredNav, [
      ...routes,
      ...walletRoute(),
      ...allStoringRoutes(),
      ...allIncidentStoringRoutes(),
    ]);

    const managerLinks = this.layout(managerNav, managerRoutes);
    const securityLinks = this.layout(securityNav, securityRoutes);
    const mechanicLinks = this.layout(mechanicNav, mechanicRoutes);
    const headMechanicLinks = this.layout(headMechanicNav, mechanicRoutes);
    const goodsIntercompanyTraderLink = this.layout(
      goodsTraderIntercompanyNav,
      [...goodsTraderIntercompanyRoutes, ...walletRoute()]
    );
    const hrLinks = this.layout(hrNav, hrRoutes);
    const maintenanceLinks = this.layout(maintenanceNav, maintenanceRoutes);

    if (this.props.isAuthenticated) {
      if (this.props.authData.result.id_type_company === 4)
        return goodsIntercompanyTraderLink;
      if (this.props.authData.result.id_roles === 1) {
        return adminLinks;
      } else if (this.props.authData.result.id_roles === 2) {
        return managerLinks;
      } else if (this.props.authData.result.id_roles === 3) {
        return securityLinks;
      } else if (
        this.props.authData.result.id_roles === 4 ||
        this.props.authData.result.id_roles === 5
      ) {
        return adminLinks;
      } else if (this.props.authData.result.id_roles === 6) {
        return mechanicLinks;
      } else if (this.props.authData.result.id_roles === 7) {
        return headMechanicLinks;
      } else if (
        this.props.authData.result.id_roles === 13
      ) {
        return hrLinks;
      } else if (
        this.props.authData.result.id_roles === 14
      ) {
        return maintenanceLinks;
      } else {
        return window.location.reload();
      }
    } else {
      return guestLinks;
    }
  }
}

function mapStateToProps(state) {
  return {
    authData: state.authStore.authData,
    inProgress: state.authStore.inProgress,
    isAuthenticated: state.authStore.isAuthenticated,
    email_verified: state.authStore.email_verified,
  };
}

export default connect(mapStateToProps, {
  runsetupforcompanyworkshop: runSetupForCompanyWorkshop,
})(DefaultLayout);

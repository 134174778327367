import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PaymentSelection from "./contents/PaymentSelection/PaymentSelection";
import { useDispatch, useSelector } from "react-redux";
import { onPayment } from "../../redux/actions/wallet/aWallet-Saldo";

const WalletPayment = ({ children }) => {
  const { walletSaldo } = useSelector((state) => ({ walletSaldo: state.walletSaldo }));
  const dispatch = useDispatch();


  return (
    <>
      {children}
      <Modal
        size="sm"
        isOpen={walletSaldo.isModalPaymentOpen}
        toggle={() =>
            dispatch(onPayment())
        }
        backdrop="static"
      >
        <ModalHeader
          toggle={() =>
            dispatch(onPayment())
          }
        >
          Payment
        </ModalHeader>
        <ModalBody>
          <PaymentSelection
            onClose={() => dispatch(onPayment())}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default WalletPayment;

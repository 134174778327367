//For Base URL and User Authentication
const BASE_URL = "https://api.automa.id/api";

const USER_URL = BASE_URL + "/user";
const LOGIN_URL = USER_URL + "/login";
const REGISTER_URL = USER_URL + "/register";
// const CHECK_URL = BASE_URL+"/auth";
const USER_LIST_URL = USER_URL + "/list";
const USER_STATUS_URL = USER_URL + "/status";
const USER_VERIFY_URL = "https://api.automa.id/validation/user";
const CHANGE_PASSWORD_URL = BASE_URL + "/user/changepassword";
const RESEND_VERIFY_EMAIL_URL = USER_URL + "/reqverifybyadmin";
const USER_NOTIFICATION_TOKEN = USER_URL + "/token/insert";

const DRIVER_FLEET_SCAN_INPUT = BASE_URL + "/scan/fleet/driver/input";
const DRIVER_FLEET_SCAN_EDIT = BASE_URL + "/scan/fleet/driver/edit";
const DRIVER_FLEET_SCAN_LIST = BASE_URL + "/scan/fleet/driver/list";
const DRIVER_FLEET_SCAN_DELETE = BASE_URL + "/scan/fleet/driver/delete";
const DRIVER_BASE_URL = BASE_URL + "/driver";
const DRIVER_LIST_URL = DRIVER_BASE_URL + "/list/driver";
const DRIVER_LIST_URL_V2 = DRIVER_BASE_URL + "/list/driver/v2";
const DRIVER_FLEETS_LIST_URL = DRIVER_BASE_URL + "/binding/fleet/list";
const DRIVER_FLEETS_INPUT_URL = DRIVER_BASE_URL + "/binding/fleet/input";
const DRIVER_FLEETS_DELETE_URL = DRIVER_BASE_URL + "/binding/fleet/delete";
const DRIVER_SUMMARY_URL = DRIVER_BASE_URL + "/summary/driver";
const DRIVER_ADD_URL = DRIVER_BASE_URL + "/input/driver/v2";
const DRIVER_EDIT_URL = DRIVER_BASE_URL + "/edit/driver";
const DRIVER_EDIT_URL_V2 = DRIVER_BASE_URL + "/edit/driver/v2";

const DIVISION_LIST_URL = BASE_URL + "/track/division/list";
const DIVISION_ADD_URL = BASE_URL + "/track/division/input";
const DIVISION_EDIT_URL = BASE_URL + "/track/division/edit";
const DIVISION_DELETE_URL = BASE_URL + "/track/division/delete";

const CHECK_SHEET_DO_LIST_URL = BASE_URL + "/check-sheet/do/list";
const CHECK_SHEET_LIST_URL = BASE_URL + "/check-sheet/list";
const CHECK_SHEET_ADD_URL = BASE_URL + "/check-sheet/input";
const CHECK_SHEET_EDIT_URL = BASE_URL + "/check-sheet/edit";
const CHECK_SHEET_DELETE_URL = BASE_URL + "/check-sheet/delete";

const CHECK_SHEET_DETAIL_LIST_URL = BASE_URL + "/check-sheet/detail/list";
const CHECK_SHEET_DETAIL_ADD_URL = BASE_URL + "/check-sheet/detail/input";
const CHECK_SHEET_DETAIL_EDIT_URL = BASE_URL + "/check-sheet/detail/edit";
const CHECK_SHEET_DETAIL_DELETE_URL = BASE_URL + "/check-sheet/detail/delete";

const CHECK_SHEET_APPROVAL_TYPE_LIST_URL =
  BASE_URL + "/check-sheet/approval/type/list";
const CHECK_SHEET_APPROVAL_TYPE_ADD_URL =
  BASE_URL + "/check-sheet/approval/type/input";
const CHECK_SHEET_APPROVAL_TYPE_EDIT_URL =
  BASE_URL + "/check-sheet/approval/type/edit";
const CHECK_SHEET_APPROVAL_TYPE_DELETE_URL =
  BASE_URL + "/check-sheet/approval/type/delete";

const CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST_URL =
  BASE_URL + "/check-sheet/approval/type/setting/list";
const CHECK_SHEET_APPROVAL_TYPE_SETTING_ADD_URL =
  BASE_URL + "/check-sheet/approval/type/setting/input";
const CHECK_SHEET_APPROVAL_TYPE_SETTING_EDIT_URL =
  BASE_URL + "/check-sheet/approval/type/setting/edit";
const CHECK_SHEET_APPROVAL_TYPE_SETTING_DELETE_URL =
  BASE_URL + "/check-sheet/approval/type/setting/delete";

const CHECK_SHEET_APPROVAL_ADD_URL = BASE_URL + "/check-sheet/approval/input";
const CHECK_SHEET_APPROVAL_DELETE_URL =
  BASE_URL + "/check-sheet/approval/delete";

const CHECK_SHEET_BINDING_DO_ADD_URL = BASE_URL + "/check-sheet/do/input";
const CHECK_SHEET_BINDING_DO_EDIT_URL = BASE_URL + "/check-sheet/do/edit";
const CHECK_SHEET_BINDING_DO_DELETE_URL = BASE_URL + "/check-sheet/do/delete";
const CHECK_SHEET_BINDING_DO_LIST_URL = BASE_URL + "/check-sheet/do/list";
const CHECK_SHEET_DO_DETAIL_INPUT = BASE_URL + "/check-sheet/do/detail/input";
const CHECK_SHEET_DO_DETAIL_LIST = BASE_URL + "/check-sheet/do/detail/list";
const CHECK_SHEET_DO_APPROVAL_LIST = BASE_URL + "/check-sheet/approval/list";
const CHECK_SHEET_DO_APPROVAL_INPUT = BASE_URL + "/check-sheet/approval/input";

const DRIVER_ASSISTANT_LIST_URL = DRIVER_BASE_URL + "/list/assistant";
const DRIVER_ASSISTANT_ADD_URL = DRIVER_BASE_URL + "/input/assistant";
const DRIVER_ASSISTANT_EDIT_URL = DRIVER_BASE_URL + "/edit/assistant";
const SECURITY_LIST_URL = DRIVER_BASE_URL + "/list/security";
const SECURITY_INPUT_URL = DRIVER_BASE_URL + "/input/security";

const DRIVERAPPUSER_LIST_URL = DRIVER_BASE_URL + "/app-user/list";
const DRIVERAPPUSER_ADD_URL = DRIVER_BASE_URL + "/app-user/input";
const DRIVERAPPUSER_EDIT_URL = DRIVER_BASE_URL + "/app-user/edit";
const DRIVERAPPUSER_DELETE_URL = DRIVER_BASE_URL + "/app-user/delete";

const MECHANIC_FLEET_SCAN_INPUT = BASE_URL + "/scan/fleet/mechanic/input";
const MECHANIC_FLEET_SCAN_EDIT = BASE_URL + "/scan/fleet/mechanic/edit";
const MECHANIC_FLEET_SCAN_LIST = BASE_URL + "/scan/fleet/mechanic/list";
const MECHANIC_FLEET_SCAN_DELETE = BASE_URL + "/scan/fleet/mechanic/delete";
const MECHANIC_BASE_URL = BASE_URL + "/mechanic";
const MECHANIC_LIST_URL = MECHANIC_BASE_URL + "/list";
const MECHANIC_ADD = MECHANIC_BASE_URL + "/input";
const MECHANIC_EDIT = MECHANIC_BASE_URL + "/edit";
const MECHANIC_DELETE = MECHANIC_BASE_URL + "/delete";
const MECHANIC_CERTIFICATION_LIST_URL =
  MECHANIC_BASE_URL + "/certification/list";
const MECHANIC_SPECIALIZATION_LIST_URL =
  MECHANIC_BASE_URL + "/specialization/list";
const MECHANIC_OPERATIONALHOUR_LIST_URL =
  MECHANIC_BASE_URL + "/operational-hour/list";

const WORKSHOP_BASE_URL = BASE_URL + "/workshop";
const WORKSHOP_LIST_URL = WORKSHOP_BASE_URL + "/list";
const WORKSHOP_ADD_URL = WORKSHOP_BASE_URL + "/input";
const WORKSHOP_EDIT_URL = WORKSHOP_BASE_URL + "/edit";
const WORKSHOP_DELETE_URL = WORKSHOP_BASE_URL + "/delete";

const WORKSHOP_OH_BASE_URL = WORKSHOP_BASE_URL + "/operational-hour";
const WORKSHOP_OH_LIST_URL = WORKSHOP_OH_BASE_URL + "/list";
const WORKSHOP_OH_ADD_URL = WORKSHOP_OH_BASE_URL + "/input";
const WORKSHOP_OH_EDIT_URL = WORKSHOP_OH_BASE_URL + "/edit";
const WORKSHOP_OH_DELETE_URL = WORKSHOP_OH_BASE_URL + "/delete";

const WORKSHOP_PIC_POS_LIST_URL = BASE_URL + "/workshop/pic-position/list";
const WORKSHOP_PIC_POS_ADD_URL = BASE_URL + "/workshop/pic-position/input";
const WORKSHOP_PIC_POS_EDIT_URL = BASE_URL + "/workshop/pic-position/edit";
const WORKSHOP_PIC_POS_DELETE_URL = BASE_URL + "/workshop/pic-position/delete";

const WORKSHOP_PIC_LIST_URL = BASE_URL + "/workshop/pic/list";
const WORKSHOP_PIC_ADD_URL = BASE_URL + "/workshop/pic/input";
const WORKSHOP_PIC_EDIT_URL = BASE_URL + "/workshop/pic/edit";
const WORKSHOP_PIC_DELETE_URL = BASE_URL + "/workshop/pic/delete";

const WORKSHOP_OPERATIONAL_HOUR_LIST_URL =
  BASE_URL + "/workshop/operational-hour/list";
const WORKSHOP_OPERATIONAL_HOUR_ADD_URL =
  BASE_URL + "/workshop/operational-hour/input";
const WORKSHOP_OPERATIONAL_HOUR_EDIT_URL =
  BASE_URL + "/workshop/operational-hour/edit";
const WORKSHOP_OPERATIONAL_HOUR_DELETE_URL =
  BASE_URL + "/workshop/operational-hour/delete";

const WORKSHOP_PAYMENT_TERM_TYPE_LIST_URL =
  BASE_URL + "/workshop/payment-term/type/list";
const WORKSHOP_PAYMENT_TERM_TYPE_ADD_URL =
  BASE_URL + "/workshop/payment-term/type/input";
const WORKSHOP_PAYMENT_TERM_TYPE_EDIT_URL =
  BASE_URL + "/workshop/payment-term/type/edit";
const WORKSHOP_PAYMENT_TERM_TYPE_DELETE_URL =
  BASE_URL + "/workshop/payment-term/type/delete";

const FLEET_URL = BASE_URL + "/fleet";
const FLEET_LIST_URL = FLEET_URL + "/list";
const FLEET_INPUT_URL = FLEET_URL + "/input";
const FLEET_EDIT_URL = FLEET_URL + "/edit";
const FLEET_LIST_IMAGE_URL = FLEET_URL + "/list/image";
const FLEET_INPUT_IMAGE_URL = FLEET_URL + "/input/image";
const FLEET_MAINTENANCE_BINDING_LIST_URL =
  FLEET_URL + "/maintenance-settings/binding/list";
const FLEET_MAINTENANCE_SETTING_INPUT_URL =
  FLEET_URL + "/maintenance-settings/input";
const FLEET_MAINTENANCE_SETTING_EDIT_URL =
  FLEET_URL + "/maintenance-settings/binding/edit";
const FLEET_MAINTENANCE_SETTING_BINDING_URL =
  FLEET_URL + "/maintenance-settings/binding";
const FLEET_MAINTENANCE_REMINDER_URL =
  BASE_URL + "/dashboard/transporter/reminder/maintenance";
const FLEET_MAINTENANCE_REMINDER_LIST_URL =
  BASE_URL + "/dashboard/transporter/reminder/maintenance/list";

const SAVED_PLACES_URL = BASE_URL + "/transporter/place";
const SAVED_PLACES_ADD_URL = SAVED_PLACES_URL + "/input";
const SAVED_PLACES_LIST_URL = SAVED_PLACES_URL + "/list";
const SAVED_PLACES_APPROVAL_URL = SAVED_PLACES_URL + "/approve";
const SAVED_PLACES_EDIT_URL = SAVED_PLACES_URL + "/edit";
const SAVED_PLACES_MESH_LIST = SAVED_PLACES_URL + "/list/mesh/report";
const SAVED_PLACES_NEAR_LIST = SAVED_PLACES_URL + "/list/near";
const SAVED_PLACES_GET_ADDRESS = SAVED_PLACES_URL + "/address";
const SAVED_PLACES_UPLOAD_IMAGE = SAVED_PLACES_URL + "/input/image";
const SAVED_PLACES_DELETE_IMAGE = SAVED_PLACES_URL + "/delete/image";

const MAIN_CUSTOMER_URL = BASE_URL + "/transporter/customer";
const MAIN_CUSTOMER_LIST_URL = MAIN_CUSTOMER_URL + "/list";
const MAIN_CUSTOMER_ADD_URL = MAIN_CUSTOMER_URL + "/add";
const MAIN_CUSTOMER_DELETE_URL = MAIN_CUSTOMER_URL + "/delete";
const MAIN_CUSTOMER_EDIT_URL = MAIN_CUSTOMER_URL + "/edit";
const MAIN_CUSTOMER_VERIFY_URL = MAIN_CUSTOMER_URL + "/verify";

const OBD_URL = FLEET_URL + "/obd";
const OBD_TYPE_URL = OBD_URL + "/type";
const OBD_TYPE_LIST_URL = OBD_TYPE_URL + "/list";
const OBD_BINDING_URL = OBD_URL + "/binding";
const OBD_UNBINDING_URL = OBD_URL + "/unbinding";
const OBD_HISTORY_LIST_URL = OBD_URL + "/history/list";
const OBD_HISTORY_LIST_CHECK_URL = OBD_URL + "/history/check";
const OBD_IMAGE_LOGGING = BASE_URL + "/transporter/obd/image/logging";

const GENERAL_QR_GENERATE = BASE_URL + "/qr/general/generate";

const DELIVERY_BASE_URL = BASE_URL + "/delivery";
const DELIVERY_APPROVE_URL = DELIVERY_BASE_URL + "/approve";
const DELIVERY_ORDERS_ADD_URL = DELIVERY_BASE_URL + "/input/order";
const DELIVERY_ORDERS_LIST_URL = DELIVERY_BASE_URL + "/list";
const DELIVERY_ORDERS_EDIT_URL = DELIVERY_BASE_URL + "/edit/order";
const DELIVERY_ORDERS_COPY_URL = DELIVERY_BASE_URL + "/work/copy/input";
const WORK_ORDERS_ADD_URL = DELIVERY_BASE_URL + "/work/input/order";
const WORK_ORDERS_DRAFT_ADD_URL = DELIVERY_BASE_URL + "/work/draft/order";
const WORK_ORDERS_DRAFT_CHECK_URL = DELIVERY_BASE_URL + "/work/draft/check";
const WORK_ORDERS_DELETE_URL = DELIVERY_BASE_URL + "/work/draft/delete";
const WORK_ORDERS_EDIT_URL = DELIVERY_BASE_URL + "/work/edit/order";
const WORK_ORDERS_LIST_URL = DELIVERY_BASE_URL + "/work/list/v2";
const WORK_ORDERS_DRAFT_CONFIRM_URL = DELIVERY_BASE_URL + "/work/draft/confrim";
const WORK_ORDERS_DRAFT_EDIT_URL = DELIVERY_BASE_URL + "/work/draft/edit";
const WORK_ORDERS_DRAFT_TRIPL_ADD_URL =
  DELIVERY_BASE_URL + "/work/draft/order/request";
const DELIVERY_ORDER_ROUTE_SENT_PATH_DRIVER_URL =
  DELIVERY_BASE_URL + "/work/list/path/send";
const DELIVERY_STATUS_LIST_URL = DELIVERY_BASE_URL + "/status/list";
const WORK_ORDER_REVIEW_URL = DELIVERY_BASE_URL + "/work/list/status";
const WORK_ORDER_SECURITY_APPROVAL_STATUS_RESET_URL =
  DELIVERY_BASE_URL + "/work/reset/approval";
const STOCKER_WORK_ORDER_INPUT_URL =
  DELIVERY_BASE_URL + "/work/draft/order/stocker"; //For Outbound
const STOCKER_WORK_ORDER_INBOUND_URL =
  DELIVERY_BASE_URL + "/work/draft/order/stocker/inbound";
const WORK_ORDER_UPLOADED_IMAGE_LIST_URL =
  DELIVERY_BASE_URL + "/work/list/v2/image";
const WORK_ORDER_DELETE_IMAGE_URL =
  DELIVERY_BASE_URL + "/work/list/v2/image/delete";
const WORK_ORDER_UPLOADED_IMAGE_COMPARE_LIST_URL =
  DELIVERY_BASE_URL + "/work/list/v2/image/compare";
const WORK_ORDER_UPLOADED_IMAGE_COMPARE_CONFIRMATION_URL =
  DELIVERY_BASE_URL + "/work/list/v2/image/confirmation";
const WORK_ORDER_UPLOAD_IMAGE_URL = DELIVERY_BASE_URL + "/work/input/v2/image";
const WORK_ORDER_UPLOADED_IMAGE_DELETE_URL =
  DELIVERY_BASE_URL + "/work/list/v2/image/delete";
const WORK_ORDER_STOCKER = DELIVERY_BASE_URL + "/work/list/all";
const WORK_ORDER_STOCKER_OUTBOUND_ITEM =
  DELIVERY_BASE_URL + "/work/list/outbound";
const WORK_ORDER_STOCKER_INBOUND_ITEM =
  DELIVERY_BASE_URL + "/work/list/inbound";
const WORK_ORDER_QR_PRINT_URL =
  DELIVERY_BASE_URL + "/work/list/v2/generate/token";
const WORK_ORDER_POD_VERIF_LOG =
  DELIVERY_BASE_URL + "/work/list/v2/log/history/verification";

const DELIVERY_ORDER_CATEGORY = DELIVERY_BASE_URL + "/category";
const DELIVERY_ORDER_CATEGORY_LIST = DELIVERY_ORDER_CATEGORY + "/list";
const DELIVERY_ORDER_CATEGORY_INPUT = DELIVERY_ORDER_CATEGORY + "/input";
const DELIVERY_ORDER_CATEGORY_EDIT = DELIVERY_ORDER_CATEGORY + "/edit";
const DELIVERY_ORDER_CATEGORY_DELETE = DELIVERY_ORDER_CATEGORY + "/delete";

const DELIVERY_ORDER_SUBCATEGORY = DELIVERY_BASE_URL + "/subcategory";
const DELIVERY_ORDER_SUBCATEGORY_LIST = DELIVERY_ORDER_SUBCATEGORY + "/list";
const DELIVERY_ORDER_SUBCATEGORY_INPUT = DELIVERY_ORDER_SUBCATEGORY + "/input";
const DELIVERY_ORDER_SUBCATEGORY_EDIT = DELIVERY_ORDER_SUBCATEGORY + "/edit";
const DELIVERY_ORDER_SUBCATEGORY_DELETE =
  DELIVERY_ORDER_SUBCATEGORY + "/delete";

const DELIVERY_ORDER_BIND_CATEGORY =
  DELIVERY_BASE_URL + "/order/category/input";
const DELIVERY_ORDER_UNBIND_CATEGORY =
  DELIVERY_BASE_URL + "/order/category/delete";

// 3PL related
const TRIPL_WO_REQ_LIST_URL = DELIVERY_BASE_URL + "/work/request/list";
const TRIPL_WO_REQ_BID_URL = DELIVERY_BASE_URL + "/work/request/bid";
const TRIPL_WO_REQ_INCOMING_BID_LIST_URL =
  DELIVERY_BASE_URL + "/work/request/incoming/bid/list";
const TRIPL_WO_REQ_APPROVED_REQUEST_LIST_URL =
  DELIVERY_BASE_URL + "/work/request/approve/list";
const TRIPL_WO_REQ_LIST_RESULT_URL =
  DELIVERY_BASE_URL + "/work/request/list/all";
const TRIPL_WO_REQ_ADD_URL = DELIVERY_BASE_URL + "/work/request/input/order";
const TRIPL_WO_APPROVE_BID_URL =
  DELIVERY_BASE_URL + "/work/request/list/bid/approve";
const TRIPL_WO_DECLINE_BID_URL =
  DELIVERY_BASE_URL + "/work/request/list/bid/decline";
const TRIPL_WO_BINDING_WO_DO_URL =
  DELIVERY_BASE_URL + "/work/input/order/request";
const TRIPL_WO_BID_APPROVE_CHECK_PAYMENT_URL =
  DELIVERY_BASE_URL + "/work/request/list/bid/approve/payment/check";
const TRIPL_WO_BID_APPROVE_GENERATE_INVOICE_URL =
  DELIVERY_BASE_URL + "/work/request/list/bid/approve/payment";
const TRIPL_WO_BID_APPROVE_GENERATE_INVOICE_URL_V2 =
  DELIVERY_BASE_URL + "/work/request/list/bid/approve/payment/v2";
const TRIPL_CHECK_EST_COST_URL = DELIVERY_BASE_URL + "/check/cost";
const TRIPL_WO_BID_HISTORY_URL =
  DELIVERY_BASE_URL + "/work/request/list/bid/history";
const TRIPL_BID_WINNER_TRACKER_URL =
  DELIVERY_BASE_URL + "/work/request/history/list";
const TRIPL_HISTORY_LIST_PATH_URL =
  DELIVERY_BASE_URL + "/work/request/history/list/path";
const TRIPL_BID_STATUS_TO_READY_TO_REVIEW_URL =
  DELIVERY_BASE_URL + "/work/request/list/bid/review/status";

// 3PL Group
const TRIPL_GROUP_LIST_URL = BASE_URL + "/transporter/3pl/group/list";
const TRIPL_GROUP_ADD_URL = BASE_URL + "/transporter/3pl/group/add";
const TRIPL_GROUP_EDIT_URL = BASE_URL + "/transporter/3pl/group/edit";
const TRIPL_GROUP_DELETE_URL = BASE_URL + "/transporter/3pl/group/delete";
const TRIPL_GROUP_ASSIGN_CREATE_FIND_URL =
  BASE_URL + "/transporter/3pl/group/create/find";
const TRIPL_GROUP_ASSIGN_URL = BASE_URL + "/transporter/3pl/group/assign";
const TRIPL_GROUP_ASSIGN_FIND_URL =
  BASE_URL + "/transporter/3pl/group/join/find";
const TRIPL_GROUP_APPROVE_URL = BASE_URL + "/transporter/3pl/group/approve";
const TRIPL_GROUP_DECLINE_URL = BASE_URL + "/transporter/3pl/group/decline";
const TRIPL_GROUP_MEMBER_VIEW_URL =
  BASE_URL + "/transporter/3pl/group/member/find";
const TRIPL_GROUP_FLEET_CHECK_URL =
  BASE_URL + "/transporter/3pl/group/fleet/check";
const TRIPL_LIVE_MONITORING_URL = BASE_URL + "/transporter/report/3pl/live/v2";

const CAR_URL = BASE_URL + "/car";
const CAR_LIST_BRAND_URL = CAR_URL + "/list/brand";
const CAR_LIST_TYPE_URL = CAR_URL + "/list/type";
const CAR_LIST_GENERAL_URL = CAR_URL + "/list/general";
const CAR_LIST_FUEL_TYPE_URL = CAR_URL + "/list/fuel";

const TRANSPORTER_URL = BASE_URL + "/transporter";
const TRANSPORTER_DELETE_URL = TRANSPORTER_URL + "/delete";
const REPORT_BASE_URL = TRANSPORTER_URL + "/report";
const DELIVERY_AUDIT_URL = REPORT_BASE_URL + "/audit";
const DELIVERY_AUDIT_URL_FAMILY = REPORT_BASE_URL + "/audit/family";
const CAR_DETAILS_TABLE_URL = REPORT_BASE_URL + "/log";
const DELIVERY_CHECK_IN_LOG_URL = REPORT_BASE_URL + "/check/in/log";
const FLEET_REPORT_URL = REPORT_BASE_URL + "/beetwen";
const FLEET_FUEL_REPORT_URL = REPORT_BASE_URL + "/fuel";
const REPORT_DAILY_SUM = REPORT_BASE_URL + "/daily";

const UNPLANNED_STOP_LOCK = DELIVERY_BASE_URL + "/lock/unplanned/stop";
const UNPLANNED_STOP_LOCK_LIST = DELIVERY_BASE_URL + "/list/unplanned/stop";
const UNPLANNED_STOP_LOCK_TAGGING = DELIVERY_BASE_URL + "/edit/unplanned/stop";
const UNPLANNED_STOP_LOCK_FINISH =
  DELIVERY_BASE_URL + "/finish/tag/unplanned/stop";

const LIVE_MONITORING_URL = REPORT_BASE_URL + "/live";
const LIVE_MONITORING_FOR_EST_COST_URL = REPORT_BASE_URL + "/fleet/live";
const LIVE_MONITORING_SHARE_TOKEN = REPORT_BASE_URL + "/live/share/generate";
const GOOGLE_GEOCODING = BASE_URL + "/transporter/place/address";
const GOOGLE_GEOCODING_v2 = BASE_URL + "/transporter/place/address/v2";
const LIVE_MONITORING_DASHBOARD = LIVE_MONITORING_URL + "/dashboard";
const LIVE_MONITORING_BEETWEN = REPORT_BASE_URL + "/beetwen";

const SETTING_CHANGE_URL = USER_URL + "/settingchange";
const SETTING_LIST_URL = USER_URL + "/settinglist";

const COMPANY_SETTING_LIST_URL = USER_URL + "/company/list";
const COMPANY_VA_LIST_URL = USER_URL + "/company/list/va";
const COMPANY_EDIT_URL = USER_URL + "/company/edit";
const COMPANY_LIST_ALL_URL = BASE_URL + "/user/company/list/user";
const SERVICE_PLAN_LIST_URL = USER_URL + "/company/plan/list";
const USER_COMPANY_BANK_LIST = USER_URL + "/company/bank/list";
const USER_COMPANY_GENERATE_VA = USER_URL + "/company/generate/va";
const USER_TRANSACTION_HISTORY_LIST = USER_URL + "/transactional/fva/list";

const MAINTENANCE_BASE_URL = FLEET_URL + "/service";
const FLEET_MAINTENANCE_LIST_URL = MAINTENANCE_BASE_URL + "/list";
const FLEET_MAINTENANCE_INPUT_URL = MAINTENANCE_BASE_URL + "/input";
const FLEET_MAINTENANCE_EDIT_URL = MAINTENANCE_BASE_URL + "/edit";
const FLEET_MAINTENANCE_DELETE_URL = MAINTENANCE_BASE_URL + "/delete";

const SCHEDULED_MAINTENANCE_BASE_URL = BASE_URL + "/scheduled-maintenance";
const SCHEDULED_MAINTENANCE_LIST_URL = SCHEDULED_MAINTENANCE_BASE_URL + "/list";
const SCHEDULED_MAINTENANCE_ADD_URL = SCHEDULED_MAINTENANCE_BASE_URL + "/input";
const SCHEDULED_MAINTENANCE_EDIT_URL = SCHEDULED_MAINTENANCE_BASE_URL + "/edit";
const SCHEDULED_MAINTENANCE_DELETE_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/delete";
const SCHEDULED_MAINTENANCE_WORKHSOP_SIMPLE_APPROVAL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/workshop/approval";
const SM_CLIENT_APPROVAL_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/client/approval";
const SM_CLIENT_FAKE_PAYMENT_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/client/approval/pay";

const SCHEDULED_MAINTENANCE_TASK_DETAIL_LIST_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/mechanic/task/detail/list";
const SCHEDULED_MAINTENANCE_TASK_DETAIL_ADD_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/mechanic/task/detail/input";
const SCHEDULED_MAINTENANCE_TASK_DETAIL_EDIT_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/mechanic/task/detail/edit";
const SCHEDULED_MAINTENANCE_TASK_DETAIL_DELETE_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/mechanic/task/detail/delete";
const SCHEDULED_MAINTENANCE_TASK_DETAIL_APPROVAL = "";

const SCHEDULED_MAINTENANCE_TASK_PROOF =
  SCHEDULED_MAINTENANCE_BASE_URL + "/mechanic/task/proof/list";

const SCHEDULED_MAINTENANCE_WORKSHOP_LIST_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/workshop/list";

const SM_APPROVAL_TYPE_BASE_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/approval/type";
const SM_APPROVAL_TYPE_LIST_URL = SM_APPROVAL_TYPE_BASE_URL + "/list";
const SM_APPROVAL_TYPE_ADD_URL = SM_APPROVAL_TYPE_BASE_URL + "/input";
const SM_APPROVAL_TYPE_EDIT_URL = SM_APPROVAL_TYPE_BASE_URL + "/edit";
const SM_APPROVAL_TYPE_DELETE_URL = SM_APPROVAL_TYPE_BASE_URL + "/delete";

const SM_APPROVAL_SETTING_BASE_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/approval/type/setting";
const SM_APPROVAL_SETTING_LIST_URL = SM_APPROVAL_SETTING_BASE_URL + "/list";
const SM_APPROVAL_SETTING_ADD_URL = SM_APPROVAL_SETTING_BASE_URL + "/input";
const SM_APPROVAL_SETTING_EDIT_URL = SM_APPROVAL_SETTING_BASE_URL + "/edit";
const SM_APPROVAL_SETTING_DELETE_URL = SM_APPROVAL_SETTING_BASE_URL + "/delete";

const SM_APPROVAL_BASE_URL =
  SCHEDULED_MAINTENANCE_BASE_URL + "/approval/request";
const SM_APPROVAL_LIST_URL = SM_APPROVAL_BASE_URL + "/list";
const SM_APPROVAL_ADD_URL = SM_APPROVAL_BASE_URL + "/input";
const SM_APPROVAL_EDIT_URL = SM_APPROVAL_BASE_URL + "/edit";
const SM_APPROVAL_DELETE_URL = SM_APPROVAL_BASE_URL + "/delete";

const SM_MECHANIC_BASE_URL = SCHEDULED_MAINTENANCE_BASE_URL + "/mechanic";
const SM_MECHANIC_LIST_URL = SM_MECHANIC_BASE_URL + "/list";
const SM_MECHANIC_ADD_URL = SM_MECHANIC_BASE_URL + "/input";
const SM_MECHANIC_EDIT_URL = SM_MECHANIC_BASE_URL + "/edit";
const SM_MECHANIC_DELETE_URL = SM_MECHANIC_BASE_URL + "/delete";

const SM_MECHANIC_TASK_BASE_URL = SM_MECHANIC_BASE_URL + "/task";
const SM_MECHANIC_TASK_LIST_URL = SM_MECHANIC_TASK_BASE_URL + "/list";
const SM_MECHANIC_TASK_ADD_URL = SM_MECHANIC_TASK_BASE_URL + "/input";
const SM_MECHANIC_TASK_EDIT_URL = SM_MECHANIC_TASK_BASE_URL + "/edit";
const SM_MECHANIC_TASK_DELETE_URL = SM_MECHANIC_TASK_BASE_URL + "/delete";

// Fuel Management
const FUEL_URL = FLEET_URL + "/fuel";
const FUEL_MANAGEMENT_LIST = FUEL_URL + "/track/list";
const FUEL_MANAGEMENT_ADD = FUEL_URL + "/track/add";
const FUEL_MANAGEMENT_APPROVAL = FUEL_URL + "/track/approval";
const FUEL_MANAGEMENT_DELETE_URL = FUEL_URL + "/track/delete";

const DASHBOARD_URL = BASE_URL + "/dashboard";
const TRANSPORTER_DASHBOARD_URL = DASHBOARD_URL + "/transporter";
const DASHBOARD_TRANSPORTER_COUNT_URL = TRANSPORTER_DASHBOARD_URL + "/count";
const TRANSPORTER_FLEET_NOTIFICATION_DASHBOARD_URL =
  TRANSPORTER_DASHBOARD_URL + "/reminder/fleet";
const TRANSPORTER_SIM_NOTIFICATION_DASHBOARD_URL =
  TRANSPORTER_DASHBOARD_URL + "/reminder/sim";
const TRANSPORTER_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD_URL =
  TRANSPORTER_DASHBOARD_URL + "/reminder/service";

const UPLOAD_IMAGE_FOR_EDIT = BASE_URL + "/iot/logging/upload";

// Covid Test
const DRIVER_COVID_TEST_INPUT_URL = BASE_URL + "/driver/driver/health/put";
const DRIVER_COVID_TEST_LIST_URL = BASE_URL + "/driver/driver/health/list";
const DRIVER_VACCINE_INPUT_URL = BASE_URL + "/driver/driver/vaccine/put";
const DRIVER_VACCINE_LIST_URL = BASE_URL + "/driver/driver/vaccine/list";
const DRIVER_ASST_COVID_TEST_INPUT_URL = BASE_URL + "/driver/asst/health/put";
const DRIVER_ASST_COVID_TEST_LIST_URL = BASE_URL + "/driver/asst/health/list";
const DRIVER_ASST_VACCINE_LIST_URL = BASE_URL + "/driver/asst/vaccine/list";
const DRIVER_ASST_VACCIBE_INPUT_URL = BASE_URL + "/driver/asst/vaccine/put";

//RENTEE
const RENTEE_CREATE_REQUEST = BASE_URL + "/transporter/rente/fleet/request";
const RENTEE_REQUEST_LIST = BASE_URL + "/transporter/rente/fleet/request/list";
const RENTEE_REQUEST_OWNER_LIST =
  BASE_URL + "/transporter/rente/fleet/request/list/owner";
const RENTEE_REQUEST_FLEET_OWNER_LIST =
  BASE_URL + "/transporter/rente/fleet/request/list/owner/detail";
const RENTEE_FLEET_REQUEST_LIST = BASE_URL + "/transporter/rente/fleet/list";
const RENTEE_APPROVE_AND_CREATE_CONTRACT =
  BASE_URL + "/transporter/rente/fleet/request/approve";
const RENTEE_CONTRACT_LIST =
  BASE_URL + "/transporter/rente/fleet/request/contract/list";
const RENTEE_RENTER_CONTRACT_APPROVAL =
  BASE_URL + "/transporter/rente/fleet/request/rente/approve";
const RENTEE_FLEET_LIVE_MONITORING =
  BASE_URL + "/transporter/report/live/rente";
const RENTEE_DO_INPUT = BASE_URL + "/delivery/input/order/rente";
const REQUESTED_FLEET_DECLINE =
  BASE_URL + "/transporter/rente/fleet/request/update/decline";
const OWNER_ADD_REQUESTED_FLEET =
  BASE_URL + "/transporter/rente/fleet/request/update/add";

// Bulk CSV Import
const SAVED_PLACES_IMPORT_URL = SAVED_PLACES_ADD_URL + "/bulk";
const MAIN_CUSTOMER_IMPORT_URL = MAIN_CUSTOMER_ADD_URL + "/bulk";
const SAVED_PLACES_IMPORT_VALIDATE_URL =
  SAVED_PLACES_ADD_URL + "/bulk/validate";
const MAIN_CUSTOMER_IMPORT_VALIDATE_URL =
  MAIN_CUSTOMER_ADD_URL + "/bulk/validate";

const GEOCODE_VALIDATE_URL =
  "https://api.automa.id/api/geoplace/code/input/bulk/validate";
const GEOCODE_INPUT_BULK_URL =
  "https://api.automa.id/api/geoplace/code/input/bulk";

const PUBLIC_FACILITY_LIST_URL = BASE_URL + "/transporter/public/place/list";
const PUBLIC_FACILITY_ADD_URL = BASE_URL + "/transporter/public/place/input";
const PUBLIC_FACILITY_DELETE_URL =
  BASE_URL + "/transporter/public/place/delete";
const PUBLIC_FACILITY_APPROVAL_URL =
  BASE_URL + "/transporter/public/place/approve";

const PUBLIC_FACILITY_TYPE_LIST_URL =
  BASE_URL + "/transporter/public/place/type/list";

const MAINTENANCE_CATEGORY_LIST =
  BASE_URL + "/curative-maintenance/catagory/list";
const MAINTENANCE_SUB_CATEGORY_LIST =
  BASE_URL + "/curative-maintenance/sub/catagory/list";
const MAINTENANCE_CATEGORY_ADD = "";
const MAINTENANCE_CATEGORY_EDIT = "";
const MAINTENANCE_CATEGORY_DELETE = "";
const MAINTENANCE_SUB_CATEGORY_ADD = "";
const MAINTENANCE_SUB_CATEGORY_EDIT = "";
const MAINTENANCE_SUB_CATEGORY_DELETE = "";

const PREVENTIVE_MAINTENANCE_LIST =
  BASE_URL + "/preventive-maintenance/setting/list";
const PREVENTIVE_MAINTENANCE_ADD =
  BASE_URL + "/preventive-maintenance/setting/input";
const PREVENTIVE_MAINTENANCE_EDIT =
  BASE_URL + "/preventive-maintenance/setting/edit";
const PREVENTIVE_MAINTENANCE_FLEET_ADD =
  BASE_URL + "/preventive-maintenance/setting/fleet/input";
const PREVENTIVE_MAINTENANCE_FLEET_EDIT =
  BASE_URL + "/preventive-maintenance/setting/fleet/edit";
const PREVENTIVE_MAINTENANCE_FLEET_LIST =
  BASE_URL + "/preventive-maintenance/setting/fleet/list";
const PREVENTIVE_MAINTENANCE_DELETE =
  BASE_URL + "/preventive-maintenance/setting/delete";

const PREVENTIVE_MAINTENANCE_TASK_LIST =
  BASE_URL + "/preventive-maintenance/task/list";
const PREVENTIVE_MAINTENANCE_TASK_ADD =
  BASE_URL + "/preventive-maintenance/task/input";
const PREVENTIVE_MAINTENANCE_TASK_EDIT =
  BASE_URL + "/preventive-maintenance/task/edit";
const PREVENTIVE_MAINTENANCE_SUB_TASK_ADD =
  BASE_URL + "/preventive-maintenance/sub-category/input";
const PREVENTIVE_MAINTENANCE_SUB_TASK_EDIT =
  BASE_URL + "/preventive-maintenance/sub-category/edit";
const PREVENTIVE_MAINTENANCE_SUB_TASK_LIST =
  BASE_URL + "/preventive-maintenance/sub-category/list";
const PREVENTIVE_MAINTENANCE_TASK_DELETE =
  BASE_URL + "/preventive-maintenance/task/delete";

const PACKAGE_MAINTENANCE_LIST = BASE_URL + "/maintenance-package/list";
const PACKAGE_MAINTENANCE_ADD = BASE_URL + "/maintenance-package/input";
const PACKAGE_MAINTENANCE_EDIT = BASE_URL + "/maintenance-package/edit";
const PACKAGE_MAINTENANCE_DELETE = BASE_URL + "/maintenance-package/delete";

const PACKAGE_MAINTENANCE_TASK_LIST =
  BASE_URL + "/maintenance-package/task/list";
const PACKAGE_MAINTENANCE_TASK_ADD =
  BASE_URL + "/maintenance-package/task/input";
const PACKAGE_MAINTENANCE_TASK_EDIT =
  BASE_URL + "/maintenance-package/task/edit";
const PACKAGE_MAINTENANCE_TASK_SUB_ADD =
  BASE_URL + "/maintenance-package/sub-category/input";
const PACKAGE_MAINTENANCE_TASK_SUB_EDIT =
  BASE_URL + "/maintenance-package/sub-category/edit";
const PACKAGE_MAINTENANCE_TASK_DELETE =
  BASE_URL + "/maintenance-package/task/delete";

const CURRATIVE_LIST = BASE_URL + "/curative-maintenance/list";
const CURRATIVE_IMAGE_LIST = BASE_URL + "/curative-maintenance/list/image";

const SPAREPARTS_BASE = BASE_URL + "/spare-part";
const SPAREPARTS_LIST = SPAREPARTS_BASE + "/list";
const SPAREPARTS_INPUT = SPAREPARTS_BASE + "/input";
const SPAREPARTS_EDIT = SPAREPARTS_BASE + "/edit";
const SPAREPARTS_DELETE = SPAREPARTS_BASE + "/delete";

const SPAREPARTS_DETAIL_BASE = SPAREPARTS_BASE + "/detail";
const SPAREPARTS_DETAIL_LIST = SPAREPARTS_DETAIL_BASE + "/list";
const SPAREPARTS_DETAIL_INPUT = SPAREPARTS_DETAIL_BASE + "/input";
const SPAREPARTS_DETAIL_EDIT = SPAREPARTS_DETAIL_BASE + "/edit";
const SPAREPARTS_DETAIL_DELETE = SPAREPARTS_DETAIL_BASE + "/delete";

const SPAREPARTS_BRAND_BASE = SPAREPARTS_BASE + "/maker";
const SPAREPARTS_BRAND_LIST = SPAREPARTS_BRAND_BASE + "/list";
const SPAREPARTS_BRAND_INPUT = SPAREPARTS_BRAND_BASE + "/input";
const SPAREPARTS_BRAND_EDIT = SPAREPARTS_BRAND_BASE + "/edit";
const SPAREPARTS_BRAND_DELETE = SPAREPARTS_BRAND_BASE + "/delete";

const SPAREPARTS_CATEGORY_BASE = SPAREPARTS_BASE + "/main-category";
const SPAREPARTS_CATEGORY_LIST = SPAREPARTS_CATEGORY_BASE + "/list";
const SPAREPARTS_CATEGORY_INPUT = SPAREPARTS_CATEGORY_BASE + "/input";
const SPAREPARTS_CATEGORY_EDIT = SPAREPARTS_CATEGORY_BASE + "/edit";
const SPAREPARTS_CATEGORY_DELETE = SPAREPARTS_CATEGORY_BASE + "/delete";

const SPAREPARTS_SUBCATEGORY_BASE = SPAREPARTS_BASE + "/sub-category";
const SPAREPARTS_SUBCATEGORY_LIST = SPAREPARTS_SUBCATEGORY_BASE + "/list";
const SPAREPARTS_SUBCATEGORY_INPUT = SPAREPARTS_SUBCATEGORY_BASE + "/input";
const SPAREPARTS_SUBCATEGORY_EDIT = SPAREPARTS_SUBCATEGORY_BASE + "/edit";
const SPAREPARTS_SUBCATEGORY_DELETE = SPAREPARTS_SUBCATEGORY_BASE + "/delete";

const SPAREPARTS_QUALITY_BASE = SPAREPARTS_BASE + "/quality";
const SPAREPARTS_QUALITY_LIST = SPAREPARTS_QUALITY_BASE + "/list";
const SPAREPARTS_QUALITY_INPUT = SPAREPARTS_QUALITY_BASE + "/input";
const SPAREPARTS_QUALITY_EDIT = SPAREPARTS_QUALITY_BASE + "/edit";
const SPAREPARTS_QUALITY_DELETE = SPAREPARTS_QUALITY_BASE + "/delete";

const SPAREPARTS_DISTRIBUTOR_BASE = SPAREPARTS_BASE + "/distributor";
const SPAREPARTS_DISTRIBUTOR_LIST = SPAREPARTS_DISTRIBUTOR_BASE + "/list";
const SPAREPARTS_DISTRIBUTOR_INPUT = SPAREPARTS_DISTRIBUTOR_BASE + "/input";
const SPAREPARTS_DISTRIBUTOR_EDIT = SPAREPARTS_DISTRIBUTOR_BASE + "/edit";
const SPAREPARTS_DISTRIBUTOR_DELETE = SPAREPARTS_DISTRIBUTOR_BASE + "/delete";

const SPAREPARTS_STORAGE_LOCATION_BASE = SPAREPARTS_BASE + "/storage-location";
const SPAREPARTS_STORAGE_LOCATION_LIST =
  SPAREPARTS_STORAGE_LOCATION_BASE + "/list";
const SPAREPARTS_STORAGE_LOCATION_INPUT =
  SPAREPARTS_STORAGE_LOCATION_BASE + "/input";
const SPAREPARTS_STORAGE_LOCATION_EDIT =
  SPAREPARTS_STORAGE_LOCATION_BASE + "/edit";
const SPAREPARTS_STORAGE_LOCATION_DELETE =
  SPAREPARTS_STORAGE_LOCATION_BASE + "/delete";

const SPAREPARTS_REQUEST_BASE = SPAREPARTS_BASE + "/request";
const SPAREPARTS_REQUEST_LIST = SPAREPARTS_REQUEST_BASE + "/list";
const SPAREPARTS_REQUEST_DELETE = SPAREPARTS_REQUEST_BASE + "/delete";
const SPAREPARTS_REQUEST_INPUT = SPAREPARTS_REQUEST_BASE + "/input";
const SPAREPARTS_REQUEST_EDIT = SPAREPARTS_REQUEST_BASE + "/edit";

const SPAREPARTS_REQUEST_PART_BASE = SPAREPARTS_REQUEST_BASE + "/master";
const SPAREPARTS_REQUEST_PART_LIST = SPAREPARTS_REQUEST_PART_BASE + "/list";
const SPAREPARTS_REQUEST_PART_INPUT = SPAREPARTS_REQUEST_PART_BASE + "/input";
const SPAREPARTS_REQUEST_PART_EDIT = SPAREPARTS_REQUEST_PART_BASE + "/edit";
const SPAREPARTS_REQUEST_PART_DELETE = SPAREPARTS_REQUEST_PART_BASE + "/delete";

const SPAREPARTS_REQUEST_ITEM_DETAIL_BASE =
  SPAREPARTS_REQUEST_BASE + "/item-detail";
const SPAREPARTS_REQUEST_ITEM_DETAIL_LIST =
  SPAREPARTS_REQUEST_ITEM_DETAIL_BASE + "/list";
const SPAREPARTS_REQUEST_ITEM_DETAIL_INPUT =
  SPAREPARTS_REQUEST_ITEM_DETAIL_BASE + "/input";
const SPAREPARTS_REQUEST_ITEM_DETAIL_EDIT =
  SPAREPARTS_REQUEST_ITEM_DETAIL_BASE + "/edit";
const SPAREPARTS_REQUEST_ITEM_DETAIL_DELETE =
  SPAREPARTS_REQUEST_ITEM_DETAIL_BASE + "/delete";

const USER_WITHDRAWAL_LIST = USER_URL + "/withdrawal/list";
const USER_WITHDRAWAL_INPUT = USER_URL + "/withdrawal/input";
const USER_WITHDRAWAL_EDIT = USER_URL + "/withdrawal/edit";
const USER_WITHDRAWAL_DELETE = USER_URL + "/withdrawal/delete";
const USER_SALDO = USER_URL + "/transactional/fva/saldo";
const USER_ACCOUNT_BANK_LIST = USER_URL + "/company/account/list";
const USER_ACCOUNT_BANK_CREATE = USER_URL + "/company/account/input";
const USER_ACCOUNT_BANK_UPDATE = USER_URL + "/company/account/edit";
const USER_ACCOUNT_BANK_DELETE = USER_URL + "/company/account/delete";
const USER_BANK_LIST = USER_URL + "/bank/list";
const USER_PAYMENT_INTERNAL_SALDO = USER_URL + "/payment/internal/saldo";
const USER_PAYMENT_INTERNAL_SALDO_INVOICE =
  USER_URL + "/payment/internal/saldo/invoice";

const USER_PAYMENT_INTERNAL_WALLET_FEE =
  USER_URL + "/payment/internal/wallet/fee";

const ACTIVITY_TYPE_LIST = BASE_URL + "/scan/activity-type/list";
const ACTIVITY_TYPE_INPUT = BASE_URL + "/scan/activity-type/input";
const ACTIVITY_TYPE_EDIT = BASE_URL + "/scan/activity-type/edit";
const ACTIVITY_TYPE_DELETE = BASE_URL + "/scan/activity-type/delete";

// Carbon X - ASSET
const ASSET_MACHINE_LIST = BASE_URL + "/transporter/carbon/machine/list";
const ASSET_MACHINE_INPUT = BASE_URL + "/transporter/carbon/machine/input";
const ASSET_MACHINE_EDIT = BASE_URL + "/transporter/carbon/machine/edit";
const ASSET_MACHINE_DELETE = BASE_URL + "/transporter/carbon/machine/delete";

const ASSET_FIRE_SUPPRESSION_LIST =
  BASE_URL + "/transporter/carbon/fire/suppresion/list";
const ASSET_FIRE_SUPPRESSION_INPUT =
  BASE_URL + "/transporter/carbon/fire/suppresion/input";
const ASSET_FIRE_SUPPRESSION_EDIT =
  BASE_URL + "/transporter/carbon/fire/suppresion/edit";
const ASSET_FIRE_SUPPRESSION_DELETE =
  BASE_URL + "/transporter/carbon/fire/suppresion/delete";

const ASSET_GENERATOR_LIST = BASE_URL + "/transporter/carbon/generator/list";
const ASSET_GENERATOR_INPUT = BASE_URL + "/transporter/carbon/generator/input";
const ASSET_GENERATOR_EDIT = BASE_URL + "/transporter/carbon/generator/edit";
const ASSET_GENERATOR_DELETE =
  BASE_URL + "/transporter/carbon/generator/delete";

const ASSET_AC_LIST = BASE_URL + "/transporter/carbon/acrefrigerator/list";
const ASSET_AC_INPUT = BASE_URL + "/transporter/carbon/acrefrigerator/input";
const ASSET_AC_EDIT = BASE_URL + "/transporter/carbon/acrefrigerator/edit";
const ASSET_AC_DELETE = BASE_URL + "/transporter/carbon/acrefrigerator/delete";

const ASSET_REFRIGERATOR_LIST =
  BASE_URL + "/transporter/carbon/refrigerator/list";
const ASSET_REFRIGERATOR_INPUT =
  BASE_URL + "/transporter/carbon/refrigerator/input";
const ASSET_REFRIGERATOR_EDIT =
  BASE_URL + "/transporter/carbon/refrigerator/edit";
const ASSET_REFRIGERATOR_DELETE =
  BASE_URL + "/transporter/carbon/refrigerator/delete";

const BILLS_LIST = BASE_URL + "/transporter/carbon/bill/list";
const BILLS_INPUT = BASE_URL + "/transporter/carbon/bill/input";
const BILLS_EDIT = BASE_URL + "/transporter/carbon/bill/edit";
const BILLS_DELETE = BASE_URL + "/transporter/carbon/bill/delete";

const HR_LIST = BASE_URL + "/transporter/carbon/hr/list";
const HR_INPUT = BASE_URL + "/transporter/carbon/hr/input";
const HR_EDIT = BASE_URL + "/transporter/carbon/hr/edit";
const HR_DELETE = BASE_URL + "/transporter/carbon/hr/delete";

// CARBON X - SUPPLY CHAIN DASHBOARD
const SUPPLY_CHAIN_DASHBOARD =
  BASE_URL + "/transporter/carbon/dashboard/tab/supply/chain";

// CARBON X - COMPANY EMISSION DASHBOARD
const COMPANY_EMISSION_DASHBOARD_OVERVIEW =
  BASE_URL + "/transporter/carbon/dashboard/tab/overview";
const COMPANY_EMISSION_DASHBOARD_SCOPE1 =
  BASE_URL + "/transporter/carbon/dashboard/tab/scope/one";
const COMPANY_EMISSION_DASHBOARD_SCOPE2 =
  BASE_URL + "/transporter/carbon/dashboard/tab/scope/two";
const COMPANY_EMISSION_DASHBOARD_SCOPE3 =
  BASE_URL + "/transporter/carbon/dashboard/tab/scope/three";


const LIVE_MONITORING_AVG_SPD = BASE_URL + "/transporter/report/live/avg/spd";

const LIVE_MONITORING_CCTV_LIST_URL =  BASE_URL + "/transporter/cctv/creds/list";
const LIVE_MONITORING_CCTV_INPUT_URL =  BASE_URL + "/transporter/cctv/creds/input";
const LIVE_MONITORING_CCTV_EDIT_URL =  BASE_URL + "/transporter/cctv/creds/edit";
const LIVE_MONITORING_CCTV_DELETE_URL =  BASE_URL + "/transporter/cctv/creds/delete";

const FUEL_TRACKING_MYPERTAMINA_BULK = BASE_URL + "/fleet/fuel/track/mypertamina/bulk";

export const FUEL_TRACKING_MYPERTAMINA_LIST = BASE_URL + "/fleet/fuel/track/mypertamina/list";

export const CHECK_SHEET_WEEKLY_LIST = BASE_URL + "/check-sheet/weekly/list";
export const CHECK_SHEET_WEEKLY_ADD = BASE_URL + "/check-sheet/weekly/input";
export const CHECK_SHEET_WEEKLY_DETAIL_LIST = BASE_URL + "/check-sheet/weekly/detail/list"
export const CHECK_SHEET_WEEKLY_DETAIL_INPUT = BASE_URL + "/check-sheet/weekly/detail/input"
export const CHECK_SHEET_WEEKLY_DETAIL_CHECK = BASE_URL + "/check-sheet/weekly/detail/check"
export const CHECK_SHEET_WEEKLY_DETAIL_APPROVE = BASE_URL + "/check-sheet/weekly/detail/approve"

export {
  LOGIN_URL,
  REGISTER_URL,
  CHANGE_PASSWORD_URL,
  RESEND_VERIFY_EMAIL_URL,
  USER_LIST_URL,
  USER_STATUS_URL,
  USER_VERIFY_URL,
  USER_COMPANY_BANK_LIST,
  USER_COMPANY_GENERATE_VA,
  USER_TRANSACTION_HISTORY_LIST,
  CAR_DETAILS_TABLE_URL,
  USER_NOTIFICATION_TOKEN,

  // COVID
  DRIVER_COVID_TEST_INPUT_URL,
  DRIVER_COVID_TEST_LIST_URL,
  DRIVER_VACCINE_INPUT_URL,
  DRIVER_VACCINE_LIST_URL,
  DRIVER_ASST_COVID_TEST_INPUT_URL,
  DRIVER_ASST_COVID_TEST_LIST_URL,
  DRIVER_ASST_VACCINE_LIST_URL,
  DRIVER_ASST_VACCIBE_INPUT_URL,

  //Drivers
  DRIVER_BASE_URL,
  DRIVER_LIST_URL,
  DRIVER_LIST_URL_V2,
  DRIVER_FLEETS_LIST_URL,
  DRIVER_FLEETS_INPUT_URL,
  DRIVER_FLEETS_DELETE_URL,
  DRIVER_SUMMARY_URL,
  DRIVER_ADD_URL,
  DRIVER_EDIT_URL,
  DRIVER_EDIT_URL_V2,
  DRIVER_ASSISTANT_LIST_URL,
  DRIVER_ASSISTANT_ADD_URL,
  DRIVER_ASSISTANT_EDIT_URL,
  SECURITY_LIST_URL,
  SECURITY_INPUT_URL,
  DRIVERAPPUSER_LIST_URL,
  DRIVERAPPUSER_ADD_URL,
  DRIVERAPPUSER_EDIT_URL,
  DRIVERAPPUSER_DELETE_URL,
  MECHANIC_LIST_URL,
  MECHANIC_CERTIFICATION_LIST_URL,
  MECHANIC_SPECIALIZATION_LIST_URL,
  MECHANIC_OPERATIONALHOUR_LIST_URL,
  WORKSHOP_LIST_URL,
  WORKSHOP_ADD_URL,
  WORKSHOP_EDIT_URL,
  WORKSHOP_DELETE_URL,
  WORKSHOP_OH_LIST_URL,
  WORKSHOP_OH_ADD_URL,
  WORKSHOP_OH_EDIT_URL,
  WORKSHOP_OH_DELETE_URL,
  WORKSHOP_PIC_POS_LIST_URL,
  WORKSHOP_PIC_POS_ADD_URL,
  WORKSHOP_PIC_POS_EDIT_URL,
  WORKSHOP_PIC_POS_DELETE_URL,
  WORKSHOP_PIC_LIST_URL,
  WORKSHOP_PIC_ADD_URL,
  WORKSHOP_PIC_EDIT_URL,
  WORKSHOP_PIC_DELETE_URL,
  WORKSHOP_PAYMENT_TERM_TYPE_LIST_URL,
  WORKSHOP_PAYMENT_TERM_TYPE_ADD_URL,
  WORKSHOP_PAYMENT_TERM_TYPE_EDIT_URL,
  WORKSHOP_PAYMENT_TERM_TYPE_DELETE_URL,
  WORKSHOP_OPERATIONAL_HOUR_LIST_URL,
  WORKSHOP_OPERATIONAL_HOUR_ADD_URL,
  WORKSHOP_OPERATIONAL_HOUR_EDIT_URL,
  WORKSHOP_OPERATIONAL_HOUR_DELETE_URL,
  FLEET_LIST_URL,
  FLEET_INPUT_URL,
  FLEET_EDIT_URL,
  FLEET_LIST_IMAGE_URL,
  FLEET_INPUT_IMAGE_URL,
  FLEET_MAINTENANCE_BINDING_LIST_URL,
  FLEET_MAINTENANCE_SETTING_INPUT_URL,
  FLEET_MAINTENANCE_SETTING_EDIT_URL,
  FLEET_MAINTENANCE_SETTING_BINDING_URL,
  FLEET_MAINTENANCE_REMINDER_URL,
  FLEET_MAINTENANCE_REMINDER_LIST_URL,
  SCHEDULED_MAINTENANCE_LIST_URL,
  SCHEDULED_MAINTENANCE_ADD_URL,
  SCHEDULED_MAINTENANCE_EDIT_URL,
  SCHEDULED_MAINTENANCE_DELETE_URL,
  SCHEDULED_MAINTENANCE_WORKHSOP_SIMPLE_APPROVAL,
  SM_CLIENT_APPROVAL_URL,
  SM_CLIENT_FAKE_PAYMENT_URL,
  SCHEDULED_MAINTENANCE_TASK_DETAIL_LIST_URL,
  SCHEDULED_MAINTENANCE_TASK_DETAIL_ADD_URL,
  SCHEDULED_MAINTENANCE_TASK_DETAIL_EDIT_URL,
  SCHEDULED_MAINTENANCE_TASK_DETAIL_DELETE_URL,
  SCHEDULED_MAINTENANCE_TASK_DETAIL_APPROVAL,
  SCHEDULED_MAINTENANCE_TASK_PROOF,
  SCHEDULED_MAINTENANCE_WORKSHOP_LIST_URL,
  SM_APPROVAL_SETTING_LIST_URL,
  SM_APPROVAL_SETTING_ADD_URL,
  SM_APPROVAL_SETTING_EDIT_URL,
  SM_APPROVAL_SETTING_DELETE_URL,
  SM_MECHANIC_TASK_LIST_URL,
  SM_MECHANIC_TASK_ADD_URL,
  SM_MECHANIC_TASK_EDIT_URL,
  SM_MECHANIC_TASK_DELETE_URL,
  MECHANIC_ADD,
  MECHANIC_EDIT,
  MECHANIC_DELETE,
  SM_APPROVAL_TYPE_LIST_URL,
  SM_APPROVAL_TYPE_ADD_URL,
  SM_APPROVAL_TYPE_EDIT_URL,
  SM_APPROVAL_TYPE_DELETE_URL,
  SM_APPROVAL_LIST_URL,
  SM_APPROVAL_ADD_URL,
  SM_APPROVAL_EDIT_URL,
  SM_APPROVAL_DELETE_URL,
  SM_MECHANIC_LIST_URL,
  SM_MECHANIC_ADD_URL,
  SM_MECHANIC_EDIT_URL,
  SM_MECHANIC_DELETE_URL,

  //Saved Places
  SAVED_PLACES_ADD_URL,
  SAVED_PLACES_LIST_URL,
  SAVED_PLACES_APPROVAL_URL,
  SAVED_PLACES_EDIT_URL,
  SAVED_PLACES_IMPORT_URL,
  SAVED_PLACES_IMPORT_VALIDATE_URL,
  SAVED_PLACES_MESH_LIST,
  SAVED_PLACES_NEAR_LIST,
  SAVED_PLACES_GET_ADDRESS,
  SAVED_PLACES_UPLOAD_IMAGE,
  SAVED_PLACES_DELETE_IMAGE,

  //Main Customer
  MAIN_CUSTOMER_LIST_URL,
  MAIN_CUSTOMER_ADD_URL,
  MAIN_CUSTOMER_EDIT_URL,
  MAIN_CUSTOMER_DELETE_URL,
  MAIN_CUSTOMER_IMPORT_URL,
  MAIN_CUSTOMER_IMPORT_VALIDATE_URL,
  MAIN_CUSTOMER_VERIFY_URL,
  CHECK_SHEET_DO_LIST_URL,
  CHECK_SHEET_LIST_URL,
  CHECK_SHEET_ADD_URL,
  CHECK_SHEET_EDIT_URL,
  CHECK_SHEET_DELETE_URL,
  CHECK_SHEET_DETAIL_LIST_URL,
  CHECK_SHEET_DETAIL_ADD_URL,
  CHECK_SHEET_DETAIL_EDIT_URL,
  CHECK_SHEET_DETAIL_DELETE_URL,
  CHECK_SHEET_APPROVAL_TYPE_LIST_URL,
  CHECK_SHEET_APPROVAL_TYPE_ADD_URL,
  CHECK_SHEET_APPROVAL_TYPE_EDIT_URL,
  CHECK_SHEET_APPROVAL_TYPE_DELETE_URL,
  CHECK_SHEET_APPROVAL_TYPE_SETTING_LIST_URL,
  CHECK_SHEET_APPROVAL_TYPE_SETTING_ADD_URL,
  CHECK_SHEET_APPROVAL_TYPE_SETTING_EDIT_URL,
  CHECK_SHEET_APPROVAL_TYPE_SETTING_DELETE_URL,
  DIVISION_LIST_URL,
  DIVISION_ADD_URL,
  DIVISION_EDIT_URL,
  DIVISION_DELETE_URL,

  // Delivery
  DELIVERY_BASE_URL,
  DELIVERY_ORDERS_ADD_URL,
  DELIVERY_ORDERS_LIST_URL,
  DELIVERY_APPROVE_URL,
  DELIVERY_ORDER_ROUTE_SENT_PATH_DRIVER_URL,
  DELIVERY_ORDERS_EDIT_URL,
  DELIVERY_ORDERS_COPY_URL,
  WORK_ORDERS_ADD_URL,
  WORK_ORDERS_LIST_URL,
  WORK_ORDERS_EDIT_URL,
  WORK_ORDERS_DELETE_URL,
  WORK_ORDERS_DRAFT_ADD_URL,
  WORK_ORDERS_DRAFT_TRIPL_ADD_URL,
  WORK_ORDERS_DRAFT_CHECK_URL,
  WORK_ORDERS_DRAFT_CONFIRM_URL,
  WORK_ORDERS_DRAFT_EDIT_URL,
  WORK_ORDER_REVIEW_URL,
  DELIVERY_STATUS_LIST_URL,
  WORK_ORDER_SECURITY_APPROVAL_STATUS_RESET_URL,
  STOCKER_WORK_ORDER_INPUT_URL,
  STOCKER_WORK_ORDER_INBOUND_URL,
  WORK_ORDER_UPLOADED_IMAGE_LIST_URL,
  WORK_ORDER_UPLOADED_IMAGE_COMPARE_LIST_URL,
  WORK_ORDER_UPLOADED_IMAGE_COMPARE_CONFIRMATION_URL,
  WORK_ORDER_UPLOAD_IMAGE_URL,
  WORK_ORDER_DELETE_IMAGE_URL,
  WORK_ORDER_UPLOADED_IMAGE_DELETE_URL,
  WORK_ORDER_STOCKER,
  WORK_ORDER_STOCKER_OUTBOUND_ITEM,
  WORK_ORDER_STOCKER_INBOUND_ITEM,
  WORK_ORDER_QR_PRINT_URL,
  WORK_ORDER_POD_VERIF_LOG,
  DELIVERY_ORDER_CATEGORY_LIST,
  DELIVERY_ORDER_CATEGORY_INPUT,
  DELIVERY_ORDER_CATEGORY_EDIT,
  DELIVERY_ORDER_CATEGORY_DELETE,
  DELIVERY_ORDER_SUBCATEGORY_LIST,
  DELIVERY_ORDER_SUBCATEGORY_INPUT,
  DELIVERY_ORDER_SUBCATEGORY_EDIT,
  DELIVERY_ORDER_SUBCATEGORY_DELETE,
  DELIVERY_ORDER_BIND_CATEGORY,
  DELIVERY_ORDER_UNBIND_CATEGORY,

  // 3PL
  TRIPL_WO_REQ_LIST_URL,
  TRIPL_WO_REQ_LIST_RESULT_URL,
  TRIPL_WO_REQ_ADD_URL,
  TRIPL_WO_REQ_BID_URL,
  TRIPL_WO_REQ_INCOMING_BID_LIST_URL,
  TRIPL_WO_APPROVE_BID_URL,
  TRIPL_WO_DECLINE_BID_URL,
  TRIPL_WO_REQ_APPROVED_REQUEST_LIST_URL,
  TRIPL_WO_BINDING_WO_DO_URL,
  TRIPL_WO_BID_APPROVE_CHECK_PAYMENT_URL,
  TRIPL_WO_BID_APPROVE_GENERATE_INVOICE_URL,
  TRIPL_WO_BID_APPROVE_GENERATE_INVOICE_URL_V2,
  TRIPL_CHECK_EST_COST_URL,
  TRIPL_WO_BID_HISTORY_URL,
  TRIPL_BID_WINNER_TRACKER_URL,
  TRIPL_HISTORY_LIST_PATH_URL,

  // 3PL Group
  TRIPL_GROUP_ADD_URL,
  TRIPL_GROUP_LIST_URL,
  TRIPL_GROUP_EDIT_URL,
  TRIPL_GROUP_DELETE_URL,
  TRIPL_GROUP_ASSIGN_URL,
  TRIPL_GROUP_ASSIGN_FIND_URL,
  TRIPL_GROUP_APPROVE_URL,
  TRIPL_GROUP_DECLINE_URL,
  TRIPL_GROUP_ASSIGN_CREATE_FIND_URL,
  TRIPL_GROUP_MEMBER_VIEW_URL,
  TRIPL_GROUP_FLEET_CHECK_URL,
  TRIPL_LIVE_MONITORING_URL,
  TRIPL_BID_STATUS_TO_READY_TO_REVIEW_URL,

  //OBD
  OBD_TYPE_LIST_URL,
  OBD_BINDING_URL,
  OBD_UNBINDING_URL,
  OBD_HISTORY_LIST_URL,
  OBD_HISTORY_LIST_CHECK_URL,
  OBD_IMAGE_LOGGING,
  GENERAL_QR_GENERATE,

  //RENTEE
  RENTEE_CREATE_REQUEST,
  RENTEE_REQUEST_LIST,
  RENTEE_REQUEST_OWNER_LIST,
  RENTEE_REQUEST_FLEET_OWNER_LIST,
  RENTEE_FLEET_REQUEST_LIST,
  RENTEE_APPROVE_AND_CREATE_CONTRACT,
  RENTEE_CONTRACT_LIST,
  RENTEE_RENTER_CONTRACT_APPROVAL,
  RENTEE_FLEET_LIVE_MONITORING,
  RENTEE_DO_INPUT,
  REQUESTED_FLEET_DECLINE,
  OWNER_ADD_REQUESTED_FLEET,

  //Car
  CAR_LIST_BRAND_URL,
  CAR_LIST_TYPE_URL,
  CAR_LIST_GENERAL_URL,
  CAR_LIST_FUEL_TYPE_URL,
  DELIVERY_CHECK_IN_LOG_URL,
  LIVE_MONITORING_URL,
  LIVE_MONITORING_FOR_EST_COST_URL,
  LIVE_MONITORING_SHARE_TOKEN,
  GOOGLE_GEOCODING,
  LIVE_MONITORING_DASHBOARD,
  LIVE_MONITORING_BEETWEN,
  TRANSPORTER_DELETE_URL,
  FLEET_REPORT_URL,
  DELIVERY_AUDIT_URL,
  DELIVERY_AUDIT_URL_FAMILY,
  REPORT_DAILY_SUM,
  FLEET_FUEL_REPORT_URL,
  UNPLANNED_STOP_LOCK,
  UNPLANNED_STOP_LOCK_LIST,
  UNPLANNED_STOP_LOCK_TAGGING,
  UNPLANNED_STOP_LOCK_FINISH,

  //Maintenance
  FLEET_MAINTENANCE_LIST_URL,
  FLEET_MAINTENANCE_INPUT_URL,
  FLEET_MAINTENANCE_EDIT_URL,
  FLEET_MAINTENANCE_DELETE_URL,

  // Fuel Management
  FUEL_MANAGEMENT_ADD,
  FUEL_MANAGEMENT_LIST,
  FUEL_MANAGEMENT_APPROVAL,
  FUEL_MANAGEMENT_DELETE_URL,

  //Settings
  SETTING_CHANGE_URL,
  SETTING_LIST_URL,
  COMPANY_SETTING_LIST_URL,
  COMPANY_EDIT_URL,
  COMPANY_LIST_ALL_URL,
  SERVICE_PLAN_LIST_URL,

  //TRANSPORTER DASHBOARD
  DASHBOARD_TRANSPORTER_COUNT_URL,
  TRANSPORTER_SIM_NOTIFICATION_DASHBOARD_URL,
  TRANSPORTER_FLEET_NOTIFICATION_DASHBOARD_URL,
  TRANSPORTER_FLEET_MAINTENANCE_NOTIFICATION_DASHBOARD_URL,

  //EDITING IMAGES
  UPLOAD_IMAGE_FOR_EDIT,
  GEOCODE_INPUT_BULK_URL,
  GEOCODE_VALIDATE_URL,

  //PUBLIC FACILITY
  PUBLIC_FACILITY_LIST_URL,
  PUBLIC_FACILITY_ADD_URL,
  PUBLIC_FACILITY_DELETE_URL,
  PUBLIC_FACILITY_APPROVAL_URL,
  PUBLIC_FACILITY_TYPE_LIST_URL,
  MAINTENANCE_CATEGORY_LIST,
  MAINTENANCE_SUB_CATEGORY_LIST,
  CURRATIVE_LIST,
  CURRATIVE_IMAGE_LIST,
  MAINTENANCE_CATEGORY_ADD,
  MAINTENANCE_CATEGORY_EDIT,
  MAINTENANCE_CATEGORY_DELETE,
  MAINTENANCE_SUB_CATEGORY_ADD,
  MAINTENANCE_SUB_CATEGORY_EDIT,
  MAINTENANCE_SUB_CATEGORY_DELETE,
  PACKAGE_MAINTENANCE_LIST,
  PACKAGE_MAINTENANCE_ADD,
  PACKAGE_MAINTENANCE_EDIT,
  PACKAGE_MAINTENANCE_DELETE,
  PACKAGE_MAINTENANCE_TASK_LIST,
  PACKAGE_MAINTENANCE_TASK_ADD,
  PACKAGE_MAINTENANCE_TASK_EDIT,
  PACKAGE_MAINTENANCE_TASK_SUB_ADD,
  PACKAGE_MAINTENANCE_TASK_SUB_EDIT,
  PACKAGE_MAINTENANCE_TASK_DELETE,
  PREVENTIVE_MAINTENANCE_LIST,
  PREVENTIVE_MAINTENANCE_ADD,
  PREVENTIVE_MAINTENANCE_EDIT,
  PREVENTIVE_MAINTENANCE_DELETE,
  PREVENTIVE_MAINTENANCE_TASK_LIST,
  PREVENTIVE_MAINTENANCE_TASK_ADD,
  PREVENTIVE_MAINTENANCE_TASK_EDIT,
  PREVENTIVE_MAINTENANCE_SUB_TASK_ADD,
  PREVENTIVE_MAINTENANCE_SUB_TASK_EDIT,
  PREVENTIVE_MAINTENANCE_SUB_TASK_LIST,
  PREVENTIVE_MAINTENANCE_TASK_DELETE,
  PREVENTIVE_MAINTENANCE_FLEET_ADD,
  PREVENTIVE_MAINTENANCE_FLEET_EDIT,
  PREVENTIVE_MAINTENANCE_FLEET_LIST,
  CHECK_SHEET_APPROVAL_ADD_URL,
  CHECK_SHEET_APPROVAL_DELETE_URL,
  CHECK_SHEET_BINDING_DO_ADD_URL,
  CHECK_SHEET_BINDING_DO_EDIT_URL,
  CHECK_SHEET_BINDING_DO_DELETE_URL,
  CHECK_SHEET_BINDING_DO_LIST_URL,
  CHECK_SHEET_DO_DETAIL_INPUT,
  CHECK_SHEET_DO_DETAIL_LIST,
  CHECK_SHEET_DO_APPROVAL_LIST,
  CHECK_SHEET_DO_APPROVAL_INPUT,
  SPAREPARTS_LIST,
  SPAREPARTS_INPUT,
  SPAREPARTS_EDIT,
  SPAREPARTS_DELETE,
  SPAREPARTS_BRAND_LIST,
  SPAREPARTS_BRAND_INPUT,
  SPAREPARTS_BRAND_EDIT,
  SPAREPARTS_BRAND_DELETE,
  SPAREPARTS_CATEGORY_LIST,
  SPAREPARTS_CATEGORY_INPUT,
  SPAREPARTS_CATEGORY_EDIT,
  SPAREPARTS_CATEGORY_DELETE,
  SPAREPARTS_SUBCATEGORY_LIST,
  SPAREPARTS_SUBCATEGORY_INPUT,
  SPAREPARTS_SUBCATEGORY_EDIT,
  SPAREPARTS_SUBCATEGORY_DELETE,
  SPAREPARTS_QUALITY_LIST,
  SPAREPARTS_QUALITY_INPUT,
  SPAREPARTS_QUALITY_EDIT,
  SPAREPARTS_QUALITY_DELETE,
  SPAREPARTS_DETAIL_LIST,
  SPAREPARTS_DETAIL_INPUT,
  SPAREPARTS_DETAIL_EDIT,
  SPAREPARTS_DETAIL_DELETE,
  SPAREPARTS_DISTRIBUTOR_LIST,
  SPAREPARTS_DISTRIBUTOR_INPUT,
  SPAREPARTS_DISTRIBUTOR_EDIT,
  SPAREPARTS_DISTRIBUTOR_DELETE,
  SPAREPARTS_STORAGE_LOCATION_LIST,
  SPAREPARTS_STORAGE_LOCATION_INPUT,
  SPAREPARTS_STORAGE_LOCATION_EDIT,
  SPAREPARTS_STORAGE_LOCATION_DELETE,
  SPAREPARTS_REQUEST_LIST,
  SPAREPARTS_REQUEST_INPUT,
  SPAREPARTS_REQUEST_EDIT,
  SPAREPARTS_REQUEST_DELETE,
  SPAREPARTS_REQUEST_PART_LIST,
  SPAREPARTS_REQUEST_PART_INPUT,
  SPAREPARTS_REQUEST_PART_EDIT,
  SPAREPARTS_REQUEST_PART_DELETE,
  SPAREPARTS_REQUEST_ITEM_DETAIL_LIST,
  SPAREPARTS_REQUEST_ITEM_DETAIL_INPUT,
  SPAREPARTS_REQUEST_ITEM_DETAIL_EDIT,
  SPAREPARTS_REQUEST_ITEM_DETAIL_DELETE,
  COMPANY_VA_LIST_URL,
  USER_WITHDRAWAL_LIST,
  USER_WITHDRAWAL_INPUT,
  USER_SALDO,
  USER_ACCOUNT_BANK_LIST,
  USER_ACCOUNT_BANK_CREATE,
  USER_ACCOUNT_BANK_UPDATE,
  USER_ACCOUNT_BANK_DELETE,
  USER_BANK_LIST,
  USER_PAYMENT_INTERNAL_SALDO,
  USER_PAYMENT_INTERNAL_SALDO_INVOICE,
  USER_WITHDRAWAL_EDIT,
  USER_WITHDRAWAL_DELETE,
  DRIVER_FLEET_SCAN_LIST,
  DRIVER_FLEET_SCAN_INPUT,
  DRIVER_FLEET_SCAN_EDIT,
  DRIVER_FLEET_SCAN_DELETE,
  MECHANIC_FLEET_SCAN_LIST,
  MECHANIC_FLEET_SCAN_INPUT,
  MECHANIC_FLEET_SCAN_EDIT,
  MECHANIC_FLEET_SCAN_DELETE,
  USER_PAYMENT_INTERNAL_WALLET_FEE,
  ACTIVITY_TYPE_LIST,
  ACTIVITY_TYPE_INPUT,
  ACTIVITY_TYPE_EDIT,
  ACTIVITY_TYPE_DELETE,

  // Carbon X - ASSET
  ASSET_MACHINE_LIST,
  ASSET_MACHINE_INPUT,
  ASSET_MACHINE_EDIT,
  ASSET_MACHINE_DELETE,
  ASSET_FIRE_SUPPRESSION_LIST,
  ASSET_FIRE_SUPPRESSION_INPUT,
  ASSET_FIRE_SUPPRESSION_EDIT,
  ASSET_FIRE_SUPPRESSION_DELETE,
  ASSET_GENERATOR_LIST,
  ASSET_GENERATOR_INPUT,
  ASSET_GENERATOR_EDIT,
  ASSET_GENERATOR_DELETE,
  ASSET_AC_LIST,
  ASSET_AC_INPUT,
  ASSET_AC_EDIT,
  ASSET_AC_DELETE,
  ASSET_REFRIGERATOR_LIST,
  ASSET_REFRIGERATOR_INPUT,
  ASSET_REFRIGERATOR_EDIT,
  ASSET_REFRIGERATOR_DELETE,
  BILLS_LIST,
  BILLS_INPUT,
  BILLS_EDIT,
  BILLS_DELETE,
  HR_LIST,
  HR_INPUT,
  HR_EDIT,
  HR_DELETE,

  // CARBON X - SUPPLY CHAIN DASHBOARD
  SUPPLY_CHAIN_DASHBOARD,

  // CARBON X - CARBON EMISSION DASHBOARD
  COMPANY_EMISSION_DASHBOARD_OVERVIEW,
  COMPANY_EMISSION_DASHBOARD_SCOPE1,
  COMPANY_EMISSION_DASHBOARD_SCOPE2,
  COMPANY_EMISSION_DASHBOARD_SCOPE3,

  LIVE_MONITORING_AVG_SPD,

  LIVE_MONITORING_CCTV_LIST_URL,
  LIVE_MONITORING_CCTV_INPUT_URL,
  LIVE_MONITORING_CCTV_EDIT_URL,
  LIVE_MONITORING_CCTV_DELETE_URL,

  GOOGLE_GEOCODING_v2,

  FUEL_TRACKING_MYPERTAMINA_BULK
};

import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  Font,
  // Image,
} from "@react-pdf/renderer";
import Header from './Components/Header'
import Body from './Components/Body'

Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: './assets/fonts/Roboto-Regular.ttf',
      },
      {
        fontStyle: 'bold',
        src: './assets/fonts/Roboto-Bold.ttf',
      },
      {
        fontStyle: 'bolditalic',
        src: './assets/fonts/Roboto-BoldItalic.ttf',
      },
      {
        fontStyle: 'black',
        src: './assets/fonts/Roboto-Black.ttf',
      },
  ],
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff'
    },
})

const PrintableTriPLInvoice = (props) => (
    <Page size="A4" style={styles.page} orientation="portrait">
        <Header/>
        <Body 
            fleets={props.fleets}
          />
    </Page>
)

export default (
  props
) => (
    <Document
      author="Automa IoT Platform"
      subject="Fleet(s) Qr - Automa"
      title="Fleet(s) Qr - Automa"
    >
      <PrintableTriPLInvoice 
          fleets={props.fleets}
        />
    </Document>
  );
import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Collapse,
  CardBody,
  Row,
  Col,
  // Alert,
  Button,
  Label,
  Input,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Badge,
} from "reactstrap";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import { connect } from "react-redux";
import * as API_LINKS from "../../../redux/config/links";
import axios from "axios";
import { fetchDeliveryStatusList } from "../../../redux/actions/transporter/aTransporter-DeliveryStatusList";
import { OUTBOUND_DETAIL_LIST_URL } from "../../../redux/config/stck.links";
import { SelectField } from "../../LandLord/Widgets/SelectField";
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AbilityContext } from "../../../casl/Can";
import WorkOrderResetSecurityApproval from "./WorkOrderResetSecurityApproval";
// import WorkOrdersImageUpload from './WorkOrdersImageUpload'
import WorkOrdersViewImageUpload from "./WorkOrdersViewImageUpload";
import WorkOrderSubmitForReview from "./WorkOrderSubmitForReview";
import StockerItemDetail from "./StockerItemDetail";
import CheckOutboundItem from "./CheckOutboundItemButton";
import CheckOutboundItemTable from "./CheckOutboundItemTable";
import WorkOrdersViewImageUploadNoButton from "./WorkOrdersViewImageUploadNoButton";
import PrintableSingleWorkOrder from "./PrintableSingleWorkOrder/PrintableSingleWorkOrder";
import { pdf } from "@react-pdf/renderer";

var QRCode = require("qrcode");

const validationBindSchema = Yup.object().shape({
  status_delivery: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.number(),
    })
    .nullable()
    .required("Location is required"),
  notes: Yup.string().notRequired(),
  driver_notes: Yup.string().notRequired(),
});

const propTypes = {
  id: PropTypes.number,
  company: PropTypes.string,
  company_phone: PropTypes.number,
  id_do: PropTypes.number,
  wo_number: PropTypes.string,
  wo_seq: PropTypes.number,
  wo_desc: PropTypes.string,
  wo_destination_name: PropTypes.string,
  wo_address: PropTypes.string,
  pic_name: PropTypes.string,
  pic_phone: PropTypes.string,
  loc_name: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  dept_lat: PropTypes.number,
  dept_lng: PropTypes.number,
  est_departure_time: PropTypes.string,
  est_arrival_time: PropTypes.string,
  dept_time: PropTypes.string,
  arrival_time: PropTypes.string,
  cargo_weight: PropTypes.number,
  loading_time: PropTypes.number,
  check_in: PropTypes.string,
  check_out: PropTypes.string,
  distance: PropTypes.number,
  share: PropTypes.string,
  approval_time: PropTypes.string,
  id_security: PropTypes.any,
  security_name: PropTypes.string,
  status_delivery: PropTypes.string,
  status_delivery_detail: PropTypes.string,
  customer_approval: PropTypes.bool,
  status_customer_approval_detail: PropTypes.string,
  customer_approval_name: PropTypes.string,
  customer_approval_remarks: PropTypes.string,
  customer_approval_time: PropTypes.string,
  main_customer: PropTypes.string,
  main_customer_phone: PropTypes.string,
  goals_type: PropTypes.string,
  notes: PropTypes.string,
  draftStatus: PropTypes.bool,
  est_stop_time: PropTypes.number,
  break_time: PropTypes.number,
  boolSecurity: PropTypes.bool,
  other_fee: PropTypes.number,
  toll_fee: PropTypes.number,
  weight_fee: PropTypes.number,
  price_delivery_tkm: PropTypes.number,
  driver_notes: PropTypes.string,
  est_carbon: PropTypes.number,
  est_cost: PropTypes.number,
  id_stocker_outbound: PropTypes.number,
  id_stocker_outbound_saved: PropTypes.number,
  id_stocker_inbound: PropTypes.number,
  id_stocker_inbound_saved: PropTypes.number,
  invoice_id: PropTypes.string,
  id_bid: PropTypes.number,
  id_request_wo_3pl: PropTypes.number,
  do_number: PropTypes.string,
  driver_name: PropTypes.string,
  fleet_plate: PropTypes.string,
};

const defaultProps = {
  id: 0,
  company: "",
  company_phone: "",
  id_do: 0,
  wo_number: "",
  wo_seq: 0,
  wo_desc: "",
  wo_destination_name: "",
  wo_address: "",
  pic_name: "",
  pic_phone: "",
  loc_name: "",
  lat: 0,
  lng: 0,
  dept_lat: 0,
  dept_lng: 0,
  est_departure_time: "",
  est_arrival_time: "",
  dept_time: "",
  arrival_time: "",
  cargo_weight: 0,
  loading_time: 0,
  check_in: "",
  check_out: "",
  distance: 0,
  share: "",
  approval_time: "",
  id_security: 0,
  security_name: "",
  status_delivery: null,
  status_delivery_detail: null,
  customer_approval: false,
  status_customer_approval_detail: "",
  customer_approval_name: "",
  customer_approval_remarks: "",
  customer_approval_time: "",
  main_customer: "",
  main_customer_phone: "",
  goals_type: "",
  notes: "",
  draftStatus: true,
  est_stop_time: 0,
  break_time: 0,
  boolSecurity: false,
  other_fee: 0,
  weight_fee: 0,
  toll_fee: 0,
  price_delivery_tkm: 0,
  est_carbon: 0,
  est_cost: 0,
  driver_notes: "",
  id_stocker_outbound: 0,
  id_bid: null,
  do_number: "",
  fleet_plate: "",
  driver_name: "",
};

const validationDeleteSchema = Yup.object().shape({
  id: Yup.number().required(),
});

const validationEditSchema = Yup.object().shape({
  // id_do: Yup.number()
  // .required(),
  id_wo: Yup.number().required(),
  wo_seq: Yup.number()
    .min(1, "Wo Sequence cannot be more than 1000")
    .max(1000, "Wo Sequence cannot be more than 1000")
    .required(),
  est_stop_time: Yup.number()
    .min(0, "Value cannot be lower than 0")
    .required("Est. Stop Time should be stated!"),
  break_time: Yup.number()
    .min(0, "Value cannot be lower than 0")
    .required("Break time should be stated!"),
  wo_number: Yup.string().notRequired(),
});

class WorkOrderWidget extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
      modalDelete: false,
      modalEdit: false,
      initialApprovalSchema: {
        status_delivery: {},
        notes: "",
        driver_notes: "",
      },

      stockerItems: [],

      initialDeleteValues: {
        id: 0,
      },

      initialEditValues: {
        id_do: this.props.id_do,
        id_wo: this.props.id,
        wo_seq: this.props.wo_seq,
        wo_number: this.props.wo_number,
        est_stop_time: this.props.est_stop_time,
        break_time: this.props.break_time,
      },

      showCheckItemTable: false,
    };
  }

  showCheckItemTable = (isShow) => {
    this.setState({
      showCheckItemTable: isShow,
    });
  };

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  toggleOpenBind = (id, id_do, wo_number) => {
    // this.loadSecurityDatatoSelect()
    this.setState({
      modalBind: !this.state.modalBind,
      bindId: id,
      bindIdDO: id_do,
      bindWoNumber: wo_number,
    });
  };

  toggleCloseBind = () => {
    this.setState({
      modalBind: !this.state.modalBind,
    });
  };

  toggleOpenEdit = () => {
    this.setState({
      id_do: this.props.id_do,
      id_wo: this.props.id,
      wo_seq: this.props.wo_seq,
      wo_number: this.props.wo_number,
      est_stop_time: this.props.est_stop_time,
      break_time: this.props.break_time,

      modalEdit: !this.state.modalEdit,
    });
  };

  toggleCloseEdit = () => {
    this.setState({
      id_do: this.props.id_do,
      id_wo: this.props.id,
      wo_seq: this.props.wo_seq,
      wo_number: this.props.wo_number,
      est_stop_time: this.props.est_stop_time,
      break_time: this.props.break_time,

      modalEdit: !this.state.modalEdit,
    });
  };

  toggleOpenDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete,
      initialDeleteValues: {
        id: this.props.id,
      },
    });
  };

  toggleCloseDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete,
      initialDeleteValues: {
        id: 0,
      },
    });
  };

  handleCheckStockerOutbound(id_stocker_outbound) {
    if (
      id_stocker_outbound === null ||
      id_stocker_outbound === undefined ||
      id_stocker_outbound === 0
    ) {
      return true;
    } else {
      this.setState({
        isChecking: true,
      });
      var self = this;
      var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      var auth = loginCreds.token;

      axios({
        method: "post",
        url: OUTBOUND_DETAIL_LIST_URL,
        headers: {
          Authorization: auth,
        },
        data: {
          id_master_outbound: id_stocker_outbound,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              self.setState({
                isChecking: false,
                successCheck: true,
                stockerItems: response.data.result,
              });
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              self.setState({
                isChecking: false,
                successCheck: false,
                stockerItems: [],
              });
              return toast.error("Invalid credentials! Please try again!");
            } else if (error.response.status === 403) {
              self.setState({
                isChecking: false,
                successCheck: false,
                stockerItems: [],
              });
              return toast.error("Unauthorized Access! Please try to logout..");
            } else if (error.response.status === 400) {
              self.setState({
                isChecking: false,
                successCheck: false,
                stockerItems: [],
              });
              return toast.error(error.response.data.message);
            } else if (
              error.response.status === 404 ||
              error.response.status === 500
            ) {
              self.setState({
                isChecking: false,
                successCheck: false,
                stockerItems: [],
              });
              return toast.error(
                "Server cannot be contacted! Please ask your system administrator!"
              );
            } else {
              self.setState({
                isChecking: false,
                successCheck: false,
                stockerItems: [],
              });
              return toast.error(
                "Something went wrong... Please try again later..."
              );
            }
          } else if (error.request) {
            self.setState({
              isChecking: false,
              successCheck: false,
              stockerItems: [],
            });
            return toast.error("Request error! Please try again later...");
          } else {
            self.setState({
              isChecking: false,
              successCheck: false,
              stockerItems: [],
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        });
    }
  }

  colorHeader = (_type) => {
    // eslint-disable-next-line
    if (_type == 0) {
      return "primary";
    } // eslint-disable-next-line
    else if (_type == 9989) {
      return "success";
    } else {
      return "warning";
    }
  };

  dateFormatter = (_data) => {
    var date = moment(_data).format("DD-MMM-YYYY HH:mm:ss");
    if (date !== "Invalid date") {
      // console.log(date)
      return date;
    } else {
      return "Invalid date";
    }
  };

  checkWOType = (_type) => {
    // eslint-disable-next-line
    if (_type == 0) {
      return "Departure";
    } // eslint-disable-next-line
    else if (_type == 9989) {
      return "Home";
    } else {
      return "Waypoint";
    }
  };

  checkWOTypeTrue = (_type) => {
    // eslint-disable-next-line
    if (_type == 0) {
      return false;
    } // eslint-disable-next-line
    else if (_type == 9989) {
      return false;
    } else {
      return true;
    }
  };

  viewWOType = (_type) => {
    // eslint-disable-next-line
    if (_type == 0) {
      return this.viewDepartureWO();
    } // eslint-disable-next-line
    else if (_type == 9989) {
      return this.viewHomeWO();
    } else {
      return this.viewWaypointWO();
    }
  };

  curStringFormat = (_digit) => {
    return (
      "Rp. " +
      parseFloat(_digit)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };

  checkLevel = () => {
    var isLevelValid =
      this.context.can(
        "view",
        "security_approval_button@work_order_widget_subs"
      ) &&
      this.context.can("view", "security_approval_button@work_order_widget")
        ? true
        : false;

    return isLevelValid;
  };

  checkSuperAdminForReset = () => {
    var isLevelValid =
      this.context.can(
        "reset",
        "security_reset_approval_button@work_order_widget_subs"
      ) &&
      this.context.can(
        "reset",
        "security_reset_approval_button@work_order_widget"
      )
        ? true
        : false;

    return isLevelValid;
  };

  checkAdmin = () => {
    var isAdmin = this.context.can(
      "view",
      "edit_delete_wo_list@work_order_widget"
    )
      ? true
      : false;

    return isAdmin;
  };

  viewHomeWO = () => {
    const {
      wo_number,
      wo_destination_name,
      status_delivery,
      draftStatus,
      status_delivery_detail,
      notes,
      driver_notes,
      est_cost,
      est_carbon,
      id_do,
      id,
    } = this.props;
    return (
      <Row className="container">
        <Col md="12">
          <h6>Work Order Information</h6>
          <dt>WO ID</dt>
          <dd>{id ? id : "-"}</dd>
          <dt>WO Number</dt>
          <dd>{wo_number ? wo_number : "-"}</dd>
          <dt>Home Destination</dt>
          <dd>{wo_destination_name ? wo_destination_name : "-"}</dd>
          {/* {(!draftStatus) ?  <WorkOrdersImageUpload  woNumber ={wo_number} mapId={this.props.mapId} idDo={id_do} idWo={id}/> : null} */}
          <br />
          <br />
          <h6>Cost Estimation</h6>
          {/* <dt>Toll Fee</dt><dd>{toll_fee ? this.curStringFormat(toll_fee) : '-'}</dd>
                    <dt>Weight Fee</dt><dd>{weight_fee ? this.curStringFormat(weight_fee) : '-'}</dd>
                    <dt>Other Fee Fee</dt><dd>{other_fee ? this.curStringFormat(other_fee) : '-'}</dd> */}
          {/* <dt>Delivery Fee</dt><dd>{price_delivery_tkm ? this.curStringFormat(price_delivery_tkm) : '-'}</dd> */}
          <dt>Est. Delivery Cost</dt>
          <dd>{est_cost ? this.curStringFormat(est_cost) : "-"}</dd>
          <br />
          <br />
          <h6>Carbon Emission</h6>
          <dt>Est. Carbon</dt>
          <dd>
            {est_carbon ? est_carbon.toLocaleString() + " Kg CO2" : "-"}
          </dd>{" "}
          <br />
          {draftStatus ? (
            true
          ) : (
            <div>
              <br />
              <h6>Delivery Status</h6>
              <dt>Status</dt>
              <dd>{status_delivery_detail ? status_delivery_detail : "-"}</dd>
              {/* <dt>Security Name</dt><dd>{security_name ? security_name : '-'}</dd> */}
              <dt>Notes</dt>
              <dd style={{ textAlign: "justify" }}>{notes ? notes : "-"}</dd>
              <dt>Driver Notes</dt>
              <dd style={{ textAlign: "justify" }}>
                {driver_notes ? driver_notes : "-"}
              </dd>
            </div>
          )}
          <br />
          {status_delivery === null && !draftStatus ? (
            this.checkLevel() ? (
              <>
                <Button
                  className="btn btn-success"
                  color="success"
                  size="md"
                  onClick={() => this.toggleOpenBind(id, id_do, wo_number)}
                >
                  <i className="fa fa-shield"></i> Security Approval{" "}
                </Button>{" "}
              </>
            ) : (
              true
            )
          ) : (
            true
          )}
          {!draftStatus ? (
            <WorkOrdersViewImageUpload
              woNumber={wo_number}
              mapId={this.props.mapId}
              idDo={id_do}
              idWo={id}
              isfromWOBuilder={true}
            />
          ) : null}
        </Col>
      </Row>
    );
  };

  viewDepartureWO = () => {
    const {
      wo_number,
      wo_destination_name,
      est_departure_time,
      status_delivery,
      draftStatus,
      status_delivery_detail,
      notes,
      driver_notes,
      price_delivery_tkm,
      id_do,
      id,
    } = this.props;
    return (
      <Row className="container">
        <Col md="12">
          <h6>Work Order Information</h6>
          <dt>WO ID</dt>
          <dd>{id ? id : "-"}</dd>
          <dt>WO Number</dt>
          <dd>{wo_number ? wo_number : "-"}</dd>

          <dt>Departure Location</dt>
          <dd>{wo_destination_name ? wo_destination_name : "-"}</dd>

          <dt>Est. Departure Time</dt>
          <dd>
            {est_departure_time ? this.dateFormatter(est_departure_time) : "-"}
          </dd>

          <br />
          <br />
          <h6>Cost Estimation</h6>
          {/* <dt>Toll Fee</dt><dd>{toll_fee ? this.curStringFormat(toll_fee) : '-'}</dd>
                    <dt>Weight Fee</dt><dd>{weight_fee ? this.curStringFormat(weight_fee) : '-'}</dd>
                    <dt>Other Fee Fee</dt><dd>{other_fee ? this.curStringFormat(other_fee) : '-'}</dd> */}
          <dt>Delivery Fee</dt>
          <dd>
            {price_delivery_tkm
              ? this.curStringFormat(price_delivery_tkm)
              : "-"}
          </dd>

          {draftStatus ? (
            true
          ) : this.props.boolSecurity ? (
            <div>
              <br />
              <h6>Delivery Status</h6>
              <dt>Status</dt>
              <dd>{status_delivery_detail ? status_delivery_detail : "-"}</dd>
              {/* <dt>Security Name</dt><dd>{security_name ? security_name : '-'}</dd> */}
              <dt>Notes</dt>
              <dd style={{ textAlign: "justify" }}>{notes ? notes : "-"}</dd>
              <dt>Driver Notes</dt>
              <dd style={{ textAlign: "justify" }}>
                {driver_notes ? driver_notes : "-"}
              </dd>
            </div>
          ) : (
            true
          )}

          <br />
        </Col>
        <Col md="12">
          {status_delivery === null &&
          !draftStatus &&
          this.props.boolSecurity ? (
            this.checkLevel() ? (
              <>
                <Button
                  className="btn btn-success"
                  color="success"
                  size="md"
                  onClick={() => this.toggleOpenBind(id, id_do, wo_number)}
                >
                  <i className="fa fa-shield"></i> Security Approval
                </Button>{" "}
              </>
            ) : (
              true
            )
          ) : (
            true
          )}
          {!draftStatus ? (
            <WorkOrdersViewImageUpload
              woNumber={wo_number}
              mapId={this.props.mapId}
              idDo={id_do}
              idWo={id}
              isfromWOBuilder={true}
            />
          ) : null}
        </Col>
        <br />
      </Row>
    );
  };

  viewWaypointWO = () => {
    const {
      id,
      wo_number,
      wo_seq,
      wo_destination_name,
      wo_address,
      cargo_weight,
      wo_desc,
      pic_name,
      pic_phone,
      approval_time,
      goals_type,
      est_arrival_time,
      status_delivery,
      draftStatus,
      status_delivery_detail,
      notes,
      est_stop_time,
      break_time,
      est_departure_time,
      loading_time,
      distance,
      main_customer,
      main_customer_phone,
      customer_approval_name,
      customer_approval_remarks,
      customer_approval_time,
      company,
      company_phone,
      status_customer_approval_detail,
      updateWO,
      id_do,
      driver_notes,
      id_request_wo_3pl,
      // price_delivery_tkm,
      est_carbon,
      est_cost,
      loc_name,
      lat,
      lng,
      dept_lat,
      dept_lng,
      id_stocker_outbound,
      id_stocker_outbound_saved,
      invoice_id,
      id_bid,
      id_inbound_stocker,
      id_stocker_inbound_saved,
      inboundItem,
      outboundItem,
      do_number,
      driver_name,
      fleet_plate,
      company_requestor,
      non_company_requestor_name,
    } = this.props;
    const { stockerItems } = this.state;
    return (
      <Row className="container">
        <Col md="12">
          <h6>Work Order Information</h6>
          <dt>WO ID</dt>
          <dd>{id ? id : "-"}</dd>
          <dt>WO Number</dt>
          <dd>{wo_number ? wo_number : "-"}</dd>
          <dt>Description</dt>
          <dd style={{ textAlign: "justify" }}>{wo_desc ? wo_desc : "-"}</dd>
          <dt>Goals Type</dt>
          <dd>{goals_type ? goals_type : "-"}</dd>
          <dt>Waypoint Location</dt>
          <dd>{wo_destination_name ? wo_destination_name : "-"}</dd>
          <dt>Cargo Weight</dt>
          <dd>{cargo_weight ? cargo_weight + " Kg" : "-"}</dd>
          <dt>PIC Name</dt>
          <dd>{pic_name ? pic_name : "-"}</dd>
          <dt>PIC Phone</dt>
          <dd>{pic_phone ? pic_phone : "-"}</dd>
          <dt>Est. Arrival Time</dt>
          <dd>{est_arrival_time ? est_arrival_time : "-"}</dd>
          <dt>Est. Stop Time</dt>
          <dd>{est_stop_time ? est_stop_time + " min" : "0 min"}</dd>
          <dt>Break Time</dt>
          <dd>{break_time ? break_time + " min" : "0 min"}</dd>
          <br />
          <br />
          <h6>Cost Estimation</h6>
          {/* <dt>Toll Fee</dt><dd>{toll_fee ? this.curStringFormat(toll_fee) : '-'}</dd>
                    <dt>Weight Fee</dt><dd>{weight_fee ? this.curStringFormat(weight_fee) : '-'}</dd>
                    <dt>Other Fee Fee</dt><dd>{other_fee ? this.curStringFormat(other_fee) : '-'}</dd> */}
          {/* <dt>Delivery Fee</dt><dd>{price_delivery_tkm ? this.curStringFormat(price_delivery_tkm) : '-'}</dd> */}
          <dt>Est. Delivery Cost</dt>
          <dd>{est_cost ? this.curStringFormat(est_cost) : "-"}</dd>
          <br />
          <br />
          <h6>Carbon Emission</h6>
          <dt>Est. Carbon</dt>
          <dd>
            {est_carbon ? est_carbon.toLocaleString() + " Kg CO2" : "-"}
          </dd>{" "}
          <br />
          {draftStatus ? (
            true
          ) : this.props.boolSecurity ? (
            <div>
              <br />
              <h6>Company Delivery Status</h6>
              <dt>Status</dt>
              <dd>{status_delivery_detail ? status_delivery_detail : "-"}</dd>

              <dt>Approval Time</dt>
              <dd>
                {approval_time
                  ? moment(approval_time).format("dddd, DD MMM YYYY HH:mm:ss")
                  : "-"}
              </dd>

              {/* <dt>Security Name</dt><dd>{security_name ? security_name : '-'}</dd> */}
              <dt>Notes</dt>
              <dd style={{ textAlign: "justify" }}>{notes ? notes : "-"}</dd>
              <dt>Driver Notes</dt>
              <dd style={{ textAlign: "justify" }}>
                {driver_notes ? driver_notes : "-"}
              </dd>
            </div>
          ) : (
            true
          )}
          {draftStatus === false ? (
            <div>
              <br />
              <h6>Customer Delivery Status</h6>
              <dt>Customer Approval Name</dt>
              <dd>{customer_approval_name ? customer_approval_name : "-"}</dd>

              <dt>Status Delivery Customer</dt>
              <dd>
                {status_customer_approval_detail
                  ? status_customer_approval_detail
                  : "-"}
              </dd>

              <dt>Customer Delivery Remarks</dt>
              <dd>
                {customer_approval_remarks ? customer_approval_remarks : "-"}
              </dd>

              <dt>Customer Approval Time</dt>
              <dd>
                {customer_approval_time
                  ? moment(customer_approval_time).format(
                      "dddd, DD MMM YYYY HH:mm:ss"
                    )
                  : "-"}
              </dd>
            </div>
          ) : (
            true
          )}
          <br />
          <Row style={{ marginLeft: "0px" }}>
            {status_delivery === null &&
            !draftStatus &&
            this.props.boolSecurity ? (
              this.checkLevel() ? (
                <>
                  <Button
                    className="btn btn-success"
                    color="success"
                    size="md"
                    onClick={() => this.toggleOpenBind(id, id_do, wo_number)}
                  >
                    <i className="fa fa-shield"></i> Security Approval
                  </Button>
                  <span>&nbsp;</span>
                </>
              ) : (
                true
              )
            ) : (
              true
            )}
            {status_delivery !== null &&
            !draftStatus &&
            this.props.boolSecurity ? (
              this.checkSuperAdminForReset() ? (
                <>
                  <WorkOrderResetSecurityApproval
                    id={id}
                    id_do={id_do}
                    wo_number={wo_number}
                    updateWO={updateWO}
                  />
                  <span>&nbsp;</span>
                </>
              ) : (
                true
              )
            ) : (
              true
            )}
            {/* Print WO Waypoint */}
            {!draftStatus &&
            this.context.can(
              "view",
              "work_orders_new_settings@work_orders_new"
            ) ? (
              <>
                <Button
                  onClick={async () => {
                    // const props = await getProps();
                    const loginCreds = JSON.parse(
                      window.atob(localStorage.Automalt)
                    );
                    const auth = loginCreds.token;
                    const res = await axios({
                      method: "post",
                      url: API_LINKS.WORK_ORDER_QR_PRINT_URL, //Please change when it's ready
                      headers: {
                        Authorization: auth,
                      },
                      data: {
                        id_work_order: id,
                        id_delivery_order: id_do,
                      },
                    }).then((response) => {
                      return response.data.result.token;
                    });

                    const qrCodeURI = await QRCode.toDataURL(
                      `https://track.automa.id/#/wo/verification/${res}`,
                      { type: "png" }
                    );

                    const doc = (
                      <PrintableSingleWorkOrder
                        do_number={do_number}
                        driver_name={driver_name}
                        fleet_plate={fleet_plate}
                        company={company}
                        company_phone={company_phone}
                        id={id}
                        wo_number={wo_number}
                        wo_seq={wo_seq}
                        wo_desc={wo_desc}
                        wo_destination_name={wo_destination_name}
                        wo_address={wo_address}
                        pic_name={pic_name}
                        pic_phone={pic_phone}
                        loc_name={loc_name}
                        lat={lat}
                        lng={lng}
                        dept_lat={dept_lat}
                        dept_lng={dept_lng}
                        est_departure_time={est_departure_time}
                        est_arrival_time={est_arrival_time}
                        cargo_weight={cargo_weight}
                        loading_time={loading_time}
                        distance={distance}
                        main_customer={main_customer}
                        main_customer_phone={main_customer_phone}
                        id_request_wo_3pl={id_request_wo_3pl}
                        id_inbound_stocker={id_inbound_stocker}
                        id_stocker_outbound_saved={id_stocker_outbound_saved}
                        stocker_items={outboundItem}
                        token={res}
                        tokenDataURI={qrCodeURI}
                        company_requestor={company_requestor}
                        non_company_requestor_name={non_company_requestor_name}
                      />
                    );
                    const asPdf = pdf([]); // {} is important, throws without an argument
                    asPdf.updateContainer(doc);
                    const blob = await asPdf.toBlob();
                    var csvURL = window.URL.createObjectURL(blob);
                    var tempLink = document.createElement('a');
                    tempLink.href = csvURL;
                    tempLink.setAttribute('download', this.makeDocumentName(company, wo_number));
                    tempLink.click();
                  }}
                  size="sm"
                  color="success"
                >
                  <i className="fa fa-print"></i>&nbsp; Print WO
                </Button>
                &nbsp;
              </>
            ) : (
              true
            )}
            {!draftStatus ? (
              <WorkOrdersViewImageUpload
                woNumber={wo_number}
                mapId={this.props.mapId}
                idDo={id_do}
                idWo={id}
                isfromWOBuilder={true}
                id_request_wo_3pl={id_request_wo_3pl}
              />
            ) : null}
            {!draftStatus &&
            (id_stocker_outbound_saved || id_stocker_inbound_saved) ? (
              <>
                &nbsp;
                <CheckOutboundItem
                  showCheckItemTable={this.showCheckItemTable}
                  size="sm"
                />
                <CheckOutboundItemTable
                  showTable={this.state.showCheckItemTable}
                  idInboundStocker={id_stocker_inbound_saved}
                  idOutboundStocker={id_stocker_outbound_saved}
                  inboundItem={inboundItem}
                  outboundItem={outboundItem}
                />
              </>
            ) : null}
            {!draftStatus && invoice_id && this.checkSuperAdminForReset() ? (
              <>
                &nbsp;
                <WorkOrderSubmitForReview woNumber={wo_number} bidId={id_bid} />
              </>
            ) : null}
          </Row>
          <br />
          {id_stocker_outbound ? (
            <div>
              {this.handleCheckStockerOutbound()}
              <h6>Stocker Item</h6>
              <hr />
              {stockerItems.length > 0 ? (
                <div>
                  {stockerItems.map((item, index) => {
                    return (
                      <div>
                        <StockerItemDetail
                          key={index}
                          id={item.id}
                          id_master_outbound={item.id_master_outbound}
                          outbound_number={item.outbound_number}
                          document_number={item.document_number}
                          id_outbound_detail={item.id_outbound_detail}
                          awb_number={item.awb_number}
                          id_master_item={item.id_master_item}
                          item_name={item.item_name}
                          sku_item_name={item.sku_item_name}
                          expected_qty={item.expected_qty}
                          realization_qty={item.realization_qty}
                          rejected_qty={item.rejected_qty}
                          batch_number={item.batch_number}
                          production_date={item.production_date}
                          expired_date={item.expired_date}
                          id_receiver={item.id_receiver}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-muted">item not found</div>
              )}
            </div>
          ) : (
            true
          )}
        </Col>
      </Row>
    );
  };

  makeDocumentName = (companyName, woNumber) => {
    return `Work Order ${companyName} - ${woNumber}.pdf`;
  };

  formatTime = (_time) => {
    return moment(_time).format("HH:mm");
  };

  getPodAvail = (podAvail) => {
    this.setState({
      podAvail: podAvail,
    });
  };

  viewBindSecurity = (id, id_do, wo_number) => {
    const { deliveryStatusListData, deliveryStatusApproveOnlyData } =
      this.props;
    return (
      <>
        <h5>Proof of Delivery</h5>
        <WorkOrdersViewImageUploadNoButton
          woNumber={wo_number}
          idDo={id_do}
          idWo={id}
          isfromWOBuilder={true}
          getPodAvail={this.getPodAvail}
          closeModal={this.toggleCloseBind}
          refreshModal={this.getPodAvail}
        />
        <hr />
        {this.state.podAvail ||
        !this.context.can("view", "pod_upload@pod_button") ? (
          <Formik
            initialValues={this.state.initialApprovalSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              var self = this;
              var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
              var auth = loginCreds.token;
              setSubmitting(true);

              axios({
                method: "post",
                url: API_LINKS.DELIVERY_APPROVE_URL,
                headers: {
                  Authorization: auth,
                },
                data: {
                  id_delivery_order: self.props.id_do,
                  id_work_order: self.props.id,
                  status_delivery: values.status_delivery.value,
                  notes: values.notes,
                  notes_for_driver: values.driver_notes,
                },
              })
                .then((response) => {
                  if (response.status === 200) {
                    if (response.data.status === "success") {
                      setSubmitting(false);
                      toast.success("DO Delivery status updated succesfully!");
                      self.toggleCloseBind();
                      resetForm();
                      self.props.updateWO(self.props.id_do);
                    }
                  }
                })
                .catch(function (error) {
                  if (error.response) {
                    if (error.response.status === 401) {
                      setSubmitting(false);
                      return toast.error(
                        "Invalid credentials! Please try again!"
                      );
                    } else if (error.response.status === 403) {
                      setSubmitting(false);
                      return toast.error(
                        "Unauthorized Access! Please try to logout.."
                      );
                    } else if (error.response.status === 400) {
                      setSubmitting(false);
                      return toast.error(error.response.data.message);
                    } else if (
                      error.response.status === 404 ||
                      error.response.status === 500
                    ) {
                      setSubmitting(false);
                      return toast.error(
                        "Server cannot be contacted! Please ask your system administrator!"
                      );
                    } else {
                      setSubmitting(false);
                      return toast.error(
                        "Something went wrong... Please try again later..."
                      );
                    }
                  } else if (error.request) {
                    setSubmitting(false);
                    return toast.error(
                      "Request error! Please try again later..."
                    );
                  } else {
                    setSubmitting(false);
                    return toast.error(
                      "Something went wrong... Please try again later..."
                    );
                  }
                });
            }}
            validationSchema={validationBindSchema}
            render={({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              handleBlur,
              handleChange,
              // handleReset,
              // setTouched,
              isValid,
              isSubmitting,
            }) => (
              <form>
                <h5>Update status for {this.props.wo_number}</h5>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Status Delivery</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <SelectField
                      id="status_delivery"
                      name="status_delivery"
                      label="status_delivery"
                      placeholder="Select status"
                      options={
                        this.props.wo_seq !== 0 && this.props.wo_seq !== 9989
                          ? deliveryStatusListData.result
                          : deliveryStatusApproveOnlyData
                      }
                      value={values.status_delivery}
                      isMulti={false}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      touched={touched.status_delivery}
                      error={errors.status_delivery}
                      isClearable={true}
                      backspaceRemovesValue={true}
                      disabled={isSubmitting}
                    />
                  </Col>
                  {/* <FormFeedback>{errors.location}</FormFeedback> */}
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input"> Customer Notes</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="textarea"
                      name="notes"
                      id="notes"
                      placeholder="Notes"
                      valid={!errors.notes}
                      invalid={touched.notes && !!errors.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.notes}
                      disabled={isSubmitting}
                    />
                    <FormFeedback>{errors.notes}</FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Driver Notes</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="textarea"
                      name="driver_notes"
                      id="driver_notes"
                      placeholder="Driver Notes"
                      valid={!errors.driver_notes}
                      invalid={touched.driver_notes && !!errors.driver_notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.driver_notes}
                      disabled={isSubmitting}
                    />
                    <FormFeedback>{errors.driver_notes}</FormFeedback>
                  </Col>
                </FormGroup>

                <FormGroup>
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    color="primary"
                    className="mr-1"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? "Wait..." : "Update Status"}
                  </Button>
                  <Button
                    type="button"
                    color="warning"
                    className="mr-1"
                    disabled={isSubmitting}
                    onClick={this.toggleCloseBind}
                  >
                    Close
                  </Button>
                  {/* <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button> */}
                </FormGroup>
              </form>
            )}
          />
        ) : null}
      </>
    );
  };

  viewEditWO = () => {
    return (
      <Formik
        initialValues={this.state.initialEditValues}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          var self = this;
          var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
          var auth = loginCreds.token;
          setSubmitting(true);

          axios({
            method: "post",
            url: API_LINKS.WORK_ORDERS_DRAFT_EDIT_URL,
            headers: {
              Authorization: auth,
            },
            data: {
              id_work_order_draft: values.id_wo,
              id_delivery_order: values.id_do,
              wo_seq: values.wo_seq,
              est_stop_time: values.est_stop_time,
              break_time: values.break_time,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                if (response.data.status === "success") {
                  setSubmitting(false);
                  toast.success("Work order edited succesfully!");
                  self.toggleCloseEdit();
                  resetForm();
                  self.props.updateWO(self.props.id_do);
                }
              }
            })
            .catch(function (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  setSubmitting(false);
                  return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 403) {
                  setSubmitting(false);
                  return toast.error(
                    "Unauthorized Access! Please try to logout.."
                  );
                } else if (error.response.status === 400) {
                  setSubmitting(false);
                  return toast.error(error.response.data.message);
                } else if (
                  error.response.status === 404 ||
                  error.response.status === 500
                ) {
                  setSubmitting(false);
                  return toast.error(
                    "Server cannot be contacted! Please ask your system administrator!"
                  );
                } else {
                  setSubmitting(false);
                  return toast.error(
                    "Something went wrong... Please try again later..."
                  );
                }
              } else if (error.request) {
                setSubmitting(false);
                return toast.error("Request error! Please try again later...");
              } else {
                setSubmitting(false);
                return toast.error(
                  "Something went wrong... Please try again later..."
                );
              }
            });
        }}
        validationSchema={validationEditSchema}
        render={({
          values,
          errors,
          touched,
          // setFieldValue,
          // setFieldTouched,
          handleSubmit,
          handleBlur,
          handleChange,
          // handleReset,
          // setTouched,
          // isValid,
          isSubmitting,
        }) => (
          <form>
            <FormGroup row>
              <Col md="12">
                <Label htmlFor="text-input">
                  Your work order number is <strong>{values.wo_number}</strong>
                </Label>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">WO Sequence</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="number"
                  name="wo_seq"
                  id="wo_seq"
                  placeholder="WO Sequence (i.e. 1)"
                  valid={!errors.wo_seq}
                  invalid={touched.wo_seq && !!errors.wo_seq}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.wo_seq}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.wo_seq}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Est Stop Time (in Min)</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="number"
                  name="est_stop_time"
                  id="est_stop_time"
                  placeholder="Cargo Weight"
                  valid={!errors.est_stop_time}
                  invalid={touched.est_stop_time && !!errors.est_stop_time}
                  autoFocus={true}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.est_stop_time}
                />
                <FormFeedback>{errors.est_stop_time}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="text-input">Break Time (in Min)</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="number"
                  name="break_time"
                  id="break_time"
                  placeholder="Cargo Weight"
                  valid={!errors.break_time}
                  invalid={touched.break_time && !!errors.break_time}
                  autoFocus={true}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.break_time}
                />
                <FormFeedback>{errors.break_time}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="12">
                <Label htmlFor="text-input">
                  Are you sure to change the details of this work order?
                </Label>
              </Col>
            </FormGroup>

            <FormGroup>
              <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="mr-1"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Editing..." : "Edit"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={isSubmitting}
                onClick={this.toggleCloseEdit}
              >
                Close
              </Button>
              {/* <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button> */}
            </FormGroup>
          </form>
        )}
      />
    );
  };

  viewDeleteWO = () => {
    return (
      <Formik
        initialValues={this.state.initialDeleteValues}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          var self = this;
          var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
          var auth = loginCreds.token;
          setSubmitting(true);

          axios({
            method: "post",
            url: API_LINKS.WORK_ORDERS_DELETE_URL,
            headers: {
              Authorization: auth,
            },
            data: {
              id_work_order_draft: self.props.id,
              id_delivery_order: self.props.id_do,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                if (response.data.status === "success") {
                  setSubmitting(false);
                  toast.success("Work order deleted succesfully!");
                  self.toggleCloseDelete();
                  resetForm();
                  self.props.updateWO(self.props.id_do);
                }
              }
            })
            .catch(function (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  setSubmitting(false);
                  return toast.error("Invalid credentials! Please try again!");
                } else if (error.response.status === 403) {
                  setSubmitting(false);
                  return toast.error(
                    "Unauthorized Access! Please try to logout.."
                  );
                } else if (error.response.status === 400) {
                  setSubmitting(false);
                  return toast.error(error.response.data.message);
                } else if (
                  error.response.status === 404 ||
                  error.response.status === 500
                ) {
                  setSubmitting(false);
                  return toast.error(
                    "Server cannot be contacted! Please ask your system administrator!"
                  );
                } else {
                  setSubmitting(false);
                  return toast.error(
                    "Something went wrong... Please try again later..."
                  );
                }
              } else if (error.request) {
                setSubmitting(false);
                return toast.error("Request error! Please try again later...");
              } else {
                setSubmitting(false);
                return toast.error(
                  "Something went wrong... Please try again later..."
                );
              }
            });
        }}
        validationSchema={validationDeleteSchema}
        render={({
          // values,
          // errors,
          // touched,
          // setFieldValue,
          // setFieldTouched,
          handleSubmit,
          // handleBlur,
          // handleChange,
          // handleReset,
          // setTouched,
          // isValid,
          isSubmitting,
        }) => (
          <form>
            <FormGroup row>
              <Col md="12">
                <Label htmlFor="text-input">
                  Are you sure to delete this work order?{" "}
                  <strong>This action cannot be undone!</strong>
                </Label>
              </Col>
            </FormGroup>

            <FormGroup>
              <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="mr-1"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Deleting..." : "Delete"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={isSubmitting}
                onClick={this.toggleCloseDelete}
              >
                Close
              </Button>
              {/* <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button> */}
            </FormGroup>
          </form>
        )}
      />
    );
  };

  WOBadgeFactory = (props) => {
    const {
      id_request_wo_3pl,
      id_stocker_outbound_saved,
      id_stocker_inbound_saved,
    } = props.data;
    if (id_request_wo_3pl)
      return (
        <h5>
          <Badge color="primary">3PL</Badge>
        </h5>
      );
    if (id_stocker_outbound_saved)
      return (
        <h5>
          <Badge color="info" style={{ color: "white" }}>
            Stocker Outbound
          </Badge>
        </h5>
      );
    if (id_stocker_inbound_saved)
      return (
        <h5>
          <Badge color="info" style={{ color: "white" }}>
            Stocker Inbound
          </Badge>
        </h5>
      );
    return null;
  };

  render() {
    const {
      id,
      // id_do,
      wo_number,
      wo_seq,
      wo_destination_name,
      est_arrival_time,
      draftStatus,
      est_departure_time,
    } = this.props;
    const { bindId, bindIdDO, bindWoNumber } = this.state;
    return (
      <div>
        <Card key={id}>
          <CardHeader style={{ backgroundColor: this.colorHeader(wo_seq) }}>
            <div style={{ display: "inline-flex" }}>
              <p>{id} -&nbsp;</p>
            </div>
            <div style={{ display: "inline-block" }}>
              <this.WOBadgeFactory data={this.props} />{" "}
            </div>
            <span>
              {" "}
              <strong>
                {" "}
                {this.checkWOType(wo_seq) +
                  " - " +
                  wo_number +
                  " - " +
                  wo_destination_name +
                  (this.checkWOTypeTrue(wo_seq)
                    ? " - [" + wo_seq + "]"
                    : "")}{" "}
              </strong>
            </span>
            <div className="card-header-actions">
              {wo_seq === 0
                ? est_departure_time
                  ? this.formatTime(est_departure_time)
                  : ""
                : est_arrival_time
                ? this.formatTime(est_arrival_time)
                : ""}{" "}
              &nbsp;
              {/* Editing Section */}
              {/* eslint-disable-next-line */}
              {draftStatus &&
              this.checkAdmin &&
              this.checkWOTypeTrue(wo_seq) ? (
                <span
                  style={{cursor:"pointer"}}
                  className="card-header-action btn btn-minimize"
                  onClick={this.toggleOpenEdit}
                >
                  <i className="fa fa-edit"></i>
                </span>
              ) : (
                ""
              )}
              {/* Delete Section */}
              {/* eslint-disable-next-line */}
              {draftStatus && this.checkAdmin ? (
                <span
                  style={{cursor:"pointer"}}
                  className="card-header-action btn btn-minimize"
                  onClick={this.toggleOpenDelete}
                >
                  <i className="fa fa-trash"></i>
                </span>
              ) : (
                true
              )}
              {/* eslint-disable-next-line */}
              <a
                className="card-header-action btn btn-minimize"
                data-target="#collapseExample"
                onClick={this.toggle}
              >
                {this.state.collapse ? (
                  <i className="icon-arrow-up"></i>
                ) : (
                  <i className="icon-arrow-down"></i>
                )}
              </a>
            </div>
          </CardHeader>
          <Collapse isOpen={this.state.collapse} id="collapseExample">
            <CardBody>{this.viewWOType(wo_seq)}</CardBody>
          </Collapse>
        </Card>

        {/* Modal for Approval Security */}
        <Modal
          isOpen={this.state.modalBind}
          toggle={this.toggleOpenBind}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggleCloseBind}>
            DO Approval for : {this.props.wo_number}
          </ModalHeader>
          <ModalBody>
            {this.viewBindSecurity(bindId, bindIdDO, bindWoNumber)}
          </ModalBody>
        </Modal>

        {/* Modal for Delete WO */}
        <Modal
          isOpen={this.state.modalDelete}
          toggle={this.toggleOpenDelete}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseDelete}>
            Delete {wo_number}?
          </ModalHeader>
          <ModalBody>{this.viewDeleteWO()}</ModalBody>
        </Modal>

        {/* Modal for Delete WO */}
        <Modal
          isOpen={this.state.modalEdit}
          toggle={this.toggleOpenEdit}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCloseEdit}>
            Change WO details for {wo_number}?
          </ModalHeader>
          <ModalBody>{this.viewEditWO()}</ModalBody>
        </Modal>
      </div>
    );
  }
}

WorkOrderWidget.propTypes = propTypes;
WorkOrderWidget.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    // Delivery Status
    deliveryStatusListData:
      state.deliveryStatusListStore.deliveryStatusListData,
    deliveryStatusApproveOnlyData:
      state.deliveryStatusListStore.deliveryStatusApproveOnlyData,
    inProgressDelivery: state.deliveryStatusListStore.inProgress,
    successDeliveryStatus: state.deliveryStatusListStore.success,
  };
};

WorkOrderWidget.contextType = AbilityContext;
export default connect(mapStateToProps, { fetchDeliveryStatusList })(
  WorkOrderWidget
);

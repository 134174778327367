import React from 'react';

import { Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import moment from 'moment';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: './assets/fonts/Roboto-Regular.ttf',
    },
    {
      fontStyle: 'bold',
      src: './assets/fonts/Roboto-Bold.ttf',
    },
    {
      fontStyle: 'bolditalic',
      src: './assets/fonts/Roboto-BoldItalic.ttf',
    },
    {
      fontStyle: 'black',
      src: './assets/fonts/Roboto-Black.ttf',
    },
],
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'stretch',
    margin: 5
  },
  invoiceToColumn: {
    flexDirection: 'column',
    flexGrow: 7,
    marginLeft: 20,
    marginTop: 5
  },
  woToColumn: {
    flexDirection: 'column',
    flexGrow: 7,
    marginLeft: 10,
    marginTop: 5
  },
  headerDetail: {
    fontSize: '11',
    fontFamily: 'Roboto',
    fontStyle: 'bold'
  },
  informationDetail: {
    fontSize: '10',
    fontFamily: 'Roboto',
  },
  receiptHeaderWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 0
  },    
  receiptHeader: {
    fontSize: '14',
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    textAlign: 'center'
  },
  tableWrapper: {
    flexGrow: 1,
    flexDirection: 'row',
    margin: 30
  },
  tablehandle: {
    width: '100%'
  },
  table: {
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColNumber: { 
    width: "15%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 
  tableColQr: { 
    width: "50%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableColFleetDetail: { 
    width: "50%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableColAmount: { 
    width: "25%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: { 
    // margin: "auto", 
    margin: 5,
    textAlign: 'center',
    fontSize: 10,
    fontStyle: 'bold',
    fontFamily: 'Roboto'
  },
  tableColFleetDetailSpacing:{
    marginTop: 30,
  },
  tableColFleetDetailSpacingAdd:{
    marginTop: 100,
  },
  tableColFleetDetailSpacingAdd2:{
    marginTop: 160,
  },
  tableCell: { 
    // margin: "auto", 
    margin: 5,
    paddingLeft:"70px",
    fontFamily: 'Roboto',
    fontSize: 10 
  },
  tableCellBold: { 
    // margin: "auto", 
    margin: 5,
    paddingLeft:"70px",
    marginBottom: 0,
    marginTop: 3,
    fontSize: 10,
    fontFamily: 'Roboto',
    fontStyle: 'bold'
  },
  tableColPaymentChannel: { 
    width: "30%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableColPaymentTime: { 
    width: "25%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableColPaymentStatus: { 
    width: "75%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  paymentSuccess: {
    // margin: "auto", 
    margin: 5,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    fontSize: 10,
    color: '#22bb33'
  },
  paymentFailed: {
    // margin: "auto", 
    margin: 5,
    fontFamily: 'Roboto',
    fontStyle: 'bold',
    fontSize: 10,
    color: '#bb2124'
  },
  footer: {
    fontSize: 7,
    marginTop: 20,
    marginBottom: 10,
    textAlign: 'center',
    color: 'grey',
  },
  qrCodeImage: {
    width: '240px',
    height: 'auto',
    paddingLeft:"30px"
  },
  qrColumn: {
    flexDirection: 'column',
    flexGrow: 7,
    marginLeft: 20,
    marginTop: 10
  },
});

// function countCost(data){
//   let result = 0
//   data.forEach(d => {
//       result = result + d.cost
//   })
//   return result
// }

export default (props) => (
    <View>
        {/* <View style={styles.container}> */}
            {/* <View style={styles.invoiceToColumn}>
                <Text style={styles.headerDetail}>Fleet(s) Qr</Text>
                <Text style={styles.informationDetail}>{props.company_requestor ? props.company_requestor : 'Invalid Company'}</Text>
            </View> */}
            {/* <View style={styles.woToColumn}>
                <Text style={styles.headerDetail}>Workshop Detail:</Text>
            <Text style={styles.informationDetail}>{props.workshop_name ? props.workshop_name : 'Invalid Transporter Company'}</Text>
            <Text style={styles.informationDetail}>{props.workshop_phone ? props.workshop_phone : 'Invalid Phone'}</Text> 
            </View> */}
        {/* </View> */}
        {/* <View style={styles.receiptHeaderWrapper}>
            <Text style={styles.receiptHeader}> </Text>
        </View>
        <View style={styles.receiptHeaderWrapper}>
            <Text style={styles.receiptHeader}>Receipt Order</Text>
        </View> */}
        <View style={styles.tableWrapper}>
            <View style={styles.table}>
                {/* Table Header */}
                <View style={styles.tableRow}>
                    <View style={styles.tableColQr}>
                        <Text style={styles.tableCellHeader}>QR</Text>
                    </View>
                    <View style={styles.tableColFleetDetail}>
                        <Text style={styles.tableCellHeader}>Fleet Profile</Text>
                    </View>
                </View>
                {/* Content of the Table */}
                {props.fleets.map((fleet, index) => (
                    <View style={styles.tableRow}>
{/* 
                        <View style={styles.tableColNumber}>
                        <Text style={styles.tableCell}>{index+1}</Text>
                        </View> */}
                        <View style={styles.tableColQr}>
                          <Image src={fleet.qr} style={styles.qrCodeImage}/>
                        </View>
                          <>
                          <View style={styles.tableColFleetDetail}>
                              <View style={styles.tableColFleetDetailSpacing}>
                                <Text style={styles.tableCellBold}>Registration Number</Text>
                                <Text style={styles.tableCell}>{fleet.reg_number_with_door}</Text>
                                <Text style={styles.tableCellBold}>VIN</Text>
                                <Text style={styles.tableCell}>{fleet.vehicle_id_number}</Text>
                                <Text style={styles.tableCellBold}>Brand</Text>
                                <Text style={styles.tableCell}>{fleet.car_brands}</Text>
                                <Text style={styles.tableCellBold}>Type</Text>
                                <Text style={styles.tableCell}>{fleet.car_type}</Text>
                                {index+1 === 3?
                                     <View style={styles.tableColFleetDetailSpacingAdd}/> : null
                                }
                                {((index+1)%3) === 0 && (index+1 > 3)?
                                     <View style={styles.tableColFleetDetailSpacingAdd2}/> : null
                                }
                              </View>
                          </View>
                          </>
                      </View>
                  ))}
            </View>
        </View>
        <View style={styles.footer}>
            <Text>~ Generated automatically by system. Powered by automa.id. Printed on {moment().toLocaleString()} by {localStorage.AutomaEml} ~</Text>
          </View>
    </View>
);
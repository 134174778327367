import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import Select from "react-select";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { STORING_TICKET_LIST } from "../../../../../redux/config/incident.links";
import * as yup from "yup";
import { MAINTENANCE_MECHANIC_TASK_INPUT, MAINTENANCE_MECHANIC_TASK_EDIT } from "../../../../../redux/config/storing.links";
import useForm from "../../../../../hooks/useForm";

const TaskAdd = ({
  isEdit = false,
  initialValues = null,
  onReload,
  onClose,
}) => {
  const [gettingIncidentStoringTicket, setGettingIncidentStoringTicket] =
    useState(true);
  const [
    onGetIncidentStoringTicketList,
    {
      loading: loadingIncidentStoringTicketList,
      data: incidentStoringTicketList,
    },
  ] = useLazyFetch({
    url: STORING_TICKET_LIST,
    method: "POST",
  });

  const [onInputTask] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_TASK_INPUT,
    method: "POST",
  });
  
  const [onEditTask] = useLazyFetch({
    url: MAINTENANCE_MECHANIC_TASK_EDIT,
    method: "POST",
  });

  const validation = () => {
    let selectYup = yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable();
    return yup.object().shape({
      incident_storing_ticket: selectYup.required("Mechanic is required!"),
      task_name: yup.string().required("Task Name is required!"),
      desc: yup.string().required("Description is required!"),
    });
  };
  const {
    handleSubmit,
    errors,
    values,
    handleReset,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues: {
      incident_storing_ticket:
      !!initialValues && !!initialValues.id_incident_storing_ticket
        ? {
            value: initialValues.id_incident_storing_ticket,
            label: initialValues.incident_storing_ticket_title,
          }
        : null,
    task_name:
      !!initialValues && !!initialValues.task_name
        ? initialValues.task_name
        : "",
    desc:
      !!initialValues && !!initialValues.desc ? initialValues.desc : "",
    },
    validation: validation(),
  });

  const handleChange = (e) => {
    setFieldValue(e.currentTarget.name, e.currentTarget.value);
  };

  const onSubmit = (values, { setSubmitting }) => {
    if(!isEdit){
      onInputTask(
        {
          body: {
            id_storing_maintenance_mechanic:
              initialValues.id_storing_maintenance_mechanic,
            id_incident_storing_ticket: values.incident_storing_ticket.value,
            task_name: values.task_name,
            desc: values.desc,
          },
        },
        () => {
          setSubmitting(false);
          onReload();
          onClose();
        }
      );
    }
    else{
      onEditTask(
        {
          body: {
            id_storing_maintenance_mechanic:
              initialValues.id_storing_maintenance_mechanic,
            id_incident_storing_ticket: values.incident_storing_ticket.value,
            task_name: values.task_name,
            desc: values.desc,
            id: initialValues.id
          },
        },
        () => {
          setSubmitting(false);
          onReload();
          onClose();
        }
      );
    }
  };

  useEffect(() => {
    if (gettingIncidentStoringTicket) {
      setGettingIncidentStoringTicket(false);
      onGetIncidentStoringTicketList({});
    }
  }, [gettingIncidentStoringTicket, onGetIncidentStoringTicketList]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Incident Storing Ticket</Label>
          </Col>
          <Col xs="12" md="9">
            <LoadingContent
              loading={loadingIncidentStoringTicketList}
              onReload={() => setGettingIncidentStoringTicket(true)}
            >
              <Select
                value={values.incident_storing_ticket}
                onChange={(value) => {
                  setFieldValue("incident_storing_ticket", value);
                }}
                options={(incidentStoringTicketList
                  ? incidentStoringTicketList.result
                  : []
                ).map((item) => ({
                  label: item.title,
                  value: item.id,
                  id_driver: item.driver_fleet_scan_id_driver,
                  driver_name: item.driver_name,
                  id_fleet: item.driver_fleet_scan_id_fleet,
                  fleet_reg_number: item.fleet_reg_number,
                  id_driver_fleet_scan: item.id_driver_fleet_scan,
                }))}
                disabled={isSubmitting}
                placeholder={"Choose incident storing ticket"}
              />
            </LoadingContent>
            <FormFeedback>{errors.incident_storing_ticket}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="task_name">Task Name</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="task_name"
              id="task_name"
              placeholder="Task Name"
              autoComplete="off"
              valid={!errors.task_name}
              invalid={!!errors.task_name}
              onChange={handleChange}
              value={values.task_name || ""}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.task_name}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="desc">Description</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="desc"
              id="desc"
              placeholder="Description"
              autoComplete="off"
              valid={!errors.desc}
              invalid={!!errors.desc}
              onChange={handleChange}
              value={values.desc || ""}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.desc}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            color="primary"
            className="mr-1"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Wait..." : !isEdit ? "Save Changes" : "Edit"}
          </Button>
          <Button
            type="button"
            color="warning"
            className="mr-1"
            disabled={isSubmitting}
            onClick={() => onClose()}
          >
            Close
          </Button>
          <Button
            type="reset"
            color="danger"
            className="mr-1"
            disabled={isSubmitting}
            onClick={handleReset}
          >
            Reset
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

export default TaskAdd;

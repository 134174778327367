import React from "react";
import { Switch, Route } from "react-router-dom";
import { maintenanceRoutes } from "./Maintenance.constants";

const Maintenance = () => {
  return (
    <>
      <Switch>
        {maintenanceRoutes().map(({ render, ...route }, idx) => (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={render}
          />
        ))}
      </Switch>
    </>
  );
};

export default Maintenance;

import React, { Component } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Button,
    Col,
    Label,
    Input,
    FormFeedback,
    Alert
} from 'reactstrap'
import * as Yup from 'yup';
import PropTypes from 'prop-types'
import { Formik } from 'formik';
import { SelectField } from '../../LandLord/Widgets/SelectField';
import { connect } from 'react-redux';
// import Datetime from 'react-datetime';
// import moment from 'moment';
import { BarLoader } from 'react-spinners';
import {toast} from 'react-toastify'
import axios from 'axios';
import * as API_LINKS from '../../../redux/config/links';
import { OUTBOUND_ITEM_DETAIL_LIST_URL, INBOUND_ITEM_DETAIL_LIST_URL } from '../../../redux/config/stck.links';
import { PTH_CUSTOMER_PIC_LIST_URL } from '../../../redux/config/pth.links';
import { fetchSavedPlacesTableByCustomer } from '../../../redux/actions/transporter/aTransporter-SavedPlacesTable';
import {goalsTypeSelections} from '../../../constants/constants'
import { fetchMasterOutbound } from '../../../redux/actions/stocker/stck.masteroutbound.act'
import { fetchMasterInbound } from '../../../redux/actions/stocker/stck.masterinbound.act'
import { fetchPthCustomerByNameList, fetchPthCustomerList} from '../../../redux/actions/pointhub/pth.customer.act'
import { fetchTriPLGroupAssignFind } from '../../../redux/actions/transporter/aTransporter-TriPLGrouping';

// Import for Maps
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    // InfoWindow, 
    KmlLayer
  } from "react-google-maps"
import { compose } from "recompose"
import StockerItemDetail from './StockerItemDetail';

function generateRandom() {
    return Math.random() * 10000000000000000
}

const MapWithAMarker = compose(withScriptjs, withGoogleMap)(props => {
    return (
        <GoogleMap defaultZoom={18} defaultCenter={{ lat: props.companyLat, lng: props.companyLng }} center={{ lat: props.lat, lng: props.lng }}>
            <KmlLayer url={props.kmlLink + "&ver=" + generateRandom()} options={{ preserveViewport: true }}/>
            {
                Array.isArray(props.marker) && props.marker.length === 0?
                true:

                <Marker
                    key={props.value}
                //   onClick={onClick}
                    //   options={{icon: customMarker}}
                    position={{ lat: props.lat, lng: props.lng }}
                >
                </Marker>
            }
        </GoogleMap>
    )
})

var schemaObj = {
    stocker_type: Yup.object()
    .shape({
    label: Yup.string(),
    value: Yup.number()
    })
    .nullable(),
    id_master_outbound: Yup.object()
    .shape({
    label: Yup.string(),
    value: Yup.number()
    })
    .nullable()
    .required("Master Outbound is required"),
    saved_place: Yup.object()
    .shape({
    label: Yup.string(),
    value: Yup.number(),
    lat: Yup.number(),
    lng: Yup.number()
    })
    .nullable()
    .required("Location is required"),
    // id_customer_pic: Yup.object()
    // .shape({
    // label: Yup.string(),
    // value: Yup.number()
    // })
    // .nullable()
    // .required("Customer PIC is required"),
    // cargo_weight: Yup.number()
    // .min(0, 'Value cannot be lower than 0')
    // .required("Cargo weight should be stated!"),
    // pic_name: Yup.string()
    // .notRequired(),
    // pic_phone: Yup.string()
    // .min(7, 'Phone number should not be shorter than 7 characters!')
    // .matches(/^[6]{1}[2]{1}[0-9]{6}/, 'Phone number should be start with 62')
    // .notRequired(),
    wo_desc: Yup.string()
    .notRequired(),
    goals_type: Yup.object()
    .shape({
    label: Yup.string(),
    value: Yup.number()
    })
    .nullable()
    .required("Goals type is required"),
    est_stop_time: Yup.number()
    .min(0, 'Value cannot be lower than 0')
    .required("Est. Stop Time should be stated!"),
    break_time: Yup.number()
    .min(0, 'Value cannot be lower than 0')
    .required("Break time should be stated!"),
}

const propTypes = {
    id_do: PropTypes.any,
    lastSeq: 0,
}

const defaultProps = {
    id_do: 0,
    lastSeq: 0,
}

class WorkOrderAddStockerOutbound extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            modalAdd: false,
            isChecking: false,
            successCheck: false,
            isCheckingCustomer: false,
            successCheckCustomer: false,
            itemData: [],
            custPicData: [],
            validationSchema: Yup.object().shape(schemaObj),
            initialState: {
                stocker_type: {
                    label:"Outbound",
                    value:1,
                },
                id_master_outbound: null,
                saved_place: {},
                id_customer_pic: null,
                wo_desc: '',
                goals_type: null,
                est_stop_time: 30,
                break_time: 0
            },
            isConvertCustomerPicLoad: false
         };
         
    }

    componentDidUpdate(prevProps){
        const {pthCustomersData} = this.props
        if((prevProps.pthCustomersData !== pthCustomersData) && !Array.isArray(pthCustomersData))
        {
            if(pthCustomersData.result.length > 0){
                this.checkCustomerPic(pthCustomersData.result[0].id)
                this.setState({isConvertCustomerPicLoad: true})
            }
        }
    }

    componentWillUnmount(){
        this.setState({
            modalAdd: false,
            isChecking: false,
            successCheck: false,
            isCheckingCustomer: false,
            successCheckCustomer: false,
            itemData: [],
            custPicData: [],
            validationSchema: Yup.object().shape(schemaObj),
            initialState: {
                stocker_type: {
                    label:"Outbound",
                    value:1,
                },
                id_master_outbound: null,
                saved_place: {},
                id_customer_pic: null,
                wo_desc: '',
                goals_type: null,
                est_stop_time: 0,
                break_time: 0
            }
        })
    }

    UNSAFE_componentWillMount = () => {
        this.props.fetchMasterOutbound(true)
        this.props.fetchMasterInbound()
        this.props.fetchPthCustomerList()
    }

    toggleOpen = () => {
        this.setState({
            modalAdd: !this.state.modalAdd
        })
    }

    toggleClose = () => {
        this.setState({
            modalAdd: !this.state.modalAdd
        })
    }


    getSavedPlaceByDOSettings = () => {
        const { selectorAllPfWithCustGroup } = this.props;
        const { doSetting } = this.props;
        if(doSetting.delivery_order_family_type === 1){
            return selectorAllPfWithCustGroup
        }
        return selectorAllPfWithCustGroup
    }

    checkOutboundItem = (id_master_outbound, id_customer, customer_name) => {
        if(id_master_outbound === null || id_master_outbound === undefined || id_master_outbound === 0) {
            return true
        } else {
            this.setState({
                isChecking: true,
            })
            var self = this;
            var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
            var auth = loginCreds.token;

            axios({
                method: 'post',
                url: OUTBOUND_ITEM_DETAIL_LIST_URL,
                headers: {
                    Authorization: auth
                },
                data: {
                    id_master_outbound: id_master_outbound
                }
            })
            .then((response) => {
                if(response.status === 200){
                    if (response.data.status === "success"){
                        self.checkCustomerPic(id_customer)
                        this.props.fetchSavedPlacesTableByCustomer(customer_name)
                        let uniqueResult = []
                        response.data.result.filter(function(item){
                            var i = uniqueResult.findIndex(x => (x.id === item.id));
                            if(i <= -1){
                                uniqueResult.push(item);
                            }
                            return null;
                          });
                        self.setState({
                            isChecking: false,
                            successCheck: true,
                            itemData: uniqueResult
                        })
                    }
                }
            })
            .catch(function(error){
                if (error.response) {
                    if(error.response.status === 401) {
                        self.setState({
                            isChecking: false,
                            successCheck: false,
                            itemData: []
                        })
                        return toast.error("Invalid credentials! Please try again!");
                    } else if (error.response.status === 403) {
                        self.setState({
                            isChecking: false,
                            successCheck: false,
                            itemData: []
                        })
                        return toast.error("Unauthorized Access! Please try to logout..");
                    } else if (error.response.status === 400) {
                        self.setState({
                            isChecking: false,
                            successCheck: false,
                            itemData: []
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        self.setState({
                            isChecking: false,
                            successCheck: false,
                            itemData: []
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else {
                        self.setState({
                            isChecking: false,
                            successCheck: false,
                            itemData: []
                        })
                        return toast.error('Something went wrong... Please try again later...')
                    }
                } else if (error.request) {
                    self.setState({
                        isChecking: false,
                        successCheck: false,
                        itemData: []
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    self.setState({
                        isChecking: false,
                        successCheck: false,
                        itemData: []
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }

    checkInboundItem = (id_master_inbound) => {
        if(id_master_inbound === null || id_master_inbound === undefined || id_master_inbound === 0) {
            return true
        } else {
            this.setState({
                isChecking: true,
            })
            var self = this;
            var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
            var auth = loginCreds.token;

            axios({
                method: 'post',
                url: INBOUND_ITEM_DETAIL_LIST_URL,
                headers: {
                    Authorization: auth
                },
                data: {
                    id_master_inbound: id_master_inbound
                }
            })
            .then((response) => {
                if(response.status === 200){
                    if (response.data.status === "success"){
                        let uniqueResult = []
                        response.data.result.filter(function(item){
                            var i = uniqueResult.findIndex(x => (x.id === item.id));
                            if(i <= -1){
                                uniqueResult.push(item);
                            }
                            return null;
                          });
                        self.setState({
                            isChecking: false,
                            successCheck: true,
                            itemData: uniqueResult
                        })
                    }
                }
            })
            .catch(function(error){
                if (error.response) {
                    if(error.response.status === 401) {
                        self.setState({
                            isChecking: false,
                            successCheck: false,
                            itemData: []
                        })
                        return toast.error("Invalid credentials! Please try again!");
                    } else if (error.response.status === 403) {
                        self.setState({
                            isChecking: false,
                            successCheck: false,
                            itemData: []
                        })
                        return toast.error("Unauthorized Access! Please try to logout..");
                    } else if (error.response.status === 400) {
                        self.setState({
                            isChecking: false,
                            successCheck: false,
                            itemData: []
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        self.setState({
                            isChecking: false,
                            successCheck: false,
                            itemData: []
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else {
                        self.setState({
                            isChecking: false,
                            successCheck: false,
                            itemData: []
                        })
                        return toast.error('Something went wrong... Please try again later...')
                    }
                } else if (error.request) {
                    self.setState({
                        isChecking: false,
                        successCheck: false,
                        itemData: []
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    self.setState({
                        isChecking: false,
                        successCheck: false,
                        itemData: []
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }

    checkCustomerPic = (id_customer) => {
        if(id_customer === null || id_customer === undefined || id_customer === 0) {
            return true
        } else {
            this.setState({
                isCheckingCustomer: true,
            })
            var self = this;
            var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
            var auth = loginCreds.token;

            axios({
                method: 'post',
                url: PTH_CUSTOMER_PIC_LIST_URL,
                headers: {
                    Authorization: auth
                },
                data: {
                    id_customer: id_customer
                }
            })
            .then((response) => {
                if(response.status === 200){
                    if (response.data.status === "success"){
                        self.convertCustomerPic(response.data.result)
                        self.setState({
                            isCheckingCustomer: false,
                            successCheckCustomer: true,
                        })
                    }
                }
            })
            .catch(function(error){
                if (error.response) {
                    if(error.response.status === 401) {
                        self.setState({
                            isCheckingCustomer: false,
                            successCheckCustomer: false,
                            itemData: []
                        })
                        return toast.error("Invalid credentials! Please try again!");
                    } else if (error.response.status === 403) {
                        self.setState({
                            isCheckingCustomer: false,
                            successCheckCustomer: false,
                            itemData: []
                        })
                        return toast.error("Unauthorized Access! Please try to logout..");
                    } else if (error.response.status === 400) {
                        self.setState({
                            isCheckingCustomer: false,
                            successCheckCustomer: false,
                            itemData: []
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        self.setState({
                            isCheckingCustomer: false,
                            successCheckCustomer: false,
                            itemData: []
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else {
                        self.setState({
                            isCheckingCustomer: false,
                            successCheckCustomer: false,
                            itemData: []
                        })
                        return toast.error('Something went wrong... Please try again later...')
                    }
                } else if (error.request) {
                    self.setState({
                        isCheckingCustomer: false,
                        successCheckCustomer: false,
                        itemData: []
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    self.setState({
                        isCheckingCustomer: false,
                        successCheckCustomer: false,
                        itemData: []
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }

    convertCustomerPic = (data) => {
        var list = []
        if(data !== null && data !== undefined && data.length > 0) {
            var _pthCustPicData = data

            _pthCustPicData.forEach((p, index) => {
                var _loc = {
                    'label': _pthCustPicData[index].name + ' (' + _pthCustPicData[index].whatsapp_number + ')',
                    'value': _pthCustPicData[index].id,
                    "id":  _pthCustPicData[index].id,
                    "id_company": _pthCustPicData[index].id_company,
                    "id_customer": _pthCustPicData[index].id_customer,
                    "name": _pthCustPicData[index].name,
                    "email": _pthCustPicData[index].email,
                    "main_phone_number": _pthCustPicData[index].main_phone_number,
                    "whatsapp_number": _pthCustPicData[index].whatsapp_number,
                    "id_pic_position": _pthCustPicData[index].id_pic_position,
                    "customer_company_name": _pthCustPicData[index].customer_company_name,
                    "customer_name": _pthCustPicData[index].customer_name,
                    "customer_address": _pthCustPicData[index].customer_address,
                    "customer_main_phone_number": _pthCustPicData[index].customer_main_phone_number,
                    "customer_main_whatsapp_number": _pthCustPicData[index].customer_main_whatsapp_number,
                }
                list.push(_loc)
            })

            this.setState({
                custPicData: list,
                isConvertCustomerPicLoad: false
            })
        }
    }

    getInputAxiosObjectByType = (type, values) => {
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        var self = this;
        if(type === 1){
            return {
                method: 'post',
                url: API_LINKS.STOCKER_WORK_ORDER_INPUT_URL,
                headers: {
                    Authorization: auth
                },
                data: {
                    id: values.id_master_outbound.value,
                    wo_number: values.id_master_outbound.outbound_number,
                    id_delivery_order: self.props.id_do,
                    wo_seq: self.props.lastSeq,
                    id_saved_place: values.saved_place.value,
                    pic_name: values.id_customer_pic.name,
                    pic_phone: values.id_customer_pic.whatsapp_number,
                    target_type: values.goals_type.value,
                    wo_desc: values.wo_desc,
                    est_stop_time: values.est_stop_time,
                    break_time: values.break_time
                }
            }
        }
        if(type === 2){
            return {
                method: 'post',
                url: API_LINKS.STOCKER_WORK_ORDER_INBOUND_URL,
                headers: {
                    Authorization: auth
                },
                data: {
                    id: values.id_master_inbound.value,
                    wo_number: values.id_master_inbound.inbound_number,
                    id_delivery_order: self.props.id_do,
                    wo_seq: self.props.lastSeq,
                    id_saved_place: values.saved_place.value,
                    pic_name: `${values.id_master_inbound.warehouse_name}, ${values.id_master_inbound.adm_office_name}`,
                    pic_phone: `${values.id_master_inbound.adm_office_main_phone_number}`,
                    target_type: values.goals_type.value,
                    wo_desc: values.wo_desc,
                    est_stop_time: values.est_stop_time,
                    break_time: values.break_time
                }
            }
        }
        if(type === 3){
            return {
                method: 'post',
                url: API_LINKS.STOCKER_WORK_ORDER_INPUT_URL,
                headers: {
                    Authorization: auth
                },
                data: {
                    id: values.id_master_outbound.value,
                    wo_number: values.id_master_outbound.outbound_number,
                    id_delivery_order: self.props.id_do,
                    wo_seq: self.props.lastSeq,
                    id_saved_place: values.saved_place.value,
                    // pic_name: values.id_customer_pic.name,
                    // pic_phone: values.id_customer_pic.whatsapp_number,
                    target_type: values.goals_type.value,
                    wo_desc: values.wo_desc,
                    est_stop_time: values.est_stop_time,
                    break_time: values.break_time
                }
            }
        }
    }
    
    getStockerType = () => {
        if(this.props.authData.result.id_type_company === 4){
            return [
                {label:"Outbound", value:1},
                {label:"Outbound Intercompany", value:3},
                {label:"Inbound", value:2},
            ]
        }
        return [
            {label:"Outbound", value:1},
            {label:"Inbound", value:2},
        ]
    }

    viewAddStockerWO = () => {
        const { stckMasterInboundSelectWCustomerforTriPL, 
            stckMasterOutboundSelectWCustomer, stckMasterOutboundSelectIntercompany,
            pthCustomersSelect, groupingIntercompanyMemberSelect} = this.props;
        const { itemData, custPicData, validationSchema, isConvertCustomerPicLoad } = this.state;
        // var yesterday = moment().subtract( 1, 'day' );
        // var disabledDays = function( current ){
        //     return current.isAfter( yesterday );
        // };
        return (
            <Formik
                initialValues={this.state.initialState}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    var self = this;
                    setSubmitting(true)

                    axios(this.getInputAxiosObjectByType(values.stocker_type.value, values))
                    .then((response) => {
                        if(response.status === 200){
                            if (response.data.status === "success"){
                                setSubmitting(false)
                                toast.success('Stocker Work Order has been saved to draft!')
                                self.toggleClose();
                                resetForm()
                                self.props.updateWO(self.props.id_do);
                            }
                        }
                    })
                    .catch(function(error){
                        if (error.response) {
                            if(error.response.status === 401) {
                                setSubmitting(false)
                                return toast.error("Invalid credentials! Please try again!");
                            } else if (error.response.status === 403) {
                                setSubmitting(false)
                                return toast.error("Unauthorized Access! Please try to logout..");
                            } else if (error.response.status === 400) {
                                setSubmitting(false)
                                return toast.error(error.response.data.message);
                            } else if (error.response.status === 404 || error.response.status === 500) {
                                setSubmitting(false)
                                return toast.error("Server cannot be contacted! Please ask your system administrator!");
                            } else {
                                setSubmitting(false)
                                return toast.error('Something went wrong... Please try again later...')
                            }
                        } else if (error.request) {
                            setSubmitting(false)
                            return toast.error('Request error! Please try again later...')
                        } else {
                            setSubmitting(false)
                            return toast.error('Something went wrong... Please try again later...')
                        }
                    })
                }}
                validationSchema={validationSchema}
                render={({
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                handleSubmit,
                handleBlur,
                handleChange,
                // handleReset,
                // setTouched,
                isValid,
                isSubmitting
                }) => (
                <form>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Stocker Type</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <SelectField
                                id="stocker_type"
                                name="stocker_type"
                                label="stocker_type"
                                placeholder="Select stocker type"
                                searchable
                                options={this.getStockerType()}
                                value={values.stocker_type}
                                isMulti={false}
                                onChange={async (fieldName, selected) => {
                                    setFieldValue(fieldName, selected)
                                    if(selected){
                                    if(selected.value === 1){
                                        schemaObj["id_customer_pic"] = await
                                                    Yup.object()
                                                        .shape({
                                                        label: Yup.string(),
                                                        value: Yup.number()
                                                        })
                                                        .nullable()
                                                        .required("Customer PIC is required")
                                        this.props.fetchPthCustomerList()
                                    }
                                    if((selected.value === 3)){
                                        delete schemaObj["id_master_inbound"]
                                        schemaObj["id_master_outbound"] =  await
                                                        Yup.object()
                                                        .shape({
                                                        label: Yup.string(),
                                                        value: Yup.number()
                                                        })
                                                        .nullable()
                                                        .required("Master Outbound is required")
                                        this.props.fetchTriPLGroupAssignFind(4)
                                    }       
                                    if(selected.value === 2){
                                        delete schemaObj["id_customer_pic"]
                                        delete schemaObj["id_master_outbound"]
                                        schemaObj["id_master_inbound"] =  await
                                                        Yup.object()
                                                            .shape({
                                                            label: Yup.string(),
                                                            value: Yup.number()
                                                            })
                                                            .nullable()
                                                            .required("Master inbound is required")
                                    }
                                    this.setState({
                                        validationSchema: Yup.object().shape(schemaObj)
                                    })
                                    }
                                }}
                                onBlur={setFieldTouched}
                                touched={touched.stocker_type}
                                error={errors.stocker_type}
                                isClearable={false}
                                backspaceRemovesValue={true}
                                disabled={isSubmitting}
                            />
                            
                        </Col>
                        {/* <FormFeedback>{errors.location}</FormFeedback> */}
                    </FormGroup>
                    <hr />
                    {values.stocker_type?<>
                    {values.stocker_type.value === 1?
                    <>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Customer / Receiver</Label>
                        </Col>
                        <Col xs="12" md="9">
                            {this.props.inProgressCustomerAll?
                            <BarLoader color={'#123abc'} loading={true}/>
                            :
                            <SelectField
                                id="id_customer"
                                name="id_customer"
                                label="id_customer"
                                placeholder="Select Receiver"
                                searchable
                                options={pthCustomersSelect}
                                value={values.id_customer}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.id_customer}
                                error={errors.id_customer}
                                isClearable={false}
                                backspaceRemovesValue={true}
                                disabled={isSubmitting}
                            />
                            }
                        </Col>
                        {/* <FormFeedback>{errors.location}</FormFeedback> */}
                    </FormGroup> 
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Stocker Outbound Number</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <SelectField
                                id="id_master_outbound"
                                name="id_master_outbound"
                                label="id_master_outbound"
                                placeholder="Select Stocker -> Work Order"
                                searchable
                                options={values.id_customer? stckMasterOutboundSelectWCustomer.filter(outbound => 
                                    outbound.id_customer === values.id_customer.value
                                ) : []}
                                value={values.id_master_outbound}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.id_master_outbound}
                                error={errors.id_master_outbound}
                                isClearable={false}
                                backspaceRemovesValue={true}
                                disabled={isSubmitting || !values.id_customer}
                            />
                            
                        </Col>
                        {/* <FormFeedback>{errors.location}</FormFeedback> */}
                    </FormGroup> 
                    </>
                    : null}
                    {values.stocker_type.value === 2?
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Stocker Inbound Number</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <SelectField
                                id="id_master_inbound"
                                name="id_master_inbound"
                                label="id_master_inbound"
                                placeholder="Select Stocker -> Work Order"
                                searchable
                                options={stckMasterInboundSelectWCustomerforTriPL}
                                value={values.id_master_inbound}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.id_master_inbound}
                                error={errors.id_master_inbound}
                                isClearable={false}
                                backspaceRemovesValue={true}
                                disabled={isSubmitting}
                            />
                            
                        </Col>
                        {/* <FormFeedback>{errors.location}</FormFeedback> */}
                    </FormGroup> : null}
                    {values.stocker_type.value === 3?
                    <>
                        <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Customer / Receiver</Label>
                        </Col> 
                        <Col xs="12" md="9">
                            {this.props.inProgressCustomerAll?
                            <BarLoader color={'#123abc'} loading={true}/>
                            :
                            <SelectField
                                id="id_receiver"
                                name="id_receiver"
                                label="id_receiver"
                                placeholder="Select Receiver"
                                searchable
                                options={groupingIntercompanyMemberSelect}
                                value={values.id_receiver}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.id_receiver}
                                error={errors.id_receiver}
                                isClearable={false}
                                backspaceRemovesValue={true}
                                disabled={isSubmitting}
                            />
                            }
                        </Col>
                        {/* <FormFeedback>{errors.location}</FormFeedback> */}
                    </FormGroup> 
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Stocker Outbound Intercompany Number</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <SelectField
                                    id="id_master_outbound"
                                    name="id_master_outbound"
                                    label="id_master_outbound"
                                    placeholder="Select Stocker -> Work Order"
                                    searchable
                                    options={values.id_receiver? stckMasterOutboundSelectIntercompany.filter(outbound =>  
                                        outbound.id_company_receiver === values.id_receiver.value
                                    ) : []}
                                    value={values.id_master_outbound}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    touched={touched.id_master_outbound}
                                    error={errors.id_master_outbound}
                                    isClearable={false}
                                    backspaceRemovesValue={true}
                                    disabled={isSubmitting || !values.id_receiver}
                                />
                                
                            </Col>
                            {/* <FormFeedback>{errors.location}</FormFeedback> */}
                        </FormGroup> 
                    </>
                    
                    : null}
                    {
                        values.id_master_outbound && ((values.stocker_type.value === 1) || (values.stocker_type.value === 3))? 
                        <this.OutboundItemDetailList values={values} itemData={itemData} />
                        :
                        null
                    }
                    {
                        values.id_master_inbound && values.stocker_type.value === 2? 
                        <this.InboundItemDetailList values={values} itemData={itemData} errors={errors}/>
                        :
                        null
                    }
                    </>:null}
                    {this.props.savedPlaceInProgress &&
                        <center><BarLoader color={'#123abc'} loading={true}/> 
                            <br /> Loading.... Please wait...
                        </center>
                    }
                    {!this.props.savedPlaceInProgress &&
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Destination Place</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <SelectField
                                id="saved_place"
                                name="saved_place"
                                label="saved_place"
                                placeholder="Select location"
                                options={this.getSavedPlaceByDOSettings()}
                                value={values.saved_place}
                                isMulti={false}
                                onChange={(fieldName, selected) => {
                                    if(selected){
                                        setFieldValue(fieldName, selected)
                                        if(selected.customer_name !== "")
                                            this.props.fetchPthCustomerByNameList(selected.customer_name)
                                    }  
                                }}
                                onBlur={setFieldTouched}
                                touched={touched.saved_place}
                                error={errors.saved_place}
                                isClearable={true}
                                backspaceRemovesValue={true}
                                disabled={isSubmitting}
                            />
                        </Col>
                        <FormFeedback>{errors.saved_place}</FormFeedback>
                    </FormGroup>
                    }
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Destination Plot</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <MapWithAMarker
                                // selectedMarker={this.state.selectedMarker}
                                // markers={this.state.markers}
                                lat={ values.saved_place ? values.saved_place.lat : this.props.companyLat }
                                lng={ values.saved_place ? values.saved_place.lng : this.props.companyLng }
                                // onClick={this.handleClick}
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `200px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                kmlLink={ this.props.statKML? this.props.kml : ''}
                                companyLat={ this.props.companyLat ? this.props.companyLat : 0 }
                                companyLng={ this.props.companyLng ? this.props.companyLng : 0 }
                            />
                        </Col>
                    </FormGroup> 
                    {values.stocker_type && ((values.stocker_type.value === 1) || (values.stocker_type.value === 2))?
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Destination PIC</Label>
                            </Col>
                            {values.stocker_type.value === 1?
                                !isConvertCustomerPicLoad ?
                                    <Col xs="12" md="9">
                                        <SelectField
                                            id="id_customer_pic"
                                            name="id_customer_pic"
                                            label="id_customer_pic"
                                            placeholder="Select Destination PIC -> Stocker"
                                            searchable
                                            options={custPicData}
                                            value={values.id_customer_pic}
                                            isMulti={false}
                                            onChange={setFieldValue}
                                            onBlur={setFieldTouched}
                                            touched={touched.id_customer_pic}
                                            error={errors.id_customer_pic}
                                            isClearable={false}
                                            backspaceRemovesValue={true}
                                            disabled={isSubmitting}
                                        />
                                        
                                    </Col> :
                                    <center><BarLoader color={'#123abc'} loading={true}/> 
                                        <br /> Loading.... Please wait...
                                    </center>
                                    : null
                            }
                            {values.stocker_type.value === 2 && values.id_master_inbound?
                                <Col xs="12" md="9">
                                    {values.id_master_inbound.warehouse_name} , &nbsp;
                                        {values.id_master_inbound.adm_office_name} &nbsp;
                                        ({values.id_master_inbound.adm_office_main_phone_number})
                                </Col> : "-"
                            }
                            {/* <FormFeedback>{errors.location}</FormFeedback> */}
                        </FormGroup>
                    :null}
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Goals Type</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <SelectField
                                id="goals_type"
                                name="goals_type"
                                label="goals_type"
                                placeholder="Select goals type"
                                options={goalsTypeSelections}
                                value={values.goals_type}
                                isMulti={false}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                touched={touched.goals_type}
                                error={errors.goals_type}
                                isClearable={true}
                                backspaceRemovesValue={true}
                                disabled={isSubmitting}
                            />
                        </Col>
                        {/* <FormFeedback>{errors.goals_type}</FormFeedback> */}
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Est Stop Time (in Min)</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="est_stop_time"
                                    id="est_stop_time"
                                    placeholder="Cargo Weight"
                                    valid={!errors.est_stop_time}
                                    invalid={touched.est_stop_time && !!errors.est_stop_time}
                                    autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.est_stop_time} />
                            <FormFeedback>{errors.est_stop_time}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Break Time (in Min)</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="break_time"
                                    id="break_time"
                                    placeholder="Cargo Weight"
                                    valid={!errors.break_time}
                                    invalid={touched.break_time && !!errors.break_time}
                                    autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.break_time} />
                            <FormFeedback>{errors.break_time}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Description</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="textarea"
                                    name="wo_desc"
                                    id="wo_desc"
                                    placeholder="Work Order Description"
                                    autoComplete="work-order-desc"
                                    valid={!errors.wo_desc}
                                    invalid={touched.wo_desc && !!errors.wo_desc}
                                    autoFocus={true}
                                    required
                                    rows="10"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.wo_desc} />
                            <FormFeedback>{errors.wo_desc}</FormFeedback>
                        </Col>
                    </FormGroup>
                    {values.stocker_type?<FormGroup row>
                        <Col xs="12" md="12">
                            <Label htmlFor="text-input"><strong>Please make sure that you input the right wo. Stocker {values.stocker_type.label} Work order will bind to this delivery order. Your action cannot be undone.</strong></Label>
                        </Col>
                    </FormGroup>:null}
                    {
                        isSubmitting ? 
                        <center><BarLoader color={'#123abc'} loading={isSubmitting} /> <br /> Loading.... Please wait...</center> 
                        : 
                        <div>
                            <Button onClick={handleSubmit} size="sm" color="success" disabled={isSubmitting || !isValid}><i className="fa fa-check"></i>{isSubmitting ? 'Wait...' : 'Submit'}</Button>
                            <Button onClick={this.toggleClose} size="sm" color="warning" disabled={isSubmitting || !isValid}><i className="fa fa-window-close"></i> Close</Button>
                        </div>
                    }
                </form>
                )}
            />
        )
    }

    OutboundItemDetailList = (props) => {
        const {values, itemData} = props
        const {isChecking} = this.state
        console.log(values)
        console.log(itemData)
        return(
            <FormGroup row>
            <Col md="12">
                <Alert color="secondary">
                    <div className="text-muted">Here are some details on your Stocker Outbound</div>
                    <hr/>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Stocker Outbound Number</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Label htmlFor="text-input">{values.id_master_outbound.label}</Label>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Sales Order Number</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Label htmlFor="text-input">{values.id_master_outbound.so_number}</Label>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Origin Warehouse</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Label htmlFor="text-input">{values.id_master_outbound.warehouse_name}</Label>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Expected Total Item</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Label htmlFor="text-input">{values.id_master_outbound.expected_total_item}</Label>
                        </Col>
                    </FormGroup>
                    <Button color="success" size="md" disabled={isChecking} onClick={() => this.checkOutboundItem(values.id_master_outbound.id, values.id_master_outbound.id_customer, values.id_master_outbound.customer_name)}><i className="fa fa-check"></i> Check Outbound Item</Button>
                    <br/><br/>
                    
                    {
                        itemData.length > 0 ?
                        <div>
                            <h4>Item Details</h4> <hr/>
                            {
                                itemData.map((item, index) => {
                                    return (
                                        <div>
                                            <StockerItemDetail
                                                key={index}
                                                id={item.id}
                                                id_master_outbound={item.id_master_outbound}
                                                outbound_number={item.outbound_number}
                                                document_number={item.document_number}
                                                id_outbound_detail={item.id_outbound_detail}
                                                awb_number={item.awb_number}
                                                id_master_item={item.id_master_item}
                                                item_name={item.item_name}
                                                sku_item_name={item.sku_item_name}
                                                expected_qty={item.expected_qty}
                                                realization_qty={item.realization_qty}
                                                rejected_qty={item.rejected_qty}
                                                batch_number={item.batch_number}
                                                production_date={item.production_date}
                                                expired_date={item.expired_date}
                                                id_receiver={item.id_receiver}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <div className="text-muted">item not found</div>
                    }
                </Alert>
            </Col>
        </FormGroup>
        )
    }

    InboundItemDetailList = (props) => {
        const {values, itemData} = props
        const {isChecking} = this.state
        return(
            <FormGroup row>
            <Col md="12">
                <Alert color="secondary">
                    <div className="text-muted">Here are some details on your Stocker Inbound</div>
                    <hr/>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Stocker Inbound Number</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Label htmlFor="text-input">{values.id_master_inbound.label}</Label>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Purchase Order Number</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Label htmlFor="text-input">{values.id_master_inbound.po_number}</Label>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Destination Warehouse</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Label htmlFor="text-input">{values.id_master_inbound.warehouse_name}</Label>
                        </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Expected Total Item</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Label htmlFor="text-input">{values.id_master_inbound.expected_total_item}</Label>
                        </Col>
                    </FormGroup> */}
                    <Button color="success" size="md" disabled={isChecking} onClick={() => this.checkInboundItem(values.id_master_inbound.id)}><i className="fa fa-check"></i> Check Inbound Item</Button>
                    <br/><br/>
                    
                    {
                        itemData.length > 0 ?
                        <div>
                            <h4>Item Details</h4> <hr/>
                            {
                                itemData.map((item, index) => {
                                    return (
                                        <div>
                                            <StockerItemDetail
                                                key={index}
                                                id={item.id}
                                                id_master_outbound={item.id_master_outbound}
                                                outbound_number={item.outbound_number}
                                                document_number={item.document_number}
                                                id_outbound_detail={item.id_outbound_detail}
                                                awb_number={item.awb_number}
                                                id_master_item={item.id_master_item}
                                                item_name={item.item_name}
                                                sku_item_name={item.sku_item_name}
                                                expected_qty={item.expected_qty}
                                                realization_qty={item.realization_qty}
                                                rejected_qty={item.rejected_qty}
                                                batch_number={item.batch_number}
                                                production_date={item.production_date}
                                                expired_date={item.expired_date}
                                                id_receiver={item.id_receiver}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <div className="text-muted">item not found</div>
                    }
                </Alert>
            </Col>
        </FormGroup>
        )
    }
    render() {
        return (
            <div>
                {/* <FormGroup> */}
                    {/* <Col md="12"> */}
                        <Button className="btn mr-2" onClick={() => this.toggleOpen()} color="primary">
                            <div className="mr-1 ml-1 mb-1 mt-1">
                                <i className="fa fa-mail-forward"></i>&nbsp;Add Stocker WO
                            </div>
                        </Button>
                    {/* </Col> */}
                {/* </FormGroup> */}
                
                <Modal isOpen={this.state.modalAdd} toggle={this.toggleOpen} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={this.toggleClose}>Add Stocker WO</ModalHeader>
                    <ModalBody>
                            {this.viewAddStockerWO()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

WorkOrderAddStockerOutbound.propTypes = propTypes;
WorkOrderAddStockerOutbound.defaultProps = defaultProps;

const mapStateToProps = (state) => {
    return {
        // Stocker Master Outbound
        stckMasterOutboundSelectDesc: state.stckMasterOutboundStore.stckMasterOutboundSelectDesc,
        inProgressOutbounds: state.stckMasterOutboundStore.inProgress,
        successOutbounds: state.stckMasterOutboundStore.success,
        stckMasterOutboundSelectWCustomer: state.stckMasterOutboundStore.stckMasterOutboundSelectWCustomer,
        stckMasterOutboundSelectIntercompany: state.stckMasterOutboundStore.stckMasterOutboundSelectIntercompany,

        stckMasterInboundSelectWCustomerforTriPL: state.stckMainInboundStore.stckMasterInboundSelectWCustomerforTriPL,
        inProgressInbound: state.stckMainInboundStore.inProgress,
        successInbound: state.stckMainInboundStore.success,

        // KML and Company State
        kml: state.companyStore.companyKML,
        companyLat: state.companyStore.lat,
        companyLng: state.companyStore.lng,
        statKML: state.settingStore.kmlSet,

        // Saved Place State
        selectorAllPfWithCustGroup: state.savedPlacesTableStore.selectorAllPfWithCustGroup,
        savedPlaceWithMainCustomerLabel: state.savedPlacesTableStore.savedPlaceWithMainCustomerLabel,
        savedPlaceInProgress: state.savedPlacesTableStore.inProgress,
        successSavedPlaceFetch: state.savedPlacesTableStore.success,

        pthCustomersData: state.pthCustomersStore.pthCustomersData,
        inProgressCustomer: state.pthCustomersStore.inProgress,
        successCustomer: state.pthCustomersStore.success,

        pthCustomersSelect: state.pthCustomersStore.pthCustomersSelect,
        inProgressCustomerAll: state.pthCustomersStore.inProgress,
        successCustomerAll: state.pthCustomersStore.success,

        groupingIntercompanyMemberSelect: state.triPLGroupingAssignedFindStore.groupingIntercompanyMemberSelect,
        inProgressGroupAssignFind: state.triPLGroupingAssignedFindStore.inProgress,
        successGroupAssignFind: state.triPLGroupingAssignedFindStore.success,

        authData: state.authStore.authData,

      }
}

export default connect(mapStateToProps, {fetchMasterOutbound, fetchMasterInbound, fetchPthCustomerByNameList, 
    fetchPthCustomerList, fetchTriPLGroupAssignFind, fetchSavedPlacesTableByCustomer})(WorkOrderAddStockerOutbound);
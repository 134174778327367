import React, { Component } from 'react';
import { Alert, Button, Col, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
// import CodeEditor from '@uiw/react-textarea-code-editor';
import { BarLoader } from 'react-spinners';
import Papa from 'papaparse'
import { SAVED_PLACES_IMPORT_URL, SAVED_PLACES_IMPORT_VALIDATE_URL } from '../../../redux/config/links';
import { toast } from 'react-toastify'
import axios from 'axios'
import { AbilityContext} from '../../../casl/Can';
class SavedPlaceImportCSVButton extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dHour: 0,
            dDay: 0,
            dTime: '',
            modal: false,
            isLoading: false,
            isConverted: false,
            isValidated: false,
            success: true,
            dataNotAccepted: []
         };
    }

    componentDidMount() {
        var Time = new Date();
        var Day = Time.getDay();
        var Hour = Time.getHours()
        this.setState({
            dDay: Day,
            dHour: Hour
        })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            success: false,
            isLoading: false,
            data: []
        })
    }

    handleSubmit = () => {
        this.setState({ isLoading: true })
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        if(this.state.data.length  === 0 && !this.state.isValidated) {

        } else {
            axios({
                method:'post',
                url: SAVED_PLACES_IMPORT_URL, //Please change when it's ready
                headers: {
                    Authorization: auth
                },
                data: {
                    data: self.state.data
                }
            })
            .then((response) => {
                if(response.status === 200) {
                    self.setState({
                        isLoading: false,
                        data: [],
                        dataNotAccepted: [],
                        isConverted: false,
                        success: true
                    })
                    if (response.data.status === "success") {
                        toast.success('Data saved!')
                        self.toggle()
                        self.props.refreshData()
                    }
                }
            })
            .catch(function(error){
                if (error.response) {
                    if(error.response.status === 401) {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error("Invalid credentials! Please try again!");
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else if (error.response.status === 400) {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error(error.response.data.message);
                    } else {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error('Something went wrong... Please try again later...');
                    }
                } else if (error.request) {
                    self.setState({
                        isLoading: false,
                        success: false
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    self.setState({
                        isLoading: false,
                        success: false
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }

    handleValidate = () => {
        this.setState({ isLoading: true })
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        if(this.state.data.length  === 0) {

        } else {
            axios({
                method:'post',
                url: SAVED_PLACES_IMPORT_VALIDATE_URL, //Please change when it's ready
                headers: {
                    Authorization: auth
                },
                data: {
                    data: self.state.data
                }
            })
            .then((response) => {
                console.log(response)
                if(response.status === 200) {
                    self.setState({
                        isLoading: false,
                        isValidated: true,
                        dataNotAccepted: response.data.result.not_accepted,
                        success: true
                    })
                    if (response.data.status === "success") {
                        toast.success('Data validated! You can proceed!')
                        // self.toggle()
                        // self.props.fetchSavedPlacesTable()
                    }
                }
            })
            .catch(function(error){
                if (error.response) {
                    if(error.response.status === 401) {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error("Invalid credentials! Please try again!");
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else if (error.response.status === 400) {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error(error.response.data.message);
                    } else {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error('Something went wrong... Please try again later...');
                    }
                } else if (error.request) {
                    self.setState({
                        isLoading: false,
                        success: false
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    self.setState({
                        isLoading: false,
                        success: false
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }

    showDatatable = () => {
        var options = {
            defaultSortName: 'saved_place',  // default sort column name
            defaultSortOrder: 'asc', // default sort order
            noDataText: 'No csv placed on the system. Please try one!',
            sizePerPageList: [{
                text: '10', value: 10
            },{
                text: '20', value: 20
            },{
                text: '50', value: 50
            },{
                text: '100', value: 100
            }],
            sizePerPage: 20,
            exportCSVSeparator: ';',
        }
        return (
            <BootstrapTable data={this.state.data} version="4" bordered={false} hover pagination search edit options={options} multiColumnSearch>
              <TableHeaderColumn dataField="main_customer_id" isKey dataSort csvHeader="ID" width="15%">Main Customer ID</TableHeaderColumn>
              <TableHeaderColumn dataField="saved_place" searchable dataSort csvHeader="Places Name" thStyle={ { whiteSpace: 'normal' } } width="25%">Places Name</TableHeaderColumn>
              <TableHeaderColumn dataField="latitude" dataSort csvHeader="Latitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Latitude</TableHeaderColumn>
              <TableHeaderColumn dataField="longitude" dataSort csvHeader="Longitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Longitude</TableHeaderColumn>
            </BootstrapTable>
        )
    }

    showDatatableNotAccepted = () => {
        console.log(this.state.dataNotAccepted)
        var options = {
            defaultSortName: 'name',  // default sort column name
            defaultSortOrder: 'asc', // default sort order
            noDataText: 'No invalid data. You are good to go!',
            sizePerPageList: [{
                text: '10', value: 10
            },{
                text: '20', value: 20
            },{
                text: '50', value: 50
            },{
                text: '100', value: 100
            }],
            sizePerPage: 20,
            exportCSVSeparator: ';',
        }
        return (
            <BootstrapTable data={this.state.dataNotAccepted} version="4" bordered={false} hover pagination search edit options={options} multiColumnSearch>
              <TableHeaderColumn dataField="id_customer" isKey dataSort csvHeader="ID" width="15%">Main Customer ID</TableHeaderColumn>
              <TableHeaderColumn dataField="name" searchable dataSort csvHeader="Places Name" thStyle={ { whiteSpace: 'normal' } } width="25%">Places Name</TableHeaderColumn>
              {/* <TableHeaderColumn dataField="main_customer_name" searchable dataSort csvHeader="Customer Name" thStyle={ { whiteSpace: 'normal' } } width="20%">Customer Name</TableHeaderColumn> */}
              <TableHeaderColumn dataField="lat" dataSort csvHeader="Latitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Latitude</TableHeaderColumn>
              <TableHeaderColumn dataField="lng" dataSort csvHeader="Longitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Longitude</TableHeaderColumn>
            </BootstrapTable>
        )
    }

    viewModal = () => {
        const { isConverted, isLoading, success, isValidated } = this.state;
        return (
            <div>
                <Alert color="warning">
                    Please use the proper format of CSV. We cannot process file that are not formatted yet. Please download <a href="https://assets.automa.id/SavedPlaceImportTemplateAUTOMA.csv" target="_blank" rel="noopener noreferrer">here</a>
                </Alert>
                <FormGroup row>
                    <Col md={12}>
                        <strong>You are about to import Saved Place CSV into our system. Please double check your data before processing!</strong>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={3}>
                        Select File
                    </Col>
                    <Col md={9}>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                            try{
                                var self = this
                                const files = e.target.files;
                                console.log(files);
                                if (files) {
                                    Papa.parse(files[0], {
                                        complete: (result) => {
                                            // var abc = result.data.map(o=>Object.values(o))
                                            self.setState({
                                                data: result.data,
                                                isConverted: true,
                                                isValidated: false
                                            })
                                            console.log("result", result.data);
                                            // console.log(abc)
                                        },
                                        header: true,
                                        delimiter: ';',
                                        transformHeader: header => header.toLowerCase().replace(/\W/g, "_"),
                                        skipEmptyLines: true
                                    });
                                }
                            }catch{}
                        }}
                    />
                    </Col>
                </FormGroup>
                {/* <FormGroup>
                    <Col md={12}>
                        Result Preview <br/>
                        <CodeEditor
                            value={JSON.stringify(this.state.data)}
                            language="json"
                            placeholder="Please enter JS code."
                            disabled
                            readOnly
                            rows="20"
                            // onChange={(evn) => setCode(evn.target.value)}
                            padding={15}
                            style={{
                                fontSize: 12,
                                backgroundColor: "#f5f5f5",
                                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                            }}
                        />
                    </Col>
                </FormGroup> */}
                <FormGroup>
                    <Col md={12}>
                        Table Preview <br/>
                        {this.showDatatable()}
                        {/* <textarea disabled readOnly value={JSON.stringify(this.state.data)} rows="10"/> */}
                    </Col>
                </FormGroup>
                {
                    isValidated ? 
                    <FormGroup>
                        <Col md={12}>
                            <Alert color="danger">
                                Validation Invalid Preview <br/>
                                <strong>Data shown below is the one that we can't validate since our system see something wrong with your data or format. The rest of it is good to go! We only show the one that we can't validate!</strong><ht/>
                                {this.showDatatableNotAccepted()}
                            </Alert>
                        </Col>
                    </FormGroup>
                    :
                    true
                }
                <div>
                    {
                        isConverted ? 
                        <div>
                            <FormGroup row>
                                <Col md={12}>
                                    <strong>Please double check and make sure that you already import main customer first. If not, the data might be not connected with the main customer and leads you to scam or ther kind of security issues.</strong>
                                </Col>
                            </FormGroup>
                            {
                                isLoading ? 
                                <center><BarLoader color={'#123abc'} loading={isLoading} /> <br /> Loading.... Please wait...</center> 
                                : 
                                (
                                    success ? 
                                    <div>
                                        {
                                            isValidated ? 
                                            <Button onClick={() => this.handleSubmit()} size="sm" color="success" disabled={!isConverted || isLoading}><i className="fa fa-circle"></i>{isLoading ? 'Wait...' : 'Submit'}</Button>
                                            :
                                            <Button onClick={() => this.handleValidate()} size="sm" color="primary" disabled={!isConverted || isLoading}><i className="fa fa-check"></i>{isLoading ? 'Wait...' : 'Validate'}</Button>
                                        }
                                        
                                        <Button onClick={this.toggle} size="sm" color="warning" disabled={isLoading || !isConverted}><i className="fa fa-window-close"></i> Close</Button>
                                    </div>
                                    : 
                                    <div>
                                        {
                                            isValidated ? 
                                            <Button onClick={() => this.handleSubmit()} size="sm" color="success" disabled={!isConverted || isLoading}><i className="fa fa-circle"></i>{isLoading ? 'Wait...' : 'Submit'}</Button>
                                            :
                                            <Button onClick={() => this.handleValidate()} size="sm" color="primary" disabled={!isConverted || isLoading}><i className="fa fa-check"></i>{isLoading ? 'Wait...' : 'Validate'}</Button>
                                        }
                                        
                                        <Button onClick={this.toggle} size="sm" color="warning" disabled={isLoading || !isConverted}><i className="fa fa-window-close"></i> Close</Button>
                                    </div>
                                )
                            } 
                        </div>
                        :
                        true
                    }
                </div>
            </div>
        )
    }

    render() {
        const { dHour, dDay } = this.state;
        return (
            <div>
                
                {
                    dHour > 18 && dHour < 22 ? 
                    (
                        dDay < 6 ? 
                        this.context.can("view", "import_csv_button@saved_places_datatable") && this.context.can("view", "import_csv_button@saved_places_datatable_subs") &&
                        <Button onClick={() => this.toggle()} color="primary" size="sm" disabled={this.props.inProgress}><i className="fa fa-plus"></i> Import CSV</Button>
                        :
                        true
                    )                    
                    :
                    (
                        dDay >= 5 ? 
                        this.context.can("view", "import_csv_button@saved_places_datatable") && this.context.can("view", "import_csv_button@saved_places_datatable_subs") &&
                        <Button onClick={() => this.toggle()} color="primary" size="sm" disabled={this.props.inProgress}><i className="fa fa-plus"></i> Import CSV</Button>
                        :
                        true
                    )
                }
                
                {/* <Button onClick={() => this.toggle()} color="primary" size="sm" disabled={this.props.inProgress}><i className="fa fa-plus"></i> Import CSV</Button> */}

                {/* Modal for Adding Place */}
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={'modal-lg'} backdrop="static">
                    <ModalHeader toggle={this.toggle}>Import Saved Place by CSV</ModalHeader>
                    <ModalBody>
                            {this.viewModal()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

SavedPlaceImportCSVButton.contextType = AbilityContext;
export default SavedPlaceImportCSVButton;
import React, { Component } from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    Button,
    FormGroup,
    Label,
    Col
} from 'reactstrap'
import {toast} from 'react-toastify'
import BarLoader from 'react-spinners'
import PropTypes from 'prop-types'
import Select from 'react-select';
import axios from 'axios'
import { Link } from 'react-router-dom';
import * as API_LINKS from '../../../redux/config/links';
import 'react-select/dist/react-select.css';
import moment from 'moment';

const propTypes = {
    id_fleet: PropTypes.number,
    reg_number: PropTypes.string
}

const defaultProps = {
    id_fleet: 0,
    reg_number: '',
}

class FleetHistoryObdModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            modalHistory: false,
            isLoading: false,
            success: true,
            historyList: [],
            selectedHistory: {
                "label": "-   Please Select   -",
                "value": null
            }
        };
    }

    toggleOpenHistory = () => {
        this.fetchData()
        this.setState({
            modalHistory: !this.state.modalHistory
        })
    }

    toggleCloseHistory = () => {
        this.setState({
            modalHistory: !this.state.modalHistory,
            historyList: []
        })
    }

    fetchData = () => {
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        var _id = this.props.id_fleet

        this.setState({
            isLoading: true,
            success: true,
        })

        axios({
            method:'post',
            url: API_LINKS.OBD_HISTORY_LIST_URL, //Please change when it's ready
            headers: {
                Authorization: auth
            },
            data: {
                id_fleet: _id
            }
        })
        .then((response) => {
            if(response.status === 200) {
                if (response.data.status === "success") {
                    var list = []
                    var _data = {}
                    var result = response.data.result
                    result.forEach((p, index) => {
                        if(result[index].active === 1) {
                            _data = {
                                "id": result[index].id,
                                "id_company": result[index].id_company,
                                "company": result[index].company,
                                "id_fleet": result[index].id_fleet,
                                "reg_number": result[index].reg_number,
                                "id_obd": result[index].id_obd,
                                "obd_code": result[index].obd_code,
                                "start_date": result[index].start_date,
                                "end_date": result[index].end_date,
                                "active": result[index].active,
                                "label": result[index].reg_number + ' - ' + result[index].obd_code + ' (Current)', 
                                "value": result[index].id
                            }
                        } else {
                            _data = {
                                "id": result[index].id,
                                "id_company": result[index].id_company,
                                "company": result[index].company,
                                "id_fleet": result[index].id_fleet,
                                "reg_number": result[index].reg_number,
                                "id_obd": result[index].id_obd,
                                "obd_code": result[index].obd_code,
                                "start_date": result[index].start_date,
                                "end_date": result[index].end_date,
                                "active": result[index].active,
                                "label": result[index].reg_number + ' - ' + result[index].obd_code + ' (Up to '+moment(result[index].end_date).format("DD-MM-YYYY")+')',
                                "value": result[index].id
                            }
                        }
                        list.push(_data)
                    })
                    self.setState({
                        historyList: list,
                        isLoading: false,
                        success: true
                    })
                }
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    self.setState({
                        isLoading: false,
                        success: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    self.setState({
                        isLoading: false,
                        success: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    self.setState({
                        isLoading: false,
                        success: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    self.setState({
                        isLoading: false,
                        success: false,
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    self.setState({
                        isLoading: false,
                        success: false,
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                self.setState({
                    isLoading: false,
                    success: false,
                })
                return toast.error('Request error! Please try again later...')
            } else {
                self.setState({
                    isLoading: false,
                    success: false,
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }

    viewObdHistory = () => {
        const { isLoading, success } = this.state;
        return (
            <div>
                {
                    (
                        isLoading ? 
                        // <center><BarLoader color={'#123abc'} loading={isLoading}/> <br /> Loading.... Please wait...</center>
                        <div>Preparing</div>
                        : 
                        (
                            success ? 
                            this.viewObdContent() 
                            : 
                            <center>Error fetching your data... <br/><br/> 
                                <Button className="btn btn-primary" color="primary" size="md" disabled={isLoading} onClick={() => this.fetchData()}>
                                    <i className="fa fa-refresh"></i> 
                                    Try again
                                </Button>
                            </center>
                        )
                    )
                }
            </div>
        )
    }

    handleHistory = (selectedHistory) => {
        this.setState({ selectedHistory })
    }

    viewObdContent = () => {
        const { historyList, isLoading, selectedHistory } = this.state;
        return (
            <div>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">OBD History</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Select 
                            options={historyList} 
                            value={selectedHistory} 
                            onChange={this.handleHistory} 
                            disabled={isLoading} 
                            isClearable={false}
                        />
                    </Col>
                </FormGroup>
                {
                    selectedHistory.value === null ? 
                    <FormGroup row>
                        <Col xs="12" md="12">
                            <strong>You should select one of the history OBD to continue</strong>
                        </Col>
                    </FormGroup>
                    :
                    <div>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Registration Number</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Label htmlFor="text-input">{selectedHistory.reg_number ? selectedHistory.reg_number : '-'}</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">OBD Code</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Label htmlFor="text-input">{selectedHistory.obd_code ? selectedHistory.obd_code : '-'}</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Installation Date</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Label htmlFor="text-input">{selectedHistory.start_date ? selectedHistory.start_date : '-'}</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Removal Date</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Label htmlFor="text-input">{selectedHistory.end_date ? selectedHistory.end_date : 'Still Installed'}</Label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Status</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Label htmlFor="text-input">{selectedHistory.active > 0 ? 'Active' : 'Deactivated'}</Label>
                            </Col>
                        </FormGroup>
                        {
                            isLoading 
                            ? 
                            <center><BarLoader color={'#123abc'} loading={isLoading} /> <br /> Loading.... Please wait...</center> 
                            : 
                            <div>
                                <Button onClick={() => this.toggleCloseHistory()} size="sm" color="warning" disabled={isLoading}><i className="fa fa-window-close"></i> Close</Button>
                                <Link to={{
                                    pathname: '/transporter/fleets/obd-history/'+selectedHistory.id,
                                    state: {
                                        id: selectedHistory.value,
                                        id_fleet: this.props.id_fleet,
                                        reg_number: selectedHistory.reg_number,
                                        obd_code: selectedHistory.obd_code,
                                        start_date: selectedHistory.start_date,
                                        end_date: selectedHistory.end_date
                                    }
                                }}>
                                    <Button size="sm" color="primary" disabled={isLoading}><i className="fa fa-dot-circle-o"></i> Check Fleet History</Button>
                                </Link>
                                <Link to={{
                                    pathname: '/transporter/fleets/fuel/report/'+this.props.id_fleet,
                                    state: {
                                        id_value: this.props.id_fleet,
                                        id: selectedHistory.value,
                                        reg_number: selectedHistory.reg_number,
                                        obd_code: selectedHistory.obd_code,
                                        start_date: selectedHistory.start_date,
                                        end_date: selectedHistory.end_date
                                    }
                                }}>
                                    <Button size="sm" color="success" disabled={isLoading}><i className="fa fa-water"></i> Check Fuel History</Button>
                                </Link>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

    render() {
        const { modalHistory, isLoading } = this.state;
        const { reg_number } = this.props;
        return (
            <div>
                <Button size="sm" onClick={this.toggleOpenHistory} color="primary" disabled={isLoading}><i className="fa fa-history"></i> Fleet History</Button>

                {/* Modal for History OBD */}
                <Modal isOpen={modalHistory} toggle={this.toggleOpenHistory} className={this.props.className}>
                <ModalHeader toggle={this.toggleCloseHistory}>Check OBD History or Fuel Report for {reg_number}</ModalHeader>
                    <ModalBody>
                            {this.viewObdHistory()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

FleetHistoryObdModal.propTypes = propTypes;
FleetHistoryObdModal.defaultProps = defaultProps;

export default FleetHistoryObdModal;
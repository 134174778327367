
export default {
  items: [
    {
      name: "Home",
      url: "/welcome",
      icon: "home icon",
    },
    {
      name: "TrackX",
      url: "/transporter",
      icon: "truck icon",
      children: [
        {
          name: "Delivery Orders",
          url: "/transporter/delivery-order",
          // icon: "fa fa-file-text",
        },
        {
          name: "Check Sheet",
          url: "/transporter/check-sheet",
          // icon: "fa fa-check-square-o",
        },
        {
          name: "Fleet Operations",
          url: "/transporter/fleet-operations",
          // icon: "map signs icon",
        },
        {
          name: "Mechanics",
          url: "/transporter/mechanics",
          // icon: "fa fa-user",
        },
        {
          name: "Fleet Maintenance",
          url: "/transporter/fleet-maintenance",
          // icon: "fa fa-wrench",
        },
      ],
    },
    
  ],
};

import { Formik } from "formik";
import React from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as yup from "yup";
import {
  LOGIN_URL,
  // USER_PAYMENT_INTERNAL_SALDO,
  USER_PAYMENT_INTERNAL_SALDO_INVOICE
} from "../../../../../redux/config/links";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchSaldo } from "../../../../../redux/actions/wallet/aWallet-Saldo";

const PaymentConfirmPassword = ({ onClose, onReload }) => {
  const { authStore, walletSaldo } = useSelector((state) => ({
    walletSaldo: state.walletSaldo,
    authStore: state.authStore,
  }));
  const dispatch = useDispatch();
  const validation = () => {
    return yup.object().shape({
      password: yup.string().required("password is required!"),
      agreement: yup
        .boolean()
        .oneOf([true])
        .required("Please check to agree to spend your wallet"),
    });
  };

  const [confirmPassword] = useLazyFetch({
    url: LOGIN_URL,
    method: "POST",
    type: "json",
  });

  const [onPaymentSaldo] = useLazyFetch({
    url: USER_PAYMENT_INTERNAL_SALDO_INVOICE,
    method: "POST",
    type: "json",
  });

  const onSubmit = (values, { setSubmitting }) => {
    confirmPassword(
      {
        body: {
          email: authStore.authData.result.email,
          password: values.password,
        },
      },
      (success) => {
        if (!!success) {
          toast.success("Password confirm success");
          onPaymentSaldo(
            {
              body: {
                id_company_destination: walletSaldo.companyDestinationId,
                amount: walletSaldo.paymentTotalAmount,
                type_transaction: walletSaldo.typeTransaction,
                id_invoice: walletSaldo.invoiceId,
              },
            },
            (paymentSuccess) => {
              setSubmitting(false);
              if (paymentSuccess) {
                toast.success("Payment success");
                dispatch(fetchSaldo());
                onReload();
                onClose();
              } else {
                toast.error("Payment failed");
              }
            }
          );
        } else {
          setSubmitting(false);
          toast.error("Wrong password");
        }
      }
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          agreement: false,
        }}
        onSubmit={onSubmit}
        validationSchema={validation()}
        render={({
          touched,
          handleChange,
          handleBlur,
          values,
          isSubmitting,
          errors,
          isValid,
          handleReset,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup row>
              <Col md="3">
                <Label>Current Balance</Label>
              </Col>
              <Col xs="12" md="9">
                Rp {Number(walletSaldo.currentSaldo).toLocaleString("id-ID")}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label>Estimated Balance</Label>
              </Col>
              <Col xs="12" md="9">
                Rp{" "}
                {(
                  Number(walletSaldo.currentSaldo) -
                  Number(walletSaldo.paymentTotalAmount)
                ).toLocaleString("id-ID")}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="3">
                <Label htmlFor="password">Password</Label>
              </Col>
              <Col xs="12" md="9">
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  autoComplete="off"
                  valid={!errors.password}
                  invalid={touched.password && !!errors.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password || ""}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.password}</FormFeedback>
              </Col>
            </FormGroup>
            
            <FormGroup row>
              <Col md="12">
                <p>
                  <input
                    type="checkbox"
                    name="agreement"
                    onChange={() => {
                      if (values.agreement) {
                        setFieldValue("agreement", false);
                      } else {
                        setFieldValue("agreement", true);
                      }
                    }}
                    value={"agree"}
                    checked={values.agreement}
                  />{" "}
                  {walletSaldo.agreementText}
                </p>
                <FormFeedback>{errors.agreement}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup>
              <Button
                type="submit"
                color="primary"
                className="mr-1"
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting ? "Wait..." : "Submit"}
              </Button>
              <Button
                type="button"
                color="warning"
                className="mr-1"
                disabled={false}
                onClick={() => onClose()}
              >
                Close
              </Button>
              <Button
                type="reset"
                color="danger"
                className="mr-1"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Reset
              </Button>
            </FormGroup>
          </form>
        )}
      />
    </>
  );
};

export default PaymentConfirmPassword;

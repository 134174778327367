import React from "react";
import MechanicHome from "./contents/MechanicHome";

export const mechanicRoutes = () => {
  const path = "/storing/maintenance/mechanic";

  return [
    {
      name: "Mechanic",
      path: `${path}`,
      exact: true,
      render: (props) => (
        <MechanicHome {...props} />
      ),
    },
  ];
};

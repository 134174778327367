import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import InputImage from "src/components/InputImage/InputImage";
import LoadingContent from "src/components/LoadingContent/LoadingContent";
import { onFetchArrayAsync } from "src/helpers/helper";
import useForm, { ICallbackSubmit } from "src/hooks/useForm";
import useLazyFetch from "src/hooks/useLazyFetch";
import { CHECK_SHEET_WEEKLY_DETAIL_CHECK, CHECK_SHEET_WEEKLY_DETAIL_LIST, UPLOAD_IMAGE_FOR_EDIT } from "src/redux/config/links";
import * as yup from "yup";

interface IProps {
    id: number;
    onClose: () => void;
}

const CheckSheetWeeklySubmission: React.FC<IProps> = ({ id, onClose }) => {


    const validation = () => {
        // const selectYup = yup
        //     .object()
        //     .shape({
        //         label: yup.string(),
        //         value: yup.string(),
        //     })
        //     .nullable();
        return yup.object().shape({
            activity: yup.array().of(yup
                .object()
                .shape({
                    checked: yup.boolean(),
                    index: yup.string().when("checked", {
                        is: true,
                        then: yup.string().required("index is required")
                    }),
                    desc: yup.string().when("checked", {
                        is: true,
                        then: yup.string().required("descriptioin is required")
                    }),
                    image: yup.mixed().when("checked", {
                        is: true,
                        then: yup.mixed().required("image is required")
                    }),
                }))
        });
    };

    const [isGetting, setIsGetting] = useState(true);
    const [getCheckSheetWeeklyDetailList, { loading, data }] =
        useLazyFetch({
            url: CHECK_SHEET_WEEKLY_DETAIL_LIST,
            method: "POST",
        });




    const {
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
        isValid,
    } = useForm({
        initialValues: {
            activity: []
        },
        validation: validation()
    });

    const onSubmit: ICallbackSubmit = (values, { setSubmitting }) => {

        onFetchArrayAsync(values.activity.filter((item: { checked: boolean; }) => item.checked).map((item: { index: number; image: File; }) => ({
            url: UPLOAD_IMAGE_FOR_EDIT,
            method: "POST",
            data: {
                index: item.index,
                file: item.image
            },
            type: "formdata"
        })), {
            beforeSend: () => { setSubmitting(true); return null },
            success: (response: { config: { data: FormData; }; data: { result: { id: number; }; }; }[]) => {

                // console.log(response);

                let newValues = JSON.parse(JSON.stringify(values.activity.filter((item: { checked: boolean }) => item.checked)))

                response.forEach((item: { config: { data: FormData; }; data: { result: { id: number; }; }; }) => {
                    
                    let obj:{[key:string]:any} = {};
                    item.config.data.forEach(function(value: any, key){
                        obj = {...obj, [key as unknown as keyof typeof obj]: value};
                    });

                    newValues.forEach((sub: { index: number; }) => {
                        if (Number(obj.index) === sub.index) {
                            newValues = [...newValues.map((ss: { index: number; data: { result: { id: number; }; }; }) => ss.index === sub.index ? ({ ...ss, id_image: item.data.result.id }) : ss)]
                        }
                    });
                });

                onFetchArrayAsync(newValues.map((item: { id: any; id_image: any; desc: any; }) => ({
                    url: CHECK_SHEET_WEEKLY_DETAIL_CHECK,
                    method: "POST",
                    data: {
                        id_check_sheet_weekly_detail: item.id,
                        id_image: item.id_image,
                        desc: item.desc,
                        notes: item.desc
                    },
                    type: "json"
                })), {
                    beforeSend: () => null,
                    success: () => {
                        setSubmitting(true)
                        onClose();

                    },
                    error: () => {
                        setSubmitting(true)
                    }
                })
            },
            error: () => {
                setSubmitting(true)
            }
        })
    }


    useEffect(() => {
        if (isGetting) {
            setIsGetting(false);
            getCheckSheetWeeklyDetailList({
                body: {
                    id_check_sheet_weekly: id
                }
            }, (response) => {
                if (!!response) {
                    setFieldValue("activity", response.data.result.map((item: { id: any; }, index: any) => ({
                        id: item.id,
                        index: index,
                        checked: false,
                        desc: "",
                        image: null
                    })))
                }
            })
        }
    }, [isGetting, getCheckSheetWeeklyDetailList, id])



    return <>
        <LoadingContent loading={loading} onReload={() => { setIsGetting(true); return null; }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ display: "flex", flexDirection: "row", gap: ".5rem", flexWrap: "wrap" }}>
                    {data?.result.map((item: { check_sheet_detail_activity_name: string; check_sheet_desc: string; }, i: React.Key | null | undefined) => <React.Fragment key={i}>
                        <div style={{ display: "flex", flexDirection: "row", gap: ".5rem", flex: "1 1 200px" }}>
                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                                <input type="checkbox" name="activity" checked={values.activity.find((sub: { index: any; }) => sub.index === i).checked} onChange={() => null} onClick={() => {
                                    setFieldValue("activity", [...values.activity].map((sub) => sub.index === i ? { ...sub, checked: !!!sub.checked } : sub));
                                }} />
                            </div>
                            <div>
                                {item.check_sheet_detail_activity_name}
                                <p style={{ opacity: .5 }}>
                                    {item.check_sheet_desc}
                                </p>
                                <Input
                                    type="text"
                                    name="desc"
                                    value={(values.activity.find((sub: { index: React.Key; }) => sub.index === i)).desc}
                                    placeholder="Description"
                                    onChange={(e) => {
                                        setFieldValue("activity", [...values.activity].map((sub) => sub.index === i ? { ...sub, desc: e.target.value } : sub))
                                    }}
                                />
                                <InputImage
                                    value={(values.activity.find((sub: { index: React.Key; }) => sub.index === i)).image}
                                    onChange={(result: any) => {
                                        setFieldValue("activity", [...values.activity].map((sub) => sub.index === i ? { ...sub, image: result } : sub))
                                    }}
                                    onRemove={() => {
                                        setFieldValue("activity", [...values.activity].map((sub) => sub.index === i ? { ...sub, image: null } : sub))
                                    }}
                                />
                            </div>
                        </div>
                    </React.Fragment>)}
                </div>

                <Button
                    type="submit"
                    color="primary"
                    className="mr-1"
                    disabled={isSubmitting || !isValid}
                >
                    Save Changes
                </Button>
                <Button
                    type="button"
                    color="warning"
                    className="mr-1"
                    disabled={isSubmitting}
                    onClick={() => onClose()}
                >
                    Close
                </Button>
            </form>
        </LoadingContent>
    </>
}

export default CheckSheetWeeklySubmission;
import React from "react";
import styles from "./CustomToast.module.scss";

const CustomToast = ({ text, icon }) => (
  <div className={styles["custom-toast"]}>
    {icon}
    <span> {text}</span>
  </div>
);

export default CustomToast;

import React from "react";
import { Link } from "react-router-dom";
import Fleet from "./Fleet/Fleet";
import { fleetRoutes } from "./Fleet/Fleet.constants";
import Maintenance from "./Maintenance/Maintenance";
import { maintenanceRoutes } from "./Maintenance/Maintenance.constants";


export const storingRoute = () => {
    const path = "/storing";
    return [
        {
          name: "Storing",
          path: `${path}`,
          exact: true,
          render: () => <>
            <ul>
                <li><Link to={`${path}/fleet`}>Fleet</Link></li>
                <li><Link to={`${path}/maintenance`}>Maintenance</Link></li>
            </ul>
          </>,
        },
        {
          name: "Fleet",
          path: `${path}/fleet`,
          render: (props) => <Fleet {...props} />,
        },
        {
          name: "Maintenance",
          path: `${path}/maintenance`,
          render: (props) => <Maintenance {...props} />,
        },
    ];
  };

export const allStoringRoutes = () =>{
    return [...storingRoute(), ...fleetRoutes(), ...maintenanceRoutes()]
}
import useLazyFetch from "../../../hooks/useLazyFetch";
import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as API_LINKS from "../../../redux/config/links";
import { bootstrapTableOption, fileNameFormat } from "../../../helpers/helper";
import { Button } from "reactstrap";
import LoadingContent from "../../../components/LoadingContent/LoadingContent";

const History = () => {
  const [gettingTransactionHistory, setGettingTransactionHistory] =
    useState(true);

  const [
    onGetTransactionHistory,
    { loading: loadingTransactionHistory, data },
  ] = useLazyFetch({
    url: API_LINKS.USER_TRANSACTION_HISTORY_LIST,
    method: "POST",
  });

  useEffect(() => {
    if (gettingTransactionHistory) {
      setGettingTransactionHistory(false);
      onGetTransactionHistory({});
    }
  }, [gettingTransactionHistory, onGetTransactionHistory]);
  return (
    <>
      <LoadingContent
        loading={loadingTransactionHistory}
        onReload={() => setGettingTransactionHistory(true)}
      >
        <BootstrapTable
          data={data ? data.result : []}
          version="4"
          bordered={false}
          hover
          pagination
          search
          edit
          options={bootstrapTableOption({
            defaultSortName: "id",
            defaultSortOrder: "desc",
            btnGroup: (props) => (
              <div>
                {props.exportCSVBtn}{" "}
                <Button
                  color={"primary"}
                  onClick={() => setGettingTransactionHistory(true)}
                >
                  <i className="fa fa-refresh"></i> Reload
                </Button>
              </div>
            ),
          })}
          exportCSV={true}
          csvFileName={fileNameFormat("")}
          multiColumnSearch
        >
          <TableHeaderColumn
            dataField="id"
            isKey
            dataSort
            csvHeader="ID"
            hidden
          >
            ID
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="bank_code"
            dataSort
            csvHeader="Bank"
            width="10%"
          >
            Bank
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="account_number"
            dataSort
            csvHeader="Account Number"
            width="16.66%"
          >
            Account Number
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="amount"
            dataSort
            csvHeader="Amount"
            width="16.66%"
            dataFormat={(cell, row) => (
              <>
                {row.kredit_debit === 1 ? (
                  <span style={{ color: "#4dbd74" }}>
                    + Rp {Number(cell).toLocaleString("id-ID")}
                  </span>
                ) : (
                  <span style={{ color: "red" }}>
                    - Rp {Number(cell).toLocaleString("id-ID")}
                  </span>
                )}
              </>
            )}
          >
            Amount
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="current_saldo"
            dataSort
            csvHeader="Current Saldo"
            width="16.66%"
            dataFormat={(cell) => (
              <>Rp {Number(cell).toLocaleString("id-ID")}</>
            )}
          >
            Current Saldo
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="desc_transaction"
            dataSort
            csvHeader="Description"
            thStyle={{ whiteSpace: "normal" }}
            tdStyle={{ whiteSpace: "normal" }}
          >
            Description
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="transaction_timestamp"
            dataSort
            csvHeader="Transaction Date"
            width="16.66%"
          >
            Transaction Date
          </TableHeaderColumn>
        </BootstrapTable>
      </LoadingContent>
    </>
  );
};

export default History;

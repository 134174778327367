import React from 'react';
import DashboardDetails from '../views/Dashboard/DashboardDetails/DashboardDetails.jsx';
import WelcomeMessage from '../containers/DefaultLayout/WelcomeMessage'

// //Enviroma files import
// import EnviromaDevices from '../views/Enviroma/Devices/Devices.jsx';
// import EnviromaTemperature from '../views/Enviroma/Temperature/Temperature.jsx';

// //Fleet files import
// import VehicleMonitoring from '../views/Fleet/VehicleMonitoring/VehicleMonitoring.jsx';
// import MonitorDetails from '../views/Fleet/MonitorDetails/MonitorDetails.jsx';
// import Drivers from '../views/Fleet/Drivers/Drivers.jsx';
// import DriversAssistant from '../views/Fleet/DriverAssistants/DriverAssistants.jsx';
// import Fleets from '../views/Fleet/Fleets/Fleets.jsx';
// import SavedPlaces from '../views/Fleet/SavedPlaces/SavedPlaces.jsx';
import LiveMonitoring from '../views/Fleet/LiveMonitoring/LiveMonitoringTabs'
// import Security from '../views/Fleet/Security/Security.jsx';

//User Management Files Import
import Login from '../views/Users/Login/Login';
import Logout from '../views/Users/Logout/Logout';
import Register from '../views/Users/Register/Register';
import UserManagement from '../views/Users/Management/Management';

// Delivery Management Files Import
import DeliveryOrders from '../views/Fleet/DeliveryOrders/DeliveryOrders';
import WorkOrders from '../views/Fleet/WorkOrders/WorkOrdersNew';
import ReportDeliveryOrders from '../views/Fleet/Report-DeliveryOrders/Report-DeliveryOrders';

//Settings
import Settings from '../views/Settings/Settings';

const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));
const WOReview = React.lazy(() => import('../views/Fleet/Report/WOReview/WOReview'));
const WOPODConfirmReview = React.lazy(() => import('../views/Fleet/Report/WOPODVerif/WOPODVerif'));
const DoCheckSheetApproval = React.lazy(() => import('../views/Fleet/DeliveryOrders/DOChecksheetApproval'));

// const DashboardDetails = React.lazy(() => import('./views/DashboardDetails/DashboardDetails.jsx'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const securityRoutes = [
  { path: '/welcome', name: 'Welcome Message', component: WelcomeMessage , exact: true },
  { path: '/transporter/dashboard', name: 'Dashboard', component: Dashboard, exact:true },
  { path: '/dashboard/details', name: 'Details', component: DashboardDetails },
  { path: '/transporter/wo-review/:month/:id', name: 'Work Order Review', component: WOReview, exact: true },
  { path: '/transporter/wo-pod-verif-review/:id', name: 'Work Order POD Verification Review', component: WOPODConfirmReview, exact: true },

//   //Enviroma
//   { path: '/enviroma/devices', name: 'Enviroma Devices', component: EnviromaDevices },
//   { path: '/enviroma/temperature', name: 'Enviroma Temperature', component: EnviromaTemperature },
  
  //Fleet
  { path: '/transporter/live-monitoring', name: 'Live Monitoring', component: LiveMonitoring, exact: true },
//   { path: '/transporter/monitoring', name: 'Fleet Monitoring', component: VehicleMonitoring, exact: true },
//   { path: '/transporter/monitoring/details', name: 'Monitor Details', component: MonitorDetails },
//   { path: '/transporter/fleets', name: 'Fleets', component: Fleets },
//   { path: '/transporter/drivers', name: 'Drivers', component: Drivers },
//   { path: '/transporter/drivers-assistant', name: 'Drivers Assistant', component: DriversAssistant },
  { path: '/transporter/delivery-order', name: 'Delivery Order', component: DeliveryOrders },
//   { path: '/transporter/saved-places', name: 'Saved Places', component: SavedPlaces },
//   { path: '/transporter/security', name: 'Security', component: Security },
  { path: '/transporter/work-order/:id', name: 'Work Order', component: WorkOrders, exact: true },
  { path: '/transporter/report/delivery/:id', name: 'Delivery Order Report', component: ReportDeliveryOrders, exact: true },
  { path: '/transporter/do-check-sheet/approval/:id', name: 'Do Check Sheet Approval', component: DoCheckSheetApproval, exact: true },

  //User Management
  { path: '/login', name: 'Login', component: Login },
  { path: '/logout', name: 'Logout', component: Logout },
  { path: '/register', name: 'Register', component: Register },
  { path: '/settings', name: 'Settings', component: Settings },
  { path: '/user/management', name: 'User Management', component: UserManagement },

//   { path: '/user/management', name: 'User Management', component: UserManagement },
];

export default securityRoutes;

import React, { useEffect, useState } from "react";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {
  bootstrapTableOption,
  fileNameFormat,
} from "../../../../../helpers/helper";
import {
  Button,
  ButtonGroup,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { MECHANIC_LIST } from "../../../../../redux/config/storing.links";
import MechanicAdd from "./MechanicAdd";
import MechanicDelete from "./MechanicDelete";
import SummaryWidgets from "../../../../Widgets/SummaryWidgets";

const MechanicDatatable = ({ id_storing_fleet_trip }) => {
  const [modalOpenEdit, setModalOpenEdit] = useState(null);
  const [modalOpenDelete, setModalOpenDelete] = useState(null);
  const [openAddMechanic, setOpenAddMechanic] = useState(null);
  const [isGettingMechanic, setIsGettingMechanic] = useState(true);
  const [onGetMechanic, { data, loading }] = useLazyFetch({
    url: MECHANIC_LIST,
    method: "post",
    type: "json",
  });

  useEffect(() => {
    if (isGettingMechanic) {
      setIsGettingMechanic(false);
      onGetMechanic({
        // body: {
        //   id_storing_fleet_trip,
        // },
      });
    }
  }, [isGettingMechanic, id_storing_fleet_trip]);

  const dataFiltered = data
    ? data.result.filter(
        (item) => item.id_storing_fleet_trip === id_storing_fleet_trip
      )
    : [];

  return (
    <>
      <Row>
        <Col md={3} xs={12}>
          <SummaryWidgets
            header={dataFiltered.length}
            mainText={"Total Mechanic"}
            icon={"fa fa-id-card"}
            color={"success"}
          />
        </Col>
      </Row>
      <LoadingContent
        loading={loading}
        onReload={() => setIsGettingMechanic(true)}
      >
        <BootstrapTable
          data={dataFiltered}
          version="4"
          bordered={false}
          hover
          pagination
          search
          edit
          options={bootstrapTableOption({
            btnGroup: (props) => (
              <div>
                {props.exportCSVBtn}{" "}
                <Button
                  color={"primary"}
                  onClick={() => setIsGettingMechanic(true)}
                >
                  <i className="fa fa-refresh"></i> Reload
                </Button>{" "}
                <Button
                  color={"primary"}
                  onClick={() => setOpenAddMechanic({ id_storing_fleet_trip })}
                >
                  <i className="fa fa-plus"></i> Add Mechanic
                </Button>{" "}
              </div>
            ),
          })}
          exportCSV={true}
          csvFileName={fileNameFormat({ name: "" })}
          multiColumnSearch
        >
          <TableHeaderColumn
            dataField="id"
            isKey
            dataSort
            csvHeader="ID"
            hidden
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="mechanic_first_name"
            dataSort
            csvHeader="First Name"
          >
            First Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="mechanic_last_name"
            dataSort
            csvHeader="Last Name"
          >
            Last Name
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="id"
            dataFormat={(cell, row) => (
              <>
                <ButtonGroup>
                  <Button
                    size="sm"
                    color="warning"
                    onClick={() => setModalOpenEdit({ ...row })}
                  >
                    <i className="fa fa-edit"></i> Edit
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => setModalOpenDelete({ ...row })}
                  >
                    <i className="fa fa-trash"></i> Delete
                  </Button>
                </ButtonGroup>
              </>
            )}
          >
            Action
          </TableHeaderColumn>
        </BootstrapTable>
      </LoadingContent>

      <Modal
        size="md"
        isOpen={!!openAddMechanic}
        toggle={() => setOpenAddMechanic(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setOpenAddMechanic(null)}>
          Add Mechanic
        </ModalHeader>
        <ModalBody>
          <MechanicAdd
            initialValues={openAddMechanic}
            onClose={() => setOpenAddMechanic(null)}
            onReload={() => setIsGettingMechanic(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenEdit}
        toggle={() => setModalOpenEdit(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenEdit(null)}>
          Edit Mechanic
        </ModalHeader>
        <ModalBody>
          <MechanicAdd
            initialValues={modalOpenEdit}
            isEdit
            onClose={() => setModalOpenEdit(null)}
            onReload={() => setIsGettingMechanic(true)}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={!!modalOpenDelete}
        toggle={() => setModalOpenDelete(null)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalOpenDelete(null)}>
          Delete Mechanic
        </ModalHeader>
        <ModalBody>
          <MechanicDelete
            initialValues={modalOpenDelete}
            onClose={() => setModalOpenDelete(null)}
            onReload={() => setIsGettingMechanic(true)}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default MechanicDatatable;

import { useCallback, useState } from "react";
import axios, { AxiosResponse } from "axios";
import useHelper from "./useHelper";
import { useSelector } from "react-redux";

// const methods = {
//   GET: "get",
//   POST: "post",
//   PUT: "put",
//   PATCH: "patch",
//   DELETE: "delete",
// };

const useLazyFetch = ({ url, method = "GET", type = "formdata" }: { url: string, method?: "GET" | "POST", type?: "json" | "formdata" }) => {
  const { authData } = useSelector((state: {
    authStore: {
      authData: { result: { token: string } }
    }
  }) => ({
    authData: state.authStore.authData,
  }));
  const { expandJSON } = useHelper();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [error, setError] = useState();

  const onAction = useCallback(
    (params, cb?: (response?: AxiosResponse<any>, err?: any) => void) => {
      let auth = null;

      if (authData !== null) {
        auth = authData.result.token;
      }

      const source = axios.CancelToken.source();
      setLoading(true);
      setData(undefined);
      let bodyData = null;
      if (params && params.body) {
        const formd = new FormData();
        const bd = expandJSON(params.body);
        for (const key in bd) {
          formd.append(bd[key].label, bd[key].value);
        }
        bodyData = formd;
      }
      const newUrl = `${url}${params && params.path ? params.path : ""}`;
      let headers = {};

      if (auth !== null) {
        headers = { ...headers, Authorization: auth };
      }

      axios({
        url: newUrl,
        method,
        cancelToken: source.token,
        headers: { ...headers },
        ...(method === "GET"
          ? { params: params && params.body ? params.body : null }
          : { data: type === "json" ? params && params.body ? params.body : null : bodyData }),
      })
        .then((res) => {
          setLoading(false);
          cb?.(res, undefined)
          setData(res.data);
        })
        .catch((err) => {
          setLoading(false);
          cb?.(undefined, err);
          setError(err);
        });

      return () => {
        source.cancel("Cancelling in cleanup");
      };
    },
    [method, url, expandJSON]
  );

  return [onAction, { loading, data, error }] as const;
};

export default useLazyFetch;

import React, { Component } from 'react';
import { Row, Col, Input, FormGroup } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { OUTBOUND_ITEM_DETAIL_EDIT_URL }  from '../../../redux/config/stck.links'
import axios from 'axios'
import {toast} from 'react-toastify'

class CheckOutboundItemTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id_master_item: null,
            itemObj: {}
        }
    }

    componentDidUpdate = (prevProps) => {
        const {successVerified} = this.props
        if((prevProps.successVerified !== successVerified) && successVerified){
            console.log(this.state.itemObj)
            this.submitRealDeliveredQty()
        }
    }

    submitRealDeliveredQty = () => {
        const {itemObj} = this.state
        const toEdit = []
        let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        let auth = loginCreds.token;
        for (let i in itemObj) {
            toEdit.push(
                axios({
                    method: 'post',
                    url: OUTBOUND_ITEM_DETAIL_EDIT_URL,
                    headers: {
                        Authorization: auth,
                    },
                    data : itemObj[i]
                })
            )
        }
        Promise.all(toEdit)
    }

    realDeliveryQtyInput = (cell, row) => {
        const {itemObj} = this.state
        return(
            <FormGroup row>
                <Col xs="12" md="12">
                    <Input 
                        type="number"
                        name="realization_qty"
                        id="realization_qty"
                        placeholder=""
                        autoComplete="realization-qty"
                        onChange={(e)=>{
                        let copyItemObj = itemObj
                            if(e.target.value <= row.expected_qty){
                                copyItemObj[row.id] = {
                                    id: row.id,
                                    rejected_qty: row.expected_qty - e.target.value,
                                    realization_qty: e.target.value
                                }
                                this.setState({
                                    itemObj: copyItemObj
                                })
                            }else{
                                e.target.value = 0
                                toast.error("Real delivered qty cannot more than expected!")
                            }
                        }}
                        onBlur={()=>{}}
                        />
                </Col>
            </FormGroup>
        )
    }

    showDataTable = (idInboundStocker, inboundItem, idOutboundStocker, outboundItem, withRealizationFill, isDeliveryFinish) => {
        let options = {
            noDataText: 'No items registered. Try one-lah!',
            sizePerPageList: [{
                text: '10', value: 10
            },{
                text: '25', value: 25
            },{
                text: '50', value: 50
            }],
            sizePerPage: 20,
            btnGroup: this.customBtnGroup,
        };
        return(
            <>
                {idInboundStocker && !withRealizationFill?
                    <>
                        <BootstrapTable data={inboundItem} version="4" bordered={false} hover pagination search edit options={options} exportCSV={false} csvFileName={this.fileNameFormat} multiColumnSearch>
                            <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" searchable={false} hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="item_name" searchable dataSort csvHeader="Item Name" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Item Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="batch_number" searchable dataSort csvHeader="Batch Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Batch Number</TableHeaderColumn>
                            <TableHeaderColumn dataField="expected_qty" searchable dataSort csvHeader="Exp. Qty" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Exp. Qty</TableHeaderColumn>
                            <TableHeaderColumn dataField="delivery_qty" searchable dataSort csvHeader="Exp.Delivered Qty" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Exp. Delivered Qty</TableHeaderColumn>
                            {/* <TableHeaderColumn dataField="production_date" searchable dataSort csvHeader="Production Date" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Production Date</TableHeaderColumn> */}
                            <TableHeaderColumn dataField="expired_date" searchable dataSort csvHeader="Expired Date" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Expiry</TableHeaderColumn>
                        </BootstrapTable>
                    </>
                    : null    
                }
                {idOutboundStocker && !withRealizationFill?
                        <>
                          <BootstrapTable data={outboundItem} version="4" bordered={false} hover pagination search edit options={options} exportCSV={false} csvFileName={this.fileNameFormat} multiColumnSearch>
                              <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" searchable={false} hidden>ID</TableHeaderColumn>
                              <TableHeaderColumn dataField="item_name" searchable dataSort csvHeader="Item Name" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Item Name</TableHeaderColumn>
                              <TableHeaderColumn dataField="batch_number" searchable dataSort csvHeader="Batch Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Batch Number</TableHeaderColumn>
                              <TableHeaderColumn dataField="expected_qty" searchable dataSort csvHeader="Exp. Qty" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Exp. Qty</TableHeaderColumn>
                              <TableHeaderColumn dataField="delivery_qty" searchable dataSort csvHeader="Exp.Delivered Qty" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Exp. Delivered Qty</TableHeaderColumn>
                              <TableHeaderColumn dataField="realization_qty" searchable dataSort csvHeader="Realization Qty" hidden={!isDeliveryFinish} thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="25%">Real Delivered Qty</TableHeaderColumn>
                              {/* <TableHeaderColumn dataField="production_date" searchable dataSort csvHeader="Production Date" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Production Date</TableHeaderColumn> */}
                              <TableHeaderColumn dataField="expired_date" searchable dataSort csvHeader="Expired Date" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Expiry</TableHeaderColumn>
                          </BootstrapTable>
                      </>
                    : null
                }
                {idInboundStocker && withRealizationFill?
                    <>
                        <BootstrapTable data={inboundItem} version="4" bordered={false} hover pagination search edit options={options} exportCSV={false} csvFileName={this.fileNameFormat} multiColumnSearch>
                            <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" searchable={false} hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="item_name" searchable dataSort csvHeader="Item Name" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Item Name</TableHeaderColumn>
                            <TableHeaderColumn dataField="batch_number" searchable dataSort csvHeader="Batch Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Batch Number</TableHeaderColumn>
                            <TableHeaderColumn dataField="expected_qty" searchable dataSort csvHeader="Exp. Qty" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Exp. Qty</TableHeaderColumn>
                            <TableHeaderColumn dataField="delivery_qty" searchable dataSort csvHeader="Exp.Delivered Qty" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Exp. Delivered Qty</TableHeaderColumn>
                            <TableHeaderColumn dataField="realization_qty" searchable dataSort csvHeader="Realization Qty" hidden={!isDeliveryFinish} thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="25%">Real Delivered Qty</TableHeaderColumn>
                            {/* <TableHeaderColumn dataField="production_date" searchable dataSort csvHeader="Production Date" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Production Date</TableHeaderColumn> */}
                            <TableHeaderColumn dataField="expired_date" searchable dataSort csvHeader="Expired Date" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Expiry</TableHeaderColumn>
                        </BootstrapTable>
                    </>
                    : null    
                }
                {idOutboundStocker && withRealizationFill?
                        <>
                          <BootstrapTable data={outboundItem} version="4" bordered={false} hover pagination search edit options={options} exportCSV={false} csvFileName={this.fileNameFormat} multiColumnSearch>
                              <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" searchable={false} hidden>ID</TableHeaderColumn>
                              <TableHeaderColumn dataField="item_name" searchable dataSort csvHeader="Item Name" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Item Name</TableHeaderColumn>
                              <TableHeaderColumn dataField="batch_number" searchable dataSort csvHeader="Batch Number" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Batch Number</TableHeaderColumn>
                              <TableHeaderColumn dataField="expected_qty" searchable dataSort csvHeader="Exp. Qty" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Exp. Qty</TableHeaderColumn>
                              <TableHeaderColumn dataField="delivery_qty" searchable dataSort csvHeader="Exp.Delivered Qty" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Exp. Delivered Qty</TableHeaderColumn>
                              <TableHeaderColumn dataField="realization_qty" searchable dataSort csvHeader="Realization Qty" dataFormat={ this.realDeliveryQtyInput.bind(this)} thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="25%">Real Delivered Qty</TableHeaderColumn>
                              {/* <TableHeaderColumn dataField="production_date" searchable dataSort csvHeader="Production Date" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Production Date</TableHeaderColumn> */}
                              <TableHeaderColumn dataField="expired_date" searchable dataSort csvHeader="Expired Date" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="20%">Expiry</TableHeaderColumn>
                          </BootstrapTable>
                        </>
                    : null
                }
            </>
        )
    }

    render(){
        const {idInboundStocker, idOutboundStocker, withRealizationFill, 
                outboundItem, inboundItem, showTable, isDeliveryFinish} = this.props
        return(
            <React.Fragment>
                <br />
                {showTable?
                    <Row>
                        <Col md="12" xs="12">
                            {this.showDataTable(idInboundStocker, inboundItem, idOutboundStocker, outboundItem, withRealizationFill, isDeliveryFinish)} 
                        </Col>
                    </Row>
                    : 
                    null
                }
            </React.Fragment>
        )
    }

}

export default CheckOutboundItemTable;
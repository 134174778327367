import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Col,
    Label,
    Input
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import * as API_LINKS from '../../../redux/config/links';
import axios from 'axios';
import {fetchUserTable} from '../../../redux/actions/aUser-UserListTable'
import {toast} from 'react-toastify'
import { BarLoader } from 'react-spinners';
import { AbilityContext} from '../../../casl/Can';
import _ from 'lodash';
import { activeFeature } from '../../../constants/constants';

const USERLEVEL_OPTION = [
    {
         'label': '-  Please select  -',
         'value': null
    },
    {
        'label': 'Admin',
        'value': '1'
    },
    {
        'label': 'Security',
        'value': '3'
    },
    {
        'label': 'Manager',
        'value': '2'
    },
    {
        'label': 'Superadmin',
        'value': '4'
    },
    {
        'label': 'HR',
        'value': '13'
    },
    {
        'label': 'Maintenance',
        'value': '14'
    },
]

class ManagementAddUser extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            modalAdd: false,
            selectedUserLevel: null,
         };
    }

    toggleOpenAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd
        })
    }

    toggleCloseAdd = () => {
        this.setState({
            modalAdd: !this.state.modalAdd
        })
    }

    handleChangeUserLevel = (selectedUserLevel) => {
        this.setState({ selectedUserLevel });
        // console.log(`Option selected:`, selectedUserLevel);
    }

    userLevelBasedOnSubs = (userLevelOptionArr) => {

        if(this.context.can("view","max_1_free@management_add_user_subs"))
            return _.at(userLevelOptionArr, [0,1])

        if(this.context.can("view","max_1_basic@management_add_user_subs"))
            return _.at(userLevelOptionArr, [0,4])

        if(this.context.can("view","max_3_medium@management_add_user_subs"))
            return _.at(userLevelOptionArr, [0,1,2,4])

        if(this.context.can("view","max_4_advanced@management_add_user_subs"))
            return _.at(userLevelOptionArr, [0,1,2,3,4,5,6])

    }
    viewAddUser = () => {
        const {selectedUserLevel} = this.state
        let userLevelBasedOnSubsArr = this.userLevelBasedOnSubs(USERLEVEL_OPTION.slice(0)); 
        var self = this;
        return (
            <div className="animated fadeIn container">
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">Email Address<span className="incomplete-cell">*</span></Label>
                    </Col>
                    <Col xs="12" md="9">
                    <Input type="email" name="text-input" id="addEmail" placeholder="john@doe.com" disabled={this.state.isAdding} required autoComplete="off"/>
                    </Col>
                </FormGroup> 
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">Password<span className="incomplete-cell">*</span></Label>
                    </Col>
                    <Col xs="12" md="9">
                    <Input type="password" name="text-input" id="addPassword" placeholder="*********" disabled={this.state.isAdding} required autoComplete="off"/>
                    </Col>
                </FormGroup> 
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">First Name<span className="incomplete-cell">*</span></Label>
                    </Col>
                    <Col xs="12" md="9">
                    <Input type="text" name="text-input" id="addFirstName" placeholder="John" disabled={this.state.isAdding} required autoComplete="off"/>
                    </Col>
                </FormGroup> 
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">Last Name<span className="incomplete-cell">*</span></Label>
                    </Col>
                    <Col xs="12" md="9">
                    <Input type="text" name="text-input" id="addLastName" placeholder="Doe" disabled={this.state.isAdding} required autoComplete="off"/>
                    </Col>
                </FormGroup> 
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">User Level<span className="incomplete-cell">*</span></Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Select
                            value={selectedUserLevel}
                            onChange={this.handleChangeUserLevel}
                            options={userLevelBasedOnSubsArr}
                            disabled={this.state.isAdding}
                            
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                    <Label htmlFor="text-input">Phone<span className="incomplete-cell">*</span></Label>
                    </Col>
                    <Col xs="12" md="9">
                    <Input type="text" name="text-input" id="addPhone" placeholder="628123456789" disabled={this.state.isAdding} required autoComplete="off"/>
                    </Col>
                </FormGroup> 
                {this.state.isAdding ? <center><BarLoader color={'#123abc'} 
                  loading={this.state.isAdding} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => this.handleAdd()} size="sm" color="success" disabled={self.state.isAdding}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                <Button onClick={() => this.toggleCloseAdd()} size="sm" color="warning" disabled={self.state.isAdding}><i className="fa fa-window-close"></i> Close</Button></div>} 
            </div>
        )
    }

    isValidEmailAddress(emailAddress) {
        var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
        return pattern.test(emailAddress);
    }

    totalUsersBasedOnSubs = ()=> {

        if(this.context.can("create","max_1_free@total_users_subs"))
            return 1

        if(this.context.can("create","max_2_basic@total_users_subs"))
            return 2

        if(this.context.can("create","max_5_medium@total_users_subs"))
            return 5

        if(this.context.can("create","max_10_advanced@total_users_subs"))
            return 30

    }

    handleAdd = () => {

        // const totalUsersLimitSubs = this.props.maxTotalUser;
        const totalUsersLimitSubs = activeFeature.TOTAL_USERS_LIMIT_SUBS;
        const userTableData = this.props.userTableData.result;
        const userDataFilteredByLevel = userTableData.filter((user) => (user.id_level < 5))
        if(userDataFilteredByLevel.length >= totalUsersLimitSubs){

            toast.error('Upgrade your subscription to insert another user!');

        } else {
            var self = this;
            var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
            var auth = loginCreds.token;

            //Variables for text input
            var _firstName = document.querySelector('#addFirstName').value;
            var _lastName = document.querySelector('#addLastName').value;
            var _email = document.querySelector('#addEmail').value;
            var _password = document.querySelector('#addPassword').value;
            var _phone = document.querySelector('#addPhone').value;

            var _userLevel;

            if(this.state.selectedUserLevel === null || this.state.selectedUserLevel === "") {
                _userLevel = ''
            } else {
                _userLevel = this.state.selectedUserLevel.value
            }

            const nameRegex = /^[a-zA-Z ]{2,30}$/;

            if (_firstName === "" || _firstName === null || !nameRegex.test(_firstName)) {
                self.setState({isAdding: false})
                toast.error("First Name is Invalid! Please fill in the visitor name! (Alphabets only)")
            } else if (_lastName === "" || _lastName === null || !nameRegex.test(_lastName)) {
                self.setState({isAdding: false})
                toast.error("Last Name is Invalid! Please fill in the visitor name! (Alphabets only)")
            } else if (_email === null || _email === "" || !this.isValidEmailAddress(_email)) {
                this.setState({
                    isAdding: false
                })
                toast.error('Email address is not valid!!')
            } else if (_password === null || _password === "") {
                self.setState({
                    isAdding: false
                })
                toast.error('Password is not valid!!')
            } else if (_password.length < 8) {
                self.setState({
                    isAdding: false
                })
                toast.error('Password minimum length 8 characters!!')
            } else {
                axios({
                    method:'post',
                    url: API_LINKS.REGISTER_URL, //Please change when it's ready
                    headers: {
                        Authorization: auth
                    },
                    data: {
                        email: _email,
                        password: _password,
                        first_name: _firstName,
                        last_name: _lastName,
                        phone: _phone,
                        id_level: _userLevel
                    }
                })
                .then((response) => {
                    // console.log(response)
                    if(response.status === 200) {
                        if (response.data.status === "success") {
                            self.setState({
                                isAdding: false,
                                selectedUserLevel: null
                            })
                            self.toggleCloseAdd()
                            self.props.fetchUserTable()
                            return toast.success('User has been registered!');
                        }
                    }
                })
                .catch(function(error){
                    if (error.response) {
                        if(error.response.status === 401) {
                            self.setState({
                                isAdding: false,
                            })
                            return toast.error(error.response.data.message);
                        } else if (error.response.status === 403) {
                            self.setState({
                                isAdding: false,
                            })
                            return toast.error(error.response.data.message);
                        } else if (error.response.status === 400) {
                            self.setState({
                                isAdding: false,
                            })
                            return toast.error(error.response.data.message);
                        } else if (error.response.status === 404 || error.response.status === 500) {
                            self.setState({
                                isAdding: false,
                            })
                            return toast.error("Server cannot be contacted! Please ask your system administrator!");
                        } else {
                            self.setState({
                                isAdding: false,
                            })
                            return toast.error('Something went wrong... Please try again later...')
                        }
                    } else if (error.request) {
                        self.setState({
                            isAdding: false,
                        })
                        return toast.error('Request error! Please try again later...')
                    } else {
                        self.setState({
                            isAdding: false,
                        })
                        return toast.error('Something went wrong... Please try again later...')
                    }
                })
            }
        }
    }

    render() {
        return (
            <div>
                <Button onClick={this.toggleOpenAdd} size="sm" color="success" disabled={this.props.inProgress}><i className="fa fa-plus"></i> Add User</Button> &nbsp; &nbsp; 

                {/* Modal for Adding Cards */}
                <Modal isOpen={this.state.modalAdd} toggle={this.toggleOpenAdd} className={'modal-lg ' + this.props.className} backdrop="static" >
                <ModalHeader toggle={this.toggleCloseAdd}>Add New User to Your Company</ModalHeader>
                <ModalBody>
                        {this.viewAddUser()}
                </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userTableData: state.userTableStore.userTableData,
        inProgress: state.userTableStore.inProgress,

        maxTotalUser: state.authStore.subs.user_amount,
      }
}

ManagementAddUser.contextType = AbilityContext;
export default connect(mapStateToProps, {fetchUserTable})(ManagementAddUser);

import React from 'react';
import DashboardDetails from '../views/Dashboard/DashboardDetails/DashboardDetails.jsx';
import WelcomeMessage from '../containers/DefaultLayout/WelcomeMessage'

//Enviroma files import
import EnviromaDevices from '../views/LandLord/Devices/Devices.jsx';
import EnviromaTemperature from '../views/LandLord/Temperature/Temperature.jsx';

//Fleet files import
// import VehicleMonitoring from '../views/Fleet/VehicleMonitoring/VehicleMonitoring.jsx';
import MonitorDetails from '../views/Fleet/MonitorDetails/MonitorDetails.jsx';
import Drivers from '../views/Fleet/Drivers/Drivers.jsx';
import DriversAssistant from '../views/Fleet/DriverAssistants/DriverAssistants.jsx';
import Fleets from '../views/Fleet/Fleets/Fleets.jsx';
import FleetsReport from '../views/Fleet/Fleets/Fleets-Report.jsx';
import SavedPlaces from '../views/Fleet/SavedPlaces/SavedPlaces.jsx';
import LiveMonitoring from '../views/Fleet/LiveMonitoring/LiveMonitoringTabs'
import Security from '../views/Fleet/Security/Security.jsx';

//User Management Files Import
import Login from '../views/Users/Login/Login';
import Logout from '../views/Users/Logout/Logout';
import Register from '../views/Users/Register/Register';
import UserManagement from '../views/Users/Management/Management';

// Delivery Management Files Import
import DeliveryOrders from '../views/Fleet/DeliveryOrders/DeliveryOrders';
import WorkOrders from '../views/Fleet/WorkOrders/WorkOrdersNew';
import ReportDeliveryOrders from '../views/Fleet/Report-DeliveryOrders/Report-DeliveryOrders';

//Settings
import Settings from '../views/Settings/Settings';

const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));
const FleetOperations = React.lazy(() => import('../views/Fleet/FleetOperations/FleetOperations'));
const DeliveryAudit = React.lazy(() => import('../views/Fleet/DeliveryOrders/DeliveryOrderAudit/DeliveryOrderAudit'));
const WOReview = React.lazy(() => import('../views/Fleet/Report/WOReview/WOReview'));
const WOPODConfirmReview = React.lazy(() => import('../views/Fleet/Report/WOPODVerif/WOPODVerif'));
const FleetMaintenance = React.lazy(() => import('../views/Fleet/FleetMaintenance/FleetMaintenanceMain.jsx'));
const SMMechanicTask = React.lazy(() => import('../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMMechanicTask/SMMechanicTask.jsx'));
const SMApprovalSetting = React.lazy(() => import('../views/Settings/FleetMaintenanceSettings/ApprovalType/ApprovalTypeSetting'));
const SMApproval = React.lazy(() => import('../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMApproval'));
const SMMechanicTaskDetail =  React.lazy(() => import('../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMMechanicTask/SMMechanicTaskDetail'));
const Mechanics = React.lazy(() => import('../views/Fleet/Mechanics/MechanicsMain.jsx'));
const MechanicsCertification = React.lazy(() => import('../views/Fleet/Mechanics/MechanicsCertification/MechanicsCertification.jsx'));
const MechanicsSpecialization = React.lazy(() => import('../views/Fleet/Mechanics/MechanicsSpecialization/MechanicsSpecialization.jsx'));
const MechanicsOperationalHour = React.lazy(() => import('../views/Fleet/Mechanics/MechanicsOperationalHour/MechanicsOperationalHour.jsx'));
const WSMMechanicTask = React.lazy(() => import('../views/Fleet/FleetMaintenance/ScheduledMaintenanceWorkshop/SMMechanicTask/SMMechanicTask'));
const WSMMechanicTaskDetail =  React.lazy(() => import('../views/Fleet/FleetMaintenance/ScheduledMaintenanceWorkshop/SMMechanicTask/SMMechanicTaskDetail'));

const Workshop = React.lazy(() => import('../views/Fleet/Workshop/WorkshopMain.jsx'));
const WorkshopOperationalHour = React.lazy(() => import('../views/Fleet/Workshop/WorkshopOperationalHour/WorkshopOperationalHour.jsx'));

const CurrativeOrder = React.lazy(() => import('../views/Fleet/FleetMaintenance/CurrativeOrders'));
const CheckSheet = React.lazy(() => import('../views/Fleet/CheckSheet'));
const CheckSheetDetail = React.lazy(() => import('../views/Fleet/CheckSheet/MainCheckSheet/MainCheckSheetDetail'));
const CheckSheetApprovalSetting = React.lazy(() => import('../views/Fleet/CheckSheet/ApprovalType/ApprovalTypeSetting'));
const DoCheckSheetApproval = React.lazy(() => import('../views/Fleet/DeliveryOrders/DOChecksheetApproval'));
const PackageMaintenanceTask = React.lazy(() => import('../views/Settings/FleetMaintenanceSettings/PackageMaintenance/PackageMaintenanceTask'));
const PreventiveMaintenanceTask = React.lazy(() => import('../views/Settings/FleetMaintenanceSettings/PreventiveMaintenance/PreventiveMaintenanceTask'));

// const DashboardDetails = React.lazy(() => import('./views/DashboardDetails/DashboardDetails.jsx'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const managerRoutes = [
  { path: '/welcome', name: 'Welcome Message', component: WelcomeMessage , exact: true },
  { path: '/transporter/dashboard', name: 'Dashboard', component: Dashboard, exact:true },
  { path: '/dashboard/details', name: 'Details', component: DashboardDetails },
  { path: '/transporter/wo-review/:month/:id', name: 'Work Order Review', component: WOReview, exact: true },
  { path: '/transporter/wo-pod-verif-review/:id', name: 'Work Order POD Verification Review', component: WOPODConfirmReview, exact: true },

  //Enviroma
  { path: '/enviroma/devices', name: 'Enviroma Devices', component: EnviromaDevices },
  { path: '/enviroma/temperature', name: 'Enviroma Temperature', component: EnviromaTemperature },
  
  //Fleet
  { path: '/transporter/live-monitoring', name: 'Live Monitoring', component: LiveMonitoring, exact: true },
  { path: '/transporter/delivery/audit/:id', name: 'Delivery Order Audit', component: DeliveryAudit, exact: true },
  // { path: '/transporter/monitoring', name: 'Fleet Monitoring', component: VehicleMonitoring, exact: true },
  { path: '/transporter/monitoring/details', name: 'Monitor Details', component: MonitorDetails },
  { path: '/transporter/fleets', name: 'Fleets', component: Fleets, exact: true },
  { path: '/transporter/fleet-operations', name: 'Fleet Operations', component: FleetOperations, exact: true},
  { path: '/transporter/fleets/report/:id', name: 'Fleet Report', component: FleetsReport },
  { path: '/transporter/drivers', name: 'Drivers', component: Drivers },
  { path: '/transporter/drivers-assistant', name: 'Drivers Assistant', component: DriversAssistant },
  { path: '/transporter/delivery-order', name: 'Delivery Order', component: DeliveryOrders },
  { path: '/transporter/saved-places', name: 'Saved Places', component: SavedPlaces },
  { path: '/transporter/security', name: 'Security', component: Security },
  { path: '/transporter/work-order/:id', name: 'Work Order', component: WorkOrders,exact: true },
  { path: '/transporter/report/delivery/:id', name: 'Delivery Order Report', component: ReportDeliveryOrders, exact: true },

  { path: '/transporter/mechanics', name: 'Mechanics', component: Mechanics, exact: true },
  { path: '/transporter/mechanics/certification/:id', name: 'Mechanics Certification', component: MechanicsCertification, exact: true },
  { path: '/transporter/mechanics/specialization/:id', name: 'Mechanics Specialization', component: MechanicsSpecialization, exact: true },
  { path: '/transporter/mechanics/operational-hour/:id', name: 'Mechanics Operational Hour', component: MechanicsOperationalHour, exact: true },
  { path: '/transporter/workshop', name: 'Workshop', component: Workshop, exact: true },
  { path: '/transporter/workshop/operational-hour/:id', name: 'Workshop Operational Hour', component: WorkshopOperationalHour, exact: true },
  { path: '/transporter/fleet-maintenance', name: 'Fleet Maintenance', component: FleetMaintenance, exact: true },
  { path: '/transporter/fleet-maintenance/mechanic-task/:id', name: 'Scheduled Maintenance Mechanic Task', component: SMMechanicTask, exact: true },
  { path: '/transporter/fleet-maintenance/mechanic-task-detail/:id', name: 'Scheduled Maintenance Mechanic Task Detail', component: SMMechanicTaskDetail, exact: true },  
  { path: '/transporter/fleet-maintenance/workshop-mechanic-task/:id', name: 'Scheduled Maintenance Mechanic Task', component: WSMMechanicTask, exact: true },
  { path: '/transporter/fleet-maintenance/workshop-mechanic-task-detail/:id', name: 'Scheduled Maintenance Mechanic Task Detail', component: WSMMechanicTaskDetail, exact: true },
  { path: '/transporter/currative-order', name: 'Currative Order', component: CurrativeOrder, exact: true },
  { path: '/transporter/check-sheet', name: 'Check Sheet', component: CheckSheet, exact: true },
  { path: '/transporter/check-sheet/details/:id', name: 'Check Sheet Detail', component: CheckSheetDetail, exact: true },
  { path: '/transporter/check-sheet/approval-setting/:id', name: 'Check Sheet Approval Setting', component: CheckSheetApprovalSetting, exact: true },
  { path: '/transporter/do-check-sheet/approval/:id', name: 'Do Check Sheet Approval', component: DoCheckSheetApproval, exact: true },
  { path: '/transporter/package-maintenance-task/:id', name: 'Package Maintenance', component: PackageMaintenanceTask, exact: true },
  { path: '/transporter/preventive-maintenance-task/:id', name: 'Preventive Maintenance', component: PreventiveMaintenanceTask, exact: true },
  { path: '/transporter/scheduled-maintenance/approval-setting/:id', name: 'Scheduled Maintenance Approval Setting', component: SMApprovalSetting, exact: true },
  { path: '/transporter/scheduled-maintenance/approval/:id', name: 'Scheduled Maintenance Approval', component: SMApproval, exact: true },


  //User Management
  { path: '/login', name: 'Login', component: Login },
  { path: '/logout', name: 'Logout', component: Logout },
  { path: '/register', name: 'Register', component: Register },
  { path: '/user/management', name: 'User Management', component: UserManagement },
  { path: '/settings', name: 'Settings', component: Settings },
];

export default managerRoutes;

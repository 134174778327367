import React, { Component } from 'react';
import {Card, CardBody, Table, Button, Badge} from 'reactstrap';
// import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {Gmaps, Marker, InfoWindow, Circle} from 'react-gmaps';
import {Link} from 'react-router-dom';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
// import data from './data.js';
import moment from 'moment';
import CarDetailsTable from './MonitorDetails-DataTable.jsx';

const params = {
    v: '3.exp', 
    key: 'AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg'
  };

class MonitorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            selectedDay: Date(),
            lat: -6.1729876,
            long: 106.8287232,
        };
        this.handleDayChange = this.handleDayChange.bind(this);
    }

    handleDayChange(day) {
        this.setState({ selectedDay: day });
    }

    onMapCreated(map) {
        map.setOptions({
          disableDefaultUI: false
        });
    }

    render() {
        const { selectedDay } = this.state;
        return (
            <div className="animated fadeIn">
                <div>
                <Link to="/transporter/monitoring"><Button className="bg-info"><i className="fa fa-angle-left"></i> Back</Button></Link><br/><br/>
                </div>

                <Card className="fleet-mondtl-card">
                    <h2>Toyota Avanza</h2>
                    <div className="fleet-mondtl-container">
                        <div className="fleet-mondtl-table">
                            <Table responsive bordered={false}>
                                <tbody>
                                    <tr>
                                        <td>Plate Number</td>
                                        <td>&nbsp;</td>
                                        <td>B 8081 OXN</td>
                                    </tr>
                                    <tr>
                                        <td>Model</td>
                                        <td>&nbsp;</td>
                                        <td>Toyota Avanza Veloz</td>
                                    </tr>
                                    <tr>
                                        <td>OBD ID</td>
                                        <td>&nbsp;</td>
                                        <td>ABCH138-1289</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="fleet-mondtl-table">
                            <Table responsive bordered={false}>
                                <tbody>
                                    <tr>
                                        <td>Distance</td>
                                        <td>&nbsp;</td>
                                        <td>92 KM</td>
                                    </tr>
                                    <tr>
                                        <td>Fuel Spent</td>
                                        <td>&nbsp;</td>
                                        <td>10 L</td>
                                    </tr>
                                    <tr>
                                        <td>Avg. Speed</td>
                                        <td>&nbsp;</td>
                                        <td>30 KPH</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="fleet-mondtl-table">
                            <Table responsive bordered={false}>
                                <tbody>
                                    <tr>
                                        <td>Travel Time</td>
                                        <td>&nbsp;</td>
                                        <td>04:34:21</td>
                                    </tr>
                                    <tr>
                                        <td>Idle Time</td>
                                        <td>&nbsp;</td>
                                        <td>05:23:44</td>
                                    </tr>
                                    <tr>
                                        <td>Total Time</td>
                                        <td>&nbsp;</td>
                                        <td>09:58:05</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Card>

                <div className="fleet-mondtl-container">
                    <div className="fleet-mondtl-panel">
                        <Card>
                            {/* <div>
                                <Badge color="dark" className="fleet-monitor-panel-title">Select date:</Badge>
                            </div> */}
                            <CardBody>
                                <div className="fleet-date-panel-text">
                                    {selectedDay && <p><b>Selected date: </b><br/> {moment(selectedDay).format("ddd, MMM Do YYYY")}</p>}
                                    {!selectedDay && <p>No date selected</p>}
                                    <DayPickerInput 
                                        onDayChange={this.handleDayChange} 
                                    />
                                </div>
                            </CardBody>
                        </Card>

                        <Card>
                            <div>
                                <Badge color="danger" className="fleet-monitor-panel-title">No. of Alerts Raised</Badge>
                            </div>
                            <CardBody>
                                <div className="fleet-monitor-panel-alert">0</div>
                            </CardBody>
                        </Card>

                        <Card>
                            <div>
                                <Badge color="info" className="fleet-monitor-panel-title">Last Trip</Badge>
                            </div>
                            <CardBody>
                                <div className="fleet-mondtl-panel-time">{moment(Date()).format("ddd, MMM Do YYYY, h:mm:ss A")}</div>
                            </CardBody>
                        </Card>
                    </div>

                    <div className="fleet-mondtl-obd-data">
                        <Card>
                            <CardBody>
                                <CarDetailsTable/>
                                {/* <BootstrapTable data={data.rows} version="4" responsive bordered={false} hover pagination search edit exportCSV={true} >
                                    <TableHeaderColumn dataField="id" isKey dataSort csvHeader="id" hidden>id</TableHeaderColumn>
                                    <TableHeaderColumn dataField="activity" dataSort csvHeader="Activity" thStyle={ { whiteSpace: 'normal' } } width="10%">Activity</TableHeaderColumn>
                                    <TableHeaderColumn dataField="start" dataSort csvHeader="Start" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Start</TableHeaderColumn>
                                    <TableHeaderColumn dataField="finish" dataSort csvHeader="Finish" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Finish</TableHeaderColumn>
                                    <TableHeaderColumn dataField="duration" dataSort csvHeader="Duration" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Duration</TableHeaderColumn>
                                    <TableHeaderColumn dataField="distance" dataSort csvHeader="Distance" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Distance</TableHeaderColumn>
                                    <TableHeaderColumn dataField="gps" dataSort csvHeader="GPS Coordinate" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">GPS</TableHeaderColumn>
                                    <TableHeaderColumn dataField="checkin" dataSort csvHeader="Check In" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Check In</TableHeaderColumn>
                                    <TableHeaderColumn dataField="velocity" dataSort csvHeader="Velocity" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Velocity</TableHeaderColumn>
                                </BootstrapTable> */}
                            </CardBody>
                        </Card>
                    </div>

                    <div className="fleet-mondtl-map">
                        <Gmaps
                            width="100%"
                            height="30vw"
                            lat={this.state.lat}
                            lng={this.state.long}
                            zoom={16}
                            loadingMessage={"Please wait while we're preparing this for you..."}
                            params={params}
                            onMapCreated={this.onMapCreated}>
                            <Marker
                                lat={this.state.lat}
                                lng={this.state.long}
                                draggable={true}
                                onDragEnd={this.onDragEnd}
                            />
                            <InfoWindow
                                lat={this.state.lat+0.0008}
                                lng={this.state.long}
                                content={"<strong>Lat: </strong>"+this.state.lat+"<strong>,<br/> Long: </strong>"+this.state.long}
                                onCloseClick={this.onCloseClick}
                            />
                            <Circle
                                lat={this.state.lat}
                                lng={this.state.long}
                                radius={100}
                                // onClick={this.onClick}
                            />
                        </Gmaps>
                    </div>
                </div>
            </div>    
        );
    }
}

export default MonitorDetails;
import React from "react";
// import DashboardDetails from '../views/Dashboard/DashboardDetails/DashboardDetails.jsx';
import WelcomeMessage from "../containers/DefaultLayout/WelcomeMessage";


//User Management Files Import
import Login from "../views/Users/Login/Login";
import Logout from "../views/Users/Logout/Logout";
import Register from "../views/Users/Register/Register";
import UserManagement from "../views/Users/Management/Management";

// Delivery Management Files Import
import DeliveryOrders from "../views/Fleet/DeliveryOrders/DeliveryOrders";
const CheckSheet = React.lazy(() => import("../views/Fleet/CheckSheet"));
const CheckSheetDetail = React.lazy(() =>
  import("../views/Fleet/CheckSheet/MainCheckSheet/MainCheckSheetDetail")
);
const CheckSheetApprovalSetting = React.lazy(() =>
  import("../views/Fleet/CheckSheet/ApprovalType/ApprovalTypeSetting")
);
const DoCheckSheetApproval = React.lazy(() =>
  import("../views/Fleet/DeliveryOrders/DOChecksheetApproval")
);


const FleetOperations = React.lazy(() =>
  import("../views/Fleet/FleetOperations/FleetOperations.jsx")
);
const Mechanics = React.lazy(() =>
  import("../views/Fleet/Mechanics/MechanicsMain.jsx")
);
const FleetMaintenance = React.lazy(() =>
  import("../views/Fleet/FleetMaintenance/FleetMaintenanceMain.jsx")
);

const MechanicsCertification = React.lazy(() =>
  import(
    "../views/Fleet/Mechanics/MechanicsCertification/MechanicsCertification.jsx"
  )
);

const SMMechanicTask = React.lazy(() =>
  import(
    "../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMMechanicTask/SMMechanicTask.jsx"
  )
);

const SMMechanicTaskDetail = React.lazy(() =>
  import(
    "../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMMechanicTask/SMMechanicTaskDetail"
  )
);

// const DashboardDetails = React.lazy(() => import('../views/DashboardDetails/DashboardDetails.jsx'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/welcome",
    name: "Welcome Message",
    component: WelcomeMessage,
    exact: true,
  },
  {
    path: "/transporter/delivery-order",
    name: "Delivery Order",
    component: DeliveryOrders,
  },
  {
    path: "/transporter/check-sheet",
    name: "Check Sheet",
    component: CheckSheet,
    exact: true,
  },
  {
    path: "/transporter/check-sheet/details/:id",
    name: "Check Sheet Detail",
    component: CheckSheetDetail,
    exact: true,
  },
  {
    path: "/transporter/check-sheet/approval-setting/:id",
    name: "Check Sheet Approval Setting",
    component: CheckSheetApprovalSetting,
    exact: true,
  },
  {
    path: "/transporter/do-check-sheet/approval/:id",
    name: "Do Check Sheet Approval",
    component: DoCheckSheetApproval,
    exact: true,
  },
  { path: "/login", name: "Login", component: Login },
  { path: "/logout", name: "Logout", component: Logout },
  { path: "/register", name: "Register", component: Register },
  {
    path: "/user/management",
    name: "User Management",
    component: UserManagement,
  },
  {
    path: "/transporter/fleet-operations",
    name: "Fleet Operations",
    component: FleetOperations,
    exact: true,
  },
  {
    path: "/transporter/mechanics",
    name: "Mechanics",
    component: Mechanics,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance",
    name: "Fleet Maintenance",
    component: FleetMaintenance,
    exact: true,
  },
  {
    path: "/transporter/mechanics/certification/:id",
    name: "Mechanics Certification",
    component: MechanicsCertification,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance/mechanic-task/:id",
    name: "Scheduled Maintenance Mechanic Task",
    component: SMMechanicTask,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance/mechanic-task-detail/:id",
    name: "Scheduled Maintenance Mechanic Task Detail",
    component: SMMechanicTaskDetail,
    exact: true,
  },
];

export default routes;

import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import WalletIcon from "../../../../assets/img/icons/WalletIcon";
import ConfirmPassword from "./contents/PaymentConfirmPassword";
import { fetchTriPLIncomingBidList } from "../../../../redux/actions/transporter/aTransporter-TriPLWOIncomingBidList";

const PaymentSelection = ({ onClose }) => {
  const { walletSaldo } = useSelector((state) => ({
    walletSaldo: state.walletSaldo,
  }));
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    walletPayment: null,
  });
  const [modalConfirmation, setModalConfirmation] = useState(false);

  const buttonWalletPayment = () => {
    if (walletSaldo.currentSaldo < walletSaldo.paymentTotalAmount) {
      setErrors({ ...errors, walletPayment: "Saldo kurang!" });
    } else {
      setModalConfirmation(true);
    }
  };
  return (
    <>
      <form>
        <FormGroup row>
          <Col
            xs="12"
            md="12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              name="payment_type"
              color={"primary"}
              disabled={walletSaldo.paymentTotalAmount === null}
              onClick={() => buttonWalletPayment()}
            >
              <WalletIcon color="#fff" size={12} />
              &nbsp;Wallet Payment
            </Button>
            {errors.walletPayment ? (
              <p style={{ color: "red" }}>{errors.walletPayment}</p>
            ) : null}
          </Col>
        </FormGroup>
        {/* <FormGroup row>
          <Col
            xs="12"
            md="12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              name="payment_type"
              color={"primary"}
              onClick={() => window.open(walletSaldo.xenditUrl, "_blank")}
            >
              <i className="fa fa-money"></i> Payment
            </Button>
          </Col>
        </FormGroup> */}
        <br />
      </form>
      <Modal
        size="md"
        isOpen={modalConfirmation}
        toggle={() => setModalConfirmation(false)}
        backdrop="static"
      >
        <ModalHeader toggle={() => setModalConfirmation(false)}>
          Password Confirmation
        </ModalHeader>
        <ModalBody>
          <ConfirmPassword
            onClose={() => setModalConfirmation(false)}
            onReload={() => {
              dispatch(fetchTriPLIncomingBidList());
              walletSaldo.additionalFunction()
              onClose();
              return null
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default PaymentSelection;

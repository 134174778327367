import React, { Component } from "react";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import { COMPANY_EDIT_URL } from "../../../redux/config/links";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { toast } from "react-toastify";
import WorkshopTypesConfigurations from "./CompanyTypesConfigurations/Workshop";
import "./CompanySettingForm.css";
import { FaCheckCircle } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import CustomToast from "../../../components/CustomToast/CustomToast";

const validationSchema = function () {
  return Yup.object().shape({
    company: Yup.string()
      .min(2, `Company Name has to be at least 2 characters`)
      .required("Company name is required"),
    address: Yup.string()
      .min(10, `Company Address has to be at least 10 character`)
      .required("Company Address is required"),
    lat: Yup.number().optional,
    lon: Yup.number().optional,
    telp: Yup.number().required("Phone number is required!"),
    kml_link: Yup.string().url("KML Links is not valid!"),
    custom_link: Yup.string()
      .min(4, `Custom link has to be at least 4 characters`)
      .max(10, "Custom link cannot be longer than 10 characters")
      .required("Custom link is required"),
  });
};

const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

const COMPANY_TYPES = [
  {
    id: 1,
    name: "logistic",
    label: "Logistic",
    isSettingAvail: false,
  },
  {
    id: 2,
    name: "workshop",
    label: "Workshop",
    isSettingAvail: true,
  },
  {
    id: 3,
    name: "utility-company",
    label: "Utility Company",
  },
  {
    id: 4,
    name: "trading-supplier-vendor-customer",
    label: "Trading Supplier /Vendor /Customer",
    isSettingAvail: false,
  },
  {
    id: 5,
    name: "external-warehouse-provider-customer",
    label: "External Warehouse Provider /Customer",
    isSettingAvail: true,
  },
];

class CompanySettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      companyTypesChecked: {
        1: false,
        2: false,
        // "3": false
      },
      selectedSettingId: 0,
      modalCompanyTypeSetting: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { initialValues } = this.props;
    const { companyTypesChecked } = this.state;
    let changedCompanyTypesChecked = companyTypesChecked;
    let getCompanyIdType = initialValues.id_type;
    changedCompanyTypesChecked[getCompanyIdType] = true;
    this.setState({
      initialValues: initialValues,
      companyTypesChecked: changedCompanyTypesChecked,
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      initialValues: props.initialValues,
    });
  }

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    this.fetchEditCompany(values);
    setSubmitting(false);
  };

  settingsComponentFactory = (typeId) => {
    const { initialValues } = this.state;
    let id_workshop = initialValues.configs.workshop.id_workshop;
    switch (typeId) {
      case 2:
        return <WorkshopTypesConfigurations id_workshop={id_workshop} />;
      default:
        return null;
    }
  };

  settingsWordingFactory = (typeId) => {
    switch (typeId) {
      case 1:
        return "Logistic";
      case 2:
        return "Workshop";
      case 3:
        return "Utility Company";
      case 4:
        return "Trading Supplier /Vendor /Customer";
      case 5:
        return "External Warehouse Provider /Customer";
      default:
        return typeId;
    }
  };

  openModalCompanyTypeSetting = () => {
    this.setState({
      modalCompanyTypeSetting: true,
    });
  };

  closeModalCompanyTypeSetting = () => {
    this.setState({
      modalCompanyTypeSetting: false,
    });
  };

  CompanyTypesCheckBox = () => {
    const { companyTypesChecked } = this.state;
    return (
      <FormGroup>
        <Label for="company_type">Company Type</Label>
        <FormGroup
          check
          style={{
            border: "1px solid grey",
            padding: "15px",
          }}
        >
          <Row
            style={{
              "padding-left": "15px",
            }}
          >
            {COMPANY_TYPES.map((typesObj) => (
              <Col md="3" xs="3">
                <Input
                  className=""
                  id={typesObj.id}
                  name={typesObj.name}
                  type="checkbox"
                  disabled={true}
                  defaultChecked={companyTypesChecked[typesObj.id]}
                  onChange={(e) => {
                    let checkBoxProp = e.target;
                    let changedCompanyTypesChecked = companyTypesChecked;
                    let typesId = typesObj.id;
                    if (checkBoxProp.checked)
                      changedCompanyTypesChecked[typesId] = true;
                    else changedCompanyTypesChecked[typesId] = false;
                    this.setState({
                      companyTypesChecked: changedCompanyTypesChecked,
                    });
                  }}
                />
                <Label for={typesObj.id}>{typesObj.label}</Label>
                &nbsp;&nbsp;
                {companyTypesChecked[typesObj.id] && typesObj.isSettingAvail ? (
                  <Button
                    color="info"
                    size="sm"
                    className="mr-1"
                    onClick={() =>
                      this.setState(
                        {
                          selectedSettingId: typesObj.id,
                        },
                        () => {
                          this.openModalCompanyTypeSetting();
                        }
                      )
                    }
                  >
                    <i className="fa fa-cog" style={{ color: "white" }}></i>
                  </Button>
                ) : null}
              </Col>
            ))}
          </Row>
        </FormGroup>
      </FormGroup>
    );
  };

  fetchEditCompany = (values) => {
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    var self = this;

    this.setState({
      isLoading: true,
    });
    // console.log(values);
    axios({
      method: "post",
      url: COMPANY_EDIT_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        address: values.company_address,
        lat: values.lat,
        lon: values.lon,
        telp: values.telp,
        kml_link: values.kml_link,
        custom_link: values.custom_link,
      },
    })
      .then(() => {
        self.setState({ isLoading: false });
        return toast.success(
          <CustomToast
            text="Company data successfully changed!"
            icon={<FaCheckCircle />}
          />,
          {
            autoClose: 3000,
          }
        );
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({ isLoading: false });
            return toast.error(
              <CustomToast
                text={error.response.data.message}
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({ isLoading: false });
            return toast.error(
              <CustomToast
                text={error.response.data.message}
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          } else if (error.response.status === 400) {
            self.setState({ isLoading: false });
            return toast.error(
              <CustomToast
                text={error.response.data.message}
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          } else {
            self.setState({ isLoading: false });
            return toast.error(
              <CustomToast
                text="Something went wrong... Please try again later..."
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          }
        } else if (error.request) {
          self.setState({ isLoading: false });
          return toast.error(
            <CustomToast
              text="Request error! Please try again later..."
              icon={<BiSolidError />}
            />,
            {
              autoClose: 3000,
            }
          );
        } else {
          self.setState({ isLoading: false });
          return toast.error(
            <CustomToast
              text="Something went wrong... Please try again later..."
              icon={<BiSolidError />}
            />,
            {
              autoClose: 3000,
            }
          );
        }
      });
  };

  render() {
    const {
      initialValues,
      isLoading,
      modalCompanyTypeSetting,
      selectedSettingId,
    } = this.state;
    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={validate(validationSchema)}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => (
            <Row>
              <Col lg="12">
                <Form onSubmit={handleSubmit} noValidate name="companyForm">
                  <FormGroup>
                    <Label for="company">Company Name</Label>
                    <Input
                      type="text"
                      name="company"
                      id="company"
                      placeholder="Company Name"
                      autoComplete="company-name"
                      valid={!errors.company}
                      invalid={touched.company && !!errors.company}
                      autoFocus={true}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company}
                      disabled={true}
                    />
                    <FormFeedback>{errors.company}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="company_address">Company Address</Label>
                    <Input
                      type="textarea"
                      name="company_address"
                      id="company_address"
                      placeholder="Company Address"
                      autoComplete="company-address"
                      valid={!errors.company_address}
                      invalid={
                        touched.company_address && !!errors.company_address
                      }
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_address}
                      disabled={isSubmitting || isLoading}
                    />
                    <FormFeedback>{errors.company_address}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="lat">Latitude</Label>
                    <Input
                      type="text"
                      name="lat"
                      id="lat"
                      placeholder="-6.xxxx"
                      autoComplete="lat"
                      valid={!errors.lat}
                      invalid={touched.lat && !!errors.lat}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lat}
                      disabled={isSubmitting || isLoading}
                    />
                    <FormFeedback>{errors.lat}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="lon">Longitude</Label>
                    <Input
                      type="text"
                      name="lon"
                      id="lon"
                      placeholder="-101.xxxx"
                      autoComplete="lon"
                      valid={!errors.lon}
                      invalid={touched.lon && !!errors.lon}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lon}
                      disabled={isSubmitting || isLoading}
                    />
                    <FormFeedback>{errors.lon}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="telp">Phone (Whatsapp)</Label>
                    <Input
                      type="text"
                      name="telp"
                      id="telp"
                      placeholder="ex 628xxxxxx"
                      autoComplete="telp"
                      valid={!errors.telp}
                      invalid={touched.telp && !!errors.telp}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.telp}
                      disabled={isSubmitting || isLoading}
                    />
                    <FormFeedback>{errors.telp}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="kml_link">Company KML</Label>
                    <Input
                      type="text"
                      name="kml_link"
                      id="kml_link"
                      placeholder="ex: https://google.com/xxxxx"
                      autoComplete="kml_link"
                      valid={!errors.kml_link}
                      invalid={touched.kml_link && !!errors.kml_link}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.kml_link}
                      disabled={isSubmitting || isLoading}
                    />
                    <FormFeedback>{errors.kml_link}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="company">Custom 3PL TPL Link</Label>
                    <Input
                      type="text"
                      name="custom_link"
                      id="custom_link"
                      placeholder="automa (just type your short link) i.e https://3pl.automa.id/#/c/automa"
                      valid={!errors.custom_link}
                      invalid={touched.custom_link && !!errors.custom_link}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.custom_link}
                      disabled={isSubmitting || isLoading}
                    />
                    <FormFeedback>{errors.custom_link}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <this.CompanyTypesCheckBox />
                    <Button
                      type="submit"
                      color="primary"
                      className="mr-1"
                      disabled={isSubmitting || !isValid || isLoading}
                    >
                      {isSubmitting || isLoading ? "Wait..." : "Save changes"}
                    </Button>
                    {/* <Button type="button" color="success" className="mr-1" onClick={() => this.touchAll(setTouched, errors)}  disabled={isValid}>Validate</Button> */}
                    {/* <Button type="reset" color="danger" className="mr-1" onClick={handleReset}>Reset</Button> */}
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          )}
        />
        <Modal
          isOpen={modalCompanyTypeSetting}
          toggle={this.openModalCompanyTypeSetting}
          style={{ maxWidth: "1440px", width: "100%" }}
          className={this.props.className}
          backdrop="static"
          size="lg"
        >
          <ModalHeader toggle={this.closeModalCompanyTypeSetting}>
            {this.settingsWordingFactory(selectedSettingId)} Settings
          </ModalHeader>
          <ModalBody>
            {this.settingsComponentFactory(selectedSettingId)}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default CompanySettingsForm;

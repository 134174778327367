import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import * as yup from "yup";
import useForm from "../../../../../hooks/useForm";
import Select from "react-select";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import {
  MAINTENANCE_APPROVAL_TYPE_SETTING_INPUT,
  MAINTENANCE_APPROVAL_TYPE_SETTING_EDIT,
} from "../../../../../redux/config/storing.links";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import { DIVISION_LIST_URL } from "../../../../../redux/config/links";

const ApprovalTypeSettingAddEdit = ({
  onClose,
  onReload,
  initialValues = null,
  isEdit = false,
}) => {
  const [isGettingMechanicTaskList, setIsGettingMechanicTaskList] =
    useState(true);

  const [onGetMechanicTaskList, { loading, data: dataTaskList }] = useLazyFetch(
    {
      url: DIVISION_LIST_URL,
      method: "POST",
    }
  );

  const [onInput] = useLazyFetch({
    url: MAINTENANCE_APPROVAL_TYPE_SETTING_INPUT,
    method: "POST",
  });

  const [onEdit] = useLazyFetch({
    url: MAINTENANCE_APPROVAL_TYPE_SETTING_EDIT,
    method: "POST",
  });

  const validation = () => {
    let selectYup = yup.object().shape({
      label: yup.string().required("Required"),
      value: yup.number().required("Required"),
    });
    return yup.object().shape({
      trackx_division: selectYup.required("Track X Division is Required"),
      step: yup.number().required(),
    });
  };

  const {
    handleSubmit,
    errors: error,
    values,
    handleReset,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues: {
      trackx_division:
        !!initialValues && !!initialValues.id_trackx_division
          ? {
              value: initialValues.id_trackx_division,
              label: initialValues.trackx_division_name,
            }
          : null,
      step: !!initialValues && !!initialValues.step ? initialValues.step : "",
    },
    validation: validation(),
  });

  const handleChange = (e) => {
    setFieldValue(e.currentTarget.name, e.currentTarget.value);
  };

  const onSubmit = (value, { setSubmitting }) => {
    if (!isEdit) {
      onInput(
        {
          body: {
            step: value.step,
            id_trackx_division: value.trackx_division.value,
          },
        },
        () => {
          setSubmitting(false);
          onReload();
          onClose();
        }
      );
    } else {
      onEdit(
        {
          body: {
            step: value.step,
            id_trackx_division: value.trackx_division.value,
            id: initialValues.id,
          },
        },
        () => {
          setSubmitting(false);
          onReload();
          onClose();
        }
      );
    }
  };

  useEffect(() => {
    if (isGettingMechanicTaskList) {
      setIsGettingMechanicTaskList(false);
      onGetMechanicTaskList({});
    }
  }, [isGettingMechanicTaskList, onGetMechanicTaskList]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Col md="3">Track X Division</Col>
        <Col xs="12" md="9">
          <LoadingContent
            loading={loading}
            onReload={() => setIsGettingMechanicTaskList(true)}
          >
            <div className={`${!!error.trackx_division ? "is-invalid" : ""}`}>
              <Select
                value={values.trackx_division}
                onChange={(selected) => {
                  setFieldValue("trackx_division", selected);
                }}
                options={(!!dataTaskList ? dataTaskList.result : []).map(
                  (item) => ({ label: item.name, value: item.id })
                )}
                disabled={isSubmitting}
              />
            </div>
          </LoadingContent>
          <FormFeedback>{error.trackx_division}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col md="3">
          <Label htmlFor="step">Step</Label>
        </Col>
        <Col xs="12" md="9">
          <Input
            type="number"
            autoComplete="off"
            placeholder="Step"
            name="step"
            id="step"
            onChange={handleChange}
            value={values.step}
            valid={!error.step}
            invalid={!!error.step}
          />
          <FormFeedback>{error.step}</FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup>
        <Button
          type="submit"
          color="primary"
          className="mr-1"
          disabled={isSubmitting || !isValid}
        >
          {isSubmitting
            ? "Wait..."
            : !isEdit
            ? "Save Changes"
            : "Edit"}
        </Button>
        <Button
          type="button"
          color="warning"
          className="mr-1"
          disabled={isSubmitting}
          onClick={() => onClose()}
        >
          Close
        </Button>
        <Button
          type="reset"
          color="danger"
          className="mr-1"
          disabled={isSubmitting}
          onClick={handleReset}
        >
          Reset
        </Button>
      </FormGroup>
    </form>
  );
};

export default ApprovalTypeSettingAddEdit;

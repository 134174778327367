import React, { Component } from "react";
// import {Button, ButtonGroup} from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { AbilityContext } from "../../../../casl/Can";
import moment from "moment";
import { fileNameFormatWithType } from "../../../../helpers/helper";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Button } from "reactstrap";
import { FaDownload } from "react-icons/fa";
class TriPLLiveMonitoringDatatable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //   console.log(this.props.state.liveMonitoringData)
  }

  latDataFormat = (cell, row) => {
    if (row.lat === null) {
      return <span>-</span>;
    } else {
      return <span>{row.lat}</span>;
    }
  };

  lngDataFormat = (cell, row) => {
    if (row.lng === null) {
      return <span>-</span>;
    } else {
      return <span>{row.lng}</span>;
    }
  };

  latDataFormatCSV = (cell, row) => {
    if (row.lat === null) {
      return "-";
    }
    if (row.address) {
      return `${row.lat} - ${row.address}`;
    } else {
      return `${row.lat}`;
    }
  };

  lngDataFormatCSV = (cell, row) => {
    if (row.lng === null) {
      return "-";
    }
    if (row.address) {
      return `${row.lng} - ${row.address}`;
    } else {
      return `${row.lng}`;
    }
  };

  fleetStatusFormat = (cell, row) => {
    if (row.wrn === 0) {
      return <span>Off</span>;
    } else {
      if (row.spd > 3.5) {
        return <span>On & Moving</span>;
      } else {
        return <span>On & Idle</span>;
      }
    }
  };

  fleetStatusCsvFormat = (cell, row) => {
    if (!row.time) return "-";
    if (row.wrn === 0 && row.spd > 0) {
      return "Off (Warning!)";
    }
    if (row.wrn === 0) {
      return "Off";
    } else {
      if (row.spd > 0) {
        return "On & Moving";
      }
      if (row.spd === 0) {
        return `On & Idle, ${this.fleetOnIdleTimeCheck(row)}`;
      }
    }
  };

  tempStatusFormat = (cell, row) => {
    if (cell) {
      return <span>{cell} °C</span>;
    } else {
      return <span>-</span>;
    }
  };

  tempStatusCsvFormat = (cell) => {
    if (cell) {
      return `${cell} °C`;
    } else {
      return "-";
    }
  };

  doorStatus = (cell, row) => {
    if (cell) {
      if (cell > 0) {
        return <span>Open</span>;
      } else {
        return <span>Close</span>;
      }
    } else {
      return <span>-</span>;
    }
  };

  doorStatusCsvFormat = (cell, row) => {
    let isDoorSensorBind = false;
    if (isDoorSensorBind) {
      if (cell > 0) {
        return "Open";
      } else {
        return "Close";
      }
    } else {
      return "-";
    }
  };

  mergeLiveMonitoringData = () => {
    let resultData = this.props.state.liveMonitoringData;
    let woData = this.props.state.liveMonitoringWoData;
    let mergeResult = [];
    for (let index in woData) {
      woData[index].lat = resultData[index].lat;
      woData[index].lng = resultData[index].lng;
      mergeResult.push({
        ...resultData[index],
        ...woData[index],
      });
    }
    return mergeResult;
  };

  clickableIdFleet = (cell, row) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => this.onIdFleetClicked(row)}
      >
        {cell}
      </div>
    );
  };

  onIdFleetClicked = (row) => {
    this.props.onIdFleetClicked(row);
  };

  doNumberFormat = (cell, row) => {
    return `(ID ${row.delivery_id}) ${cell}`;
  };

  doNumberFormatCsv = (cell, row) => {
    return `(ID ${row.delivery_id}) ${cell}`;
  };

  etaLiveFormat = (cell, row) => {
    if (row.arrival_time)
      return (
        "Completed at " + moment(row.arrival_time).format("YYYY-MM-DD HH:mm:ss")
      );
    return cell;
  };

  etaLiveFormatCsv = (cell, row) => {
    if (row.arrival_time)
      return (
        "Completed at " + moment(row.arrival_time).format("YYYY-MM-DD HH:mm:ss")
      );
    return cell;
  };

  timestampFormatter = (cell) => {
    return moment(cell).format("YYYY-MM-DD HH:mm:ss");
  };

  getExcelData = () => {
    // Cek apakah data liveMonitoringData ada dan bukan kosong
    if (
      !this.state.liveMonitoringData ||
      this.state.liveMonitoringData.length === 0
    ) {
      // Jika tidak ada data, kembalikan array dgn info "-"
      return [
        {
          company: "-",
          do_number: "-",
          wo_number: "-",
          wo_seq: "-",
          wo_destination_name: "-",
          fleet_plate: "-",
          driver_name: "-",
          lat: "-",
          lng: "-",
          est_arrival_time: "-",
          eta_live: "-",
          time: "-",
        },
      ];
    }

    // Jika ada data, proses dan format seperti biasa
    return this.state.liveMonitoringData.map((item) => ({
      company: item.company || "-",
      do_number: this.doNumberFormatCsv(null, item.do_number) || "-",
      wo_number: item.wo_number || "-",
      wo_seq: item.wo_seq || "-",
      wo_destination_name: item.wo_destination_name || "-",
      fleet_plate: item.fleet_plate || "-",
      driver_name: item.driver_name || "-",
      lat: this.latDataFormatCSV(null, item.lat) || "-",
      lng: this.lngDataFormatCSV(null, item.lng) || "-",
      est_arrival_time: item.est_arrival_time || "-",
      eta_live: item.eta_live || "-",
      time: this.dateTimeCsvFormat(item.time) || "-",
    }));
  };

  exportToExcel = async () => {
    const data = this.getExcelData();
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Live Monitoring Data");

    worksheet.columns = [
      { header: "Company", key: "company" },
      { header: "Delivery ID", key: "do_number" },
      { header: "WO Number", key: "wo_number" },
      { header: "WO Seq", key: "wo_seq" },
      { header: "Destination", key: "wo_destination_name" },
      { header: "Registration Number", key: "fleet_plate" },
      { header: "Driver Name", key: "driver_name" },
      { header: "Latitude", key: "lat" },
      { header: "Longitude", key: "lng" },
      { header: "ETA", key: "est_arrival_time" },
      { header: "ETA Live", key: "eta_live" },
      { header: "Timestamp", key: "time" },
    ];

    // Tambahkan data ke worksheet
    if (data.length === 0) {
      // Jika tidak ada data, tambahkan baris pemberitahuan
      worksheet.addRow({
        company: "",
        do_number: "",
        wo_number: "",
        wo_seq: "",
        wo_destination_name: "",
        fleet_plate: "",
        driver_name: "",
        lat: "",
        lng: "",
        est_arrival_time: "",
        eta_live: "",
        time: "",
      });
    } else {
      data.forEach((row) => {
        worksheet.addRow(row);
      });
    }

    // Font style untuk semua kolom
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell, colNumber) => {
        cell.font = {
          name: "Inter",
          family: 2,
          size: 12,
        };
      });
    });

    // Font style bold untuk header
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { name: "Inter", bold: true };
    });

    // Font style & font color utk kolom tertetu
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber !== 1) {
        row.eachCell((cell, colNumber) => {
          if ([5, 8, 9, 12].includes(colNumber)) {
            // 4 untuk wo_destination_name, 8 9 utk lat lon, 12 untuk time
            cell.font = {
              name: "Inter",
              color: { argb: "FF0000FF" }, // Warna biru
              family: 2,
              size: 12,
            };
          }
        });
      }
    });

    // Nyimpen workbook ke file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = fileNameFormatWithType("LiveMonitoringData3PL", "xlsx");
    saveAs(blob, fileName);
  };

  showDatatables = () => {
    const options = {
      noDataText: "No vehicle to monitor yet!",
      btnGroup: (props) => (
        <div style={{ display: "flex", gap: "10px" }}>
          {props.exportCSVBtn}{" "}
          <div>
            <Button
              color="primary"
              onClick={this.exportToExcel}
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <FaDownload /> Export to Excel
            </Button>
          </div>
        </div>
      ),
    };
    return (
      <BootstrapTable
        data={this.mergeLiveMonitoringData()}
        version="4"
        bordered={false}
        hover
        pagination
        search
        edit
        options={options}
        exportCSV={true}
        csvFileName={fileNameFormatWithType("LiveMonitoringData3PL", "csv")}
        multiColumnSearch
      >
        <TableHeaderColumn
          dataField="id"
          isKey
          dataSort
          csvHeader="ID"
          thStyle={{ whiteSpace: "normal" }}
          width="10%"
          hidden
        >
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="company"
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="Company"
          thStyle={{ whiteSpace: "normal" }}
          width="20%"
        >
          Company
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="do_number"
          dataFormat={this.doNumberFormat.bind(this)}
          csvFormat={this.doNumberFormatCsv}
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="Delivery ID"
          thStyle={{ whiteSpace: "normal" }}
          width="40%"
        >
          DO Number
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="wo_number"
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="WO Number"
          thStyle={{ whiteSpace: "normal" }}
          width="20%"
        >
          WO Number
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="wo_seq"
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="WO Seq"
          thStyle={{ whiteSpace: "normal" }}
          width="10%"
        >
          WO Seq.
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="wo_destination_name"
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="Destination"
          thStyle={{ whiteSpace: "normal" }}
          width="20%"
        >
          Destination
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="fleet_plate"
          dataFormat={this.clickableIdFleet.bind(this)}
          csvFormat={(cell) => cell || "-"}
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="Registration Number"
          thStyle={{ whiteSpace: "normal" }}
          width="20%"
        >
          ID Fleet
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="driver_name"
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="Driver"
          thStyle={{ whiteSpace: "normal" }}
          width="20%"
        >
          Driver
        </TableHeaderColumn>
        {/* <TableHeaderColumn dataField="obd_code" searchable dataSort csvHeader="OBD Code" thStyle={ { whiteSpace: 'normal' } } width="20%">OBD Code</TableHeaderColumn> */}
        <TableHeaderColumn
          dataField="lat"
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="Latitude"
          thStyle={{ whiteSpace: "normal" }}
          width="20%"
          dataFormat={this.latDataFormat.bind(this)}
          csvFormat={this.latDataFormatCSV}
        >
          Latitude
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="lng"
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="OBD Code"
          thStyle={{ whiteSpace: "normal" }}
          width="20%"
          dataFormat={this.lngDataFormat.bind(this)}
          csvFormat={this.lngDataFormatCSV}
        >
          Longitude
        </TableHeaderColumn>
        {/* <TableHeaderColumn dataField="vsys" searchable dataSort csvHeader="Fuel Level" thStyle={ { whiteSpace: 'normal' } } width="20%" >Fuel Level</TableHeaderColumn> */}
        {/* <TableHeaderColumn dataField="wrn" searchable dataSort csvHeader="Fleet Status" thStyle={ { whiteSpace: 'normal' } } width="20%" dataFormat={this.fleetStatusFormat.bind(this)}>Fleet Status</TableHeaderColumn> */}
        <TableHeaderColumn
          dataField="est_arrival_time"
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="ETA"
          thStyle={{ whiteSpace: "normal" }}
          width="20%"
        >
          ETA
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="eta_live"
          tdStyle={{ whiteSpace: "normal" }}
          searchable
          dataSort
          csvHeader="ETA Live"
          dataFormat={this.etaLiveFormat.bind(this)}
          csvFormat={this.etaLiveFormat.bind(this)}
          thStyle={{ whiteSpace: "normal" }}
          width="20%"
        >
          ETA Live
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="time"
          tdStyle={{ whiteSpace: "normal" }}
          dataSort
          csvHeader="Timestamp"
          thStyle={{ whiteSpace: "normal" }}
          width="15%"
          dataFormat={this.timestampFormatter.bind(this)}
          csvFormat={this.timestampFormatter.bind(this)}
        >
          Timestamp
        </TableHeaderColumn>
        {/* <TableHeaderColumn dataField="action" export={false} dataFormat={ this.ActionFormatter.bind(this) } width="25%">Action</TableHeaderColumn> */}
      </BootstrapTable>
    );
  };

  render() {
    return <div>{this.showDatatables()}</div>;
  }
}

TriPLLiveMonitoringDatatable.contextType = AbilityContext;
export default TriPLLiveMonitoringDatatable;

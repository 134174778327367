import { ReactElement, ReactNode } from "react";
import { bootstrapTableOption, fileNameFormat } from "../../helpers/helper"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

interface ITable {
    name: string;
    data: any[],
    option?: {
        defaultSortName?: string;
        defaultSortOrder?: string;
        btnGroup?: (props: { exportCSVBtn: ReactNode }) => ReactElement
    },
    columns: {
        field?: string;
        dataSort?: boolean;
        name: string;
        hidden?: boolean;
        isKey?: boolean;
        dataFormat?: (cell: any, row: any, formatExtraData: any, rowIndex: number) => ReactElement;
        width?: string;
    }[]
}

const Table: React.FC<ITable> = ({ name, data, option, columns }) => {
    return <BootstrapTable
        data={data}
        version="4"
        striped
        hover
        pagination
        search
        options={bootstrapTableOption(option)}
        exportCSV={true}
        csvFileName={fileNameFormat(name)}
        multiColumnSearch
    >
        {columns.map((column, i) =>
            <TableHeaderColumn
                key={`${i}`}
                dataField={column.field}
                isKey={column?.isKey ?? false}
                dataSort={column?.dataSort ?? false}
                csvHeader={column.name}
                hidden={column?.hidden ?? false}
                dataFormat={column.dataFormat}
                width={column?.width}
            >
                {column.name}
            </TableHeaderColumn>)}
    </BootstrapTable>
}

export default Table;
import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col
} from 'reactstrap'
import { connect } from 'react-redux'
import SummaryWidgets from '../../../../../Widgets/SummaryWidgets';
import PicDatatable from './PicDatatable';
import { fetchWorkshopPic } from '../../../../../../redux/actions/transporter/workshop.act';

class Pic extends Component{

    constructor(props) {
        super(props);
        this.state = { 
            picFetchInProgress: true,
            totalPics: 0,
            pic: [],
        }
        const {id_workshop} = this.props
        this.props.fetchWorkshopPic(id_workshop)
    }

    componentDidUpdate(prevProps){
        const {pic, totalPics, picFetchInProgress} = this.props
        if(prevProps.picFetchInProgress !== picFetchInProgress){
            this.setState({
                picFetchInProgress: picFetchInProgress
            })
        }
        if(prevProps.pic !== pic){
            this.setState({
                pic: pic
            })
        }
        if(prevProps.totalPics !== totalPics){
            this.setState({
                totalPics: totalPics
            })
        }
    }

    fetchRefresh = () => {
        const {id_workshop} = this.props
        this.props.fetchWorkshopPic(id_workshop)
    }

    initialLoad = () => {
        const {picFetchInProgress, totalPics} = this.state
        if(picFetchInProgress){
            return {isComplete: false}
        }
        if((totalPics === 0) && !picFetchInProgress){
            return {isComplete: true}
        }
        return {isComplete: true}
    }

    render(){
        const {totalPics, pic} = this.state
        const {id_workshop} = this.props
        return(
            <>
                <div className="animated fadeIn">
                    <Row>
                        <Col md={3} xs={12}>
                            <SummaryWidgets header={totalPics} 
                                            mainText={'Total Pic'} 
                                            icon={'fa fa-user'} 
                                            color={'success'}/>
                        </Col>
                    </Row>
                    <Card>
                        <CardHeader>
                            Available Pic
                        </CardHeader>
                        <CardBody>
                            <PicDatatable
                                initialLoad={this.initialLoad()} pic={pic} 
                                id_workshop={id_workshop}
                                fetchRefresh={this.fetchRefresh} />
                        </CardBody>
                    </Card>
                </div>
                {/* <ToastContainer position="bottom-right" autoClose={7500} style={{zIndex: 7000}}/> */}
            </>
        )
    }

}
const mapStateToProps = (state) => ({
    totalPics: state.workShopPicsStore.totalPics,
    pic: state.workShopPicsStore.pic,
    picFetchInProgress: state.workShopPicsStore.inProgress
})
export default connect(mapStateToProps, {fetchWorkshopPic})(Pic);
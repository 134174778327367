const BASE_URL = "https://api.automa.id/api/storing";

const TRIP_URL = BASE_URL + "/fleet/trip";
export const TRIP_EDIT = TRIP_URL + "/edit";
export const TRIP_LIST = TRIP_URL + "/list";
export const TRIP_INPUT = TRIP_URL + "/input";
export const TRIP_DELETE = TRIP_URL + "/delete";
export const MECHANIC_LIST = TRIP_URL + "/mechanic/list";
export const MECHANIC_INPUT = TRIP_URL + "/mechanic/input";
export const MECHANIC_EDIT = TRIP_URL + "/mechanic/edit";
export const MECHANIC_DELETE = TRIP_URL + "/mechanic/delete";
export const SPARE_PART_LIST = TRIP_URL + "/spare-part/list";
export const SPARE_PART_INPUT = TRIP_URL + "/spare-part/input";
export const SPARE_PART_EDIT = TRIP_URL + "/spare-part/edit";
export const SPARE_PART_DELETE = TRIP_URL + "/spare-part/delete";

const MAINTENANCE_MECHANIC = BASE_URL + "/maintenance/mechanic";
export const MAINTENANCE_MECHANIC_LIST = MAINTENANCE_MECHANIC + "/list";
export const MAINTENANCE_MECHANIC_INPUT = MAINTENANCE_MECHANIC + "/input";
export const MAINTENANCE_MECHANIC_EDIT = MAINTENANCE_MECHANIC + "/edit";
export const MAINTENANCE_MECHANIC_DELETE = MAINTENANCE_MECHANIC + "/delete";

const MAINTENANCE_MECHANIC_TASK = MAINTENANCE_MECHANIC + "/task";
export const MAINTENANCE_MECHANIC_TASK_LIST =
  MAINTENANCE_MECHANIC_TASK + "/list";
export const MAINTENANCE_MECHANIC_TASK_EDIT = 
  MAINTENANCE_MECHANIC_TASK + "/edit";
export const MAINTENANCE_MECHANIC_TASK_INPUT =
  MAINTENANCE_MECHANIC_TASK + "/input";
export const MAINTENANCE_MECHANIC_TASK_DELETE =
  MAINTENANCE_MECHANIC_TASK + "/delete";

const MAINTENANCE_MECHANIC_TASK_DETAIL = MAINTENANCE_MECHANIC_TASK + "/detail";
export const MAINTENANCE_MECHANIC_TASK_DETAIL_LIST =
  MAINTENANCE_MECHANIC_TASK_DETAIL + "/list";
export const MAINTENANCE_MECHANIC_TASK_DETAIL_INPUT =
  MAINTENANCE_MECHANIC_TASK_DETAIL + "/input";
export const MAINTENANCE_MECHANIC_TASK_DETAIL_EDIT = 
MAINTENANCE_MECHANIC_TASK_DETAIL + "/edit";
export const MAINTENANCE_MECHANIC_TASK_DETAIL_DELETE =
  MAINTENANCE_MECHANIC_TASK_DETAIL + "/delete";

const MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST =
  MAINTENANCE_MECHANIC_TASK + "/spare-part";
export const MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST_EDIT = 
MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST + "/edit";
export const MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST_LIST =
  MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST + "/list";
export const MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST_INPUT =
  MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST + "/input";
export const MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST_DELETE =
  MAINTENANCE_MECHANIC_TASK_SPARE_PART_REQUEST + "/delete";

export const MAINTENANCE_MECHANIC_TASK_SPARE_PART_LIST =
  MAINTENANCE_MECHANIC + "/task/spare-part/list";
  
export const MAINTENANCE_PROOF_LIST = BASE_URL + "/maintenance/proof/list";
export const MAINTENANCE_PROOF_INPUT = BASE_URL + "/maintenance/proof/input";
export const MAINTENANCE_PROOF_EDIT = BASE_URL + "/maintenance/proof/edit";
export const MAINTENANCE_PROOF_DELETE = BASE_URL + "/maintenance/proof/delete";


export const MAINTENANCE_APPROVAL_TYPE_SETTING_LIST = BASE_URL + "/maintenance/approval/type/setting/list";
export const MAINTENANCE_APPROVAL_TYPE_SETTING_INPUT = BASE_URL + "/maintenance/approval/type/setting/input";
export const MAINTENANCE_APPROVAL_TYPE_SETTING_EDIT = BASE_URL + "/maintenance/approval/type/setting/edit";
export const MAINTENANCE_APPROVAL_TYPE_SETTING_DELETE = BASE_URL + "/maintenance/approval/type/setting/delete";

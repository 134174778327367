import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    Label,
    FormGroup,
    Col,
    ModalHeader,
    Input,
    Badge
} from 'reactstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { connect } from "react-redux";
import { BarLoader } from 'react-spinners';
import {toast} from 'react-toastify';
import * as API_LINKS from '../../../redux/config/links'
import axios from 'axios';
import { fetchUserTable } from '../../../redux/actions/aUser-UserListTable.jsx'
import { AbilityContext} from '../../../casl/Can';
import ResendVerificationForm from './ResendVerificationForm'

class ManagementDatatable extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            modalReset: false,
            modalDelete: false,
            modalChangeLevel: false,
            selectedName: '',
            selectedId: 0,
            selectedEmail: '',
            isLoading: false,

            newPassword: '',
            confirmNewPassword: '',
            emailToResenVerif: null
         };
    }

    toggleOpenResetPassword = (_id, _name, _email) => {
        this.setState({
            selectedId: _id,
            selectedName: _name,
            selectedEmail: _email,
            modalReset: !this.state.modalReset
        })
    }

    toggleCloseResetPassword = () => {
        this.setState({
            selectedName: '',
            selectedId: 0,
            selectedEmail: '',
            modalReset: !this.state.modalReset
        })
    }

    toggleOpenChangeStatus = (_id, _name, _email, _lock) => {
        this.setState({
            selectedId: _id,
            selectedName: _name,
            selectedEmail: _email,
            lock: _lock,
            modalStatus: !this.state.modalStatus
        })
    }

    toggleCloseChangeStatus = () => {
        this.setState({
            selectedId: 0,
            selectedName: '',
            selectedEmail: '',
            lock: false,
            modalStatus: !this.state.modalStatus
        })
    }

    toggleOpenChangeLevel = (_id, _name, _email, _level) => {
        this.setState({
            selectedId: _id,
            selectedName: _name,
            selectedEmail: _email,
            selectedLevel: _level,
            modalChangeLevel: !this.state.modalChangeLevel
        })
    }

    toggleCloseChangeLevel = () => {
        this.setState({
            selectedId: 0,
            selectedName: '',
            selectedEmail: '',
            selectedLevel: 0,
            modalChangeLevel: !this.state.modalChangeLevel
        })
    }

    toggleOpenDelete = (_id, _name, _email) => {
        this.setState({
            selectedId: _id,
            selectedName: _name,
            selectedEmail: _email,
            modalDelete: !this.state.modalDelete
        })
    }

    toggleCloseDelete = () => {
        this.setState({
            selectedId: 0,
            selectedName: '',
            selectedEmail: '',
            modalDelete: !this.state.modalDelete
        })
    }

    UNSAFE_componentWillMount = () => {
        this.props.fetchUserTable()
    }

    ActionButtonFormatter = (cell, row) => {
        //eslint-disable-next-line
        let isLevelValid = this.context.can("view", "lock_unclock_and_reset_password_button@management_datatable") ? true : false;

        if(row.status === 1 && isLevelValid) {
                return (
                    <div>
                        <Button className="btn btn-danger" color="danger" size="sm" onClick={() => this.toggleOpenChangeStatus(row.id, row.first_name, row.email, true)}><i className="fa fa-lock"></i> Lock</Button>
                        <Button className="btn btn-warning" color="warning" size="sm" onClick={() => this.toggleOpenResetPassword(row.id, row.first_name, row.email)}><i className="fa fa-key"></i> Reset Password</Button>
                        <this.ResenEmailVerif verified={row.verified} email={row.email}/>
                    </div>
                )// eslint-disable-next-line
            } else if(row.status === 4 && isLevelValid) {
                return (
                    <div>
                        <Button className="btn btn-primary" color="primary" size="sm" onClick={() => this.toggleOpenChangeStatus(row.id, row.first_name, row.email, false)}><i className="fa fa-unlock"></i> Unlock</Button>
                        <Button className="btn btn-warning" color="warning" size="sm" onClick={() => this.toggleOpenResetPassword(row.id, row.first_name, row.email)}><i className="fa fa-key"></i> Reset Password</Button>
                        <Button className="btn btn-danger" color="danger" size="sm" onClick={() => this.toggleOpenDelete(row.id, row.first_name, row.email)}><i className="fa fa-trash"></i> Delete</Button>
                    </div>
                )
        }
        
    }

    ResenEmailVerif = (props) => {
        return (
            Number(props.verified) !== 1?
                <>&nbsp;&nbsp;
                    <Button className="btn btn-info" color="info"
                            size="sm" onClick={() => this.toggleOpenResendVerif(props.email)}>
                                <i className="fa fa-share"></i> Resend Verif. Email
                    </Button>
                </>
                : null
        )
    }

    viewResetPassword = () => {
        var self = this
        const { isLoading, newPassword, confirmNewPassword} = this.state
        return (
            <div>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">New Password</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="password" name="text-input" id="Password" placeholder="**********" value={newPassword} onChange={e => this.setState({ newPassword: e.target.value })} disabled={isLoading} autoComplete="off" required/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Confirm New Password</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Input type="password" name="text-input" id="confirmPassword" placeholder="**********" value={confirmNewPassword} onChange={e => this.setState({ confirmNewPassword: e.target.value })} disabled={isLoading} autoComplete="off" required/>
                    </Col>
                </FormGroup>
                {this.state.isLoading ? <center><BarLoader color={'#123abc'} 
                  loading={this.state.isLoading} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => this.handleResetPassword()} size="sm" color="success" disabled={self.state.isLoading}><i className="fa fa-dot-circle-o"></i> Submit</Button>
                <Button onClick={() => this.handleReset()} size="sm" color="warning" disabled={self.state.isLoading}><i className="fa fa-window-close"></i> Reset</Button></div>}
            </div>
        )
    }

    viewChangeStatus = () => {
        var self = this;
        const { selectedName, selectedId, selectedEmail, lock} = this.state;
        return(
            <div className="container">
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Email: </Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Label htmlFor="text-input">{selectedEmail}</Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Name: </Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Label htmlFor="text-input">{selectedName}</Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" md="12">
                        <Label htmlFor="text-input">Do you want to {lock ? 'lock' : 'unlock'} this account? </Label>
                    </Col>
                </FormGroup>
                  
                {this.state.isLoading ? <center><BarLoader color={'#123abc'} 
                  loading={this.state.isLoading} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => self.handleChangeStatus(selectedId, selectedEmail, lock)} size="sm" color="danger" disabled={this.state.isLoading}><i className={lock ? "fa fa-lock" : "fa fa-unlock"}></i> {lock? 'Lock' : 'Unlock'}</Button>
                <Button onClick={() => this.toggleCloseChangeStatus()} size="sm" color="success" disabled={this.state.isLoading}><i className="fa fa-window-close"></i> Close</Button></div>}         
            </div>
        )
    }

    handleChangeStatus = (_id, _email, _lock) => {
        this.setState({ isLoading: true })
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        const stat = _lock ? 4 : 1

        axios({
            method:'post',
            url: API_LINKS.USER_STATUS_URL, //Please change when it's ready
            headers: {
                Authorization: auth
            },
            data: {
                id: _id,
                email: _email,
                status: stat
            }
        })
        .then((response) => {
            if(response.status === 200) {
                self.setState({
                    isLoading: false,
                })
                toast.success('Change status success for '+ self.state.selectedEmail + '!')
                self.props.fetchUserTable()
                self.toggleCloseChangeStatus()
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                self.setState({
                    isLoading: false,
                })
                return toast.error('Request error! Please try again later...')
            } else {
                self.setState({
                    isLoading: false,
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }

    viewDelete = () => {
        var self = this;
        const { selectedName, selectedId, selectedEmail, confirmEmailDeletion, isLoading} = this.state;
        return(
            <div className="container">
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Email: </Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Label htmlFor="text-input">{selectedEmail}</Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Name: </Label>
                    </Col>
                    <Col xs="12" md="9">
                        <Label htmlFor="text-input">{selectedName}</Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" md="12">
                        <Label htmlFor="text-input">Do you want to delete this account? <strong>Please type the email address to confirm deletion</strong></Label>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" md="12">
                    <Input type="text" name="text-input" id="confirmEmailDeletion"  placeholder="email@email.com" value={confirmEmailDeletion} onChange={e => this.setState({ confirmEmailDeletion: e.target.value })} disabled={isLoading} autoComplete="off" required/>
                    </Col>
                </FormGroup>
                  
                {this.state.isLoading ? <center><BarLoader color={'#123abc'} 
                  loading={this.state.isLoading} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => self.handleDeleteAccount(selectedId, selectedEmail, confirmEmailDeletion)} size="sm" color="danger" disabled={this.state.isLoading}><i className="fa fa-trash"></i> Delete the account</Button>
                <Button onClick={() => this.toggleCloseDelete()} size="sm" color="success" disabled={this.state.isLoading}><i className="fa fa-window-close"></i> Close</Button></div>}         
            </div>
        )
    }

    viewChangeLevel = () => {

    }

    handleDeleteAccount = (_id, _email, _confirmEmail) => {
        if(_email !== _confirmEmail) {
            toast.error('Please enter the email address to continue processing!');
        }

        this.setState({ isLoading: true })
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;

        axios({
            method:'post',
            url: API_LINKS.USER_STATUS_URL, //Please change when it's ready
            headers: {
                Authorization: auth
            },
            data: {
                id: _id,
                email: _email,
                status: 2
            }
        })
        .then((response) => {
            if(response.status === 200) {
                self.setState({
                    isLoading: false
                })
                toast.success('Account deleted successfully!')
                self.toggleCloseDelete()
                self.props.fetchUserTable()
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                self.setState({
                    isLoading: false,
                })
                return toast.error('Request error! Please try again later...')
            } else {
                self.setState({
                    isLoading: false,
                })
                return toast.error('Something went wrong... Please try again later...')
            }
        })
    }

    handleResetPassword = () => {
        const {newPassword, confirmNewPassword, selectedEmail} = this.state

        this.setState({ isLoading: true })
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;
        var _email = selectedEmail;

        if(newPassword === "" || newPassword === null || newPassword.length < 8) {
            self.setState({isLoading: false})
            toast.error("New password is empty or less than 8 characters!")
        } else if(confirmNewPassword === "" || confirmNewPassword === null || confirmNewPassword.length < 8) {
            self.setState({isLoading: false})
            toast.error("Confirm New password is empty or less than 8 characters!")
        } else if(newPassword !== confirmNewPassword) {
            self.setState({isLoading: false})
            toast.error("New password did not match!")
        } else {
            axios({
                method:'post',
                url: API_LINKS.CHANGE_PASSWORD_URL, //Please change when it's ready
                headers: {
                    Authorization: auth
                },
                data: {
                    email: _email,
                    new_password: newPassword,
                    confirm_new_password: confirmNewPassword
                }
            })
            .then((response) => {
                if(response.status === 200) {
                    self.setState({
                        isLoading: false,
                        newPassword: "",
                        confirmNewPassword: ""
                    })
                    toast.success('Reset password success for '+ self.state.selectedEmail + '!')
                    self.toggleCloseResetPassword()
                }
            })
            .catch(function(error){
                if (error.response) {
                    if(error.response.status === 401) {
                        self.setState({
                            isLoading: false,
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 403) {
                        self.setState({
                            isLoading: false,
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 400) {
                        self.setState({
                            isLoading: false,
                        })
                        return toast.error(error.response.data.message);
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        self.setState({
                            isLoading: false,
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else {
                        self.setState({
                            isLoading: false,
                        })
                        return toast.error('Something went wrong... Please try again later...')
                    }
                } else if (error.request) {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    self.setState({
                        isLoading: false,
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }

    emailStatusFormarter(cell, row){
        const EMAIL_VERIFIED = 1
        switch(row.verified){
            case EMAIL_VERIFIED:
                return<>{cell}&nbsp;<Badge color="info">Verified</Badge></>
            default:
                return<>{cell}&nbsp;<Badge color="warning">Not Verified</Badge></>
        }
    }

    showDatatable = () => {
        return (
            <BootstrapTable data={this.props.userTableData.result} version="4" bordered={false} hover pagination edit options={this.options} exportCSV={true} csvFileName={this.fileNameFormat}>
              <TableHeaderColumn dataField="id" isKey dataSort csvHeader="ID" hidden>ID</TableHeaderColumn>
              <TableHeaderColumn dataField="email" searchable dataSort dataFormat={this.emailStatusFormarter} csvHeader="Email" thStyle={ { whiteSpace: 'normal' } } width="20%">Email</TableHeaderColumn>
              <TableHeaderColumn dataField="first_name" dataSort csvHeader="First Name" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">First Name</TableHeaderColumn>
              <TableHeaderColumn dataField="last_name" dataSort csvHeader="Last Name" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">Last Name</TableHeaderColumn>  
              <TableHeaderColumn dataField="user_level" dataSort csvHeader="User Level" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%">User Level</TableHeaderColumn>           
              {/* <TableHeaderColumn dataField="lng" dataSort csvHeader="Longitude" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="10%">Longitude</TableHeaderColumn> */}
              {/* <TableHeaderColumn dataField="created_on" dataSort csvHeader="Created On" thStyle={ { whiteSpace: 'normal' } } tdStyle={ { whiteSpace: 'normal' } } width="15%" hidden>Created On</TableHeaderColumn> */}
              <TableHeaderColumn dataField="action" export={false} dataFormat={ this.ActionButtonFormatter.bind(this) } width="20%">Action</TableHeaderColumn>
            </BootstrapTable>
        );
    }

    handleReset = () => {
        this.setState({
            newPassword: "",
            confirmNewPassword: ""
        })
    }

    toggleOpenResendVerif = (_email) => {
        this.setState({
            modalEmailVerif : !this.state.modalEmailVerif,
            emailToResenVerif: _email? _email.replace(/\s/g, '') : null
        })
    }

    toggleCloseResendVerif = () => {
        this.setState({
            modalEmailVerif : !this.state.modalEmailVerif,
            emailToResenVerif: null
        })
    }

    refreshTable = () => {
        this.props.fetchUserTable()
    }

    render() {
        return (
            <div>
                {this.props.inProgress ? <center><BarLoader color={'#123abc'} 
                loading={this.props.inProgress} 
                /> <br /> Loading.... Please wait...</center> : this.showDatatable()}

                {/* Modal for Approve Place */}
                <Modal isOpen={this.state.modalReset} toggle={this.toggleOpenResetPassword} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseResetPassword}>Reset password for {this.state.selectedEmail} ({this.state.selectedName})?</ModalHeader>
                    <ModalBody>
                            {this.viewResetPassword()}
                    </ModalBody>
                </Modal>

                {/* Modal for Lock and Unlock */}
                <Modal isOpen={this.state.modalStatus} toggle={this.toggleOpenChangeStatus} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseChangeStatus}>{this.state.lock ? 'Lock account ' : 'Unlock account '} for {this.state.selectedEmail} ({this.state.selectedName})?</ModalHeader>
                    <ModalBody>
                            {this.viewChangeStatus()}
                    </ModalBody>
                </Modal>

                {/* Modal for Edit Level */}
                <Modal isOpen={this.state.modalChangeLevel} toggle={this.toggleOpenChangeStatus} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseChangeStatus}>Change user level for {this.state.selectedEmail} ({this.state.selectedName})?</ModalHeader>
                    <ModalBody>
                            {this.viewChangeLevel()}
                    </ModalBody>
                </Modal>

                {/* Modal for Delete Account */}
                <Modal isOpen={this.state.modalDelete} toggle={this.toggleOpenDelete} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseDelete}>Delete Account {this.state.selectedEmail} ({this.state.selectedName})?</ModalHeader>
                    <ModalBody>
                            {this.viewDelete()}
                    </ModalBody>
                </Modal>

                 {/* Modal for Resend Verif. Email */}
                <Modal isOpen={this.state.modalEmailVerif} toggle={this.toggleOpenResendVerif} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseResendVerif}>Resend Email Verification</ModalHeader>
                    <ModalBody>
                            <ResendVerificationForm emailToConfirm={this.state.emailToResenVerif} 
                                            refreshTable={this.refreshTable} closeVerif={this.toggleCloseResendVerif}/>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userTableData: state.userTableStore.userTableData,
        inProgress: state.userTableStore.inProgress,
      }
}

ManagementDatatable.contextType = AbilityContext;
export default connect(mapStateToProps, {fetchUserTable})(ManagementDatatable);
import React, { Component } from 'react';
import {
    Card,
    CardBody,
    Button,
    Row,
    Col,
    Progress
} from 'reactstrap';
import ReactSpeedometer from "react-d3-speedometer";
import axios from 'axios';
import moment from "moment";
import TemperatureChart from './TemperatureChart';

const dummyDetails = [
    {id:1, name: 'Bathroom',temperature:50},
    {id:2,name: 'Warehouse',temperature:42},
    {id:3,name: 'Office',temperature:12},
];

class DashboardDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            alert: 86.5
        }
    }

    overallRatingsColor(){
        var alertValue;
        if(this.state.alert > 100){
          alertValue = "bg-danger"
        } else {
          alertValue = "bg-success"
        }
        return alertValue
    }

    renderDetails(){
        return(
            dummyDetails.map(detail =>
                <div className="flex-container">
                    <div className="flex-column-30">
                        <Card className="bg-primary">
                            <div className="title-text-speedometer">{detail.name}</div>
                            <div className="title-text-details">{detail.temperature}</div>
                            <div className="title-text-progressbar">
                                <Progress animated color="warning" bordered={false} value={50} max="135" />
                                <div className="text-center">50 of 135</div>
                            </div>
                        </Card>
                    </div>
                    <div className="flex-column-70">
                        <Chart2 />
                    </div>
                </div>
                )
        )
    }

    render(){
        return(
            <div className="animated fadeIn">
                <Card>
                    <CardBody>
                        <div className="flex-container">
                            <div className="flex-column-75">
                                <Card>
                                    <div className="title-text-speedometer">WAREHOUSE</div>
                                    <CardBody>
                                        <div className="flex-container">
                                            <div className="flex-column-25">Sensor ID</div>
                                            <div className="flex-column-75">: GSKX1400</div>
                                            <div className="flex-column-25">Location</div>
                                            <div className="flex-column-75">: Warehouse - Factory 1</div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className="flex-column-25">
                                <Button outline color="warning" className="dash-details-button">Edit Info</Button><br/><br/>
                                <Button outline color="danger" className="dash-details-button">Set Alert</Button>
                            </div>
                        </div>

                        <div className="flex-container">
                            <div className="flex-column-25">
                                <div className="title-text-speedometer">Current Temperature</div>
                                <ReactSpeedometer
                                maxValue={100}
                                minValue={0}
                                value={70}
                                needleColor="red"
                                startColor="green"
                                segments={5}
                                endColor="red"
                                needleTransitionDuration={4000}
                                currentValueText="70 °C"
                                needleTransition="easeElastic"
                                width={200}
                                height={150}
                                />
                            </div>

                            <div className="flex-column-25">
                                <div className="title-text-speedometer">Current Air Pressure</div>
                                <ReactSpeedometer
                                maxValue={100}
                                minValue={0}
                                value={70}
                                needleColor="red"
                                startColor="green"
                                segments={5}
                                endColor="red"
                                needleTransitionDuration={4000}
                                currentValueText="70 °C"
                                needleTransition="easeElastic"
                                width={200}
                                height={150}
                                />
                            </div>

                            <div className="flex-column-25">
                                <div className="title-text-speedometer">Current Humidity</div>
                                <ReactSpeedometer
                                maxValue={100}
                                minValue={0}
                                value={70}
                                needleColor="red"
                                startColor="green"
                                segments={5}
                                endColor="red"
                                needleTransitionDuration={4000}
                                currentValueText="70 °C"
                                needleTransition="easeElastic"
                                width={200}
                                height={150}
                                />
                            </div>

                            <div className="flex-column-25">
                                <div className="title-text-speedometer">Overall Ratings</div>
                                <Card className={this.overallRatingsColor()}>
                                <CardBody>
                                    <div className="title-text-ratings">86.5</div>
                                </CardBody>
                                </Card>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <Card>
                    {/* {this.renderDetails()} */}

                    <div className="flex-container">

                        <div className="flex-column-30">
                            <Card className="bg-primary">
                                <div className="title-text-speedometer">Average Temperature</div>
                                <div className="title-text-details">60</div>
                                <div className="title-text-progressbar">
                                    <Progress animated color="warning" bordered={false} value={50} max="135" />
                                    <div className="text-center">50 of 135</div>
                                </div>
                            </Card>
                        </div>
                        <div className="flex-column-70">
                            <Chart2 />
                        </div>

                        <div className="flex-column-30">
                            <Card className="bg-primary">
                                <div className="title-text-speedometer">Average Air Pressure</div>
                                <div className="title-text-details">60</div>
                                <div className="title-text-progressbar">
                                    <Progress animated color="warning" bordered={false} value={50} max="135" />
                                    <div className="text-center">50 of 135</div>
                                </div>
                            </Card>
                        </div>
                        <div className="flex-column-70">
                            <Chart2 />
                        </div>

                        <div className="flex-column-30">
                            <Card className="bg-primary">
                                <div className="title-text-speedometer">Average Humidity</div>
                                <div className="title-text-details">60</div>
                                <div className="title-text-progressbar">
                                    <Progress animated color="warning" bordered={false} value={50} max="135" />
                                    <div className="text-center">50 of 135</div>
                                </div>
                            </Card>
                        </div>
                        <div className="flex-column-70">
                            <Chart2 />
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

class Chart2 extends Component {
	constructor(props){
    super(props);

    this.state = {
      timestamp: 0,
      tempMachine1: 0,
      tempMachine2: 0,
      tempMachine3: 0,
      tempMachine4: 0,
      
      button1: false,
      button2: false,
      button3: false,
      button4: false,
    }
    this.fetchData = this.fetchData.bind(this);

  }

  fetchData(){
    var self = this;
      axios.post('https://api.insightia.id/factory/monitortest/', {
          TokenAdmin:'9lA526V5vqVwN0jr7vD7jy+M/3UrNarxR+SMjdv/xjU=',
      })
      .then(function (response) {
        //   console.log(response.data);
          // eslint-disable-next-line
          if(response.data != "" || response.data != null){
            var date = moment(response.data[0].timestamp).format("HH:mm:ss");
            self.setState({
              timestamp: date,
              tempMachine1: response.data[0].value
            })
        }
      })
      .catch(error => console.log(error));
      if(this.state.tempMachine1 > 85) {
        // toast.warn(<div><strong>Machine 1 Temp over limit!</strong><br /><em>Current temp: {this.state.tempMachine1}</em></div>)
      }
  }

  componentDidMount() {
    setInterval(this.fetchData, 3000);
  }


  
  
	render() {
    // var self = this;
		return (
      <div>
      <Row>
          <Col md="12">
          <TemperatureChart
            tempMachine1={this.state.tempMachine1}
            timestamp={this.state.timestamp}
          />
          </Col>
      </Row>
     </div>    
		);
	}
}

export default DashboardDetails;

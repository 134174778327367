import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
} from "reactstrap";
import { BarLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-toastify";
import * as API_LINKS from "../../../redux/config/links";
import { FaCheckCircle } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import CustomToast from "../../../components/CustomToast/CustomToast";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  }

  showDetails = () => {
    var self = this;
    const { isLoading, oldPassword, newPassword, confirmNewPassword } =
      this.state;
    return (
      <div>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Old Password</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="password"
              name="text-input"
              id="oldPassword"
              placeholder="**********"
              value={oldPassword}
              onChange={(e) => this.setState({ oldPassword: e.target.value })}
              disabled={isLoading}
              autoComplete="off"
              required
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">New Password</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="password"
              name="text-input"
              id="newPassword"
              placeholder="**********"
              value={newPassword}
              onChange={(e) => this.setState({ newPassword: e.target.value })}
              disabled={isLoading}
              autoComplete="off"
              required
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Confirm New Password</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="password"
              name="text-input"
              id="cnfmNewPassword"
              placeholder="**********"
              value={confirmNewPassword}
              onChange={(e) =>
                this.setState({ confirmNewPassword: e.target.value })
              }
              disabled={isLoading}
              autoComplete="off"
              required
            />
          </Col>
        </FormGroup>
        {this.state.isLoading ? (
          <center>
            <BarLoader color={"#123abc"} loading={this.state.isLoading} />{" "}
            <br /> Loading.... Please wait...
          </center>
        ) : (
          <div>
            <Button
              onClick={() => this.handleChangePassword()}
              size="sm"
              color="success"
              disabled={self.state.isLoading}
            >
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button
              onClick={() => this.handleReset()}
              size="sm"
              color="warning"
              disabled={self.state.isLoading}
            >
              <i className="fa fa-window-close"></i> Reset
            </Button>
          </div>
        )}
      </div>
    );
  };

  handleChangePassword = () => {
    const { oldPassword, newPassword, confirmNewPassword } = this.state;

    this.setState({ isLoading: true });
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    var _email = loginCreds.email;

    if (oldPassword === "" || oldPassword === null) {
      self.setState({ isLoading: false });
      toast.error(
        <CustomToast text="Old password is empty!" icon={<BiSolidError />} />,
        {
          autoClose: 3000,
        }
      );
    } else if (
      newPassword === "" ||
      newPassword === null ||
      newPassword.length < 8
    ) {
      self.setState({ isLoading: false });
      toast.error(
        <CustomToast
          text="New password is empty or less than 8 characters!"
          icon={<BiSolidError />}
        />,
        {
          autoClose: 3000,
        }
      );
    } else if (
      confirmNewPassword === "" ||
      confirmNewPassword === null ||
      confirmNewPassword.length < 7
    ) {
      self.setState({ isLoading: false });
      toast.error(
        <CustomToast
          text="Confirm New password is empty or less than 8 characters!"
          icon={<BiSolidError />}
        />,
        {
          autoClose: 3000,
        }
      );
    } else if (newPassword !== confirmNewPassword) {
      self.setState({ isLoading: false });
      toast.error(
        <CustomToast
          text="New password did not match!"
          icon={<BiSolidError />}
        />,
        {
          autoClose: 3000,
        }
      );
    } else {
      axios({
        method: "post",
        url: API_LINKS.CHANGE_PASSWORD_URL, //Please change when it's ready
        headers: {
          Authorization: auth,
        },
        data: {
          email: _email,
          password: oldPassword,
          new_password: newPassword,
          confirm_new_password: confirmNewPassword,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            self.setState({
              isLoading: false,
              oldPassword: "",
              newPassword: "",
              confirmNewPassword: "",
            });
            return toast.success(
              <CustomToast
                text="Password successfully changed!"
                icon={<FaCheckCircle />}
              />,
              {
                autoClose: 3000,
              }
            );
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              self.setState({
                isLoading: false,
              });
              return toast.error(
                <CustomToast
                  text={error.response.data.message}
                  icon={<BiSolidError />}
                />,
                {
                  autoClose: 3000,
                }
              );
            } else if (error.response.status === 403) {
              self.setState({
                isLoading: false,
              });
              return toast.error(
                <CustomToast
                  text={error.response.data.message}
                  icon={<BiSolidError />}
                />,
                {
                  autoClose: 3000,
                }
              );
            } else if (error.response.status === 400) {
              self.setState({
                isLoading: false,
              });
              return toast.error(
                <CustomToast
                  text={error.response.data.message}
                  icon={<BiSolidError />}
                />,
                {
                  autoClose: 3000,
                }
              );
            } else if (
              error.response.status === 404 ||
              error.response.status === 500
            ) {
              self.setState({
                isLoading: false,
              });
              return toast.error(
                <CustomToast
                  text="Server cannot be contacted! Please ask your system administrator!"
                  icon={<BiSolidError />}
                />,
                {
                  autoClose: 3000,
                }
              );
            } else {
              self.setState({
                isLoading: false,
              });
              return toast.error(
                <CustomToast
                  text="Something went wrong... Please try again later..."
                  icon={<BiSolidError />}
                />,
                {
                  autoClose: 3000,
                }
              );
            }
          } else if (error.request) {
            self.setState({
              isLoading: false,
            });
            return toast.error(
              <CustomToast
                text="Request error! Please try again later..."
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          } else {
            self.setState({
              isLoading: false,
            });
            return toast.error(
              <CustomToast
                text="Something went wrong... Please try again later..."
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          }
        });
    }
  };

  handleReset = () => {
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
  };

  render() {
    return (
      <div>
        <Card>
          <CardHeader>Change Password</CardHeader>
          <CardBody>{this.showDetails()}</CardBody>
        </Card>
      </div>
    );
  }
}

export default ChangePassword;

import axios from "axios";
import React from "react";
import moment from "moment";
import { toast } from "react-toastify";
import truckRotate0 from "../assets/img/truckrotate-0.svg";
import truckRotate40 from "../assets/img/truckrotate-40.svg";
import truckRotate90 from "../assets/img/truckrotate-90.svg";
import truckRotate140 from "../assets/img/truckrotate-140.svg";
import truckRotate180 from "../assets/img/truckrotate-180.svg";
import truckRotate220 from "../assets/img/truckrotate-220.svg";
import truckRotate270 from "../assets/img/truckrotate-270.svg";
import truckRotate320 from "../assets/img/truckrotate-320.svg";
import threePlTruckRotate0 from "../assets/img/3pl-truckrotate-0.svg";
import threePlTruckRotate40 from "../assets/img/3pl-truckrotate-40.svg";
import threePlTruckRotate90 from "../assets/img/3pl-truckrotate-90.svg";
import threePlTruckRotate140 from "../assets/img/3pl-truckrotate-140.svg";
import threePlTruckRotate180 from "../assets/img/3pl-truckrotate-180.svg";
import threePlTruckRotate220 from "../assets/img/3pl-truckrotate-220.svg";
import threePlTruckRotate270 from "../assets/img/3pl-truckrotate-270.svg";
import threePlTruckRotate320 from "../assets/img/3pl-truckrotate-320.svg";
import renteeTruckRotate0 from "../assets/img/rentee-truckrotate-0.svg";
import renteeTruckRotate40 from "../assets/img/rentee-truckrotate-40.svg";
import renteeTruckRotate90 from "../assets/img/rentee-truckrotate-90.svg";
import renteeTruckRotate140 from "../assets/img/rentee-truckrotate-140.svg";
import renteeTruckRotate180 from "../assets/img/rentee-truckrotate-180.svg";
import renteeTruckRotate220 from "../assets/img/rentee-truckrotate-220.svg";
import renteeTruckRotate270 from "../assets/img/rentee-truckrotate-270.svg";
import renteeTruckRotate320 from "../assets/img/rentee-truckrotate-320.svg";
import circleMarkerRed from "../assets/img/circle-marker-red.svg";
import circleMarkerGreen from "../assets/img/circle-marker-green.svg";
import circleMarkerBlue from "../assets/img/circle-marker-blue.svg";
import circleMarkerBlack from "../assets/img/circle-marker-black.svg";
import CustomToast from "../components/CustomToast/CustomToast";
import { BiSolidError } from "react-icons/bi";

export const imageFormatter = (data, setModalContent, toggleModal) => {
  let assetPicture = data;

  if (assetPicture && assetPicture.type === "Buffer") {
    assetPicture = String.fromCharCode.apply(null, assetPicture.data);
  }

  let width = "100%";
  let height = "40%";
  if (!assetPicture) {
    assetPicture = "/assets/img/empty-file.png";
    width = "25px";
    height = "30px";
  }

  const isPdf = assetPicture.endsWith(".pdf");
  const handleClick = () => {
    setModalContent({ isPdf, src: assetPicture });
    toggleModal();
  };

  return (
    <>
      {isPdf ? (
        <>
          <embed
            width={width}
            height={height}
            src={assetPicture}
            type="application/pdf"
          />
          <i
            onClick={width === "25px" ? null : handleClick}
            className="text-primary"
            style={
              width === "25px" ? { cursor: "auto" } : { cursor: "pointer" }
            }
          >
            <b>Open document</b>
          </i>
        </>
      ) : (
        <img
          width={width}
          height={height}
          src={assetPicture}
          loading="lazy"
          onClick={width === "25px" ? null : handleClick}
          style={width === "25px" ? { cursor: "auto" } : { cursor: "pointer" }}
          alt="Preview"
        />
      )}
    </>
  );
};

export const fileNameFormat = (name) => {
  var baseName = `${name} - `;
  var baseDate = new Date();
  var date = moment(baseDate).format("DD-MMM-YYYY HH:mm");
  var strDate = date.toString();
  return baseName + strDate + ".csv";
};

export const fileNameFormatWithType = (name, fileType) => {
  var baseDate = new Date();
  var date = moment(baseDate).format("DD-MMM-YYYY HH:mm");
  var strDate = date.toString();
  return `${name} - ${strDate}.${fileType}`;
};

export const bootstrapTableOption = (params) => {
  return {
    defaultSortName: "id", // default sort column name
    defaultSortOrder: "asc", // default sort order
    noDataText: params && !!params.noDataText ? params.noDataText : "No place registered on your dashboard. Try one-lah!" ,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
    ],
    sizePerPage: 20,
    exportCSVSeparator: ";",
    ...(params && params.defaultSortName
      ? { defaultSortName: params.defaultSortName }
      : {}),
    ...(params && params.defaultSortOrder
      ? { defaultSortOrder: params.defaultSortOrder }
      : {}),
    ...(params && params.btnGroup ? { btnGroup: params.btnGroup } : {}),
  };
};

const onArrayForm = (name, data, obj) => {
  let newObj = [...obj];
  for (const key in data) {
    if (
      (Array.isArray(data[key]) || typeof data[key] === "object") &&
      !(data[key] instanceof File)
    ) {
      newObj = onArrayForm(`${name}[${key}]`, data[key], newObj);
    } else {
      newObj = [...newObj, { label: `${name}[${key}]`, value: data[key] }];
    }
  }
  return newObj;
};

export const expandJSON = (data) => {
  let obj = [];
  for (const key in data) {
    if (
      Array.isArray(data[key]) ||
      (typeof data[key] === "object" && !(data[key] instanceof File))
    ) {
      obj = onArrayForm(`${key}`, data[key], obj);
    } else {
      obj = [
        ...obj,
        {
          label: key,
          value: data[key],
        },
      ];
    }
  }
  return obj;
};

export const onFetch = (
  { url, data, type = "json" },
  { beforeSend, success, error }
) => {
  beforeSend();

  var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
  var auth = loginCreds.token;

  const form = new FormData();
  const dt = expandJSON(data);
  for (let i in dt) {
    form.append(dt[i].label, dt[i].value);
  }

  axios({
    method: "post",
    url: url,
    headers: {
      Authorization: auth,
      "Content-Type":
        type === "json" ? "application/json" : "multipart/form-data",
    },
    data: type === "json" ? data : form,
  })
    .then((response) => {
      success(response);
    })
    .catch(function (err) {
      error(err);
    });
};

const onFetchSingle = ({ url, data, type }, cb) =>
  onFetch(
    { url, data, type },
    {
      beforeSend: () => null,
      success: (response) => cb(null, response),
      error: (err) => cb(err, false),
    }
  );

export const onFetchAll = (
  { url, data: datas, type },
  { beforeSend = () => null, success, error },
  index = 0,
  results = [],
  errors = []
) => {
  let dts = [];
  let dte = [];
  if (index === 0) {
    beforeSend();
  }
  if (!!datas[index]) {
    onFetchSingle({ url, data: datas[index], type }, (err, response) => {
      dts = [...results];
      dte = [...errors];
      if (!err) {
        dts = [...dts, response];
      } else {
        dte = [...dte, err];
      }

      if (index < datas.length - 1) {
        onFetchAll(
          { url, data: datas, type },
          { beforeSend, success, error },
          index + 1,
          [...dts],
          [...dte]
        );
      } else {
        success(dts.length > 0 ? dts : false);
        if (dte.length > 0) {
          error(dte);
        }
      }
    });
  } else {
    success(dts.length > 0 ? dts : false);
    if (dte.length > 0) {
      error(dte);
    }
  }
};

export const onFetchAllAsync = (
  { url, data: datas = [], type },
  { beforeSend = () => null, success, error }
) => {
  beforeSend();
  Promise.all(
    (Array.isArray(datas) ? datas : []).map((item) => {
      var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
      var auth = loginCreds.token;

      const form = new FormData();
      const dt = expandJSON(item);
      for (let i in dt) {
        form.append(dt[i].label, dt[i].value);
      }
      return axios({
        method: "post",
        url: url,
        headers: {
          Authorization: auth,
          "Content-Type":
            type === "json" ? "application/json" : "multipart/form-data",
        },
        data: type === "json" ? item : form,
      });
    })
  )
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

// interface IConfig{
//   url: string;
//   path?: string;
//   method?: "GET"|"POST"|"PUT"|"DELETE",
//   data?: unknown;
//   type?: "formdata" | "json"
// }

export const onFetchArrayAsync = (config=[],
  { beforeSend = () => null, success, error }) =>{
    beforeSend();
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;
    Promise.all(config.map((item)=>{
      const form = new FormData();
      if(item.data){
        const dt = expandJSON(item.data);
        for(let i in dt){
          form.append(dt[i].label, dt[i].value);
        }
      }
      return axios({
        method: !!item.method ? item.method : "GET",
        url: item.url + (!!item.path ? item.path : ""),
        headers: {
          Authorization: auth,
          "Content-Type":
            item.type === "json" ? "application/json" : "multipart/form-data",
        },
        ...((!!item.method ? item.method : "GET") === "GET")
        ? {
          params: item.data
        }:{
          data: item.type === "json" ? item.data: form
        }
      })
    })).then((response)=>{
      success(response)
    }).catch((err)=>{
      error(err);
    })
  }

export const showTruckValueBasedOnEngineAndSpeedStatus = (spd, wrn) => {
  if (spd > 0 && wrn === 0) return "OFF (WARNING!)";
  if (wrn === 0) return "OFF";
  if (spd > 0) return "ON MOVING";
  if (spd === 0) return "STANDBY";
  return "INACTIVE";
};

export const handleRequestError = (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        return toast.error(
          <CustomToast
            text="Invalid username or password! Please try again!"
            icon={<BiSolidError />}
          />,
          {
            autoClose: 3000,
          }
        );
      case 404:
        return toast.error(
          <CustomToast text="Data not found!" icon={<BiSolidError />} />,
          {
            autoClose: 3000,
          }
        );
      case 500:
        return toast.error(
          <CustomToast
            text="Server cannot be contacted! Please ask your system administrator!"
            icon={<BiSolidError />}
          />,
          {
            autoClose: 3000,
          }
        );
      case 400:
        return toast.error(
          <CustomToast
            text={error.response.data.message}
            icon={<BiSolidError />}
          />,
          {
            autoClose: 3000,
          }
        );
      default:
        return toast.error(
          <CustomToast
            text="Something went wrong... Please try again later..."
            icon={<BiSolidError />}
          />,
          {
            autoClose: 3000,
          }
        );
    }
  } else if (error.request) {
    return toast.error(
      <CustomToast
        text="Request error! Please try again later..."
        icon={<BiSolidError />}
      />,
      {
        autoClose: 3000,
      }
    );
  } else {
    return toast.error(
      <CustomToast
        text="Something went wrong... Please try again later..."
        icon={<BiSolidError />}
      />,
      {
        autoClose: 3000,
      }
    );
  }
};

export const handleFleetIcon = (radian, owner) => {
  const getIcon = (prefix) => {
    if (radian >= 0 && radian < Math.PI / 4) {
      return prefix[0];
    } else if (radian >= Math.PI / 4 && radian < Math.PI / 2) {
      return prefix[40];
    } else if (radian >= Math.PI / 2 && radian < (3 * Math.PI) / 4) {
      return prefix[90];
    } else if (radian >= (3 * Math.PI) / 4 && radian < Math.PI) {
      return prefix[140];
    } else if (radian >= Math.PI && radian < (5 * Math.PI) / 4) {
      return prefix[180];
    } else if (radian >= (5 * Math.PI) / 4 && radian < (3 * Math.PI) / 2) {
      return prefix[220];
    } else if (radian >= (3 * Math.PI) / 2 && radian < (7 * Math.PI) / 4) {
      return prefix[270];
    } else if (radian >= (7 * Math.PI) / 4 && radian < 2 * Math.PI) {
      return prefix[320];
    } else {
      return prefix[0]; // default value
    }
  };

  const iconSets = {
    "Own Fleet": {
      // icon truck hijau
      0: truckRotate0,
      40: truckRotate40,
      90: truckRotate90,
      140: truckRotate140,
      180: truckRotate180,
      220: truckRotate220,
      270: truckRotate270,
      320: truckRotate320,
    },
    "Own fleet": {
      // icon truck hijau
      0: truckRotate0,
      40: truckRotate40,
      90: truckRotate90,
      140: truckRotate140,
      180: truckRotate180,
      220: truckRotate220,
      270: truckRotate270,
      320: truckRotate320,
    },
    "3PL fleet": {
      // icon truck oren
      0: threePlTruckRotate0,
      40: threePlTruckRotate40,
      90: threePlTruckRotate90,
      140: threePlTruckRotate140,
      180: threePlTruckRotate180,
      220: threePlTruckRotate220,
      270: threePlTruckRotate270,
      320: threePlTruckRotate320,
    },
    "3PL Fleet": {
      // icon truck oren
      0: threePlTruckRotate0,
      40: threePlTruckRotate40,
      90: threePlTruckRotate90,
      140: threePlTruckRotate140,
      180: threePlTruckRotate180,
      220: threePlTruckRotate220,
      270: threePlTruckRotate270,
      320: threePlTruckRotate320,
    },
    "Rental Fleet": {
      // icon truck putih
      0: renteeTruckRotate0,
      40: renteeTruckRotate40,
      90: renteeTruckRotate90,
      140: renteeTruckRotate140,
      180: renteeTruckRotate180,
      220: renteeTruckRotate220,
      270: renteeTruckRotate270,
      320: renteeTruckRotate320,
    },
    "Rental fleet": {
      // icon truck putih
      0: renteeTruckRotate0,
      40: renteeTruckRotate40,
      90: renteeTruckRotate90,
      140: renteeTruckRotate140,
      180: renteeTruckRotate180,
      220: renteeTruckRotate220,
      270: renteeTruckRotate270,
      320: renteeTruckRotate320,
    },
  };

  const selectedIconSet = iconSets[owner] || iconSets["Own Fleet"];
  return getIcon(selectedIconSet);
};

export const handleFleetOwner = (owner) => {
  switch (owner) {
    case "Own Fleet":
      return truckRotate0;
    case "Own fleet":
      return truckRotate0;
    case "3PL Fleet":
      return threePlTruckRotate0;
    case "3PL fleet":
      return threePlTruckRotate0;
    case "Rental Fleet":
      return renteeTruckRotate0;
    case "Rental fleet":
      return renteeTruckRotate0;
    default:
      return truckRotate0;
  }
};

export const handleMarkerType = (data) => {
  switch (data) {
    case "AABAC":
      return circleMarkerBlue;
    case "CAME":
      return circleMarkerGreen;
    case "LATAM":
      return circleMarkerBlack;
    case "FCA":
      return circleMarkerRed;

    default:
      return circleMarkerRed;
  }
};

export function formatNumber(input) {
  const number = Number(input);
  // Cek apakah input valid, bukan nol, null, undefined
  if (isNaN(number)) {
    return "0";
  }
  // Jika number adalah floating-point atau kurang dari 1000, kembalikan input asli sebagai string
  if (!Number.isInteger(number) || number < 1000) {
    return number.toString();
  }
  // Format angka lebih besar dari atau sama dengan 1000
  return number.toLocaleString("id-ID");
}

import React, { Component } from 'react'
import {
    Button,
    FormGroup,
    Label,
    Input,
    Col,
    FormFeedback,
} from 'reactstrap'
import axios from 'axios';
import { SelectField } from '../../../LandLord/Widgets/SelectField'
import {PUBLIC_FACILITY_OWNER} from '../../../../constants/constants'
import {PUBLIC_FACILITY_ADD_URL}  from '../../../../redux/config/links'
import {connect} from 'react-redux'
import {BarLoader} from 'react-spinners'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {toast} from 'react-toastify'
import {fetchPublicFacilityType} from '../../../../redux/actions/transporter/publicfacilitytype.act'
import handleErrorResponseCode from '../../../../helpers/error-response-code-handler'
class AddEditPublicFacilityForm extends Component {

    constructor(props){
        super(props)
        this.props.fetchPublicFacilityType()
    }

    validation = () => {
        let selectYup = (errorPrompt) => {
            return Yup.object().shape({
                label: Yup.string().required(errorPrompt),
                value: Yup.number().required(errorPrompt)
            })
        }
            
        let yup = Yup.object().shape({
                        name : Yup.string().required('Public facility name should be stated!'),
                        address : Yup.string().required('Public facilityaddress should be stated!'),
                        lat : Yup.string().required('Public facility latitude should be stated!'),
                        lng : Yup.string().required('Public facility longitude should be stated!'),
                        owner : selectYup("Owner selection is required!"),
                        id_type: selectYup("Type selection is required!")
                    })
        return yup
    }

    form = () => {
        const {isAdd, initialValues} = this.props
        const {addFormClose, editFormClose, refreshTable} = this.props
        return(
            <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                let auth = loginCreds.token;
                setSubmitting(true)
                values.owner = values.owner.label
                values.id_type = values.id_type? values.id_type.value : null
                if(isAdd){
                    axios({
                            method: 'post',
                            url: PUBLIC_FACILITY_ADD_URL,
                            headers: {
                                Authorization: auth,
                            },
                            data : values
                        }).then((response) => {
                            if(response.status === 200){
                                setSubmitting(false)
                                toast.success('Thank you for your contribution, we will verify it shortly')
                                addFormClose()
                                refreshTable()
                            }
                        })
                    .catch(function(error){
                        setSubmitting(false)
                        toast.error(handleErrorResponseCode(error))
                    })
                    // addFormClose()
                }
                setSubmitting(false)
            }} 
            validationSchema={this.validation()}
            render={({
            values,errors,
            touched,setFieldValue,
            handleSubmit,handleBlur,
            handleChange,handleReset,
            isValid,isSubmitting,
            }) => (
                <form>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Code</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Input type="text"
                                    name="name"
                                    id="name"
                                    placeholder="PublicFacility Name"
                                    autoComplete="name"
                                    valid={!errors.name}
                                    invalid={touched.name && !!errors.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name || ""}
                                    disabled={isSubmitting}
                                    />
                            <FormFeedback>{errors.name}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Address</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Input type="textarea"
                                    name="address"
                                    id="address"
                                    placeholder="Address"
                                    valid={!errors.address}
                                    invalid={touched.address && !!errors.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address || ""}
                                    disabled={isSubmitting}
                                    />
                            <FormFeedback>{errors.address}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Owner</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <SelectField
                                    id="owner"
                                    name="owner"
                                    placeholder="Select Owner"
                                    options={PUBLIC_FACILITY_OWNER}
                                    value={values.owner}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={handleBlur}
                                    touched={touched.owner}
                                    error={errors.owner}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                    disabled={isSubmitting}
                                />   
                            </Col>
                            <FormFeedback>{errors.owner? errors.owner.label: "Owner selection is required!"}</FormFeedback>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Public Facility Type</Label>
                            </Col>
                            <Col xs="12" md="9">
                            {
                                this.props.inProgressPublicFacilityType ? 
                                <center><BarLoader color={'#123abc'} loading={this.props.inProgressPublicFacilityType}/> 
                                <br /> Loading.... Please wait...</center>
                                    : 
                                    (
                                this.props.successPublicFacilityType ? 
                                <SelectField
                                    id="id_type"
                                    name="id_type"
                                    placeholder="Select Public Facility Type"
                                    options={[{'label':"Gas Station", 'value':1},
                                                {'label':"Airport", 'value':7},     
                                                {'label':"Harbor", 'value':6},
                                                {'label':"Bank dan ATM", 'value':8},
                                            ]}
                                    value={values.id_type}
                                    isMulti={false}
                                    onChange={setFieldValue}
                                    onBlur={handleBlur}
                                    touched={touched.id_type}
                                    error={errors.id_type}
                                    isClearable={true}
                                    backspaceRemovesValue={true}
                                    disabled={isSubmitting}
                                />
                                : 
                                <center>Error fetching your data... <br/><br/> 
                                    <Button className="btn btn-primary" color="primary" size="md"
                                        disabled={this.props.inProgressPublicFacilityType} 
                                        onClick={() => this.props.fetchPublicFacilityType()}>
                                        Try again
                                    </Button>
                                </center>
                                )
                            }
                            </Col>
                            <FormFeedback>{errors.id_type? errors.id_type.label : "Type selection is required!"}</FormFeedback>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Latitude</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Input type="number"
                                    name="lat"
                                    id="lat"
                                    placeholder="Latitude"
                                    valid={!errors.lat}
                                    invalid={touched.lat && !!errors.lat}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lat || ""}
                                    disabled={isSubmitting}
                                    />
                            <FormFeedback>{errors.lat}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Longitude</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Input type="number"
                                    name="lng"
                                    id="lng"
                                    placeholder="Longitude"
                                    valid={!errors.lng}
                                    invalid={touched.lng && !!errors.lng}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lng || ""}
                                    disabled={isSubmitting}
                                    />
                            <FormFeedback>{errors.lng}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Remark</Label>
                            </Col>
                            <Col xs="12" md="9">
                                <Input type="textarea"
                                    name="desc"
                                    id="desc"
                                    placeholder="Remark"
                                    valid={!errors.desc}
                                    invalid={touched.desc && !!errors.desc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.desc || ""}
                                    disabled={isSubmitting}
                                    />
                            <FormFeedback>{errors.desc}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <this.Error error={errors} />
                        <FormGroup>
                            <Button type="button" 
                                    onClick={handleSubmit} 
                                    color="primary" 
                                    className="mr-1" 
                                    disabled={(isSubmitting || !isValid)}>{isSubmitting ? 'Wait...' : isAdd? 'Save Changes' : 'Edit'}
                            </Button>
                            <Button type="button" color="warning" className="mr-1" disabled={isSubmitting} onClick={isAdd? addFormClose : editFormClose}>Close</Button>
                            <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button>
                        </FormGroup>
                    </form>
                )}
            />
        )
    }

    Error = (props) => {
        console.log(props.error)
        return <></>
    }

    render(){
        return(
            <React.Fragment>
                {this.form()}
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        publicFacilityTypeSelect: state.publicFacilityTypeStore.publicFacilityTypeSelect,
        inProgressPublicFacilityType: state.publicFacilityTypeStore.inProgress,
        successPublicFacilityType: state.publicFacilityTypeStore.success,
    }
}

export default connect(mapStateToProps, {fetchPublicFacilityType})(AddEditPublicFacilityForm);
import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Col,
    Alert,
    FormGroup
} from 'reactstrap'
import PropTypes from 'prop-types'
import {BarLoader} from 'react-spinners'
import axios from 'axios'
import * as API_LINKS from '../../../redux/config/links';
import {toast} from 'react-toastify'
import decodePolyline from 'decode-google-map-polyline'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow, 
    KmlLayer,
    Polyline
  } from "react-google-maps"
import { compose } from "recompose"
import {connect} from 'react-redux'
import { fetchWorkOrdersTable } from '../../../redux/actions/transporter/aTransporter-WorkOrdersTable';
import WorkOrderWidget from './WorkOrderWidget'

import customMarkerDeparture from '../../../assets/img/airport.png'
import customMarkerHome from '../../../assets/img/home.png'
import customMarkerRed from '../../../assets/img/newmarker.png'

function generateRandom() {
    return Math.random() * 10000000000000000
}

const MapWithAMarker = compose(
    withScriptjs, 
    withGoogleMap,
    )(props => {
        const dcPoly = props.mapOptimized ? decodePolyline(props.mapOptimized) : []
    return (
        <GoogleMap defaultZoom={9} defaultCenter={{ lat:  ( !props.companyLat ? -7.6221790314:  props.companyLat), lng: ( !props.companyLng ? 110.8043441772:  props.companyLng) }}>
            <KmlLayer url={props.kmlLink + "&ver=" + generateRandom()} options={{ preserveViewport: true }}/>
            <Polyline
                path={dcPoly}
                geodesic={true}
                options={{
                    strokeColor: "#3bba3c",
                    strokeOpacity: 0.90,
                    strokeWeight: 5,
                    icons: [
                        {
                            // icon: lineSymbol,
                            offset: "0",
                            repeat: "20px"
                        }
                    ]
                }}
            />
            {/* <MapDirectionsRenderer
                waypoints={props.waypoints}
                avoidToll={props.doDetails[0].avoid_toll > 0 ? true : false}
                travelMode={window.google.maps.TravelMode.DRIVING}
            /> */}
        
        {/* Fleet Markers */}
        {/* {props.markers.map((marker, i) => {
          const onClick = props.onClick.bind(this, marker)
            return (
                <Marker
                  key={i}
                  onClick={onClick}
                    options={marker.wrn > 0 ? (marker.spd > 5 ? {icon: customMarker} : {icon: customMarkerYellow}) : {icon: customMarkerRed}}
                  position={{ lat: marker.lat, lng: marker.lng }}
                >
                    {props.selectedMarker === marker &&
                  <InfoWindow>
                    <div>
                    {props.doDetails[0].fleet_plate}<br/>{marker.obd_id}<br/>{marker.time}<br/><span>Latitude: {marker.lat}</span>
                    <br/><span>Longitude: {marker.lng}</span>
                    <br/><span>Speed: {marker.spd} KM/h</span>
                    <br/><span>Engine Status: {marker.wrn > 0 ? 'Engine On' : 'Engine Off'}</span>
                    </div>
                  </InfoWindow>
                }
                </Marker>
            )
        })} */}
        {/* WO Markers */}
        {
            props.woMarker.length > 0 || props.woMarker !== undefined ? 
            (
                props.woMarker.map(marker => { 
                    // const onClick = props.onClick.bind(this, marker)
                        if(marker.wo_seq === 0) {
                            return (
                                <Marker
                                key={marker.id}
                                // onClick={onClick}
                                options={{icon: customMarkerDeparture}}
                                position={{ lat: marker.lat, lng: marker.lng }}
                                >
                                    {props.selectedMarker === marker &&
                                <InfoWindow>
                                    <div>
                                    {marker.wo_destination_name ? marker.wo_destination_name : ''}
                                    </div>
                                </InfoWindow>
                                }
                                </Marker>
                            )
                        } else if(marker.wo_seq === 9989) {
                            return (
                                <Marker
                                key={marker.id}
                                // onClick={onClick}
                                options={{icon: customMarkerHome}}
                                position={{ lat: marker.lat, lng: marker.lng }}
                                >
                                    {/* {props.selectedMarker === marker && */}
                                <InfoWindow>
                                    <div>
                                    {marker.wo_destination_name ? marker.wo_destination_name : ''}
                                    </div>
                                </InfoWindow>
                                {/* } */}
                                </Marker>
                            )
                        } else {
                            return (
                                <Marker
                                key={marker.id}
                                // onClick={onClick}
                                options={{icon: customMarkerRed}}
                                position={{ lat: marker.lat, lng: marker.lng }}
                                >
                                    {/* {props.selectedMarker === marker && */}
                                <InfoWindow>
                                    <div>
                                    {marker.wo_destination_name ? marker.wo_destination_name : ''}
                                    </div>
                                </InfoWindow>
                                {/* } */}
                                </Marker>
                            )
                        }
                    }
                )
            )
            :
            true
        }
      </GoogleMap>
    )
})

const propTypes = {
    id_do: PropTypes.any,
    loading_state: PropTypes.bool,
    totalWO: PropTypes.number,
    optimize: PropTypes.bool,
}

const defaultProps = {
    id_do: 0,
    loading_state: false,
    totalWO: 0,
    optimize: true
}

class WorkOrdersPreviewOptimize extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            woOptimized: [],
            mapOptimized: [],
            isLoading: false,
            success: true,
        };
    }

    toggleOpenAdd = () => {
        this.handleFetch(this.props.id_do)
        this.setState({
            modalAdd: true
        })
    }

    toggleCloseAdd = () => {
        this.setState({
            modalAdd: false,
            woOptimized: [],
            mapOptimized: [],
        })
    }

    handleFetch = (_id) => {
        var self = this;
        var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        var auth = loginCreds.token;

        this.setState({
            isLoading: true,
            success: true
        })

        axios({
            method:'post',
            url: API_LINKS.WORK_ORDERS_DRAFT_CHECK_URL, //Please change when it's ready
            headers: {
                Authorization: auth
            },
            data: {
                id_delivery_order: _id,
                optimize: self.props.optimize
            }
        })
        .then((response) => {
            // console.log(response)
            if(response.status === 200) {
                if (response.data.status === "success") {
                    self.setState({
                        woOptimized: response.data.result.wo,
                        mapOptimized: response.data.result.overview_polyline ? response.data.result.overview_polyline : [],
                        success: true,
                        isLoading: false
                    })
                    // self.toggleCloseAdd()
                    toast.success('Please check your route optimization. Optimization preview complete.')
                    // self.props.updateWO(self.props.id_do);
                }
            }
        })
        .catch(function(error){
            if (error.response) {
                if(error.response.status === 401) {
                    self.setState({
                        success: false,
                        isLoading: false
                    })
                    toast.error(error.response.data.message);
                } else if (error.response.status === 403) {
                    self.setState({
                        success: false,
                        isLoading: false
                    })
                    toast.error(error.response.data.message);
                } else if (error.response.status === 400) {
                    self.setState({
                        success: false,
                        isLoading: false
                    })
                    toast.error(error.response.data.message);
                } else if (error.response.status === 404 || error.response.status === 500) {
                    self.setState({
                        success: false,
                        isLoading: false
                    })
                    toast.error("Server cannot be contacted! Please ask your system administrator!");
                } else {
                    self.setState({
                        success: false,
                        isLoading: false
                    })
                    toast.error('Something went wrong... Please try again later...')
                }
            } else if (error.request) {
                self.setState({
                    success: false,
                    isLoading: false
                })
                toast.error('Request error! Please try again later...')
            } else {
                self.setState({
                    success: false,
                    isLoading: false
                })
                toast.error('Something went wrong... Please try again later...')
            }
        })    
    }

    viewContent = () => {
        // var self = this;
        const { optimize } = this.props;
        return(
            <div className="container">
                <FormGroup row>
                    <Col xs="12" md="12">
                        <Alert color="warning">{optimize ? 'This preview only for route optimization review. If you wish not to use, you can just save as per you input your work order or if you wish to continue with the optimized route, you can choose save and optimise later on.' : 'This preview only for route review. You can choose between using our smart optimize or based on your input routes.If you wish to continue with your current route preview, click on Save later on.'}</Alert>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" md="12">
                        {
                            this.state.isLoading ? 
                            <center><BarLoader color={'#123abc'} loading={this.state.isLoading}/> <br /> Loading.... Please wait...</center>
                            : 
                            (
                                this.state.success ? 
                                // <WorkOrderList workOrderData={this.props.workOrdersTableData} />
                                <div>
                                {this.state.woOptimized.map((data, i)=>{
                                    console.log(data)
                                    return (
                                        <WorkOrderWidget 
                                            key={data.id}
                                            id={data.id}
                                            id_do={this.props.id_do}
                                            wo_number={data.wo_number}
                                            wo_seq={data.wo_seq}
                                            wo_desc={data.wo_desc}
                                            wo_destination_name={data.wo_destination_name}
                                            wo_address={data.wo_address}
                                            pic_name={data.pic_name}
                                            pic_phone={data.pic_phone}
                                            loc_name={data.loc_name}
                                            lat={data.lat}
                                            lng={data.lng}
                                            dept_lat={data.dept_lat}
                                            dept_lng={data.dept_lng}
                                            est_departure_time={data.est_departure_time}
                                            est_arrival_time={data.est_arrival_time}
                                            est_stop_time={data.est_stop_time}
                                            dept_time={data.dept_time}
                                            arrival_time={data.arrival_time}
                                            break_time={data.break_time}
                                            cargo_weight={data.cargo_weight}
                                            // loading_time={data.loadingTime}
                                            // check_in={data.timeCheckIn}
                                            // check_out={data.timeCheckOut}
                                            // distance={data.distance}
                                            // share={data.share}
                                            // status_delivery_detail={data.status_delivery_detail}
                                            // approval_time={data.approval_time}
                                        />
                                    )
                                })}
                                </div>
                                : 
                                <center>Error fetching your data... <br/><br/> 
                                    <Button className="btn btn-primary" color="primary" size="md" disabled={this.state.isLoading} onClick={() => this.props.handleFetch(this.props.id_do)}>
                                        <i className="fa fa-refresh"></i> 
                                        Try again
                                    </Button>
                                </center>
                            )
                        }
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col xs="12" md="12">
                        <MapWithAMarker
                            // selectedMarker={this.state.selectedMarker}
                            // markers={this.props.fleetPath}
                            onClick={this.handleClick}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `700px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            kmlLink={this.props.statKML ? this.props.kml : null}
                            woMarker={this.state.woOptimized}
                            mapOptimized={this.state.mapOptimized}
                            // doDetails={this.props.doDetailsData}
                            companyLat={this.props.companyLat}
                            companyLng={this.props.companyLng}
                            // waypoints={waypoints}
                        />
                    </Col>
                </FormGroup>

                  
                {/* {this.state.isLoading ? <center><BarLoader color={'#123abc'} 
                  loading={this.state.isLoading} 
                /> <br /> Loading.... Please wait...</center> : <div><Button onClick={() => self.handleFetchSave(id_do)} size="sm" color="success" disabled={this.state.isLoading || this.props.loading_state}><i className="fa fa-save"></i> Submit</Button>
                <Button onClick={() => this.toggleCloseAdd()} size="sm" color="warning" disabled={this.state.isLoading || this.props.loading_state}><i className="fa fa-window-close"></i> Cancel</Button></div>}          */}
                <Button onClick={() => this.toggleCloseAdd()} size="md" color="warning" disabled={this.state.isLoading || this.props.loading_state}><i className="fa fa-window-close"></i> Close</Button>
            </div>
        )
    }

    render() {
        const { totalWO, optimize } = this.props;
        const { isLoading, success } = this.state;
        return (
            <div>
                {
                    totalWO > 0 ? 
                    // 
                    (
                        optimize ? 
                        <Button className="btn btn-lg" color="warning" onClick={() => this.toggleOpenAdd()}><i className="fa fa-superpowers"></i>&nbsp;Check Route Optimization</Button>
                        :
                        <Button className="btn btn-lg" color="danger" onClick={() => this.toggleOpenAdd()}><i className="fa fa-superpowers"></i>&nbsp;Check Route</Button>
                    )
                    
                    :
                    true
                }

                <Modal isOpen={this.state.modalAdd} toggle={this.toggleOpenAdd} className={'modal-lg'} backdrop="static">
                    <ModalHeader toggle={this.toggleCloseAdd}>{optimize ? 'Preview Route Optimization' : 'Preview Route'}</ModalHeader>
                    <ModalBody>
                        {
                            isLoading ? 
                            <center><BarLoader color={'#123abc'} loading={isLoading}/> <br /> Loading.... Please wait...</center>
                            : 
                            (
                                success ? 
                                this.viewContent()
                                :
                                <center>Error fetching your data... <br/><br/> 
                                    <Button className="btn btn-primary" color="primary" size="md" disabled={isLoading} onClick={() => this.handleFetch(this.props.id_do)}>
                                        <i className="fa fa-refresh"></i> 
                                        Try again
                                    </Button>
                                </center>
                            )
                        }  
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    // KML and Company State
    kml: state.companyStore.companyKML,
    companyLat: state.companyStore.lat,
    companyLng: state.companyStore.lng,
    statKML: state.settingStore.kmlSet,

    // Saved Place State
    selectApprovedPlace: state.savedPlacesTableStore.selectApprovedPlace,
    inProgress: state.savedPlacesTableStore.inProgress,
    successSavedPlaceFetch: state.savedPlacesTableStore.success,

    // Work Order Related
    // inProgressWorkOrder: state.workOrderStore.inProgress,
    // successWorkOrder: state.workOrderStore.success,
    // workOrderData: state.workOrderStore.workOrderData,

})

WorkOrdersPreviewOptimize.propTypes = propTypes;
WorkOrdersPreviewOptimize.defaultProps = defaultProps;

export default connect(mapStateToProps, {fetchWorkOrdersTable})(WorkOrdersPreviewOptimize);
import React, { Component } from "react";
import { Button, FormGroup, Label, Input, Col, FormFeedback } from "reactstrap";
import axios from "axios";
import { RESEND_VERIFY_EMAIL_URL } from "../../../redux/config/links";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import handleErrorResponseCode from "../../../helpers/error-response-code-handler";
class ResendVerificationForm extends Component {
  validation = () => {
    let yup = Yup.object().shape({
      email: Yup.string()
        .email()
        .oneOf(
          [this.props.emailToConfirm, null],
          "Email must match with selected"
        )
        .required("cannot empty!"),
    });
    return yup;
  };

  form = () => {
    const { refreshTable, closeVerif } = this.props;
    return (
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          let loginCreds = JSON.parse(window.atob(localStorage.Automalt));
          let auth = loginCreds.token;
          setSubmitting(true);
          axios({
            method: "post",
            url: RESEND_VERIFY_EMAIL_URL,
            headers: {
              Authorization: auth,
            },
            data: values,
          })
            .then((response) => {
              if (response.status === 200) {
                setSubmitting(false);
                toast.success(
                  `Email verification successfully resend to ${values.email}`
                );
                closeVerif();
                refreshTable();
              }
            })
            .catch(function (error) {
              setSubmitting(false);
              toast.error(handleErrorResponseCode(error));
            });
          setSubmitting(false);
        }}
        validationSchema={this.validation()}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          handleBlur,
          handleChange,
          handleReset,
          isValid,
          isSubmitting,
        }) => (
          <form>
            <FormGroup row>
              <Col md="4">
                <Label htmlFor="text-input">Re-Type Email Address</Label>
              </Col>
              <Col xs="12" md="12">
                <Input
                  type="input"
                  name="email"
                  id="email"
                  placeholder=""
                  autoComplete="email"
                  valid={!errors.email}
                  invalid={touched.email && !!errors.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  disabled={isSubmitting}
                />
                <FormFeedback>{errors.email}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup>
              <Button
                type="button"
                onClick={handleSubmit}
                color="primary"
                className="mr-1"
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting ? "Wait..." : "Submit"}
              </Button>
              <Button
                type="reset"
                color="danger"
                className="mr-1"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Reset
              </Button>
            </FormGroup>
          </form>
        )}
      />
    );
  };

  render() {
    return <React.Fragment>{this.form()}</React.Fragment>;
  }
}

export default ResendVerificationForm;

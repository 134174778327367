import React, { Component } from "react";
// import { FormFeedback } from 'reactstrap'
import Select from "react-select";
import 'react-select/dist/react-select.css';

class SelectField extends Component {
  handleChange = value => {
    const { onChange, name } = this.props;

    onChange(name, value);
  };

  handleBlur = () => {
    const { onBlur, name } = this.props;

    onBlur(name, true);
  };

  // componentDidCatch(error) {  
  //      console.log(error);  
  // }

  render() {
    const {
      id,
      // name,
      // label,
      searchable,
      placeholder,
      options,
      value,
      isMulti,
      isDisabled,
      disabled,
      touched,
      error,
      isClearable,
      clearable,
      backspaceRemovesValue,
      arrowRenderer,
      openOnFocus,
      openOnClick,
      onInputChange,
      onOpen,
      onClose
    } = this.props;

    return (
      <div>
        <Select
          id={id}
          placeholder={placeholder}
          options={options}
          value={value}
          searchable={searchable}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          touched={touched}
          error={error}
          isMulti={isMulti}
          isDisabled={isDisabled}
          isClearable={isClearable}
          clearable = {clearable}
          backspaceRemovesValue={backspaceRemovesValue}
          disabled={disabled}
          components={{ ClearIndicator: null }}
          arrowRenderer={arrowRenderer}
          openOnFocus={openOnFocus}
          openOnClick={openOnClick}
          onInputChange={onInputChange}
          onOpen={onOpen}
          onClose={onClose}
        />
        
        {/* {touched && error ? <p className="invalid-feedback" style={{'display': 'block' }}>{error}</p> : null} */}
      </div>
    );
  }
}

export { SelectField };

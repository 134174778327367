import axios from 'axios';
import * as API_LINKS from '../config/links.jsx'
import { toast } from 'react-toastify';

import Cookies from 'js-cookie';


export const FETCH_LOGIN = 'FETCH_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const FAILED_LOGIN = 'FAILED_LOGIN'
export const FETCH_LOGOUT = 'FETCH_LOGOUT'
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT'
export const FETCH_CHECK_AUTH = 'FETCH_CHECK_AUTH'
export const RECEIVE_CHECK_AUTH = 'RECEIVE_CHECK_AUTH'

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !getAccessToken();

export function fetchLogin(userEmail, userPassword) {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_LOGIN
        })
        axios({
            method: 'post',
            url: API_LINKS.LOGIN_URL, //For API
            data: {
                email: userEmail,
                password: userPassword
            }
        })
            .then((response) => {
                // console.log(response)
                if (response.status === 200) {
                    if (response.data.status === "Auth failed" || response.data.status === "Password Invalid!!") {
                        dispatch({
                            type: FAILED_LOGIN
                        })
                        return toast.error("Invalid username or password! Please try again!");
                    }
                    else if (response.data.status === "success") {
                        dispatch({
                            type: RECEIVE_LOGIN,
                            payload: response.data
                        })
                        Cookies.set('userSession', response.data.result.name);
                        Cookies.set('Automalt', window.btoa(JSON.stringify(response.data.result)));
                        
                        localStorage.userSession = response.data.result.name
                        localStorage.Automalt = window.btoa(JSON.stringify(response.data.result));
                        localStorage.AutomaLvl = response.data.result.id_roles;
                        localStorage.AutomaCmp = response.data.result.company;
                        localStorage.AutomaEml = response.data.result.email;
                        localStorage.AutomaSbs = response.data.result.subs.plan_name;
                        sessionStorage.rolesName = response.data.result.roles;
                    }
                } else {
                    dispatch({
                        type: FAILED_LOGIN
                    })
                    return toast.error("Invalid username or password! Please try again!");
                }
            })
            .catch(function (error) {
                console.log(error)
                if (error.response) {
                    if(error.response.status === 401) {
                        dispatch({
                            type: FAILED_LOGIN
                        })
                        return toast.error("Invalid username or password! Please try again!");
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        dispatch({
                            type: FAILED_LOGIN
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else if (error.response.status === 400) {
                        dispatch({
                            type: FAILED_LOGIN
                        })
                        return toast.error(error.response.data.message);
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                    dispatch({
                        type: FAILED_LOGIN
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    // Something happened in setting up the request that triggered an Error
                    dispatch({
                        type: FAILED_LOGIN
                    })
                    return toast.error('Something went wrong... Please try again later...')
                    // return toast.error(error.message);
                }
                dispatch({
                    type: FAILED_LOGIN
                })
                // console.log(error.config);
            })
    }
}

export function checkAuth() {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_CHECK_AUTH
        })
        dispatch({
            type: RECEIVE_CHECK_AUTH
        })
    }
}

export function clearAuth() {
    localStorage.clear();
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_LOGOUT,
            // type: 'RESET'
        })
        window.location.reload()
        dispatch({
            type: RECEIVE_LOGOUT
        })
    }
}
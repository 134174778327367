import React, { Component } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types'

const propTypes = {
    toll_fee: PropTypes.number,
    weight_fee: PropTypes.number,
    other_fee: PropTypes.number,
    delivery_cost_method: PropTypes.string,
    total_delivery_fee: PropTypes.number,
    fuel_fee: PropTypes.number,
    total_est_delivery_cost: PropTypes.number,
    total_est_carbon: PropTypes.number,
}

const defaultProps = {
    tolls_fee: 0,
    weight_fee: 0,
    other_fee: 0,
    delivery_cost_method: 'TKM (Tonnage Kilometre) Method',
    total_delivery_fee: 0,
    fuel_fee: 0,
    total_est_delivery_cost: 0,
    total_est_carbon: 0,
}

class DeliveryCostEstimation extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }   

    curStringFormat = (_digit) => {
        return ('Rp. ' + parseFloat(_digit).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
    }

    render() {
        const { toll_fee, weight_fee, other_fee, delivery_cost_method, total_est_delivery_cost, total_est_carbon } = this.props;
        return (
            <div>
                <Card>
                    <CardBody>
                        <div className="h5 mb-0">Delivery Cost Estimation</div>
                        <div className="text-muted text-uppercase font-xs">Automa could help you define the delivery estimation cost</div>
                        <br/>
                        <Row>
                            {/* Left Column */}
                            <Col md="6">
                                <dt>Delivery Cost Calculation Method</dt>
                                <dd>{delivery_cost_method ? delivery_cost_method : '-'}</dd>
                                <br/>
                                <dt>Weighting Fee</dt>
                                <dd>{weight_fee ? this.curStringFormat(weight_fee) : '-'}</dd>
                            </Col>

                            {/* Right Column */}
                            <Col md="6">
                                <dt>Tolls Fee</dt>
                                <dd>{toll_fee ? this.curStringFormat(toll_fee) : '-'}</dd>
                                <br/>
                                <dt>Others Fee</dt>
                                <dd>{other_fee ? this.curStringFormat(other_fee) : '-'}</dd>
                            </Col>
                        </Row>
                        <hr style={{'height': '2.5px', 'border': 'none', 'backgroundColor': 'black'}}/>
                        <Row>
                            {/* Left Column */}
                            <Col md="6">
                                <dt>Total Est. Delivery Cost</dt>
                                <dd>{total_est_delivery_cost ? this.curStringFormat(total_est_delivery_cost) : '-'}</dd>
                            </Col>

                            <Col md="6">
                                <dt>Total Est. Carbon</dt>
                                <dd>{total_est_carbon ? total_est_carbon.toLocaleString() + ' Kg CO2' : '-'}</dd>
                            </Col>

                            {/* Right Column */}
                            {/* <Col md="6">
                                <dt>Fuel Fee</dt>
                                <dd>{fuel_fee ? this.curStringFormat(fuel_fee) : '-'}</dd>
                            </Col> */}
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

DeliveryCostEstimation.propTypes = propTypes;
DeliveryCostEstimation.defaultProps = defaultProps;

export default DeliveryCostEstimation;
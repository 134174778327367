import React from "react";
import { Link } from "react-router-dom";
import StoringTicket from "./StoringTicket/StoringTicket";


export const incidentRoute = () => {
    const path = "/incident";
    return [
        {
          name: "Incident",
          path: `${path}`,
          exact: true,
          render: () => <>
            <ul>
                <li><Link to={`${path}/storing-ticket`}>Storing Ticket</Link></li>
            </ul>
          </>,
        },
        {
          name: "Storing Ticket",
          path: `${path}/storing-ticket`,
          exact: true,
          render: (props) => <StoringTicket {...props} />,
        },
    ];
  };

export const allIncidentStoringRoutes = () =>{
    return [...incidentRoute(), ]
}
import React from "react";
// import DashboardDetails from '../views/Dashboard/DashboardDetails/DashboardDetails.jsx';
import WelcomeMessage from "../containers/DefaultLayout/WelcomeMessage";

//Enviroma files import
import EnviromaDevices from "../views/LandLord/Devices/Devices.jsx";
import EnviromaTemperature from "../views/LandLord/Temperature/Temperature.jsx";

//User Management Files Import
import Login from "../views/Users/Login/Login";
import Logout from "../views/Users/Logout/Logout";
import Register from "../views/Users/Register/Register";
import UserManagement from "../views/Users/Management/Management";

// Delivery Management Files Import
import DeliveryOrders from "../views/Fleet/DeliveryOrders/DeliveryOrders";
import WorkOrders from "../views/Fleet/WorkOrders/WorkOrdersNew";
import ReportDeliveryOrders from "../views/Fleet/Report-DeliveryOrders/Report-DeliveryOrders";
import { activeFeature } from "../constants/constants";

const Wallet = React.lazy(() => import("../views/Wallet/Wallet"));

//Landlord
const LandLordDashboard = React.lazy(() =>
  import("../views/LandLord/Dashboard/Dashboard.jsx")
);
const LandLordLocationType = React.lazy(() =>
  import("../views/LandLord/LocationType/LLLocationType.jsx")
);
const LandLordLocation = React.lazy(() =>
  import("../views/LandLord/Location/LLLocation.jsx")
);
const LandLordSensors = React.lazy(() =>
  import("../views/LandLord/Sensors/LLSensors.jsx")
);
const LandLordSensorInstallation = React.lazy(() =>
  import("../views/LandLord/SensorInstallation/LLSensorInstallation.jsx")
);
const LandLordUtility = React.lazy(() =>
  import("../views/LandLord/Utility/Utility.jsx")
);

//Enermo
const EnermoDashboard = React.lazy(() => import("../views/CarbonX/Dashboard"));
const EnermoSettings = React.lazy(() =>
  import("../views/Enermo/Settings/Enermo-Setting")
);

//Fleet files import
// const VehicleMonitoring = React.lazy(() => import('../views/Fleet/VehicleMonitoring/VehicleMonitoring.jsx'));
const MonitorDetails = React.lazy(() =>
  import("../views/Fleet/MonitorDetails/MonitorDetails.jsx")
);
const Drivers = React.lazy(() => import("../views/Fleet/Drivers/Drivers.jsx"));
const DriversAssistant = React.lazy(() =>
  import("../views/Fleet/DriverAssistants/DriverAssistants.jsx")
);
// const Fleets = React.lazy(() => import('../views/Fleet/Fleets/Fleets.jsx'));
const Fleets = React.lazy(() => import("../views/Fleet/Fleets/FleetsTab"));
const FleetsObdHistory = React.lazy(() =>
  import("../views/Fleet/Fleets/FleetObdHistory.jsx")
);
const FleetOperations = React.lazy(() =>
  import("../views/Fleet/FleetOperations/FleetOperations.jsx")
);
const SavedPlaces = React.lazy(() =>
  import("../views/Fleet/SavedPlaces/SavedPlaces.jsx")
);
const PublicFacility = React.lazy(() =>
  import("../views/Fleet/PublicFacility/Tabs")
);
const MainCustomer = React.lazy(() =>
  import("../views/Fleet/MainCustomer/MainCustomer.jsx")
);
const LiveMonitoring = React.lazy(() =>
  import("../views/Fleet/LiveMonitoring/LiveMonitoringTabs")
);
const LiveMonitoringCommandCenter = React.lazy(() =>
  import(
    "../views/Fleet/LiveMonitoringCommandCenter/LiveMonitoringCCWrapper"
  )
);
const Security = React.lazy(() =>
  import("../views/Fleet/Security/Security.jsx")
);
const FleetsReport = React.lazy(() =>
  import("../views/Fleet/Fleets/Fleets-Report")
);
const FuelReport = React.lazy(() =>
  import("../views/Fleet/Fleets/FuelReport/FleetFuelReport")
);
const DeliveryAudit = React.lazy(() =>
  import("../views/Fleet/DeliveryOrders/DeliveryOrderAudit/DeliveryOrderAudit")
);
const TriPLWorkOrderRequest = React.lazy(() =>
  import("../views/Fleet/3PL/Request/TriPLRequest")
);
const TriPLAdministrative = React.lazy(() =>
  import("../views/Fleet/3PL/Administrative/TriPLAdministrative")
);
const TriPLGrouping = React.lazy(() =>
  import("../views/Fleet/3PL/Grouping/TriPLGroupingTabs")
);
const GroupMembersAssign = React.lazy(() =>
  import("../views/Fleet/3PL/Grouping/GroupMembersAssign/GroupMembersAssign")
);
const WOReview = React.lazy(() =>
  import("../views/Fleet/Report/WOReview/WOReview")
);
const WOPODConfirmReview = React.lazy(() =>
  import("../views/Fleet/Report/WOPODVerif/WOPODVerif")
);
const FleetMaintenance = React.lazy(() =>
  import("../views/Fleet/FleetMaintenance/FleetMaintenanceMain.jsx")
);
const SMMechanicTask = React.lazy(() =>
  import(
    "../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMMechanicTask/SMMechanicTask.jsx"
  )
);
const SMApprovalSetting = React.lazy(() =>
  import(
    "../views/Settings/FleetMaintenanceSettings/ApprovalType/ApprovalTypeSetting"
  )
);
const SMApproval = React.lazy(() =>
  import("../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMApproval")
);
const SMMechanicTaskDetail = React.lazy(() =>
  import(
    "../views/Fleet/FleetMaintenance/ScheduledMaintenance/SMMechanicTask/SMMechanicTaskDetail"
  )
);
const Mechanics = React.lazy(() =>
  import("../views/Fleet/Mechanics/MechanicsMain.jsx")
);
const MechanicsCertification = React.lazy(() =>
  import(
    "../views/Fleet/Mechanics/MechanicsCertification/MechanicsCertification.jsx"
  )
);
const MechanicsSpecialization = React.lazy(() =>
  import(
    "../views/Fleet/Mechanics/MechanicsSpecialization/MechanicsSpecialization.jsx"
  )
);
const MechanicsOperationalHour = React.lazy(() =>
  import(
    "../views/Fleet/Mechanics/MechanicsOperationalHour/MechanicsOperationalHour.jsx"
  )
);
const WSMMechanicTask = React.lazy(() =>
  import(
    "../views/Fleet/FleetMaintenance/ScheduledMaintenanceWorkshop/SMMechanicTask/SMMechanicTask"
  )
);
const WSMMechanicTaskDetail = React.lazy(() =>
  import(
    "../views/Fleet/FleetMaintenance/ScheduledMaintenanceWorkshop/SMMechanicTask/SMMechanicTaskDetail"
  )
);

const Workshop = React.lazy(() =>
  import("../views/Fleet/Workshop/WorkshopMain.jsx")
);
const WorkshopOperationalHour = React.lazy(() =>
  import(
    "../views/Fleet/Workshop/WorkshopOperationalHour/WorkshopOperationalHour.jsx"
  )
);

const CurrativeOrder = React.lazy(() =>
  import("../views/Fleet/FleetMaintenance/CurrativeOrders")
);
const CheckSheet = React.lazy(() => import("../views/Fleet/CheckSheet"));
const CheckSheetDetail = React.lazy(() =>
  import("../views/Fleet/CheckSheet/MainCheckSheet/MainCheckSheetDetail")
);
const CheckSheetApprovalSetting = React.lazy(() =>
  import("../views/Fleet/CheckSheet/ApprovalType/ApprovalTypeSetting")
);
const DoCheckSheetApproval = React.lazy(() =>
  import("../views/Fleet/DeliveryOrders/DOChecksheetApproval")
);
const PackageMaintenanceTask = React.lazy(() =>
  import(
    "../views/Settings/FleetMaintenanceSettings/PackageMaintenance/PackageMaintenanceTask"
  )
);
const PreventiveMaintenanceTask = React.lazy(() =>
  import(
    "../views/Settings/FleetMaintenanceSettings/PreventiveMaintenance/PreventiveMaintenanceTask"
  )
);
const DOCategory = React.lazy(() => import("../views/Fleet/Category/Category"));

const TransporterDivisions = React.lazy(() =>
  import("../views/Fleet/Divisions")
);
// Stocker
const AdministrationOffices = React.lazy(() =>
  import("../views/Stocker/AdministrationOffices/AdministrationOffices")
);
const Batchs = React.lazy(() =>
  import("../views/Stocker/Batch/MasterBatchs/MasterBatchs")
);
const Brands = React.lazy(() => import("../views/Stocker/Brands/Brands"));
const CompanyBankAccounts = React.lazy(() =>
  import("../views/Stocker/CompanyBankAccounts/CompanyBankAccounts")
);
const Divisions = React.lazy(() =>
  import("../views/Stocker/Divisions/Divisions")
);
const Inbounds = React.lazy(() =>
  import("../views/Stocker/Inbounds/MasterInbound/MasterInbound")
);
const InboundDetail = React.lazy(() =>
  import("../views/Stocker/Inbounds/InboundDetails/InboundDetails")
);
const InboundItemDetail = React.lazy(() =>
  import("../views/Stocker/Inbounds/InboundItemDetails/InboundItemDetails")
);
const InboundDriverOfficer = React.lazy(() =>
  import("../views/Stocker/Inbounds/InboundDriverOfficer/InboundDriverOfficer")
);
const InboundOfficerAssistant = React.lazy(() =>
  import(
    "../views/Stocker/Inbounds/InboundOfficerAssistants/InboundOfficerAssistants"
  )
);
const InboundVehicle = React.lazy(() =>
  import("../views/Stocker/Inbounds/InboundVehicles/InboundVehicles")
);
const Outbounds = React.lazy(() =>
  import("../views/Stocker/Outbounds/Outbounds")
);
const OutboundDetail = React.lazy(() =>
  import("../views/Stocker/Outbounds/OutboundDetails/OutboundDetails")
);
const OutboundItemDetail = React.lazy(() =>
  import("../views/Stocker/Outbounds/OutboundItemDetails/OutboundItemDetails")
);
const Items = React.lazy(() => import("../views/Stocker/Items/Items"));
const ItemDetail = React.lazy(() =>
  import("../views/Stocker/Items/MasterItemDetails/MasterItemDetailTab")
);
const ItemSettings = React.lazy(() =>
  import("../views/Stocker/ItemSettings/ItemSettings")
);
const ItemDisposalDetail = React.lazy(() =>
  import(
    "../views/Stocker/DisposalItems/DisposalItemDetails/DisposalItemDetails"
  )
);
const OperatingAreas = React.lazy(() =>
  import("../views/Stocker/OperatingAreas/OperatingAreas")
);
const OperatingAreaGeofence = React.lazy(() =>
  import("../views/Stocker/OperatingAreaGeofences/OperatingAreaGeofences")
);
const PurchaseOrder = React.lazy(() =>
  import("../views/Stocker/PurchaseOrder/PurchaseOrder")
);
const PurchaseOrderApproval = React.lazy(() =>
  import(
    "../views/Stocker/PurchaseOrder/PurchaseOrderApprovals/PurchaseOrderApprovals"
  )
);
const PurchaseOrderPayment = React.lazy(() =>
  import(
    "../views/Stocker/PurchaseOrder/PurchaseOrderPayments/PurchaseOrderPayments"
  )
);
const PurchaseOrderPrintJob = React.lazy(() =>
  import(
    "../views/Stocker/PurchaseOrder/PurchaseOrderPrintJob/PurchaseOrderPrintJob"
  )
);
const PurchaseOrderItemDetail = React.lazy(() =>
  import(
    "../views/Stocker/PurchaseOrder/PurchaseOrderItemDetails/PurchaseOrderItemDetails"
  )
);
const PurchaseOrderItemDetailsIntercompany = React.lazy(() =>
  import(
    "../views/Stocker/PurchaseOrder/PurchaseOrderItemDetailsIntercompany/PurchaseOrderItemDetailsIntercompany"
  )
);
const PaymentTerms = React.lazy(() =>
  import("../views/Stocker/PaymentTerms/PaymentTerms")
);
const Repacakaging = React.lazy(() =>
  import("../views/Stocker/Repackaging/Repacakaging")
);
const RepacakagingItemDetail = React.lazy(() =>
  import("../views/Stocker/Repackaging/RepackagingItem/RepackagingItemDetails")
);
const ReturnOrder = React.lazy(() =>
  import("../views/Stocker/ReturnOrder/ReturnOrder")
);
const SalesOrder = React.lazy(() =>
  import("../views/Stocker/SalesOrder/SalesOrder")
);
const SalesOrderApproval = React.lazy(() =>
  import("../views/Stocker/SalesOrder/SalesOrderApprovals/SalesOrderApprovals")
);
const SalesOrderPayment = React.lazy(() =>
  import("../views/Stocker/SalesOrder/SalesOrderPayments/SalesOrderPayments")
);
const SalesOrderPrintJob = React.lazy(() =>
  import("../views/Stocker/SalesOrder/SalesOrderPrintJob/SalesOrderPrintJob")
);
const SalesOrderItemDetail = React.lazy(() =>
  import(
    "../views/Stocker/SalesOrder/SalesOrderItemDetails/SalesOrderItemDetails"
  )
);
const SalesOrderItemDetailIntercompany = React.lazy(() =>
  import(
    "../views/Stocker/SalesOrder/SalesOrderItemDetailsIntercompany/SalesOrderItemDetails"
  )
);
const StockerPicPositions = React.lazy(() =>
  import("../views/Stocker/PicPositions/PicPositions")
);
const Vendors = React.lazy(() => import("../views/Stocker/Vendors/Vendors"));
const VendorPICPersons = React.lazy(() =>
  import("../views/Stocker/VendorPicPersons/VendorPicPersons")
);
const VendorsBankAccounts = React.lazy(() =>
  import("../views/Stocker/VendorsBankAccounts/VendorsBankAccounts")
);
const Warehouse = React.lazy(() =>
  import("../views/Stocker/Warehouse/Warehouse")
);
const WarehouseArea = React.lazy(() =>
  import("../views/Stocker/Warehouse/WarehouseAreas/WarehouseAreas")
);
const WarehouseAreaGeofence = React.lazy(() =>
  import("../views/Stocker/Warehouse/WarehouseGeofence/WarehouseGeofence")
);
const WarehouseTransfers = React.lazy(() =>
  import("../views/Stocker/WarehouseTransfers/WarehouseTransfers")
);
const WarehouseTransferApprovals = React.lazy(() =>
  import(
    "../views/Stocker/WarehouseTransfers/WarehouseTransferApprovals/WarehouseTransferApprovals"
  )
);
const WarehouseTransferDetails = React.lazy(() =>
  import(
    "../views/Stocker/WarehouseTransfers/MasterWarehouseTransferDetail/MasterWarehouseTransferDetail"
  )
);
const WarehouseTransferItem = React.lazy(() =>
  import(
    "../views/Stocker/WarehouseTransfers/WarehouseTransferItem/WarehouseTransferItem"
  )
);
const IncomingGoods = React.lazy(() =>
  import("../views/Stocker/IncomingGoods")
);
const InvoicePage = React.lazy(() =>
  import("../views/Stocker/InvoicePage/InvoicePage")
);
const InvoiceItemDetail = React.lazy(() =>
  import("../views/Stocker/InvoicePage/InvoiceItemDetails/InvoiceItemDetails")
);
const RackManagement = React.lazy(() =>
  import("../views/Stocker/RackManagement/RackManagement")
);
const Opname = React.lazy(() => import("../views/Stocker/Opname/"));
const OpnameTaskDetail = React.lazy(() =>
  import("../views/Stocker/Opname/TaskDetail")
);
const IOTBindingUserAndDevice = React.lazy(() =>
  import("../views/Stocker/IOTBinding/UserAndDevice/UserAndDevice")
);
const IOTBindingItemBox = React.lazy(() =>
  import("../views/Stocker/IOTBinding/ItemBox/ItemBox")
);
//Point Hub
const Customers = React.lazy(() =>
  import("../views/PointHub/Customers/Customers")
);
const CustomerBankAccounts = React.lazy(() =>
  import("../views/PointHub/CustomerBankAccounts/CustomerBankAccounts")
);
const CustomerApprovals = React.lazy(() =>
  import("../views/PointHub/CustomerApproval/CustomerApproval")
);
const PicPositions = React.lazy(() =>
  import("../views/PointHub/PicPositions/PicPositions")
);
const CustomerPic = React.lazy(() =>
  import("../views/PointHub/CustomersPIC/CustomersPIC")
);
const MaintenanceWorkshop = React.lazy(() =>
  import("../views/PointHub/MaintenanceWorkshop")
);
const Rentee = React.lazy(() =>
  import("../views/PointHub/Rentee/RenteeGroupingTabs")
);
const RenteeMemberAssign = React.lazy(() =>
  import("../views/PointHub/Rentee/GroupMembersAssign/GroupMembersAssign")
);
const TradingIntercompanyVendor = React.lazy(() =>
  import("../views/PointHub/TradingIntercompanyPair/ConnectAsVendor")
);
const TradingIntercompanyCustomer = React.lazy(() =>
  import("../views/PointHub/TradingIntercompanyPair/ConnectAsCustomer")
);
const IntercompanyOrder = React.lazy(() =>
  import("../views/PointHub/IntercompanyOrder/IntercompanyOrder")
);
const IntercompanyOrderCard = React.lazy(() =>
  import(
    "../views/PointHub/IntercompanyOrder/IntercompanyOrderCart/IntercompanyOrderCart"
  )
);
const IntercompanyOrderShipment = React.lazy(() =>
  import(
    "../views/PointHub/IntercompanyOrder/IntercompanyOrderCart/IntercompanyOrderShipment/IntercompanyOrderShipment"
  )
);
const IntercompanyOrderItem = React.lazy(() =>
  import(
    "../views/PointHub/IntercompanyOrder/IntercompanyOrderItem/IntercompanyOrderItem"
  )
);

//Settings
const Settings = React.lazy(() => import("../views/Settings/Settings"));
const Subscriptions = React.lazy(() =>
  import("../views/Subscriptions/Subscriptions")
);
const Dashboard = React.lazy(() => import("../views/Dashboard/Dashboard"));
const CovidMain = React.lazy(() => import("../views/Fleet/Covid/CovidMain"));
const DriverAppUsers = React.lazy(() =>
  import("../views/Fleet/DriverAppUsers/DriverAppUsers.jsx")
);
const MaintenanceSettings = React.lazy(() =>
  import("../views/Settings/FleetMaintenanceSettings")
);

const GeocodeConverter = React.lazy(() =>
  import("../views/Tools/GeocodeConverter/GeocodeConverter")
);

const MyPertaminaConverter = React.lazy(() =>
  import("../views/Tools/MyPertaminaConverter/MyPertaminaConverter")
);

//Carbon X
const SupplyChainEmissionDashboard = React.lazy(() =>
  import("../views/CarbonX/Dashboard/SupplyChainEmission/")
);

const CompanyEmissionDashboard = React.lazy(() =>
  import("../views/CarbonX/Dashboard/CompanyEmission/")
);

const CompanySetting = React.lazy(() =>
  import("../views/CarbonX/CompanyDisclosure/CompanySetting/CompanySetting")
);

const Checksheets = React.lazy(() =>
  import(
    "../views/CarbonX/CompanyDisclosure/ListDisclosure/Checksheets/Checksheets"
  )
);
const Asset = React.lazy(() =>
  import("../views/CarbonX/CompanyDisclosure/ListDisclosure/Asset/AssetTab")
);
const HR = React.lazy(() =>
  import("../views/CarbonX/CompanyDisclosure/ListDisclosure/HR/HumanResources")
);
const Bills = React.lazy(() =>
  import("../views/CarbonX/CompanyDisclosure/ListDisclosure/Bills/BillsTab")
);
const PurchasedGoodsAndServices = React.lazy(() =>
  import(
    "../views/CarbonX/CompanyDisclosure/ListDisclosure/PurchasedGoodsAndServices/PurchasedGoodsAndServices"
  )
);
const ThirdPartyLogistics = React.lazy(() =>
  import(
    "../views/CarbonX/CompanyDisclosure/ListDisclosure/ThirdPartyLogistics/ThirdPartyLogisticsTab"
  )
);

const OperationDisclosure = React.lazy(() =>
  import(
    "../views/CarbonX/CompanyDisclosure/OperationDisclosure/OperationDisclosure"
  )
);
const AccountingDisclosure = React.lazy(() =>
  import(
    "../views/CarbonX/CompanyDisclosure/AccountingDisclosure/AccountingDisclosure"
  )
);

const AETDashboard = React.lazy(() =>
  import("../views/CarbonX/AETDashboard/AETDashboard")
);
const AETScope1 = React.lazy(() =>
  import("../views/CarbonX/AET/Scope1/Scope1Tab")
);
// const AETScope2 = React.lazy(() =>
//   import("./views/Enermo/Dashboard/index-new")
// );
const AETScope2 = React.lazy(() =>
  import("../views/CarbonX/AET/Scope2/Scope2Tab")
);
const AETScope3 = React.lazy(() =>
  import("../views/CarbonX/AET/Scope3/Scope3Tab")
);
const CarbonInvoice = React.lazy(() =>
  import("../views/CarbonX/CarbonInvoice/")
);

const EmissionFactors = React.lazy(() =>
  import("../views/CarbonX/EmissionFactors/EmissionFactors")
);

const MyData = React.lazy(() =>
  import("../views/CarbonX/EmissionDatabase/MyData/MyData")
);
const DataPool = React.lazy(() =>
  import("../views/CarbonX/EmissionDatabase/DataPool/DataPool")
);

const DecarbonizationPlan = React.lazy(() =>
  import("../views/CarbonX/DecarbonizationPlan/DecarbonizationPlan")
);

const OffsetStewardship = React.lazy(() =>
  import("../views/CarbonX/OffsetStewardship/OffsetStewardship")
);

const ReportDisclosure = React.lazy(() =>
  import("../views/CarbonX/ReportDisclosure/ReportDisclosure")
);

// Carbon X Lama
const LoggerScope1 = React.lazy(() =>
  import("../views/CarbonX/Logger/Scope1/Scope1Tab")
);
const LoggerScope2 = React.lazy(() =>
  import("../views/Enermo/Dashboard/index-new")
);
const LoggerScope3 = React.lazy(() =>
  import("../views/CarbonX/Logger/Scope3/Scope3Tab")
);

// const DashboardDetails = React.lazy(() => import('../views/DashboardDetails/DashboardDetails.jsx'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/welcome",
    name: "Welcome Message",
    component: WelcomeMessage,
    exact: true,
  },
  // { path: '/dashboard/details', name: 'Details', component: DashboardDetails },

  //Landlord
  {
    path: "/landlord/dashboard",
    name: "Land Lord Monitoring",
    component: LandLordDashboard,
  },
  {
    path: "/landlord/devices",
    name: "Land Lord Devices",
    component: EnviromaDevices,
  },
  {
    path: "/landlord/temperature",
    name: "Enviroma Temperature",
    component: EnviromaTemperature,
  },
  {
    path: "/landlord/locationtype",
    name: "Land Lord Location Type",
    component: LandLordLocationType,
  },
  {
    path: "/landlord/locations",
    name: "Land Lord Locations",
    component: LandLordLocation,
  },
  {
    path: "/landlord/sensors",
    name: "Land Lord Sensors",
    component: LandLordSensors,
    exact: true,
  },
  {
    path: "/landlord/sensors/installation/:id",
    name: "Land Lord Sensors Installation",
    component: LandLordSensorInstallation,
    exact: true,
  },
  {
    path: "/landlord/utilities",
    name: "Land Lord Utilities",
    component: LandLordUtility,
    exact: true,
  },

  //Enermo
  {
    path: "/enermo/dashboard",
    name: "CarbonX Dashboard",
    component: EnermoDashboard,
  },
  {
    path: "/enermo/settings",
    name: "CarbonX Settings",
    component: EnermoSettings,
  },

  //Fleet
  {
    path: "/transporter/dashboard",
    name: "Dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/transporter/live-monitoring",
    name: "Live Monitoring",
    component: LiveMonitoring,
    exact: true,
  },
  ...activeFeature.LIVE_MONITORING_V2 ? [{
    path: "/transporter/command-center",
    name: "Live Monitoring Command Center",
    component: LiveMonitoringCommandCenter,
    exact: true,
  }]:[],
  // { path: '/transporter/monitoring', name: 'Fleet Monitoring', component: VehicleMonitoring, exact: true },
  {
    path: "/transporter/monitoring/details",
    name: "Monitor Details",
    component: MonitorDetails,
  },
  {
    path: "/transporter/fleets",
    name: "Fleets",
    component: Fleets,
    exact: true,
  },
  {
    path: "/transporter/fleet-operations",
    name: "Fleet Operations",
    component: FleetOperations,
    exact: true,
  },
  {
    path: "/transporter/fleets/report/:id",
    name: "Fleet Report",
    component: FleetsReport,
  },
  {
    path: "/transporter/fleets/fuel/report/:id",
    name: "Fuel Report",
    component: FuelReport,
  },
  {
    path: "/transporter/fleets/obd-history/:id",
    name: "Fleet OBD History",
    component: FleetsObdHistory,
  },
  { path: "/transporter/drivers", name: "Drivers", component: Drivers },
  {
    path: "/transporter/drivers-assistant",
    name: "Drivers Assistant",
    component: DriversAssistant,
  },
  {
    path: "/transporter/delivery/audit/:id",
    name: "Delivery Order Audit",
    component: DeliveryAudit,
    exact: true,
  },
  {
    path: "/transporter/delivery-order",
    name: "Delivery Order",
    component: DeliveryOrders,
  },
  {
    path: "/transporter/main-customers",
    name: "Main Customers",
    component: MainCustomer,
  },
  {
    path: "/transporter/saved-places",
    name: "Saved Places",
    component: SavedPlaces,
  },
  {
    path: "/transporter/public-facility",
    name: "Public Facilty",
    component: PublicFacility,
  },
  { path: "/transporter/security", name: "Security", component: Security },
  {
    path: "/transporter/work-order/:id",
    name: "Work Order",
    component: WorkOrders,
    exact: true,
  },
  {
    path: "/transporter/wo-review/:month/:id",
    name: "Work Order Review",
    component: WOReview,
    exact: true,
  },
  {
    path: "/transporter/wo-pod-verif-review/:id",
    name: "Work Order POD Verification Review",
    component: WOPODConfirmReview,
    exact: true,
  },
  {
    path: "/transporter/report/delivery/:id",
    name: "Delivery Order Report",
    component: ReportDeliveryOrders,
    exact: true,
  },
  {
    path: "/transporter/3pl/request",
    name: "3PL Work Order Request",
    component: TriPLWorkOrderRequest,
    exact: true,
  },
  {
    path: "/transporter/3pl/grouping",
    name: "3PL Work Order Grouping",
    component: TriPLGrouping,
    exact: true,
  },
  {
    path: "/transporter/3pl/group-members-assign/:id",
    name: "3PL Work Order Grouping Member Assign",
    component: GroupMembersAssign,
    exact: true,
  },
  {
    path: "/transporter/3pl/administrative",
    name: "3PL Administrative",
    component: TriPLAdministrative,
    exact: true,
  },
  {
    path: "/transporter/covid-related/:id",
    name: "Covid Related",
    component: CovidMain,
    exact: true,
  },
  {
    path: "/transporter/driver-app-user/:id",
    name: "Driver App Users",
    component: DriverAppUsers,
    exact: true,
  },
  {
    path: "/transporter/mechanics",
    name: "Mechanics",
    component: Mechanics,
    exact: true,
  },
  {
    path: "/transporter/mechanics/certification/:id",
    name: "Mechanics Certification",
    component: MechanicsCertification,
    exact: true,
  },
  {
    path: "/transporter/mechanics/specialization/:id",
    name: "Mechanics Specialization",
    component: MechanicsSpecialization,
    exact: true,
  },
  {
    path: "/transporter/mechanics/operational-hour/:id",
    name: "Mechanics Operational Hour",
    component: MechanicsOperationalHour,
    exact: true,
  },
  {
    path: "/transporter/workshop",
    name: "Workshop",
    component: Workshop,
    exact: true,
  },
  {
    path: "/transporter/workshop/operational-hour/:id",
    name: "Workshop Operational Hour",
    component: WorkshopOperationalHour,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance",
    name: "Fleet Maintenance",
    component: FleetMaintenance,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance/mechanic-task/:id",
    name: "Scheduled Maintenance Mechanic Task",
    component: SMMechanicTask,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance/mechanic-task-detail/:id",
    name: "Scheduled Maintenance Mechanic Task Detail",
    component: SMMechanicTaskDetail,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance/workshop-mechanic-task/:id",
    name: "Scheduled Maintenance Mechanic Task",
    component: WSMMechanicTask,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance/workshop-mechanic-task-detail/:id",
    name: "Scheduled Maintenance Mechanic Task Detail",
    component: WSMMechanicTaskDetail,
    exact: true,
  },
  {
    path: "/transporter/currative-order",
    name: "Currative Order",
    component: CurrativeOrder,
    exact: true,
  },
  {
    path: "/transporter/fleet-maintenance-settings",
    name: "Fleet Maintenance Settings",
    component: MaintenanceSettings,
    exact: true,
  },
  {
    path: "/transporter/check-sheet",
    name: "Check Sheet",
    component: CheckSheet,
    exact: true,
  },
  {
    path: "/transporter/check-sheet/details/:id",
    name: "Check Sheet Detail",
    component: CheckSheetDetail,
    exact: true,
  },
  {
    path: "/transporter/check-sheet/approval-setting/:id",
    name: "Check Sheet Approval Setting",
    component: CheckSheetApprovalSetting,
    exact: true,
  },
  {
    path: "/transporter/do-check-sheet/approval/:id",
    name: "Do Check Sheet Approval",
    component: DoCheckSheetApproval,
    exact: true,
  },
  {
    path: "/transporter/divisions",
    name: "Divisions",
    component: TransporterDivisions,
    exact: true,
  },
  {
    path: "/transporter/package-maintenance-task/:id",
    name: "Package Maintenance",
    component: PackageMaintenanceTask,
    exact: true,
  },
  {
    path: "/transporter/preventive-maintenance-task/:id",
    name: "Preventive Maintenance",
    component: PreventiveMaintenanceTask,
    exact: true,
  },

  {
    path: "/transporter/scheduled-maintenance/approval-setting/:id",
    name: "Scheduled Maintenance Approval Setting",
    component: SMApprovalSetting,
    exact: true,
  },
  {
    path: "/transporter/scheduled-maintenance/approval/:id",
    name: "Scheduled Maintenance Approval",
    component: SMApproval,
    exact: true,
  },

  {
    path: "/transporter/delivery/category",
    name: "Category",
    component: DOCategory,
    exact: true,
  },

  // Stocker
  {
    path: "/stocker/administrative-offices",
    name: "Stocker Administration Offices",
    component: AdministrationOffices,
    exact: true,
  },
  {
    path: "/stocker/batchs",
    name: "Stocker Batchs",
    component: Batchs,
    exact: true,
  },
  {
    path: "/stocker/brands",
    name: "Stocker Brands",
    component: Brands,
    exact: true,
  },
  {
    path: "/stocker/company-bank-accounts",
    name: "Stocker Company Bank Accounts",
    component: CompanyBankAccounts,
    exact: true,
  },
  {
    path: "/stocker/divisions",
    name: "Stocker Divisions",
    component: Divisions,
    exact: true,
  },
  {
    path: "/stocker/operating-areas",
    name: "Stocker Operating Areas",
    component: OperatingAreas,
    exact: true,
  },
  {
    path: "/stocker/operating-area-geofence/:id",
    name: "Stocker Operating Area Geofence",
    component: OperatingAreaGeofence,
    exact: true,
  },
  {
    path: "/stocker/inbounds",
    name: "Stocker Inbounds",
    component: Inbounds,
    exact: true,
  },
  {
    path: "/stocker/inbound-detail/:id",
    name: "Stocker Inbound Detail",
    component: InboundDetail,
    exact: true,
  },
  {
    path: "/stocker/inbound-item-detail/:id",
    name: "Stocker Inbound Item Detail",
    component: InboundItemDetail,
    exact: true,
  },
  {
    path: "/stocker/inbound-driver-officer/:id",
    name: "Stocker Driver Officer",
    component: InboundDriverOfficer,
    exact: true,
  },
  {
    path: "/stocker/inbound-officer-assistant/:id",
    name: "Stocker Officer Assistant",
    component: InboundOfficerAssistant,
    exact: true,
  },
  {
    path: "/stocker/inbound-vehicle/:id",
    name: "Stocker Inbound Vehicle",
    component: InboundVehicle,
    exact: true,
  },
  {
    path: "/stocker/outbounds",
    name: "Stocker Outbounds",
    component: Outbounds,
    exact: true,
  },
  {
    path: "/stocker/outbound-detail/:id",
    name: "Stocker Outbound Detail",
    component: OutboundDetail,
    exact: true,
  },
  {
    path: "/stocker/outbound-item-detail/:id",
    name: "Stocker Outbound Item Detail",
    component: OutboundItemDetail,
    exact: true,
  },
  {
    path: "/stocker/payment-term",
    name: "Stocker Payment Terms",
    component: PaymentTerms,
    exact: true,
  },
  {
    path: "/stocker/purchase-order",
    name: "Stocker Purchase Order",
    component: PurchaseOrder,
    exact: true,
  },
  {
    path: "/stocker/purchase-order-approval/:id",
    name: "Stocker Purchase Order",
    component: PurchaseOrderApproval,
    exact: true,
  },
  {
    path: "/stocker/purchase-order-payment/:id",
    name: "Stocker Purchase Order",
    component: PurchaseOrderPayment,
    exact: true,
  },
  {
    path: "/stocker/purchase-order-print-job/:id",
    name: "Stocker Purchase Order",
    component: PurchaseOrderPrintJob,
    exact: true,
  },
  {
    path: "/stocker/sales-order",
    name: "Stocker Sales Order",
    component: SalesOrder,
    exact: true,
  },
  {
    path: "/stocker/sales-order-approval/:id",
    name: "Stocker Sales Order",
    component: SalesOrderApproval,
    exact: true,
  },
  {
    path: "/stocker/sales-order-payment/:id",
    name: "Stocker Sales Order",
    component: SalesOrderPayment,
    exact: true,
  },
  {
    path: "/stocker/sales-order-print-job/:id",
    name: "Stocker Sales Order",
    component: SalesOrderPrintJob,
    exact: true,
  },
  {
    path: "/stocker/items",
    name: "Stocker Items",
    component: Items,
    exact: true,
  },
  {
    path: "/stocker/item-detail/:id",
    name: "Stocker Item Detail",
    component: ItemDetail,
    exact: true,
  },
  {
    path: "/stocker/item-settings",
    name: "Stocker Item Settings",
    component: ItemSettings,
    exact: true,
  },
  {
    path: "/stocker/item-disposal-detail/:id",
    name: "Stocker Disposal Item Detail",
    component: ItemDisposalDetail,
    exact: true,
  },
  {
    path: "/stocker/pic-positions",
    name: "Stocker PIC Positions",
    component: StockerPicPositions,
    exact: true,
  },
  {
    path: "/stocker/repacakaging",
    name: "Stocker Repackaging",
    component: Repacakaging,
    exact: true,
  },
  {
    path: "/stocker/repackaging-item-detail/:id",
    name: "Stocker Repackaging Item Detail",
    component: RepacakagingItemDetail,
    exact: true,
  },
  {
    path: "/stocker/return-order",
    name: "Stocker Return Order",
    component: ReturnOrder,
    exact: true,
  },
  {
    path: "/stocker/vendors",
    name: "Stocker Vendors",
    component: Vendors,
    exact: true,
  },
  {
    path: "/stocker/vendors-bank-accounts/:id",
    name: "Stocker Vendors Bank Accounts",
    component: VendorsBankAccounts,
    exact: true,
  },
  {
    path: "/stocker/vendor-pic-persons/:id",
    name: "Stocker Vendors PIC Persons",
    component: VendorPICPersons,
    exact: true,
  },
  {
    path: "/stocker/warehouse",
    name: "Stocker Warehouse",
    component: Warehouse,
    exact: true,
  },
  {
    path: "/stocker/warehouse-areas/:id",
    name: "Stocker Warehouse Areas",
    component: WarehouseArea,
    exact: true,
  },
  {
    path: "/stocker/warehouse-areas-geofence/:id",
    name: "Stocker Warehouse Areas Geofence",
    component: WarehouseAreaGeofence,
    exact: true,
  },
  {
    path: "/stocker/warehouse-transfer",
    name: "Stocker Warehouse Transfers",
    component: WarehouseTransfers,
    exact: true,
  },
  {
    path: "/stocker/warehouse-transfer-approval/:id",
    name: "Stocker Warehouse Transfer Approval",
    component: WarehouseTransferApprovals,
    exact: true,
  },
  {
    path: "/stocker/warehouse-transfer-detail/:id",
    name: "Stocker Warehouse Transfer Detail",
    component: WarehouseTransferDetails,
    exact: true,
  },
  {
    path: "/stocker/warehouse-transfer-item/:id",
    name: "Stocker Warehouse Item Detail",
    component: WarehouseTransferItem,
    exact: true,
  },
  {
    path: "/stocker/incoming-goods",
    name: "Stocker Incoming Goods",
    component: IncomingGoods,
    exact: true,
  },
  {
    path: "/stocker/invoice-page",
    name: "Stocker Invoice Page",
    component: InvoicePage,
    exact: true,
  },
  {
    path: "/stocker/invoice-page/item-detail",
    name: "Stocker Invoice Item Detail",
    component: InvoiceItemDetail,
    exact: true,
  },

  {
    path: "/stocker/sales-order-item-detail/:id",
    name: "Stocker Sales Order Item Detail",
    component: SalesOrderItemDetail,
    exact: true,
  },
  {
    path: "/stocker/purchase-order-intercompany-item-detail/:id",
    name: "Stocker Purchase Order Item Detail Intercompany",
    component: PurchaseOrderItemDetailsIntercompany,
    exact: true,
  },
  {
    path: "/stocker/purchase-order-item-detail/:id",
    name: "Stocker Purchase Order Item Detail",
    component: PurchaseOrderItemDetail,
    exact: true,
  },
  {
    path: "/stocker/sales-order-intercompany-item-detail/:id",
    name: "Stocker Sales Order Item Detail Intercompany",
    component: SalesOrderItemDetailIntercompany,
    exact: true,
  },
  {
    path: "/stocker/rack-management",
    name: "Rack Management",
    component: RackManagement,
    exact: true,
  },
  {
    path: "/stocker/opname/",
    name: "Stocker Opname",
    component: Opname,
    exact: true,
  },
  {
    path: "/stocker/opname/task-detail/:id",
    name: "Stocker My Opname Task Detail",
    component: OpnameTaskDetail,
    exact: true,
  },
  {
    path: "/stocker/user-and-device",
    name: "IOT Binding User and Device",
    component: IOTBindingUserAndDevice,
    exact: true,
  },
  {
    path: "/stocker/item-box",
    name: "IOT Binding Item Box",
    component: IOTBindingItemBox,
    exact: true,
  },

  //PointHub
  {
    path: "/point-hub/customers",
    name: "Point Hub Customers",
    component: Customers,
    exact: true,
  },
  {
    path: "/point-hub/customer-bank-accounts",
    name: "Point Hub Customer Bank Accounts",
    component: CustomerBankAccounts,
    exact: true,
  },
  {
    path: "/point-hub/customer-approvals",
    name: "Point Hub Customer Approvals",
    component: CustomerApprovals,
    exact: true,
  },
  {
    path: "/point-hub/pic-positions",
    name: "Point Hub PIC Position",
    component: PicPositions,
    exact: true,
  },
  {
    path: "/point-hub/customer-pic",
    name: "Point Hub Customer PIC",
    component: CustomerPic,
    exact: true,
  },
  {
    path: "/point-hub/maintenance-workshop",
    name: "Maintenance Workshop",
    component: MaintenanceWorkshop,
    exact: true,
  },
  { path: "/point-hub/rentee", name: "Rentee", component: Rentee, exact: true },
  {
    path: "/point-hub/rentee/group-members-assign/:id",
    name: "Rentee",
    component: RenteeMemberAssign,
    exact: true,
  },
  {
    path: "/point-hub/intercompany-trading/vendor",
    name: "Trading Intercompany Connection",
    component: TradingIntercompanyVendor,
    exact: true,
  },
  {
    path: "/point-hub/intercompany-trading/customer",
    name: "Trading Intercompany Connection",
    component: TradingIntercompanyCustomer,
    exact: true,
  },
  {
    path: "/point-hub/intercompany-order",
    name: "Intercompany Order",
    component: IntercompanyOrder,
    exact: true,
  },
  {
    path: "/point-hub/intercompany-order/item/:id",
    name: "Item",
    component: IntercompanyOrderItem,
    exact: true,
  },
  {
    path: "/point-hub/intercompany-order/cart",
    name: "Cart",
    component: IntercompanyOrderCard,
    exact: true,
  },
  {
    path: "/point-hub/intercompany-order/cart/shipment",
    name: "Shipment",
    component: IntercompanyOrderShipment,
    exact: true,
  },

  //CarbonX V2
  ...(activeFeature.CARBONX_V2
    ? [
        {
          path: "/carbonx/dashboard/supplychainemission",
          name: "Supply Chain Emission",
          component: SupplyChainEmissionDashboard,
          exact: true,
        },
        {
          path: "/carbonx/dashboard/companyemission",
          name: "Company Emission",
          component: CompanyEmissionDashboard,
          exact: true,
        },
        {
          path: "/carbonx/companydisclosure/companysetting",
          name: "Company Setting",
          component: CompanySetting,
          exact: true,
        },

        {
          path: "/carbonx/listdisclosure/checksheets",
          name: "Checksheets",
          component: Checksheets,
          exact: true,
        },
        {
          path: "/carbonx/listdisclosure/asset",
          name: "Asset",
          component: Asset,
          exact: true,
        },
        {
          path: "/carbonx/listdisclosure/hr",
          name: "HR",
          component: HR,
          exact: true,
        },
        {
          path: "/carbonx/listdisclosure/bills",
          name: "Bills",
          component: Bills,
          exact: true,
        },
        {
          path: "/carbonx/listdisclosure/purchasedgoodsandservices",
          name: "Purchased Goods and Service",
          component: PurchasedGoodsAndServices,
          exact: true,
        },
        {
          path: "/carbonx/listdisclosure/thirdpartylogistics",
          name: "Third Party Logistics",
          component: ThirdPartyLogistics,
          exact: true,
        },

        {
          path: "/carbonx/companydisclosure/operationdisclosure",
          name: "Operation Disclosure",
          component: OperationDisclosure,
          exact: true,
        },
        {
          path: "/carbonx/companydisclosure/accountingdisclosure",
          name: "Accounting Disclosure",
          component: AccountingDisclosure,
          exact: true,
        },

        {
          path: "/carbonx/AET/AETDashboard",
          name: "AET Dashboard",
          component: AETDashboard,
          exact: true,
        },
        {
          path: "/carbonx/AET/scope1",
          name: "AET Scope 1",
          component: AETScope1,
          exact: true,
        },
        {
          path: "/carbonx/AET/scope2",
          name: "AET Scope 2",
          component: AETScope2,
          exact: true,
        },
        {
          path: "/carbonx/AET/scope3",
          name: "AET Scope 3",
          component: AETScope3,
          exact: true,
        },

        {
          path: "/carbonx/emissionfactors",
          name: "Emission Factors",
          component: EmissionFactors,
          exact: true,
        },

        {
          path: "/carbonx/emissiondatabase/mydata",
          name: "My Data",
          component: MyData,
          exact: true,
        },
        {
          path: "/carbonx/emissiondatabase/datapool",
          name: "Data Pool",
          component: DataPool,
          exact: true,
        },

        {
          path: "/carbonx/decarbonizationplan",
          name: "Decarbonization Plan",
          component: DecarbonizationPlan,
          exact: true,
        },

        {
          path: "/carbonx/offsetstewardship",
          name: "Offset and Stewardship",
          component: OffsetStewardship,
          exact: true,
        },

        {
          path: "/carbonx/reportdisclosure",
          name: "Report Disclosure",
          component: ReportDisclosure,
          exact: true,
        },
      ]
    : []),

  // CarbonX Lama
  // {
  //   path: "/carbonx/dashboard/",
  //   name: "CarbonX Dashboard",
  //   component: CarbonXDashboard,
  //   exact: true,
  // },
  {
    path: "/carbonx/logger/scope1",
    name: "Logger Scope 1",
    component: LoggerScope1,
    exact: true,
  },
  {
    path: "/carbonx/logger/scope2",
    name: "Logger Scope 2",
    component: LoggerScope2,
    exact: true,
  },
  {
    path: "/carbonx/logger/scope3",
    name: "Logger Scope 3",
    component: LoggerScope3,
    exact: true,
  },
  {
    path: "/carbonx/invoice/",
    name: "Carbon Invoice",
    component: CarbonInvoice,
    exact: true,
  },

  //User Management
  { path: "/login", name: "Login", component: Login },
  { path: "/logout", name: "Logout", component: Logout },
  { path: "/register", name: "Register", component: Register },
  {
    path: "/user/management",
    name: "User Management",
    component: UserManagement,
  },
  { path: "/settings", name: "Settings", component: Settings },
  { path: "/subscriptions", name: "Subscriptions", component: Subscriptions },

  // Tools
  {
    path: "/tools/geocode-converter",
    name: "Geocode Converter",
    component: GeocodeConverter,
    exact: true,
  },
  {
    path: "/tools/mypertamina-converter",
    name: "MyPertamina Converter",
    component: MyPertaminaConverter,
    exact: true,
  },
  activeFeature.WALLET_PAGE && {
    path: "/wallet",
    name: "Wallet",
    component: Wallet,
  },
];

export default routes;

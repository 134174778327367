import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, FormGroup, Label } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "react-switch";
import { ClipLoader } from "react-spinners";
import { SETTING_CHANGE_URL } from "../../../../redux/config/links";
import { FaCheckCircle } from "react-icons/fa";
import { BiSolidError } from "react-icons/bi";
import CustomToast from "../../../../components/CustomToast/CustomToast";

const propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.number,
  status: PropTypes.bool,
};

const defaultProps = {
  id: 0,
  name: "Settings",
  value: 0,
  status: false,
};

class EmailSwitches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  UNSAFE_componentWillMount() {
    // eslint-disable-next-line
    if (this.props.value == 1) {
      this.setState({ status: true });
    } else {
      this.setState({ status: false });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    // eslint-disable-next-line
    if (props.value == 1) {
      this.setState({ status: true });
    } else {
      this.setState({ status: false });
    }
  }

  handleChangeSwitch = (status) => {
    this.setState({ status });
    this.fetchChanges(status);
  };

  fetchChanges = (_stat) => {
    var self = this;
    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
    var auth = loginCreds.token;

    this.setState({ isLoading: true });

    var _id = this.props.id;
    var _idstatus;
    if (_stat === true) {
      _idstatus = 1;
    } else {
      _idstatus = 2;
    }

    axios({
      method: "post",
      url: SETTING_CHANGE_URL,
      headers: {
        Authorization: auth,
      },
      data: {
        id: _id,
        id_status: _idstatus,
      },
    })
      .then(() => {
        self.setState({ isLoading: false });
        toast.success(
          <CustomToast
            text={self.props.name + " successfully changed!"}
            icon={<FaCheckCircle />}
          />,
          {
            autoClose: 3000,
          }
        );
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            self.setState({ isLoading: false });
            self.prevStatusReturn();
            return toast.error(
              <CustomToast
                text={error.response.data.message}
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            self.setState({ isLoading: false });
            self.prevStatusReturn();
            return toast.error(
              <CustomToast
                text={error.response.data.message}
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          } else if (error.response.status === 400) {
            self.setState({ isLoading: false });
            self.prevStatusReturn();
            return toast.error(
              <CustomToast
                text={error.response.data.message}
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          } else {
            self.setState({ isLoading: false });
            self.prevStatusReturn();
            return toast.error(
              <CustomToast
                text="Something went wrong... Please try again later..."
                icon={<BiSolidError />}
              />,
              {
                autoClose: 3000,
              }
            );
          }
        } else if (error.request) {
          self.setState({ isLoading: false });
          self.prevStatusReturn();
          return toast.error(
            <CustomToast
              text="Request error! Please try again later..."
              icon={<BiSolidError />}
            />,
            {
              autoClose: 3000,
            }
          );
        } else {
          self.setState({ isLoading: false });
          self.prevStatusReturn();
          return toast.error(
            <CustomToast
              text="Something went wrong... Please try again later..."
              icon={<BiSolidError />}
            />,
            {
              autoClose: 3000,
            }
          );
        }
      });
  };

  prevStatusReturn = (_last) => {
    // eslint-disable-next-line
    if (_last == true || _last == 1) {
      this.setState({ status: false });
    } else {
      this.setState({ status: true });
    }
  };

  render() {
    const { name } = this.props;
    const { isLoading } = this.state;
    return (
      <div>
        <FormGroup row>
          <Col md="9">
            <Label htmlFor="text-input">{name}</Label>
          </Col>
          <Col xs="12" md="3">
            {isLoading ? (
              <center>
                <ClipLoader color={"#123abc"} loading={isLoading} />
              </center>
            ) : (
              <Switch
                onChange={this.handleChangeSwitch}
                checked={this.state.status}
                disabled={isLoading}
              />
            )}
          </Col>
        </FormGroup>
      </div>
    );
  }
}

EmailSwitches.propTypes = propTypes;
EmailSwitches.defaultProps = defaultProps;

export default EmailSwitches;

class DropzoneHelper {

    verifyFile = (file, toVerify, numberOfFileNow) => {
        
        let result = this._verifyMaxFile(file, toVerify, numberOfFileNow);

        return result;

    }

    _verifyMaxFile = (file, toVerify, numberOfFileNow) => {

        if(numberOfFileNow+ 1 <= toVerify.maxFile)
        
            return this._verifyFileSize(file, toVerify)

        return {passed: false, reasonRejected: "max file reached!"}

    }

    _verifyFileSize = (file, toVerify) => {

        if(file.size < toVerify.maxSize)

            return this._verifyFileTypes(file, toVerify)

        return {passed: false, reasonRejected: `file size more than ${(toVerify.maxSize / (1024*1024)).toFixed(2)} MB!`} 
    }

    _verifyFileTypes = (file, toVerify) => {

        let arrayOfAcceptedType = toVerify.accept.split(",")
                                                    .map((fileType) => {return fileType.trim()});

        if(arrayOfAcceptedType.includes(file.type))

            return {passed: true};                      

        return {passed: false, reasonRejected: "wrong file type!" }

    }

    verifyFilesStateLength = (file) => {
        
        if(file.length === 0)

            return false;

        return true;

    }

}

const helper = new DropzoneHelper();

export default helper;
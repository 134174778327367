import React, {Component} from 'react'
import moment from 'moment'
import {Col, Row, Label, Button} from 'reactstrap';
import DatePicker from "react-datepicker"

class DriverSummaryFilters extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            filtersInput : {
                start_date: null,
                end_date: null
            }
        }
    }

    onClose = () => {
        this.props.onClose()
    }

    onSubmitFilterClicked = () => {
        this.props.onFilterSubmited(this.state.filtersInput)
    }

    setFilterInputState = (key, value) => {
        const {filtersInput} = this.state
        if(!(key in filtersInput))
            return
        this.setState({
            filtersInput: {...filtersInput, [key]: value}
        })
    }

    onStartDateChange = (date) => {
        this.setFilterInputState("start_date", 
                    moment(date).startOf("month").format("YYYY-MM-DD"))
    }

    onEndDateChange = (date) => {
        this.setFilterInputState("end_date",
                     moment(date).endOf("month").format("YYYY-MM-DD"))
    }

    render(){
        const {filtersInput} = this.state
        return(
            <React.Fragment>
                <Row>
                    <Col md="4">
                        <Label htmlFor="text-input">Start</Label>
                    </Col>
                    <Col xs="8" md="8">
                        <DatePicker
                            id="start_date"
                            autoComplete="off"
                            dateFormat="yyyy MMMM"
                            showMonthYearPicker
                            onChange={date => this.onStartDateChange(date)}
                            onKeyDown={e => {
                                let typedDate = e.target.value
                                 this.onStartDateChange(typedDate)
                            }}
                            selectsStart
                            placeholderText={filtersInput.start_date? 
                                                    moment(filtersInput.start_date).format("YYYY MMMM") : 
                                                    "Select Month Year" }
                            />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md="4">
                        <Label htmlFor="text-input">End</Label>
                    </Col>
                    <Col xs="8" md="8">
                        <DatePicker
                            id="end_date"
                            autoComplete="off"
                            dateFormat="yyyy MMMM"
                            disabled={!filtersInput.start_date}
                            minDate={new Date(filtersInput.start_date)}
                            showMonthYearPicker
                            onChange={date => this.onEndDateChange(date)}
                            onKeyDown={e => {
                                let typedDate = e.target.value
                                this.onEndDateChange(typedDate)
                            }}
                            selectsStart
                            placeholderText={filtersInput.end_date? 
                                                    moment(filtersInput.end_date).format("YYYY MMMM")  : 
                                                    "Select Month Year"}/>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs="12" md="12">
                        <Button 
                            onClick={() => this.onSubmitFilterClicked()} 
                            disabled={!filtersInput.end_date 
                                        || !filtersInput.start_date 
                                        || isNaN(moment(filtersInput.end_date)) 
                                        || isNaN(moment(filtersInput.start_date))}
                            size="md" color="success">
                                <i className="fa fa-file-o"></i>&nbsp; Export
                        </Button>
                        &nbsp;&nbsp;
                        <Button 
                            onClick={() => this.onClose()} 
                            size="md" color="danger"> Close
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

} export default DriverSummaryFilters
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import {connect} from 'react-redux'
import { HashLoader } from 'react-spinners';
import { fetchCompanyList } from '../../../redux/actions/aUser-CompanyList'
import { fetchWorkshopForSetting } from '../../../redux/actions/transporter/workshop.act'
import CompanySettingsForm from './CompanySettingsForm';
import CompanySettingsLogoUpload from './CompanySettingsLogoUpload';

class CompanySettings extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            id_workshop: null
        };
    }

    componentDidMount = () => {
        this.props.fetchCompanyList()
        this.props.fetchWorkshopForSetting()
    }

    componentDidUpdate(prevProps){
        const {workshopListData} = this.props
        if(prevProps.workshopListData !== workshopListData){
           if(workshopListData.length > 0){
                this.setState({
                    id_workshop: workshopListData[0].id
                }) 
           }
        }
    }

    showContent = () => {
        const { companyListData } = this.props;
        const {id_workshop} = this.state
        let companyListDataWithOtherConfig = companyListData.result[0]
        companyListDataWithOtherConfig["configs"] = {
            "workshop":{
                "id_workshop": id_workshop
            }
        }
                                   
        return (
            <div>
                {/* <CompanySettingsForm companyName={companyListData.result[0].company} companyAddress={companyListData.result[0].company_address} companyLat={companyListData.result[0].lat} companyLng={companyListData.result[0].lng} companyKml={companyListData.result[0].kml_link}/>
                 */}
                 <CompanySettingsForm initialValues={companyListDataWithOtherConfig} />
            </div>
        )
    }

    render() {
        const { inProgress, successCompanyList, companyListData, link_logo, fetchCompanyList } = this.props
        const {inProgressWorkshop, successWorkshop} = this.props
        return (
            <div>
                <Card>
                    <CardHeader>
                        Company Settings
                    </CardHeader>
                    <CardBody>
                    {
                        this.props.inProgress && inProgressWorkshop? 
                        <center><HashLoader color={'#123abc'} loading={this.props.inProgress && inProgressWorkshop}/> <br /> Loading.... Please wait...</center>
                        : 
                        (
                            this.props.successCompanyList && successWorkshop ? 
                            this.showContent() 
                            : 
                            <center>Error fetching your data... <br/><br/> 
                                <Button className="btn btn-primary" color="primary" size="md" 
                                disabled={this.props.inProgress && inProgressWorkshop} 
                                onClick={() => {
                                    this.props.fetchCompanyList()
                                    this.props.fetchWorkshopForSetting()
                                }}>
                                    <i className="fa fa-refresh"></i> 
                                    Try again
                                </Button>
                            </center>
                        )
                    }
                    </CardBody>
                </Card>
                <CompanySettingsLogoUpload 
                    inProgress={inProgress}
                    success={successCompanyList}
                    link_logo={link_logo}
                    companyListData={companyListData.result}
                    fetchCompanyList={fetchCompanyList}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyListData: state.companyStore.companyListData,
        link_logo: state.companyStore.link_logo,
        inProgress: state.companyStore.inProgress,
        successCompanyList: state.companyStore.success,

        workshopListData: state.workshopStore.workshopListData,
        inProgressWorkshop : state.workshopStore.inProgress,
        successWorkshop: state.workshopStore.success
      }
}

export default connect(mapStateToProps, {fetchCompanyList, fetchWorkshopForSetting})(CompanySettings);
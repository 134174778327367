export default {
    items: [
      // {
      //   name: 'Enviroma Devices',
      //   url: '/enviroma/devices',
      //   icon: 'fa fa-microchip',
      // },
      // {
      //   name: 'Temperature',
      //   url: '/enviroma/temperature',
      //   icon: 'fa fa-thermometer-three-quarters',
      // },
      // {
      //   name: 'Air Pressure',
      //   url: '/airpressure',
      //   icon: 'fa fa-cloud',
      // },
      // {
      //   name: 'Humidity',
      //   url: '/humidity',
      //   icon: 'fa fa-tint',
      // },
      // {
      //   name: 'Alerts',
      //   url: '/alerts',
      //   icon: 'fa fa-bell',
      // },
      {
        name: 'TrackX',
        url: '/transporter',
        icon: 'fa fa-car',
        children: [
        //   {
        //     name: 'Fleets',
        //     url: '/transporter/fleets',
        //     icon: 'fa fa-car',
        //   },
          {
            name: 'Main Dashboard',
            url: '/transporter/dashboard',
            // icon: 'icon-speedometer',
          },
          {
            name: 'Live Monitoring',
            url: '/transporter/live-monitoring',
            // icon: 'fa fa-map',
          },
        //   {
        //     name: 'Drivers',
        //     url: '/transporter/drivers',
        //     icon: 'fa fa-id-badge',
        //   },
        //   {
        //     name: 'Drivers Assistant',
        //     url: '/transporter/drivers-assistant',
        //     icon: 'fa fa-male',
        //   },
          {
            name: 'Delivery Orders',
            url: '/transporter/delivery-order',
            // icon: 'fa fa-file-text',
          },
        //   {
        //     name: 'Saved Places',
        //     url: '/transporter/saved-places',
        //     icon: 'fa fa-map-marker',
        //   },
        //   {
        //     name: 'Security',
        //     url: '/transporter/security',
        //     icon: 'fa fa-shield',
        //   },
        ]
    },
      // {
      //   name: 'Fleets',
      //   url: '/transporter/fleets',
      //   icon: 'fa fa-car',
      // },
      // {
      //   name: 'Fleet Monitoring',
      //   url: '/transporter/monitoring',
      //   icon: 'fa fa-map',
      // },
      // {
      //   name: 'Drivers',
      //   url: '/transporter/drivers',
      //   icon: 'fa fa-id-badge',
      // },
      // {
      //   name: 'Drivers Assistant',
      //   url: '/transporter/drivers-assistant',
      //   icon: 'fa fa-male',
      // },
      // {
      //   name: 'Delivery Orders',
      //   url: '/transporter/delivery-order',
      //   icon: 'fa fa-file-text',
      // },
      {
        name: 'Settings',
        url: '/settings',
        icon: 'fa fa-cogs',
      },
  
    ]
  };
  
import React, { useEffect, useState } from "react";
import useLazyFetch from "../../../../../hooks/useLazyFetch";
import { DRIVER_ASSISTANT_LIST_URL } from "../../../../../redux/config/links";
import { Button, Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import LoadingContent from "../../../../../components/LoadingContent/LoadingContent";
import Select from "react-select";
import * as Yup from "yup";
import { STORING_TICKET_LIST } from "../../../../../redux/config/incident.links";
import Datetime from "react-datetime";
import moment from "moment";
import {
  TRIP_INPUT,
  TRIP_EDIT,
} from "../../../../../redux/config/storing.links";
import { toast } from "react-toastify";
import useForm from "../../../../../hooks/useForm";

// DRIVER_FLEETS_LIST_URL

const TripAddEdit = ({
  onClose,
  onRefresh,
  initialValues = null,
  isEdit = false,
}) => {
  const [gettingIncidentStoringTicket, setGettingIncidentStoringTicket] =
    useState(true);
  const [
    onGetIncidentStoringTicketList,
    {
      loading: loadingIncidentStoringTicketList,
      data: incidentStoringTicketList,
    },
  ] = useLazyFetch({
    url: STORING_TICKET_LIST,
    method: "POST",
  });

  const [gettingDriverAssistantList, setGettingDriverAssistantList] =
    useState(true);
  const [
    onGetDriverAssistantList,
    { loading: loadingDriverAssistantList, data: driverAssistantList },
  ] = useLazyFetch({
    url: DRIVER_ASSISTANT_LIST_URL,
    method: "POST",
  });

  const [onTripInput] = useLazyFetch({
    url: TRIP_INPUT,
    method: "POST",
  });

  const [onTripEdit] = useLazyFetch({
    url: TRIP_EDIT,
    method: "POST",
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (!isEdit) {
      onTripInput(
        {
          body: {
            id_incident_storing_ticket: values.incident_storing_ticket.value,
            id_fleet: values.fleet.value,
            id_driver: values.driver.value,
            id_driver_fleet_scan: values.driver_fleet_scan.value,
            id_driver_assistant: values.driver_assistant.value,
            est_arrival_time:
              moment(values.est_arrival_time).format(
                "YYYY-MM-DDTHH:mm:ss.000"
              ) + "Z",
            realization_arrival_time:
              moment(values.realization_arrival_time).format(
                "YYYY-MM-DDTHH:mm:ss.000"
              ) + "Z",
            departure_lat: values.departure_lat,
            departure_lng: values.departure_lng,
            progress_status: values.progress_status,
            progress_status_remark: values.progress_status_remark,
          },
        },
        (success) => {
          if (!!success) {
            onRefresh();
            onClose();
          } else {
            setSubmitting(false);
            toast.error("Something went wrong!");
          }
        }
      );
    } else {
      onTripEdit(
        {
          body: {
            id_incident_storing_ticket: values.incident_storing_ticket.value,
            id_fleet: values.fleet.value,
            id_driver: values.driver.value,
            id_driver_fleet_scan: values.driver_fleet_scan.value,
            id_driver_assistant: values.driver_assistant.value,
            est_arrival_time:
              moment(values.est_arrival_time).format(
                "YYYY-MM-DDTHH:mm:ss.000"
              ) + "Z",
            realization_arrival_time:
              moment(values.realization_arrival_time).format(
                "YYYY-MM-DDTHH:mm:ss.000"
              ) + "Z",
            departure_lat: values.departure_lat,
            departure_lng: values.departure_lng,
            progress_status: values.progress_status,
            progress_status_remark: values.progress_status_remark,
            id: initialValues.id,
          },
        },
        (success) => {
          if (!!success) {
            onRefresh();
            onClose();
          } else {
            setSubmitting(false);
            toast.error("Something went wrong!");
          }
        }
      );
    }
  };

  const validation = () => {
    let selectYup = Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable();

    let yup = Yup.object().shape({
      incident_storing_ticket: selectYup.required(
        "Incident Storing Ticket is required!"
      ),
      driver: selectYup.required("Driver is required!"),
      driver_assistant: selectYup.required("Driver Assistant is required!"),
      est_arrival_time: Yup.string().required("Est Arrival Time is required!"),
      realization_arrival_time: Yup.string().required(
        "Realization Arrival Time is required!"
      ),
      departure_lat: Yup.string().required("Departure Latitude is required!"),
      departure_lng: Yup.string().required("Departure Longitude is required!"),
      progress_status: Yup.string().required("Progress Status is required!"),
      progress_status_remark: Yup.string().required(
        "Progress Status Remark is required!"
      ),
    });
    return yup;
  };

  const convertDate = (value) => {
    const splittedValue = value.split("T");
    const splittedDate = splittedValue[0].split("-");
    const splittedTime = splittedValue[1].split(".");

    return (
      splittedDate[1] +
      "-" +
      splittedDate[2] +
      "-" +
      splittedDate[0] +
      " " +
      splittedTime[0]
    );
  };

  const {
    handleSubmit,
    errors,
    values,
    handleReset,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useForm({
    initialValues: {
      incident_storing_ticket:
        !!initialValues && !!initialValues.id_incident_storing_ticket
          ? {
              value: initialValues.id_incident_storing_ticket,
              label: initialValues.incident_storing_ticket_title,
            }
          : null,
      fleet:
        !!initialValues && !!initialValues.id_fleet
          ? {
              value: initialValues.id_fleet,
              label: initialValues.fleet_reg_number,
            }
          : null,
      driver:
        !!initialValues && !!initialValues.id_driver
          ? {
              value: initialValues.id_driver,
              label: initialValues.driver_name,
            }
          : null,
      driver_assistant:
        !!initialValues && !!initialValues.id_driver_assistant
          ? {
              value: initialValues.id_driver_assistant,
              label: initialValues.driver_assistant_name,
            }
          : null,
      driver_fleet_scan:
        !!initialValues && !!initialValues.id_driver_fleet_scan
          ? {
              value: initialValues.id_driver_fleet_scan,
              label: initialValues.driver_name,
            }
          : null,
      est_arrival_time:
        !!initialValues && !!initialValues.est_arrival_time
          ? convertDate(initialValues.est_arrival_time)
          : null,
      realization_arrival_time:
        !!initialValues && !!initialValues.realization_arrival_time
          ? convertDate(initialValues.realization_arrival_time)
          : null,
      departure_lat:
        !!initialValues && !!initialValues.departure_lat
          ? initialValues.departure_lat
          : "",
      departure_lng:
        !!initialValues && !!initialValues.departure_lng
          ? initialValues.departure_lng
          : "",
      progress_status:
        !!initialValues && !!initialValues.progress_status
          ? initialValues.progress_status
          : "",
      progress_status_remark:
        !!initialValues && !!initialValues.progress_status_remark
          ? initialValues.progress_status_remark
          : "",
    },
    validation: validation(),
  });

  const handleChange = (e) => {
    setFieldValue(e.currentTarget.name, e.currentTarget.value);
  };

  useEffect(() => {
    if (gettingIncidentStoringTicket) {
      setGettingIncidentStoringTicket(false);
      onGetIncidentStoringTicketList({});
    }
  }, [gettingIncidentStoringTicket, onGetIncidentStoringTicketList]);

  useEffect(() => {
    if (gettingDriverAssistantList) {
      setGettingDriverAssistantList(false);
      onGetDriverAssistantList({});
    }
  }, [gettingDriverAssistantList, onGetDriverAssistantList]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Incident Storing Ticket</Label>
          </Col>
          <Col xs="12" md="9">
            <LoadingContent
              loading={loadingIncidentStoringTicketList}
              onReload={() => setGettingIncidentStoringTicket(true)}
            >
              <Select
                value={values.incident_storing_ticket}
                onChange={(value) => {
                  setFieldValue("incident_storing_ticket", value);
                  setFieldValue("driver", {
                    label: value.driver_name,
                    value: value.id_driver,
                  });
                  setFieldValue("fleet", {
                    label: value.fleet_reg_number,
                    value: value.id_fleet,
                  });
                  setFieldValue("driver_fleet_scan", {
                    label: value.driver_name,
                    value: value.id_driver_fleet_scan,
                  });
                }}
                options={(incidentStoringTicketList
                  ? incidentStoringTicketList.result
                  : []
                ).map((item) => ({
                  label: item.title,
                  value: item.id,
                  id_driver: item.driver_fleet_scan_id_driver,
                  driver_name: item.driver_name,
                  id_fleet: item.driver_fleet_scan_id_fleet,
                  fleet_reg_number: item.fleet_reg_number,
                  id_driver_fleet_scan: item.id_driver_fleet_scan,
                }))}
                disabled={isSubmitting}
                placeholder={"Choose incident storing ticket"}
              />
            </LoadingContent>
            <FormFeedback>{errors.incident_storing_ticket}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver Fleet Scan</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={values.driver_fleet_scan}
              onChange={(value) => {
                setFieldValue("driver_fleet_scan", value);
              }}
              options={[].map((item) => ({
                label: item.driver_name,
                value: item.id,
              }))}
              disabled={true}
              placeholder={"Choose Driver Fleet Scan"}
            />
            <FormFeedback>{errors.driver_fleet_scan}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Fleet</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={values.fleet}
              onChange={(value) => {
                setFieldValue("fleet", value);
              }}
              options={[].map((item) => ({
                label: item.reg_number,
                value: item.id,
              }))}
              disabled={true}
              placeholder={"Choose Fleet"}
            />
            <FormFeedback>{errors.fleet}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              value={values.driver}
              onChange={(value) => {
                setFieldValue("driver", value);
              }}
              options={[].map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              disabled={true}
              placeholder={"Choose Driver"}
            />
            <FormFeedback>{errors.driver}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="text-input">Driver Assistant</Label>
          </Col>
          <Col xs="12" md="9">
            <LoadingContent
              loading={loadingDriverAssistantList}
              onReload={() => setGettingDriverAssistantList(true)}
            >
              <Select
                value={values.driver_assistant}
                onChange={(value) => {
                  setFieldValue("driver_assistant", value);
                }}
                options={(driverAssistantList
                  ? driverAssistantList.result
                  : []
                ).map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                disabled={isSubmitting}
                placeholder={"Choose Driver Assistant"}
              />
            </LoadingContent>
            <FormFeedback>{errors.driver_assistant}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="est_arrival_time">Est Arrival Time</Label>
          </Col>
          <Col xs="12" md="9">
            <Datetime
              id="est_arrival_time"
              name="est_arrival_time"
              dateFormat="MM-DD-YYYY"
              timeFormat="HH:mm:ss"
              value={values.est_arrival_time}
              minDate={new Date()}
              onChange={(date) => setFieldValue("est_arrival_time", date)}
              placeholderText={moment(values.est_arrival_time).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.est_arrival_time}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="realization_arrival_time">
              Realization Arrival Time
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Datetime
              id="realization_arrival_time"
              name="realization_arrival_time"
              dateFormat="MM-DD-YYYY"
              timeFormat="HH:mm:ss"
              value={values.realization_arrival_time}
              minDate={new Date()}
              onChange={(date) =>
                setFieldValue("realization_arrival_time", date)
              }
              placeholderText={moment(values.realization_arrival_time).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.realization_arrival_time}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="departure_lat">Departure Latitude</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="departure_lat"
              id="departure_lat"
              placeholder="Departure Latitude"
              autoComplete="off"
              valid={!errors.departure_lat}
              invalid={!!errors.departure_lat}
              onChange={handleChange}
              value={values.departure_lat || ""}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.departure_lat}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="departure_lng">Departure Longitude</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="departure_lng"
              id="departure_lng"
              placeholder="Departure Longitude"
              autoComplete="off"
              valid={!errors.departure_lng}
              invalid={!!errors.departure_lng}
              onChange={handleChange}
              value={values.departure_lng || ""}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.departure_lng}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="progress_status">Progress Status</Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="progress_status"
              id="progress_status"
              placeholder="Progress Status"
              autoComplete="off"
              valid={!errors.progress_status}
              invalid={!!errors.progress_status}
              onChange={handleChange}
              value={values.progress_status || ""}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.progress_status}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md="3">
            <Label htmlFor="progress_status_remark">
              Progress Status Remark
            </Label>
          </Col>
          <Col xs="12" md="9">
            <Input
              type="text"
              name="progress_status_remark"
              id="progress_status_remark"
              placeholder="Progress Status Remark"
              autoComplete="off"
              valid={!errors.progress_status_remark}
              invalid={!!errors.progress_status_remark}
              onChange={handleChange}
              value={values.progress_status_remark || ""}
              disabled={isSubmitting}
            />
            <FormFeedback>{errors.progress_status_remark}</FormFeedback>
          </Col>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            color="primary"
            className="mr-1"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Wait..." : !isEdit ? "Save Changes" : "Edit"}
          </Button>
          <Button
            type="button"
            color="warning"
            className="mr-1"
            disabled={false}
            onClick={() => null}
          >
            Close
          </Button>
          <Button
            type="reset"
            color="danger"
            className="mr-1"
            disabled={isSubmitting}
            onClick={handleReset}
          >
            Reset
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

export default TripAddEdit;

import React, { Component } from 'react';
import { Input } from 'reactstrap';

class FleetsList extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            value: this.props.isEdit? this.props.state.value : "",
        };
    }
    
    handleChange(e){
        this.setState({
            value: e.target.value
        })
        // console.log(this.state.value)
    }

    getDoorNumber(fleet){
        if(fleet.door_number)
            return `(${fleet.door_number})`
        return ""
    }

    render() {
        let fleets = this.props.state.fleets;
        // console.log(fleets);
        let optionItems = fleets.map((fleet) =>
            <option value={fleet.id}>{fleet.reg_number} {this.getDoorNumber(fleet)} | {fleet.car_general_type} - {fleet.car_brands} {fleet.car_type}</option>
        );

        return (
            <div className="animated fadeIn">
                <Input type="select" name="select" id="addFleetSelect" 
                        value={this.state.value} onChange={this.handleChange.bind(this)}>
                    <option value="">Please select fleet</option>
                    {optionItems}
                </Input>
            </div>
        );
    }
}

export default FleetsList;
import React, { Component } from 'react';
import {
    Col,
    FormGroup,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { WORK_ORDER_SECURITY_APPROVAL_STATUS_RESET_URL } from '../../../redux/config/links';

const validationResetSchema = Yup.object().shape({
    id: Yup.number()
    .notRequired()
})

const propTypes = {
    id: PropTypes.number,
    id_do: PropTypes.number,
    wo_number: PropTypes.string,
    updateWO: PropTypes.func,
}

const defaultProps = {
    id: 0,
    id_do: 0,
    wo_number: '',
}

class WorkOrderResetSecurityApproval extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            initialResetValues: {
                id: 0
            },

            modalDelete: false,
        };
    }

    toggleOpenDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        })
    }

    toggleCloseDelete = () => {
        this.setState({
            modalDelete: false
        })
    }

    showContent = () => {
        return (
            <Formik
                initialValues={this.state.initialResetValues}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    var self = this;
                    var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                    var auth = loginCreds.token;
                    setSubmitting(true)

                    axios({
                        method: 'post',
                        url: WORK_ORDER_SECURITY_APPROVAL_STATUS_RESET_URL,
                        headers: {
                            Authorization: auth
                        },
                        data: {
                            id_work_order: self.props.id,
                        }
                    })
                    .then((response) => {
                        if(response.status === 200){
                            if (response.data.status === "success"){
                                setSubmitting(false)
                                toast.success('Work order reset succesfully!')
                                self.toggleCloseDelete();
                                resetForm()
                                self.props.updateWO(self.props.id_do);
                            }
                        }
                    })
                    .catch(function(error){
                        if (error.response) {
                            if(error.response.status === 401) {
                                setSubmitting(false)
                                return toast.error("Invalid credentials! Please try again!");
                            } else if (error.response.status === 403) {
                                setSubmitting(false)
                                return toast.error("Unauthorized Access! Please try to logout..");
                            } else if (error.response.status === 400) {
                                setSubmitting(false)
                                return toast.error(error.response.data.message);
                            } else if (error.response.status === 404 || error.response.status === 500) {
                                setSubmitting(false)
                                return toast.error("Server cannot be contacted! Please ask your system administrator!");
                            } else {
                                setSubmitting(false)
                                return toast.error('Something went wrong... Please try again later...')
                            }
                        } else if (error.request) {
                            setSubmitting(false)
                            return toast.error('Request error! Please try again later...')
                        } else {
                            setSubmitting(false)
                            return toast.error('Something went wrong... Please try again later...')
                        }
                    })
                }}
                validationSchema={validationResetSchema}
                render={({
                // values,
                // errors,
                // touched,
                // setFieldValue,
                // setFieldTouched,
                handleSubmit,
                // handleBlur,
                // handleChange,
                // handleReset,
                // setTouched,
                // isValid,
                isSubmitting
                }) => (
                <form>
                    <FormGroup row>
                        <Col md="12">
                            <Label htmlFor="text-input">Are you sure to reset this work order approval's status? <strong>This action cannot be undone!</strong></Label>
                        </Col>
                    </FormGroup>
                    

                    <FormGroup>
                        <Button type="button" onClick={handleSubmit} color="primary" className="mr-1" disabled={isSubmitting}>{isSubmitting ? 'Resetting...' : 'Reset'}</Button>
                        <Button type="button" color="warning" className="mr-1" disabled={isSubmitting} onClick={this.toggleCloseDelete}>Close</Button>
                        {/* <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button> */}
                    </FormGroup>
                </form>
                )}
            />
        )
    }

    render() {
        const { wo_number } = this.props;
        return (
            <div>
                <div><Button className="btn btn-warning" color="warning" size="md" onClick={() => this.toggleOpenDelete()}><i className="fa fa-history"></i> Reset Approval</Button></div>

                <Modal isOpen={this.state.modalDelete} toggle={this.toggleOpenDelete} className={this.props.className}>
                    <ModalHeader toggle={this.toggleCloseDelete}>Reset security approval for {wo_number}?</ModalHeader>
                    <ModalBody>
                            {this.showContent()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

WorkOrderResetSecurityApproval.propTypes = propTypes;
WorkOrderResetSecurityApproval.defaultProps = defaultProps;

export default WorkOrderResetSecurityApproval;